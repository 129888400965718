<template>
  <el-dialog
    v-if="dialogReceiptDetailVisible"
    :title="title"
    :visible.sync="dialogReceiptDetailVisible"
    width="1270px"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <div class="content">
      <el-row>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <!-- 销售退款 -->
          <el-table v-show="form.payType === 2" border :data="topTableData">
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="#"
            ></el-table-column>
            <el-table-column
              width="140"
              prop="purchaseOrderNumber"
              align="center"
              label="销售单号"
            >
              <template slot-scope="scope">
                <span
                  class="page-link"
                  @click="
                    purchaseOrderNumberClick(scope.row.purchaseOrderNumber)
                  "
                >
                  {{ scope.row.purchaseOrderNumber }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              prop="supplierName"
              align="center"
              label="收款方"
            >
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  align="middle"
                  justify="center"
                  class="supplierName-row"
                >
                  <p class="supplierName-p">
                    {{ scope.row.supplierName || '--' }}
                  </p>
                  <el-row class="test-row" v-if="scope.row.isAgreement == 1">
                    <el-tag type="danger" size="mini" class="ml5">协议</el-tag>
                  </el-row>
                </el-row>
              </template>
            </el-table-column>

            <el-table-column
              prop="bankAccount"
              align="center"
              label="收款账户"
            ></el-table-column>

            <el-table-column
              width="100"
              prop="amountApplication"
              align="center"
              label="付款金额"
            >
              <template slot-scope="scope">
                <span class="f_w_6 c_success">
                  {{ form.entry == 1 ? '-' : '' }}
                  {{ scope.row.currency | formatCurrency }}
                  {{ utils.numberFormat(scope.row.amountApplication, 2) }}
                </span>
              </template>
            </el-table-column>
          </el-table>

          <!-- 采购付款 -->
          <el-table v-show="form.payType === 1" border :data="topTableData">
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="#"
            ></el-table-column>
            <el-table-column
              width="180"
              prop="purchaseOrderNumber"
              align="center"
              label="采购单号"
            >
              <template slot-scope="scope">
                <router-link
                  target="_blank"
                  :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                    scope.row.purchaseOrderId
                  }&number=${scope.row.purchaseOrderNumber}&noReturn=${true}`"
                >
                  <span class="page-link">
                    {{ scope.row.purchaseOrderNumber || '--' }}
                  </span>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="purchaser"
              label="买方"
              align="center"
              width="200"
            ></el-table-column>
            <el-table-column
              width="200"
              prop="supplierName"
              align="center"
              label="收款方"
            >
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  align="middle"
                  justify="center"
                  class="supplierName-row"
                >
                  <p class="supplierName-p">
                    {{ scope.row.supplierName || '--' }}
                  </p>
                  <el-row class="test-row" v-if="scope.row.isAgreement == 1">
                    <el-tag type="danger" size="mini" class="ml5">协议</el-tag>
                  </el-row>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column align="center" label="收款账户">
              <template slot-scope="scope">
                {{ scope.row.accountName }}/{{ scope.row.bankName }}/{{
                  scope.row.bankAccount
                }}/{{ scope.row.dutyAccount }}
              </template>
            </el-table-column>

            <el-table-column
              width="100"
              prop="amountApplication"
              align="center"
              label="付款金额"
            >
              <template slot-scope="scope">
                <span v-if="form.entryType == 1" class="c_danger f_w_6">
                  <span style="margin-right: -3px">-</span>
                  <span style="margin-right: -3px">
                    {{ scope.row.currency | formatCurrency }}
                  </span>
                  {{ utils.numberFormat(scope.row.amountApplication, 2) }}
                </span>
                <span class="f_w_6 c_success" v-else>
                  {{ scope.row.currency | formatCurrency }}
                  {{ utils.numberFormat(scope.row.amountApplication, 2) }}
                </span>
              </template>
            </el-table-column>
          </el-table>

          <el-form-item
            class="labelStyle"
            label-width="100%"
            label="付款总金额"
            style="width: 15%; display: inline-block"
          >
            <p v-if="form.entryType == 1" class="c_danger f_w_6">
              -{{ form.currency === '美元' ? '$' : '￥'
              }}{{ utils.numberFormat(form.amountApplication, 2) }}
            </p>
            <p v-else style="color: #008000; font-weight: 600">
              {{ form.currency === '美元' ? '$' : '￥'
              }}{{ utils.numberFormat(form.amountApplication, 2) }}
            </p>
            <small>
              汇率：{{ form.exchangeRate }} ￥{{
                form.entryType == 1 ? '-' : ''
              }}
              {{ utils.numberFormat(form.amountApplicationEx, 2) }}
            </small>
          </el-form-item>
          <el-form-item
            v-if="!isCBS"
            class="labelStyle"
            label-width="100%"
            label="公账付款"
            style="width: 29%; display: inline-block"
          >
            <el-radio-group v-model="form.publicPayment">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            class="labelStyle"
            label-width="100%"
            label="付款账号"
            style="width: 24%; display: inline-block"
            prop="paymentAccount"
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="form.paymentAccount"
              filterable
              style="width: 73%"
            >
              <el-option
                v-for="item in Payer"
                :key="item.shroffAccountId"
                :label="
                  item.payee +
                  ' / ' +
                  item.bankDeposit +
                  ' / ' +
                  item.bankAccount
                "
                :value="item.bankAccount"
              >
                <span style="float: left">
                  {{ item.payee }} / {{ item.bankDeposit }} /
                  {{ item.bankAccount }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="!isCBS"
            class="labelStyle"
            label-width="100%"
            label="实际付款时间"
            prop="paymentDate"
            style="width: 30%; display: inline-block"
          >
            <el-date-picker
              type="date"
              placeholder="年/月/日"
              v-model="form.paymentDate"
              value-format="yyyy-MM-dd"
              style="width: 50%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            class="labelStyle"
            label-width="100%"
            label="备注"
            style="width: 44%; display: inline-block"
            prop="remark"
          >
            <el-input
              placeholder="请输入500个字以内"
              type="textarea"
              rows="3"
              resize="none"
              style="width: 84%"
              v-model="form.remark"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="labelStyle"
            label-width="100%"
            label="附件"
            style="width: 42%; display: inline-block"
          >
            <ErpUpload
              :key="dialogReceiptDetailVisible"
              :uploadParams="uploadParamsCertif2"
              @uploadList="uploadCertifFileList"
            ></ErpUpload>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <el-row slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="save" :loading="loading">
        确 认
      </el-button>
      <el-button @click="close">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import {
    getDetailForEdit,
    batchPaymentComfirm,
    comfirm,
    paymentStatusVO,
    getbatchPayList,
    launchCbsPay,
  } from '@/api/finance/finance-payment'
  import { listShorffAccountPay } from '@/api/finance/finance-cost'
  import { mapState } from 'vuex'
  let _this
  export default {
    name: 'affirm-payment',
    data() {
      return {
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        amendRow: '',
        dialogReceiptDetailVisible: false, //付款单详情弹窗
        form: {
          paymentAccount: '',
          paymentDate: '',
          paymentCnapsCode: '', // 联行号
        },
        // 付款人
        Payer: [],
        topTableData: [],
        rules: {
          remark: [{ max: 500, message: '最多500个字符', trigger: 'blur' }],
          paymentDate: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        moneySign: '', //货币符号
        title: '确认付款',
        isCBS: false, //是否是CBS支付
        loading: false,
      }
    },
    computed: {
      ...mapState({
        currencyList: (state) => state.warehouseCenter.currencyList,
      }),
      totalAmount() {
        return this.topTableData.reduce((total, item) => {
          let currency = this.currencyList.find((obj) => {
            return obj.financeName == item.currency
          })
          if (currency) {
            return total + item.amountApplication * currency.exchangeRate
          } else {
            return total
          }
        }, 0)
      },
    },
    filters: {
      formatCurrency(value) {
        let str
        _this.currencyList.forEach((item) => {
          if (item.financeName == value) {
            str = item.currencyMark
          }
        })
        return str
      },
    },
    beforeCreate() {
      _this = this
    },

    methods: {
      // 付款账号
      async getPayer(purchaser = '') {
        let response = await listShorffAccountPay({
          shroffType: 4,
          cbsFlag: this.isCBS ? 1 : 0,
          purchaser:purchaser
        })
        this.Payer = response.data
      },
      async showReceiptDetail(ids, str, isCBS) {
        this.isCBS = isCBS
        if (str === 'more') {
          this.title = '批量确认付款'
        }
        if (this.isCBS) {
          this.title = '发起CBS支付'
        }
        this.dialogReceiptDetailVisible = true
        // this.$refs.form.clearValidate(['paymentAccount', 'paymentDate'])
        let response
        // 批量和单个走不同接口
        if (ids.indexOf(',') > -1) {
          response = await getbatchPayList({ businessIds: ids })
        } else {
          response = await getDetailForEdit({ businessId: ids })
        }
        this.topTableData = response.data.payOrderChildEditVOList.map(
          (item) => {
            item.purchaser = response.data.purchaser
            return item
          }
        )
        this.getPayer(response.data.purchaser)
        // 处理-1情况开始
        response.data.publicPayment = 1
        // 处理-1情况结束
        this.form = response.data
        this.form = JSON.parse(
          JSON.stringify(this.form).replace(
            /payOrderChildEditVOList/g,
            'payOrderChildEditDTOList'
          )
        )
        this.form.paymentCnapsCode = response.data.cnapsCode
        this.form.paymentDate = new Date()
        this.paymentAccountHidden = this.form.publicPayment
      },

      //销售订单号跳转
      async purchaseOrderNumberClick(code) {
        let response = await paymentStatusVO({
          orderCode: code,
        })
        if (response.code == '000000') {
          let orderStatus = response.data.orderStatus
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: code, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        }
      },
      close() {
        this.title = '确认付款'
        this.dialogReceiptDetailVisible = false
        this.$refs.form.resetFields()
        this.money = 0
      },
      // //公账付款
      // publicPaymentChange(val) {
      //   this.paymentAccountHidden = val
      // },
      // 文件上传
      uploadCertifFileList(fileList) {
        this.form.financeUrl = JSON.stringify(fileList)
      },
      save() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let idList = this.topTableData.map((item) => {
              return item.payOrderId
            })
            this.loading = true
            let ids = Array.from(new Set(idList))
            let params = {
              confirmFileAnnex: this.form.financeUrl,
              confirmRemark: this.form.remark,
              paymentAccount: this.form.paymentAccount,
              paymentDate: this.form.paymentDate,
              publicPayment: this.form.publicPayment,
              rejectReason: '',
            }
            try {
              let response
              // 批量和单个走不同接口
              if (ids.length > 1) {
                params['payOrderIdList'] = ids
                response = await batchPaymentComfirm(params)
              } else {
                const reqMethod = this.isCBS ? launchCbsPay : comfirm
                response = await reqMethod({
                  ...this.form,
                  confirmFileAnnex: this.form.financeUrl,
                  confirmRemark: this.form.remark,
                })
              }
              if (this.isCBS) {
                if (response.data == '发起CBS支付成功') {
                  this.$message.success('确认成功')
                  this.close()
                  this.$emit('getList')
                } else {
                  this.$message.error(response.data)
                }
              } else {
                if (response.code == '000000') {
                  this.$message.success('确认成功')
                  this.close()
                  this.$emit('getList')
                }
              }
            } catch (err) {
              console.log('服务有错误，错误信息为：', err)
            } finally {
              this.loading = false
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;
    .detail-header {
      > .el-col {
        p {
          margin-bottom: 14px;
        }
        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }
    .remark {
      > .el-col {
        p {
          margin-bottom: 14px;
        }
        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }
    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }
  ::v-deep {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 14px;
        color: #6f7279;
      }
    }
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0;
    }
    .el-dialog__footer {
      text-align: center;
    }
    .labelStyle {
      label {
        text-align: left;
      }
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .supplierName-row {
    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }
</style>
