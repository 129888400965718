<template>
  <el-dialog
    title="出库申请"
    width="1200px"
    class="custom-dialog"
    :visible.sync="demandDeliveryVisable"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="出库时间"
            prop="expectOutDate"
            label-width="80px"
          >
            <el-date-picker
              style="width: 100%"
              v-model="form.expectOutDate"
              type="date"
              placeholder="年/月/日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="出库仓库" prop="warehouseId" label-width="80px">
            <el-select
              v-model="form.warehouseId"
              @change="checkStock"
              clearable
            >
              <el-option
                v-for="item in warehouseList"
                :key="item.warehouseId"
                :label="item.name"
                :value="item.warehouseId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-table
        border
        ref="ErpTable"
        :data="form.applicationWarehouseOutProductEditDTOList"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="input-table"
      >
        <el-table-column label="序号" type="index" width="55" />
        <el-table-column type="expand" width="40">
          <template slot="header">
            <i
              :class="[
                'el-icon-arrow-right expand-all',
                isExpandAll ? 'active' : '',
              ]"
              @click="expandAll"
            ></i>
          </template>
          <!-- 扩展table -->
          <template slot-scope="props">
            <el-table
              :data="props.row.childrens"
              border
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
              class="expand-table"
            >
              <el-table-column
                label="入库批次"
                width="200"
                prop="lotNo"
                label-class-name="required-th"
              >
                <template slot-scope="scope">
                  <el-form-item>
                    <div class="column-sty">
                      <el-input
                        v-model="
                          form.applicationWarehouseOutProductEditDTOList[
                            props.$index
                          ].childrens[scope.$index].lotNo
                        "
                        placeholder="请选择入库批次"
                        disabled
                      ></el-input>
                      <i
                        v-show="isChoose"
                        class="el-icon-circle-plus-outline"
                        @click="openStorage(props.$index, scope.$index)"
                      ></i>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="采购单号"
                width="150"
                prop="purchaseNumber"
              >
                <template slot-scope="scope">
                  {{ scope.row.purchaseNumber || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="采购开发" width="150" prop="buyer">
                <template slot-scope="scope">
                  {{ scope.row.buyer || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="批次出库数量"
                width="150"
                prop="num"
              ></el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button
                    class="icon-r"
                    @click="delChildren(props.$index, scope.$index)"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    v-show="isChoose"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="productCn" label="销售订单编号" width="130">
          <template slot-scope="scope">
            <div class="page-link">
              {{ scope.row.salesOrderNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sku"
          label="产品编码"
          width="120"
        ></el-table-column>
        <el-table-column label="产品名称" width="240" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ell">
              <span v-show="scope.row.productCode">{{ scope.row.productCode }}/</span>
              <span v-show="scope.row.nameEn">{{ scope.row.nameEn }}/</span>
              <span>{{  joinSpecs(scope.row) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="standardCustomized"
          label="产品类型"
          width="120"
        ></el-table-column>
        <el-table-column prop="productCn" label="是否需要印刷" width="120">
          <template slot-scope="scope">
            {{ scope.row.printingWay.toUpperCase() === 'BLANK' ? '否' : '是' }}
          </template>
        </el-table-column>
        <el-table-column prop="productCn" label="需求数量" width="120">
          <template slot-scope="scope">
            <div class="stock">
              <div slot="reference">
                <el-button
                  v-show="
                    scope.row.purchasingOrderAdressVOList &&
                    scope.row.purchasingOrderAdressVOList.length > 1 &&
                    scope.row.numUncompleted > 0
                  "
                  :type="scope.row.receiveAddress ? 'success' : 'primary'"
                  plain
                  size="mini"
                  icon="iconfont icon-bangding"
                  circle
                  @click="
                    receiveAddressChoose(
                      scope.row.purchasingOrderAdressVOList,
                      scope.$index
                    )
                  "
                ></el-button>
                {{ scope.row.numUncompleted }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productCn" label="库存可用量" width="240">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.stockWarehouseNumVOList &&
                scope.row.stockWarehouseNumVOList.length > 1
              "
            >
              <el-popover
                v-if="scope.row.stockWarehouseNumVOList.length > 1"
                placement="right"
                width="200"
                trigger="hover"
                :content="formatStock(scope.row.stockWarehouseNumVOList)"
              >
                <span slot="reference">
                  {{
                    formatStock(scope.row.stockWarehouseNumVOList.slice(0, 1))
                  }}
                  <span class="more">
                    等{{ scope.row.stockWarehouseNumVOList.length }}个
                  </span>
                </span>
              </el-popover>
            </span>
            <span v-else>
              {{ formatStock(scope.row.stockWarehouseNumVOList) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="productCn" label="库存锁定情况" width="240">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.stockLockNumList &&
                scope.row.stockLockNumList.length > 1
              "
            >
              <el-popover
                v-if="scope.row.stockLockNumList.length > 1"
                placement="right"
                width="200"
                trigger="hover"
                :content="formatStock(scope.row.stockLockNumList)"
              >
                <span slot="reference">
                  {{ formatStock(scope.row.stockLockNumList.slice(0, 1)) }}
                  <span class="more">
                    等{{ scope.row.stockLockNumList.length }}个
                  </span>
                </span>
              </el-popover>
            </span>
            <span v-else>
              {{ formatStock(scope.row.stockLockNumList) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productCn"
          label="出库数量"
          width="120"
          label-class-name="required-th"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="
                'applicationWarehouseOutProductEditDTOList.' +
                scope.$index +
                '.warehouseOutNum'
              "
              :rules="[
                {
                  required: true,
                  message: '出库数量必填',
                  trigger: 'blur',
                },
                {
                  pattern: /^[0-9]+?$/,
                  message: '请输入>=0的整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                maxlength="10"
                v-model="
                  form.applicationWarehouseOutProductEditDTOList[scope.$index]
                    .warehouseOutNum
                "
                @change="warehouseOutNumChange(scope.$index)"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="productCn" label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              class="icon-r"
              @click="delProduct(scope.$index)"
              size="mini"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <p class="mt10 mb10 text-right">
        总出库数量：
        <span class="gray f_s_16">
          {{
            utils.amountTotal(
              form.applicationWarehouseOutProductEditDTOList,
              'warehouseOutNum',
              0
            )
          }}
        </span>
      </p>

      <el-row :gutter="20">
        <el-col :span="12">
          <p>备注</p>
          <el-input
            type="textarea"
            v-model="form.remark"
            maxlength="500"
            show-word-limit
            :rows="3"
            placeholder="请输入500个字符以内"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <p>附件</p>
          <ErpUpload
            ref="ErpUpload"
            :uploadParams="uploadParams"
            @uploadList="uploadList"
          ></ErpUpload>
        </el-col>
      </el-row>

      <el-checkbox class="mt20" :disabled="!flag" v-model="form.machiningFlag">
        同时生成加工单
      </el-checkbox>
    </el-form>

    <div class="text-center mt20">
      <el-button type="primary" @click="save">确认</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 绑定地址 -->
    <el-dialog
      title="绑定地址"
      width="120"
      class="custom-dialog"
      :visible.sync="addressVisible"
      :close-on-click-modal="false"
      :before-close="addressClose"
      append-to-body
    >
      <el-table
        border
        :data="addressData"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column width="55" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column width="240" label="收货地址">
          <template slot-scope="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column width="160" label="收货公司">
          <template slot-scope="scope">
            {{ scope.row.companyName }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="收货人">
          <template slot-scope="scope">
            {{ scope.row.consigneeName }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="联系电话">
          <template slot-scope="scope">
            {{ scope.row.consigneePhone }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="需求数量">
          <template slot-scope="scope">
            {{ scope.row.quantTotle }}
          </template>
        </el-table-column>
        <el-table-column width="80" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="submitAddress(scope.row)"
              :type="
                scope.row.receiveAddress == scope.row.address
                  ? 'success'
                  : 'primary'
              "
              plain
              size="mini"
              icon="el-icon-check"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList"></LotBatchSel>
  </el-dialog>
</template>

<script>
  import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'
  import { OrderSettingInteractor } from '@/core/interactors/order-setting'
  import { mapGetters } from 'vuex'
  import { joinSpecs } from '@/utils/specs-utils'
  export default {
    components: {
      LotBatchSel,
    },
    data() {
      return {
        pIndex: '', // 选中入库批次父级索引
        addressIndex: '', // 选中收货地址索引
        form: {
          expectOutDate: new Date(), // 出库时间
          warehouseId: '', // 出库仓库
          applicationWarehouseOutProductEditDTOList: [],
          remark: '', // 备注
          fileUrls: '', // 附件链接
          machiningFlag: null, // 同时生成加工单标识：1是0否
          hasNotCompleteInStock: null,
        },
        addressData: [],
        warehouseList: [],
        warehouseMap: {}, // 出库仓库 map 用于判断所选择的仓库与出库仓库对比
        demandDeliveryVisable: false,
        rules: {
          expectOutDate: [
            { required: true, message: '请选择出库时间', trigger: 'change' },
          ],
          warehouseId: [
            { required: true, message: '请选择出库仓库', trigger: 'change' },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        addressVisible: false,
        isChoose: false, // 是否可以选择入库批次
        flag: false, // 是否可以选择同时生成加工单
        isExpandAll: false, // 是否展开全部
        productListVisible: false,
        warehouseOutType: 1, // 先进先出 2指定批次
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    mounted() {
      this.getDict()
    },
    methods: {
      joinSpecs,
      // 初始化加载
      async initLoading(ids) {
        const res = await purchaseDemandInterator.orderCreateWarehouseOutOrder(
          ids
        )

        if (res && res.code == '000000') {
          this.form.applicationWarehouseOutProductEditDTOList =
            res.data.applicationWarehouseOutProductEditVOList.map((item) => {
              return {
                ...item,
                receiveAddress:
                  // 收货地址 只有一个地址默认赋值
                  item.purchasingOrderAdressVOList?.length == 1
                    ? item.purchasingOrderAdressVOList[0].address
                    : '',
                // 判断出库数量不能大于绑定地址的需求数量
                limitNum: '',
                childrens: [],
                // 收货信息默认赋值
                receivingCompany:
                  item?.purchasingOrderAdressVOList[0]?.companyName,
                receivingPerson:
                  item?.purchasingOrderAdressVOList[0]?.consigneeName,
                receivingPhone:
                  item?.purchasingOrderAdressVOList[0]?.consigneePhone,
              }
            })
          this.flag = this.form.applicationWarehouseOutProductEditDTOList.some(
            (item) =>
              item.standardCustomized == '标准品' &&
              item.printingWay.toUpperCase() != 'BLANK'
          )
          this.demandDeliveryVisable = true
          this.$nextTick(() => {
            this.expandAll()
          })
        }
      },

      /**
       * 根据出库数量获取入库批次
       * @param {*} item 选中的出库
       * @param {*} i 选中的出库索引
       * @param {*} warehouseOutType 1先进先出 2指定批次
       * @param {*} type 0:选择出库仓库触发 1:输入出库数量触发
       */
      async lotRecommend(item, i, warehouseOutType, type) {
        if (warehouseOutType === 2) {
          if (type === 0) {
            this.form.applicationWarehouseOutProductEditDTOList[i].childrens = [
              {},
            ]
          }
        } else {
          let params = {
            num: item.warehouseOutNum,
            salesOrderNumber: item.salesOrderNumber,
            skuId: item.sku,
            tenantId: this.userInfo.tenantId,
            warehouseId: this.form.warehouseId,
          }

          if (Object.values(params).every((item) => item)) {
            const res = await purchaseDemandInterator.lotRecommend({
              ...params,
              orderProductUniqueId: item.orderProductUniqueId,
              recommendLotDTOs: this.getRecommendLots(i),
            })

            if (res && res.code === '000000') {
              this.form.applicationWarehouseOutProductEditDTOList[i].childrens =
                res.data

              // recommendLotDTOs需要取当前产品外其余的入库批次合集
              if (type === 0) {
                const _list =
                  this.form.applicationWarehouseOutProductEditDTOList
                if (_list.length > i + 1) {
                  this.lotRecommend(
                    _list[i + 1],
                    i + 1,
                    this.warehouseOutType,
                    0
                  )
                }
              }
            }
          }
        }
      },

      /**
       * 获取推荐批次列表
       * @param {*} i 选中的出库索引
       */
      getRecommendLots(i) {
        let _lots = []
        const _list = this.form.applicationWarehouseOutProductEditDTOList

        for (let n = 0; n < _list.length; n++) {
          const _childrens = _list[n].childrens
          if (i != n && _childrens.length) {
            for (let m = 0; m < _childrens.length; m++) {
              _lots.push({
                num: _childrens[m].num, // 推荐数量
                lotNo: _childrens[m].lotNo, // 批次号
                stockLotId: _childrens[m].stockLotId, // 推荐批次
              })
            }
          }
        }

        return _lots
      },

      // 选择入库批次
      openStorage(pIndex) {
        const parent =
          this.form.applicationWarehouseOutProductEditDTOList[pIndex]
        const childrens = parent.childrens.map((item) => {
          return {
            ...item,
            lotNumber: item.num,
          }
        })

        this.pIndex = pIndex
        this.$refs.lotBatchSel.show({
          list: childrens[0].lotNo ? childrens : [],
          sku: parent.sku,
          orderCode: parent.salesOrderNumber,
          productName: parent.nameEn,
          warehouseId: this.form.warehouseId,
          applyNum: parent.warehouseOutNum,
          orderProductUniqueId: parent.orderProductUniqueId,
        })
      },

      // 获取选中的入库批次
      setLocList(_arr) {
        const list = this.form.applicationWarehouseOutProductEditDTOList
        list[this.pIndex].childrens = []
        for (let i = 0; i < _arr.length; i++) {
          _arr[i].num = _arr[i].lotNumber
          list[this.pIndex].childrens.push(_arr[i])
        }
      },

      // 清除入库批次
      clearLotNo() {
        const list = this.form.applicationWarehouseOutProductEditDTOList
        for (let i = 0; i < list.length; i++) {
          list[i].childrens = []
        }
      },

      // 获取出库仓库下拉框
      async getDict() {
        const res = await purchaseDemandInterator.storageFindWarehouseList2()
        if (res && res.code === '000000') {
          let __data = res.data
          if (__data) {
            this.warehouseList = __data
            for (let i = 0; i < __data.length; i++) {
              let _warehouse = __data[i]
              this.warehouseMap[_warehouse.warehouseId] = _warehouse.name
            }
          }
        }
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.fileUrls = JSON.stringify(fileList)
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate(async (valid) => {
          const list = this.form.applicationWarehouseOutProductEditDTOList

          if (valid) {
            if (!list.length) {
              this.$message.warning('至少选择一条采购需求！')
              return false
            }
            if (!list.every((item) => item.receiveAddress)) {
              this.$message.warning('未绑定收货地址！')
              return false
            }
            // 出库数量不能大于库存数量
            let [storeName, storeFlag] = [null, false]
            this.warehouseList.forEach((item) => {
              if (this.form.warehouseId == item.warehouseId) {
                storeName = item.name
              }
            })
            list.forEach((item) => {
              let num = 0
              if (item.stockWarehouseNumVOList?.length > 0) {
                item.stockWarehouseNumVOList.find((obj) => {
                  if (obj.warehouseName == storeName) {
                    num = obj.num + num
                  }
                })
              }
              if (item.stockLockNumList?.length > 0) {
                item.stockLockNumList.find((obj) => {
                  if (obj.warehouseName == storeName) {
                    num = obj.num + num
                  }
                })
              }

              if (item.warehouseOutNum > num) {
                storeFlag = true
              }
            })
            if (storeFlag) {
              this.$message.warning('出库数量不能大于库存数量')
              return false
            }

            // 批次出库数量=出库数量校验
            for (let i = 0; i < list.length; i++) {
              const amount = list[i].childrens.reduce((p, c) => {
                return (p += c.num)
              }, 0)

              if (amount != list[i].warehouseOutNum) {
                this.$message.warning('批次出库数量总和不等于出库数量')
                return false
              }
            }

            // 保存接口
            const res =
              await purchaseDemandInterator.orderApplicationWarehouseOut(
                this.handleParams()
              )
            if (res && res.code == '000000') {
              this.$message.success('保存成功')
              this.$parent.initLoading()
              this.close()
            }
          } else {
            return false
          }
        })
      },

      // 保存接口入参处理
      handleParams() {
        let [_arr, list] = [
          [],
          this.form.applicationWarehouseOutProductEditDTOList,
        ]

        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < list[i].childrens.length; j++) {
            const _obj = {
              ...list[i],
              stockLotId: list[i].childrens[j].stockLotId,
              lotNo: list[i].childrens[j].lotNo,
              warehouseOutNum: list[i].childrens[j].num,
            }

            _arr.push(_obj)
          }
        }

        return {
          ...this.form,
          machiningFlag: this.form.machiningFlag ? 1 : 0,
          applicationWarehouseOutProductEditDTOList: _arr,
        }
      },

      // 出库数量不能大于需求数量
      async warehouseOutNumChange(i) {
        let list = this.form.applicationWarehouseOutProductEditDTOList[i]

        if (!list.receiveAddress && list.warehouseOutNum) {
          this.$message.warning('请先绑定收货地址！')
          list.warehouseOutNum = list.numUncompleted
          return false
        }
        // 出库数量不允许大于需求数量
        // 判断能否大于出运数量
        const { value } =
          await OrderSettingInteractor.getOutboundQuantityConfig()
        if (!value) {
          if (list.purchasingOrderAdressVOList.length > 1) {
            if (
              list.warehouseOutNum > list.limitNum ||
              list.warehouseOutNum > list.numUncompleted
            ) {
              list.warehouseOutNum =
                list.limitNum > list.numUncompleted
                  ? list.numUncompleted
                  : list.limitNum
              this.$message.warning('出库数量不能大于需求数量')
              return false
            }
          } else {
            if (list.warehouseOutNum > list.numUncompleted) {
              list.warehouseOutNum = list.numUncompleted
              this.$message.warning('出库数量不能大于需求数量')
              return false
            }
          }
        }

        // 获取入库批次
        this.lotRecommend(list, i, this.warehouseOutType, 1)
      },

      // 确定收货地址
      submitAddress(row) {
        let list =
          this.form.applicationWarehouseOutProductEditDTOList[this.addressIndex]

        list.receiveAddress = row.address
        list.receivingCompany = row.companyName
        list.receivingPerson = row.consigneeName
        list.receivingPhone = row.consigneePhone

        list.limitNum = row.quantTotle
        // 如果出库数量大于绑定地址的需求数量，需要置空
        if (list.warehouseOutNum > list.limitNum) {
          list.warehouseOutNum = ''
        }
        this.addressVisible = false
        this.$message.success('绑定地址成功！')
      },

      // 删除产品
      delProduct(i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.applicationWarehouseOutProductEditDTOList.splice(i, 1)
            this.$message.success('删除成功！')
          })
          .catch(() => {})
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
        this.$refs.ErpUpload.uploadListEmpty()
        this.demandDeliveryVisable = false
        this.isExpandAll = false
      },

      // 格式化库存数据
      formatStock(stockData) {
        if (!stockData) {
          return '--'
        } else {
          let isArray = (stockData.constructor = Array)
          let __stockInfo = []
          let __allStockArray = []

          if (isArray && stockData.length > 0) {
            for (let i = 0; i < stockData.length; i++) {
              __stockInfo.push(
                `${stockData[i].warehouseName}:${stockData[i].num};`
              )
            }
          } else {
            __stockInfo.push(`--`)
          }
          return __stockInfo.toString()
        }
      },

      // 判断出库仓库和库存对应的仓库是否匹配
      checkStock(item) {
        let list = this.form.applicationWarehouseOutProductEditDTOList
        let stockArr = []
        list.forEach((item) => {
          if (
            item.stockWarehouseNumVOList &&
            item.stockWarehouseNumVOList.length > 0
          ) {
            item.stockWarehouseNumVOList.forEach((obj) => {
              stockArr.push(obj.warehouseName)
            })
          }
        })
        list.forEach((item) => {
          if (item.stockLockNumList && item.stockLockNumList.length > 0) {
            item.stockLockNumList.forEach((obj) => {
              stockArr.push(obj.warehouseName)
            })
          }
        })

        let currentStockIds = []
        if (stockArr.length) {
          stockArr.forEach((item) => {
            this.warehouseList.forEach((obj) => {
              if (item == obj.name) {
                currentStockIds.push(obj.warehouseId)
              }
            })
          })

          if (!currentStockIds.includes(item)) {
            this.$message({
              message: '出库仓库和库存对应的仓库不一致，请重新选择！',
              type: 'warning',
            })
            this.form.warehouseId = ''
            this.clearLotNo()
          } else {
            this.getStockCostConfig(this.form.warehouseId, list)
          }
        }
      },

      // 获取仓库配置
      async getStockCostConfig(id, list) {
        const res = await purchaseDemandInterator.getStockCostConfigureVO(id)

        if (res && res.code === '000000') {
          this.isChoose = res.data?.warehouseInBatch === 1 ? true : false
          this.warehouseOutType = res.data?.warehouseOutType

          if (list[0].warehouseOutNum) {
            this.lotRecommend(list[0], 0, this.warehouseOutType, 0)
          }
        }
      },

      // 选择收货地址
      receiveAddressChoose(data, i) {
        this.addressData = data
        this.addressIndex = i
        this.addressVisible = true
      },

      // 关闭收货地址
      addressClose() {
        this.addressVisible = false
      },

      // 展开全部
      expandAll() {
        this.isExpandAll = !this.isExpandAll
        this.form.applicationWarehouseOutProductEditDTOList.forEach((item) => {
          this.$refs.ErpTable.toggleRowExpansion(item, this.isExpandAll)
        })
      },

      // 删除子列表
      delChildren(pIndex, cIndex) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const childrens =
              this.form.applicationWarehouseOutProductEditDTOList[pIndex]
                .childrens

            if (childrens.length === 1) {
              this.$message.warning('至少保留一条！')
            } else {
              childrens.splice(cIndex, 1)
              this.$message.success('删除成功！')
            }
          })
          .catch(() => {})
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .el-form-item {
      margin-bottom: 18px;
    }
    p {
      margin: 10px 0;
    }
    .stock {
      position: relative;
      padding-right: 30px;
      .el-button {
        width: 26px;
        height: 26px;
        position: absolute;
        top: -10px;
        right: -5px;
      }
    }
    .column-sty {
      display: flex;
      .el-input {
        padding-right: 5px;
      }
      i {
        font-size: 24px;
        cursor: pointer;
        margin-top: 5px;
        color: #409eff;
      }
    }
  }
  ::v-deep {
    .el-dialog__body {
      margin-top: -15px;
    }
    .icon-bangding {
      line-height: 0;
      font-size: 14px;
      margin-left: -1px;
    }
    .expand-all {
      cursor: pointer;
    }
    &.active {
      transform: rotate(90deg);
    }
  }
  .expand-table {
    margin: 10px 0 10px 0;
    ::v-deep tr {
      height: auto;
      th.el-table__cell {
        height: 32px;
        padding: 4px 0;
      }
      td.el-table__cell {
        height: 40px;
      }
      .el-form-item {
        padding: 5px 0 !important;
      }
    }
  }
</style>
