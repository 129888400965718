<template>
    <div class="ticket-info">
      <el-form ref="form" :model="queryForm" label-width="110px" label-position="left" :rules="queryFormRules">
        <el-row class="query-form-heard">
          <el-col :span="6">
            <el-form-item  label="票据单号：" prop="receiptNumber">
              <el-input v-model.trim="queryForm.receiptNumber" clearable v-if="editBol" maxlength="50"/>
              <span v-else>{{ queryForm.receiptNumber }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="票据流水号：">
              <span>{{ queryForm.receiptCode }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="票据来源：" v-if="!editBol"> 
              <span>{{ queryForm.source | billSourceOptionsFilter}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="收款方式：" prop="paymentType">
              <el-select
                v-model="queryForm.paymentType"
                placeholder="请选择"
                class="w100"
                clearable
                v-if="editBol"
              >
                <el-option
                  v-for="item in paymentListOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <span v-else>{{ queryForm.paymentType === 0 ?'支票':'电汇' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="付款方：" prop="payer">
              <el-input v-model.trim="queryForm.payer" clearable  v-if="editBol" maxlength="90"/>
              <span v-else>{{ queryForm.payer }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="收票时间：">
              {{ queryForm.createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="票据币种：" prop="receiptCurrency">
              <el-select
                v-model="queryForm.receiptCurrency"
                placeholder="请选择"
                class="w100"
                clearable
                v-if="editBol"
              >
                <el-option
                  v-for="item in billCurrencyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <span v-else>{{ queryForm.receiptCurrency === 0 ?'美元':'人民币' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="票据金额：" prop="receiptAmount">
              <el-input v-model="queryForm.receiptAmount" type="number" clearable  
              @blur="receiptAmountBlur" maxlength="10"
              v-if="editBol" oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"/>
              <span v-else>{{ addThousandSeparator(queryForm.receiptAmount) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="票据余额：">
              <span>{{ addThousandSeparator(queryForm.verificationBalance) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="出票银行：" prop="receiptBank">
              <el-input v-model="queryForm.receiptBank"  clearable v-if="editBol" maxlength="50"/>
              <span v-else>{{ queryForm.receiptBank || '--' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="备注：" prop="remark">
              <el-input v-model="queryForm.remark"  clearable  v-if="editBol" maxlength="50"/>
              <span v-else>{{ queryForm.remark || '--'}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="核销金额合计：">
              <span>{{ addThousandSeparator(queryForm.verificationAmountTotal) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="创建人：" v-if="!editBol"> 
              <span>{{ queryForm.creator || '--' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
</template>
<script>
 import { paymentListOptions ,billCurrencyOptions,billSourceOptions} from '../utils'
 import { addThousandSeparator } from 'kits'
 export default {       
    name: 'ticketInfo',
    props: {
      // 是否可编辑
      editBol: {
        type: Boolean,
        default: true
      },
      // 票据信息
      ticketInfo:{
        type: Object,
        required: true
      }
    },
    data() {
      return {
        paymentListOptions,// 收款方式下拉框选项
        billCurrencyOptions,// 票据币种下拉框选项
        queryFormRules:{
          payer: [
            { required: this.editBol, message: '请输入付款方', trigger: 'blur' }
          ],
          receiptNumber: [
            { required: this.editBol, message: '请输入票据单号', trigger: 'blur' },
            { 
            validator: (rule, value, callback) => {
              const isNumber = /^[a-zA-Z0-9]+$/.test(value);
              if (!isNumber) {
                callback(new Error('票据单号必须是数字或字母'));
              } else {
                callback();
              }
            }, 
             trigger: 'blur' 
           }
          ],
          paymentType: [
            { required: this.editBol, message: '请选择收款方式', trigger: 'change' }
          ],
          receiptCurrency: [
            { required: this.editBol, message: '请选择票据币种', trigger: 'change' }
          ],
          receiptAmount: [
            { required: this.editBol, message: '请输入票据金额', trigger: 'blur' }
          ],
        },
      }
    },
    computed:{
      queryForm:{
        get(){
          return this.ticketInfo
        },
        set(val){
          this.$emit('update:ticketInfo',val)
        }
      }
    },
    filters:{
      // 票据来源过滤
      billSourceOptionsFilter(val){
        return billSourceOptions.find(item => item.code === val)?.name
      }
    },
    methods: {
      // 处理千分位
      addThousandSeparator(val) {    
        return addThousandSeparator(val,true)    
      },
      // 票据金额失去焦点重新计算票据余额
      receiptAmountBlur() {
        this.queryForm.verificationBalance = this.queryForm.receiptAmount - this.queryForm.verificationAmountTotal
      }
    }
 }
</script>
<style lang="scss" scoped>
.ticket-info{
  padding: 20px 0;
  .query-form-heard{
    .el-col{
        padding: 0 20px;
      }
   }  
}
</style>