<template>
  <div class="left-aside-wrapper">
    <div ref="leftAside" class="left-aside" :style="{ width: width + 'px' }">
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="item in leftData" :key="item.mailAccountId" :title="item.emailAccount"
          :name="item.emailAccount" style="margin-bottom: 18px">
          <div slot="title" class="account-title">
            <span class="flex1">{{ item.emailAccount }}</span>

            <!-- 协助 -->
            <el-tag class="tag tag1" v-if="item.authStatus == 0">
              {{ $t('myMail.assist') }}
            </el-tag>
            <!-- 失效 -->
            <el-tag class="tag tag2" v-if="item.authStatus == 2">
              {{ $t('myMail.invalid') }}
            </el-tag>
          </div>
          <!-- 文件夹tree -->
          <el-tree :data="item.folderConfigVOS" node-key="folderConfigId" :expand-on-click-node="true"
            :props="defaultProps" :highlight-current="showCurrent" :current-node-key="currentemailId"
            :ref="item.emailAccount" :default-expanded-keys="expandTree" @node-expand="nodeExpand"
            @node-collapse="nodeCollapse" :auto-expand-parent="false">
            <div class="custom-tree-node" slot-scope="{ node, data }" :id="data.folderConfigId">
              <div class="custom-tree-node-text" v-if="!data.rename" @click="getList(data, node, item)" :style="{color:data.color}">
                <el-tooltip class="item" effect="dark" :content="
                    data.langKey ? $t(data.langKey) : data.CnName || data.name
                  " placement="top-start" :disabled="data.name.length < 9">
                  <span class="folederName" :style="{ maxWidth: width * 0.75 + 'px' }">
                    <i :class="
                        data.icon ? data.icon : 'iconfont icon-folder-fill'
                      " style="margin-right: 10px"></i>
                    {{
                    data.userId && data.userId !== 'ALL_USER_ID' && data.userId !== 'OTHER_USER_ID'
                    ? lang === 'en'
                    ? data.userNameEn
                    : data.userNameCn + '(' + data.userNameEn + ')'
                    : data.fullName === '其他文件夹'
                    ? $t('other.Otherfolders')
                    : data.langKey
                    ? $t(data.langKey)
                    : data.CnName || data.name
                    }}
                  </span>
                </el-tooltip>
                <!--  垃圾邮件显示总邮件 其他邮件显示未读 -->
                <span class="unRead" v-if="
                    (data.CnName == '草稿箱' ||
                      data.CnName == '垃圾箱' ||
                      data.CnName == '垃圾邮件') &&
                    data.mailTotalCount
                  ">
                  [{{ data.mailTotalCount }}]
                </span>
                <span class="unRead" v-else-if="
                    data.mailunReadTotalCount && data.mailunReadTotalCount > 0
                  ">
                  [{{ data.mailunReadTotalCount }}]
                </span>

                <ErpMouseoverTip disabled v-if="
                    item.sendFlag == 2 &&
                    ['已发邮件', '已发送', '已发邮件'].includes(data.name)
                  "></ErpMouseoverTip>
              </div>

              <el-input v-if="data.rename" v-model="labelValue" :ref="data.folderConfigId" :id="data.folderConfigId"
                @focus="focusLabel(data)" @keyup.enter.native="$event.target.blur()" @blur="blurLabel(data)"></el-input>
              <el-dropdown trigger="hover" :show-timeout="50" v-if="!data.unOper && item.authStatus === null">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div v-if="
                        data.fullName == '其他文件夹' ||
                        data.emailAccount.indexOf('@qq.com') < 0
                      " @click="append(data)">
                      <!-- 新建文件夹 -->
                      {{ $t('other.newFolder') }}
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div v-if="
                        data.fullName !== '其他文件夹' &&
                        data.fullName !== '[Gmail]'
                      " @click="reName(data)">
                      <!-- 重命名 -->
                      {{ $t('other.rename') }}
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div v-if="
                        data.fullName !== '其他文件夹' &&
                        data.fullName !== '[Gmail]'
                      " @click="remove(node, data)">
                      <!-- 删除文件夹 -->
                      {{ $t('other.removefolders') }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-tree>
          <!-- 标签 -->

          <leftAsideMaskLabel @getDetail="getDetail" :maskList="item.maskList" :currentLabelId="currentLabelId"
            :id="item.mailAccountId + 1" :mailAccountId="item.mailAccountId + 1" :emailAccount="item.emailAccount">
          </leftAsideMaskLabel>
        </el-collapse-item>
      </el-collapse>
    </div>
    <addFolder ref="addFolder" @addNewFolder="addNewFolder"></addFolder>
    <erp-handle class="myxhandle" @widthChange="widthChange" />
  </div>
</template>
<script>
  import { mapGetters, mapState } from 'vuex'
  import { deepCopy } from 'kits'
  import { mailLabelPageList, addEmailFolder } from '@/api/mail/mail.js'
  import { MailInteractor, MailSettingInteractor } from '@/core'

  import { BusOn, BusEmit } from '@/utils/eventBus'
  import {
    getEmailName,
    isInEmailName,
    isShowZohoMailFolder,
  } from '../utils/mail-data'
  import { savePageWidth } from '../utils/tool.js'

  import ShrinkBar from './ShrinkBar.vue'
  import addFolder from './addFolder.vue'
  import leftAsideMaskLabel from './leftAsideMaskLabel.vue'

  export default {
    name: 'MailLeftAside',
    components: {
      ShrinkBar,
      addFolder,
      leftAsideMaskLabel,
    },
    data() {
      return {
        defaultProps: {
          label: 'name',
        },
        leftData: [], // 邮件左边菜单栏
        totalUnread: 0,
        width: 300,
        labelValue: '', // 重命名label
        activeNames: [], //折叠自动展开
        editObj: {}, //重命名 存储数据
        addObj: {},
        currentLabelId: '', // 当前标签高亮
        // expandTree: [], // 默认展开树
        currentemailId: '', // 当前选中的项,默认是第一个邮箱的收件箱
        mailServer: '',
        showCurrent: true,
        searchType: null,
        accountFolderIds: [], //同事文件夹folderConfigId
      }
    },
    created() {
      if (
        this.$route.path == '/mail/my-mail/book' &&
        !this.$route.query.mailName
      ) {
        this.currentemailId = this.$store.state.mail.mailAccountId
      }
      this.currentLabelId = this.$route.query.mailLabelId || ''
      this.currentemailId = this.$store.state.mail.currentAccountNameId || ''
      this.width = Number(this.$store.state.mail?.mailPageWidth?.leftWidth)
      this.getData()
    },

    destroyed() {
      this.$store.commit('mail/setcurrentAccountNameId', '')
    },
    filters: {
      lengthLimit(val) {
        if (val?.length > 10) {
          return val.substring(0, 12) + '...'
        } else {
          return val ? val : '--'
        }
      },
    },
    mixins: [BusOn],
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
        expandTree: 'mail/expandTree',
      }),
      ...mapState({
        currentAccount: (state) => state.mail.currentAccount,
      }),
    },

    methods: {
      refreshData() {
        this.getData()
      },
      searchAll(val) {
        this.searchType = val
        this.showCurrent = false
        this.currentemailId = ''
      },

      treeToArray(data) {
        const result = []
        if (Array.isArray(data)) {
          data.forEach((item) => {
            const loop = (target) => {
              result.push({
                isParent: target.isParent,
                mailTotalCount: target.mailTotalCount,
                folderConfigId: target.folderConfigId,
                mailunReadTotalCount: target.mailunReadTotalCount,
                emailAccount: target.emailAccount,
                name: target.name,
                tenantId: target.tenantId,
                fullName: target.fullName,
                type: target.type,
                mailServer: target.mailServer,
                parentId: target.parentId,
              })
              let child = target.children
              if (child) {
                for (let i = 0; i < child.length; i++) {
                  loop(child[i])
                }
              }
            }
            loop(item)
          })
        }

        return result
      },

      async getData() {
        await MailInteractor.maiLeftAsideApi(this.userInfo.userId, this)
        //  设置第一个邮箱为选中状态
        if (
          (!this.currentemailId && this.searchType !== 0) ||
          this.$route.path === '/mail/my-mail/receive' ||
          this.$route.query.check == 1 // 查看已发邮件
        ) {
          let index = 0
          if (this.$route.query.account) {
            index =
              this.leftData?.findIndex(
                (item) => item.emailAccount === this.$route.query.account
              ) || 0
          }
          // 处理账号解绑
          if (index == -1) {
            index = 0
          }
          let arr = this.leftData[index]?.folderConfigVOS
          let CnName = ''
          let {
            notify,
            mailName,
            mailId,
            mailFullName,
            serve,
            check,
            boxFullName,
            account,
            ddLink
          } = this.$route.query

          let obj = ''
          if (
            (notify == 1 && mailName !== 'INBOX' && mailName != '收件箱') ||
            check == 1
          ) {
            let leftData = this.leftData
            leftData = leftData.map((item) => item.folderConfigVOS)
            leftData = this.treeToArray(leftData[index])
            obj = leftData.find((item) => item.fullName === mailFullName)
          } else {
            obj = arr?.find((item) => {
              return item?.CnName == '收件箱'
            })
          }
          if(ddLink === '1') {


            let gmailFolder = arr?.find((item) => item?.fullName === '[Gmail]')
            obj = gmailFolder?.children?.find(s => s.name ==='已发邮件')
          }

          this.currentemailId = obj?.folderConfigId
          this.$store.commit('mail/setExpandTreeId', obj?.parentId)
          this.$store.commit('mail/setCurrentAccount', obj?.emailAccount)
          this.$store.commit(
            'mail/setcurrentAccountNameId',
            obj?.folderConfigId
          )
          this.$store.commit('mail/setcurrentAccountServer', obj?.mailServer)
          this.$store.commit('mail/setmailFolderName', obj?.name)
          if (
            this.$route.path.indexOf('/mail/my-mail') > -1 &&
            this.$route.path !== '/mail/my-mail/book' &&
            this.$route.path !== '/mail/my-mail/receive'
          ) {

            this.$router.push({
              path: '/mail/my-mail/email-list',
              query: {
                mailName: obj?.name,
                serve: obj?.mailServer,
                mlt: this.$route.query.mlt || '0', // mailListType   默认查全部
                time: 1,
                isAuth:
                  this.leftData[index]?.authStatus !== null ? true : false,
                account: this.leftData[index]?.emailAccount,
                authStatus: this.leftData[index]?.authStatus,
                notify,
                mailId,
                boxFullName: obj?.fullName,
              },
            })
          }
          if (this.$route.path === '/mail/my-mail/receive') {
            this.$router.push({
              path: '/mail/my-mail/receive',
              query: {
                mailName: obj?.name,
                serve: obj?.mailServer,
                mlt: this.$route.query.mlt || '0', // mailListType   默认查全部
                time: 1,
                isAuth:
                  this.leftData[index]?.authStatus !== null ? true : false,
                account: this.leftData[index]?.emailAccount,
                authStatus: this.leftData[index]?.authStatus,
                notify,
                mailId,
                boxFullName: obj?.fullName,
              },
            })
          }

          if (this.$route.query.check == 1) {
            this.$router.push({
              path: '/mail/my-mail/email-list',
              query: {
                mailName,
                serve,
                mlt: this.$route.query.mlt || '0', // mailListType   默认查全部
                time: 1,
                isAuth:
                  this.leftData[index]?.authStatus !== null ? true : false,
                account,
                authStatus: this.leftData[index]?.authStatus,
                boxFullName,
                check,
                ddLink
              },
            })
          }
        }

        this.setView()
      },

      getMailMask(val) {
        mailLabelPageList({ emailAccount: val }).then((res) => {
          this.leftData.forEach((item) => {
            if (item.emailAccount == val) {
              this.$set(item, 'maskList', res?.data?.records)
            }
          })
          this.$store.commit('mail/setReceiveCatgoryList', { [val]: res.data })
        })
      },
      // 获取各邮箱的标签列表
      getMailMarkList(val) {
        mailLabelPageList({
          emailAccount: val,
        }).then((res) => {
          if (res.code == '000000') {
            let list = res.data || []
            this.$set(this.accoutConfig, `${val}`, {
              ...this.accoutConfig[val],
              mailMarkList: list,
            })
          }
          return ele
        })
      },
      // 给其他文件夹中的子文件夹增加parentiId值
      // 只需要在第一层
      setChildParentId(arr, id) {
        return arr?.map((item) => {
          this.$set(item, 'parentId', id)
          return {
            ...item,
          }
        })
      },
      // 拖动后宽度变化监听
      widthChange(movement) {
        this.width -= movement
        if (this.width < 300) {
          this.width = 300
        }
        if (this.width > 700) {
          this.width = 700
        }
        this.$store.commit('mail/setMailListPageWidth', {
          leftWidth: this.width,
        })
        savePageWidth()
      },
      append(data) {
        this.$refs.addFolder.showDialog()
        this.addObj = { ...data }
      },
      addNewFolder(data) {
        let dt = {
          emailAccount: this.addObj.emailAccount,
          fullName: this.addObj.fullName,
          mailServer: this.addObj.mailServer,
          name: data,
          parentId: this.addObj.folderConfigId,
          tenantId: this.addObj.tenantId,
        }
        if (
          this.addObj.mailServer === '阿里云企业邮箱' &&
          this.addObj.parentId < 200
        ) {
          dt.mailServer = null
          delete dt.fullName
          delete dt.parentId
        }
        this.addEmail(dt, 0)
      },
      // 新增或修改邮件文件夹
      addEmail(data, option) {
        let dt = {
          ...data,
          option,
        }

        addEmailFolder(dt).then((res) => {
          this.getData()
          this.$refs.addFolder.cancel()
          if (res?.code === '000000') {
            // this.$message.success('操作成功！')
            this.$message.success(this.$t('reqmsg.$7'))
            if (option == 0) {
              if (!data.children) {
                this.$set(data, 'children', [])
              }
              this.$refs.addFolder.cancel()
              this.addObj = {}
            }
          } else {
            this.$message.error(res.desc)
          }
        })
      },

      remove(node, data) {
        this.$confirm(this.$t('reqmsg.$13'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('other.confirm'),
          cancelButtonText: this.$t('other.cancel'),
          type: 'warning',
        })
          .then(() => {
            let dt = {
              folderConfigId: data.folderConfigId,
              emailAccount: data.emailAccount,
              fullName: data.fullName,
              mailServer: data.mailServer,
              name: data.name,
              parentId: data.parentId,
              tenantId: data.tenantId,
            }
            this.addEmail(dt, 2)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              // message: '已取消删除',
              message: this.$t('reqmsg.$14'),
            })
          })
      },
      // 重命名
      reName(data) {
        this.$set(data, 'rename', true)
        this.editObj = { ...data }
        this.$nextTick(() => {
          this.$refs[data.folderConfigId][0].focus()
        })
      },
      // 编辑label
      blurLabel(data) {
        data.name = this.labelValue
        this.editObj.name = this.labelValue

        // 鼠标失去焦点 新增或者修改

        let dt = {
          emailAccount: this.editObj.emailAccount,
          folderConfigId: this.editObj.folderConfigId,

          mailServer: this.editObj.mailServer,
          name: this.editObj.name,
          option: 1,
          parentId: this.editObj.parentId,
          tenantId: this.editObj.tenantId,
        }

        this.addEmail(dt, 1)
      },
      focusLabel(data) {
        this.labelValue = data.CnName || data.name
      },
      // 展开节点
      nodeExpand(item, node, comp) {
        const expandTree = this.expandTree.slice()
        expandTree.push(item.folderConfigId)
        this.$store.commit('mail/setExpandTree', expandTree)
      },
      // 收起节点
      nodeCollapse(item, node, comp) {
        const expandTree = this.expandTree.slice()
        const index = expandTree.indexOf(item.folderConfigId)
        if (index > -1) {
          expandTree.splice(index, 1)
        }
        this.$store.commit('mail/setExpandTree', expandTree)
      },
      // 点击树
      getList(data, node, item) {
        let mailAccountId = item.mailAccountId
        // 新建文件夹
        if (data.isNew) {
          return
        }
        this.currentemailId = data.folderConfigId
        this.$store.commit('mail/setExpandTreeId', data.parentId)
        this.$store.commit('mail/setCurrentAccount', data.emailAccount)
        this.$store.commit('mail/setcurrentAccountNameId', data.folderConfigId)
        this.$store.commit('mail/setMailAccountId', mailAccountId)
        let path = '/mail/my-mail/email-list'
        // data?.CnName === '收件箱' && !data.parentId
        //   ? '/mail/my-mail/receive'
        //   : '/mail/my-mail/email-list'
        let isAuth, authStatus
        if (data.emailAccount) {
          authStatus = this.leftData.find(
            (i) => i.emailAccount === data.emailAccount
          )?.authStatus
          isAuth = authStatus !== null ? true : false
        }
        this.$router.push({
          path,
          query: {
            mailName: data.name,
            serve: data.mailServer,
            mlt: this.$route.query.mlt || '0', // mailListType   默认查全部
            time: Math.random(),
            account: data?.emailAccount,
            isAuth,
            authStatus,
            boxFullName: data.fullName,
            userId: data.userId,
            id: data.id,
          },
        })
      },
      // 通讯录
      handleToBook(acountItem) {
        this.currentemailId = acountItem.mailAccountId
        this.currentLabelId = acountItem.mailAccountId
        this.$store.commit(
          'mail/setcurrentAccountNameId',
          acountItem.mailAccountId
        )
        this.$store.commit('mail/setCurrentAccount', acountItem.emailAccount)
        this.$router.push({
          path: '/mail/my-mail/book',
          query: {
            account: acountItem.emailAccount,
            mailName: 'qf_txl',
            mlt: this.$route.query.mlt || '0',

          },
        })
      },
      // 标签
      getDetail(item, mailAccountId) {
        let serve = this.getMailServerFromMailAccountId(item)
        this.currentLabelId = item.mailLabelId
        this.currentemailId = mailAccountId
        this.$store.commit('mail/setCurrentAccount', item.emailAccount)
        this.$store.commit('mail/setcurrentAccountNameId', mailAccountId)
        let clickMailAccountId = mailAccountId.slice(
          0,
          mailAccountId.length - 1
        )
        let index =
          this.leftData?.findIndex(
            (item) => item.mailAccountId === clickMailAccountId
          ) || 0
        if (item != undefined) {
          this.$router.push({
            path: '/mail/my-mail/email-list',
            mlt: this.$route.query.mlt || '0',
            query: {
              mailLabelId: item.mailLabelId,
              serve,
              authStatus: this.leftData[index]?.authStatus,
              time: Date.now(),
            },
          })
        } else {
          this.$router.push({
            path: '/mail/my-mail/email-list',
            query: { type: 1, authStatus: this.leftData[index]?.authStatus },
            mlt: this.$route.query.mlt || '0',
          })
        }
      },

      // 根据mailAccountId获取mailServer
      getMailServerFromMailAccountId({ emailAccount }) {
        let leftTableData = this.$store.state.mail?.leftTableData
        if (Array.isArray(leftTableData)) {
          let currentMailAccount = leftTableData.find(
            (mail) => mail.emailAccount === emailAccount
          )
          return currentMailAccount?.mailServer
        }
      },

      // 选择的标签出现在屏幕中
      setView() {
        // 通过Id获取到对应的dom元素
        setTimeout(() => {
          let node,
            mailLabelId = this.$route.query.mailLabelId
          if (mailLabelId) {
            node = document.getElementById(mailLabelId)
          } else {
            node = document.getElementById(this.currentemailId)
          }

          if (node) {
            this.$nextTick(() => {
              node.scrollIntoView({
                block: 'center',
              }) // 通过scrollIntoView⽅法将对应的dom元素定位到可见区域【block: 'center'】这个属性是在垂直⽅向居中显⽰
            })
          }
        }, 0)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .left-aside-wrapper {
    .icon-careful-full {
      color: red;
    }

    display: flex;
    overflow-y: scroll;

    .left-aside {
      overflow-y: scroll;

      ::v-deep {
        .el-tree-node__content {
          height: 36px;
        }

        .all-unRead {
          height: 36px;
          line-height: 36px;
          background-color: #fff;
          padding-left: 5px;
        }

        .el-collapse {
          .el-collapse-item {
            .el-collapse-item__header {
              padding-left: 5px;
              color: #303133;
              font-size: 14px;
              font-family: PingFang Bold;
            }

            .el-collapse-item__content {
              padding-bottom: 0px;
            }
          }
        }
      }

      .custom-tree-node {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 10px;

        .custom-tree-node-text {
          display: flex;
          width: 100%;
          height: 36px;
          align-items: center;
        }
      }

      .folederName {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        display: inline-block;
      }

      .unRead {
        color: #ff5757;
        margin-left: 10px;
      }
    }

    .mail-nav-item {
      line-height: 36px;
      font-size: 14px;
      color: #303030;
      cursor: pointer;
      padding-left: 14px;

      &.active {
        background: #f5f7fa;
        color: #0486fe;

        .iconfont {
          color: #0486fe;
        }
      }

      &:hover {
        background: #f5f7fa;
      }

      span {
        margin-left: 14px;
      }

      .warning-text {
        color: #e02020;
        margin-left: 0;
      }

      .iconfont {
        color: #999;
      }

      &.receive {
        padding: 10px;
        color: #999999;

        &.active {
          background: #f5f7fa;
          color: #303030;
        }

        .receive-header {
          text-align: left;
          text-indent: 0;
          padding-left: 4px;
          display: flex;
          justify-content: space-between;
          padding-right: 14px;

          .warning-text {
            color: #e02020;
            margin-left: 0;
          }

          &>div:first-child {
            i {
              font-size: 16px;
              padding: 0;
              line-height: 36px;
            }
          }

          &>div:nth-child(2) {
            i {
              font-size: 12px;
              margin-left: 13px;
            }
          }

          .icon-plus {
            display: none;
          }

          &:hover {
            .icon-plus {
              display: block;
            }
          }
        }

        .receive-add {
          display: flex;
          text-indent: 0;

          &>div:first-child {
            padding-left: 14px;
            box-sizing: border-box;
            display: flex;

            i {
              font-size: 16px;
              padding: 0;
              line-height: 36px;
              margin-right: 14px;
            }

            /deep/ .el-input__inner {
              height: 24px;
              padding: 0 4px !important;
            }
          }

          &>.sub-btns {
            flex: 1;
            display: flex;

            &>div {
              padding: 0 2px;
              width: 20px;

              i {
                line-height: 36px;
                font-size: 16px;
              }
            }
          }
        }

        .receive-main {
          padding-left: 10px;
        }
      }
    }

    .account-title {
      width: 100%;
      display: flex;
      padding-right: 15px;
      align-items: center;

      .flex1 {
        flex: 1;
      }

      .tag {
        color: #fff !important;
        padding: 0 4px;
        border-radius: 2px;
        border-color: transparent;
        height: 20px;
        line-height: 20px;

        &.tag1 {
          background-color: rgb(194, 128, 255);
        }

        &.tag2 {
          background-color: #7f7f7f;
        }
      }
    }
  }
</style>
