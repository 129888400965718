<template>
  <div class="table-container">
    <el-row type="flex" align="middle" justify="space-between">
      <el-row>
        <el-input
          size="small"
          class="mr10"
          style="width: 270px"
          v-model="form.param"
          placeholder="支持搜索产品编码/产品名称/规格"
        ></el-input>
        <el-select
          size="small"
          placeholder="仓库"
          class="mr10"
          v-model="form.warehouseId"
          value-key="type"
          :key="Math.random()"
        >
          <el-option
            v-for="item in areas"
            :key="item.warehouseId"
            :value="item.warehouseId"
            :label="item.name"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="onButtonSearch">搜索</el-button>
        <el-button class="mr15" @click="onReset">重置</el-button>
        <el-checkbox
          v-model="form.isShow"
          :true-label="0"
          :false-label="1"
          @change="onSearch"
        >
          不显示库存为0的数据
        </el-checkbox>
        <!-- 仓库维度查看 -->
        <el-checkbox
          v-model="wrehouseDimensionView"
          :true-label="1"
          :false-label="0"
          @change="changeUserChecked"
        >
          仓库维度查看
        </el-checkbox>
        <!-- 不显示虚拟库存 -->
        <el-checkbox
          v-model="noVirtualInventory"
          :true-label="1"
          :false-label="0"
          @change="changeUserChecked"
        >
          不显示虚拟库存
        </el-checkbox>
      </el-row>
      <!-- 自定义展示列 -->
      <el-popover
        ref="showOption"
        popper-class="custom-table-checkbox"
        trigger="hover"
      >
        <erp-draggable v-bind="dragOptions" :list="columns">
          <el-checkbox
            v-for="(item, index) in columns"
            :key="item + index"
            v-model="item.isShow"
            :disabled="item.checkable === false"
            :label="item.label"
          >
            {{ item.label }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn text-center">
          <el-button
            type="primary"
            class="ml10"
            size="mini"
            plain
            @click="operateSave"
          >
            保存
          </el-button>
        </div>
        <template #reference>
          <el-tooltip
            effect="dark"
            content="列表可拖动排序"
            placement="top-start"
          >
            <el-button plain type="primary">自定义显示</el-button>
          </el-tooltip>
        </template>
      </el-popover>
    </el-row>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="page.total"
      @query="onSearch"
      class="table-fixed mt15"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index + Math.random()"
        :label="item.label"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
        :fixed="item.fixed"
      >
        <template slot="header" slot-scope="scope">
          <span>{{ item.label }}</span>
          <el-popover
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
          >
            <p>当前仓库的库存在仓数；锁定量+待出库+可用库存+不可用库存</p>
            <i
              class="el-icon-question"
              slot="reference"
              v-if="item.prop == 'num'"
            ></i>
          </el-popover>

          <el-popover
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
          >
            <p>库存在此仓库内锁定的库存数量</p>
            <i
              class="el-icon-question"
              slot="reference"
              v-if="item.prop == 'lockNum'"
            ></i>
          </el-popover>

          <el-popover
            placement="top-start"
            title=""
            width="200"
            effect="dark"
            trigger="hover"
          >
            <p>退货入库且产品类型为不可用库存数量</p>
            <i
              class="el-icon-question"
              slot="reference"
              v-if="item.prop == 'unusableNum'"
            ></i>
          </el-popover>

          <el-popover
            placement="top-start"
            title=""
            width="200"
            effect="dark"
            trigger="hover"
          >
            <p>产品在当前仓库的入库成本+入库成本调整-出库成本-出库成本调整</p>
            <i
              class="el-icon-question"
              slot="reference"
              v-if="item.prop == 'totalPrice'"
            ></i>
          </el-popover>

          <el-popover
            placement="top-start"
            title=""
            width="200"
            effect="dark"
            trigger="hover"
          >
            <div>
              <p>
                1）产品在当前仓库的实时平均库存单价；此数据仅供参考，不算作出库单价；
              </p>
              <p>2）库存单价=库存成本/库存数量</p>
            </div>
            <i
              class="el-icon-question"
              slot="reference"
              v-if="item.prop == 'price'"
            ></i>
          </el-popover>
        </template>
        <template #default="{ row }">
          <!-- 产品图片-放大 -->
          <el-row v-if="item.prop === 'productImage'">
            <el-image
              v-if="row.productImage"
              :src="row.productImage"
              style="width: 50px; height: 50px"
              class="c_pointer"
              @click="handlePreview(row.productImage)"
            />
            <span v-else>--</span>
          </el-row>

          <!-- 产品编码-跳转 -->
          <el-row v-else-if="item.prop === 'skuId'">
            <span v-if="row.skuId.includes('FL')">
              {{ row.skuId }}
            </span>
            <span v-else class="link" @click="skuIdClick(row.skuId)">
              {{ row.skuId }}
            </span>
          </el-row>

          <!-- 产品规格 -->
          <el-row v-else-if="item.prop === 'productSpec'" class="row-over">
            <span>
              {{ $hasChineseChar(row.productSpec) }}
            </span>
          </el-row>
          <el-row v-else-if="item.prop === 'productSpecCn'" class="row-over">
            <span>
              {{ row.productSpecCn || '--' }}
            </span>
          </el-row>

          <!-- 单位 -->
          <el-row v-else-if="item.prop === 'productUnit'" class="row-over">
            <span>
              {{ row.productUnit || '--' }}
            </span>
          </el-row>

          <!-- 仓库 -->
          <el-row
            v-else-if="item.prop === 'warehouseNumberVOList'"
            class="over-row"
          >
            <p
              class="d_block"
              :class="{ link: wrehouseDimensionView != 1 }"
              v-for="(warehouse, warehouseIndex) of row.warehouseNumberVOList"
              :key="warehouseIndex"
              v-show="warehouseIndex <= 1"
              @click="warehouseNumberVOListClcik(row.skuId)"
            >
              {{ warehouse.warehouseName }}
              <span v-if="wrehouseDimensionView !== 1">
                ：{{ warehouse.num }}
              </span>
            </p>
            <p class="link" v-show="row.warehouseNumberVOList.length > 2">
              ...
            </p>
          </el-row>

          <!-- 采购在途 -->
          <el-row v-else-if="item.prop === 'purchaseOrderNum'">
            <el-popover
              placement="bottom"
              trigger="click"
              v-if="wrehouseDimensionView !== 1"
            >
              <el-table
                :data="caigouzaituData"
                border
                class="base-table-height"
              >
                <el-table-column
                  prop="number"
                  width="150"
                  label="采购单号"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.number"
                      class="link"
                      @click="purchaseOrderNumNumberClick(scope.row.number)"
                    >
                      {{ scope.row.number }}
                    </span>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="numPurchase"
                  label="采购数量"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.numPurchase || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="buyerName"
                  label="采购开发"
                  align="center"
                  width="120"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.buyerName || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="actualDeliveryDate"
                  label="交货日期"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    {{ scope.row.actualDeliveryDate || '--' }}
                  </template>
                </el-table-column>
              </el-table>
              <span
                slot="reference"
                :class="[row.purchaseOrderNum ? 'link' : '']"
                @click="caigouzaituClick(row.skuId)"
              >
                {{ row.purchaseOrderNum }}
              </span>
            </el-popover>
            <span v-else>--</span>
          </el-row>

          <!-- 锁定量 -->
          <el-row v-else-if="item.prop === 'lockNum'">
            <el-popover
              placement="bottom"
              trigger="click"
              v-if="wrehouseDimensionView == 0"
            >
              <el-table
                :data="suodingliangData"
                border
                class="base-table-height"
              >
                <el-table-column
                  prop="salesOrderNumber"
                  width="150"
                  label="销售单号"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span
                      class="link"
                      v-if="scope.row.salesOrderNumber"
                      @click="orderCodeClick(scope.row.salesOrderNumber)"
                    >
                      {{ scope.row.salesOrderNumber }}
                    </span>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="warehouseName"
                  label="仓库"
                  align="center"
                  show-overflow-tooltip
                  width="120"
                >
                  <template slot-scope="scope">
                    {{ scope.row.warehouseName || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="warehouseName"
                  label="入库批次"
                  align="center"
                  show-overflow-tooltip
                  width="120"
                >
                  <template slot-scope="scope">
                    {{ scope.row.lotNo || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="num"
                  label="锁定量"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.num || '--' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="remainLockTime"
                  label="剩余锁定时长"
                  align="center"
                  show-overflow-tooltip
                  width="150"
                >
                  <template slot-scope="scope">
                    {{ scope.row.remainLockTime || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createName"
                  label="锁定人"
                  align="center"
                  width="150"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.createName || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="lockTime"
                  label="锁定时间"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    {{ scope.row.lockTime || '--' }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      content="解锁"
                      placement="top"
                      v-if="scope.row.state"
                    >
                      <el-button
                        type="primary"
                        plain
                        size="mini"
                        icon="iconfont icon-jiesuo"
                        circle
                        @click="unlock(scope.row)"
                      ></el-button>
                    </el-tooltip>
                    <span v-if="!scope.row.state">--</span>
                  </template>
                </el-table-column>
              </el-table>
              <span
                slot="reference"
                :class="[row.lockNum ? 'link' : '']"
                @click="suodingliangClick(row.skuId)"
              >
                {{ row.lockNum }}
              </span>
            </el-popover>
            <span v-else>{{ row.lockNum }}</span>
          </el-row>

          <!-- 待出库 -->
          <el-row v-else-if="item.prop === 'outNum'">
            <el-popover
              placement="bottom"
              trigger="click"
              v-if="wrehouseDimensionView == 0"
            >
              <el-table :data="daichukuData" border class="base-table-height">
                <el-table-column
                  prop="warehouseOutNumber"
                  width="150"
                  label="出库单号"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.warehouseOutNumber"
                      class="link"
                      @click="warehouseOutNumberClick(scope.row.warehouseOutId)"
                    >
                      {{ scope.row.warehouseOutNumber }}
                    </span>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="num"
                  label="出库数量"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.num || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="warehouseName"
                  label="仓库"
                  align="center"
                  width="130"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.warehouseName || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="creator"
                  label="创建人"
                  align="center"
                  width="130"
                >
                  <template slot-scope="scope">
                    {{ scope.row.creator || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    {{ scope.row.createTime || '--' }}
                  </template>
                </el-table-column>
              </el-table>
              <span
                slot="reference"
                :class="[row.outNum ? 'link' : '']"
                @click="daichukuClick(row.skuId)"
              >
                {{ row.outNum }}
              </span>
            </el-popover>
            <span v-else>{{ row.outNum }}</span>
          </el-row>

          <!-- 不可用库存 -->
          <el-row v-else-if="item.prop === 'unusableNum'">
            <el-popover
              placement="bottom"
              trigger="click"
              v-if="wrehouseDimensionView == 0"
            >
              <el-table
                :data="bukeyongkucunData"
                border
                :key="customTableKey"
                class="base-table-height"
              >
                <el-table-column
                  property=""
                  width="150"
                  label="入库单号"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span
                      class="link"
                      v-if="scope.row.warehouseInNumber"
                      @click="warehouseInNumberClick(scope.row.warehouseInId)"
                    >
                      {{ scope.row.warehouseInNumber }}
                    </span>
                    <span v-else>{{ scope.row.warehouseInNumber }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="orderNumber"
                  label="销售单号"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    <span
                      class="link"
                      v-if="scope.row.orderNumber"
                      @click="orderCodeClick(scope.row.orderNumber)"
                    >
                      {{ scope.row.orderNumber }}
                    </span>
                    <span v-else>{{ scope.row.orderNumber }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="num"
                  label="退货数量"
                  align="center"
                  width="200"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.num || '--' }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="businessName"
                  label="客户代表"
                  align="center"
                  show-overflow-tooltip
                  width="130"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.businessName || '--' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="customerName"
                  label="客户名称"
                  align="center"
                  width="150"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.customerName || '--' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="warehouseInDate"
                  label="入库时间"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.warehouseInDate || '--' }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <span
                slot="reference"
                :class="[row.unusableNum ? 'link' : '']"
                @click="bukeyongkucunClick(row.skuId)"
              >
                {{ row.unusableNum }}
              </span>
            </el-popover>
            <span v-else>{{ row.unusableNum }}</span>
          </el-row>

          <!-- 库存成本 -->
          <el-row v-else-if="item.prop === 'totalPrice'">
            <BaseStar v-if="row.flag === 0" />
            <template v-else>{{ toFixed3(row.totalPrice) }}</template>
          </el-row>

          <!-- 库存单价 -->
          <el-row v-else-if="item.prop === 'price'">
            <BaseStar v-if="row.flag === 0" />
            <template v-else>{{ toFixed3(row.price) }}</template>
          </el-row>

          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" fixed="right" width="100">
        <template slot-scope="scope">
          <el-tooltip content="库存流水" placement="top">
            <el-button
              type="primary"
              plain
              circle
              size="mini"
              @click="turnoverClick(scope.row)"
            >
              <i class="iconfont el-icon-tickets"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 修改库存弹窗 -->
    <el-dialog
      :visible.sync="showDialog"
      title="修改库存"
      width="420px"
      append-to-body
      @close="onClose"
      modal-append-to-body
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="50px"
      >
        <el-form-item
          label="数量"
          prop="num"
          class="check-num"
          :status-icon="false"
        >
          <el-input
            clearable
            v-model.number="ruleForm.num"
            placeholder="不能输入0,可输入负数和正数"
            style="width: 330px; text-align: left"
          ></el-input>
        </el-form-item>

        <el-form-item label="" class="conut-form-item">
          当前库存数量:
          {{ num * 1 + (isNaN(ruleForm.num) ? 0 : ruleForm.num * 1) }}
        </el-form-item>
      </el-form>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" @click="onSure">确 认</el-button>
        <el-button @click="onClose">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 库存流水 -->
    <ProTurnoverModel ref="ProTurnoverModel" />

    <!-- 仓库详情 -->
    <ProWarehouseNameModel ref="ProWarehouseNameModel" />

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[imgUrl]"
    />
    <!-- 解锁锁定量 -->
    <unlock-store ref="unlock-store"></unlock-store>
  </div>
</template>

<script>
  import { storeManage } from '@/core/interactors/stock-center/stock-manage'
  import StockInteractor from '@/core/interactors/stock-center/stock'
  import Pagination from '@/components/Pagination/Index'
  import BaseStar from '@/components/base-star/base-star'
  import ProTurnoverModel from './components/pro-turnover-model.vue'
  import ProWarehouseNameModel from './components/pro-warehouseName-model.vue'
  import CustomCache from '@/utils/custom-cache'
  import customTableMixin from '@/utils/custom-table-mixin'
  import unlockStore from './components/unlockStore.vue'
  import ErpDraggable from 'vuedraggable'
  import tooltipOver from '@/components/base-tooltip'
  import { mapGetters } from 'vuex'
  let self,
    MAX_COUNT = 99999999
  export default {
    components: {
      Pagination,
      BaseStar,
      ProTurnoverModel,
      ErpDraggable,
      ProWarehouseNameModel,
      tooltipOver,
      unlockStore,
    },
    mixins: [customTableMixin],
    data() {
      self = this
      return {
        locList: [],
        showDialog: false,
        num: 0,
        activeName: '0',
        form: {
          isShow: 0,
          warehouseId: '', //仓库区域
          param: '', //产品编码、产品名称、产品规格、仓库名称
        },
        ruleForm: {
          num: '',
        },
        giftForm: {
          proType: '', //产品类型
          param: '', //产品编码、产品名称、产品规格、仓库名称
        },
        areas: ['合肥', '义乌', '苏州'],
        proTypes: ['标准品', '订制品'],
        giftPage: {
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        tableData: [],
        rules: {
          num: [
            {
              required: true,
              message: '请输入数量',
              trigger: 'blur',
            },
            { type: 'number', message: '数量必须为数字值', trigger: 'blur' },
            {
              trigger: 'blur',
              validator: (rule, value, callback) => {
                value = value * 1
                if (value === 0) {
                  return callback(new Error('数量不能为0'))
                }

                if (value < self.minCount) {
                  return callback(new Error(`数量不能小于${self.minCount}`))
                }
                if (value > MAX_COUNT) {
                  return callback(new Error(`数量不能大于${MAX_COUNT}`))
                }
                callback()
              },
            },
          ],
        },
        checked: true,
        columns: [
          {
            label: '产品图片',
            prop: 'productImage',
            width: '120',
            isShow: true,
            fixed: 'left',
          },
          {
            label: '产品编码',
            prop: 'skuId',
            isShow: true,
            checkable: false,
          },
          {
            label: '产品名称',
            prop: 'productName',
            isShow: true,
          },
          {
            label: '产品类型',
            prop: 'productType',
            isShow: true,
          },
          {
            label: '英文规格',
            prop: 'productSpec',
            isShow: true,
          },
          {
            label: '中文规格',
            prop: 'productSpecCn',
            isShow: true,
          },
          {
            label: '单位',
            prop: 'productUnit',
            isShow: true,
          },
          {
            label: '仓库',
            prop: 'warehouseNumberVOList',
            isShow: true,
            width: '200',
          },
          {
            label: '库存数量',
            prop: 'num',
            isShow: true,
          },
          {
            label: '采购在途',
            prop: 'purchaseOrderNum',
            isShow: true,
          },
          {
            label: '锁定量',
            prop: 'lockNum',
            isShow: true,
          },
          {
            label: '待出库',
            prop: 'outNum',
            isShow: true,
          },
          {
            label: '可用库存',
            prop: 'usableNum',
            isShow: true,
          },
          {
            label: '不可用库存',
            prop: 'unusableNum',
            isShow: true,
          },
          {
            label: '库存成本',
            prop: 'totalPrice',
            isShow: true,
          },
          {
            label: '库存单价',
            prop: 'price',
            isShow: true,
          },
        ],
        pageNo: 1,
        pageLe: 10,
        total: 0,
        page: {
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        caigouzaituData: [], //采购在途data
        suodingliangData: [], //锁定量data
        bukeyongkucunData: [], //不可用库存data
        daichukuData: [], //待出库data
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        userCustomizeColumnId: '', //自义定保存id
        wrehouseDimensionView: 0, //仓库维度查看
        noVirtualInventory: 1, // 不显示虚拟库存
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      minCount() {
        return this.num * -1
      },
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },

    watch: {
      activeName(newval) {
        newval === '0' ? this.onSearch() : this.onGiftSearch()
      },
    },
    filters: {
      toFixed2(val) {
        if (val || val === 0) {
          return (val * 1).toFixed(2)
        } else {
          return '--'
        }
      },
    },
    async created() {
      //获取自定义保存
      CustomCache.getColumns(52, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })

      //仓库下拉数据
      this.getFindWarehouseList2()
      // 获取配置数据
      await this.getUserChecked()

      this.onSearch()
    },
    methods: {
      // 获取勾选项
      async getUserChecked() {
        const res = await StockInteractor.getUserFilterConditions()
        if (res && res.code === '000000') {
          if (res?.data?.data.length > 0) {
            const config = res.data.data[res.data.data.length - 1]
            const configFields = [
              'noVirtualInventory',
              'stockLookConfigId',
              'wrehouseDimensionView',
            ]
            configFields.forEach((item) => {
              this[item] = config[item]
            })
          }
          return
        }
      },

      // 用户勾选项改变
      async changeUserChecked() {
        // 获取
        this.onButtonSearch()

        // 保存
        const { wrehouseDimensionView, noVirtualInventory, stockLookConfigId } =
          this //仓库维度查看

        await StockInteractor.saveUserFilterConditions({
          wrehouseDimensionView,
          noVirtualInventory,
          stockLookConfigId,
        })
        this.getUserChecked()
      },

      // 仓库维度
      getListFromWrehouseDimensionView() {
        StockInteractor.getListFromWrehouseDimensionView()
      },

      //仓库下拉数据
      async getFindWarehouseList2() {
        const response = await storeManage.findWarehouseList2()

        if (response?.code === '000000') {
          this.areas = response.data
        }
      },

      //产品库存列表
      async onSearch() {
        const { isShow, warehouseId, param } = this.form,
          { pageLe, pageNo } = this.page
        const { noVirtualInventory, wrehouseDimensionView } = this
        const requestParams = {
          isShow,
          warehouseId,
          param,
          pageLe,
          pageNo,
          noVirtualInventory,
          wrehouseDimensionView,
        }
        const res = await storeManage.listPage(requestParams)

        if (res?.code === '000000') {
          this.tableData = res.data.data || []
          this.page.total = res.data.total
        }
      },

      //自定义保存
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 52,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res?.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      //产品编码click
      skuIdClick(skuId) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        const response = await storeManage.productIdByCode(id)

        if (response?.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      //仓库点击详情
      warehouseNumberVOListClcik(id) {
        if (this.wrehouseDimensionView == 1) {
          return
        }
        this.$refs['ProWarehouseNameModel'].showAddEdit(id)
      },

      //采购在途click
      async caigouzaituClick(skuId) {
        this.caigouzaituData = []
        const response = await storeManage.inTransitBySku(skuId)

        if (response?.code === '000000') {
          this.caigouzaituData = response.data
        }
      },

      //采购在途-采购单号跳转
      async purchaseOrderNumNumberClick(code) {
        // 采购订单号跳转
        let response = await storeManage.dinggetDetailForShow(code)
        let data = response.data
        if (response?.code == '000000') {
          let routeData = this.$router.resolve({
            path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
            query: {
              id: data.purchaseOrderId,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      //锁定量click
      async suodingliangClick(skuId) {
        this.suodingliangData = []
        const response = await storeManage.stockLockInfo(skuId)

        if (response?.code === '000000') {
          this.suodingliangData = response.data.map((item) => {
            return {
              ...item,
              state: this.userInfo.userId == item.creatorId ? true : false,
            }
          })
        }
      },

      //锁定量/不可用库存-销售单号跳转
      orderCodeClick(code) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode: code, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },

      //不可用库存click
      async bukeyongkucunClick(skuId) {
        this.bukeyongkucunData = []
        const response = await storeManage.warehouseInUnusableDetail(skuId)

        if (response?.code === '000000') {
          this.bukeyongkucunData = response.data
        }
      },

      //不可用库存-入库单号跳转
      warehouseInNumberClick(id) {
        let routeData = this.$router.resolve({
          path: '/stock-center/inbound-sheet-detail',
          query: { warehouseInId: id, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },

      //待出库click
      async daichukuClick(skuId) {
        this.daichukuData = []
        const response = await storeManage.warehouseOutForStockDetail(skuId)

        if (response?.code === '000000') {
          this.daichukuData = response.data
        }
      },

      //待出库-出库单号跳转
      warehouseOutNumberClick(id) {
        let routeData = this.$router.resolve({
          path: '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail',
          query: { warehouseOutId: id, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },

      //库存流水click
      turnoverClick(row) {
        this.$refs['ProTurnoverModel'].showAddEdit(row, {
          wrehouseDimensionView: this.wrehouseDimensionView, //1仓库维度查看
        })
      },

      // 大图预览
      handlePreview(item) {
        this.imgUrl = item
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      show({ skuId, stockGiftId, num }) {
        this.stockGiftId = stockGiftId
        this.num = num
        this.showDialog = true
      },

      onSure() {
        this.$refs.ruleForm.validate(async (flag) => {
          if (flag) {
            const { reason, num } = this.ruleForm,
              stockGiftId = this.stockGiftId
            const res = await storeManage.updateStockGiftNum({
              reason,
              num,
              stockGiftId,
            })

            if (res && res.code === '000000') {
              this.$message.success('修改库存成功')
              this.onClose()
              this.onGiftSearch()
            }
          }
        })
      },

      onClose() {
        this.ruleForm.num = ''
        this.ruleForm.reason = ''
        this.showDialog = false
        this.$refs.ruleForm.clearValidate(['num', 'reason'])
      },

      async onGiftSearch() {
        const { proType, param } = this.giftForm,
          { pageLe, pageNo } = this.giftPage
        const requestParams = {
          proType,
          param,
          pageLe,
          pageNo,
        }
        const res = await storeManage.stockGiftListPage(requestParams)

        if (res?.code === '000000') {
          this.tableData = res.data.data || []
          this.giftPage.total = res.data.total
        }
      },

      onButtonSearch() {
        this.page.pageNo = 1
        this.onSearch()
      },

      onReset() {
        this.form.warehouseId = ''
        this.form.param = ''
        this.form.isShow = 0
        this.page.pageLe = 10
        this.page.pageNo = 1
        this.onSearch()
      },

      unlock(val) {
        this.$refs['unlock-store'].showDialog()
        this.$refs['unlock-store'].unlockData = { ...val }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .line {
    height: 2px;
    background-color: #e4e7ed;
  }
  .conut-form-item {
    position: relative;
    top: -10px;
  }
  .row-over {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }
  .base-table-height {
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
  }
  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 15px !important;
    text-align: left;
  }
  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }
  .link {
    color: #1890ff;
    cursor: pointer;
  }
  ::v-deep .check-num .el-icon-circle-close {
    display: none;
  }
  .over-row {
    width: 100%;
    overflow: hidden;
    padding: 0 5px;
    text-overflow: ellipsis;
  }
  .text-left {
    color: #1890ff;
  }
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
