<template>
  <div>
    <!-- 查询 -->
    <CompanyDetailSearch
      :serchForm="serchForm"
      @serchForm="search"
      :code="code"
      v-if="[4, 5, 6, 8, 9, 10, 11, 12, 13].includes(Number(code))"
    ></CompanyDetailSearch>
    <ErpTable
      :page-number.sync="pageNo"
      :page-size.sync="pageLe"
      :table-data="tableData"
      :total="total"
      @query="query"
      @row-click="rowClick"
      class="table-fixed"
      :key="tableKey"
      ref="erpTable"
    >
      <el-table-column
        align="center"
        type="index"
        width="60"
        :label="lang === 'en' ? 'No.' : '序号'"
      />
      <!-- :label="$t(item.langKey)" -->
      <el-table-column
        v-for="(item, index) in finallyColumn"
        :key="index"
        :label="
          [1, 4, 14].includes(Number(code)) ? $t(item.langKey) : item.label
        "
        show-overflow-tooltip
        :resizable="false"
        align="center"
        :min-width="lang === 'en' ? item.enWidth : item.width"
        :width="item.width"
        :prop="item.prop"
      >
        <template
          #default="{ row }"
          v-if="item.prop == 'customerRank' && code == 1"
        >
          <el-rate
            v-model="row.customerRank"
            disabled
            text-color="#ff9900"
          ></el-rate>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'allChargeCn'">
          {{ lang == 'zh' ? row.allChargeCn || '--' : row.allChargeEn || '--' }}
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'linkman'">
          <span style="white-space: pre">{{ row.linkman }}</span>
        </template>
        <template #default="{ row }" v-else-if="item.prop == 'customerName'">
          <span style="white-space: pre">{{ row.customerName }}</span>
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'salesman' && code == 4"
        >
          {{ lang == 'zh' ? row.salesman || '--' : row.salesmanEn || '--' }}
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'releatedCustomer' && code == 4"
        >
          {{
            lang == 'zh'
              ? row.releatedCustomer || '--'
              : row.releatedCustomerEn || '--'
          }}
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'creator' && code == 4"
        >
          {{ lang == 'zh' ? row.creator || '--' : row.creatorEn || '--' }}
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'belongerName' && code == 1"
        >
          {{
            lang == 'zh' ? row.belongerName || '--' : row.belongerNameEn || '--'
          }}
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'creator' && code == 14"
        >
          {{ lang == 'zh' ? row.creator || '--' : row.creatorEn || '--' }}
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'orderCode' && code == 5"
        >
          <span class="page-link" @click="goOrderDetail(row.orderCode)">
            {{ row.orderCode }}
          </span>
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'businessNumber' && code == 10"
        >
          <span class="page-link" @click="goOrderDetail(row.businessNumber)">
            {{ row.businessNumber }}
          </span>
        </template>
        <template
          #default="{ row }"
          v-else-if="item.prop == 'salesOrder' && code == 12"
        >
          <span class="page-link" @click="goOrderDetail(row.salesOrder)">
            {{ row.salesOrder }}
          </span>
        </template>
        <template v-else #default="{ row }">
          <span>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
    </ErpTable>
    <div class="mt20" v-if="[5, 6, 13].includes(Number(code))">
      <detail-statistics
        :infoId="infoId"
        :companyId="companyId"
        :code="code"
        type="company"
        :isInit.sync="refresh"
        :searchData="requestForm"
      ></detail-statistics>
    </div>
    <div class="mt20" v-if="code == 4">
      <ReplayRecord :tableData="currentHistory"></ReplayRecord>
    </div>
  </div>
</template>
<script>
  import {
    customerColumns,
    PayMethodColumns,
    shipAddressColumns,
    EvaluateColumns,
    orderColumns,
    inquiryColumns,
    quoteColumns,
    productColumns,
    shipmentColumns,
    followRecordColumns,
    surplusColumns,
    customerGradeColumns,
    remarksColumns,
    logColumns,
    quoteSearch,
    productSearch,
    shipmentSearch,
    surplusSearch,
    inquirySearch,
    customerGradeSearch,
    evaluateSearch,
    orderSearch,
    followRecordSearch,
  } from '../helper/detailBaseCompany'
  import CompanyDetailSearch from './company-detail-search.vue'
  import { mapGetters } from 'vuex'
  import CompanyDetailListInteractor from '@/core/interactors/new-customer-manage/detail'
  import CustomerCenterInteractor from '@/core/interactors/customer-manage/customer-center'
  import DetailStatistics from './detail-statistics'
  import ReplayRecord from '@/views/customer-manage/customer-center/components/replay-record'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'

  export default {
    components: {
      CompanyDetailSearch,
      DetailStatistics,
      ReplayRecord,
    },
    data() {
      return {
        columns: customerColumns,
        tableData: [],
        pageLe: 10,
        pageNo: 1,
        total: 0,
        tableKey: Math.random(),
        requestForm: {},
        currentHistory: [],
        refresh: false,
      }
    },
    props: {
      code: {
        type: [String, Number],
        default: '1',
      },
      infoId: {
        type: String,
        default: '',
      },
      companyId: {
        default: '',
        type: String,
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      finallyColumn() {
        this.tableKey = Math.random()
        let obj = {
          1: customerColumns,
          2: PayMethodColumns,
          3: shipAddressColumns,
          4: EvaluateColumns,
          5: orderColumns,
          6: inquiryColumns,
          8: quoteColumns,
          9: productColumns,
          10: shipmentColumns,
          11: followRecordColumns,
          12: surplusColumns,
          13: customerGradeColumns,
          14: remarksColumns,
          15: logColumns,
        }
        return obj[this.code]
      },
      serchForm() {
        let obj = {
          4: evaluateSearch,
          5: orderSearch,
          6: inquirySearch,
          8: quoteSearch,
          9: productSearch,
          10: shipmentSearch,
          11: followRecordSearch,
          12: surplusSearch,
          13: customerGradeSearch,
        }
        return obj[this.code]
      },
      interactors() {
        let obj = {
          1: 'getCustomerListAll', // 客户信息
          2: 'getPayWay', // 付款方式
          3: 'getAddressList', // 收货
          4: 'getCustomerComplain', // 评价
          5: 'getSalesOrder', // 订单
          6: 'getEnquiry', // 询盘
          8: 'getOrderEnquiry',
          9: 'getOrderProduct', // 产品
          10: 'getShipping', // 出运
          11: 'getFollowRecords', // 跟进记录
          12: 'getCustomerAccountBalance', // 余额
          13: 'getCustomerScoreRecord', // 积分成长记录
          14: 'getCustomerRemark', // 备注
          15: 'getListOperationLog', // 操作日志
        }
        return obj[this.code]
      },
    },
    watch: {},
    mounted() {
      this.requestForm = {}
      this.search()
    },
    methods: {
      query() {
        this.search(this.requestForm, this.pageLe, this.pageNo)
      },
      async search(val = {}, pageLe = 10, pageNo = 1) {
        this.pageLe = pageLe
        this.pageNo = pageNo
        if (['5', '6'].indexOf(this.code) > -1) {
          this.refresh = true
        }
        let dt = {}
        this.requestForm = val
        if (['8', '9', '10', '11'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoIds: this.infoId.split(','),
          }
        }

        if (['12'].indexOf(this.code) > -1) {
          dt = {
            customerId: this.infoId,
            pageLe: pageLe,
            pageNo: pageNo,
          }
        }
        if (['2', '3'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            companyId: this.companyId,
          }
        }

        if (['5', '6'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoIdList: this.infoId.split(','),
          }
        }
        if (['14'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoId: this.infoId,
            companyId: this.companyId,
            queryType: 1,
          }
        }
        if (['13'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            companyId: this.companyId,
            infoId: this.infoId,
          }
        }
        if (['4'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            customerIdList: this.infoId.split(','),
            sourceType: 1,
          }
        }
        if (['1', '15'].indexOf(this.code) > -1) {
          dt = {
            pageLe: pageLe,
            pageNo: pageNo,
            orderBy: 'id desc',
            infoIds: this.infoId,
            companyId: this.companyId,
          }
        }
        if (this.code == 11 && val.createTime) {
          this.$set(val, 'startTime', val?.createTime[0])
          this.$set(val, 'endTime', val?.createTime[1])
        }
        if (!val.createTime || val.createTime.length == 0) {
          this.$set(val, 'startTime', '')
          this.$set(val, 'endTime', '')
        }

        let params = { ...val, ...dt }
        let res = await CompanyDetailListInteractor[this.interactors](params)
        this.tableData = res.data.data
        this.total = res.data.total
      },
      async rowClick(row) {
        if (this.code == 4) {
          let res = await CustomerCenterInteractor.getCustomerComplainRecords(
            row.customerComplainId
          )
          this.currentHistory = res.data
        }
      },
      // 跳转详情
      goOrderDetail(code) {
        navigateTo(this, {
          pageType: PAGE_TYPES.salesOrderDetail,
          roleJudge: false,
          orderNumber: code,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table-fixed {
    height: 400px !important;
  }
  ::v-deep {
    // .el-table__body-wrapper {
    //   overflow-x: overlay !important;
    //   width: 100% !important;
    // }
    .el-table--border th.gutter:last-of-type {
      display: block !important;
      width: 17px !important;
    }
  }
</style>
