var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.planOrderVisible)?_c('el-dialog',{staticClass:"plan-order",attrs:{"title":"安排订单","visible":_vm.planOrderVisible,"before-close":_vm.close,"width":"1400px"},on:{"update:visible":function($event){_vm.planOrderVisible=$event}}},[_c('div',{staticClass:"container"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('div',{staticClass:"mb-10"},[_vm._v(" 是否以当前时间自动顺延计划装运日期？ "),(!_vm.isDelayed)?_c('el-button',{staticClass:"ml-10 mr-10",staticStyle:{"background-color":"#169bd5"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.confirm}},[_vm._v(" 确定 ")]):_vm._e(),(_vm.isDelayed)?_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.reset}},[_vm._v(" 恢复 ")]):_vm._e()],1),_c('div',{staticClass:"c-table-wrap"},[_c('el-table',{attrs:{"data":_vm.form.tableData,"span-method":function () {
                var rest = [], len = arguments.length;
                while ( len-- ) rest[ len ] = arguments[ len ];

                return _vm.mergeRowByProp.apply(void 0, rest.concat( [_vm.form.tableData], [_vm.mergeList] ));
},"border":"","max-height":"500"}},[_c('el-table-column',{attrs:{"prop":"orderCode","label":"订单编号","min-width":"160","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"Item","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.item && row.item.includes('****') ? row.item.split('****')[1] : '--')+" ")]}}],null,false,3454437925)}),_c('el-table-column',{attrs:{"label":"英文规格","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.specification || '/')+" ")]}}],null,false,799746893)}),_c('el-table-column',{attrs:{"label":"中文规格","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.specificationCn || '/')+" ")]}}],null,false,3981448896)}),_c('el-table-column',{attrs:{"label":"Qty","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.amount || '/')+" ")]}}],null,false,968378652)}),_c('el-table-column',{attrs:{"label":"印刷方式","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.printingWayEn || '--')+" ")]}}],null,false,2082236266)}),_c('el-table-column',{attrs:{"label":"计价方式","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s((row.productExtend && row.productExtend.printingPositionValue) || '--')+" ")]}}],null,false,2883246903)}),_c('el-table-column',{attrs:{"label":"印刷位置","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s((row.productExtend && row.productExtend.valuationMethodValue) || '--')+" ")]}}],null,false,565988723)}),_c('el-table-column',{attrs:{"align":"center","prop":"planShippingDate","label":_vm.$t('orderList.plannedShipmentDate'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' +
                  [scope.$index] +
                  '.productExtend.planShippingDate',"rules":[
                  {
                    required: true,
                    message: _vm.$t('placeholder.plsInput'),
                    trigger: 'blur',
                  } ]}},[_c('CalculatedDatePicker',{attrs:{"type":"date","calc-type":"planShippingDate","product-info":_vm.form.tableData[scope.$index],"address-list":scope.row.addressList,"quant-type":scope.row.quantType,"placeholder":_vm.$t('placeholder.plsSel'),"picker-options":{
                    disabledDate: function (time) {
                      return (
                        time >
                        new Date(
                          new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                        )
                      )
                    },
                  },"clearable":""},model:{value:(
                    _vm.form.tableData[scope.$index].productExtend
                      .planShippingDate
                  ),callback:function ($$v) {_vm.$set(_vm.form.tableData[scope.$index].productExtend
                      , "planShippingDate", $$v)},expression:"\n                    form.tableData[scope.$index].productExtend\n                      .planShippingDate\n                  "}})],1)]}}],null,false,3798742827)},[_c('template',{slot:"header"},[_c('PlannedShipmentDate')],1)],2),_c('el-table-column',{staticClass:"required-th",attrs:{"align":"center","prop":"shippingMethod","label":_vm.$t('orderDetails.ShippingMethod'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'tableData.' +
                  [scope.$index] +
                  '.productExtend.shippingMethodId',"rules":[
                  {
                    required: true,
                    message: _vm.$t('placeholder.plsSelect'),
                    trigger: 'blur',
                  } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.plsSel')},on:{"change":function($event){return _vm.changeShipMethod(
                      scope.row,
                      scope.row.productExtend.shippingMethodId
                    )}},model:{value:(scope.row.productExtend.shippingMethodId),callback:function ($$v) {_vm.$set(scope.row.productExtend, "shippingMethodId", $$v)},expression:"scope.row.productExtend.shippingMethodId"}},_vm._l((_vm.shippingMethodList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.transportWayCn,"value":item.id}})}),1),(scope.row.shippingDelay === 1)?_c('p',{staticClass:"red-pink"},[_vm._v(" 可能错过 Firm 交期 ")]):_vm._e()],1)]}}],null,false,2295819517)},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('span',[_vm._v(_vm._s(_vm.$t('orderDetails.ShippingMethod')))])])],2),_c('el-table-column',{attrs:{"min-width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{staticStyle:{"margin-top":"33px"},attrs:{"prop":'tableData.' +
                  [scope.$index] +
                  '.productExtend.customerDeliveryDate',"rules":[
                  {
                    required: true,
                    message: _vm.$t('placeholder.plsInput'),
                    trigger: 'blur',
                  } ]}},[_c('div',[_c('CalculatedDatePicker',{attrs:{"type":"date","checkDeliveryDate":"","product-info":_vm.form.tableData[scope.$index],"address-list":scope.row.addressList,"quant-type":scope.row.quantType,"disabled":_vm.form.tableData[scope.$index].productExtend.firmFlag ===
                      1,"placeholder":_vm.$t('placeholder.plsSel'),"picker-options":{
                      disabledDate: function (time) {
                        return (
                          time >
                          new Date(
                            new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                          )
                        )
                      },
                    },"clearable":""},on:{"calc-delivery":_vm.calcDelivery},model:{value:(
                      _vm.form.tableData[scope.$index].productExtend
                        .customerDeliveryDate
                    ),callback:function ($$v) {_vm.$set(_vm.form.tableData[scope.$index].productExtend
                        , "customerDeliveryDate", $$v)},expression:"\n                      form.tableData[scope.$index].productExtend\n                        .customerDeliveryDate\n                    "}}),_c('p',{staticClass:"text-left"},[_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},model:{value:(
                        _vm.form.tableData[scope.$index].productExtend.firmFlag
                      ),callback:function ($$v) {_vm.$set(_vm.form.tableData[scope.$index].productExtend, "firmFlag", $$v)},expression:"\n                        form.tableData[scope.$index].productExtend.firmFlag\n                      "}},[_vm._v(" Firm交期 ")])],1)],1)])]}}],null,false,1325817703)},[_c('template',{slot:"header"},[_c('CustomerDeliveryTime')],1)],2),_c('el-table-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" 采购开发 ")]},proxy:true},{key:"default",fn:function(ref){
                      var row = ref.row;
                      var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'tableData[' + $index + '].salesId',"rules":_vm.rules.salesId}},[_c('UserSelect',{attrs:{"roleName":"采购员","defaultUser":{
                    userName: row.salesName,
                    englishName: row.salesNameEn,
                    userId: row.salesId,
                  }},on:{"user-choose":function (user) { return _vm.handleUserChoose(user, row); }}})],1)]}}],null,false,2077931356)}),_c('el-table-column',{attrs:{"prop":"id","label":"订单备注","min-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
                  var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'tableData[' + $index + '].remark',"rules":_vm.rules.remark}},[_c('el-input',{attrs:{"placeholder":"请输入","clearable":""},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})],1)]}}],null,false,282882631)})],1),_c('el-checkbox',{staticClass:"mt10",model:{value:(_vm.sendEmailFlag),callback:function ($$v) {_vm.sendEmailFlag=$$v},expression:"sendEmailFlag"}},[_vm._v(" 发送邮件 ")]),_c('p',{staticClass:"mt5 f_s_12",staticStyle:{"color":"#999","margin-left":"23px"}},[_vm._v(" 勾选后，采购订单初次审核通过后，会自动发送“安排生产”邮件给客户 ")])],1)])],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.isLoading ? '加载中' : '确认')+" ")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }