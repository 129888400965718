<!--
  付款单详情组件
    使用场景：
      1. 审核管理-付款管理抽屉
      2. 付款管理-付款单详情弹框
-->
<template>
  <div class="payment-detail-wrapper">
    <el-row :gutter="20">
      <el-col :span="8">
        付款单号：{{ info.number || '--' }}
        <el-tag
          v-if="stateObj.label"
          class="ml10"
          :type="stateObj.type"
          plain
          size="small"
        >
          {{ stateObj.label }}
        </el-tag>
      </el-col>
      <el-col :span="8">
        付款类型：{{ info.entryType == 1 ? '采购退款' : '采购付款' }}
      </el-col>
      <el-col :span="8">
        {{ Number(info.entryType) === 0 ? '付款总额' : '退款总额' }}：
        <div class="flex1">
          <template v-if="!isEmpty(info.amountApplication)">
            <span
              v-text="
                currencySymbol(info.currency) +
                Decimal(info.amountApplication).toFixed(2)
              "
              style="color: #d9001b; font-size: 18px"
              class="mr10"
            />
            <template v-if="info.currency === '人民币'">
              {{
                number_str(Decimal(info.amountApplication).toFixed(2), '人民币')
              }}
            </template>
          </template>
          <template v-else>--</template>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="8">买方：{{ info.purchaser || '--' }}</el-col>
      <el-col :span="16">
        收款账户： {{ info.accountName || '--' }} /
        {{ info.bankName || '--' }} / {{ info.bankAccount || '--' }} /
        {{ info.dutyAccount || '--' }}
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="8">
        供应商：{{ info.supplierName || '--' }}
        <el-tag
          v-if="Number(info.isAgreement) === 1"
          type="danger"
          class="ml10"
        >
          协议
        </el-tag>
      </el-col>
      <el-col :span="8">申请人：{{ info.creator || '--' }}</el-col>
      <el-col :span="8">申请时间：{{ info.createTime || '--' }}</el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="8" class="flex-start">
        <div class="label">备注：</div>
        <ErpTitleTip :content="info.remark || '--'" placement="top" row="3" />
      </el-col>
      <el-col :span="12" class="erp-upload-wrapper flex-start">
        <em>电子回单：</em>
        <router-link
          v-if="info.receiptFile"
          :to="`/preview-file/index?name=${info.number + '.pdf'}&url=${
            info.receiptFile
          }`"
          target="_blank"
        >
          回单附件.PDF
        </router-link>
        <span v-else>--</span>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="12" class="erp-upload-wrapper flex-start">
        <em>附件：</em>
        <!-- 付款管理-审核中 -->
        <ErpUpload
          v-if="isAudit"
          ref="fileRef"
          :uploadParams="{ size: 1024 * 1024 * 20, amount: 10 }"
          @uploadList="$emit('uploadList', $event)"
        />
        <!-- 其他 -->
        <template v-else>
          <ErpUpload
            v-if="info.fileAnnex && info.fileAnnex.length"
            ref="fileRef"
            :uploadParams="{ uploadButton: false }"
          />
          <template v-else>--</template>
        </template>
      </el-col>
    </el-row>
    <el-button class="mt15" type="default" @click="handleExpandAll">
      {{ isAllExpand ? '全部收起' : '全部展开' }}
    </el-button>
    <div
      v-for="(item, index) of info.purchaseOrderListVO"
      :key="index"
      class="list-wrapper"
    >
      <div class="header">
        <div class="order-num">
          <i
            :class="
              item.isExpand
                ? 'el-icon-remove-outline'
                : 'el-icon-circle-plus-outline'
            "
            @click="handleToggleExpand(index)"
          />
          <div class="blue"></div>
          <span style="font-size: 16px">采购订单：</span>
          <span
            class="page-link"
            @click="handleToPurchaseOrderDetail(item.purchaseOrderId)"
          >
            {{ item.number }}
          </span>
          <el-tag class="ml10">{{ item.stateNote }}</el-tag>
        </div>
        <template v-if="item.isExpand">
          <div>
            {{ Number(info.entryType) === 0 ? '申请付款' : '申请退款' }}：
            <span
              v-if="!isEmpty(item.amountApplication)"
              v-text="
                currencySymbol(item.currency) +
                Decimal(item.amountApplication).toFixed(2)
              "
              style="color: #d9001b"
            />
            <template v-else>--</template>
          </div>
          <div>创建人：{{ item.creator || '--' }}</div>
          <div>采购开发：{{ item.buyerName || '--' }}</div>
          <div>订单税率：{{ item.taxRateListStr || '--' }}</div>
        </template>
      </div>
      <template v-if="item.isExpand">
        <div v-if="item.contentList && item.contentList.length" class="tip">
          此采购单存在以下问题，请谨慎付款
        </div>
        <!-- 需要进行单元格合并，合并项：付款方式、预计付款时间、预计付款时间 -->
        <el-table
          :data="item.paymentMethodShowListVO"
          :row-class-name="tableRowClassName"
          :span-method="
            (...rest) =>
              mergeRowByColumn(...rest, item.paymentMethodShowListVO, mergeList)
          "
          border
          class="mt10"
        >
          <el-table-column label="付款方式" align="center">
            <template slot-scope="scope">
              {{ scope.row.paymentNodeNameStr || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="预计付款时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.estimatedTransactionDateStr || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="预计付款金额" align="center">
            <template slot-scope="scope">
              {{ scope.row.amountPayableStr || '--' }}
            </template>
          </el-table-column>
          <!-- 仅采购付款（0）显示，采购退款（1）不显示 -->
          <template v-if="+info.entryType === 0">
            <el-table-column label="是否满足付款条件" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.paymentOrderNumber === info.number">
                  <span
                    v-if="item.contentList && item.contentList.length"
                    style="color: red"
                  >
                    否
                  </span>
                  <span v-else>是</span>
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="未满足原因" align="center">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.paymentOrderNumber === info.number &&
                    item.contentListStr
                  "
                  style="color: red; white-space: pre-line; text-align: left"
                >
                  {{ item.contentListStr }}
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="实际付款金额" align="center">
            <template slot-scope="scope">
              <!-- 0：采购付款 1：采购退款 -->
              <span
                v-if="scope.row.paymentOrderNumber === info.number"
                class="tag"
                :class="+info.entryType === 0 ? 'pay' : 'refund'"
              >
                {{ +info.entryType === 0 ? '本次付款' : '本次退款' }}
              </span>
              <template v-if="!isEmpty(scope.row.amountApply)">
                <!-- 采购退款显示负号 -->
                <template v-if="Number(scope.row.entryType) === 1">-</template>
                <span
                  v-text="
                    currencySymbol(scope.row.currency) +
                    Decimal(scope.row.amountApply).toFixed(2)
                  "
                />
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column label="付款单号" align="center">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.paymentOrderNumber &&
                  scope.row.paymentOrderNumber !== info.number
                "
                class="page-link"
                @click="handlePaymentDetailClick(scope.row)"
              >
                {{ scope.row.paymentOrderNumber }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="付款状态" align="center">
            <template slot-scope="scope">
              <!-- 付款状态参考采购订单详情-付款发票-交易记录表格-交易状态 -->
              <div v-if="scope.row.type == 0" class="tool-tip">
                <span v-if="scope.row.state == 1 || scope.row.state == 2">
                  付款中
                </span>
                <span v-else-if="scope.row.state == 3">
                  <el-tooltip
                    :content="scope.row.rejectReason"
                    placement="top"
                    effect="light"
                  >
                    <div slot="content">
                      <p>驳回人：{{ scope.row.updatorName || '--' }}</p>
                      <p class="mt5">
                        驳回原因：{{ scope.row.rejectReason || '--' }}
                      </p>
                      <p class="mt5">
                        驳回时间：{{ scope.row.updateTime || '--' }}
                      </p>
                    </div>
                    <span>付款驳回</span>
                  </el-tooltip>
                </span>
                <span v-else-if="scope.row.state == 4">付款完成</span>
                <span v-else-if="scope.row.state == 5">银行处理中</span>
                <span v-else>--</span>
                <erp-mouseover-tip v-if="scope.row.bankReturnFlag == 1">
                  <template name="content">
                    <div>银行退票，请联系财务处理</div>
                  </template>
                </erp-mouseover-tip>
              </div>
              <div v-else-if="scope.row.type == 2" class="tool-tip">
                <span v-if="scope.row.state == 1 || scope.row.state == 2">
                  收款中
                </span>
                <span v-else-if="scope.row.state == 3">
                  <el-tooltip
                    :content="scope.row.rejectReason"
                    placement="top"
                    effect="light"
                  >
                    <span>收款驳回</span>
                  </el-tooltip>
                </span>
                <span v-else-if="scope.row.state == 4">收款完成</span>
                <span v-else-if="scope.row.state == 5">银行处理中</span>
                <span v-else>-</span>
                <erp-mouseover-tip v-if="scope.row.bankReturnFlag == 1">
                  <template name="content">
                    <div>银行退票，请联系财务处理</div>
                  </template>
                </erp-mouseover-tip>
              </div>
              <div v-else-if="scope.row.type == 4" class="tool-tip">
                <span v-if="scope.row.state == 1 || scope.row.state == 2">
                  付款中
                </span>
                <span v-else-if="scope.row.state == 3">
                  <el-tooltip
                    :content="scope.row.rejectReason"
                    placement="top"
                    effect="light"
                  >
                    <div slot="content">
                      <p>驳回人：{{ scope.row.updatorName || '--' }}</p>
                      <p class="mt5">
                        驳回原因：{{ scope.row.rejectReason || '--' }}
                      </p>

                      <p class="mt5">
                        驳回时间：{{ scope.row.updateTime || '--' }}
                      </p>
                    </div>
                    <span>付款驳回</span>
                  </el-tooltip>
                </span>
                <span v-else-if="scope.row.state == 4">付款完成</span>
                <span v-else-if="scope.row.state == 5">银行处理中</span>
                <span v-else>--</span>
                <erp-mouseover-tip v-if="scope.row.bankReturnFlag == 1">
                  <template name="content">
                    <div>银行退票，请联系财务处理</div>
                  </template>
                </erp-mouseover-tip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
        <el-row :gutter="20" class="mt20">
          <el-col :span="8">
            采购总额：
            <span
              v-if="!isEmpty(item.amountTotal)"
              v-text="
                currencySymbol(item.currency) +
                Decimal(item.amountTotal).toFixed(2)
              "
            />
            <template v-else>--</template>
          </el-col>
          <el-col :span="8">
            已付金额：
            <span
              v-if="!isEmpty(item.amountPaid)"
              v-text="
                currencySymbol(item.currency) +
                Decimal(item.amountPaid).toFixed(2)
              "
            />
            <template v-else>--</template>
          </el-col>
          <el-col :span="8">
            未付金额：
            <span
              v-if="!isEmpty(item.amountUnPaid)"
              v-text="
                currencySymbol(item.currency) +
                Decimal(item.amountUnPaid).toFixed(2)
              "
            />
            <template v-else>--</template>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <el-col :span="8">采购数量：{{ item.numTotal || '--' }}</el-col>
          <el-col :span="8">入库数量：{{ item.numWarehouseIn || '--' }}</el-col>
          <el-col :span="8">
            入库状态：{{ item.warehouseInStateNote || '--' }}
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <!-- <el-col :span="8">
            验货状态：{{
              (item.inspectionState && item.inspectionState.join('、')) || '--'
            }}
          </el-col> -->
          <el-col :span="16">
            验货结果：{{
              (item.inspectionResult && item.inspectionResult.join('、')) ||
              '--'
            }}
          </el-col>
          <el-col :span="8">
            最后入库时间：{{ item.warehouseInDate || '--' }}
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <el-col :span="8">发货日期：{{ item.shipDate || '--' }}</el-col>
          <el-col :span="12" class="erp-upload-wrapper flex-start">
            <em>发货凭证：</em>
            <ErpUpload
              v-if="item.shipFileList && item.shipFileList.length"
              :ref="`evidenceRef${index}`"
              :uploadParams="{ uploadButton: false }"
            />
            <template v-else>--</template>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <el-col
            :span="item.sealedContract && item.sealedContract.length ? 12 : 8"
            class="erp-upload-wrapper flex-start"
          >
            <em>已盖章合同：</em>
            <ErpUpload
              v-if="item.sealedContract && item.sealedContract.length"
              :ref="`contractRef${index}`"
              :uploadParams="{ uploadButton: false }"
            />
            <template v-else>--</template>
          </el-col>
          <el-col :span="12" class="erp-upload-wrapper flex-start">
            <em>发票凭证：</em>
            <ErpUpload
              v-if="item.invoiceFileList && item.invoiceFileList.length"
              :ref="`invoiceRef${index}`"
              :uploadParams="{ uploadButton: false }"
            />
            <template v-else>--</template>
          </el-col>
        </el-row>
        <div style="font-weight: bold; margin: 20px 0">
          {{
            item.orderShowListVO && item.orderShowListVO.length
              ? '关联销售订单'
              : '备货需求采购，无关联销售订单'
          }}
        </div>
        <el-table
          v-if="item.orderShowListVO && item.orderShowListVO.length"
          :data="item.orderShowListVO"
          row-key="orderCode"
          border
          @expand-change="handleExpandChange"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.expandData" border>
                <el-table-column
                  label="收款方式"
                  align="center"
                  min-width="150"
                >
                  <template slot-scope="{ row }">
                    {{ row.typeMemo || '--' }}
                  </template>
                </el-table-column>
                <el-table-column label="预计收款时间" align="center">
                  <template slot-scope="{ row }">
                    {{ row.transactionDate || '--' }}
                  </template>
                </el-table-column>
                <el-table-column label="预计收款金额" align="center">
                  <template slot-scope="{ row }">
                    <!-- 退款需要显示-- -->
                    <span
                      v-if="!isEmpty(row.amount) && Number(row.type) !== 2"
                      v-text="currencySymbol(scope.row.currency) + row.amount"
                    />
                    <template v-else>--</template>
                  </template>
                </el-table-column>
                <el-table-column label="实际收款时间" align="center">
                  <template slot-scope="{ row }">
                    {{ row.reTransactionDate || '--' }}
                  </template>
                </el-table-column>
                <el-table-column label="实际收款金额" align="center">
                  <template slot-scope="{ row }">
                    <!-- 退款显示负号 -->
                    <span
                      v-if="!isEmpty(row.reAmount)"
                      v-text="
                        (Number(row.type) === 2 ? '-' : '') +
                        currencySymbol(scope.row.currency) +
                        row.reAmount
                      "
                    />
                    <template v-else>--</template>
                  </template>
                </el-table-column>
                <el-table-column label="收款单号" align="center">
                  <template slot-scope="{ row }">
                    <span
                      v-if="row.transactionCode"
                      class="page-link"
                      @click="handleReceiptDetail(row)"
                    >
                      {{ row.transactionCode }}
                    </span>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column label="收款状态" align="center">
                  <template slot-scope="{ row }">
                    {{ row.statusName || '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="销售订单" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.orderCode"
                class="page-link"
                @click="handleOrderDetail(scope.row.orderCode)"
              >
                {{ scope.row.orderCode }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="客户代表" align="center">
            <template slot-scope="scope">
              {{ scope.row.businessName || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="公司名称" align="center">
            <template slot-scope="scope">
              {{ scope.row.companyName || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="订单总额" align="center">
            <template slot-scope="scope">
              <span
                v-if="!isEmpty(scope.row.totlePrice)"
                v-text="
                  currencySymbol(scope.row.currency) +
                  Decimal(scope.row.totlePrice).toFixed(2)
                "
              />
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column label="已收金额" align="center">
            <template slot-scope="scope">
              <span
                v-if="!isEmpty(scope.row.amountReceived)"
                v-text="
                  currencySymbol(scope.row.currency) +
                  Decimal(scope.row.amountReceived).toFixed(2)
                "
                :style="{
                  color: Number(scope.row.amountReceived) === 0 && '#d9001b',
                }"
              />
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column label="未收金额" align="center">
            <template slot-scope="scope">
              <span
                v-if="!isEmpty(scope.row.outstandingAmount)"
                v-text="
                  currencySymbol(scope.row.currency) +
                  Decimal(scope.row.outstandingAmount).toFixed(2)
                "
              />
              <template v-else>--</template>
            </template>
          </el-table-column>
          <el-table-column label="订单总数" align="center">
            <template slot-scope="scope">
              {{ scope.row.amount || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="出运数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.shippedNum || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="签收数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.signNum || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="收款方式" align="center" min-width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.paymentProportion">
                <div
                  v-for="pay of scope.row.paymentProportion.split(', ')"
                  :key="pay"
                >
                  {{ pay }}
                </div>
              </template>
              <template v-else>--</template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <!--收款单详情弹窗 -->
    <!-- 销售订单，其他费用ReceiptDetailModel1，采购退款ReceiptDetailModel2-->
    <ReceiptDetailModel1 ref="ReceiptDetailModel1" />
  </div>
</template>

<script>
  import ErpTitleTip from '@/erp/components/ErpTitleTip'
  import ReceiptDetailModel1 from '@/views/finance-center/credit-manage/components/receipt-detail-model1'
  import Decimal from 'decimal.js'
  import { mergeRowByColumn, isEmpty } from 'kits'
  import { number_str } from './utils'
  import { auditInteractor } from '@/core'
  const EXPEND_LENGTH = 20 // 默认展开条数
  export default {
    components: { ErpTitleTip, ReceiptDetailModel1 },
    props: {
      businessId: { type: String, default: '' },
      isAudit: { type: Boolean, default: false }, // 付款管理-审核中，显示上传按钮，其他情况纯展示
    },
    data() {
      return {
        info: { fileAnnex: [], purchaseOrderListVO: [] },
        mergeList: [
          { column: 0, prop: 'paymentNodeNameStr' },
          { column: 1, prop: 'estimatedTransactionDateStr' },
          { column: 2, prop: 'amountPayableStr' },
        ],
        stateObj: { label: '', type: '' },
      }
    },
    computed: {
      // 是否全部为展开状态
      isAllExpand() {
        return this.info.purchaseOrderListVO.every((item) => item.isExpand)
      },
    },
    watch: {
      businessId: {
        handler(id) {
          this.getDetail(id)
        },
        immediate: true,
      },
    },
    methods: {
      Decimal,
      mergeRowByColumn,
      isEmpty,
      number_str,
      async getDetail(businessId) {
        const res = await auditInteractor.payOrderGetDetailForShowNewApi(
          businessId
        )
        if (res?.code === '000000') {
          const { data } = res
          this.$emit('getData', data) // 付款管理-付款单详情弹框-需要数据回显横向以下的内容
          // 回显附件
          if (data.fileAnnex) {
            this.info.fileAnnex = JSON.parse(data.fileAnnex)
            this.$nextTick(() => {
              this.$refs.fileRef.initUpload(data.fileAnnex, this.isAudit)
            })
          }
          // 设置采购订单都为收起状态
          if (data.purchaseOrderListVO?.length) {
            data.purchaseOrderListVO.forEach((item) => {
              item.isExpand = false
            })
          }
          this.info = data
          // 前n条默认展开
          if (data.purchaseOrderListVO?.length) {
            data.purchaseOrderListVO.forEach((item, index) => {
              if (index < EXPEND_LENGTH) {
                this.handleToggleExpand(index)
              }
            })
          }
          if (data.state) {
            const stateList = [
              { value: 1, label: '待处理', type: 'primary' },
              { value: 2, label: '已完成', type: 'success' },
              { value: 3, label: '付款驳回', type: 'danger' },
              { value: 4, label: '审核中', type: 'primary' },
              { value: 5, label: '已撤销', type: 'danger' },
              { value: 6, label: '审核驳回', type: 'danger' },
              { value: 7, label: '银行处理中', type: 'primary' },
            ]
            const item = stateList.find((n) => n.value == data.state) || {}
            this.stateObj = item
          }
        }
      },
      // 全部展开/全部收起
      async handleExpandAll() {
        if (this.isAllExpand) {
          // 全部收起
          this.info.purchaseOrderListVO.forEach((item, index) => {
            item.isExpand = false
          })
        } else {
          // 全部展开
          const { purchaseOrderListVO } = this.info
          const batchSize = 10
          for (let i = 0; i < purchaseOrderListVO.length; i += batchSize) {
            const batch = purchaseOrderListVO.slice(i, i + batchSize)

            // 执行并等待当前批次的所有请求完成
            await Promise.all(
              batch.map((item, index) => {
                const actualIndex = i + index // 计算实际索引
                if (!item.isExpand) {
                  return this.handleToggleExpand(actualIndex)
                }
              })
            )
          }
        }
      },
      // 展开收起
      async handleToggleExpand(index) {
        const { purchaseOrderListVO, number } = this.info
        const { isExpand, purchaseOrderId } = purchaseOrderListVO[index]
        if (!isExpand) {
          // 展开采购订单时，调接口获取详情
          const res = await auditInteractor.getPurchaseOrderPayShowApi(
            purchaseOrderId,
            number
          )
          if (res?.code === '000000') {
            console.log('>>>', JSON.parse(JSON.stringify(res.data)))
            this.echoDetail(res.data || {}, index)
          }
        }
        this.$set(purchaseOrderListVO, index, {
          ...purchaseOrderListVO[index],
          isExpand: !isExpand,
        })
      },
      // 回显采购订单详情-异步回显发货凭证和已盖章合同
      echoDetail(data, index) {
        if (data.paymentMethodShowListVO?.length) {
          data.paymentMethodShowListVO = data.paymentMethodShowListVO.filter(
            (row) => {
              const {
                currency,
                type,
                entryType,
                percent,
                paymentNodeName,
                estimatedTransactionDate,
                amountPayable,
              } = row
              // 付款方式：逻辑参考采购订单详情-交易记录字段
              let paymentNodeNameStr = ''
              if (type == 0) {
                if (entryType == 1) {
                  paymentNodeNameStr = '采购退款'
                } else {
                  paymentNodeNameStr = `${
                    parseInt(percent * 100) || 0
                  }%${paymentNodeName}`
                }
              } else {
                paymentNodeNameStr = paymentNodeName
              }
              row.paymentNodeNameStr = paymentNodeNameStr
              // 预计付款时间
              let estimatedTransactionDateStr = ''
              if (Number(entryType) === 1) {
                // 采购退款
                estimatedTransactionDateStr = '--'
              } else {
                // 其他
                estimatedTransactionDateStr = estimatedTransactionDate
              }
              row.estimatedTransactionDateStr = estimatedTransactionDateStr
              // 预计付款金额
              let amountPayableStr = ''
              if (Number(entryType) === 1) {
                // 采购退款
                amountPayableStr = '--'
              } else {
                // 其他
                const symbol = currency == '人民币' ? '￥' : '$'
                if (amountPayable === 0 || amountPayable) {
                  amountPayableStr = symbol + Decimal(amountPayable).toFixed(2)
                }
              }
              row.amountPayableStr = amountPayableStr

              // 过滤掉hidden为1的数据
              return row.hidden !== 1
            }
          )
        }
        // 订单税率
        if (data.taxRateList?.length) {
          data.taxRateListStr = data.taxRateList
            .map((n) => Decimal(n).mul(Decimal(100)).toString() + '%')
            .join('、')
        }
        // 未满足原因
        if (data.contentList?.length) {
          data.contentListStr = data.contentList
            .map((content, i) => `${i + 1}、${content}`)
            .join('\n')
        }
        // 关联销售订单-初始化子表格数据
        if (data.orderShowListVO?.length) {
          for (const row of data.orderShowListVO) {
            row.expandData = []
            row.isSearched = false // 标记是否已经查询过
          }
        }
        // 触发视图更新
        const item = this.info.purchaseOrderListVO[index]
        this.$set(this.info.purchaseOrderListVO, index, {
          ...item,
          ...data,
        })
        // 回显发货凭证/已盖章合同/发票凭证
        this.$nextTick(() => {
          for (const file of data.shipFileList) {
            file.time = file.time.slice(0, 10)
          }
          if (data.sealedContract) {
            data.sealedContract = JSON.parse(data.sealedContract)
          }
          if (
            Array.isArray(data.invoiceFileList) &&
            data.invoiceFileList.length
          ) {
            data.invoiceFileList = data.invoiceFileList.flatMap((item) => {
              try {
                const parsed = JSON.parse(item)
                if (Array.isArray(parsed)) {
                  return parsed
                }
              } catch {
                return item.split(',').map((url) => ({
                  name: '发票凭证.jpg',
                  url: url.trim(),
                  size: '',
                  time: '',
                }))
              }
            })
          }
          const evidenceRefArr = this.$refs[`evidenceRef${index}`] // 发货凭证
          const contractRefArr = this.$refs[`contractRef${index}`] // 已盖章合同
          const invoiceRefArr = this.$refs[`invoiceRef${index}`] // 发票凭证
          if (evidenceRefArr?.length) {
            evidenceRefArr[0].initUpload(
              JSON.stringify(data.shipFileList),
              false
            )
          }
          if (contractRefArr?.length) {
            contractRefArr[0].initUpload(
              JSON.stringify(data.sealedContract),
              false
            )
          }
          if (invoiceRefArr?.length) {
            invoiceRefArr[0].initUpload(
              JSON.stringify(data.invoiceFileList),
              false
            )
          }
        })
      },
      // 关联销售订单表格展开收起
      async handleExpandChange(row) {
        // 如果当前子表格还未获取数据，则调接口获取数据
        if (!row.isSearched) {
          const { orderCode } = row
          const res = await auditInteractor.listSalesGatheringApi(orderCode)
          if (res?.code === '000000') {
            row.expandData = res.data
            row.isSearched = true // 标记已经查询过
          }
        }
      },
      // 跳转至采购订单详情页
      handleToPurchaseOrderDetail(id) {
        const routeData = this.$router.resolve({
          path: '/purchase/purchase/purchaseOrder/purchaseOrderDetail',
          query: { id, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },
      // 点击收款单号，打开收款详情弹框
      handleReceiptDetail({ type, transactionCode, financeStatus }) {
        // 参考 src\views\finance-center\credit-manage\index.vue 页面 receiptNoClick方法
        // type:1-收款 2-退款
        if (['1', '2', '1_1'].includes(type + '')) {
          this.$refs['ReceiptDetailModel1'].showReceiptDetail(
            transactionCode,
            financeStatus,
            false
          )
        }
      },
      // 跳转至订单详情页
      handleOrderDetail(orderCode) {
        const routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },
      // 打开付款单详情弹框
      handlePaymentDetailClick(row) {
        const routeData = this.$router.resolve({
          path: '/finance-center/payment-manage/index',
          query: { paymentOrderId: row.paymentOrderId },
        })
        localStorage.setItem('paymentDetailBool', true)
        window.open(routeData.href, '_blank')
      },
      // 高亮行
      tableRowClassName({ row }) {
        return row.paymentOrderNumber === this.info.number
          ? 'highlight-row'
          : ''
      },
      currencySymbol(currency) {
        return currency === '人民币' ? '￥' : '$'
      },
    },
  }
</script>
<style lang="scss" scoped>
  .payment-detail-wrapper {
    .el-row .el-col {
      display: flex;
      align-items: center;
      .flex1 {
        flex: 1;
      }
      &.flex-start {
        align-items: flex-start;
      }
    }
    .list-wrapper {
      margin-top: 20px;
      & + .list-wrapper {
        margin-top: 40px;
      }
      .header {
        display: flex;
        justify-content: space-between;
        .order-num {
          display: flex;
          align-items: center;
          > i {
            cursor: pointer;
          }
          .blue {
            width: 3px;
            height: 20px;
            margin: 0 6px;
            background-color: #0486fe;
          }
        }
      }
      .tip {
        background-color: rgba(254, 240, 240, 1);
        color: #f56c6c;
        border-radius: 5px;
        font-size: 14px;
        line-height: 24px;
        padding: 10px 20px;
        margin-top: 10px;
        font-weight: bold;
      }
      .el-table {
        // 高亮行
        ::v-deep {
          .highlight-row {
            background-color: #f6fbff;
          }
        }
        .el-table__cell {
          .tag {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            padding: 2px 4px;
            line-height: 1;
            border-radius: 2px;
            &.pay {
              color: #1890ff;
              background-color: #e8f4ff;
              border: 1px solid #d1e9ff;
            }
            &.refund {
              color: #ff4d4f;
              background-color: #ffeded;
              border: 1px solid #ffdbdc;
            }
          }
        }
      }
    }
    ::v-deep {
      // 发货凭证/已盖章合同
      .erp-upload-wrapper {
        display: flex;
        .upload_file {
          margin-top: -6px;
          .file_list {
            margin-top: 0;
            .file_line:first-of-type {
              margin-top: 0;
            }
          }
        }
      }
      // 子表格
      .el-table__expanded-cell {
        padding: 5px;
        .el-table__header {
          tr {
            height: auto;
            .el-table__cell {
              padding: 0;
              height: 32px;
            }
          }
        }
      }
      .el-table__expand-column .el-icon {
        visibility: visible;
      }
    }
  }
</style>
