<template>
  <div class="custom-container">
    <el-row class="page-header align_center mb15" type="flex">
      <el-col :span="20" class="page-header-left">
        <el-row type="flex" class="align_center">
          <!-- 查询表单 -->
          <el-form
            ref="form"
            :inline="true"
            label-min-width="49px"
            :model="form"
            size="small"
            @submit.native.prevent
          >
            <el-form-item prop="params">
              <el-input
                style="width: 280px"
                v-model="form.params"
                placeholder="支持搜索SKU ID/产品名称/规格/仓库"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="area">
              <el-select
                style="width: 120px"
                v-model="form.area"
                placeholder="请选择"
                clearable
              >
                <el-option label="合肥" value="合肥" />
                <el-option label="苏州" value="苏州" />
                <el-option label="义乌" value="义乌" />
              </el-select>
            </el-form-item>
            <!-- 入库时间 -->
            <el-form-item prop="dateArray">
              <el-date-picker
                v-model="dateArray"
                align="right"
                type="daterange"
                style="width: 240px"
                value-format="yyyy-MM-dd"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="onReset">重置</el-button>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover
            ref="showOption"
            popper-class="custom-table-checkbox"
            trigger="hover"
          >
            <div :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </div>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-button plain type="primary">自定义显示</el-button>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :total="total"
      :table-data="tableData"
      :extraHeight="0"
      :pagination="true"
      @query="getList"
      :key="customTableKey"
      class="table-box"
    >
      <el-table-column label="操作时间" min-width="120">
        <div slot-scope="scope">
          {{ utils.cutDate(scope.row.createTime) }}
        </div>
      </el-table-column>
      <el-table-column
        prop="skuId"
        label="SKU ID"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        show-overflow-tooltip
        min-width="160"
      ></el-table-column>
      <el-table-column
        v-if="columns[3].isShow"
        prop="productSpec"
        label="规格"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="columns[4].isShow"
        prop="warehouseName"
        label="所属仓库"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>

      <!-- 入库 -->
      <el-table-column
        v-if="
          columns[5].isShow ||
          columns[6].isShow ||
          columns[7].isShow ||
          columns[8].isShow ||
          columns[9].isShow ||
          columns[10].isShow
        "
        label="入库"
      >
        <el-table-column
          v-if="columns[5].isShow"
          label="入库单号"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseInNumber || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[23].isShow"
          label="采购单号"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.purchaseNumber || '--' }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="columns[6].isShow"
          prop="warehouseInType"
          label="入库类型"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseInType || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[7].isShow"
          prop="warehouseInArea"
          label="所属地区"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseInArea || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[8].isShow"
          prop="warehouseInNum"
          label="入库数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseInNum || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[9].isShow"
          label="入库单价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseInPrice > 0
                ? utils.moneyFormat(scope.row.warehouseInPrice)
                : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[10].isShow"
          label="入库成本"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseInTotalPrice > 0
                ? utils.moneyFormat(scope.row.warehouseInTotalPrice)
                : '--'
            }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 出库 -->
      <el-table-column
        v-if="
          columns[11].isShow ||
          columns[12].isShow ||
          columns[13].isShow ||
          columns[14].isShow ||
          columns[15].isShow ||
          columns[16].isShow
        "
        label="出库"
      >
        <el-table-column
          v-if="columns[11].isShow"
          label="出库单号"
          min-width="120"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.warehouseOutNumber || '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[24].isShow"
          label="销售单号"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.piNumber || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[12].isShow"
          prop="warehouseOutType"
          label="出库类型"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseOutType || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[13].isShow"
          prop="warehouseOutArea"
          label="所属地区"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.warehouseOutArea || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[14].isShow"
          prop="warehouseOutNum"
          label="出库数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseOutNum > 0 ? scope.row.warehouseOutNum : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[15].isShow"
          label="出库单价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseOutPrice > 0
                ? utils.moneyFormat(scope.row.warehouseOutPrice)
                : '--'
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[16].isShow"
          label="出库成本"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.warehouseOutTotalPrice > 0
                ? utils.moneyFormat(scope.row.warehouseOutTotalPrice)
                : '--'
            }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 费用跳转 -->
      <el-table-column v-if="columns[17].isShow" label="费用调整">
        <el-table-column
          v-if="columns[17].isShow"
          label="费用调整金额"
          min-width="120"
        >
          <template slot-scope="scope">
            <span :class="scope.row.entryType == 1 ? 'red' : ''">
              <span v-show="scope.row.entryType == 1" class="f_s_16">-</span>
              {{
                scope.row.adjustPrice > 0
                  ? utils.moneyFormat(scope.row.adjustPrice)
                  : '--'
              }}
            </span>
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 盘点 -->
      <el-table-column
        v-if="columns[18].isShow || columns[19].isShow"
        label="盘点"
      >
        <el-table-column
          v-if="columns[18].isShow"
          prop="differenceNum"
          label="差异数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.differenceNum > 0 ? scope.row.differenceNum : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[19].isShow"
          label="差异金额"
          min-width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.differencePrice > 0
                ? utils.moneyFormat(scope.row.differencePrice)
                : '--'
            }}
          </template>
        </el-table-column>
      </el-table-column>

      <!-- 期末 -->
      <el-table-column
        v-if="columns[20].isShow || columns[21].isShow || columns[22].isShow"
        label="期末"
      >
        <el-table-column
          v-if="columns[20].isShow"
          prop="finalNum"
          label="期末数量"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.finalNum || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[21].isShow"
          label="期末单价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ utils.moneyFormat(scope.row.finalPrice) || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="columns[22].isShow"
          label="期末总价"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ utils.moneyFormat(scope.row.finalTotalPrice) || 0 }}
          </template>
        </el-table-column>
      </el-table-column>
    </ErpTable>
  </div>
</template>

<script>
import CustomCache from '@/utils/custom-cache'
import customTableMixin from '@/utils/custom-table-mixin'
import { mapGetters } from 'vuex'
import ErpDraggable from 'vuedraggable'
import { columns } from './helper/index'

export default {
  name: 'stock-statistics',
  components: {
    ErpDraggable,
  },
  mixins: [customTableMixin],
  data() {
    return {
      form: {
        params: '', // 	SKU ID/产品名称/规格/仓库
        area: '', // 仓库区域
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        warehouseId: '', //	入库仓库
      },
      dateArray: [],
      tableData: [],
      page: {
        pageLe: 10,
        pageNo: 1,
      },
      total: 0,
      userCustomizeColumnId: '', //自义定保存id
      columns: columns,
    }
  },
  watch: {
    // 签约日期
    dateArray(dateArray) {
      if (dateArray.length) {
        this.form.startTime = dateArray[0]
        this.form.endTime = dateArray[1]
      } else {
        this.form.startTime = ''
        this.form.endTime = ''
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.getList()
    this.getColumsData()
  },

  methods: {
    // 获取自定义保存
    getColumsData() {
      CustomCache.getColumns(14, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
    },

    // 保存自定义显示列
    operateSave() {
      let customizeData = JSON.stringify(this.columns)
      let params = {
        userCustomizeColumnId: this.userCustomizeColumnId,
        menuType: 14,
        userId: this.userInfo.userId,
        customizeData,
      }
      CustomCache.setColumns(params).then((res) => {
        if (res.code === '000000') {
          this.userCustomizeColumnId = res.data.userCustomizeColumnId
          return this.$baseMessage(
            '操作成功',
            'success',
            false,
            'erp-hey-message-success'
          )
        }
      })
    },

    // 查询
    search() {
      this.page.pageNo = 1
      this.getList()
    },

    // 获取
    getList() {
      const params = {
        ...this.page,
        ...this.form,
      }
    },

    // 重置
    onReset() {
      this.page.pageNo = 1
      this.dateArray = []
      this.form.startTime = ''
      this.form.endTime = ''
      this.$refs.form.resetFields()
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.table-box {
  /deep/.el-table th > .cell {
    text-align: center;
  }
  /deep/.el-table .cell {
    text-align: center;
  }
}
</style>
