import { render, staticRenderFns } from "./to-be-assign-model.vue?vue&type=template&id=ebdf8e56&scoped=true&"
import script from "./to-be-assign-model.vue?vue&type=script&lang=js&"
export * from "./to-be-assign-model.vue?vue&type=script&lang=js&"
import style0 from "./to-be-assign-model.vue?vue&type=style&index=0&id=ebdf8e56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebdf8e56",
  null
  
)

export default component.exports