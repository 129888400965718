<template>
  <div class="table-container inbound">
    <el-row v-if="$route.query.noReturn !== 'true'" class="return">
      <el-page-header @back="goBack" content="确认入库"></el-page-header>
    </el-row>
    <div class="base-info2">
      <h3 class="mb20">基本信息</h3>

      <div class="form-wrapper">
        <!-- 调拨入库 -->
        <el-form ref="form" label-width="90px" key="typeIsQt" :model="form" :rules="rules" v-if="typeIsDb">
          <el-row>
            <el-col :span="6">
              <el-form-item label="入库方式" prop="type">
                <el-select v-model="currentType" value-key="type" disabled style="width: 100%">
                  <el-option v-for="item in typeNames" :key="item.type" :value="item"
                    :label="item.typeName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="入库仓库" prop="warehouseId">
                <el-select v-model="form.warehouseId" value-key="type" style="width: 100%"
                  :popper-append-to-body="false" disabled>
                  <el-option v-for="item in warehouses" :key="item.warehouseId" :value="item.warehouseId"
                    :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="创建人" prop="creator">
                <el-input disabled v-model="form.creator"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属地区" prop="creatorArea">
                <el-input disabled v-model="form.creatorArea"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="操作人" prop="operationName">
                <el-input v-model="form.operationName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sapn="24">
              <el-form-item prop="remark">
                <span slot="label">备注</span>
                <el-input type="textarea" :rows="2" show-word-limit v-model="form.remark"
                  placeholder="请输入备注,字数500字以内"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 采购确认入库表单 -->
        <el-form ref="form" v-if="typeIsCg || typeIsPd" key="typeIsCg" :model="form" label-width="90px" :rules="rules">
          <!-- 入库方式 -->
          <el-row>
            <el-col :span="6">
              <el-form-item label="入库方式" prop="type">
                <el-select v-model="currentType" value-key="type" disabled style="width: 100%">
                  <el-option v-for="item in typeNames" :key="item.type" :value="item"
                    :label="item.typeName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 入库仓库 -->
              <el-form-item prop="warehouseId">
                <template #label>入库仓库</template>
                <el-select disabled v-model="form.warehouseId" value-key="type" style="width: 100%"
                  :popper-append-to-body="false">
                  <el-option :title="item.name" v-for="item in warehouses" :key="item.warehouseId"
                    :value="item.warehouseId" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 采购单号 -->
              <el-form-item prop="purchaseOrderNumber">
                <template #label>采购单号</template>
                <el-input v-model="form.purchaseOrderNumber" placeholder="请输入" disabled>
                  <i slot="suffix" class="el-input__icon el-icon-search pointer" @click="searchPurchaseOrder"></i>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <!-- 供应商-->
              <el-form-item label="供应商">
                <el-input disabled v-model="form.supplierName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <!-- 采购开发-->
              <el-form-item label="采购开发">
                <el-input disabled v-model="form.buyer"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="creator">
                <span slot="label">创建人</span>
                <el-input disabled v-model="form.creator"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="creatorArea">
                <span slot="label">所属地区</span>
                <el-input disabled v-model="form.creatorArea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--  操作人 -->
              <el-form-item prop="operationName">
                <span slot="label">操作人</span>
                <el-input disabled v-model="form.operationName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sapn="24">
              <el-form-item prop="remark">
                <span slot="label">备注说明</span>
                <el-input type="textarea" disabled :autosize="{ minRows: 2 }" v-model="form.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <!-- 退货确认入库表单 -->
        <el-form ref="form" :model="form" :rules="rules" v-else-if="typeIsTh" label-width="90px" key="typeIsTh">
          <el-row>
            <el-col :span="6">
              <el-form-item label="入库方式" prop="type">
                <el-select v-model="currentType" value-key="type" disabled style="width: 100%">
                  <el-option v-for="item in typeNames" :key="item.type" :value="item"
                    :label="item.typeName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 入库仓库 -->
              <el-form-item label="入库仓库" prop="warehouseId">
                <el-select v-model="form.warehouseId" value-key="type" :popper-append-to-body="false"
                  style="width: 100%" disabled>
                  <el-option v-for="item in warehouses" :key="item.warehouseId" :value="item.warehouseId"
                    :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="销售单号" prop="orderNumber">
                <el-input v-model="form.orderNumber" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="creator">
                <span slot="label">客户代表</span>
                <el-input disabled v-model="form.creator" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="creator">
                <span slot="label">客户名称</span>
                <el-input disabled v-model="form.customerName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="creator">
                <span slot="label">创建人</span>
                <el-input disabled v-model="form.creator"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="creatorArea">
                <span slot="label">所属地区</span>
                <el-input disabled v-model="form.creatorArea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 操作人 -->
              <el-form-item label="操作人" prop="createName">
                <el-input disabled v-model="form.operationName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sapn="24">
              <el-form-item prop="remark">
                <span slot="label">原因说明</span>
                <el-input type="textarea" :rows="2" disabled v-model="form.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 退料入库 -->

        <el-form ref="form" label-width="90px" key="typeIsTl" :model="form" :rules="rules"
          v-else-if="typeIsTl || typeIsJG">
          <el-row>
            <el-col :span="6">
              <el-form-item label="入库方式" prop="type">
                <el-select v-model="currentType" value-key="type" disabled style="width: 100%">
                  <el-option v-for="item in typeNames" :key="item.type" :value="item"
                    :label="item.typeName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="入库仓库" prop="warehouseId">
                <el-select v-model="form.warehouseId" value-key="type" style="width: 100%"
                  :popper-append-to-body="false" disabled>
                  <el-option v-for="item in warehouses" :key="item.warehouseId" :value="item.warehouseId"
                    :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="销售单号" prop="orderNumber">
                <el-input v-model="salesOrderNumber" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加工单号" prop="orderNumber">
                <el-input v-model="form.orderNumber" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="创建人" prop="creator">
                <el-input disabled v-model="form.creator"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属地区" prop="creatorArea">
                <el-input disabled v-model="form.creatorArea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="操作人" prop="operationName">
                <el-input v-model="form.operationName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sapn="24">
              <el-form-item prop="remark">
                <span slot="label">备注</span>
                <el-input type="textarea" :rows="2" show-word-limit v-model="form.remark" disabled
                  placeholder="请输入备注,字数500字以内"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 入库产品 -->
      <h3 class="mb20 h3-wrapper">
        <span>入库产品</span>
      </h3>
    </div>

    <el-table v-if="typeIsDb" border :data="tableData" class="mt20" :key="tableKey + 'DB'">
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link v-if="scope.row.skuId.indexOf('FL') === -1" type="primary" :underline="false"
            @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="产品名称"></el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="160"   show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型"></el-table-column>
      <el-table-column align="center" prop="productUnit" label="单位">
        <template slot-scope="scope">
          {{ scope.row.productUnit | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseInNum" width="150px" label="实际入库数量"></el-table-column>

      <el-table-column align="center" prop="priceExcludingTax" width="130px">
        <template slot="header">
          <span>{{ typeIsCg ? '不含税单价' : '入库单价' }}</span>
        </template>
        <template slot-scope="scope">
          {{ toFixed3(scope.row.priceExcludingTax) }}
        </template>
      </el-table-column>

      <el-table-column align="center" :label="'入库成本'" prop="totalPriceExcluding">
        <template slot-scope="scope">
          {{ toFixed3(scope.row.totalPriceExcluding) }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top">
            <el-input v-model="scope.row.remark" placeholder="请添加备注"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请添加备注" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入库库位">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationDTOList" @add-loc="addLoc"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <el-table border :data="tableData" class="mt20" v-else-if="typeIsPd" :key="tableKey + 'PD'" :height="tableHeight">
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="产品名称">
        <template slot-scope="scope">
          {{ scope.row.productName }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="160"   show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expectInNum" label="申请入库数量/单位" width="160">
        <template slot-scope="scope">
          {{ scope.row.expectInNum ? scope.row.expectInNum : '--' }}/
          {{ scope.row.productUnit ? scope.row.productUnit : '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="usable" label="产品是否可用" width="160">
        <template slot-scope="scope">
          {{ scope.row.usable == '0' ? '否' : '是' }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="warehouseInNum" width="180px">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>实际入库数量</span>
        </template>
        <template slot-scope="scope">
          <el-input-number v-if="!scope.row.warehouseInNum && scope.row.warehouseInNum !== 0"
            v-model="scope.row.warehouseInNum" :max="scope.row.expectInNum"
            :class="{ error: showError && !scope.row.warehouseInNum }" @change="onChangeWarehouseInNum(scope.row)"
            @blur="checkEmpty(scope.row, 'warehouseInNum')" :precision="0" disabled></el-input-number>
          <el-input-number v-else :min="0" v-model="scope.row.warehouseInNum" :max="scope.row.expectInNum"
            :class="{ error: showError && !scope.row.warehouseInNum }" @change="onChangeWarehouseInNum(scope.row)"
            @blur="checkEmpty(scope.row, 'warehouseInNum')" :precision="0" disabled></el-input-number>
          <p v-if="
              scope.row.warehouseInNumEmptyCheck && !scope.row.warehouseInNum
            " class="error-info">
            为正数,不能为空或0
          </p>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top">
            <el-input v-model="scope.row.remark" placeholder="请输入"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请输入" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>

      <!-- 入库库位 -->
      <el-table-column align="center" label="入库库位">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationDTOList" @add-loc="addLoc"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <!-- 采购入库确认入库 -->
    <el-table border :data="tableData" class="mt20" v-else-if="typeIsCg" :key="tableKey + 'CG'">
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="产品名称">
        <template slot-scope="scope">
          {{ scope.row.productName }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="productSpec" label="英文规格" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="160"   show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expectInNum" label="申请入库数量/单位" width="160">
        <template slot-scope="scope">
          {{ scope.row.expectInNum ? scope.row.expectInNum : '--' }}/
          {{ scope.row.productUnit ? scope.row.productUnit : '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseInNum" width="180px">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>实际入库数量</span>
        </template>
        <template slot-scope="scope">
          <el-input-number v-if="!scope.row.warehouseInNum && scope.row.warehouseInNum !== 0"
            v-model="scope.row.warehouseInNum" :max="scope.row.expectInNum"
            :class="{ error: showError && !scope.row.warehouseInNum }" @change="onChangeWarehouseInNum(scope.row)"
            @blur="checkEmpty(scope.row, 'warehouseInNum')" :precision="0"></el-input-number>
          <el-input-number v-else :min="0" v-model="scope.row.warehouseInNum" :max="scope.row.expectInNum"
            :class="{ error: showError && !scope.row.warehouseInNum }" @change="onChangeWarehouseInNum(scope.row)"
            @blur="checkEmpty(scope.row, 'warehouseInNum')" :precision="0"></el-input-number>
          <p v-if="
              scope.row.warehouseInNumEmptyCheck && !scope.row.warehouseInNum
            " class="error-info">
            为正数,不能为空或0
          </p>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top">
            <el-input v-model="scope.row.remark" placeholder="请输入"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请输入" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>

      <!-- 入库库位 -->
      <el-table-column align="center" label="入库库位">
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationDTOList" @add-loc="addLoc"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <!-- 退料入库确认入库 -->
    <el-table border :data="tableData" class="mt20" v-else-if="typeIsTl" :key="tableKey + 'TL'"
      :span-method="objectSpanMethod">
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="产品名称">
        <template slot-scope="scope">
          {{ scope.row.productName }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="160"   show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型">
        <template slot-scope="scope">
          {{ scope.row.productType }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expectInNum" label="单位" width="160">
        <template slot-scope="scope">
          {{ scope.row.productUnit || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expectInNum" label="申请入库数量" width="160">
        <template slot-scope="scope">
          {{ scope.row.expectInNum || '--' }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="warehouseInNum" width="180px">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>实际入库数量</span>
        </template>
        <template slot-scope="scope">
          <el-input-number disabled v-model="scope.row.warehouseInNum"></el-input-number>

          <p v-if="
              scope.row.warehouseInNumEmptyCheck && !scope.row.warehouseInNum
            " class="error-info">
            为正数,不能为空或0
          </p>
        </template>
      </el-table-column>
      <!-- 入库库位 -->
      <el-table-column align="center" label="入库库位">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>入库库位</span>
        </template>
        <template slot-scope="scope">
          <SelectedLocs :locs="scope.row.warehouseInProductLocationDTOList" @add-loc="addLoc"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top">
            <el-input v-model="scope.row.remark" placeholder="请输入"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请输入" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
    </el-table>
    <!-- 加工入库表格 -->

    <el-table border :data="tableData" class="mt20" v-else-if="typeIsJG" :key="tableKey + 'JG'">
      <el-table-column align="center" prop="" label="出库单号">
        <template slot-scope="scope">
          <p v-if="scope.row.warehouseOutNumber">
            <el-link type="primary" :underline="false" @click="showOutDetail(scope.row.warehouseOutNumber)">
              {{ scope.row.warehouseOutNumber }}
            </el-link>
          </p>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="产品名称"></el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="160"   show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型"></el-table-column>
      <el-table-column align="center" prop="qualityNum" label="合格品申请入库" width="120"></el-table-column>
      <el-table-column align="center" prop="qualityNum" label="合格品实际入库" width="140">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>合格品实际入库</span>
        </template>
        <template slot-scope="scope">
          <el-input v-model="scope.row.qualityNum" disabled></el-input>
        </template>
      </el-table-column>
      <!-- 入库库位 -->
      <el-table-column align="center" label="合格品入库库位">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>合格品入库库位</span>
        </template>
        <template slot-scope="scope">
          <span style="display: none">{{ scope.row }}</span>
          <SelectedLocs :locs="scope.row.qualityWarehouseInProductLocationVOList" @add-loc="addLoc"
            :tableIndex="scope.$index" type="quality" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="scrapNum" label="报废品申请入库" width="120"></el-table-column>
      <el-table-column align="center" prop="scrapNum" label="报废品实际入库" width="140">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>报废品实际入库</span>
        </template>
        <template slot-scope="scope">
          <el-input v-model="scope.row.scrapNum" disabled></el-input>
        </template>
      </el-table-column>
      <!-- 入库库位 -->
      <el-table-column align="center" label="报废品入库库位">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>报废品入库库位</span>
        </template>
        <template slot-scope="scope">
          <span style="display: none">{{ scope.row }}</span>
          <SelectedLocs :locs="scope.row.scrapWarehouseInProductLocationVOList" @add-loc="addLoc"
            :tableIndex="scope.$index" type="scrap" />
        </template>
      </el-table-column>

      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top">
            <el-input maxlength="500" v-model="scope.row.remark" placeholder="请添加备注"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请添加备注" v-else maxlength="500"></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
    </el-table>
    <!-- 退货入库表格 -->
    <el-table border :data="tableData" class="mt20" v-else :key="tableKey + 'th'">
      <el-table-column align="center" prop="" label="出库单号">
        <template slot-scope="scope">
          <p v-if="scope.row.warehouseOutNumber">
            <el-link type="primary" :underline="false" @click="showOutDetail(scope.row.warehouseOutNumber)">
              {{ scope.row.warehouseOutNumber }}
            </el-link>
          </p>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <popoverShipClick :detailId="scope.row" :itemTitle="
              scope.row.mainFlag == 0 && scope.row.splitFlag == 1 ? '子' : ''
            "></popoverShipClick>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="产品名称"></el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecCn" label="中文规格" width="160"   show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn | emptyFilter }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型"></el-table-column>
      <el-table-column align="center" prop="productUnit" label="已出库数量/个" width="120">
        <template slot-scope="scope">
          {{ scope.row.warehouseOutNum ? scope.row.warehouseOutNum : '-' }}/{{
          scope.row.productUnit ? scope.row.productUnit : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="returnInLotNo" label="入库批次">
        <template slot-scope="scope">
          {{ scope.row.returnInLotNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="purchaseNumber" label="采购单号">
        <template slot-scope="scope">
          {{ scope.row.purchaseNumber || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="buyer" label="采购开发">
        <template slot-scope="scope">
          {{ scope.row.buyer || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseOutNum" label="批次出库数量">
        <template slot-scope="scope">
          {{ scope.row.warehouseOutNum || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expectInNum" label="申请入库数量">
        <template slot-scope="scope">
          {{ scope.row.expectInNum ? scope.row.expectInNum : '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品是否可用">
        <template slot-scope="scope">
          {{ scope.row.usable == '0' ? '否' : '是' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="250px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top">
            <el-input v-model="scope.row.remark" placeholder="请输入内容,字数500字以内"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请输入内容,字数500字以内" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>

      <!-- 入库库位 -->
      <el-table-column align="center" label="入库库位">
        <template slot-scope="scope">
          <span style="display: none">{{ scope.row }}</span>
          <SelectedLocs :locs="scope.row.warehouseInProductLocationDTOList" @add-loc="addLoc"
            :tableIndex="scope.$index" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next,total" :total="total" :current-page.sync="pageNo"
      :page-size="pageSize" @current-change="handleCurrentChange" v-if="typeIsPd">
    </el-pagination>
    <div class="file-wrapper">
      <h3>相关附件</h3>
      <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList"
        style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"></ErpUpload>
      <p class="divider"></p>
    </div>

    <div style="position: absolute; bottom:0;width: 100%; text-align: center;">
      <el-button type="primary" @click="onSubmit" :loading="isLoading">
        确认
      </el-button>
      <el-button @click="backCancel" class="ml15">取消</el-button>
    </div>

    <ProductDialg :params="params" :selectedPro="selectedPro" @close-pro="onClose" @select-pro="selectPro">
    </ProductDialg>

    <Loc ref="loc" :locList="locList" :params="locParams" :warehouseInNum="warehouseInNum"
      :warehouseId="form.warehouseId" @set-loc="onSetLoc" @close="onClose('loc')"></Loc>
  </div>
</template>

<script>
  import { InboundSheet, ProductListInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  import ProductDialg from './components/product.vue'
  import BaseDetail from './components/base-detail.vue'
  import Loc from './components/loc.vue'
  import globalBackButtonMixin from '@/utils/global-back-button'
  import AuditChoose from '@/components/AuditChoose'
  import SelectedLocs from './components/selected-locs.vue'
  import popoverShipClick from '../outbound-sheet/components/popoverShipClick.vue'
  import InspectionResult from './components/inspection-result'
  import { deepCopy, getRemainingHeight } from 'kits'

  const mergeColumns = [
    '产品编码',
    '产品名称',
    // '规格',
    '英文规格',
    '中文规格',
    '产品类型',
    '单位',
    '结算备注',
    '审核备注',
    '操作',
    '已审核备注',
  ]

  const baseProduct = {
    skuId: '',
    productName: '',
    productSpec: '',
    productType: '',
    productUnit: '',
    contractNum: '',
    warehouseInNum: '',
    giftNum: '',
    priceExcludingTax: '',
    priceIncludingTax: '',
    totalPriceExcluding: '0.000',
    totalPriceIncluding: '0.000',
    remark: '',
    productImage: '',
    productCode: '',
    warehouseInProductLocationDTOList: [], //入库库位信息
  }
  let self,
    rules = {
      remark: [
        {
          message: '请输入备注',
          trigger: 'blur',
        },
        {
          validator: (rule, value, callback) => {
            if (value && value.length > 500) {
              return callback(new Error('备注长度不能超过500'))
            }
            callback()
          },
        },
      ],
    }
  export default {
    name: 'confirm-inbound-sheet',
    data() {
      self = this
      return {
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        form: {
          type: 1, //入库方式code
          typeName: '', // 入库方式名称
          warehouseId: '', //入库仓库
          operationId: '',
          operationName: '',
          remark: '',
          orderNumber: '', //订单编号
          warehouseInId: '',
          purchaseOrderNumber: '', //采购单号
          supplierName: '',
          supplierId: '',
          warehouseInNumber: '',
          creator: '',
          creatorArea: '',
        },
        showError: false,
        selectedPro: [], //已选择产品
        rules,
        tableKey: '',
        operation: {}, //登录人
        typeNames: [
          {
            type: 0,
            typeName: '采购入库',
          },
          {
            type: 1,
            typeName: '退货入库',
          },
          {
            type: 2,
            typeName: '其他入库',
          },
          {
            type: 10,
            typeName: '调拨入库',
          },
          {
            type: 11,
            typeName: '盘盈入库',
          },
          {
            type: 14,
            typeName: '退料入库',
          },
          {
            type: 15,
            typeName: '加工入库',
          },
        ], //入库方式
        currentType: {}, //选中入库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
        },
        locParams: {
          //库位参数
          show: false,
        },
        tableData: [], // 产品数据
        currentRow: {}, //产品当前操作行
        purchaseOrder: {
          contractArea: '',
          supplierName: '',
          purchaseOrderNumber: '',
        },
        files: '',
        isLoading: false,
        showEmptyError: false,
        locList: [],
        warehouseInNum: '',
        spanArr: [],
        pos: 0,
        total: 0, //总页码
        pageSize: 200, //分页数量
        pageNo: 1, //第几页
        initTableData: [], //初始数据
        tableHeight: 300, //表格高度
      }
    },

    components: {
      AuditChoose,
      ProductDialg,
      Loc,
      SelectedLocs,
      popoverShipClick,
      // InspectionResult,
      BaseDetail,
    },

    computed: {
      warehouseName() { },
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
      typeIsCg() {
        //采购入库
        return this.form.type === 0
      },
      typeIsTh() {
        return this.form.type === 1
      },
      typeIsQt() {
        return this.form.type === 2
      },
      // 盘盈入库
      typeIsPd() {
        return this.form.type === 11
      },
      // 调拨入库
      typeIsDb() {
        return this.form.type === 10
      },
      typeIsTl() {
        return this.form.type === 14
      },
      typeIsJG() {
        return this.form.type === 15
      },
      isEdit() {
        return !!this.$route.query.warehouseInId
      },
      salesOrderNumber() {
        return (
          this.form.warehouseInProductVOList &&
          this.form.warehouseInProductVOList[0]?.salesOrderNumber
        )
      },
    },
    mixins: [globalBackButtonMixin],
    mounted() {
      setTimeout(() => {
        this.getTableHeight()
      }, 200)
    },
    created() {
      this.tableKey = Date.now() + this.form.type
      this.initData()
      InboundSheet.findWarehouseList({
        hasCloudWarehouse: 1,
      }).then((res) => {
        this.warehouses = res.data || []
      })

      if (this.isEdit) {
        const { warehouseInId } = this.$route.query
        InboundSheet.getDetail({ warehouseInId }).then((res) => {

          if (res.code == '000000') {
            setTimeout(() => {
              let warehouseInProductVOList = res.data?.warehouseInProductVOList
              delete res.data.warehouseInProductVOList
              this.form = res.data
              if (res.data.type === 0) {
                this.form.purchaseOrderNumber = res.data.orderNumber
              }
              if (res.data.type == 15) {
                // 处理加工入库产品数据
                if (Array.isArray(warehouseInProductVOList)) {
                  self.handleTableData(warehouseInProductVOList)
                }
              } else {
                let arr = []
                Array.isArray(warehouseInProductVOList) &&
                  (arr = warehouseInProductVOList.map(
                    (item) => {
                      item.warehouseInProductLocationDTOList =
                        item.warehouseInProductLocationVOList

                      delete item.warehouseInProductLocationVOList
                      return item
                    }
                  ))
                if (res.data.type !== 11) {
                  this.tableData = arr
                } else {
                  this.initTableData = arr
                  this.total = arr.length
                  this.getPaginationData()
                }
              }

              this.currentType = this.typeNames.find(
                (item) => item.type == res.data.type
              )
              if (this.currentType.type == 14) {
                this.getSpanArr(this.tableData)
              }
              this.files = res.data.fileUrls
              // 将带过来的附件打标记
              if (res.data.originFileUrls) {
                let originFileUrls = JSON.parse(res.data.originFileUrls)
                originFileUrls = originFileUrls.map((file) => {
                  file.isOrign = true
                  return file
                })
                this.initUpload(JSON.stringify(originFileUrls), false)
              }
            }, 200)

          }
        })
      }
    },
    methods: {
      getTableHeight() {
        try {
          let heigth = getRemainingHeight(['file-wrapper', 'base-info2', 'el-pagination', 'return'], '', 190)
          this.tableHeight = heigth
        } catch (err) {
          this.tableHeight = 300
        }
      },
      handleCurrentChange(val) {
        this.pageNo = val
        this.getPaginationData()
      },
      getPaginationData() {
        let initTableData = this.initTableData,
          pageNo = this.pageNo,
          pageSize = this.pageSize
        // 确保页码不小于1
        pageNo = Math.max(pageNo, 1);
        // 计算起始索引
        const startIndex = (pageNo - 1) * pageSize;
        // 计算结束索引，注意JavaScript数组索引是从0开始的
        const endIndex = startIndex + pageSize;

        // 获取指定页的数据
        const tableData = initTableData.slice(startIndex, endIndex);
        this.tableData = tableData;
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (mergeColumns.includes(column.label)) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      getSpanArr(list) {
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            if (
              `${list[i].skuId}${list[i].orderProductUniqueId}` ===
              `${list[i - 1].skuId}${list[i - 1].orderProductUniqueId}`
            ) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      handleTableData(list) {
        this.initTableData = JSON.parse(JSON.stringify(list))
        // 保存数据
        let tableData = [],
          cache = {}

        // 数据处理
        list.forEach((pro) => {
          let key = `${pro.skuId}/${pro.orderProductUniqueId}`
          if (!cache[key]) {
            // 第一次添加数据
            let product = {
              ...pro,
              qualityWarehouseInProductLocationVOList: [], // 合格品库位
              scrapWarehouseInProductLocationVOList: [], //报废品库位
              _key: key,
            }
            if (product.usable === '0') {
              // 不合格品数量
              product.scrapNum = product.warehouseInNum
              product.qualityNum = 0
            } else {
              // 合格品数量
              product.scrapNum = 0
              product.qualityNum = product.warehouseInNum
            }
            cache[key] = key
            tableData.push(product)
          } else {
            // 找到产品
            let targetPro = tableData.find((item) => item._key === key)
            if (pro.usable === '0') {
              // 不合格品数量
              targetPro.scrapNum += pro.warehouseInNum
            } else {
              // 合格品数量
              targetPro.qualityNum += pro.warehouseInNum
            }
          }
        })

        this.tableData = tableData
        cache = {}
      },
      showOutDetail(warehouseOutNumber) {
        let routeData = this.$router.resolve({
          path: '/stock-center/stock-center/outbound-sheet/outbound-sheet-detail',
          query: {
            warehouseOutId: warehouseOutNumber,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      skuIdClick(skuId) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      goBack() {
        this.$router.push('/stock-center/inbound-sheet/inbound-sheet')
      },
      checkEmpty(row, field) {
        if (
          row[field] === 0 ||
          row[field] === '' ||
          typeof row[field] === 'undefined'
        ) {
          // this.showEmptyError = true
          if (!row[field + 'emptyCheck']) {
            this.$set(row, [field + 'EmptyCheck'], true)
          }
        }
      },
      // 加工入库库位数量校验
      $_checkJgCount() {
        let tableData = this.tableData
        let checkResult = true
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]
          let {
            scrapNum,
            scrapWarehouseInProductLocationVOList,
            qualityNum,
            qualityWarehouseInProductLocationVOList,
          } = pro

          if (qualityNum && !qualityWarehouseInProductLocationVOList?.length) {
            checkResult = false
            this.$message.warning(
              `请选择产品 ”${pro.productName}“ 的合格品入库库位`
            )
            break
          }
          if (scrapNum && !scrapWarehouseInProductLocationVOList?.length) {
            checkResult = false
            this.$message.warning(
              `请选择产品 ”${pro.productName}“ 的报废品入库库位`
            )
            break
          }
          if (qualityNum) {
            //校验合格评数量
            let totalQualityLocNum = qualityWarehouseInProductLocationVOList
              .map((i) => i.num)
              .reduce((prev, next) => prev + next)
            if (qualityNum !== totalQualityLocNum) {
              this.$message.warning(
                `产品 ${pro.productName}的合格品申请入库数量${qualityNum}和合格品入库库位选择数量${totalQualityLocNum}不相等`
              )
              checResult = false
              break
            }
          }
          if (scrapNum) {
            // 校验不合格品数量
            let totalScrapLocNum = scrapWarehouseInProductLocationVOList
              .map((i) => i.num)
              .reduce((prev, next) => prev + next)
            if (scrapNum !== totalScrapLocNum) {
              this.$message.warning(
                `产品 ${pro.productName}的报废品申请入库数量${qualityNum}和报废品入库库位选择数量${totalQualityLocNum}不相等`
              )
              checResult = false
              break
            }
          }
        }
        return checkResult
      },
      $_checkCount() {
        let checResult = true
        let tableData = this.tableData
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]
          let { warehouseInProductLocationDTOList, warehouseInNum, remark } =
            pro

          if (warehouseInProductLocationDTOList.length === 0) {
            //step01 校验是否选择出库库位
            checResult = false
            this.$message.warning(`请选择产品 ”${pro.productName}“ 的入库库位`)
            break
          } else {
            //step02 校验实际入库数和库位选择总数是否相同
            let totalLocNum = warehouseInProductLocationDTOList
              .map((i) => i.num)
              .reduce((prev, next) => prev + next)
            if (warehouseInNum !== totalLocNum) {
              this.$message.warning(
                `产品 ${pro.productName}的实际出库数量${warehouseInNum}和库位选择数量${totalLocNum}不相等`
              )
              checResult = false
              break
            }
            if (remark && remark.length > 500) {
              checResult = false
              break
            }
          }
        }

        return checResult
      },
      addPro() {
        this.params.show = true
        this.selectedPro = this.tableData
      },
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },

      get2TypeFiles() {
        let files = {
          originFileNames: '',
          originFileUrls: '',
          fileUrls: '',
          fileNames: '',
        }
        if (this.files) {
          let filesObj = JSON.parse(this.files)
          let originFileUrls = JSON.stringify(
            filesObj.filter((file) => file.isOrign)
          )
          let fileUrls = JSON.stringify(
            filesObj.filter((file) => !file.isOrign)
          )

          files.fileUrls = fileUrls
          files.originFileUrls = originFileUrls
        }

        return files
      },

      execOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.sku
          _basePro.productName = item.productName
          _basePro.productSpec = item.specification
          _basePro.productType = item.productCategory
          _basePro.contractNum = item.amount
          _basePro.productImage = item.rendingsUrl
          _basePro.productCode = item.productId
          _basePro.salesOrderId = _basePro.orderId

          tableData.push(_basePro)
        })
        this.tableData = tableData
      },

      execPurchaseOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.sku
          _basePro.productName = item.nameCn
          _basePro.productSpec = item.specsValue1
          _basePro.productType = item.standardCustomized
          _basePro.contractNum = item.numPurchase + item.numRelax
          _basePro.productSpec = item.specsValue1

          _basePro.productImage = item.imgMain
          _basePro.productCode = item.productId
          _basePro.orderProductId = item.businessId
          tableData.push(_basePro)
        })
        this.tableData = tableData
      },
      onChangeWarehouseInNum(row) {
        this.onChangePriceExcludingTax(row)
        this.onChangePriceIncludingTax(row)
      },
      onChangePriceExcludingTax(row) {
        row.totalPriceExcluding = this.toFixed3(
          this.BigMath.multiply(row.priceExcludingTax, row.warehouseInNum)
        )
      },
      onChangePriceIncludingTax(row) {
        if (this.typeIsCg) {
          row.totalPriceIncluding = this.toFixed3(
            this.BigMath.multiply(row.priceIncludingTax, row.warehouseInNum)
          )
        }
      },
      searchPurchaseOrder() {
        let { purchaseOrderNumber } = this.form
        if (!purchaseOrderNumber) {
          return this.$message.warning('请输入采购单号!')
        }
        InboundSheet.getDetailForShowByNumber(purchaseOrderNumber)
          .then((res) => {
            if (res && res.code === '000000') {
              this.form.supplierId = res.data.supplierId
              this.form.supplierName = res.data.supplierName
              this.execPurchaseOrderData(
                res.data.purchaseProductForStorageVOList || []
              )
            } else {
              this.form.purchaseOrderNumber = ''
            }
          })
          .catch(() => {
            this.form.purchaseOrderNumber = ''
          })
      },

      deletePro(index) {
        this.tableData.splice(index, 1)
      },
      onSetLoc(locs, warehouseInNum) {
        setTimeout(() => {
          let field
          if (!this.loctionType) {
            field = 'warehouseInProductLocationDTOList'
          } else {
            field = `${this.loctionType}WarehouseInProductLocationVOList`
          }
          if (!this.currentRow[field]) {
            this.$set(this.currentRow, field, locs)
          } else {
            this.currentRow[field] = locs
          }
          this.tableKey = Date.now() + this.form.type
        }, 100)

      },
      async addLoc(index, type) {

        let row = this.tableData[index]
        this.currentRow = row
        this.loctionType = type //库位选择类型
        if (!type) {
          this.warehouseInNum = row.warehouseInNum
        } else {
          this.warehouseInNum = row[`${type}Num`]
        }

        if (!this.form.warehouseId) {
          return this.$message.warning('请选择仓库')
        }

        if (row.warehouseInNum <= 0) {
          return this.$message.warning('请先输入实际入库数量')
        }
        let res = await InboundSheet.findWarehouseLocation({
          flag: this.form.warehouseFlag,
          skuId: row.skuId,
          warehouseId: this.form.warehouseId,
        })
        // .then((res) => {
        if (res && res.code === '000000') {
          let locList = res.data || []
          this.locList = locList.map((loc) => {
            loc.currentNum = loc.num
            loc.locationNumber = loc.fullLocationNumber
            return loc
          })
        }
        // })
        this.locParams.show = true

        this.$nextTick().then(() => {
          let warehouseInProductLocationDTOList

          if (!this.loctionType) {
            //普通库位选择
            warehouseInProductLocationDTOList =
              self.currentRow.warehouseInProductLocationDTOList
          } else {
            warehouseInProductLocationDTOList =
              self.currentRow[
              this.loctionType + 'WarehouseInProductLocationVOList'
              ]
          }

          if (
            warehouseInProductLocationDTOList &&
            warehouseInProductLocationDTOList.length > 0
          ) {
            let locs = deepCopy(warehouseInProductLocationDTOList)
            locs = locs.map(loc => {
              let curLoc = this.locList.find(l => l.warehouseLocationId === loc.warehouseLocationId)
              if (curLoc) {
                loc.currentNum = curLoc.num
              }
              return loc
            })
            self.$refs.loc.locs = locs
          } else {
            self.$refs.loc.locs = [
              {
                locationNumber: '',
                fullLocationNumber: '',
                num: '',
                currentNum: 0,
                warehouseId: '',
              },
            ]
          }
        })
      },
      onClose(isLoc) {
        if (isLoc) {
          return (this.locParams.show = false)
        }
        this.params.show = false
      },
      selectPro(val) {
        val.forEach((item) => {
          if (!this.tableData.find((pro) => pro.skuId === item.skuId)) {
            this.tableData.push({
              ...baseProduct,
              ...item,
              productName: item.nameCn,
              productType: item.standardCustomized, //产品类型
              priceExcludingTax: 0,
            })
          }
        })
      },

      $_checkProduct() {
        this.showError = true

        var checkFields = ['warehouseInNum', 'priceExcludingTax']
        let tableData = this.tableData
        tableData.forEach((item) => {
          if (this.typeIsCg || this.typeIsQt) {
            let _checkFields = checkFields.slice(0, 1)
            _checkFields.forEach((field) => {
              if (!item[field + 'EmptyCheck']) {
                this.$set(item, [field + 'EmptyCheck'], true)
              }
            })
          } else {
            checkFields.forEach((field) => {
              if (!item[field + 'EmptyCheck']) {
                this.$set(item, [field + 'EmptyCheck'], true)
              }
            })
          }
        })
        let empty = this.tableData.find((item) => {
          if (this.typeIsCg || this.typeIsTh) {
            return !item.warehouseInNum
          } else {
            return !item.warehouseInNum || !item.priceExcludingTax
          }
        })
        return empty
      },
      // 获取表格数据
      getTableData() {
        const initTableData = this.initTableData,
          tableData = this.tableData
        let cache = {}
        initTableData.forEach((product) => {
          let key = `${product.skuId}${product.orderProductUniqueId}`,
            targetPro
          if (!cache[key]) {
            targetPro = tableData.find(
              (item) => `${item.skuId}${item.orderProductUniqueId}` === key
            )
            cache[key] = targetPro
          } else {
            targetPro = cache[key]
          }
          if (product.usable === '1') {
            product.warehouseInProductLocationDTOList =
              targetPro.qualityWarehouseInProductLocationVOList
          } else {
            product.warehouseInProductLocationDTOList =
              targetPro.scrapWarehouseInProductLocationVOList
          }
          console.log(targetPro.remark)
          product.remark = targetPro.remark
        })

        return initTableData
      },

      backCancel() {
        this.$router.push({
          path: '/stock-center/inbound-sheet/inbound-sheet',
        })
      },

      onSubmit() {
        let flag = false

        if (
          !this.typeIsPd &&
          !this.typeIsDb &&
          !this.typeIsTl &&
          !this.typeIsJG
        ) {
          if (!this.tableData || this.tableData.length === 0) {
            return this.$message.warning('请选择产品')
          }

          if (this.$_checkProduct()) {
            return
          }
        }
        if (this.typeIsJG) {
          if (!this.$_checkJgCount()) {
            return
          }
        } else {
          if (!this.$_checkCount()) {
            return
          }
        }
        this.isLoading = true
        const {
          operationId,
          operationName,
          remark,
          warehouseId,
          warehouseInId,
          supplierName,
          supplierId,
          purchaseOrderNumber,
          orderNumber,
        } = this.form

        const { type, typeName } = this.currentType
        let requestData = {
          fileUrls: this.files,
          operationId,
          operationName,
          orderNumber: orderNumber || purchaseOrderNumber, // 有orderNumber先取orderNumber
          remark,
          supplierName,
          supplierId,
          type,
          typeName,
          warehouseId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseInId,
          warehouseInProductDTOList: this.typeIsJG
            ? this.getTableData()
            : (this.typeIsPd ? this.initTableData : this.tableData),
          createWay: 1,
          area: this.userInfo.area,
          ...this.get2TypeFiles(),
        }

        InboundSheet.ensure(requestData)
          .then((res) => {
            if (res && res.code === '000000') {
              this.$message.success('确认入库成功')
              this.$router.push({
                path: '/stock-center/inbound-sheet/inbound-sheet',
              })
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      },
      initData() {
        this.currentType = this.typeNames[0]
        this.form.operationName = this.userInfo.userName
        this.form.operationId = this.userInfo.userId
        this.form.creatorArea = this.userInfo.area
      },

      resetFormAndTableDataWhenTypeChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key != 'creatorArea'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      resetFormAndTableDataWhenWHChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key !== 'warehouseId' &&
            key != 'creatorArea'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      initUpload(data, flag) {
        this.$refs.ErpUpload.initUpload(data, flag)
      },
    },
    filters: {
      emptyFilter(val) {
        return val ? val : '--'
      },
    },
  }
</script>

<style scoped lang="scss">
  .h3-wrapper {
    display: flex;

    .inspection-result {
      flex: 1;
    }
  }

  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mb15 {
    margin-bottom: 15px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .table-container {
    padding: 20px 15px !important;
  }

  .operation {
    display: flex;
    justify-content: space-evenly;

    i {
      font-size: 20px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }

  ::v-deep .el-input-number--small {
    width: 100px !important;
  }

  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }

  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }

  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }
</style>

<style>
  .inbound .el-select-dropdown__item {
    width: 200px;
  }

  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
