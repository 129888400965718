<!--
  功能：功能描述:账号授权组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2023年12月12日 11:11:07
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="pro-container">
    <el-form label-position="top">
      <div class="accountAuthorization-top">
        <!-- 状态 -->
        <el-form-item :label="$t('mailConfig.status')">
          <el-radio-group v-model="form.readStatus" :disabled="disabled">
            <el-radio :label="0">
              {{
                $t('mailConfig.statusUpdateStoreadAfterOpenedByOtherAccounts')
              }}
            </el-radio>
            <el-radio :label="1" class="mt10">
              {{
                $t('mailConfig.statusRemainsUnreadAfterOpenedByOtherAccounts')
              }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 转发 -->
        <el-form-item :label="$t('mailConfig.forword')">
          <el-radio-group v-model="form.forwardStatus" :disabled="disabled">
            <el-radio :label="0">
              {{ $t('mailConfig.authorizedAccountsAreNotAllowedToForwardEM') }}
            </el-radio>
            <el-radio :label="1" class="mt10">
              {{ $t('mailConfig.authorizedAccountsAreAllowedToForwardEmails') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 回复 -->
        <el-form-item :label="$t('mailConfig.reply')">
          <el-radio-group v-model="form.replyStatus" :disabled="disabled">
            <el-radio :label="0">
              {{
                $t('mailConfig.authorizedAccountsAreNotAllowedToReplyEmails')
              }}
            </el-radio>
            <el-radio :label="1" class="mt10">
              {{ $t('mailConfig.authorizedAccountsAreAllowedToReplyEmails') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 新建按钮 -->
        <div v-allowed="['mailAccountAuth']">
          <el-button type="text" size="medium" @click="add">
            {{ $t('mailConfig.addnew') }}
          </el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        border
        stripe
        ref="multipleTable"
        class="mail-table"
        :data="tableData"
        align="center"
        :max-height="tableHeight"
      >
        <!-- 授权人 -->
        <el-table-column
          prop="nickName"
          align="center"
          show-overflow-tooltip
          :label="$t('mailConfig.authorizedby')"
        >
          <template slot-scope="scope">
            {{ scope.row[isEn ? 'authUserNameEn' : 'authUserNameCn'] || '--' }}
          </template>
        </el-table-column>
        <!-- 我的邮箱 -->
        <el-table-column
          prop="exportHead"
          align="center"
          show-overflow-tooltip
          :label="$t('mailConfig.myemail')"
        >
          <template slot-scope="scope">
            {{ scope.row.emailAccount || '--' }}
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column
          prop="status"
          align="center"
          show-overflow-tooltip
          :label="$t('mailConfig.status')"
        >
          <template slot-scope="scope">
            {{ getStatus(scope.row.status) }}
          </template>
        </el-table-column>

        <!-- 是否客服 -->
        <el-table-column
          prop="isCustomerService"
          align="center"
          show-overflow-tooltip
          :label="$t('mailConfig.isCustomerService')"
          width="200px"
        >
          <template slot-scope="scope">
            {{
              scope.row.roleType ? $t('mailConfig.yes') : $t('mailConfig.no')
            }}
          </template>
        </el-table-column>

        <!-- 操作 -->
        <el-table-column
          align="center"
          :label="$t('mailConfig.Operate')"
          fixed="right"
          :resizable="false"
          show-overflow-tooltip
          :width="lang === 'en' ? '340' : '280'"
        >
          <template slot-scope="scope">
            <!-- 编辑 -->
            <div v-if="!disabled">
              <el-button
                type="text"
                size="medium"
                v-if="scope.row.status == 0"
                @click="revoke(scope.row)"
                :disabled="disabled"
              >
                {{ $t('mailConfig.revokeauthorization') }}
              </el-button>

              <el-button
                type="text"
                size="medium"
                v-else-if="scope.row.status == 1"
                :disabled="disabled"
                @click="delAuth(scope.row)"
              >
                {{ $t('mailConfig.delete') }}
              </el-button>
              <span v-else>--</span>

              <!-- 客服状态切换按钮 -->
              <el-button
                type="text"
                size="medium"
                :disabled="disabled"
                @click="toggleCustomerService(scope.row)"
              >
                {{
                  scope.row.roleType === 1
                    ? $t('mailConfig.setNonCustomerService')
                    : $t('mailConfig.setCustomerService')
                }}
              </el-button>
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="bottom">
      <el-button type="primary" @click="save">
        {{ $t('inquiryList.Save') }}
      </el-button>
    </div>
    <!-- 新增表单 -->
    <accountAuthorizationForm
      ref="accountAuthorizationForm"
      @fetch-data="getList"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import accountAuthorizationForm from './componetns/accountAuthorizationForm'
  import { hasRight } from '@/utils/permissionBtn'
  import { MailSettingInteractor } from '@/core'
  export default {
    data() {
      return {
        form: {
          readStatus: '', //已读状态（0:标记已读,1:保持未读）
          forwardStatus: '', //转发状态（0:不允许转发,1:允许转发收件箱）
          replyStatus: '', //回复状态（0:不允许回复,1:允许回复）
        },
        tableData: [],

        total: 0,
        disabled: true,
        formChange: false,
        tableHeight: 400,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      isEn() {
        return this.lang === 'en'
      },
    },
    components: {
      accountAuthorizationForm,
    },
    methods: {
      getTableHeight() {
        const { height } = document.body.getBoundingClientRect()
        const topEl = document.querySelector('.accountAuthorization-top')
        if (topEl) {
          this.tableHeight =
            height - topEl.getBoundingClientRect()?.height - 146
        }
        this.getted = true
      },
      // 获取列表数据
      getList() {
        MailSettingInteractor.myAuthEmailApi({}).then((res) => {
          if (res?.code === '000000') {
            this.tableData = res?.data || []
          }
        })
      },
      // 获取表单初始化数据
      getDetail() {
        MailSettingInteractor.myAuthConfigApi().then((res) => {
          if (res?.code === '000000') {
            this.form = {
              ...res.data,
            }
            this.formChange = false
          }
        })
        this.disabled = !hasRight('mailAccountAuth')

        setTimeout(() => {
          this.getTableHeight()
        }, 1000)
      },
      // 切换时调用接口
      save() {
        MailSettingInteractor.changeUserAuthConfigApi({
          ...this.form,
        }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.$message.success(this.$t('reqmsg.$9'))
            this.getDetail()
          }
        })
      },

      // 获取状态
      getStatus(val) {
        const status2lang = {
          0: 'mailConfig.authorizing',
          1: 'mailConfig.Theotherpartyhasbeenuntied',
          2: 'mailConfig.unbundle',
        }

        return this.$t(status2lang[val])
      },

      // 解除授权
      revoke(item) {
        this.$confirm(
          this.$t('mailConfig.confirmdeauthorization'),
          this.$t('reqmsg.M2005'),
          {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          }
        )
          .then(() => {
            MailSettingInteractor.cancelAuthEmailApi({ id: item.id }).then(
              (res) => {
                if (res?.code === '000000' && res?.data) {
                  this.$message.success(this.$t('reqmsg.$7'))
                  this.getList()
                }
              }
            )
          })
          .catch(() => {})
      },
      // 删除
      delAuth(item) {
        MailSettingInteractor.delAuthEmailApi({
          id: item.id,
        }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.$message.success(this.$t('reqmsg.$7'))
            this.getList()
          }
        })
      },
      // 切换客服状态
      toggleCustomerService(item) {
        // 如果是设置为非客服，需要二次确认
        if (item.roleType === 1) {
          this.$confirm(
            this.$t('mailConfig.confirmdeauthorization') +
              '\n' +
              this.$t('mailConfig.resetInfo'),
            this.$t('reqmsg.M2005'),
            {
              confirmButtonText: this.$t('reqmsg.M2006'),
              cancelButtonText: this.$t('reqmsg.M2007'),
              type: 'warning',
            }
          )
            .then(() => {
              this.callToggleCustomerServiceApi(item)
            })
            .catch(() => {})
        } else {
          // 设置为客服不需要二次确认
          this.callToggleCustomerServiceApi(item)
        }
      },

      // 调用切换客服状态API
      callToggleCustomerServiceApi(item) {
        MailSettingInteractor.toggleCustomerServiceApi({
          roleType: item.roleType === 1 ? 0 : 1,
          authUserId: item.authUserId,
          emailAccount: item.emailAccount,
        }).then((res) => {
          if (res?.code === '000000' && res?.data) {
            this.$message.success(this.$t('reqmsg.$9'))
            this.getList()
          }
        })
      },
      add() {
        this.$refs.accountAuthorizationForm.showAddEdit()
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-radio-group {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
  .bottom {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
  }
</style>
