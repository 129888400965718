<template>
  <div class="custom-container">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="22" class="page-header-left">
        <el-row
          type="flex"
          class="align_center flex_row_y flex_wrap top-search"
        >
          <div>
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              label-width="49px"
              @submit.native.prevent
            >
              <el-form-item>
                <el-input
                  style="width: 300px"
                  v-model="queryForm.number"
                  placeholder="请输入询价单号/询盘单号/产品名称"
                  clearable
                  @keyup.enter.native="initLoading"
                />
              </el-form-item>
            </el-form>
          </div>
          <el-button @click="initLoading">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
          <el-button @click="highSearch">高级筛选</el-button>
          <el-button
            v-show="handlerVisible"
            @click="handler"
            v-allowed="['Allot:Solve:Person']"
          >
            分配处理人
          </el-button>
        </el-row>
      </el-col>
      <el-col :span="2">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="item + index"
                v-model="item.isShow"
                :disabled="item.checkable === false"
                :label="item.label"
              >
                {{ item.label }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button
                type="primary"
                class="ml10"
                size="mini"
                plain
                @click="operateSave()"
              >
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip
                effect="dark"
                content="列表可拖动排序"
                placement="top-start"
              >
                <el-button plain type="primary">自定义显示</el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>

    <el-radio-group
      class="mt15 mb15"
      v-model="queryForm.state"
      @change="initLoading"
    >
      <el-radio
        v-for="(item, i) in orderStatusList"
        :key="i"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio>
    </el-radio-group>
    <ErpTable
      :page-number.sync="page.pageNo"
      :page-size.sync="page.pageLe"
      :table-data="tableData"
      :total="total"
      :extraHeight="30"
      class="table-fixed"
      @query="getList"
      :key="customTableKey"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template #default="{ row }">
          <div class="text-left" v-if="item.prop === 'number'">
            <span class="page-link" @click="detail(row.inquiryOrderId, 'show')">
              {{ row.number }}
            </span>

            <i
              class="el-icon-warning"
              style="color: red"
              v-if="row.updated === 1"
            ></i>
          </div>
          <!-- 产品图片 -->
          <div v-else-if="item.prop === 'productImg'" class="img-wrap">
            <el-badge
              v-show="row.productImg && row.productImg.split(',').length > 1"
              :value="row.productImg ? row.productImg.split(',').length : ''"
            ></el-badge>
            <el-image
              v-if="row.productImg"
              :src="row.productImg.split(',')[0]"
              class="c_pointer"
              @click="handlePreview(row.productImg.split(','))"
            ></el-image>
            <span v-else>--</span>
          </div>
          <div v-else-if="item.prop === 'state'">
            <el-tag v-if="row.state == 0" type="info">待处理</el-tag>
            <el-tag v-else-if="row.state == 1" type="success">已完成</el-tag>
            <el-tag v-else-if="row.state == 2" type="info">已失效</el-tag>
          </div>
          <!-- 处理人 -->
          <div v-else-if="item.prop === 'handler'">
            <div
              v-if="!row.handler && permissionsTab('Allot:Solve:Person')"
              class="page-link"
              @click="handler(row.inquiryOrderId)"
            >
              待分配
            </div>
            <div v-else>{{ row.handler || '--' }}</div>
          </div>
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="80"
        fixed="right"
        :resizable="false"
      >
        <template slot-scope="scope">
          <el-tooltip content="询价" placement="top">
            <el-button
              type="primary"
              v-show="
                scope.row.state == 0 &&
                scope.row.handler &&
                scope.row.operateInquiryOrder === 1
              "
              @click="inquiry(scope.row.inquiryOrderId)"
              plain
              size="mini"
              icon="iconfont icon-icon--copy"
              circle
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 询价 -->
    <inquiry-model ref="inquiryModel"></inquiry-model>

    <!-- 查看验货详情 -->
    <inquiry-demand-detail ref="inquiryDemandDetail"></inquiry-demand-detail>

    <!-- 高级筛选Drawer -->
    <el-drawer
      title="高级筛选"
      :visible.sync="drawer"
      :before-close="highSearchClose"
    >
      <!-- 验货管理 -->
      <demandHighSearch
        @cancelClick="highSearchClose"
        @searchClick="searchClick"
      />
    </el-drawer>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
  import ErpDraggable from 'vuedraggable'
  import UserChoose from '@/components/userChoose'
  import { InquiryOrderInteractor } from '@/core/interactors/purchase/InquiryOrder'
  import inquiryModel from './components/inquiryModel.vue'
  import inquiryDemandDetail from './components/inquiryDemandDetail.vue'
  import demandHighSearch from '../inquiryDemand/components/demandHighSearch.vue'
  import { mapGetters } from 'vuex'
  import CustomCache from '@/utils/custom-cache'
  import customTableMixin from '@/utils/custom-table-mixin'
  import { getKeyIds } from '@/utils/pagelist-util'

  export default {
    name: 'inquiryDemand',
    mixins: [customTableMixin],
    components: {
      ErpDraggable,
      inquiryModel,
      inquiryDemandDetail,
      UserChoose,
      demandHighSearch,
    },
    data() {
      return {
        queryForm: {
          state: '',
        },
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        businessIds: '',
        userCustomizeColumnId: '', //自义定保存id
        isInspector: true,
        tableData: [],
        selectRows: [], //table勾选
        drawer: false, //高级筛选抽屉
        orderStatusList: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '待处理',
            value: 0,
          },
          {
            label: '已完成',
            value: 1,
          },
        ],
        columns: [
          {
            label: '询价单号',
            prop: 'number',
            isShow: true,
            checkable: false,
            width: 160,
          },
          {
            label: '产品图片',
            prop: 'productImg',
            isShow: true,
            checkable: true,
          },
          {
            label: '产品名称',
            prop: 'productEn',
            isShow: true,
            checkable: false,
          },
          {
            label: '需求数量',
            prop: 'amount',
            isShow: true,
            checkable: true,
          },
          {
            label: '产品类别',
            prop: 'category',
            isShow: true,
            checkable: true,
          },
          {
            label: '产品描述',
            prop: 'productDescribe',
            isShow: true,
            checkable: true,
          },
          {
            label: '状态',
            prop: 'state',
            isShow: true,
            checkable: true,
          },
          {
            label: '处理人',
            prop: 'handler',
            isShow: true,
            checkable: true,
          },
          {
            label: '创建人',
            prop: 'creator',
            isShow: true,
            checkable: false,
          },
          {
            label: '所属地区',
            prop: 'creatorArea',
            isShow: true,
            checkable: true,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: true,
            checkable: true,
          },
        ],
        handlerVisible: true, // 是否显示分配处理人
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'user/permissions',
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    created() {
      this.getColumsData()
    },
    activated() {
      this.getList()
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.getList()
        }
      },
    },
    methods: {
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(11, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 11,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      initLoading() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getList()
      },

      // 获取列表
      getList() {
        const params = {
          ...this.queryForm,
          ...this.page,
          keyIds: getKeyIds(this),
        }
        InquiryOrderInteractor.inquiryListPageApi(params).then((res) => {
          if (res && res.code == '000000') {
            const data = res.data
            this.tableData = data.data ? data.data : []
            this.total = data.total ? data.total : 0
          }
        })
        this.selectRows = []
      },

      // 表格勾选
      setSelectRows(rows) {
        this.handlerVisible = rows.every(
          (item) => item.state == 0 && !item.handler
        )
        // 获取批量分配处理人id
        this.businessIds = []
        rows.forEach((item) => this.businessIds.push(item.inquiryOrderId))
        this.businessIds = this.businessIds.join(',')
      },

      // 分配处理人
      handler(id) {
        if (Number(id)) this.businessIds = id
        if (!this.businessIds) {
          this.$message.warning('请至少选择一条询价单号！')
        } else {
          this.$refs['UserChoose'].showAddEdit(null, null, true)
        }
      },

      // 获取客户代表
      chooseUser(userIdArr) {
        let self = this
        const params = {
          businessIds: self.businessIds,
          handler: userIdArr[0].name,
          handlerEn: userIdArr[0].englishName,
          handlerId: userIdArr[0].id,
        }
        InquiryOrderInteractor.assignHandlerApi(params).then((res) => {
          if (res && res.code == '000000') {
            self.$message.success('设置成功!')
            self.initLoading()
          }
        })
      },

      // 询价
      inquiry(id) {
        this.$refs.inquiryModel.initLoading(id)
      },

      // 详情
      detail(id, str) {
        this.$refs.inquiryDemandDetail.initLoading(id, str)
      },

      // 高级筛选
      highSearch() {
        this.queryFormType = this.queryForm.state
        this.drawer = true
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },

      // 高级筛选触发
      searchClick(val) {
        this.queryForm = val
        this.$set(this.queryForm, 'state', this.queryFormType)
        this.initLoading()
        this.highSearchClose()
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;
    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }
    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
</style>
