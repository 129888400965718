<template>
  <div class="book-info">
    <template v-if="detail && detail.infoId == null">
      <div class="user-locked">
        <i class="icon el-icon-lock"></i>
        <!-- <div class="desc">暂无查看客户信息权限，请找管理员处理~</div> -->
        <div class="desc">{{ $t('reqmsg.$15') }}</div>
      </div>
    </template>
    <template v-else>
      <div class="info-item">
        <!-- <div>基本信息</div> -->
        <div>{{ $t('other.baseInfo') }}</div>
        <div>
          <el-row :gutter="25">
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>电子邮箱：</span> -->
                <span>{{ $t('cusDetail.Mail') }}:</span>
                <span>{{ detail ? detail.email || '--' : '--' }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>邮编：</span> -->
                <span>{{ $t('cusDetail.ZipCode') }}:</span>
                <span>{{ '--' }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>客户姓名：</span> -->
                <span>{{ $t('cusManage.CustomerName') }}:</span>
                <span>{{ detail ? detail.customerName || '--' : '--' }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>网址：</span> -->
                <span>{{ $t('cusManage.WebAddress') }}:</span>
                <span>{{ detail ? detail.website || '--' : '--' }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>公司名称：</span> -->
                <span>{{ $t('cusManage.CompanyName') }}:</span>
                <span>{{ detail ? detail.companyName || '--' : '--' }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>传真：</span> -->
                <span>{{ $t('cusManage.FaxNumber') }}:</span>
                <span>{{ detail ? detail.faxNumber || '--' : '--' }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>公司地址：</span> -->
                <span>{{ $t('cusManage.CompanyAddress') }}:</span>
                <span>{{ detail ? detail.companyAddress || '--' : '--' }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>负责人：</span> -->
                <span>{{ $t('cusManage.ResponsiblePerson') }}:</span>
                <span>{{ detail ? detail.belongerName || '--' : '--' }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>客户来源：</span> -->
                <span>{{ $t('cusManage.CustomerSource') }}:</span>
                <span>
                  {{ detail ? detail.customerSourceName || '--' : '--' }}
                </span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>客户类型：</span> -->
                <span>{{ $t('cusManage.CustomerType') }}:</span>
                <span>
                  {{
                    detail
                      ? detail.customerType == 0
                        ? '潜在客户'
                        : detail.customerType == 1
                        ? '正式客户'
                        : detail.customerType == 2
                        ? '黑名单'
                        : '--'
                      : '--'
                  }}
                </span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :span="24">
              <div class="info-detail vertical">
                <!-- <span>客户级别：</span> -->
                <span>{{ $t('cusManage.CustomerLevel') }}:</span>
                <span>
                  <el-rate
                    :value="detail ? Number(detail.customerRank) : 0"
                    disabled
                  ></el-rate>
                </span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :span="24">
              <div class="info-detail vertical">
                <!-- <span>备注：</span> -->
                <span>{{ $t('cusManage.Remark') }}:</span>
                <span>{{ detail ? detail.remark || '--' : '--' }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="info-item">
        <!-- <div>联系人信息</div> -->
        <div>{{ $t('other.cinfo') }}</div>
        <div>
          <el-row :gutter="25">
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>联系人：</span> -->
                <span>{{ $t('cusManage.Contacts') }}:</span>
                <span>
                  {{
                    detail
                      ? linkmans.linkman
                      : email
                      ? email.split('@')[0]
                      : '--'
                  }}
                </span>
              </div>
              <div class="info-detail vertical">
                <!-- <span>邮箱地址：</span> -->
                <span>{{ $t('cusDetail.Mail') }}:</span>
                <span>{{ detail ? linkmans.email : email }}</span>
              </div>
              <div class="info-detail vertical">
                <!-- <span>联系电话：</span> -->
                <span>{{ $t('cusDetail.ContactNumber') }}:</span>
                <span>{{ detail ? linkmans.phone || '--' : '--' }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="info-detail vertical">
                <!-- <span>WhatsApp：</span> -->
                <span>{{ $t('cusDetail.WhatsApp') }}:</span>
                <span>{{ detail ? linkmans.whatsapp || '--' : '--' }}</span>
              </div>
              <div class="info-detail vertical">
                <!-- <span>Skyoe:</span> -->
                <span>{{ $t('cusDetail.Skype') }}:</span>
                <span>{{ detail ? linkmans.skype || '--' : '--' }}</span>
              </div>
              <div class="info-detail vertical">
                <!-- <span>WeChat:</span> -->
                <span>{{ $t('cusDetail.Wechat') }}:</span>
                <span>{{ detail ? linkmans.wechat || '--' : '--' }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="info-item btns">
        <div></div>
        <div class="btn-group" v-if="email">
          <!-- <el-button type="primary" @click="handleToWrite">发邮件</el-button> -->
          <el-button type="primary" @click="handleToWrite">
            {{ $t('other.sendemails') }}
          </el-button>
          <!-- <el-button type="primary" plain @click="handleToReceive">
            查看联系人来往邮件
          </el-button>
          <el-button
            type="primary"
            v-if="detail && detail.infoId"
            plain
            @click="toDetail"
          >
            客户详情
          </el-button> -->
          <el-button type="primary" plain @click="handleToReceive">
            {{ $t('other.Checktheemailofcontacts') }}
          </el-button>
          <el-button
            type="primary"
            v-if="detail && detail.infoId"
            plain
            @click="toDetail"
          >
            {{ $t('other.Customerdetails') }}
          </el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getDetailForEmail } from '@/api/mail/contacts.js'
import { mapGetters } from 'vuex'
import { BusOn } from '@/utils/eventBus'
export default {
  data() {
    return {
      detail: null,
      linkmans: null,
      email: null,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
      userInfo: 'user/userInfo',
    }),
  },
  mixins: [BusOn],
  created() {},
  methods: {
    initData(v, initDetail) {
      this.email = v.contactEmail
      if (initDetail !== undefined) {
        if (initDetail) {
          this.detail = initDetail
          this.linkmans =
            initDetail.linkmans && initDetail.linkmans.length
              ? initDetail.linkmans.find(
                  (item) => item.email === v.contactEmail
                )
              : null
        } else {
          this.detail = null
          this.linkmans = null
        }
      } else {
        this.detail = null
        this.linkmans = null
        getDetailForEmail({ email: v.contactEmail }).then((res) => {
          if (res.code === '000000') {
            if (res.data) {
              this.detail = res.data
              this.linkmans =
                res.data.linkmans && res.data.linkmans.length
                  ? res.data.linkmans.find(
                      (item) => item.email === v.contactEmail
                    )
                  : null
            } else {
              this.detail = null
              this.linkmans = null
            }
          }
        })
      }
    },
    handleToReceive() {
      this.$store.commit('mail/setSearchType', 0)
      this.$store.commit('mail/setSearchWord', this.email)
      let serve = this.$store.state.mail.currentAccountServer
      let mailName = this.$store.state.mail.mailFolderName
      this.$router.push({
        path: '/mail/my-mail/email-list',
        query: {
          mailName: mailName,
          serve: serve,
          time: 1,
        },
      })
    },
    handleToWrite() {
      let url = `${window.location.origin}/#/write?from=book&receive=${this.email}`
      this.utils.openWindow(url)
      // this.$router.push({
      //   path: '/mail/my-mail/write',
      //   query: {
      //     from: 'book',
      //     receive: this.email,
      //   },
      // })
    },
    // 跳转到客户详情
    toDetail() {
      this.$router.push({
        path: '/client-supplier/client-manage-detail',
        query: {
          customerId: this.detail.infoId,
          operate: 'show',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.book-info {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  border-left: 1px solid #ebeef5;
  overflow-y: auto;
  .info-item {
    & > div:first-child {
      line-height: 56px;
      border-bottom: 1px solid #ebeef5;
      font-size: 14px;
      font-family: 'PingFang Bold';
      color: #303030;
    }
    .info-detail {
      margin-top: 20px;
      line-height: 36px;
      font-size: 14px;
      padding: 0 36px;
      display: flex;
      & > span:first-child {
        color: #777777;
        width: 80px;
        text-align: left;
      }
      & > span:last-child {
        color: #303133;
        flex: 1;
        /deep/ .el-rate__icon {
          line-height: 36px !important;
        }
      }
      &.vertical {
        display: flex;
        flex-direction: column;
        span {
          width: 100%;
        }
      }
    }
    &.btns {
      margin-top: 20px;
    }
    .btn-group {
      margin-top: 30px;
    }
  }
}
.user-locked {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  .icon {
    font-size: 60px;
    color: #e5e5e5;
  }
  .desc {
    font-size: 14px;
    color: #9999;
    line-height: 1.5;
  }
}
</style>
