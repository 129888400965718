import * as _ from 'dayjs'
import { mapGetters } from 'vuex'
import IndexCardItem from './components/index-card-item'
import IndexCardEmpty from './components/index-card-empty'
import IndexCard from './components/index-card'
import { updateChooseStatus } from '@/api/index'
const mixins = {
  props: {
    cardData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      active: 'ME',
      singleNum: 0,
      teamNum: 0,
      loading: false,
      pageLe: 20,
      pageNo: 1,
      boldStyle: {
        bold: {
          fontWeight: 'bold',
        },
        bolder: {
          fontWeight: 'bolder',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      config: 'kanpan/homepageConfig',
    }),
    itemStyleObj() {
      return {
        cursor: this.active === 'ME' ? 'pointer' : '',
      }
    },
  },
  components: {
    IndexCardItem,
    IndexCard,
    IndexCardEmpty,
  },
  methods: {
    handleTabClick(type) {
      this.active = type
      this.pageNo = 1
      this.list = []
      if (!this.getList || typeof this.getList !== 'function') {
        throw new Error('Component attribute getList need to be a funtion type')
      }
      this.getList()
    },
    beforeListItemClick() {
      const { tabs, active } = this
      if (tabs !== true) {
        return true
      } else {
        return active === 'ME'
      }
    },
    getIds() {
      let chooseIds = [],
        unChooseIds = []
      this.config.forEach((item) => {
        item.voList.forEach((subItem) => {
          if (subItem.divStatus === '1') {
            chooseIds.push(subItem.configId)
          } else {
            unChooseIds.push(subItem.configId)
          }
        })
      })
      return {
        chooseIds,
        unChooseIds,
      }
    },
    save() {
      this.$store.dispatch('kanpan/setHomepageConfig', this.config)
      updateChooseStatus(this.getIds()).then((res) => {
        if (res.code === '000000') {
          this.showDialog = false
        }
      })
      return true
    },
    remove() {
      this.cardData.divStatus = '0'
      this.save()
    }
  },
  filters: {
    timeFilter(val, whole) {
      if (!val) {
        return '-'
      } else {
        var formatter = whole ? 'YY.MM.DD HH:mm' : 'M.DD HH:mm'
        return _(val).format(formatter)
      }
    },
  },
}

export default mixins
