<template>
  <el-dialog
    class="plan-order"
    title="安排订单"
    v-if="planOrderVisible"
    :visible.sync="planOrderVisible"
    :before-close="close"
    width="1400px"
  >
    <div class="container">
      <el-form :model="form" ref="ruleForm">
        <!-- <el-checkbox v-model="form.checked" class="mb10">
          勾选后，采购订单审核通过，会自动发送 “安排生产” 邮件给客户
        </el-checkbox> -->
        <div class="mb-10">
          是否以当前时间自动顺延计划装运日期？
          <el-button
            type="primary"
            @click="confirm"
            class="ml-10 mr-10"
            style="background-color: #169bd5"
            size="mini"
            v-if="!isDelayed"
          >
            确定
          </el-button>
          <el-button @click="reset" size="mini" v-if="isDelayed">
            恢复
          </el-button>
        </div>
        <div class="c-table-wrap">
          <el-table
            :data="form.tableData"
            :span-method="
              (...rest) => mergeRowByProp(...rest, form.tableData, mergeList)
            "
            border
            max-height="500"
          >
            <el-table-column
              prop="orderCode"
              label="订单编号"
              min-width="160"
              show-overflow-tooltip
            />
            <el-table-column label="Item" min-width="200" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{
                  row.item && row.item.includes('****')
                    ? row.item.split('****')[1]
                    : '--'
                }}
              </template>
            </el-table-column>

            <el-table-column label="英文规格" min-width="200" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.specification || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="中文规格" min-width="200" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.specificationCn || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="Qty" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.amount || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="印刷方式" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{ row.printingWayEn || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="计价方式" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{
                  (row.productExtend &&
                    row.productExtend.printingPositionValue) ||
                  '--'
                }}
              </template>
            </el-table-column>
            <el-table-column label="印刷位置" show-overflow-tooltip>
              <template v-slot="{ row }">
                {{
                  (row.productExtend &&
                    row.productExtend.valuationMethodValue) ||
                  '--'
                }}
              </template>
            </el-table-column>
            <!-- 计划装运日期 -->
            <el-table-column
              align="center"
              prop="planShippingDate"
              :label="$t('orderList.plannedShipmentDate')"
              width="200"
            >
              <template slot="header">
                <PlannedShipmentDate />
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'tableData.' +
                    [scope.$index] +
                    '.productExtend.planShippingDate'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <CalculatedDatePicker
                    v-model="
                      form.tableData[scope.$index].productExtend
                        .planShippingDate
                    "
                    type="date"
                    calc-type="planShippingDate"
                    :product-info="form.tableData[scope.$index]"
                    :address-list="scope.row.addressList"
                    :quant-type="scope.row.quantType"
                    :placeholder="$t('placeholder.plsSel')"
                    :picker-options="{
                      disabledDate: (time) => {
                        return (
                          time >
                          new Date(
                            new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                          )
                        )
                      },
                    }"
                    clearable
                  ></CalculatedDatePicker>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 运输方式 -->
            <el-table-column
              align="center"
              prop="shippingMethod"
              :label="$t('orderDetails.ShippingMethod')"
              width="150"
              class="required-th"
            >
              <template slot="header">
                <span style="color: red">*</span>
                <span>{{ $t('orderDetails.ShippingMethod') }}</span>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'tableData.' +
                    [scope.$index] +
                    '.productExtend.shippingMethodId'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSelect'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.productExtend.shippingMethodId"
                    :placeholder="$t('placeholder.plsSel')"
                    @change="
                      changeShipMethod(
                        scope.row,
                        scope.row.productExtend.shippingMethodId
                      )
                    "
                  >
                    <el-option
                      v-for="item in shippingMethodList"
                      :key="item.id"
                      :label="item.transportWayCn"
                      :value="item.id"
                    />
                  </el-select>
                  <p v-if="scope.row.shippingDelay === 1" class="red-pink">
                    可能错过 Firm 交期
                  </p>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column min-width="200" align="center">
              <template slot="header">
                <CustomerDeliveryTime />
              </template>
              <template slot-scope="scope">
                <el-form-item
                  style="margin-top: 33px;"
                  :prop="
                    'tableData.' +
                    [scope.$index] +
                    '.productExtend.customerDeliveryDate'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <div>
                    <CalculatedDatePicker
                      v-model="
                        form.tableData[scope.$index].productExtend
                          .customerDeliveryDate
                      "
                      type="date"
                      checkDeliveryDate
                      :product-info="form.tableData[scope.$index]"
                      :address-list="scope.row.addressList"
                      :quant-type="scope.row.quantType"
                      :disabled="
                        form.tableData[scope.$index].productExtend.firmFlag ===
                        1
                      "
                      :placeholder="$t('placeholder.plsSel')"
                      :picker-options="{
                        disabledDate: (time) => {
                          return (
                            time >
                            new Date(
                              new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                            )
                          )
                        },
                      }"
                      @calc-delivery="calcDelivery"
                      clearable
                    ></CalculatedDatePicker>
                    <p class="text-left">
                      <el-checkbox
                        v-model="
                          form.tableData[scope.$index].productExtend.firmFlag
                        "
                        :true-label="1"
                        :false-label="0"
                      >
                        Firm交期
                      </el-checkbox>
                    </p>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column min-width="160">
              <template #header>
                <span style="color: red">*</span>
                采购开发
              </template>
              <template v-slot="{ row, $index }">
                <el-form-item
                  :prop="'tableData[' + $index + '].salesId'"
                  :rules="rules.salesId"
                >
                  <UserSelect
                    roleName="采购员"
                    @user-choose="(user) => handleUserChoose(user, row)"
                    :defaultUser="{
                      userName: row.salesName,
                      englishName: row.salesNameEn,
                      userId: row.salesId,
                    }"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="订单备注" min-width="160">
              <template v-slot="{ row, $index }">
                <el-form-item
                  :prop="'tableData[' + $index + '].remark'"
                  :rules="rules.remark"
                >
                  <el-input
                    v-model="row.remark"
                    placeholder="请输入"
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <el-checkbox class="mt10" v-model="sendEmailFlag">
            发送邮件
          </el-checkbox>
          <p class="mt5 f_s_12" style="color: #999; margin-left: 23px">
            勾选后，采购订单初次审核通过后，会自动发送“安排生产”邮件给客户
          </p>
        </div>
      </el-form>
    </div>
    <div class="text-center mt20">
      <el-button type="primary" @click="save()" :loading="isLoading">
        {{ isLoading ? '加载中' : '确认' }}
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import UserChoose from '@/components/userChoose'
  import UserSelect from '@/components/user-select'
  import CalculatedDatePicker from '@/components/CalculatedDatePicker'
  import { isShippingDelay } from '@/components/CalculatedDatePicker/util'

  import {
    PlannedShipmentDate,
    CustomerDeliveryTime,
  } from '@/components/shipping'
  import {
    orderAlignOrderShow,
    orderAlignOrderList,
    orderShipType,
  } from '@/api/order.js'
  import { listUserByRoleName } from '@/api/finance/credit-manage-list'
  import { mapGetters } from 'vuex'
  import { getSettingMsg } from '@/views/system-manage/mail-setting/util/utils.js'
  import { mergeRowByProp } from 'kits'
  import {
    getAddressList,
    batchDelayDate,
  } from '@/components/CalculatedDatePicker/util'

  export default {
    props: ['orderIds'],

    components: {
      UserChoose,
      UserSelect,
      CalculatedDatePicker, // 日期选择
      PlannedShipmentDate, // 计划发货日期
      CustomerDeliveryTime, // 客户交期
    },

    data() {
      return {
        mergeRowByProp,
        form: {
          tableData: [],
        },
        planOrderVisible: false,
        isLoading: false,
        sendEmailFlag: false, // 是否默认发送邮件 1:发送 0不发送
        purchaseUsers: [], //采购开发下拉列表
        mergeList: [
          { column: 0, prop: 'orderCode' },
          { column: 1, prop: 'item' },
        ],
        rules: {
          expectedDeliveryDate: [
            { required: true, message: '期望交期必填', trigger: 'change' },
          ],
          salesId: [
            { required: true, message: '采购开发必填', trigger: 'change' },
          ],
          remark: [
            { max: 500, message: '字符长度不能超过500', trigger: 'blur' },
          ],
        },
        lock: true, // 第一次打开弹框，选中某个采购开发，将每一行的采购开发都回显该值，再次选择时，不需要联动
        isDelayed: false, // 是否已自动顺延装运日期
        shippingMethodList: [],
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    methods: {
      //切换运输方式
      changeShipMethod(product, shippingMethodId) {
        if (!shippingMethodId) {
          product.productExtend.shippingMethodType = ''
          product.productExtend.shippingMethod = ''
          product.productExtend.shippingMethodId = ''
          return
        }
        this.shippingMethodList.forEach((item) => {
          if (shippingMethodId == item.id) {
            product.productExtend.shippingMethodType = item.transportType
            product.productExtend.shippingMethod = item.transportWayCn
          }
        })
      },
      // 计算日期
      calcDelivery(callback) {
        callback()
      },
      // 自动顺延计划装运日期
      confirm() {
        batchDelayDate(this.form.tableData)
        this.isDelayed = true
      },

      reset() {
        this.form.tableData.forEach((s) => {
          s.productExtend.planShippingDate =
            s.productExtend.initPlanShippingDate
          s.productExtend.customerDeliveryDate =
            s.productExtend.initCustomerDeliveryDate
        })
        this.isDelayed = false
      },

      //权限-客户代表角色下拉
      async getListUserByRoleName() {
        let response = await listUserByRoleName({
          divideGroup: 0,
          name: '采购员',
          tenantId: this.userInfo.tenantId,
        })
        if (response.code === '000000') {
          this.purchaseUsers = response.data
        }
      },
      // 订单回显
      showOrderInfo(ids, rows) {
        this.planOrderVisible = false
        orderAlignOrderShow({
          orderIds: ids,
          orderProductUniqueId: rows[0].orderProductUniqueId || '',
        }).then((res) => {
          if (res?.code === '000000') {
            this.planOrderVisible = true
            this.lock = true
            const data = this.getDataByResData(res.data, rows)
            // 给每个对象产品行赋值addressList
            data.forEach((item, index) => {
              item.addressList = getAddressList(
                item,
                item.cargoDistributionVOS,
                item.receivingAddressVOS
              )
            })
            this.form.tableData = data
          }
        })

        // 安排订单
        this.getListUserByRoleName()
      },

      getDataByResData(data, rows) {
        /**
         * 接口返回的数据结构：
         *  [
         *    { orderCode: 1, item: 'xxx', vos: [{ id: 1 }] },
         *    { orderCode: 2, item: 'yyy', vos: [{ id: 2 }, { id: 3 }] },
         *  ]
         * 需要处理成：
         *  [
         *    { orderCode: 1, item: '1****xxx', id: 1, expectedDeliveryDate: '' },
         *    { orderCode: 2, item: '2****yyy', id: 2, expectedDeliveryDate: '' },
         *    { orderCode: 2, item: '2****yyy', id: 3, expectedDeliveryDate: '' },
         *  ]
         */
        const getItem = (row, vo) => {
          return {
            orderCode: row.orderCode,
            item: `${row.orderCode}****${row.item}`,
            ...vo,
            // 期望交期：列表中的shippingDate
            // expectedDeliveryDate: rows.find(
            //   (n) => n.orderCode === row.orderCode
            // )?.shippingDate,

            cargoDistributionVOS: row.cargoDistributionVOS,
            receivingAddressVOS: row.receivingAddressVOS,
            quantType: row.quantType,
            createTime: row.createTime,
            productExtend: {
              ...vo.productExtend,
              initPlanShippingDate: vo.productExtend.planShippingDate,
              initCustomerDeliveryDate: vo.productExtend.customerDeliveryDate,
            },
          }
        }

        if (!data) return
        const newData = data.reduce((finalList, row) => {
          if (Array.isArray(row.vos)) {
            if (row.vos.length === 1) {
              finalList.push(getItem(row, row.vos[0]))
            } else {
              for (const vo of row.vos) {
                finalList.push(getItem(row, vo))
              }
            }
          }
          return finalList
        }, [])
        return newData
      },

      // 采购开发选中事件
      handleUserChoose(user, row) {
        row.salesId = user?.userId
        row.salesName = user?.userName
        row.salesNameEn = user?.englishName
        if (this.lock && user) {
          this.lock = false
          for (const item of this.form.tableData) {
            item.salesId = user?.userId
            item.salesName = user?.userName
            item.salesNameEn = user?.englishName
          }
        }
      },

      // 保存数据处理
      saveFormatData(list) {
        let arr = []
        list.forEach((item, i) => {
          item.vos.forEach((obj, k) => {
            arr.push(obj)
          })
        })
        return arr
      },

      // checkDate
      checkDate() {
        let flag = true
        for (let i = 0; i < this.form.tableData.length; i++) {
          // 如果shippingDate大于customerDeliveryDate，提示错误
          const { planShippingDate, customerDeliveryDate } =
            this.form.tableData[i].productExtend
          if (planShippingDate > customerDeliveryDate) {
            flag = false
            this.$message.warning('计划装运日期不能晚于客户交期日期')
            break
          }
        }
        return flag
      },

      // 保存
      save() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 校驗日期大小
            if (!this.checkDate()) return
            if (isShippingDelay(this.form.tableData)) return
            this.isLoading = true
            orderAlignOrderList({
              alignOrderDTOS: this.form.tableData,
              sendEmailFlag: this.sendEmailFlag ? 1 : 0,
            }).then((res) => {
              if (res && res.code == '000000') {
                this.$message.success('保存成功')
                this.close()
                this.$parent.getList()
              } else {
                this.isLoading = false
              }
            })
          }
        })
      },

      // 关闭弹窗
      close() {
        this.isLoading = false
        this.sendEmailFlag = false
        this.planOrderVisible = false
        this.isDelayed = false
      },
    },

    created() {
      getSettingMsg(this.userInfo.tenantId).then((data) => {})
      // 获取货运方式
      orderShipType().then((res) => {
        this.shippingMethodList = res?.data
      })
    },
  }
</script>

<style lang="scss" scoped>
  .plan-order {
    .mb-10 {
      margin-bottom: 10px;
    }
    .container {
      /deep/.el-form {
        .el-form-item {
          margin-bottom: 0;
          &.is-error {
            margin-bottom: 18px;
          }
          .el-date-editor {
            width: 100%;
          }
          .el-form-item__error {
            z-index: 1000;
          }
        }
      }
    }
    ::v-deep {
      .el-form-item__error {
        margin-top: 0;
      }
      .el-date-editor .el-input__inner {
        height: 32px !important;
      }
    }
  }
  .red-pink {
    font-size: 12px;
  }
</style>
