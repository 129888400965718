<template>
  <div class="ticket-management">
    <listSearch @submit="submitFn" ref="listSearch" @resetCondition="resetCondition"></listSearch>
    <div class="btn-group">
      <el-button class="mr20" type="primary" @click="handleAdd" v-if="hasRight('TICKET:UPLOAD')"> 新增 </el-button>
      <el-button class="mr20" type="danger" plain @click="turnDownFn(null)" v-if="hasRight('TICKET:TURNDOWN') && toBeConfirmedBol"> 驳回 </el-button>
      <el-button class="mr20" type="danger"  @click="receiveRefundSlipFn(7)" plain v-if="hasRight('TICKET:BANKREFUND') && confirmedBol"> 银行退回 </el-button>
      <el-button class="mr20" @click="receiveRefundSlipFn(1)" v-if="hasRight('TICKET:GENERATEREFUNDRECEIPT') && confirmedBol"> 生成收退款单 </el-button>
      <el-button class="mr20" @click="exportTicketAttachments" v-if="hasRight('TICKET:EXPORTTICKETAPPENDIX')"> 导出票据信息及附件 </el-button>
      <el-button class="mr20" @click="exportTicketVerification" v-if="hasRight('TICKET:EXPORTBILLWRITEOFF')"> 导出票据核销明细 </el-button>
    </div>
     <!-- 状态列表 -->
     <div class="status-list">
      <el-radio-group  v-model="receiptStatus" @change="receiptStatusChange">
        <el-radio v-for="(item, i) in statusList" :key="i" :label="item.code">
          {{ item.name }}
        </el-radio>
      </el-radio-group>
      <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
        <erp-draggable v-bind="dragOptions" :list="columns">
          <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
            :disabled="!item.checked" :label="item.label">
            {{ item.label }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn">
          <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
            保存
          </el-button>
        </div>
        <template #reference>
          <el-tooltip effect="dark" content="列表可拖动排序" placement="top-start">
            <el-button plain type="primary" class="pub-h-36">
              自定义显示
            </el-button>
          </el-tooltip>
        </template>
      </el-popover>
     </div>
     <!-- 表格数据 -->
     <ErpTable :page-number.sync="page.pageNo" :page-size.sync="page.pageLe" :table-data="tableData" :total="total"
      :extraHeight="218" @query="getList" class="table-fixed" ref="ErpTable" @selection-change="setSelectRows"
     :key="tablekey"   @update:pageSize="page.pageNo = 1" >
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="item.label"  show-overflow-tooltip
        :width="item.width" min-width="120" :resizable="false" align="center" :prop="item.prop">
        <template slot="header">
          <span>{{ item.label }}</span>
          <el-tooltip v-if="item.prop === 'generatePaymentReceiptStatus'" effect="dark" placement="top">
            <div slot="content" style="max-width: 200px;">
              比较“票据金额”与“SUM(该票据生成的非【已驳回、已作废】收款单的收款金额) - SUM(非【已撤销、审核驳回、已作废】退款单的退款总金额)”，等于为已生成，大于为部分生成，后者0值为未生成
            </div>
            <i class="el-icon-warning ml-5"></i>
          </el-tooltip>
        </template>
        <template slot-scope="{row}">
          <span v-if="item.prop === 'receiptCode'">
            <span class="page-link">
              <router-link :to="`/finance-center/ticket-management/ticket-management-details?id=${
                  row.id
                }`">
                {{ row.receiptCode }}
              </router-link>
            </span>
          </span>
          <span v-else-if="item.prop === 'paymentType'">
            {{ row.paymentType == 0 ? '支票' : '电汇' }}
          </span>
          <span v-else-if="item.prop === 'receiptCurrency'">
            {{ row.receiptCurrency == 0 ? '美元' : '人民币' }}
          </span>
          <span v-else-if="item.prop === 'receiptStatus'">
            {{ row.receiptStatus | receiptStatusFilter }}
          </span>
          <span v-else-if="item.prop === 'generatePaymentReceiptStatus'">
            {{ row.generatePaymentReceiptStatus | generatePaymentReceiptStatusFilter }}
          </span>
          <span v-else-if="item.prop === 'receiptVerificationStatus'">
            {{ row.receiptVerificationStatus | receiptVerificationStatusFilter }}
          </span>
          <span v-else-if="item.prop === 'source'">
            {{ row.source | billSourceOptionsFilter }}
          </span>
          <span v-else>
            {{ item.isMoney ?addThousandSeparator(row[item.prop]) : row[item.prop] ? row[item.prop] : '--'}}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200" fixed="right" :resizable="false">
        <template slot-scope="scope">
            <el-tooltip class="item" content="核销" placement="top" v-show="scope.row.receiptStatus === 0 || scope.row.receiptStatus === 1">
              <el-button type="primary" plain size="mini" icon="el-icon-pie-chart" v-allowed="['TICKET:WRITEOFF']"
                circle @click="receiveRefundSlipFn(scope.row.receiptStatus === 0 ? 5 : 6,scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" content="生成收退款单" placement="top" v-show="scope.row.receiptStatus === 1">
              <el-button type="primary" plain size="mini" icon="el-icon-document" v-allowed="['TICKET:GENERATEREFUNDRECEIPT']"
                circle @click="receiveRefundSlipFn(2,scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" content="确认" placement="top" v-show="scope.row.receiptStatus === 0">
              <el-button type="primary" plain size="mini" icon="el-icon-check" v-allowed="['TICKET:CONFIRM']"
                circle @click="receiveRefundSlipFn(4,scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" content="银行退回" placement="top" v-show="scope.row.receiptStatus === 1">
              <el-button type="danger" plain size="mini" icon="el-icon-close" v-allowed="['TICKET:BANKREFUND']"
                circle @click="receiveRefundSlipFn(3,scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" content="驳回" placement="top" v-show="scope.row.receiptStatus === 0">
              <el-button type="danger" plain size="mini" icon="el-icon-close" v-allowed="['TICKET:TURNDOWN']"
                circle @click="turnDownFn(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" content="重新提交" placement="top" v-show="scope.row.receiptStatus === 2 || scope.row.receiptStatus === 3">
              <el-button type="primary" plain size="mini" icon="iconfont icon-zhongxintijiao" v-allowed="['TICKET:RESUBMIT']"
                circle @click="handleReSubmit(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" content="编辑" placement="top" v-show="scope.row.receiptStatus === 0">
              <el-button type="primary" plain size="mini"  icon="iconfont el-icon-edit" circle 
              v-allowed="['TICKET:EDIT']" v-if="scope.row.optFlag" @click="handleEdit(scope.row)"></el-button>
            </el-tooltip>
        </template>
      </el-table-column>
    </ErpTable>
    <!-- 新增弹窗 -->
     <addNewNote :visibleAdd.sync="visibleAdd" v-if="visibleAdd" :financeReceiptId = "financeReceiptId" :source="financeReceiptId ? 2 : 0" @addSuccessFn="resetSearch"></addNewNote>
     <!-- 驳回弹窗 -->
     <turnDown :visibleTurnDown.sync="visibleTurnDown" @turnDownConfirmFn="turnDownConfirmFn" v-if="visibleTurnDown"></turnDown>
     <!-- 操作弹窗 -->
     <operateDialog  :type=operateType :visibleOperate.sync="visibleOperate" :receiveRefundSlipList="receiveRefundSlipList" @operateConfirm="getList"></operateDialog>
     <!-- 导出成功 -->
     <success-model :visible.sync="downloadVisible"></success-model>
  </div>
</template>
<script>
import listSearch from './components/list-search.vue';
import addNewNote from './components/add-new-note.vue';
import operateDialog from './components/operate-dialog.vue'
import turnDown from './components/turn-down.vue'
import { addThousandSeparator,dateTimeFormat } from 'kits'
import { columns , statusList, writeOffStatusOptions , paymentStatusOptions,billSourceOptions } from './utils';
import { mapGetters } from 'vuex'
import ErpDraggable from 'vuedraggable'
import CustomCache from '@/utils/custom-cache'
import { TicketManagementInteractor } from '@/core'
import SuccessModel from '@/components/tableImport/successModel'
import { hasRight } from '@/utils/permissionBtn'
export default{
  name: 'TicketManagement',
  data() {
    return {
       hasRight,//权限按钮
       downloadVisible:false,//导出成功弹窗
       visibleOperate:false,//操作弹窗
       operateType:1,//操作弹窗类型
       receiptStatus:null,//默认选中待确认
       visibleAdd: false, //新增弹窗
       financeReceiptId: '', //重新提交票据id
       userCustomizeColumnId: '',
       visibleTurnDown: false, //驳回弹窗
       columns,//表格列
       statusList,//状态列表
       tableData:[],//表格数据
       tablekey: Math.random(),//表格key
       page: {
          pageNo: 1,
          pageLe: 10,
        },//分页
        total: 100,//总条数
        selectionIds: [], //表格选择行ids
        searchParams: {},//搜索条件
        receiveRefundSlipList:[],//收退款单数据
        billSourceOptions,//票据来源选项
        turnDownId:'',//单个驳回id
        confirmedBol:true,//确认状态按钮
        toBeConfirmedBol:true,//待确认状态按钮
      }
  },
  components:{
    listSearch,
    ErpDraggable,
    addNewNote,
    turnDown,
    operateDialog,
    SuccessModel
  },
  computed: {
    ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      // 最终显示的列
     finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
      // 自定义显示列
    dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
  },
  filters: {
    // 状态过滤
    receiptStatusFilter(val){
      return statusList.find(item => item.code === val).name
    },
    // 核销状态过滤
    receiptVerificationStatusFilter(val){
      return writeOffStatusOptions.find(item => item.value === val).label
    },
    // 生成收款单状态选项过滤
    generatePaymentReceiptStatusFilter(val){
      return paymentStatusOptions.find(item => item.value === val).label
    },
    // 票据来源过滤
    billSourceOptionsFilter(val){
      return billSourceOptions.find(item => item.code === val).name
    }
  },
  created() {
    this.initGetcolumn()
  },
  methods: {
    // 提交搜索条件
    submitFn(params) {
      this.searchParams = params
      this.page.pageNo = 1
      this.page.pageLe = 10
      // this.receiptStatus = 0
      this.getList()
    }, 
    // 重新获取搜索条件值
   async resetCondition(params){
      this.searchParams = await params
      this.page.pageNo = 1
      this.page.pageLe = 10
    },
    // 获取表格数据
    async getList(){
      this.searchParams.receiptStatus = this.receiptStatus
      this.searchParams.pageNo = this.page.pageNo
      this.searchParams.pageLe = this.page.pageLe
      const res = await TicketManagementInteractor.listPageApi(this.searchParams)
      if(res?.code === '000000'){
        this.tableData = res?.data?.data
        this.total = res?.data?.total
      }else{
        this.tableData = []
        this.total = 0
      }
    },
    // 重置查询
    resetSearch() {
      this.$refs?.listSearch?.resetForm()
    },
    // 切换状态
    receiptStatusChange(val) {
      this.tablekey = Math.random()
      this.receiptStatus = val
      this.$refs.listSearch.submitForm()
    },
    // 选择行
    setSelectRows(val) {
      this.selectionIds = val.map(item => item.id);// 选择行id
      this.confirmedBol = val.every(item => item.receiptStatus === 1) // 已确认状态显示按钮
      this.toBeConfirmedBol = val.every(item => item.receiptStatus === 0) // 待确认状态显示按钮
      if(!val?.length){
        this.confirmedBol = true
        this.toBeConfirmedBol = true
      }
    },
    // 初始化列
    initGetcolumn() {
      CustomCache.getColumns(69, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.userCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
    },
    // 保存自定义显示列
    operateSave() {
      let customizeData = JSON.stringify(this.columns)
      let params = {
        userCustomizeColumnId: this.userCustomizeColumnId,
        menuType: 69,// 票据管理自定义列
        userId: this.userInfo.userId,
        customizeData,
      }
      CustomCache.setColumns(params).then((res) => {
        if (res.code === '000000') {
          this.userCustomizeColumnId = res.data.userCustomizeColumnId
          // 操作成功
          return this.$baseMessage(
            '操作成功',
            'success',
            false,
            'erp-hey-message-success'
          )
        }
      })
    },
    // 新增
    handleAdd() {
      this.financeReceiptId = ''
      this.visibleAdd = true
    },
    // 驳回
    turnDownFn(id = null) {
      this.turnDownId = id
      if(!this.selectionIds.length && !id){
        this.$message.warning('请至少选择一条数据！')
        return;
      }
      this.visibleTurnDown = true
    },
    // 驳回确认
    async turnDownConfirmFn(msg){
      const params = {
        financeReceiptIds: this.turnDownId ? [this.turnDownId] : this.selectionIds,
        rejectionReason: msg,
      }
      const res = await TicketManagementInteractor.rejectApi(params)
      if(res?.code === '000000'){
        this.$message.success('操作成功')
        this.visibleTurnDown = false
        this.resetSearch()
      }
    },
    // 批量生成收退款单
    async receiveRefundSlipFn(type,row){
      this.operateType = type
      let ids = []
      switch (type) {
        // 生成收退款单
        case 2:
        //银行退回
        case 3:
        // 确认收款
        case 4:
        //核销
        case 5:
        case 6:
        ids = [row.id]
        break; 
        // 批量生成收/退款单
        case 1:
        // 批量银行退回
        case 7:
        if(!this.selectionIds.length){
          this.$message.warning('请至少选择一条数据！')
          return;
        }  
        ids = this.selectionIds
        break;
        default:
        break;
      } 
      await this.processingDataFn(ids)
      this.visibleOperate = true
    },
    //处理数据    
    async processingDataFn(ids){
      const apiName = [3,7].includes(this.operateType) ? 'detailWhenBankReturnApi' : 'batchDetailWithOptApi'
      const res = await TicketManagementInteractor[apiName](ids)
        if(res?.code === '000000'){
          this.receiveRefundSlipList = res?.data?.map(item=>{ 
            // 生成收退款单和确认票据 带出默认收款账号
            if([1,2].includes(this.operateType)){
              if(item?.riseDefaultAccountId)item.receivingAccountId = item.riseDefaultAccountId
              if(!item?.receivingDate)item.receivingDate = dateTimeFormat('yyyy-MM-dd',new Date().getTime())
            }
            item.delVerifyIds = []
            item.moreSearch = true
            item.noteAttachment = item.files.filter(file=> file.bizType === 0)
            item.emailScreenshot = item.files.filter(file=> file.bizType ===1)
            item.ticketTableData = item.verifies
            delete item.files
            delete item.verifies
            delete item.riseDefaultAccountId
            return {
              ...item
            }
          })
        }else{
          return
        }
    },
    // 处理千分位
    addThousandSeparator(val) {    
      return addThousandSeparator(val,true)    
    },
    // 导出票据信息及附件
    async exportTicketAttachments(){
      await this.$refs.listSearch.resetCondition()
      if(!this.searchParams?.confirmTimeStart || !this.searchParams?.confirmTimeEnd){
        this.$refs.listSearch.setConfirmTime();
        await this.$refs.listSearch.resetCondition()
      }
      const params = JSON.parse(JSON.stringify(this.searchParams))
      delete params.receiptStatus
      delete params.pageNo
      delete params.pageLe
      const res = await TicketManagementInteractor.exportReceiptAndFileApi(params)
      if(res?.code === '000000')this.downloadVisible = true
    }, 
    // 导出票据核销流水
    async exportTicketVerification(){
      await this.$refs.listSearch.resetCondition()
      // if(!this.searchParams?.verificationTimeStart || !this.searchParams?.verificationTimeEnd){
      //   this.$message.warning('请选择核销时间')
      //   return;
      // }
      const params = JSON.parse(JSON.stringify(this.searchParams))
      delete params.receiptStatus
      delete params.pageNo
      delete params.pageLe
      const res = await TicketManagementInteractor.verifyExportApi(params)
      if(res?.code === '000000')this.downloadVisible = true 
    },  
    // 点击编辑
    handleEdit(row){
      this.$router.push({
        name:'ticketManagementEdit',
        query: {
          id:row?.id
        }
      })
    },
    // 处理重新提交 
    handleReSubmit(id){
      this.financeReceiptId = id
      this.visibleAdd = true
    }
  },
  watch: {
    columns: {
      handler(val) {
        this.tablekey = Math.random()
      },
      deep: true,
    }
  },

}

</script>
<style lang="scss" scoped>
.ticket-management{
  padding: 10px;
  .status-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    // ::v-deep{
    //   input[aria-hidden="true"]{
    //     display: none !important;
    //   }
    // }
  }
}
</style>