<template>
  <div class="order-product">
    <el-form ref="ruleForm" :model="ruleForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item prop="codeOrName">
            <el-input
              v-model="ruleForm.codeOrName"
              :placeholder="$t('placeholder.M2023')"
              maxlength="50"
              clearable
              @keyup.enter.native="search"
            />
          </el-form-item>
        </el-col>
        <!-- 产品运营/创建人 -->
        <el-col v-if="showUserSelect" :span="6">
          <UserSelect
            ref="userSelect"
            style="width: 100%"
            :placeholder="$t('orderList.operatorCreator')"
            @user-choose="chooseUser"
          />
        </el-col>
        <el-col v-show="!single" :span="4">
          <el-form-item prop="categoryId">
            <el-cascader
              ref="cascaderAddr"
              v-model="categoryId"
              :options="categoryList"
              :props="lang === 'en' ? optionPropsEn : optionPropsCn"
              clearable
              class="w100"
              :show-all-levels="false"
              :placeholder="$t('quoteDetails.ProductCategory')"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="showTypeSelect" :span="7">
          <el-form-item prop="productType">
            <el-select
              v-model="ruleForm.productType"
              :placeholder="$t('productDetail.ProductType')"
            >
              <el-option
                v-for="i in typeList"
                :key="i.code"
                :label="$t(i.langKey)"
                :value="i.code"
              />
            </el-select>
            <el-button class="pub-h-36 ml10" @click="search">
              {{ $t('productDetail.Search') }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="container">
      <el-table
        ref="multipleTable"
        border
        :data="tableData"
        height="450"
        :row-key="
          (row) => {
            return row.skuId
          }
        "
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="40"
        />
        <!-- 产品编码 -->
        <el-table-column
          align="center"
          :label="$t('productCenter.ProductCode')"
          prop="skuId"
          width="120"
        >
          <template slot-scope="scope">
            <div class="page-link">
              <router-link
                :to="`/product/ProductView?proId=${
                  scope.row.productId
                }&noReturn=${true}`"
                target="_blank"
              >
                {{ scope.row.skuId }}
              </router-link>
            </div>
          </template>
        </el-table-column>
        <!-- 产品名称 -->
        <el-table-column
          v-if="lang != 'en'"
          width="200"
          align="center"
          :label="$t('cusDetail.ProductName')"
          prop="nameCn"
          show-overflow-tooltip
        />
        <!-- 英文品名 -->
        <el-table-column
          width="200"
          align="center"
          :label="$t('productCenter.EnglishName')"
          prop="nameEn"
          show-overflow-tooltip
        />
        <!-- 英文规格 -->
        <el-table-column
          align="center"
          :label="$t('addInquiry.EnglishSpecification')"
          min-width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              formatColumn(
                scope.row.specsType,
                scope.row.specsValue1,
                scope.row.specsValue2
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('addInquiry.CNSpecification')"
          min-width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              formatColumn(
                scope.row.specsTypeCn,
                scope.row.specsValueCn1,
                scope.row.specsValueCn2
              )
            }}
          </template>
        </el-table-column>
        <!-- 产品运营 -->
        <el-table-column
          width="160"
          align="center"
          :label="$t('productCenter.ProductOperations')"
          prop="productBelonger"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              lang === 'en'
                ? scope.row.productBelongerEn || '--'
                : scope.row.productBelonger || '--'
            }}
          </template>
        </el-table-column>

        <!-- 创建人 -->
        <el-table-column
          width="160"
          align="center"
          :label="$t('cusDetail.Creator')"
          prop="creator"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              lang === 'en'
                ? scope.row.creatorEn || '--'
                : scope.row.creator || '--'
            }}
          </template>
        </el-table-column>
        <!-- 产品类别 -->
        <el-table-column
          width="180"
          align="center"
          :label="$t('productCenter.ProductCategory')"
          prop="categoryName"
        />
        <!-- 产品类型 -->
        <el-table-column
          width="160"
          align="center"
          :label="$t('productCenter.ProductType')"
          prop="standardCustomized"
        >
          <template slot-scope="scope">
            {{ getLangProductType(scope.row.standardCustomized) }}
          </template>
        </el-table-column>
        <!-- 可用库存 -->
        <el-table-column
          v-if="lang != 'en'"
          width="180"
          align="center"
          :label="$t('productCenter.AvailableStock')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.stockWarehouseNumVOList &&
                scope.row.stockWarehouseNumVOList.length > 1
              "
            >
              <el-popover
                v-if="scope.row.stockWarehouseNumVOList.length > 1"
                placement="right"
                width="200"
                trigger="hover"
                :content="formatStock(scope.row.stockWarehouseNumVOList)"
              >
                <span slot="reference">
                  {{
                    formatStock(scope.row.stockWarehouseNumVOList.slice(0, 1))
                  }}
                  <span class="more">
                    等{{ scope.row.stockWarehouseNumVOList.length }}个
                  </span>
                </span>
              </el-popover>
            </span>
            <span v-else>
              {{ formatStock(scope.row.stockWarehouseNumVOList) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      :current-page="page.pageNo"
      :page-size="page.pageLe"
      :total="total"
      @current-change="currentChange"
    />

    <div class="text-center mt20">
      <!-- 确定 -->
      <el-button type="primary" @click="save()">
        {{ $t('productDetail.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="close()">{{ $t('productDetail.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
  import {
    productGetSkuByProductId,
    selectProductList,
  } from '@/api/product/productList'
  import { categoryListForSelect } from '@/api/product/productPrintWay'
  import { mapGetters } from 'vuex'
  import UserSelect from '@/components/user-select'
  import { getLabels } from '@/utils/specs-utils'

  export default {
    components: {
      UserSelect,
    },
    props: {
      selectProductList: {
        type: Array,
        default: () => [],
      },
      purchaseDemandAddFlag: {
        type: Number,
        required: false,
      },
      requestType: {
        type: Boolean,
        default: false,
      },
      single: {
        type: Boolean,
        default: false,
      },

      showUserSelect: {
        type: Boolean,
        default: false,
      },
      showTypeSelect: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableData: [],
        categoryList: [],
        optionPropsCn: {
          value: 'productCategoryId',
          label: 'nameCn',
          children: 'children',
          checkStrictly: true,
        },
        optionPropsEn: {
          value: 'productCategoryId',
          label: 'nameEn',
          children: 'children',
          checkStrictly: true,
        },
        page: {
          pageLe: 10,
          pageNo: 1,
        },
        total: 0,
        ruleForm: {
          codeOrName: '',
          categoryId: '',
          productType: 0,
          userId: '',
        },
        categoryId: '',
        selectRows: [], // 当前分页选中的数据
        vals: '',
        typeList: [
          {
            langKey: 'orderDetails.All',
            code: 0,
          },
          {
            langKey: 'productCenter.StandardProduct',

            code: 1,
          },
          {
            langKey: 'productCenter.CustomizedProduct',
            code: 2,
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {
      this.getList()
      this.getDict()
    },
    methods: {
      chooseUser(user) {
        if (user) {
          this.ruleForm.userId = user.userId
        } else {
          this.ruleForm.userId = ''
        }
      },
      // 获取下拉字典项
      getDict() {
        categoryListForSelect().then((res) => {
          this.categoryList = res?.data || []
        })
      },

      getList() {
        const params = {
          ...this.page,
          ...this.ruleForm,
        }
        if (this.purchaseDemandAddFlag) {
          params.flag = this.purchaseDemandAddFlag
        }
        const requestMethod = this.requestType
          ? selectProductList
          : productGetSkuByProductId
        requestMethod(params).then((res) => {
          if (res?.code === '000000') {
            this.tableData = res.data?.data || []
            this.total = res.data.total || 0
          }
        })
      },

      // 搜索
      search() {
        this.ruleForm.categoryId = this.categoryId[this.categoryId.length - 1]
        this.page.pageNo = 1
        this.getList()
      },

      // 数据格式处理
      formatColumn(type, value1, value2) {
        const arr = type?.split(',')
        if (!arr || !arr[0]) {
          return '--'
        } else if (arr[0] && arr.length == 1) {
          return arr + this.$SVSplicer + value1
        } else {
          return (
            arr[0] +
            this.$SVSplicer +
            value1 +
            this.$2SSplicer +
            arr[1] +
            this.$SVSplicer +
            value2
          )
        }
      },

      // 选中
      selectionChange(rows) {
        this.selectRows = rows
      },

      // 切换分页
      currentChange(val) {
        this.page.pageNo = val
        this.getList()
      },

      // 确认
      save() {
        // 请选择一条数据
        if (!this.selectRows.length) {
          this.$message.warning(this.$t('reqmsg.G002'))
          return false
        }
        // 客评中心新增选择产品只能添加一条
        if (this.single && this.selectRows.length > 1) {
          this.$message.warning(this.$t('reqmsg.G009'))
          return false
        }
        let data = []
        data = this.selectRows.map((item) => {
          const { label, labelCn } = getLabels(item)
          return {
            ...item,
            specification: label,
            specificationCn: labelCn,
          }
        })
        this.$emit('getProductData', data)
      },

      // 关闭
      close() {
        this.$emit('productClose')
        this.$refs['ruleForm'].resetFields()
      },

      // 格式化库存数据
      formatStock(stockData) {
        let __stockInfo = []
        if (Array.isArray(stockData) && stockData.length > 0) {
          for (let i = 0; stockData.length > i; i++) {
            __stockInfo.push(
              `${stockData[i].warehouseName}:${stockData[i].num}`
            )
          }
        } else {
          __stockInfo.push(`--`)
        }
        return __stockInfo.toString()
      },

      // 产品类型中英文翻译
      getLangProductType(state) {
        let text = ''
        switch (state) {
          case '标准品':
            text = this.$t('orderDetails.StandardProduct')
            break
          case '订制品':
            text = this.$t('orderDetails.CustomizedProduct')
            break
          case '辅料':
            text = this.$t('orderDetails.Excipients')
            break
        }
        return text
      },
    },
  }
</script>

<style lang="scss" scoped>
  .more {
    color: #a0a0a0;
  }
</style>
