<template>
  <el-row class="table-search" :gutter="40">
    <el-form
      :model="tableSearch"
      :rules="rules"
      ref="tableSearch"
      label-width="92px"
      label-position="right"
      :key="formKey"
    >
      <el-col :span="5" class="print-position">
        <el-form-item
          :label="$t('productDetail.PrintingPosition')"
          :prop="standardBol ? 'printingPositionIds' : ''"
        >
          <el-select
            v-model.sync="tableSearch.printingPositionIds"
            multiple
            collapse-tags
            @change="printingPositionsChangeFn"
            v-if="!productPrintingSwitch"
          >
            <el-option
              v-for="item in printPosition"
              :key="item.productSettingId"
              :label="lang === 'zh' ? item.nameCn : item.nameEn"
              :value="item.productSettingId"
              v-if="
                tableSearch.printingPositionIdsOptions.indexOf(
                  item.productSettingId
                ) > -1
              "
            />
          </el-select>

          <el-select
            v-else
            v-model="tableSearch.printingPositionIds"
            :placeholder="$t('placeholder.M2006')"
            multiple
            collapse-tags
            @change="printingPositionsChangeFn"
          >
            <el-option
              v-for="(item, index) of options"
              :key="index"
              :label="lang === 'zh' ? item.nameCn : item.nameEn"
              :value="item.nameEn"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4" style="margin-left: -20px">
        <div class="location-info">
          <div
            class="location-btn location-btn-reduce"
            @click="changeLocationNum(1)"
          >
            <i class="el-icon-arrow-down"></i>
          </div>
          <div class="location-cont">
            {{
              tableSearch.locationNum
                ? tableSearch.locationNum + '-location'
                : ''
            }}
          </div>
          <div
            class="location-btn location-btn-add"
            @click="changeLocationNum(2)"
          >
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </el-col>
      <el-col :span="5">
        <el-form-item
          :label="$t('productDetail.PricingMethod')"
          prop="valuationMethodIds"
        >
          <el-select
            v-model.sync="tableSearch.valuationMethodIds"
            multiple
            collapse-tags
            @change="pricingConfigurationChangeFn"
          >
            <el-option
              v-for="opt in tableSearch.optionsVOS"
              :key="opt.productPrintingPricingOptionsId"
              :label="lang === 'zh' ? opt.pricingValue : opt.pricingValueEn"
              :value="opt.productPrintingPricingOptionsId"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item :label="$t('productDetail.PriceMargin')">
          <el-select
            v-model.sync="tableSearch.profitRateTemplateId"
            collapse-tags
            @change="profitMarginChange"
          >
            <el-option
              v-for="opt in profitMarginOptions"
              :key="opt.productSettingId"
              :label="lang === 'zh' ? opt.nameCn : opt.nameEn"
              :value="opt.productSettingId"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item
          :label="$t('productDetail.ReorderDiscount')"
          label-width="100px"
          class="return-discount"
          prop="returnDiscount"
        >
          <el-input
            v-model="tableSearch.returnDiscount"
            v-only-number.positive="2"
            clearable
          >
            <template slot="append">%</template>
          </el-input>
          <el-tooltip placement="top" effect="light">
            <template slot="content">
              Reorder setup charge = setup
              <br />
              charge * (1 - {{ $t('productDetail.ReorderDiscount') }})
            </template>
            <i class="el-icon-warning-outline" />
          </el-tooltip>
        </el-form-item>
      </el-col>
    </el-form>
  </el-row>
</template>
<script>
  import { listProductSetting } from '@/api/product/productSetting'
  import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
  import { mapGetters } from 'vuex'
  import { deepCopy } from 'kits'
  import _ from 'lodash'
  import Decimal from 'decimal.js'
  export default {
    data() {
      const checkReturnDiscount = (rule, value, callback) => {
        if (+value >= 100) {
          callback(new Error('请输入大于等于0，小于100的数'))
        } else {
          callback()
        }
      }
      return {
        printPosition: [],
        profitMarginOptions: [],
        editPageBol: false,
        rules: {
          printingPositionIds: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          valuationMethodIds: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          profitRateTemplateId: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          returnDiscount: [
            { validator: checkReturnDiscount, trigger: 'change' },
          ],
        },
        paramsData: {},
        oldPricingArr: [],
        selectdPricingMethod: [], //选中的计价方式
        oldPrintingPositionsArr: [], //已选择的印刷位置
        formKey: 1,
      }
    },
    props: {
      searchData: {
        type: Object,
        required: true,
      },
      numberStepList: {
        type: Array,
        required: true,
      },
      transportDay: {
        type: String | Number,
        default: 0,
      },
      productPrintingSwitch: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: () => {
          return []
        },
      },
      selectdPricingArr: {
        type: Array,
        default: () => {
          return []
        },
      },
      selectdPricingPlacehodel: {
        type: Array,
        default: () => {
          return []
        },
      },
      exchange: {
        type: Number,
        default: 0,
      },
      toFixedNumber: {
        type: Number,
        default: 2,
      },
      standardBol: {
        type: Boolean,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      tableSearch: {
        get() {
          return this.searchData
        },
        set(val) {
          this.$emit('update:searchData', val)
        },
      },
    },
    destroyed() {
      this.oldPricingArr = []
      this.tableSearch = {}
      this.selectdPricingMethod = []
    },
    watch: {
      // 数量阶梯改变
      numberStepList: {
        handler(newval, oldval) {
          if (newval.length === oldval.length) {
            this.calculateTableDataFn(5, '', {
              arr: oldval,
            })
            return
          }
          const type = newval.length > oldval.length ? 'add' : 'delete'
          if (type === 'add') {
            // 新增
            const oldvalArr = oldval.map((item) => item.num)
            newval.forEach((item, i) => {
              if (!oldvalArr.includes(item.num)) {
                this.calculateTableDataFn(3, type, {
                  num: item.num,
                  transportHeadPrice: item.transportHeadPrice,
                  index: i,
                  arr: oldval,
                })
              }
            })
          } else {
            // 减少
            const newvalArr = newval.map((item) => item.num)
            oldval.forEach((item, index) => {
              if (!newvalArr.includes(item.num)) {
                this.calculateTableDataFn(3, type, {
                  num: item.num,
                  index,
                  arr: oldval,
                })
              }
            })
          }
        },
        deep: true, // 启用深度监听
      },
      productPrintingSwitch() {
        this.formKey++
      },
      standardBol() {
        this.formKey++
      },
      options(newVal, oldVal) {
        const oldValEC = oldVal.map((item) => item.nameEn)
        const newValEC = newVal.map((item) => item.nameEn)
        if (newVal.length < oldVal.length || newVal.length === oldVal.length) {
          // 印刷位置有删除
          for (let k of oldValEC) {
            if (!newValEC.includes(k)) {
              const changeItem = oldVal.filter((item) => item.nameEn === k)?.[0]
              let printingPositionValue = deepCopy(
                this.tableSearch.printingPositionValue
              )
              let cnIdx = printingPositionValue.findIndex(
                (item) => item === changeItem.nameCn
              )
              printingPositionValue.splice(cnIdx, 1)
              this.tableSearch.printingPositionValue = deepCopy(
                printingPositionValue
              )
              let printingPositionIds = deepCopy(
                this.tableSearch.printingPositionIds
              )
              let enIdx = printingPositionIds.findIndex(
                (item) => item === changeItem.nameEn
              )
              printingPositionIds.splice(cnIdx, 1)
              this.tableSearch.printingPositionValueEn =
                deepCopy(printingPositionIds)
              this.tableSearch.printingPositionIds =
                deepCopy(printingPositionIds)
              this.oldPrintingPositionsArr = deepCopy(printingPositionIds)
              // this.tableSearch.productPrintingFullValuationDTOList.splice(
              //   -this.tableSearch.valuationMethodIds.length
              // )
            }
          }
        }
      },
      // 位置数量
      'tableSearch.locationNum'(newVal, oldVal) {
        if (newVal < oldVal) {
          // 减少
          this.calculateTableDataFn(1, 'delete')
        } else {
          // 增加
          this.calculateTableDataFn(1, 'add')
        }
      },
    },
    async created() {
      // 获取印刷位
      this.getPrintPositionByType()
      // 利润率模版
      await this.getListProductSettingFn()
      if (
        this.tableSearch.productPrintingFullValuationDTOList &&
        this.tableSearch.productPrintingFullValuationDTOList.length
      ) {
        this.oldPrintingPositionsArr = deepCopy(
          this.tableSearch?.printingPositionIds
        )
        this.oldPricingArr = deepCopy(this.tableSearch?.valuationMethodIds)
        let len = this.tableSearch?.locationNum ?? 0
        let newArr = this.transformArray(
          this.tableSearch.productPrintingFullValuationDTOList,
          len
        )
        let stencilArr = deepCopy(newArr?.[0] ?? [])

        stencilArr.map((item) => {
          for (let k in item) {
            if (k === 'valuationMethod' || k === 'valuationMethodEn') {
              item[k] = item[k]
            } else if (k === 'publishFee') {
              const obj = this.tableSearch.optionsVOS.filter((val) => {
                return val.pricingValue === item.valuationMethod
              })?.[0]
              item[k] = obj?.fee
            } else {
              item[k] = ''
            }
          }
          const selectProfitMarginArr = deepCopy(
            this.tableSearch.selectProfitMarginArr
          )
          this.numberStepList.forEach((value, index) => {
            if (selectProfitMarginArr && selectProfitMarginArr.length) {
              if (index > this.tableSearch.selectProfitMarginArr.length - 1) {
                item['profitRate' + index] =
                  this.tableSearch.selectProfitMarginArr[
                    this.tableSearch.selectProfitMarginArr.length - 1
                  ]
              } else {
                item['profitRate' + index] =
                  this.tableSearch.selectProfitMarginArr[index]
              }
            } else {
              item['profitRate' + index] = ''
            }

            item['transportHeadPrice' + index] = value.transportHeadPrice
          })
          return item
        })
        this.selectdPricingMethod = deepCopy(stencilArr)
      } else if (
        this.tableSearch.valuationMethodIds &&
        this.tableSearch.valuationMethodIds.length
      ) {
        let stencilArr = []
        this.tableSearch.valuationMethodIds.forEach((val, valIndex) => {
          let item = {
            printingPositionNum: '',
            valuationMethod: this.tableSearch.valuationMethodValue[valIndex],
            valuationMethodEn: this.tableSearch.valuationMethodValueEn[valIndex]
              ? this.tableSearch.valuationMethodValueEn[valIndex]
              : this.tableSearch.valuationMethodValue[valIndex],
            capacity: '',
            price: '',
            fullValuationId: '',
            publishFee: '',
          }
          const selectProfitMarginArr = deepCopy(
            this.tableSearch.selectProfitMarginArr
          )
          this.numberStepList.forEach((value, index) => {
            if (selectProfitMarginArr && selectProfitMarginArr.length) {
              if (index > selectProfitMarginArr.length - 1) {
                item['profitRate' + index] =
                  selectProfitMarginArr[selectProfitMarginArr.length - 1]
              } else {
                item['profitRate' + index] = selectProfitMarginArr[index]
              }
            } else {
              item['profitRate' + index] = ''
            }
            item['transportHeadPrice' + index] = value.transportHeadPrice
            item['price' + index] = ''
            item['printingDay' + index] = ''
            item['printingFrontDay' + index] = ''
            item['deliverDay' + index] = ''
            item['ladderNumId' + index] = ''
            return item
          })
          stencilArr.push(item)
        })
        this.selectdPricingMethod = deepCopy(stencilArr)
        this.oldPricingArr = deepCopy(this.tableSearch.valuationMethodIds)
      }
    },
    methods: {
      // 获取利润率模版profitMarginOptions
      async getListProductSettingFn() {
        let response = await listProductSetting()
        if (response.code === '000000') {
          this.profitMarginOptions = response.data.filter(
            (item) => item.type === 10
          )
          if (
            !this.tableSearch.profitRateTemplateId &&
            this.tableSearch.profitRateTemplateId !== null
          ) {
            let obj = this.profitMarginOptions.filter(
              (item) => item.isDefault === 1
            )?.[0]
            if (!obj) obj = this.profitMarginOptions[0]
            this.tableSearch.profitRateTemplateId = obj?.productSettingId
            const selectProfitMarginArr = JSON.parse(obj.value).map(
              (obj) => Object.values(obj)[0] * 1
            )
            this.$set(
              this.tableSearch,
              'selectProfitMarginArr',
              selectProfitMarginArr
            )
          } else {
            if (
              this.tableSearch.profitRateTemplateId !== 'old' &&
              this.tableSearch.profitRateTemplateId !== null
            ) {
              const obj = this.profitMarginOptions.filter(
                (item) =>
                  item.productSettingId ===
                  this.tableSearch.profitRateTemplateId
              )[0]
              const selectProfitMarginArr = JSON.parse(obj.value).map(
                (obj) => Object.values(obj)[0] * 1
              )
              this.$set(
                this.tableSearch,
                'selectProfitMarginArr',
                selectProfitMarginArr
              )
            } else {
              this.tableSearch.profitRateTemplateId = null
              this.$set(this.tableSearch, 'selectProfitMarginArr', [])
              this.formKey++
            }
          }
        }
      },
      // 切换利润率模版
      profitMarginChange(val) {
        const obj = this.profitMarginOptions.filter(
          (item) => item.productSettingId === val
        )[0]
        let selectProfitMarginArr = JSON.parse(obj.value).map(
          (obj) => Object.values(obj)[0] * 1
        )
        this.tableSearch.selectProfitMarginArr = selectProfitMarginArr
        this.selectdPricingMethod.map((item) => {
          this.numberStepList.forEach((value, index) => {
            if (index > selectProfitMarginArr.length - 1) {
              item['profitRate' + index] =
                selectProfitMarginArr[selectProfitMarginArr.length - 1]
            } else {
              item['profitRate' + index] = selectProfitMarginArr[index]
            }
            return item
          })
        })
        this.calculateTableDataFn(4)
      },
      // 印刷位置
      async getPrintPositionByType() {
        let res = await ProductAddEditInteractor.getPrintPosition(5)
        this.printPosition = res.code === '000000' ? res.data : []
      },
      // 改变印刷位置数量
      changeLocationNum(num) {
        if (num === 1) {
          // 减少
          if (
            this.tableSearch.locationNum < 1 ||
            this.tableSearch.locationNum <=
              this.tableSearch.printingPositionIds.length
          )
            return
          this.tableSearch.locationNum =
            Number(this.tableSearch.locationNum) - 1
        } else {
          // 增加
          this.tableSearch.locationNum =
            Number(this.tableSearch.locationNum) + 1
        }
      },
      // 印刷位置改变 printPosition
      printingPositionsChangeFn(selectedItems) {
        if (this.tableSearch.locationNum < selectedItems.length) {
          this.tableSearch.locationNum =
            Number(this.tableSearch.locationNum) + 1
        }
        let objArr = []
        if (this.productPrintingSwitch) {
          objArr = this.options.filter((item) =>
            selectedItems.includes(item.nameEn)
          )
        } else {
          objArr = this.printPosition.filter((item) =>
            selectedItems.includes(item.productSettingId)
          )
        }
        this.tableSearch.printingPositionValue = objArr.map(
          (item) => item.nameCn
        )
        this.tableSearch.printingPositionValueEn = objArr.map(
          (item) => item.nameEn
        )
      },
      // 计价方式改变
      pricingConfigurationChangeFn(selectedItems) {
        const objArr = this.tableSearch.optionsVOS.filter((item) =>
          selectedItems.includes(item.productPrintingPricingOptionsId)
        )
        this.tableSearch.valuationMethodValue = objArr.map(
          (item) => item.pricingValue
        )
        this.tableSearch.valuationMethodValueEn = objArr.map(
          (item) => item.pricingValueEn
        )
        const idArr = this.selectdPricingMethod.map(
          (item) => item.productPrintingPricingOptionsId
        )
        if (selectedItems.length > this.oldPricingArr.length) {
          //增加
          for (let k of selectedItems) {
            if (!this.oldPricingArr.includes(k)) {
              const obj = this.tableSearch.optionsVOS.filter(
                (item) => item.productPrintingPricingOptionsId === k
              )?.[0]
              let item = {
                printingPositionNum: '',
                valuationMethod: obj.pricingValue,
                valuationMethodEn: obj.pricingValueEn,
                capacity: '',
                price: '',
                fullValuationId: '',
                publishFee: obj.fee,
              }
              const selectProfitMarginArr = deepCopy(
                this.tableSearch.selectProfitMarginArr
              )
              this.numberStepList.forEach((value, index) => {
                if (selectProfitMarginArr && selectProfitMarginArr.length) {
                  if (index > selectProfitMarginArr.length - 1) {
                    item['profitRate' + index] =
                      selectProfitMarginArr[selectProfitMarginArr.length - 1]
                  } else {
                    item['profitRate' + index] = selectProfitMarginArr[index]
                  }
                } else {
                  item['profitRate' + index] = ''
                }
                item['transportHeadPrice' + index] = value.transportHeadPrice
                item['price' + index] = ''
                item['printingDay' + index] = ''
                item['printingFrontDay' + index] = ''
                item['deliverDay' + index] = ''
                item['ladderNumId' + index] = ''
                return item
              })
              this.selectdPricingMethod.push(item)
              this.calculateTableDataFn(2, 'add', item)
            }
          }
        } else {
          // 减少
          for (let k of this.oldPricingArr) {
            if (!selectedItems.includes(k)) {
              const obj = this.tableSearch.optionsVOS.filter(
                (item) => item.productPrintingPricingOptionsId === k
              )?.[0]
              const idx = this.selectdPricingMethod.findIndex((item) => {
                return item.valuationMethod === obj.pricingValue
              })
              const deleteName =
                this.lang === 'zh' ? obj.pricingValue : obj.pricingValueEn
              this.selectdPricingMethod.splice(idx, 1)
              this.calculateTableDataFn(2, 'delete', { deleteName })
            }
          }
        }
        this.oldPricingArr = deepCopy(selectedItems)
      },
      //一维数组改成二位数组
      transformArray(arr, numChunks) {
        const chunkSize = Math.ceil(arr.length / numChunks)
        const result = []
        for (let i = 0; i < arr.length; i += chunkSize) {
          result.push(arr.slice(i, i + chunkSize))
        }
        return result
      },
      // 秒、分、小时向上取值转换天8小时
      generationTimeFn(type, num, totalNum) {
        const obj = {
          0: () => {
            const dayNUm = Number(num) * 60 * 60 * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          1: () => {
            const dayNUm = num * 60 * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          2: () => {
            const dayNUm = num * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          3: () => {
            return num ? Math.ceil(Number(totalNum) / Number(num)) : ''
          },
          4: () => {
            return num ? Math.ceil(Number(totalNum) / Number(num)) : ''
          },
        }
        return obj[type]()
      },
      //成品价格计算（成本价 ÷ 汇率 + 运费单价）÷ (1 - 利润率)
      calculationFinishedProduct(price, transportHeadPrice, profitRate) {
        price = Number(price)
        transportHeadPrice = Number(transportHeadPrice)
        profitRate = Number(profitRate)
        let sum1 =
          !price || !this.exchange
            ? transportHeadPrice
            : new Decimal(price)
                .div(new Decimal(this.exchange))
                .add(new Decimal(transportHeadPrice))
        let sum2 = profitRate
          ? new Decimal(1).sub(new Decimal(profitRate).div(new Decimal(100)))
          : 1
        // Math.pow(10, this.toFixedNumber): 这部分是将 10 的幂运算，其中 this.toFixedNumber 是您希望保留的小数位数。例如，如果 this.toFixedNumber 是 2，那么这部分就是计算 10 的 2 次方，即 100。
        // sum * Math.pow(10, this.toFixedNumber): 这一步将您计算出的 sum 值乘以上面计算的 10 的幂，这样可以将小数点向右移动 this.toFixedNumber 位，将小数部分转换为整数部分，以便于使用 Math.round() 进行四舍五入。
        // Math.round(sum * Math.pow(10, this.toFixedNumber)): 这是 Math.round() 方法，它将上一步得到的结果进行四舍五入。
        // / Math.pow(10, this.toFixedNumber): 最后，这一步将得到的结果除以 10 的 this.toFixedNumber 次方，将小数点移到正确的位置，恢复到原来的小数位数。
        let sum = new Decimal(sum1).div(new Decimal(sum2)).toNumber()
        sum =
          Math.round(
            new Decimal(sum).mul(new Decimal(Math.pow(10, this.toFixedNumber)))
          ) / Math.pow(10, this.toFixedNumber)
        return sum
      },
      // 计算成品计价表格数据
      calculateTableDataFn(num = 0, type = '', obj = {}) {
        const fnObj = {
          1: () => {
            if (type === 'add') {
              let addArr = deepCopy(this.selectdPricingMethod)
              addArr.map((item) => {
                item.printingPositionNum = this.tableSearch.locationNum
              })
              this.tableSearch.productPrintingFullValuationDTOList.push(
                ...addArr
              )
            } else {
              this.tableSearch.productPrintingFullValuationDTOList.splice(
                -this.tableSearch.valuationMethodIds.length
              )
            }
          },
          2: () => {
            // 计价新增
            if (type === 'add') {
              const len = this.tableSearch.locationNum
              if (!len) return
              if (
                !this.tableSearch.productPrintingFullValuationDTOList.length
              ) {
                Array.from(
                  { length: this.tableSearch.locationNum },
                  (_, index) => index + 1
                ).forEach((value, index) => {
                  let addObj = deepCopy(obj)
                  addObj.printingPositionNum = index + 1
                  this.tableSearch.productPrintingFullValuationDTOList.push(
                    addObj
                  )
                })
                return
              }
              let newArr = this.transformArray(
                this.tableSearch.productPrintingFullValuationDTOList,
                len
              )
              newArr.map((item) => {
                let addObj = deepCopy(obj)
                addObj.printingPositionNum = item[0].printingPositionNum
                item.push(addObj)
              })
              this.tableSearch.productPrintingFullValuationDTOList =
                newArr.reduce((acc, curr) => acc.concat(curr), [])
            } else {
              const firstDataName =
                this.lang === 'zh'
                  ? this.tableSearch?.productPrintingFullValuationDTOList?.[0]
                      ?.valuationMethod
                  : this.tableSearch?.productPrintingFullValuationDTOList?.[0]
                      ?.valuationMethodEn
              this.tableSearch.productPrintingFullValuationDTOList =
                this.tableSearch.productPrintingFullValuationDTOList.filter(
                  (item) => {
                    return this.lang === 'zh'
                      ? item.valuationMethod != obj.deleteName
                      : item.valuationMethodEn != obj.deleteName
                  }
                )
              if (firstDataName === obj.deleteName) {
                this.tableSearch.productPrintingFullValuationDTOList.map(
                  (item) => {
                    item.price = ''
                    return item
                  }
                )
              }
            }
          },
          3: () => {
            if (type === 'add') {
              const oldArr = obj.arr
              const oldNumKeyArr = oldArr.map((item) => item.num)
              const newNumberKeyArr = this.numberStepList.map(
                (item) => item.num
              )
              let newIndex = 0
              for (let k of newNumberKeyArr) {
                if (!oldNumKeyArr.includes(k)) {
                  newIndex = newNumberKeyArr.findIndex((item) => item === k)
                }
              }
              const oldData =
                this.tableSearch.productPrintingFullValuationDTOList.reduce(
                  (previousValue, currentValue) => {
                    let currArr = []
                    for (let k in oldArr) {
                      currArr.push({
                        profitRate: currentValue['profitRate' + k],
                        ladderNumId: currentValue['ladderNumId' + k],
                        num: oldArr[k]['num'],
                        printingDay: currentValue['printingDay' + k],
                        printingFrontDay: currentValue['printingFrontDay' + k],
                        deliverDay: currentValue['deliverDay' + k],
                      })
                    }
                    previousValue.push(currArr)
                    return previousValue
                  },
                  []
                )
              const selectProfitMarginArr = deepCopy(
                this.tableSearch.selectProfitMarginArr
              )
              let setData = deepCopy(
                this.tableSearch.productPrintingFullValuationDTOList
              )
              setData.map((item, k) => {
                this.numberStepList.forEach((value, index) => {
                  if (newIndex === index) {
                    if (newIndex === this.numberStepList.length - 1) {
                      if (
                        selectProfitMarginArr &&
                        selectProfitMarginArr.length
                      ) {
                        if (index > selectProfitMarginArr.length - 1) {
                          item['profitRate' + index] =
                            selectProfitMarginArr[
                              selectProfitMarginArr.length - 1
                            ]
                        } else {
                          item['profitRate' + index] =
                            selectProfitMarginArr[index]
                        }
                        item['ladderNumId' + index] = ''
                      } else {
                        item['profitRate' + index] = ''
                        item['ladderNumId' + index] = ''
                      }
                    } else {
                      item['profitRate' + index] = ''
                      item['ladderNumId' + index] = ''
                    }
                    item['printingDay' + index] = this.generationTimeFn(
                      this.tableSearch.capacityUnit,
                      item.capacity,
                      value.num
                    )
                    item['printingFrontDay' + index] = this.generationTimeFn(
                      this.tableSearch.capacityUnit,
                      item.capacity,
                      value.num
                    )
                    item['deliverDay' + index] =
                      Number(item['printingDay' + index]) +
                      Number(this.tableSearch.transportDay)
                  } else {
                    for (let key of oldData[k]) {
                      if (key['num'] == value['num']) {
                        item['profitRate' + index] = key['profitRate']
                        item['ladderNumId' + index] = key['ladderNumId']
                        item['printingDay' + index] = key['printingDay']
                        item['printingFrontDay' + index] =
                          key['printingFrontDay']
                        item['deliverDay' + index] = key['deliverDay']
                      }
                    }
                  }
                  item['transportHeadPrice' + index] = value.transportHeadPrice
                  item['price' + index] = this.calculationFinishedProduct(
                    item.price,
                    item['transportHeadPrice' + index],
                    item['profitRate' + index]
                  )
                })
                return item
              })
              for (let i in setData) {
                this.$set(
                  this.tableSearch.productPrintingFullValuationDTOList,
                  i,
                  setData[i]
                )
              }
              this.selectdPricingMethod.map((item) => {
                this.numberStepList.forEach((value, index) => {
                  if (selectProfitMarginArr && selectProfitMarginArr.length) {
                    if (newIndex > selectProfitMarginArr.length - 1) {
                      item['profitRate' + index] =
                        selectProfitMarginArr[selectProfitMarginArr.length - 1]
                    } else {
                      item['profitRate' + index] =
                        selectProfitMarginArr[newIndex]
                    }
                  } else {
                    item['profitRate' + index] = ''
                  }
                  item['price' + index] = ''
                  item['printingDay' + index] = ''
                  item['printingFrontDay' + index] = ''
                  item['deliverDay' + index] = ''
                  item['ladderNumId' + index] = ''
                  item['transportHeadPrice' + index] = value.transportHeadPrice
                })
                return item
              })
            } else {
              let productPrintingFullValuationDTOList = deepCopy(
                this.tableSearch.productPrintingFullValuationDTOList
              )
              let producAllArr = []
              productPrintingFullValuationDTOList.forEach((item, index) => {
                let numArr = []
                for (let k in obj.arr.map((item) => item.num)) {
                  let currObj = {
                    profitRate: item['profitRate' + k],
                    price: item['price' + k],
                    printingDay: item['printingDay' + k],
                    printingFrontDay: item['printingFrontDay' + k],
                    deliverDay: item['deliverDay' + k],
                    ladderNumId: item['ladderNumId' + k],
                    transportHeadPrice: item['transportHeadPrice' + k],
                  }
                  delete item['profitRate' + k]
                  delete item['price' + k]
                  delete item['printingDay' + k]
                  delete item['printingFrontDay' + k]
                  delete item['deliverDay' + k]
                  delete item['ladderNumId' + k]
                  delete item['transportHeadPrice' + k]
                  numArr.push(currObj)
                }
                producAllArr.push(numArr)
              })
              producAllArr.map((item) => {
                item.splice(obj.index, 1)
                return item
              })
              const copyProducArr = deepCopy(producAllArr)
              productPrintingFullValuationDTOList.map((val, idx) => {
                copyProducArr[idx].forEach((subItem, index) => {
                  val['profitRate' + index] = subItem.profitRate
                  val['price' + index] = subItem.price
                  val['printingDay' + index] = subItem.printingDay
                  val['printingFrontDay' + index] = subItem.printingFrontDay
                  val['deliverDay' + index] = subItem.deliverDay
                  val['ladderNumId' + index] = subItem.ladderNumId
                  val['transportHeadPrice' + index] = subItem.transportHeadPrice
                })
                return val
              })
              this.tableSearch.productPrintingFullValuationDTOList = deepCopy(
                productPrintingFullValuationDTOList
              )
              let selectdPricingMethod = deepCopy(this.selectdPricingMethod)
              let numAllArr = []
              selectdPricingMethod.forEach((item, index) => {
                let numArr = []
                for (let k in obj.arr.map((item) => item.num)) {
                  let currObj = {
                    profitRate: item['profitRate' + k],
                    price: item['price' + k],
                    printingDay: item['printingDay' + k],
                    printingFrontDay: item['printingFrontDay' + k],
                    deliverDay: item['deliverDay' + k],
                    ladderNumId: item['ladderNumId' + k],
                    transportHeadPrice: item['transportHeadPrice' + k],
                  }
                  numArr.push(currObj)
                  delete item['profitRate' + k]
                  delete item['price' + k]
                  delete item['printingDay' + k]
                  delete item['printingFrontDay' + k]
                  delete item['deliverDay' + k]
                  delete item['ladderNumId' + k]
                  delete item['transportHeadPrice' + k]
                }
                numAllArr.push(numArr)
              })
              numAllArr.map((item) => {
                item.splice(obj.index, 1)
                return item
              })
              const copyArr = deepCopy(numAllArr)
              const selectProfitMarginArr = deepCopy(
                this.tableSearch.selectProfitMarginArr
              )
              selectdPricingMethod.map((val, idx) => {
                copyArr[idx].forEach((subItem, index) => {
                  val['profitRate' + index] =
                    selectProfitMarginArr[
                      index > selectProfitMarginArr.length - 1
                        ? selectProfitMarginArr.length - 1
                        : index
                    ]
                  val['price' + index] = subItem.price
                  val['printingDay' + index] = subItem.printingDay
                  val['printingFrontDay' + index] = subItem.printingFrontDay
                  val['deliverDay' + index] = subItem.deliverDay
                  val['ladderNumId' + index] = subItem.ladderNumId
                  val['transportHeadPrice' + index] = subItem.transportHeadPrice
                })
                return val
              })
              this.selectdPricingMethod = deepCopy(selectdPricingMethod)
            }
          },
          4: () => {
            this.tableSearch.productPrintingFullValuationDTOList.map((item) => {
              this.numberStepList.forEach((value, index) => {
                if (index > this.tableSearch.selectProfitMarginArr.length - 1) {
                  item['profitRate' + index] =
                    this.tableSearch.selectProfitMarginArr[
                      this.tableSearch.selectProfitMarginArr.length - 1
                    ]
                } else {
                  item['profitRate' + index] =
                    this.tableSearch.selectProfitMarginArr[index]
                }
                if (
                  Number(item['profitRate' + index]) == 100 ||
                  (!item['profitRate' + index] &&
                    item['profitRate' + index] != 0)
                ) {
                  item['price' + index] = 0
                } else {
                  item['price' + index] = this.calculationFinishedProduct(
                    item.price,
                    item['transportHeadPrice' + index],
                    item['profitRate' + index]
                  )
                }
              })
              return item
            })
          },
          5: () => {
            const oldArr = obj.arr
            const oldData =
              this.tableSearch.productPrintingFullValuationDTOList.reduce(
                (previousValue, currentValue) => {
                  let currArr = []
                  for (let k in oldArr) {
                    currArr.push({
                      profitRate: currentValue['profitRate' + k],
                      ladderNumId: currentValue['ladderNumId' + k],
                      printingDay: currentValue['printingDay' + k],
                      printingFrontDay: currentValue['printingFrontDay' + k],
                      deliverDay: currentValue['deliverDay' + k],
                      num: oldArr[k]['num'],
                    })
                  }
                  previousValue.push(currArr)
                  return previousValue
                },
                []
              )
            this.tableSearch.productPrintingFullValuationDTOList.map(
              (item, k) => {
                const oldNumKeyArr = oldData[k].map((item) => item.num)
                const newNumberKeyArr = this.numberStepList.map(
                  (item) => item.num
                )
                let oldIndex = -1
                let newIndex = -1
                for (let k of oldNumKeyArr) {
                  if (!newNumberKeyArr.includes(k)) {
                    oldIndex = oldNumKeyArr.findIndex((item) => item === k)
                  }
                }
                for (let k of newNumberKeyArr) {
                  if (!oldNumKeyArr.includes(k)) {
                    newIndex = newNumberKeyArr.findIndex((item) => item === k)
                  }
                }
                this.numberStepList.forEach((value, index) => {
                  if (index == newIndex) {
                    item['profitRate' + index] =
                      oldData[k][oldIndex]['profitRate']
                    item['ladderNumId' + index] =
                      oldData[k][oldIndex]['ladderNumId']

                    item['printingDay' + index] = this.generationTimeFn(
                      this.tableSearch.capacityUnit,
                      item.capacity,
                      value.num
                    )
                    item['printingFrontDay' + index] = this.generationTimeFn(
                      this.tableSearch.capacityUnit,
                      item.capacity,
                      value.num
                    )
                    item['deliverDay' + index] =
                      Number(item['printingDay' + index]) +
                      Number(this.tableSearch.transportDay)
                  } else {
                    for (let key of oldData[k]) {
                      if (key['num'] == value['num']) {
                        item['profitRate' + index] = key['profitRate']
                        item['ladderNumId' + index] = key['ladderNumId']
                        item['printingDay' + index] = key['printingDay']
                        item['printingFrontDay' + index] =
                          key['printingFrontDay']
                        item['deliverDay' + index] = key['deliverDay']
                      }
                    }
                  }

                  item['transportHeadPrice' + index] = value.transportHeadPrice
                  item['price' + index] = this.calculationFinishedProduct(
                    item.price,
                    item['transportHeadPrice' + index],
                    item['profitRate' + index]
                  )
                })
                return item
              }
            )
            console.log(
              this.tableSearch.productPrintingFullValuationDTOList,
              ' this.tableSearch.productPrintingFullValuationDTOList'
            )
            this.selectdPricingMethod.map((item) => {
              this.numberStepList.forEach((value, index) => {
                item['transportHeadPrice' + index] = value.transportHeadPrice
              })
              return item
            })
          },
        }
        fnObj[num]()
      },
    },
  }
</script>
<style scoped lang="scss">
  .table-search {
    .print-position {
      margin-left: 0 !important;
    }
    .return-discount {
      /deep/.el-form-item__content {
        display: flex;
        align-items: center;
        .el-tooltip {
          margin-left: 5px;
        }
      }
    }
  }
  .location-info {
    width: 128px;
    display: flex;
    align-content: center;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    .location-btn {
      width: 40px;
      height: 16px;
      text-align: center;
      background: #f5f7fa;
      color: #606266;
      cursor: pointer;
      font-size: 13px;
      line-height: 16px;
      position: absolute;
      right: 0;
      top: 0;
      border-left: 1px solid #dcdfe6;
      &.location-btn-reduce {
        top: 16px;
      }
      &.location-btn-add {
        border-bottom: 1px solid #dcdfe6;
      }
    }
    .location-cont {
      width: 88px;
      flex-shrink: 0;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      text-align: center;
    }
  }
</style>
