<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="el-dialog-div">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        :label-position="settings === 'zh' ? 'left' : 'top'"
      >
        <el-row :gutter="15">
          <el-col :span="12" v-for="(item, index) in specSelect" :key="index">
            <el-form-item
              :prop="'specsValue' + (index + 1)"
              :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsSel'),
                  trigger: 'change',
                },
              ]"
            >
              <template slot="label">
                <!-- <i style="color: #ff4d4f">*</i> -->
                {{ item.nameCn }}
              </template>
              <!-- 请选择(多选) 编辑规格-->
              <el-select
                multiple
                :placeholder="$t('placeholder.M2006')"
                clearable
                :disabled="title === $t('placeholder.M2007')"
                collapse-tags
                v-model="form['specsValue' + (index + 1)]"
              >
                <el-option
                  v-for="(option, optionIndex) of item.value"
                  :key="optionIndex"
                  :label="option"
                  :value="option"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 包装方式/英文 -->
            <el-form-item
              :label="$t('productDetail.PackingMethodEnglish')"
              prop="packingMethodEn"
            >
              <!-- 请选择 -->
              <el-select
                v-model="form.packingMethodEn"
                :placeholder="$t('placeholder.plsSel')"
              >
                <el-option
                  v-for="(item, index) of proPackingData"
                  :key="index"
                  :label="item.nameEn"
                  :value="item.nameEn"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row></el-row>
        <el-row
          style="border-top: 1px solid #ededed"
          class="mt20 pt20"
          :gutter="20"
        >
          <el-row class="mb20">
            <!-- 产品情况 -->
            <h4>{{ $t('productDetail.ProductSituation') }}</h4>
          </el-row>
          <el-col :span="8">
            <!-- 产品重量 -->
            <el-form-item
              :label="$t('productDetail.ProductWeight')"
              prop="productWeightKgs"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.productWeightKgs"
                :placeholder="$t('placeholder.plsInput')"
                type="number"
                :controls="false"
              />

              <p class="calcu-p">
                LBS:
                <span>{{ productWeightKgsLbs }}</span>
              </p>
            </el-form-item>
          </el-col>
          <!-- 印刷区域尺寸-修改 -->
          <el-col :span="16">
            <!-- 印刷区域尺寸 -->
            <el-form-item
              :label="$t('productDetail.PrintingAreaSize')"
              prop="printSize"
            >
              <el-row :gutter="10" type="flex" justify="space-between">
                <el-col :span="12">
                  <!-- 请输入>0的数值 -->
                  <el-form-item
                    prop="printLength"
                    :rules="[
                      {
                        required: form.printLength,
                        trigger: 'blur',
                      },
                      {
                        pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                        message: $t('rules.M2014'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- 长度 -->
                    <el-input
                      v-model="form.printLength"
                      type="number"
                      :placeholder="$t('placeholder.M2008')"
                    >
                      <template slot="append">
                        <select
                          class="append-select"
                          v-model="form.printUnit"
                          @change="proPrintChange"
                        >
                          <option>IN</option>
                          <option>FT</option>
                          <option>CM</option>
                        </select>
                      </template>
                    </el-input>
                  </el-form-item>
                  <p class="t_a_r calcu-p">
                    <span>{{ form.printLengthCovert }}</span>
                    {{ proPrintUnitText }}
                  </p>
                </el-col>
                <el-col :span="1">x</el-col>
                <el-col :span="12">
                  <!-- 请输入>0的数值 -->
                  <el-form-item
                    prop="printWidth"
                    :rules="[
                      {
                        required: form.printWidth,
                        trigger: 'blur',
                      },
                      {
                        pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                        message: $t('rules.M2014'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- 宽度 -->
                    <el-input
                      v-model="form.printWidth"
                      type="number"
                      :placeholder="$t('placeholder.M2009')"
                    >
                      <template slot="append">
                        <select
                          class="append-select"
                          v-model="form.printUnit"
                          @change="proPrintChange()"
                        >
                          <option>IN</option>
                          <option>FT</option>
                          <option>CM</option>
                        </select>
                      </template>
                    </el-input>
                  </el-form-item>
                  <p class="t_a_r calcu-p">
                    <span>{{ form.printWidthCovert }}</span>
                    {{ proPrintUnitText }}
                  </p>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- 产品尺寸 -->
          <el-form-item :label="$t('productDetail.ProductSize')">
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="7">
                <!-- 请输入>0的数值 -->
                <el-form-item
                  prop="productLengthCm"
                  :rules="[
                    {
                      required: form.productLengthCm,
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- 长度 -->
                  <el-input
                    v-model="form.productLengthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2008')"
                  >
                    <template slot="append">
                      <select
                        class="append-select"
                        v-model="form.sizeUnit"
                        @change="proSizeUnitChange"
                      >
                        <option>IN</option>
                        <option>FT</option>
                        <option>CM</option>
                      </select>
                    </template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.productLengthIn }}</span>
                  {{ proSizeUnitText }}
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 请输入>0的数值 -->
                <el-form-item
                  prop="productWidthCm"
                  :rules="[
                    {
                      required: form.productWidthCm,
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- 宽度 -->
                  <el-input
                    v-model="form.productWidthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2009')"
                  >
                    <template slot="append">
                      <select
                        class="append-select"
                        v-model="form.sizeUnit"
                        @change="proSizeUnitChange"
                      >
                        <option>IN</option>
                        <option>FT</option>
                        <option>CM</option>
                      </select>
                    </template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.productWidthIn }}</span>
                  {{ proSizeUnitText }}
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <el-form-item prop="productHeightCm">
                  <!-- 高度 -->
                  <el-input
                    v-model="form.productHeightCm"
                    type="number"
                    :placeholder="$t('placeholder.M2010')"
                  >
                    <template slot="append">
                      <select
                        class="append-select"
                        v-model="form.sizeUnit"
                        @change="proSizeUnitChange"
                      >
                        <option>IN</option>
                        <option>FT</option>
                        <option>CM</option>
                      </select>
                    </template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.productHeightIn }}</span>
                  {{ proSizeUnitText }}
                </p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <el-row
          style="border-top: 1px solid #ededed"
          class="mt20 pt20"
          :gutter="20"
        >
          <el-row class="mb20">
            <!-- 装箱情况 -->
            <h4>{{ $t('productDetail.PackingSituation') }}</h4>
          </el-row>
          <el-col :span="12">
            <!-- 装箱数量 -->
            <el-form-item
              :label="$t('productDetail.PackingQuantity')"
              prop="numberPerBox"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.numberPerBox"
                type="number"
                :placeholder="$t('placeholder.plsInput')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 毛重 -->
            <el-form-item
              :label="$t('productDetail.GrossWeight')"
              prop="boxWeightKgs"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.boxWeightKgs"
                type="number"
                :placeholder="$t('placeholder.plsInput')"
              />
              <p class="calcu-p">
                LBS:
                <span>{{ boxWeightKgsLbs }}</span>
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <template slot="label">
              <!-- 装箱尺寸 -->
              <i style="color: #ff4d4f">*</i>
              {{ $t('productDetail.PackingSize') }}
            </template>
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="7">
                <!-- 请输入长度  请输入>0的数值 -->
                <el-form-item
                  prop="boxLengthCm"
                  :rules="[
                    {
                      required: true,
                      message: $t('rules.M2015'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- 长度 -->
                  <el-input
                    v-model="form.boxLengthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2008')"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxLengthIn }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 请输入宽度 请输入>0的数值-->
                <el-form-item
                  prop="boxWidthCm"
                  :rules="[
                    {
                      required: true,
                      message: $t('rules.M2016'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <!-- 宽度 -->
                  <el-input
                    v-model="form.boxWidthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2009')"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxWidthIn }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 请输入高度 请输入>0的数值-->
                <el-form-item
                  prop="boxHeightCm"
                  :rules="[
                    {
                      required: true,
                      message: $t('rules.M2016'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.boxHeightCm"
                    type="number"
                    :placeholder="$t('placeholder.M2010')"
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ form.boxHeightIn }}</span>
                  IN
                </p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="end">
          <!-- 体积 -->
          <p>
            {{ $t('productDetail.VolumeM3') }}：{{ boxM3 }}
            <span></span>
          </p>
          <p class="ml30">
            <!-- 体积 -->
            {{ $t('productDetail.VolumeFT3') }}：{{ boxFt3 }}
            <span></span>
          </p>
        </el-row>
        <el-row style="border-top: 1px solid #ededed" class="mt20 pt20">
          <!-- 规格图片 -->
          <el-form-item :label="$t('other.specPic')">
            <el-upload
              ref="coverUpload"
              name="upload"
              action
              :before-upload="beforePicUpload"
              :http-request="uploadImgAction"
              :file-list="imgMainFileList"
              :on-preview="handlePreview"
              :on-remove="handleRemoveImgMain"
              :on-change="handleFileChange"
              :limit="1"
              :auto-upload="true"
              list-type="picture-card"
              accept=".jpg, .png, .jpeg"
              :class="{ hideAdd: uploadDisabled }"
            >
              <i class="el-icon-plus" />
              <div slot="tip" class="el-upload__tip mt20">
                <!-- 支持jpg/jpeg/png，建议尺寸: 1800px*1800px，最多上传
                <span class="f_s_16">1</span>
                张，不允许超过2MB -->

                {{ $t('other.uploadTips') }}
              </div>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row style="border-top: 1px solid #ededed" class="mt20 pt20">
          <!-- 备注信息 -->
          <el-form-item :label="$t('productDetail.Remarks')">
            <el-input
              v-model="form.remarks"
              type="textarea"
              maxlength="500"
              show-word-limit
              :autosize="{ minRows: 2 }"
              :placeholder="$t('placeholder.M2004')"
            />
          </el-form-item>
        </el-row>
      </el-form>

      <el-dialog :visible.sync="dialogPreview" append-to-body>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { getDetailByType } from '@/api/product/productSetting'
  import { deepCopy } from 'kits'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ProductSpecListModel',
    data() {
      return {
        // title: '新增规格',
        title: '',
        form: {
          sizeUnit: 'IN',
          printUnit: 'IN',
          packingMethodEn: null,
          productWeightKgs: null,
          numberPerBox: null,
          boxWeightKgs: '',
        },
        // rules: {
        //   packingMethodEn: [
        //     {
        //       required: true,
        //       message: '请选择包装方式/英文',
        //       trigger: 'change',
        //     },
        //   ],
        //   productWeightKgs: [
        //     {
        //       required: true,
        //       message: '请輸入产品重量',
        //       trigger: 'blur',
        //     },
        //     {
        //       pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
        //       message: '产品重量(KGS)需大于0',
        //       trigger: 'blur',
        //     },
        //   ],
        //   numberPerBox: [
        //     {
        //       required: true,
        //       message: '请輸入装箱数量',
        //       trigger: 'blur',
        //     },
        //     {
        //       pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
        //       message: '装箱数量需大于0',
        //       trigger: 'blur',
        //     },
        //   ],
        //   boxWeightKgs: [
        //     {
        //       required: true,
        //       message: '请输入毛重(KGS)',
        //       trigger: 'blur',
        //     },
        //     {
        //       pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
        //       message: '毛重(KGS)需大于0',
        //       trigger: 'blur',
        //     },
        //   ],
        // },
        specSelect: [],
        dialogFormVisible: false,
        proPackingData: [], //包装方式数据3:[]
        editIndex: null,
        productWeightKgsLbs: null, //产品重量换算
        boxWeightKgsLbs: null, //产品毛重换算

        // boxLengthCmIn: null, //装箱尺寸-长度
        // boxWidthCmIn: null, //装箱尺寸-宽度
        // boxHeightCmIn: null, //装箱尺寸-高度

        // productLengthCmIn: null, //产品尺寸-长度
        // productWidthCmIn: null, //产品尺寸-宽度
        // productHeightCm: null, //产品尺寸-高度
        boxFt3: null,
        boxM3: null,
        imgMainFileList: [],
        image: '',
        loading: null,
        proSizeUnitText: 'CM',
        proPrintUnitText: 'CM',
        dialogPreview: false,
        uploadDisabled: false, //规格图
        limitImgNumber: 1, //规格图数量
        dialogImageUrl: '',
      }
    },
    computed: {
      //装箱M3，t3
      boxM3Ft3Calcu() {
        const { boxLengthCm, boxWidthCm, boxHeightCm } = this.form,
          obj = {
            boxLengthCm,
            boxWidthCm,
            boxHeightCm,
          }
        return obj
      },
      //产品M3
      productM3Calcu() {
        const { productLengthCm, productWidthCm, productHeightCm } = this.form,
          obj = {
            productLengthCm,
            productWidthCm,
            productHeightCm,
          }
        return obj
      },
      ...mapGetters({
        settings: 'settings/language',
      }),
      rules() {
        return {
          packingMethodEn: [
            {
              required: true,
              // message: '请选择包装方式/英文',
              message: this.$t('rules.M2025'),
              trigger: 'change',
            },
          ],
          productWeightKgs: [
            {
              required: true,
              // message: '请輸入产品重量',
              message: this.$t('rules.M2026'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              // message: '产品重量(KGS)需大于0',
              message: this.$t('rules.M2027'),
              trigger: 'blur',
            },
          ],
          numberPerBox: [
            {
              required: true,
              // message: '请輸入装箱数量',
              message: this.$t('rules.M2028'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              // message: '装箱数量需大于0',
              message: this.$t('rules.M2029'),
              trigger: 'blur',
            },
          ],
          boxWeightKgs: [
            {
              required: true,
              // message: '请输入毛重(KGS)',
              message: this.$t('rules.M2030'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              // message: '毛重(KGS)需大于0',
              message: this.$t('rules.M2031'),
              trigger: 'blur',
            },
          ],
        }
      },
    },
    watch: {
      // 装箱boxM3，boxFt3
      boxM3Ft3Calcu(v) {
        if (!v.boxLengthCm || !v.boxWidthCm || !v.boxHeightCm) {
          this.boxM3 = ''
          this.boxFt3 = ''
          return
        }
        if (
          Number(v.boxLengthCm) ||
          Number(v.boxWidthCm) ||
          Number(v.boxHeightCm)
        )
          this.boxM3 = Math.round(
            v.boxLengthCm * v.boxWidthCm * v.boxHeightCm * 0.000001
          )

        this.form.boxVolumeM = (
          v.boxLengthCm *
          v.boxWidthCm *
          v.boxHeightCm *
          0.000001
        ).toFixed(6)
        this.boxFt3 = Math.round(
          v.boxLengthCm * v.boxWidthCm * v.boxHeightCm * 0.000001 * 35.31467
        )
      },
      // 产品boxM3
      productM3Calcu(v) {
        if (!v.productLengthCm || !v.productWidthCm || !v.productHeightCm) {
          this.form.productVolume = ''
          return
        }
        if (
          Number(v.productLengthCm) ||
          Number(v.productWidthCm) ||
          Number(v.productHeightCm)
        )
          this.form.productVolume = (
            v.productLengthCm *
            v.productWidthCm *
            v.productHeightCm *
            0.000001
          ).toFixed(6)
      },

      // 产品重量
      'form.productWeightKgs': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.productWeightKgsLbs = '')
          }
          if (Number(value)) {
            this.productWeightKgsLbs = (value * 2.2046).toFixed(1)
          }
        },
      },
      // 产品毛重
      'form.boxWeightKgs': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.boxWeightKgsLbs = '')
          }
          if (Number(value)) {
            this.boxWeightKgsLbs = Math.round(value * 2.2046)
          }
        },
      },
      // 产品印刷区域尺寸（长度）
      'form.printLength': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.printLengthCovert = '')
          }
          if (this.form.printUnit === 'FT' || this.form.printUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.printUnit === 'FT') {
              this.form.printLengthCovert = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.printUnit === 'CM') {
              this.form.printLengthCovert = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.printLengthCovert = (value * 2.54).toFixed(2)
          }
        },
      },
      // 产品印刷区域尺寸（宽度）
      'form.printWidth': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.printWidthCovert = '')
          }
          if (this.form.printUnit === 'FT' || this.form.printUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.printUnit === 'FT') {
              this.form.printWidthCovert = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.printUnit === 'CM') {
              this.form.printWidthCovert = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.printWidthCovert = (value * 2.54).toFixed(2)
          }
        },
      },
      // 产品尺寸（长度）
      'form.productLengthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.productLengthIn = '')
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.sizeUnit === 'FT') {
              this.form.productLengthIn = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.sizeUnit === 'CM') {
              this.form.productLengthIn = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.productLengthIn = (value * 2.54).toFixed(2)
          }
        },
      },
      // 产品尺寸（宽度）
      'form.productWidthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.productWidthIn = '')
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.sizeUnit === 'FT') {
              this.form.productWidthIn = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.sizeUnit === 'CM') {
              this.form.productWidthIn = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.productWidthIn = (value * 2.54).toFixed(2)
          }
        },
      },
      // 产品尺寸（高度）
      'form.productHeightCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.productHeightIn = '')
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            //英尺换算成英寸
            if (this.form.sizeUnit === 'FT') {
              this.form.productHeightIn = (value * 12).toFixed(2)
            }
            //厘米换算成英寸
            if (this.form.sizeUnit === 'CM') {
              this.form.productHeightIn = (value * 0.3937).toFixed(2)
            }
          } else {
            //英寸换算成厘米
            this.form.productHeightIn = (value * 2.54).toFixed(2)
          }
        },
      },

      // 装箱尺寸（长度）
      'form.boxLengthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.boxLengthIn = '')
          }
          if (Number(value)) {
            this.form.boxLengthIn = (value * 0.3937).toFixed(2)
          }
        },
      },
      // 装箱尺寸（宽度）
      'form.boxWidthCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.boxWidthIn = '')
          }
          if (Number(value)) {
            this.form.boxWidthIn = (value * 0.3937).toFixed(2)
          }
        },
      },
      // 装箱尺寸（高度）
      'form.boxHeightCm': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) {
            return (this.form.boxHeightIn = '')
          }
          if (Number(value)) {
            this.form.boxHeightIn = (value * 0.3937).toFixed(2)
          }
        },
      },
      form: {
        //用于编辑回显的规格下拉
        immediate: true,
        deep: true,
        handler(value) {
          if (
            value.specsValue1 &&
            !value.specsValue2 &&
            !Array.isArray(value.specsValue1)
          ) {
            value.specsValue1 = value.specsValue1.split(',')
          } else if (value.specsValue1 && value.specsValue2) {
            if (!Array.isArray(value.specsValue1)) {
              value.specsValue1 = value.specsValue1.split(',')
            } else if (!Array.isArray(value.specsValue2)) {
              value.specsValue2 = value.specsValue2.split(',')
            }
          }
        },
      },
    },
    created() {
      // this.title = '新增规格'
      this.title = this.$t('productDetail.AddedSpecifications')
      this.$Bus.$on('specSendBus', (spec) => {
        if (spec) {
          this.specSelect = spec
        }
      })
      this.$Bus.$on('specSendBusEdit', (spec) => {

        if (spec) {
          this.specSelect = spec
        }
      })
      //开启单规格多规格
      this.$Bus.$on('specsChange', (bol) => {
        this.specSelect = []
      })

      // 包装方式
      this.getDetailByType3()
    },
    methods: {
      //印刷区域尺寸选择
      proPrintChange() {
        //如果是IN或CM
        if (this.form.printUnit === 'FT' || this.form.printUnit === 'CM') {
          this.proPrintUnitText = 'IN'

          //英尺换算成英寸
          if (this.form.printUnit === 'FT') {
            if (this.form.printLength) {
              this.form.printLengthCovert = (
                Number(this.form.printLength) * 12
              ).toFixed(2)
            }
            if (this.form.printWidth) {
              this.form.printWidthCovert = (
                Number(this.form.printWidth) * 12
              ).toFixed(2)
            }
          }
          //厘米换算成英寸
          if (this.form.printUnit === 'CM') {
            if (this.form.printLength) {
              this.form.printLengthCovert = (
                Number(this.form.printLength) * 0.3937
              ).toFixed(2)
            }

            if (this.form.printWidth) {
              this.form.printWidthCovert = (
                Number(this.form.printWidth) * 0.3937
              ).toFixed(2)
            }
          }
        } else {
          if (this.form.printLength) {
            this.form.printLengthCovert = (
              Number(this.form.printLength) * 2.54
            ).toFixed(2)
          }
          if (this.form.printWidth) {
            this.form.printWidthCovert = (
              Number(this.form.printWidth) * 2.54
            ).toFixed(2)
          }
          this.proPrintUnitText = 'CM'
        }
      },

      //产品尺寸选择
      proSizeUnitChange() {
        //如果是IN或CM
        if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
          this.proSizeUnitText = 'IN'

          //英尺换算成英寸
          if (this.form.sizeUnit === 'FT') {
            if (this.form.productLengthCm) {
              this.form.productLengthIn = (
                Number(this.form.productLengthCm) * 12
              ).toFixed(2)
            }
            if (this.form.productWidthCm) {
              this.form.productWidthIn = (
                Number(this.form.productWidthCm) * 12
              ).toFixed(2)
            }
            if (this.form.productHeightCm) {
              this.form.productHeightIn = (
                Number(this.form.productHeightCm) * 12
              ).toFixed(2)
            }
          }
          //厘米换算成英寸
          if (this.form.sizeUnit === 'CM') {
            if (this.form.productLengthCm) {
              this.form.productLengthIn = (
                Number(this.form.productLengthCm) * 0.3937
              ).toFixed(2)
            }

            if (this.form.productWidthCm) {
              this.form.productWidthIn = (
                Number(this.form.productWidthCm) * 0.3937
              ).toFixed(2)
            }

            if (this.form.productHeightCm) {
              this.form.productHeightIn = (
                Number(this.form.productHeightCm) * 0.3937
              ).toFixed(2)
            }
          }
        } else {
          if (this.form.productLengthCm) {
            this.form.productLengthIn = (
              Number(this.form.productLengthCm) * 2.54
            ).toFixed(2)
          }
          if (this.form.productWidthCm) {
            this.form.productWidthIn = (
              Number(this.form.productWidthCm) * 2.54
            ).toFixed(2)
          }

          if (this.form.productHeightCm) {
            this.form.productHeightIn = (
              Number(this.form.productHeightCm) * 2.54
            ).toFixed(2)
          }
          this.proSizeUnitText = 'CM'
        }
      },
      //包装方式下拉
      async getDetailByType3() {
        let response = await getDetailByType({ type: 3 })
        if ((response.code = '000000')) {
          this.proPackingData = response.data
        }
      },
      //展示弹框
      productSpecListModel(row, index) {
        if (row) {
          // this.title = '编辑规格'
          this.title = this.$t('placeholder.M2007')
          this.editIndex = index
          this.form = Object.assign({}, deepCopy(row))

          if (!this.form.sizeUnit) {
            this.form.sizeUnit = 'IN'
          }
          if (this.form.sizeUnit === 'FT' || this.form.sizeUnit === 'CM') {
            this.proSizeUnitText = 'IN'
          } else {
            this.proSizeUnitText = 'CM'
          }

          if (!this.form.printUnit) {
            this.form.printUnit = 'IN'
          }
          if (this.form.printUnit === 'FT' || this.form.printUnit === 'CM') {
            this.proPrintUnitText = 'IN'
          } else {
            this.proPrintUnitText = 'CM'
          }

          if (this.form.image) {
            this.image = this.form.image
            this.imgMainFileList.push({ url: this.form.image })
            this.uploadDisabled = true
          }
        }
        this.dialogFormVisible = true
      },
      // 主图上传
      uploadImgAction({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          if (res.requestUrls.length > 0) {
            let url = res.requestUrls[0].split('?uploadId')
            this.form.image = url[0]
            this.image = url[0]
          } else {
            this.imgMainFileList = []
            this.image = ''
            return this.$baseMessage(
              '图片上传失败',
              'error',
              false,
              'erp-hey-message-error'
            )
          }
          this.loading.close()
        })
      },
      handleFileChange(file, fileList) {
        this.uploadDisabled = fileList.length >= this.limitImgNumber
      },
      // 删除主图
      handleRemoveImgMain(file, fileList) {
        this.imgMainFileList = []
        this.image = ''
      },
      // 预览主图
      handlePreview(file) {
        this.dialogImageUrl = file.url
        this.dialogPreview = true
      },
      // 上传之前验证
      beforePicUpload(file) {
        this.loading = this.$loading({
          lock: true,
          // text: '图片上传中...',
          text: this.$t('reqmsg.M2031'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.3)',
        })
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension =
          testmsg === 'jpg' ||
          testmsg === 'JPG' ||
          testmsg === 'png' ||
          testmsg === 'PNG' ||
          testmsg === 'jpeg' ||
          testmsg === 'JPEG'
        const isLt50M = file.size / 1024 / 1024 < 2
        if (!extension) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片只能是jpg/png/jpeg格式',
            this.$t('reqmsg.M2032'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false //必须加上return false; 才能阻止
        }
        if (!isLt50M) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片大小不能超过2MB!',
            this.$t('reqmsg.M2033'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }
        return true
      },
      // 关闭
      close() {
        // this.title = '新增规格'
        this.title = this.$t('productDetail.AddedSpecifications')
        this.dialogFormVisible = false
        this.image = ''
        this.imgMainFileList = []
        this.boxM3 = ''
        this.boxFt3 = ''
        this.editIndex = ''
        this.$refs['form'].resetFields()

        this.form = this.$options.data().form
      },
      // 保存
      save() {
        this.form.image = this.image
        let form = deepCopy(this.form)
        form.boxHeightIn = Number(form.boxHeightIn) || ''
        form.boxLengthIn = Number(form.boxLengthIn) || ''
        form.boxWidthIn = Number(form.boxWidthIn) || ''
        form.printLengthCovert = Number(form.printLengthCovert) || ''
        form.printWidthCovert = Number(form.printWidthCovert) || ''
        form.productHeightIn = Number(form.productHeightIn) || ''
        form.productLengthIn = Number(form.productLengthIn) || ''
        form.productWidthIn = Number(form.productWidthIn) || ''
        form.productVolume = Number(form.productVolume) || ''
        form.boxVolumeM = Number(form.boxVolumeM) || ''

        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (form.productWeightKgs <= 0) {
              return this.$baseMessage(
                // '产品重量(KGS)必须大于0',
                this.$t('rules.M2027'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            if (form.numberPerBox <= 0) {
              return this.$baseMessage(
                // '装箱数量必须大于0',
                this.$t('rules.M2029'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            if (form.boxWeightKgs <= 0) {
              return this.$baseMessage(
                // '装箱毛重(KGS)必须大于0',
                this.$t('rules.M2031'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            if (
              form.boxLengthCm == undefined ||
              form.boxWidthCm == undefined ||
              form.boxHeightCm == undefined
            ) {
              return this.$baseMessage(
                // '请完善装箱尺寸',
                this.$t('rules.M2032'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            //毛重需大于产品重量*装箱数量

            let factBoxWeightKgs = form.productWeightKgs * form.numberPerBox

            if (form.boxWeightKgs < factBoxWeightKgs) {
              return this.$baseMessage(
                // '毛重值需大于产品重量*装箱数量',
                this.$t('rules.M2033'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            this.$emit('spec-row', form, this.editIndex)
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .calcu-p {
    font-size: 12px;
    color: #999;
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .el-dialog-div {
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  ::v-deep {
    .el-dialog-div {
      .el-form {
        width: 95% !important;
        margin: 0 auto !important;
      }
    }
    .el-input-group__append,
    .el-input-group__prepend {
      padding: 0 5px;
    }
    .append-select {
      color: rgba(0, 0, 0, 0.65);
      height: 34px;
      border: none;
      background: #f5f7fa;
      cursor: pointer;
    }
    .hideAdd {
      /deep/ .el-upload--picture-card {
        display: none;
      }
    }
  }
</style>
<style>
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
