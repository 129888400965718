<template>
  <div class="order-product">
    <el-form ref="ruleForm" :model="ruleForm">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-form-item prop="cusName">
            <el-input
              v-model="ruleForm.cusName"
              placeholder="销售单号/产品编码/产品名称"
              maxlength="50"
              clearable
              @keyup.enter.native="search"
            />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <div class="text-left">
            <!-- 搜索 -->
            <el-button @click="search">
              {{ $t('productDetail.Search') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div class="container">
      <el-table
        ref="multipleTable"
        border
        :data="tableData"
        height="450"
        @selection-change="selectionChange"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column type="selection" width="40" />
        <el-table-column
          label="销售单号"
          prop="salesOrderNumber"
          width="130"
        ></el-table-column>
        <el-table-column width="100" label="效果图">
          <template slot-scope="scope">
            <div class="img-wrap">
              <el-badge
                v-show="
                  scope.row.rendingsUrl &&
                  scope.row.rendingsUrl.split(',').length > 1
                "
                :value="
                  scope.row.rendingsUrl
                    ? scope.row.rendingsUrl.split(',').length
                    : ''
                "
              ></el-badge>
              <el-image
                v-if="scope.row.rendingsUrl"
                :src="scope.row.rendingsUrl.split(',')[0]"
                class="c_pointer"
                @click="handlePreview(scope.row.rendingsUrl.split(','))"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="产品编码" prop="sku" />
        <el-table-column
          width="180"
          label="产品名称"
          prop="productCn"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          width="150"
          label="印刷方式"
          prop="printingWay"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.printingWay }}
          </template>
        </el-table-column>
        <el-table-column width="150" label="客户代表" prop="businessNameEn" />
        <el-table-column
          width="150"
          label="产品运营"
          prop="productBelonger"
        ></el-table-column>
        <el-table-column
          width="150"
          label="订单支持"
          prop="fromBusinessName"
        ></el-table-column>
        <el-table-column
          width="150"
          label="创建人"
          prop="creatorNameEn"
        ></el-table-column>
        <el-table-column width="120" label="创建时间" prop="createTime">
          <template slot-scope="scope">
            {{ utils.cutDate(scope.row.createTime) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      :current-page="page.pageNo"
      :page-size="page.pageLe"
      :total="total"
      @current-change="currentChange"
    />

    <div class="text-center mt20">
      <el-button type="primary" @click="save()">确定</el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
import { purchaseExamineGoods } from '@/core/interactors/purchase/examineGoods'
export default {
  props: {},
  data() {
    return {
      tableData: [],
      page: {
        pageLe: 10,
        pageNo: 1,
      },
      total: 0,
      ruleForm: {
        cusName: '',
      },
      selectRows: [], // 当前分页选中的数据
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        ...this.page,
        ...this.ruleForm,
      }
      const res = await purchaseExamineGoods.getListPageProduct(params)

      if (res && res.code === '000000') {
        this.tableData = res.data.data || []
        this.total = res.data.total || 0
      }
    },

    // 搜索
    search() {
      this.page.pageNo = 1
      this.getList()
    },

    // 选中
    selectionChange(rows) {
      this.selectRows = rows
    },

    // 切换分页
    currentChange(val) {
      this.page.pageNo = val
      this.getList()
    },

    // 确认
    save() {
      if (!this.selectRows.length) {
        this.$message.warning('请选择一条数据')
        return false
      }
      const data = this.selectRows.map((item) => {
        return {
          salesOrderNumber: item.salesOrderNumber, // 销售订单编号
          productCode: item.sku, // 产品编码
          businessId: item.businessId, // 客户代表Id
          businessName: item.businessName, // 客户代表
          businessNameEn: item.businessNameEn, // 客户代表英文
          nameCn: item.productCn, // 产品名称
          printingImg: item.rendingsUrl, // 效果图
          orderProductUniqueId: item.orderProductUniqueId, // 唯一Id
          sku: item.sku, // sku
          specsValue1: item.specification, // 规格1
          specsValueCn1: item.specificationCn, // 规格1
          specsValue2: '', // 规格2
          specsValueCn2:"",
          numActual: '', // 采购数量
          provideSamples: '', // 是否提供样品
          numInspection: '', // 验货数量
          actualInspectionStandard: '', // 检验标准
          inspectionRequirements: '', // 验货要求
          packingInformation: this.getPackingInfo(item), // 装箱信息
          actualMark: '', // 唛头
          orderExist: true, // 带出的销售单不能编辑
        }
      })
      this.$emit('getOrderData', data)
    },

    // 获取装箱信息
    getPackingInfo(item) {
      const info = `装箱数量：${item.numberPerBox}\n装箱尺寸：${
        item.boxLengthIn || 0
      }'' x ${item.boxWidthIn || 0}'' x ${item.boxHeightIn || 0}''\n装箱重量：${
        item.boxWeightKgs || 0
      }Kgs`

      return info
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },

    // 关闭
    close() {
      this.$emit('orderClose')
      this.$refs['ruleForm'].resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;
  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }
  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}
</style>
