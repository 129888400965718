<template>
  <div class="table-container" v-if="$route.meta.showParent">
    <el-tabs v-model="activeName">
      <el-tab-pane label="快递" name="0">
        <el-row>
          <el-row style="width: calc(100% - 100px)">
            <el-input
              style="width: 200px"
              class="ml10 mb10"
              v-model="form.condition"
              @keypress.native.enter="onEnterSearch($event)"
              placeholder="输入委托单号/订单编号"
            ></el-input>

            <el-select
              style="width: 160px"
              class="ml10"
              v-model="form.status"
              placeholder="出库状态"
              clearable
              multiple
              collapse-tags
              :disabled="statusDisabled"
            >
              <el-option
                v-for="s in searchStatusList"
                :key="s.code"
                :value="s.code"
                :label="s.name"
              ></el-option>
            </el-select>

            <el-select
              style="width: 120px"
              class="ml10"
              placeholder="安排状态"
              v-model="form.wmsUpdateCancelFlag"
              clearable
            >
              <el-option label="未安排" value="0" />
              <el-option label="已安排" value="1" />
            </el-select>
            <el-button type="primary" @click="onButtonSearch" class="ml10">
              搜索
            </el-button>
            <el-button @click="onReset">重置</el-button>
            <el-button @click="showDrawer">高级筛选</el-button>
            <!-- 海运空运导出 -->
            <el-button
              type="default"
              @click="exportData"
              v-if="activeName === '1'"
            >
              导出
            </el-button>
            <el-dropdown class="ml10" v-else>
              <el-button type="default" plain>导出</el-button>
              <el-dropdown-menu slot="dropdown" placement="bottom">
                <el-dropdown-item>
                  <p @click="exportExpress(1)">导出选中</p>
                </el-dropdown-item>
                <p class="split-div"></p>
                <el-dropdown-item>
                  <p @click="exportExpress(2)">导出全部</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="default"
              @click="modifyClerk"
              class="ml10"
              v-show="!updateClerkNameDisabled && hasClerkOperateRight"
            >
              修改单证员
            </el-button>
            <el-dropdown
              size="small"
              split-button
              @command="changeArrangeStatus"
              v-allowed="['ARRANGE:STATUS']"
              class="ml10"
            >
              {{ arrangeStatus }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">标为已安排</el-dropdown-item>
                <el-dropdown-item command="0">取消安排</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="default"
              class="ml10"
              @click="batchShip"
              v-show="!batchShipmentButtonDisabled"
              v-allowed="['SHIPMENT:MANUAL']"
            >
              手动出运
            </el-button>

            <el-button
              type="default"
              class="ml10"
              @click="batchSign"
              v-show="!batchSignButtonDisabled"
              v-allowed="['SHIPMENT:SIGN']"
            >
              手动签收
            </el-button>

            <table-import
              style="display: inline-block"
              :param="{
                type: 'ship',
                btnName: '导入运费',
              }"
            ></table-import>
          </el-row>
          <el-row>
            <!-- 自定义展示列 -->
            <el-popover
              ref="showOption"
              popper-class="custom-table-checkbox"
              trigger="hover"
            >
              <erp-draggable
                v-bind="dragOptions"
                :list="expressColumns"
                handle=".dargCheckBox"
              >
                <el-checkbox
                  v-for="(item, index) in expressColumns"
                  :key="item + index"
                  v-model="item.isShow"
                  :disabled="item.checkable === false"
                  :label="item.label"
                  :class="{ dargCheckBox: item.prop != 'number' }"
                >
                  {{ item.label }}
                </el-checkbox>
              </erp-draggable>
              <!-- 保存自定义显示列 -->
              <div class="save-btn">
                <el-button
                  type="primary"
                  class="ml10"
                  size="mini"
                  plain
                  @click="operateSave"
                >
                  保存
                </el-button>
              </div>
              <template #reference>
                <el-tooltip
                  effect="dark"
                  content="列表可拖动排序"
                  placement="top-start"
                >
                  <el-button
                    plain
                    type="primary"
                    style="position: fixed; top: 117px; right: 20px"
                  >
                    自定义显示
                  </el-button>
                </el-tooltip>
              </template>
            </el-popover>
          </el-row>
        </el-row>
        <p class="divider"></p>
        <div class="radio-wrap">
          <el-radio-group v-model="currentStatus" class="mb15">
            <el-radio label="">全部</el-radio>
            <el-radio
              :label="item.code"
              v-for="item in statusListArr"
              :key="item.code"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
          <div>
            运费总计：
            <span class="f_s_16 bold">{{ shipTotal }}</span>
          </div>
        </div>
        <ErpTable
          :tableData="tableData"
          :pagination="false"
          :extraHeight="260"
          :key="customTableKey"
          @selection-change="handleExpressSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            fixed="left"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in expressFinallyColumns"
            :key="index"
            :label="item.label"
            show-overflow-tooltip
            :sortable="item.sortable"
            :width="item.width"
            min-width="120"
            :resizable="false"
            align="center"
            :prop="item.prop"
            :fixed="item.prop === 'number' ? 'left' : false"
          >
            <template slot-scope="scope">
              <el-row v-if="item.prop === 'number'">
                <span
                  class="page-link text-left"
                  @click="
                    goDetail(
                      scope.row.shippingOrderId,
                      scope.row.operateFlag,
                      scope.row.shipTime,
                      scope.row.number,
                      'EXPRESS'
                    )
                  "
                >
                  {{ scope.row.number }}
                </span>
              </el-row>
              <el-row v-else-if="item.prop === 'businessNumber'">
                <span
                  class="page-link text-left"
                  @click="goOrderDetail(scope.row.businessNumber)"
                >
                  {{ scope.row.businessNumber }}
                </span>
              </el-row>
              <el-row v-else-if="item.prop === 'drawbackFlag'" class="flex">
                {{ scope.row.drawbackFlag | drawbackFlagFilter }}
                <el-tooltip
                  v-if="scope.row.drawbackFlag == 2"
                  placement="top"
                  effect="dark"
                  content="部分退税"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-row>
              <el-row v-else-if="item.prop === 'status'">
                <el-tag :type="getTagType(scope.row.status)">
                  {{ getStatusName(scope.row.status) }}
                </el-tag>
              </el-row>
              <el-row v-else-if="item.prop === 'shippingDate'">
                {{ scope.row.shippingDate | time }}
              </el-row>
              <el-row v-else-if="item.prop === 'createTime'">
                {{ scope.row.createTime | time }}
              </el-row>
              <el-row v-else-if="item.prop === 'deliveryDate'">
                {{ scope.row.deliveryDate | time }}
              </el-row>
              <el-row v-else-if="item.prop === 'shipNo'">
                <div
                  class="c_pointer page-link"
                  @click="logisticsProgress(scope.row.shippingOrderId)"
                  v-if="scope.row.shipNos && scope.row.shipNos.length > 0"
                >
                  <MutipleFields
                    :link="true"
                    :fields="
                      scope.row.shipNos &&
                      scope.row.shipNos.map((i) => i.shipNo)
                    "
                  />
                </div>
                <div v-else>--</div>
              </el-row>

              <el-row v-else-if="item.prop === 'manualSignInDate'">
                <template v-if="scope.row.manualSignInDate">
                  {{ scope.row.manualSignInDate | time }}
                </template>
                <span v-else>--</span>
              </el-row>

              <el-row v-else-if="item.prop === 'arriveTime'">
                <MutipleFields
                  :fields="
                    scope.row.shipNos &&
                    scope.row.shipNos.map(
                      (i) => i.arriveTime && i.arriveTime.split(' ')[0]
                    )
                  "
                />
              </el-row>
              <el-row v-else-if="item.prop === 'mailSend'">
                <MailSend
                  :prop="'mailSend'"
                  :status="scope.row.mailSend"
                  :info="scope.row.shipEmailRemarkVOS"
                />
              </el-row>
              <el-row v-else-if="item.prop === 'signMailSend'">
                <MailSend
                  :prop="'signMailSend'"
                  :status="scope.row.signMailSend"
                  :info="scope.row.shipEmailRemarkVOS"
                />
              </el-row>
              <el-row v-else-if="item.prop === 'clerkName'">
                <!-- 有操作权限 -->
                <template
                  v-if="
                    hasClerkOperateRight && ![3, 4].includes(scope.row.status)
                  "
                >
                  <span
                    v-if="scope.row.clerkName && !!scope.row.clerkName.trim()"
                    class="link span"
                    @click="editSingleClerk(scope.row)"
                  >
                    {{ scope.row.clerkName }}
                  </span>
                  <span
                    v-else
                    @click="editSingleClerk(scope.row)"
                    class="span link"
                  >
                    请设置
                  </span>
                </template>
                <template v-else>
                  <span
                    v-if="scope.row.clerkName && !!scope.row.clerkName.trim()"
                    class="span"
                  >
                    {{ scope.row.clerkName }}
                  </span>
                  <span v-else>--</span>
                </template>
              </el-row>
              <!-- 安排状态 -->
              <el-row v-else-if="item.prop === 'wmsUpdateCancelFlag'">
                {{ scope.row.wmsUpdateCancelFlag === 0 ? '未安排' : '已安排' }}
              </el-row>
              <span v-else>{{ scope.row[item.prop] || '--' }}</span>
            </template>
          </el-table-column>
          <!-- 操作   -->
          <el-table-column
            align="center"
            label="操作"
            width="120"
            fixed="right"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="查看详情"
                placement="top-start"
              >
                <el-button
                  type="primary"
                  plain
                  icon="iconfont icon-chakan"
                  circle
                  size="mini"
                  @click="
                    goDetail(
                      scope.row.shippingOrderId,
                      scope.row.operateFlag,
                      scope.row.shipTime,
                      scope.row.number,
                      'EXPRESS'
                    )
                  "
                ></el-button>
              </el-tooltip>
              <el-dropdown class="ml10">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-more"
                  circle
                  :disabled="[4].includes(scope.row.status)"
                ></el-button>
                <el-dropdown-menu slot="dropdown" placement="bottom">
                  <el-dropdown-item v-show="![3, 4].includes(scope.row.status)">
                    <p @click="setWaybillNumber(scope.row, 'EXPRESS', '录入')">
                      {{
                        scope.row.shipNos && scope.row.shipNos.length > 0
                          ? '更新运单号'
                          : '填写运单号'
                      }}
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item v-show="[3].includes(scope.row.status)">
                    <p @click="setWaybillNumber(scope.row, 'EXPRESS', '补录')">
                      补录运单号
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="[1].includes(scope.row.status)"
                    v-allowed="['SHIPMENT:MANUAL']"
                  >
                    <p @click="manualShipment(scope.row)">手动出运</p>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="scope.row.status == 5 && hasSignRight"
                  >
                    <p @click="manualSignInClick(scope.row)">手动签收</p>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="[0, 1, 2, 6].includes(scope.row.status)"
                  >
                    <p @click="updateSheet(scope.row)">修改单据</p>
                  </el-dropdown-item>
                  <el-dropdown-item v-show="[5].includes(scope.row.status)">
                    <p
                      @click="sendGoodsMail(scope.row)"
                      v-allowed="['SHIPMENT:MAIL']"
                    >
                      发货邮件
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item v-show="[3, 5].includes(scope.row.status)">
                    <p @click="logisticsProgress(scope.row.shippingOrderId)">
                      物流进度追踪
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item v-show="![3, 4].includes(scope.row.status)">
                    <p
                      @click="cancelShipping(scope.row)"
                      v-allowed="['SHIPMENT:CANCEL']"
                    >
                      取消出运
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item v-show="[2, 6].includes(scope.row.status)">
                    <p @click="showApplyDialog(scope.row)">出库申请</p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <template v-slot:pagination>
            <Pagination
              v-if="page.total > 0"
              :limit.sync="page.pageLe"
              :page.sync="page.pageNo"
              :total="page.total"
              @pagination="onPagination"
              style="position: fixed; width: calc(100% - 150px); bottom: 0"
            />
          </template>
        </ErpTable>
        <ErpSummary :summayData="summayData" :summaryParams="summaryParams" />
      </el-tab-pane>
      <el-tab-pane label="海运空运" name="1">
        <div>
          <el-row>
            <el-row style="width: calc(100% - 100px)">
              <el-input
                class="ml10 mb10"
                style="width: 200px"
                v-model="form.condition"
                @keypress.native.enter="onEnterSearch($event)"
                placeholder="输入委托单号/订单编号"
              ></el-input>

              <el-select
                style="width: 160px"
                v-model="form.status"
                @keypress.native.enter="onButtonSearch"
                placeholder="出库状态"
                clearable
                multiple
                collapse-tags
                class="ml10"
                :disabled="statusDisabled"
              >
                <el-option
                  v-for="s in searchStatusList"
                  :key="s.code"
                  :value="s.code"
                  :label="s.name"
                ></el-option>
              </el-select>
              <el-select
                style="width: 120px"
                class="ml10"
                placeholder="安排状态"
                v-model="form.wmsUpdateCancelFlag"
                clearable
              >
                <el-option label="未安排" value="0" />
                <el-option label="已安排" value="1" />
              </el-select>

              <el-button type="primary" @click="onButtonSearch" class="ml10">
                搜索
              </el-button>
              <el-button @click="onReset">重置</el-button>
              <el-button @click="showDrawer">高级筛选</el-button>
              <el-button type="default" @click="exportData">导出</el-button>
              <el-button
                type="default"
                @click="downloadData"
                v-allowed="['SHIPMENT:DOWNLOAD']"
              >
                下载
              </el-button>
              <el-button
                type="default"
                @click="modifyClerk"
                class="ml10"
                v-show="!updateClerkNameDisabled && hasClerkOperateRight"
              >
                修改单证员
              </el-button>
              <el-button
                type="default"
                class="ml10"
                v-show="!batchShipmentButtonDisabled"
                @click="batchShip"
                v-allowed="['SHIPMENT:MANUAL']"
              >
                手动出运
              </el-button>
              <el-button
                type="default"
                class="ml10"
                @click="batchSign"
                v-show="!batchSignButtonDisabled"
                v-allowed="['SHIPMENT:SIGN']"
              >
                手动签收
              </el-button>

              <table-import
                style="display: inline-block"
                :param="{
                  type: 'ship',
                  btnName: '导入运费',
                }"
              ></table-import>
            </el-row>
            <el-row>
              <!-- 自定义展示列 -->
              <el-popover
                ref="showOption"
                popper-class="custom-table-checkbox"
                trigger="hover"
              >
                <erp-draggable v-bind="dragOptions" :list="columns">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="item + index"
                    v-model="item.isShow"
                    :disabled="item.checkable === false"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-checkbox>
                </erp-draggable>
                <!-- 保存自定义显示列 -->
                <div class="save-btn">
                  <el-button
                    type="primary"
                    class="ml10"
                    size="mini"
                    plain
                    @click="operateSave"
                  >
                    保存
                  </el-button>
                </div>
                <template #reference>
                  <el-tooltip
                    effect="dark"
                    content="列表可拖动排序"
                    placement="top-start"
                  >
                    <el-button
                      plain
                      type="primary"
                      class="pub-h-36"
                      style="position: fixed; top: 117px; right: 20px"
                    >
                      自定义显示
                    </el-button>
                  </el-tooltip>
                </template>
              </el-popover>
            </el-row>
          </el-row>
          <p class="divider"></p>
          <div class="radio-wrap">
            <el-radio-group v-model="currentStatus" class="mb15">
              <el-radio label="">全部</el-radio>
              <el-radio
                :label="item.code"
                v-for="item in statusListArr"
                :key="item.code"
              >
                {{ item.name }}
              </el-radio>
            </el-radio-group>
            <div>
              运费总计：
              <span class="f_s_16 bold">{{ shipTotal }}</span>
            </div>
          </div>
          <ErpTable
            :tableData="tableData"
            :pagination="false"
            :extraHeight="200"
            :key="customTableKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              fixed="left"
            ></el-table-column>
            <el-table-column
              v-for="(item, index) in shippingFinallyColumns"
              :key="index"
              :label="item.label"
              show-overflow-tooltip
              :sortable="item.sortable"
              :width="item.width"
              min-width="120"
              :resizable="false"
              align="center"
              :prop="item.prop"
              :fixed="item.prop === 'number' ? 'left' : false"
            >
              <template slot-scope="scope">
                <el-row v-if="item.prop === 'number'">
                  <span
                    class="page-link text-left"
                    @click="
                      goDetail(
                        scope.row.shippingOrderId,
                        scope.row.operateFlag,
                        scope.row.shipTime,
                        scope.row.number,
                        'SEA'
                      )
                    "
                  >
                    {{ scope.row.number }}
                  </span>
                </el-row>
                <el-row v-else-if="item.prop === 'businessNumber'">
                  <span
                    class="page-link text-left"
                    @click="goOrderDetail(scope.row.businessNumber)"
                  >
                    {{ scope.row.businessNumber }}
                  </span>
                </el-row>
                <el-row v-else-if="item.prop === 'drawbackFlag'" class="flex">
                  {{ scope.row.drawbackFlag | drawbackFlagFilter }}
                  <el-tooltip
                    v-if="scope.row.drawbackFlag == 2"
                    placement="top"
                    effect="dark"
                    content="部分退税"
                  >
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
                <el-row v-else-if="item.prop === 'status'">
                  <el-tag :type="getTagType(scope.row.status)">
                    {{ getStatusName(scope.row.status) }}
                  </el-tag>
                </el-row>
                <el-row v-else-if="item.prop === 'shippingDate'">
                  {{ scope.row.shippingDate | time }}
                </el-row>
                <el-row v-else-if="item.prop === 'createTime'">
                  {{ scope.row.createTime | time }}
                </el-row>
                <el-row v-else-if="item.prop === 'deliveryDate'">
                  {{ scope.row.deliveryDate | time }}
                </el-row>
                <el-row v-else-if="item.prop === 'manualSignInDate'">
                  <template v-if="scope.row.manualSignInDate">
                    {{ scope.row.manualSignInDate | time }}
                  </template>
                  <span v-else>--</span>
                </el-row>
                <el-row v-else-if="item.prop === 'shipNo'">
                  <div
                    class="c_pointer page-link"
                    @click="logisticsProgress(scope.row.shippingOrderId)"
                    v-if="
                      scope.row.shipNosFields &&
                      scope.row.shipNosFields.length > 0
                    "
                  >
                    <MutipleFields
                      :link="true"
                      :fields="scope.row.shipNosFields"
                    />
                  </div>
                  <div v-else>--</div>
                </el-row>
                <el-row v-else-if="item.prop === 'arriveTime'">
                  <MutipleFields :fields="scope.row.arriveTimes" />
                </el-row>
                <el-row v-else-if="item.prop === 'mailSend'">
                  <MailSend
                    :prop="'mailSend'"
                    :status="scope.row.mailSend"
                    :info="scope.row.shipEmailRemarkVOS"
                  />
                </el-row>
                <el-row v-else-if="item.prop === 'signMailSend'">
                  <MailSend
                    :prop="'signMailSend'"
                    :status="scope.row.signMailSend"
                    :info="scope.row.shipEmailRemarkVOS"
                  />
                </el-row>

                <el-row v-else-if="item.prop === 'clerkName'">
                  <!-- 有操作权限 -->
                  <template
                    v-if="
                      hasClerkOperateRight && ![3, 4].includes(scope.row.status)
                    "
                  >
                    <span
                      v-if="scope.row.clerkName && !!scope.row.clerkName.trim()"
                      class="link span"
                      @click="editSingleClerk(scope.row)"
                    >
                      {{ scope.row.clerkName }}
                    </span>
                    <span
                      v-else
                      @click="editSingleClerk(scope.row)"
                      class="span link"
                    >
                      请设置
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-if="scope.row.clerkName && !!scope.row.clerkName.trim()"
                      class="span"
                    >
                      {{ scope.row.clerkName }}
                    </span>
                    <span v-else>--</span>
                  </template>
                </el-row>
                <!-- 安排状态 -->
                <el-row v-else-if="item.prop === 'wmsUpdateCancelFlag'">
                  {{
                    scope.row.wmsUpdateCancelFlag === 0 ? '未安排' : '已安排'
                  }}
                </el-row>

                <span v-else>{{ scope.row[item.prop] || '--' }}</span>
              </template>
            </el-table-column>
            <!-- 操作   -->
            <el-table-column
              align="center"
              label="操作"
              width="120"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="查看详情"
                  placement="top-start"
                >
                  <el-button
                    type="primary"
                    plain
                    icon="iconfont icon-chakan"
                    circle
                    size="mini"
                    @click="
                      goDetail(
                        scope.row.shippingOrderId,
                        scope.row.operateFlag,
                        scope.row.shipTime,
                        scope.row.number,
                        'SEA'
                      )
                    "
                  ></el-button>
                </el-tooltip>
                <el-dropdown class="ml10">
                  <el-button
                    type="primary"
                    plain
                    icon="el-icon-more"
                    circle
                    :disabled="[4].includes(scope.row.status)"
                  ></el-button>
                  <el-dropdown-menu slot="dropdown" placement="bottom">
                    <el-dropdown-item
                      v-show="![3, 4].includes(scope.row.status)"
                    >
                      <p @click="setWaybillNumber(scope.row, 'SEA', '录入')">
                        {{
                          scope.row.shipNos && scope.row.shipNos.length > 0
                            ? '更新运单号'
                            : '填写运单号'
                        }}
                      </p>
                    </el-dropdown-item>
                    <el-dropdown-item v-show="[3].includes(scope.row.status)">
                      <p @click="setWaybillNumber(scope.row, 'SEA', '补录')">
                        补录运单号
                      </p>
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-show="[1].includes(scope.row.status)"
                      v-allowed="['SHIPMENT:MANUAL']"
                    >
                      <p @click="manualShipment(scope.row)">手动出运</p>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-show="scope.row.status == 5 && hasSignRight"
                    >
                      <p @click="manualSignInClick(scope.row)">手动签收</p>
                    </el-dropdown-item>

                    <el-dropdown-item
                      v-show="[0, 1, 2, 6].includes(scope.row.status)"
                    >
                      <p @click="updateSheet(scope.row)">修改单据</p>
                    </el-dropdown-item>
                    <el-dropdown-item v-show="[5].includes(scope.row.status)">
                      <p
                        @click="sendGoodsMail(scope.row)"
                        v-allowed="['SHIPMENT:MAIL']"
                      >
                        发货邮件
                      </p>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-show="[3, 5].includes(scope.row.status)"
                    >
                      <p @click="logisticsProgress(scope.row.shippingOrderId)">
                        物流进度追踪
                      </p>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-show="![3, 4].includes(scope.row.status)"
                    >
                      <p
                        @click="cancelShipping(scope.row)"
                        v-allowed="['SHIPMENT:CANCEL']"
                      >
                        取消出运
                      </p>
                    </el-dropdown-item>
                    <!--  -->
                    <el-dropdown-item
                      v-show="[2, 6].includes(scope.row.status)"
                    >
                      <p @click="showApplyDialog(scope.row)">出库申请</p>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <template v-slot:pagination>
              <Pagination
                v-if="page.total > 0"
                :limit.sync="page.pageLe"
                :page.sync="page.pageNo"
                :total="page.total"
                @pagination="onPagination"
                style="
                  position: fixed;
                  width: calc(100% - 150px);
                  bottom: -15px;
                "
              />
            </template>
          </ErpTable>
          <ErpSummary :summayData="summayData" :summaryParams="summaryParams" />
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-drawer
      title="运输信息"
      :visible.sync="drawer"
      direction="rtl"
      size="440px"
      :before-close="handleClose"
    >
      <div class="progress">
        <el-timeline v-if="activities.length > 0">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            color="#1890FF"
          >
            <span style="margin-right: 20px">{{ activity.createTime }}</span>
            <span>{{ activity.statusMemo }}</span>
          </el-timeline-item>
        </el-timeline>
        <el-empty description="没有数据" v-else></el-empty>
      </div>
    </el-drawer>

    <!-- 手动签收 -->
    <SignModel ref="SignModel" />
    <!-- 运单号 -->
    <WaybillNo
      ref="waybillNo"
      :mailDetail="mailDetail"
      :transpotwayList="transpotwayList"
      :title="waybillNoTitle"
      @refresh="refresh"
    />
    <!-- 取消出运 -->
    <CancelShipping ref="cancelShipping" @dialog-confirm="onDialogConfirm" />
    <!-- 人员选择 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser"></UserChoose>
    <!-- 导出Excel -->
    <import-model ref="ImportModel" @success="onButtonSearch"></import-model>
    <!-- 高级筛选人员选择 -->
    <UserChoose
      ref="formUserChoose"
      @choose-user="chooseAdvancedFormUser"
    ></UserChoose>
    <!-- 高级筛选 -->
    <ErpAdvancedFiltering
      ref="filterDrawer"
      @confirm="onDrawerConfirm"
      @open="onDrawerOpen"
      @cancel="onDrawerCancel"
    >
      <el-form label-position="top">
        <!-- 委托单号 -->
        <el-form-item label="客户代表">
          <el-input
            placeholder="请选择"
            class="w-95"
            @focus="selectUser(0)"
            v-model="nameFromBusinessList"
          ></el-input>
        </el-form-item>

        <el-form-item label="运单号">
          <el-input
            placeholder="请输入"
            class="w-95"
            v-model="advancedForm.shipNo"
          ></el-input>
        </el-form-item>
        <el-form-item label="运输类型" v-if="activeName !== '0'">
          <el-select
            placeholder="请选择"
            class="w-95"
            v-model="advancedForm.shippingMethodType"
          >
            <el-option label="海运" :value="2"></el-option>
            <el-option label="空运" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运输方式">
          <el-select
            placeholder="请选择"
            class="w-95"
            v-model="advancedForm.shippingMethodId"
            :disabled="shippingMethodDisabled"
            clearable
            multiple
          >
            <el-option
              v-for="item in filterShippingMethods"
              :key="item.freightSettingId"
              :label="item.transportWayCn"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否退税">
          <el-select
            placeholder="请选择"
            class="w-95"
            clearable
            v-model="advancedForm.drawbackFlag"
          >
            <el-option
              v-for="item in drawbackList"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货邮件">
          <el-select
            placeholder="请选择"
            class="w-95"
            clearable
            v-model="advancedForm.mailSend"
          >
            <el-option
              v-for="item in mailList"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货邮件">
          <el-select
            placeholder="请选择"
            class="w-95"
            clearable
            v-model="advancedForm.signMailSend"
          >
            <el-option
              v-for="item in mailList"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品编码">
          <el-input
            placeholder="请输入"
            class="w-95"
            v-model="advancedForm.sku"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input
            placeholder="请输入"
            class="w-95"
            v-model="advancedForm.productName"
          ></el-input>
        </el-form-item>
        <el-form-item label="发货地">
          <el-input
            placeholder="请输入"
            class="w-95"
            v-model="advancedForm.portShipment"
          ></el-input>
        </el-form-item>
        <el-form-item label="目的地">
          <el-input
            placeholder="请输入"
            class="w-95"
            v-model="advancedForm.receivingAddress"
          ></el-input>
        </el-form-item>
        <el-form-item label="单证员">
          <el-input
            placeholder="请输入"
            class="w-95"
            @focus="selectUser(1)"
            v-model="nameFromClerkList"
          ></el-input>
        </el-form-item>

        <el-form-item label="创建人">
          <el-input
            placeholder="请输入"
            class="w-95"
            @focus="selectUser(2)"
            v-model="nameFromCreatorList"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            class="w-95"
            v-model="advancedForm.createTime"
            align="right"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="已出运时间">
          <el-date-picker
            class="w-95"
            v-model="advancedForm.shipTime"
            align="right"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="实际到货时间">
          <el-date-picker
            class="w-95"
            v-model="advancedForm.manualSignTime"
            align="right"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </ErpAdvancedFiltering>
    <!-- 选择大货照 -->
    <SelectBigGoodPhoto
      :option="selPhotoOption"
      :list="bigGoodsList"
      :shippingOrderId="shippingOrderId"
      @refresh="refresh"
    />
    <!-- 出库申请弹窗 -->
    <ApplyOut ref="applyout" @refresh="refresh" />
    <!-- 物流进度查询 -->
    <logistics-progress ref="logistics"></logistics-progress>
    <!-- 导出任务创建成功提示 -->
    <SuccessModel :visible.sync="downloadVisible" />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { productPage } from '@/api/order'
  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'
  import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'
  import Pagination from '@/components/Pagination/Index'
  import ErpDraggable from 'vuedraggable'
  import WaybillNo from './components/new-waybill-no'
  import CancelShipping from './components/cancel-shipping'
  import SignModel from './components/signModel'
  import UserChoose from '@/components/userChoose/index'
  import importModel from './components/importModel'
  import MutipleFields from './components/mutiple-fields'
  import { hasRight } from '@/utils/permissionBtn'
  import CustomCache from '@/utils/custom-cache'
  import customTableMixin from '@/utils/custom-table-mixin'
  import sendGoodsMixin from './components/select-big-good-photo/mixin'
  import SelectBigGoodPhoto from './components/select-big-good-photo/index.vue'
  import * as dayjs from 'dayjs'
  import ApplyOut from './components/apply-out' // ok
  import LogisticsProgress from './components/logistics-progress'
  import ImportShipping from './components/import-shipping'
  import TableImport from '@/components/tableImport'
  import SuccessModel from '@/components/tableImport/successModel'
  import MailSend from './components/mail-send'

  import {
    expressColumns,
    columns,
    searchStatusList,
    statusListArr,
    statusListMap,
  } from './helper/data'

  const index2Sum = {
    0: 'totalNum', //总件数
    1: 'totalCase', //总箱数
    2: 'totalGross', //总毛重
    3: 'totalVolume', //总体积
    4: 'totalChargedWeight',
    5: 'totalFreight',
  }
  import { getKeyIds } from '@/utils/pagelist-util'

  export default {
    name: 'ShipmentManagement',
    components: {
      SignModel, // 签收组件
      Pagination, //分页
      ErpDraggable, //自定义拖拽
      WaybillNo, //运单号
      CancelShipping, //取消出运
      importModel, // 导入
      UserChoose, // 用户选择
      MutipleFields, // 多字段
      SelectBigGoodPhoto, //大货照
      ApplyOut, //出库申请
      LogisticsProgress, //物流进度
      ImportShipping, // 导入
      TableImport, // 导入
      SuccessModel, //导出成功提示
      MailSend, // 发货/收货邮件提醒
    },
    mixins: [customTableMixin, sendGoodsMixin],
    data() {
      return {
        arrangeStatus: '标记已安排',
        hasClerkOperateRight: false, // 单证员操作权限
        drawer: false, //drawer控制
        activeName: '0',
        form: {
          condition: '', //入库单号、采购单号、供应商
          status: [], // v1.7.0出库状态
          wmsUpdateCancelFlag: '', // 安排状态
        },
        statusDisabled: false, // 禁止状态下拉选择
        advancedForm: {
          //高级筛选表单
          shipNo: '', //运单号
          shippingMethodId: '', // 运输方式
          shippingMethodType: '', //运输类型
          drawbackFlag: '', // 是否退税 入参：1=是 2=否 出参：1=是 0=否 2=部分退税
          mailSend: '', //发货邮件 int 1 已发 int 0未发
          signMailSend: '', //收货邮件
          sku: '', //产品编码
          productName: '', //产品名称
          portShipment: '', //发货地
          receivingAddress: '', // 目的地
          wmsUpdateCancelFlag: '', // 安排状态
          createTime: [], //创建时间 [开始时间,结束时间]
          shipTime: [],
          manualSignTime: [],
        },
        businessList: [], //选择的业务员列表
        clerkList: [], //选择的单证员列表
        creatorList: [], //选择的创建人列表
        mailList: [
          {
            code: '',
            label: '全部',
          },
          {
            code: 0,
            label: '未发',
          },
          {
            code: 1,
            label: '手动',
          },
          {
            code: 2,
            label: '自动',
          },
          {
            code: 3,
            label: '发送失败',
          },
          {
            code: 4,
            label: 'Soft Bounce',
          },
          {
            code: 5,
            label: 'Hard Bounce',
          },
        ],
        // 是否退税字典
        drawbackList: [
          { label: '全部', code: '' },
          { label: '是', code: 1 },
          { label: '否', code: 2 },
        ],
        statusListArr, //状态列表
        currentStatus: '', //当前状态
        tableData: [], // 列表数据
        page: {
          //分页
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        activities: [], //物流进度
        columns, //列表列
        expressColumns, //快递列表列
        searchStatusList, //出库状态下拉列表
        userCustomizeColumnId: '', //自定义保存id
        multipleSelection: [], //空海运勾选
        multipleExpressSelection: [], //快递勾选
        mailSend: true, //是否发送邮件
        mailDetail: {}, //邮件详情
        shippingUserCustomizeColumnId: '', //海运自定义保存Id
        expressUserCustomizeColumnId: '', //快递自定义保存id
        expressTranspotwayList: [], //快递运输方式
        hkTranspotwayList: [], //海运，空运运输方式
        transpotwayList: [], //运输方式
        dragOptions: {
          //drag配置
          animation: 600,
          group: 'description',
        },
        hasSignRight: false,
        shipTotal: 0, // 运费总计
        summaryParams: {
          type: 1, // 1当前页 2 勾选项 3 筛选项
        },
        summayData: [
          {
            //合计信息
            label: '总件数',
            count: 0,
          },
          {
            //合计信息
            label: '总箱数',
            count: 0,
          },
          {
            //合计信息
            label: '总毛重',
            count: 0,
          },
          {
            //合计信息
            label: '总体积',
            count: 0,
          },
          {
            //合计信息
            label: '总计费重（kg）',
            count: 0,
          },
          {
            //合计信息
            label: '总运费',
            count: 0,
          },
        ],
        waybillNoTitle: '录入运单号',
        downloadVisible: false,
      }
    },
    computed: {
      expressFinallyColumns() {
        //快递列
        let columns = this.expressColumns
        return columns.filter((item) => item.isShow === true)
      },
      shippingFinallyColumns() {
        // 空海运列
        let columns = this.columns
        return columns.filter((item) => item.isShow === true)
      },

      selection() {
        //勾选项
        let selection =
          this.activeName === '0'
            ? this.multipleExpressSelection
            : this.multipleSelection
        return selection
      },

      // 已完成和已取消状态的委托单单证员不允许修改，若多选选到这两种状态的单据，按钮不可点
      updateClerkNameDisabled() {
        const selection = this.selection
        if (!selection || selection.length === 0) {
          return false
        }
        if (selection.find((item) => [3, 4].includes(item.status))) {
          return true
        }
        return false
      },

      //是否显示导入
      isImport() {
        return this.selection.length > 0
      },

      // 手动出运
      batchShipmentButtonDisabled() {
        const selection = this.selection
        return selection.some((item) => item.status !== 1)
      },

      // 手动签收
      batchSignButtonDisabled() {
        const selection = this.selection
        return selection.some((item) => ![5].includes(item.status))
      },

      // 高级筛选表单控制
      shippingMethodDisabled() {
        if (this.activeName === '0') {
          return false
        } else {
          return !this.advancedForm.shippingMethodType
        }
      },

      // 筛选后的运输方式
      filterShippingMethods() {
        return this.activeName === '0'
          ? this.expressTranspotwayList
          : this.hkTranspotwayList
      },

      //名称处理
      nameFromBusinessList() {
        //选择的业务员名称
        const list = this.businessList
        return this._getUserNamesAndUserIdsFromUserList(list).names
      },
      nameFromClerkList() {
        //选择的单证员名称
        const list = this.clerkList
        return this._getUserNamesAndUserIdsFromUserList(list).names
      },
      nameFromCreatorList() {
        //选择的创建人名称
        const list = this.creatorList
        return this._getUserNamesAndUserIdsFromUserList(list).names
      },
    },
    watch: {
      // 统计维度变化
      'summaryParams.type': function (val) {
        // 统计类型变化
        // 勾选项变化
        this.getSummaryData()
      },
      // 状态变化
      currentStatus(val) {
        this._resetPageNo()
        this._resetStatus(val && val != 9)
        this.onSearch()
      },

      // tab切换
      activeName: {
        immediate: true,
        handler(val) {
          if (!val) {
            return
          }
          this.currentStatus = ''
          this._resetPageNo()
          this.onReset()
        },
      },
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this._resetPageNo()
          this.onSearch()
        }
      },
      // 运输方式
      'advancedForm.shippingMethodType': function (val) {
        this.hkTranspotwayList = this.transpotwayList.filter(
          (item) => item.transportType === val
        )
        this.advancedForm.shippingMethodId = ''
      },
    },
    filters: {
      // 时间处理
      time(val) {
        return dayjs(val).format('YYYY-MM-DD')
      },
      drawbackFlagFilter(val) {
        // 1=是 0=否 2=部分退税
        if ([1, 2].includes(val)) {
          return '是'
        } else if (val == 0) {
          return '否'
        } else {
          return '--'
        }
      },
    },
    created() {
      //获取自定义保存 9 海运 空运 100 快递
      CustomCache.getColumns(9, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.shippingUserCustomizeColumnId = res.userCustomizeColumnId
          this.columns = res.columns
        }
      })
      CustomCache.getColumns(100, this.userInfo.userId).then((res) => {
        if (res && res.columns) {
          this.expressUserCustomizeColumnId = res.userCustomizeColumnId
          this.expressColumns = res.columns
        }
      })

      this.getTransportMethods()
      this.hasClerkOperateRight = hasRight('CLERK:EDIT')
      this.hasSignRight = hasRight('SHIPMENT:SIGN')
    },
    activated() {
      this._resetPageNo()
      this.onSearch()
    },

    methods: {
      // v1.8.0获取筛选项合计
      async getSummaryData() {
        // 获取统计类型
        let { type } = this.summaryParams,
          summaryReqData = { tenantId: this.userInfo.tenantId }
        switch (type) {
          case 1: // 当前页合计
          case 2: // 勾选项合计
            summaryReqData.shippingOrderIds = this.getShippingOrderIds()
            break
          case 3:
            summaryReqData = {
              //勾选项合计，参数是基础查询和高级筛选查询参数
              ...this._getSearchRequestParams(),
            }
            break
        }
        if (type === 2 && summaryReqData.shippingOrderIds?.length === 0) {
          this.summayData.forEach((item, index, arr) => {
            item.count = ''
          })
          return
        }
        const getSummaryRequestMethod =
          this.activeName === '0'
            ? ShippingPlanInteractor.expressTotalCount
            : ShippingPlanInteractor.seaAirTotalCount
        const res = await getSummaryRequestMethod(summaryReqData)
        this.summayData.forEach((item, index, arr) => {
          item.count = res ? res[index2Sum[index]] : ''
        })
      },
      // 标记已出运
      async changeArrangeStatus(flag) {
        const _len = this.multipleExpressSelection.length

        if (!_len) {
          this.$message.warning('请勾选委托单')
          return false
        }

        this.$confirm(
          `确认将${_len}条委托单更新为${flag === '1' ? '已安排' : '未安排'}？`,
          '提示',
          {
            type: 'warning',
          }
        ).then(async () => {
          let _ids = []
          this.multipleExpressSelection.forEach((item) =>
            _ids.push(item.shippingOrderId)
          )

          const res = await ShipmentMangeInteractor.arrangeFlag(flag, _ids)
          if (res?.code == '000000') {
            this.$message.success('操作成功')
            this.onSearch()
          }
        })
      },

      // 获取当前页或者勾选项的shippingOrderIds,以逗号拼接
      getShippingOrderIds() {
        const { type } = this.summaryParams
        if (type === 3) return ''

        return this[type === 1 ? 'tableData' : 'selection']
          .map((item) => item.shippingOrderId)
          .join(',')
      },
      // 出库申请
      showApplyDialog(row) {
        const applyout = this.$refs.applyout
        productPage({
          shippingOrderId: row.shippingOrderId,
        }).then((res) => {
          if (res && res.code === '000000') {
            const { businessNumber, number, shippingOrderId } = row
            applyout.showApply({
              list: res.data || [],
              option: {
                businessNumber,
                number,
                shippingOrderId,
              },
            })
          }
        })
      },
      // 计算运费总计
      calcTotalFreight(arr) {
        this.shipTotal = this.utils.amountTotal(arr, 'totalFreight', 3)
      },

      refresh() {
        this.onSearch()
      },
      // 高级筛选取消
      onDrawerCancel() {
        this.advancedForm = this.copyForm
        this.businessList = this.copyBusinessList
        this.clerkList = this.copyClerkList
        this.creatorList = this.copyCreatorList
      },
      // 高级筛选open
      onDrawerOpen() {
        const _ = (obj) => JSON.parse(JSON.stringify(obj))
        this.copyForm = _(this.advancedForm)
        const { businessList, clerkList, creatorList } = this
        this.copyBusinessList = _(businessList)
        this.copyClerkList = _(clerkList)
        this.copyCreatorList = _(creatorList)
      },
      _resetPageNo() {
        this.page.pageNo = 1
      },
      // 高级筛选确定
      onDrawerConfirm() {
        this._resetPageNo()
        const requestMethod =
          this.activeName === '0'
            ? ShipmentMangeInteractor.getKDWTList
            : ShipmentMangeInteractor.getWTList
        const requestParams = this._getSearchRequestParams()
        if (Array.isArray(requestParams.shippingMethodId)) {
          requestParams.shippingMethodId =
            requestParams.shippingMethodId.join(',')
        }

        requestMethod(requestParams).then((res) => {
          this.customTableKey = Math.random()
          if (res && res.code === '000000') {
            this.tableData = res.data.data || []
            this.page.total = res.data.total
            // 获取统计数量
            this._resetSelection()
            this.getSummaryData(requestParams)
          }
        })
      },
      // 获取查询请求参数
      _getSearchRequestParams() {
        const { advancedFormData } = this._getSearchObjFromAdvancedForm(),
          { pageLe, pageNo } = this.page,
          state = this.currentStatus
        const form = { ...this.form }

        if (Array.isArray(form.status)) {
          form.status = form.status?.join(',')
        }
        return {
          ...advancedFormData,
          ...form,
          pageLe,
          pageNo,
          state,
          keyIds: getKeyIds(this),
        }
      },

      _getSearchObjFromAdvancedForm() {
        const userIdsTypes = ['businessId', 'clerkId', 'creatorId']
        const { createTime, shipTime, manualSignTime } = this.advancedForm
        const obj = {}
        const index2List = [
          'businessList', //选择的业务员列表
          'clerkList', //选择的单证员列表
          'creatorList', // 选择的创建人列表,
        ]

        userIdsTypes.forEach((type, index) => {
          obj[type] = this._getUserNamesAndUserIdsFromUserList(
            this[index2List[index]]
          ).ids
        })
        return {
          advancedFormData: {
            ...this.advancedForm,
            ...obj,
            ...this._getStartAndEndTimeFromTimeArray(
              createTime,
              'createStartTime',
              'createEndTime'
            ),
            ...this._getStartAndEndTimeFromTimeArray(
              shipTime,
              'shipStartTime',
              'shipEndTime'
            ),
            ...this._getStartAndEndTimeFromTimeArray(
              manualSignTime,
              'manualSignStartTime',
              'manualSignEndTime'
            ),
          },
        }
      },

      // 高级筛选查询时间设置
      _getStartAndEndTimeFromTimeArray(list, startTime, endTime) {
        const timeObj = {}
        timeObj[startTime] = !list || list.length === 0 ? '' : list[0]
        timeObj[endTime] = !list || list.length === 0 ? '' : list[1]
        return timeObj
      },
      // 根据人员列表获取名称和id
      _getUserNamesAndUserIdsFromUserList(list) {
        let ids, names
        if (!list || list.length === 0) {
          ids = ''
          names = ''
        } else {
          ids = list.map((item) => item.id).join(',')
          names = list.map((item) => item.name).join(',')
        }
        return {
          ids,
          names,
        }
      },

      // 获取货运方式
      getTransportMethods() {
        ShipmentMangeInteractor.transpotwayList().then((res) => {
          if (res && res.code === '000000') {
            this.transpotwayList = res.data
            this.expressTranspotwayList = this.transpotwayList.filter(
              (item) => item.transportType === 3
            )
          }
        })
      },
      // 更新单证员
      chooseUser([{ id, name, englishName }]) {
        let requestData = []
        if (this.singeClerkEdit) {
          requestData.push({
            shippingOrderId: this.currentRow.shippingOrderId,
            clerkName: `${name} ${englishName}`,
            clerkId: id,
          })
        } else {
          let selection =
            this.activeName === '0'
              ? this.multipleExpressSelection
              : this.multipleSelection
          requestData = JSON.parse(JSON.stringify(selection))
          requestData = requestData.map((i) => {
            let obj = {}
            obj.shippingOrderId = i.shippingOrderId
            obj.clerkName = `${name} ${englishName}`
            obj.clerkId = id
            return obj
          })
        }

        ShipmentMangeInteractor.updateClerkBatch(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('单证员修改成功')
            this.onButtonSearch()
          }
        })
      },
      // 人员选择 0 客户代表 1 单证员 2 创建人
      selectUser(type) {
        this.currentSelectUserType = type
        const type2UserList = {
          0: 'businessList', //选择的业务员列表
          1: 'clerkList', //选择的单证员列表
          2: 'creatorList', // 选择的创建人列表,
        }
        this.$refs['formUserChoose'].showAddEdit(
          '',
          this[type2UserList[type]],
          false
        )
      },

      //高级筛选人员处理
      chooseAdvancedFormUser(users) {
        const type2UserList = {
            0: 'businessList', //选择的业务员列表
            1: 'clerkList', //选择的单证员列表
            2: 'creatorList', // 选择的创建人列表,
          },
          type = this.currentSelectUserType

        this[type2UserList[type]] = users.map((item) => {
          const obj = {}
          ;['id', 'name'].forEach((i) => {
            obj[i] = item[i]
          })
          return obj
        })
      },

      // 修改单个单证员
      editSingleClerk(row) {
        this.currentRow = row
        this.singeClerkEdit = true
        const { clerkName, clerakId } = row
        let selectUsers = []
        if (clerkName && clerkName.trim()) {
          selectUsers = [
            {
              name: clerkName.split(' ')[0],
              id: clerakId,
            },
          ]
        }
        this.$refs['UserChoose'].showAddEdit('shipingOrder', selectUsers, true)
      },

      // 修改单证员
      modifyClerk() {
        if (this._isSelectionNotEmpty()) {
          this.singeClerkEdit = false
          this.$refs.UserChoose.showAddEdit('', '', true)
        }
      },
      onDialogConfirm(tableData, fileObj) {
        let requestData = {
          shippingOrderCancelConfirmDtos: tableData,
          ...fileObj,
          shippingOrderId: this.shippingOrderId,
        }
        ShipmentMangeInteractor.cancelConfirm(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.$message.success('操作成功')
            this.onSearch()
          }
        })
      },
      //修改
      updateSheet(row) {
        if (!this._checkRights(row)) {
          return
        }
        if (row.wmsUpdateCancelFlag === 1) {
          this.$message({
            showClose: true,
            message: `当前出运委托已安排，请联系单证员${row.clerkName}取消安排后，即可修改单据或取消出运`,
            type: 'error',
            duration: 0,
          })
          return false
        }
        this.$router.push({
          path: '/order/shipmentManage/update-shipment-sheet',
          query: {
            shippingOrderId: row.shippingOrderId,
          },
        })
      },

      // 重置选中项
      _resetSelection() {
        this.multipleSelection = []
        this.multipleExpressSelection = []
      },
      // 处理勾选项时，勾选项统计数据
      _handleChecked(val) {
        if (this.summaryParams.type == 2) {
          // 当没有勾选项时，不要调接口
          if (val?.length === 0) {
            let data = {
              totalCase: 0,
              totalChargedWeight: 0,
              totalFreight: 0,
              totalGross: 0,
              totalNum: 0,
              totalVolume: 0,
            }
            this.summayData.forEach((item, index, arr) => {
              item.count = data[index2Sum[index]]
            })
          } else {
            this.getSummaryData()
          }
        }
      },

      //勾选变化
      handleSelectionChange(val) {
        this.multipleSelection = val
        val.length > 0
          ? this.calcTotalFreight(val)
          : this.calcTotalFreight(this.tableData)
        // 勾选项变化
        this._handleChecked(val)
      },
      //快递勾选变化
      handleExpressSelectionChange(val) {
        this.multipleExpressSelection = val
        val.length > 0
          ? this.calcTotalFreight(val)
          : this.calcTotalFreight(this.tableData)
        // 勾选项变化
        this._handleChecked(val)
      },
      //快递导出
      exportExpress(exportType) {
        let shippingOrderId = ''
        if (exportType === 1) {
          if (
            !this.multipleExpressSelection ||
            this.multipleExpressSelection.length === 0
          ) {
            return this.$message.warning('请先选择需要导出的委托单')
          } else {
            shippingOrderId = this.multipleExpressSelection
              .map((order) => order.shippingOrderId)
              .join(',')
          }
        }
        const { advancedFormData } = this._getSearchObjFromAdvancedForm()

        let requestData = {
          condition: this.form.condition,
          exportType,
          orderBy: '',
          pageLe: this.page.pageLe,
          pageNo: this.page.pageNo,
          state: this.currentStatus,
          ...advancedFormData,
          shippingOrderId,
        }
        if (this.form.status && this.form.status.length > 0) {
          requestData.status = this.form.status.join(',')
        } else {
          requestData.status = ''
        }
        ShipmentMangeInteractor.expressExport(requestData).then((res) => {
          if (res && res.code === '000000') {
            this.downloadVisible = true
          }
        })
      },
      // 获取文件名
      $_getDownLoadFileNames(item, isExpress) {
        let fileName = ''
        if (isExpress) {
          let time = dayjs(Date.now()).format('YYYY-MM-DD')
          fileName = `${time}-快递单`
        } else {
          fileName = `${item.businessNumber}-订舱单${item.shippingMethod}`
        }
        return fileName
      },
      // 导出
      exportData() {
        if (this.multipleSelection.length === 0) {
          return this.$message.warning('请先选择需要导出的委托单')
        }
        if (this.multipleSelection.length > 10) {
          return this.$message.warning('最多一次导出10条委托单')
        }

        let shipOrderIdList = this.multipleSelection.map(
          (item) => item.shippingOrderId
        )

        ShipmentMangeInteractor.exportExcel({
          shipOrderIdList,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.downloadVisible = true
          }
        })
      },

      // 创建下载链接
      _createLink(blobData, fileName) {
        let blob = new Blob([blobData], {
          type: 'application/vnd.ms-excel',
        })
        let linkElement = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        linkElement.setAttribute('href', url)
        linkElement.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(linkElement)
        linkElement.click()
        URL.revokeObjectURL(linkElement.href) // 释放URL 对象
      },

      // 空海运下载
      async downloadData() {
        if (this.multipleSelection.length === 0) {
          return this.$message.warning('请先选择需要下载的委托单')
        }
        let shipOrderIds = this.multipleSelection
          .map((i) => i.shippingOrderId)
          .join(',')
        let res = await ShipmentMangeInteractor.download(shipOrderIds)
        let fileName = `出运单-${dayjs(Date.now()).format('YYYY-MM-DD')}-${
          Math.random().toFixed(3) * 1000
        }`

        this._createLink(res, fileName)
      },
      // 操作他人委托单权限
      _checkRights({ operateFlag }) {
        if (operateFlag !== 1) {
          this.$message.warning('暂无操作权限')
          return false
        } else {
          return true
        }
      },

      // 填写运单号
      setWaybillNumber(row, type, titleType) {
        this.waybillNoTitle = `${titleType}运单号`
        if (!this._checkRights(row)) {
          return
        }
        let shipNos = row.shipNos
          .slice()
          .filter((item) => item.recordType === (titleType === '录入' ? 1 : 2))
        this.$refs['waybillNo'].showAddEdit(
          {
            tailShipNo: '',
            tailShippingMethodId: '',
            ...row,
            shipNos,
          },
          type
        )
      },

      // 确认简单封装
      _confirm(title) {
        return this.$confirm(title, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
      },

      // 勾选项非空判断
      _isSelectionNotEmpty() {
        let selection = this.selection
        if (selection && selection.length === 0) {
          this.$message.warning('请至少选择一条数据')
          return false
        } else {
          return true
        }
      },
      // 批量手动签收
      batchSign() {
        if (this._isSelectionNotEmpty()) {
          this.$refs['SignModel'].showAddEdit(this.selection)
        }
      },
      // 批量手动出运
      batchShip() {
        if (this._isSelectionNotEmpty()) {
          this._confirm('是否确定货物已出运？')
            .then(() => {
              ShipmentMangeInteractor.manualShip(
                this.selection.map((i) => i.shippingOrderId)
              ).then((res) => {
                if (res && res.code === '000000') {
                  this.$message.success('手动出运成功')
                  this.onButtonSearch()
                }
              })
            })
            .catch(() => {})
        }
      },
      // 手动出运
      manualShipment(row) {
        if (!this._checkRights(row)) {
          return
        }
        this._confirm('是否确定货物已出运？')
          .then(() => {
            ShipmentMangeInteractor.manualShip([row.shippingOrderId]).then(
              (res) => {
                if (res && res.code === '000000') {
                  this.$message.success('手动出运成功')
                  this.onButtonSearch()
                }
              }
            )
          })
          .catch(() => {})
      },

      //手动签收
      async manualSignInClick(row) {
        return this.$refs['SignModel'].showAddEdit(row)
      },
      // 取消出运
      cancelShipping(row) {
        if (!this._checkRights(row)) {
          return
        }
        if (row.wmsUpdateCancelFlag === 1) {
          this.$message({
            showClose: true,
            message: `当前出运委托已安排，请联系单证员${row.clerkName}取消安排后，即可修改单据或取消出运`,
            type: 'error',
            duration: 0,
          })
          return false
        }

        const { shippingOrderId } = row
        this.shippingOrderId = shippingOrderId
        ShipmentMangeInteractor.cancelQuery({ shippingOrderId }).then((res) => {
          if (res && res.code === '000000') {
            this.$refs['cancelShipping'].showAddEdit(row)

            let tableData = res.data || []
            this.$refs['cancelShipping'].cancelForm.tableData = tableData.map(
              (i) => {
                if (i.shippingOrderProductStatus == 0) {
                  i.inStockId = i.warehouseId
                }
                i.usable = '1'
                return i
              }
            )
          }
        })
      },

      // 保存自定义显示列
      operateSave() {
        const {
          activeName,
          expressColumns,
          expressUserCustomizeColumnId,
          shippingUserCustomizeColumnId,
        } = this
        let columns = activeName === '0' ? expressColumns : this.columns
        let userCustomizeColumnId =
          activeName === '0'
            ? expressUserCustomizeColumnId
            : shippingUserCustomizeColumnId
        let menuType = activeName === '0' ? 100 : 9

        let customizeData = JSON.stringify(columns)

        let params = {
          userCustomizeColumnId,
          menuType,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res && res.code === '000000') {
            this[
              activeName === '0'
                ? 'expressUserCustomizeColumnId'
                : 'shippingUserCustomizeColumnId'
            ] = res.data.userCustomizeColumnId

            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 关闭
      handleClose() {
        this.drawer = false
      },

      // enter事件额外处理，避免接口多次调用
      onEnterSearch(e) {
        e.target.blur()
        if (!this.form.condition || this.form.condition.trim() === '') {
          return
        }
        this.onButtonSearch()
      },

      // 搜索按钮点击事件
      onButtonSearch() {
        this._resetPageNo()
        this.onSearch()
      },

      // 获取状态名称
      getStatusName(val) {
        return statusListMap[val] ? statusListMap[val] : '未知状态'
      },

      // 获取标签类型
      getTagType(val) {
        if ([3].includes(val)) {
          return 'success'
        }
        if ([2, 4].includes(val)) {
          return 'danger'
        }
        if ([1].includes(val)) {
          return ''
        }
        if ([0].includes(val)) {
          return 'warning'
        }
      },

      // 分页参数回调
      onPagination({ page, limit }) {
        this.page.pageLe = limit
        this.page.pageNo = page
        this.onSearch()
      },

      // 搜索
      onSearch() {
        let requestMethod =
          this.activeName === '0'
            ? ShipmentMangeInteractor.getKDWTList
            : ShipmentMangeInteractor.getWTList
        const requestParams = this._getSearchRequestParams()
        if (Array.isArray(requestParams.shippingMethodId)) {
          requestParams.shippingMethodId =
            requestParams.shippingMethodId.join(',')
        }
        requestMethod(requestParams).then((res) => {
          this.customTableKey = Math.random()

          if (res?.code === '000000') {
            this.tableData = res.data.data || []
            // 海运空运-运单号、预计到达时间格式处理
            if (this.activeName === '1') {
              this.tableData = this.tableData.map((item) => {
                return {
                  ...item,
                  shipNosFields: this.handleMutipleFields(item, 'shipNo'),
                  arriveTimes: this.handleMutipleFields(item, 'arriveTime'),
                }
              })
            }
            this.page.total = res.data.total
            this.calcTotalFreight(this.tableData)
            this._resetSelection()
            // 获取统计数量
            this.getSummaryData()
          }
        })
      },

      // 详情
      goDetail(shippingOrderId, operateFlag, shipTime, number, type) {
        const query = {
          shipingOrderId: shippingOrderId,
          operateFlag,
          type,
          number,
        }
        shipTime && (query.st = shipTime)
        this.$router.push({
          name: 'shipment-sheet-detail',
          query,
        })
      },

      //订单详情
      goOrderDetail(orderCode) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: {
            orderCode,
            status: 3,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },

      // 重置高级筛选表单
      _resetAdvancedForm() {
        Reflect.ownKeys(this.advancedForm).forEach((field) => {
          this.advancedForm[field] = Array.isArray(this.advancedForm[field])
            ? []
            : ''
        })
        const userList = ['businessList', 'clerkList', 'creatorList']
        userList.forEach((item) => {
          this[item] = []
        })
      },

      // 查询条件重置
      onReset() {
        this.form = {
          condition: '',
          status: '',
        }
        this.currentStatus = ''
        this._resetAdvancedForm()
        this.onSearch()
      },

      // 高级筛选控制
      showDrawer() {
        this.$refs.filterDrawer.showDrawer()
      },

      // 新增
      addNew() {
        this.$router.push({
          name: 'new-shipment-sheet',
        })
      },

      // 物流进度详情
      showDetail({ number, tenantId, type }) {
        ShipmentMangeInteractor.getProgressList({
          orderNumber: number,
          tenantId,
          type,
        })
          .then((res) => {
            if (res && res.code === '000000') {
              this.activities = res.data || []
              this.drawer = true
            } else {
              this.activities = []
            }
          })
          .catch((err) => {
            this.activities = []
          })
      },

      // 快递导入
      shipImport(type) {
        this.$refs.ImportModel.show(type)
      },
      // 下拉状态重置
      _resetStatus(isDisabled) {
        if (isDisabled) {
          this.form.status = []
          this.statusDisabled = true
        } else {
          this.statusDisabled = false
        }
      },
      // 物流进度追踪
      logisticsProgress(id) {
        this.$refs.logistics.init(id, 1)
      },
      // 运单号、预计到达时间格式处理
      handleMutipleFields(row, field) {
        let _arr = []
        let logistics = row.shipNoLogistics
        let shipsNosArr = row.shipNos?.map((item) => item.shipNo) || []
        if (logistics.length) {
          for (let i = 0; i < logistics.length; i++) {
            if (!shipsNosArr.includes(logistics[i]?.shipNo)) {
              _arr.push(logistics[i])
            }
          }
        }
        let shipNosAll = row.shipNos.concat(_arr)
        if (field === 'shipNo') {
          return shipNosAll.map((item) => item.shipNo)
        } else {
          return shipNosAll.map(
            (item) =>
              (item.arriveTime || item.expectArriveDate)?.split(' ')[0] || '--'
          )
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .table-container {
    overflow-y: hidden !important;
  }

  .page-header {
    height: 40px;
  }

  .w-95 {
    width: 95%;
  }

  .ml-15 {
    margin-left: 15px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .radio-wrap {
    display: flex;
    justify-content: space-between;
  }

  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .pointer {
    cursor: pointer;
  }

  i {
    font-size: 18px;
    margin: 0 6px;
  }

  .link {
    text-decoration: underline;
    color: #1890ff;
    display: inline-block;
  }

  .progress {
    padding: 15px 20px;

    .progress-wrapper {
      display: flex;

      // flex-direction: column;
      & .left {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .line {
          flex: 1;
          width: 1px;
          background-color: #c0c4cc;
          margin-top: 5px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;

        & span {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .mt15 {
    margin-top: 15px;
  }

  .error-color {
    color: #f56c6c;
  }

  .success-color {
    color: #67c23a;
  }

  .warning-color {
    color: #e6a23c;
  }

  .war .pointer {
    cursor: pointer;
  }

  .span {
    cursor: pointer;
    max-width: 90%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .split-div {
    height: 1px;
    background-color: #dcdfe6;
    margin: 5px 0;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<style>
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
