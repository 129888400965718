var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('mailConfig.addSubAccount'),"visible":_vm.dialogFormVisible,"width":"506px","close-on-click-modal":false,"custom-class":"bind-mail"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-right"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Cancel'))+" ")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Confirm'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"line mt-10"}),_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-position":"left","label-width":"100px"}},[_c('el-form-item',{attrs:{"label-position":"left","label":_vm.$t('mailConfig.myemail') + ':',"prop":"emailAccount","rules":[
          {
            required: true,
            trigger: 'change',
            message: _vm.$t('placeholder.plsSel'),
          } ]}},[_c('el-select',{model:{value:(_vm.form.emailAccount),callback:function ($$v) {_vm.$set(_vm.form, "emailAccount", $$v)},expression:"form.emailAccount"}},_vm._l((_vm.mailServerList),function(item,index){return _c('el-option',{key:item.mailAccountId,attrs:{"label":item.emailAccount,"value":item.emailAccount}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('mailConfig.authorizedPerson') + ':',"label-position":"left","label-width":_vm.settings === 'en' ? '150px' : '100px',"required":""}},[_c('div',{staticClass:"mb-10 add-btn-wrappper"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.openUserChoose}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.add'))+" ")])],1)]),_c('div',{staticClass:"table-wrapper"},[_c('el-table',{staticClass:"mb-20",staticStyle:{"width":"100%"},attrs:{"data":_vm.selectedUsers,"border":"","max-height":"215px"}},[_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('mailConfig.uName'),"align":"center","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.settings === 'en' ? row.englishName : row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"isOnline","label":_vm.$t('mailConfig.isCustomerService'),"align":"center","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-switch',{attrs:{"inactive-value":0,"active-value":1,"inactive-text":_vm.$t('mailConfig.close'),"active-text":_vm.$t('mailConfig.open')},model:{value:(row.roleType),callback:function ($$v) {_vm.$set(row, "roleType", $$v)},expression:"row.roleType"}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('mailConfig.operation'),"align":"center","width":_vm.settings === 'en' ? '120' : '80'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-button',{staticClass:"delete-btn",attrs:{"type":"text"},on:{"click":function($event){return _vm.removeUser(row)}}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.delete'))+" ")])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"line mb-10"}),_c('UserChoose',{ref:"userChoose",attrs:{"multiple":true},on:{"choose-user":_vm.chooseUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }