<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="750px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form
      ref="searchForm"
      :model="form"
      :rules="rules"
      label-width="110px"
      label-position="left"
    >
      <!-- 规格类型不可编辑 -->
      <div class="bg-wrapper">
        <el-row class="bg">
          <el-col :span="12" style="margin-right: 20px">
            <el-form-item
              label="中文规格名称"
              label-width="100px"
              style="margin-right: 10px"
            >
              <el-input v-model="searchForm.parentNameCn" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="英文规格名称">
              <el-input v-model="searchForm.parentNameEn" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <!-- 规格值 -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="中文规格值名称"
            style="margin-right: 20px"
            label-width="120px"
            v-trim
          >
            <el-input v-model.trim="searchForm.nameCn" style="width: 215px" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="英文规格值名称"
            style="margin-right: 20px"
            label-width="120px"
            v-trim
          >
            <el-input v-model="searchForm.nameEn" />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 操作按钮 -->
      <div class="btn-group">
        <el-button type="primary" @click="showNew">新增</el-button>
        <div>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </div>
      </div>
    </el-form>
    <!-- 列表 -->
    <el-form
      ref="specForm"
      :model="form"
      style="height: 450px; padding-bottom: 5px"
    >
      <el-table :data="form.tableData" border height="450px">
        <el-table-column
          label="中文名称"
          align="center"
          label-class-name="require-th"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-form-item
              v-if="scope.row.isEdit"
              :prop="'tableData.' + scope.$index + '.nameCn'"
              :rules="[
                {
                  required: true,
                  message: '请输入中文名称',
                  trigger: 'change',
                },
              ]"
            >
              <el-input
                v-model.trim="scope.row.nameCn"
                clearable
                v-no-special-chars="invalidChars"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <span v-else>{{ scope.row.nameCn || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="英文名称"
          align="center"
          label-class-name="require-th"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-form-item
              v-if="scope.row.isEdit"
              :prop="'tableData.' + scope.$index + '.nameEn'"
              :rules="[
                {
                  required: true,
                  message: '请输入英文名称',
                  trigger: 'change',
                },
              ]"
            >
              <el-input
                v-model="scope.row.nameEn"
                clearable
                maxlength="50"
                @input="() => tagValueblur(scope.row)"
                v-no-special-chars="invalidChars"
                v-filterSpace
                v-trim
              ></el-input>
            </el-form-item>
            <span v-else>{{ scope.row.nameEn || '--' }}</span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <el-tooltip content="编辑" placement="top">
              <el-button
                circle
                type="primary"
                icon="el-icon-edit"
                plain
                size="mini"
                v-if="!scope.row.isEdit"
                @click="toggleEdit(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="保存" placement="top">
              <el-button
                circle
                type="primary"
                plain
                size="mini"
                icon="iconfont icon-spec-save"
                v-if="scope.row.isEdit"
                @click="saveEditSpecVale(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <el-button
                circle
                type="danger"
                plain
                size="mini"
                icon="el-icon-delete"
                @click="deleteSpecValue(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-pagination
      style="margin-bottom: 20px"
      background
      :current-page="searchForm.pageNo"
      :page-size="searchForm.pageLe"
      layout="total, sizes,  prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    />

    <!-- 新增规格值弹窗 -->
    <SpecValueNewModel
      ref="specValueNewModel"
      :invalidChars="invalidChars"
      @new-spec-value="saveNew"
    ></SpecValueNewModel>
  </el-dialog>
</template>

<script>
  import SpecValueNewModel from './spec-value-new-model'
  import { productSettingInteractor } from '@/core'
  export default {
    name: 'SpecModel',
    components: {
      SpecValueNewModel,
    },
    props: {
      invalidChars: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        rules: {},
        newFormRules: {
          nameCn: [{ required: true, trigger: 'blur', message: '请输入' }],
          nameEn: [{ required: true, trigger: 'blur', message: '请输入' }],
        },
        loading: false,
        title: '规格值列表',

        searchForm: {
          // 搜索表单
          parentId: '', // 父id,规格值时，不能为空
          parentNameCn: '', // 父名称-中文
          parentNameEn: '', // 父名称-英文
          nameCn: '', //  规格名称-中文
          nameEn: '', // 规格名称-英文
          pageLe: 10, // 分页数量
          pageNo: 1, // 分页页码
        },
        total: 0, // 总数

        form: {
          tableData: [],
        },

        newForm: {
          nameCn: '',
          nameEn: '',
        },

        dialogFormVisible: false,
        newVisible: false,
      }
    },
    methods: {
      showNew() {
        this.$refs.specValueNewModel.newVisible = true
      },
      deleteSpecValue(row) {
        this.$confirm(
          '删除后该规格值不能再被新产品使用，请谨慎操作！',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(async () => {
          productSettingInteractor
            .removeProductSpecsSettingApi(row.id)
            .then((res) => {
              if (res?.code === '000000') {
                this.$emit('spec')
                this.$baseMessage(
                  '删除成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
                if (
                  this.form.tableData.length === 1 &&
                  this.searchForm.pageNo > 1
                ) {
                  this.searchForm.pageNo = this.searchForm.pageNo - 1
                }
                this.getTableData()
              } else {
                this.$baseMessage(
                  '删除失败',
                  'error',
                  false,
                  'erp-hey-message-error'
                )
              }
            })
        })
      },
      toggleEdit(row) {
        // this.form.tableData.forEach((item) => {
        //   item.isEdit = false
        // })
        row.isEdit = true
      },
      // 重置
      reset() {
        this.searchForm.nameCn = ''
        this.searchForm.nameEn = ''
        this.searchForm.pageNo = 1
        this.searchForm.pageLe = 10
        this.getTableData()
      },

      // 查询
      search() {
        this.searchForm.pageNo = 1
        this.getTableData()
      },

      // 获取规格值列表
      getTableData() {
        productSettingInteractor
          .listSpecsValueApi({
            ...this.searchForm,
          })
          .then((res) => {
            if (res?.code === '000000') {
              this.total = res?.data?.total
              this.form.tableData = (res?.data?.data || []).map((item) => {
                item.isEdit = false
                return item
              })
            }
          })
      },

      // 保存规格值
      saveEditSpecVale(row) {
        this.$refs.specForm.validate((valid) => {
          if (valid) {
            productSettingInteractor
              .productSpecsSettingSaveApi({
                ...row,
                parentId: this.searchForm.parentId,
              })
              .then((res) => {
                if (res?.code === '000000') {
                  this.$baseMessage(
                    '编辑成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                  this.$emit('spec')
                  row.isEdit = false
                }
              })
          }
        })
      },

      sizeChange(val) {
        this.searchForm.pageLe = val
        this.searchForm.pageNo = 1
        this.getTableData()
      },
      currentChange(val) {
        this.searchForm.pageNo = val
        this.getTableData()
      },

      //规格值首字母大写
      tagValueblur(row) {
        let val = row.nameEn
        if (!val) return
        let reg = new RegExp('^[\u4E00-\u9FA5]*$')
        if (reg.test(val)) {
          // return (this.tagValue = '')
          row.nameEn = ''
          return
        }
        if (!reg.test(val)) {
          row.nameEn = val
            ?.replace(/[\u4E00-\u9FA5]/g, '')
            ?.replace(/[\u3000-\u303F\uFF00-\uFFEF\u2018-\u201D\u2026]/g, '')
            ?.replace(/\b\w+\b/g, (word) => {
              return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
          console.log(row.nameEn)
        }
      },

      //展示弹框
      showAddEdit(row) {
        this.searchForm = {
          ...this.searchForm,
          parentId: row.id,
          parentNameCn: row.nameCn,
          parentNameEn: row.nameEn,
        }

        this.getTableData()
        this.dialogFormVisible = true
      },

      // 新增规格值保存
      saveNew(newForm, close) {
        productSettingInteractor
          .productSpecsSettingSaveApi({
            ...newForm,
            parentId: this.searchForm.parentId,
            disable: 0,
            productType: 0,
            valueType: 1,
          })
          .then((res) => {
            if (res?.code === '000000') {
              this.$baseMessage(
                '新增成功',
                'success',
                false,
                'erp-hey-message-success'
              )
              this.getTableData()
              this.$emit('spec')
              close()
            }
          })
      },

      // 关闭新增弹窗
      closeNew() {
        this.newVisible = false
        this.newForm.nameCn = ''
        this.newForm.nameEn = ''
        this.$refs['newForm'].resetFields()
      },
      // 关闭
      close() {
        this.$refs['searchForm'].resetFields()
        this.reset()
        this.loading = false
        this.dialogFormVisible = false
      },

      // 标签删除
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1)
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let response
            this.loading = true
            productSettingInteractor
              .productSpecsSettingSaveApi({
                ...this.form,
              })
              .then((res) => {
                if (res?.code === '000000') {
                  this.$baseMessage(
                    this.form.id ? '编辑成功' : '新增成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                  this.$emit('spec')
                  this.close()
                }
              })
              .then(() => {
                this.loading = false
              })
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bg-wrapper {
    padding: 0 20px;
    background-color: #f2f2f2;
  }
  .bg {
    height: 68px;
    display: flex;
    align-items: center;
    .el-form-item {
      margin-bottom: 0px !important;
    }
    margin-bottom: 25px;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 20px;
  }
  ::v-deep {
    .el-input.is-disabled .el-input__inner {
      background-color: #f2f2f2 !important;
      color: #000000 !important;
    }
  }
  ::v-deep {
    .el-dialog__body {
      padding: 5px 20px !important;
    }
  }
  ::v-deep {
    .el-table--border {
      border: 1px solid #ebeef5 !important;
    }
  }
  ::v-deep {
    .el-table .el-form-item--small.el-form-item {
      margin: 5px 0 !important;
      &.is-error {
        margin: 16px 0 !important;
      }
    }
  }
</style>
