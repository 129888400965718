<template>
  <div class="soldout-dialog">
    <el-dialog
      title="产品下架"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div class="mb20">确定将产品从此商城下架？</div>
      <el-form :model="form" :rules="rules" ref="formRef" label-width="80px">
        <el-form-item label="下架原因" prop="reason">
          <el-input
            type="textarea"
            v-model="form.reason"
            placeholder="请输入"
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="500"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="handleSubmit" :loading="loading">
          确 定
        </el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { PlatformProductListInteractor } from '@/core'
  export default {
    props: {
      value: { type: Boolean, require: true },
      row: { type: Object, require: true },
    },
    data() {
      return {
        form: { reason: '' },
        rules: {
          reason: [
            { required: true, message: '请输入下架原因', trigger: 'change' },
          ],
        },
        loading: false,
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },
    },
    methods: {
      handleClose() {
        this.dialogVisible = false
        this.$refs.formRef.resetFields()
      },
      handleSubmit() {
        this.$refs.formRef.validate(async (valid) => {
          if (!valid) return
          const params = {
            platformProductCode: this.row.platformProductCode,
            platformCode: this.row.platformCode,
            type: 0,
            reason: this.form.reason,
          }
          try {
            this.loading = true
            const { code } = await PlatformProductListInteractor.downShelfApi(
              params
            )
            if (code === '000000') {
              this.$emit('refresh')
              const msg =
                this.row.platformCode === 'SAGE'
                  ? '产品下架中。由于平台限制，请稍后查看下架状态'
                  : '下架成功！'
              this.$message.success(msg)
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.handleClose()
            this.loading = false
          }
        })
      },
    },
  }
</script>
