<template>
  <el-dialog
    :title="$t(title)"
    :visible.sync="dialogFormVisible"
    width="850px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="130px"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <!-- <el-form-item label="联系人" prop="linkmanId"> -->
            <el-form-item
              :label="$t('cusManage.CustomerName')"
              prop="linkmanId"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: this.$t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.linkmanId"
                :placeholder="$t('placeholder.plsSel')"
                @change="linkManChange"
              >
                <el-option
                  v-for="item in linkManSelect"
                  :key="item.linkmanId"
                  :label="item.linkman"
                  :value="item.linkmanId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="联系方式" prop="linkType"> -->
            <el-form-item
              :label="$t('cusManage.ContactDetails')"
              prop="linkType"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: this.$t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.linkType"
                :placeholder="$t('placeholder.plsSel')"
              >
                <el-option
                  v-for="item in linkTypeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <!-- <el-form-item label="跟进目的" prop="followGoal"> -->
            <el-form-item
              :label="$t('cusManage.FollowUpPurpose')"
              prop="followGoal"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: this.$t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.followGoal"
                :placeholder="$t('placeholder.plsSel')"
              >
                <el-option
                  v-for="item in followGoalSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="跟进时间" prop="followTime"> -->
            <el-form-item
              :label="$t('cusManage.FollowUpTime')"
              prop="followTime"
            >
              <el-date-picker
                v-model="form.followTime"
                type="date"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                :placeholder="$t('placeholder.selDate')"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col>
            <!-- <el-form-item label="备注" prop="remark"> -->
            <el-form-item :label="$t('cusManage.Remark')" prop="remark">
              <el-input
                type="textarea"
                :placeholder="$t('placeholder.rc')"
                v-model="form.remark"
                :autosize="{ minRows: 2 }"
                maxlength="500"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" :loading="loading" @click="save">
          {{ $t('cusManage.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('cusManage.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    customerFollerRecord,
    linkmanList,
  } from '@/api/client-supplier/client-manage'
  import { mapGetters } from 'vuex'
  import nameMixin from '@/utils/name-mixin'

  export default {
    name: 'ClientManagefollowModel',
    mixins: [nameMixin],
    data() {
      return {
        loading: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() //选当前时间之前的时间
          },
        },
        // title: '新增跟进记录',
        title: 'cusManage.AddFollowUpRecord',
        form: {
          linkmanId: '',
          linkType: '',
          direction: '',
          followGoal: null,
          orderType: '',
          infoId: '',
        },
        rules: {},
        dialogFormVisible: false,
        directionOption: [
          {
            //跟进下拉
            value: '进',
            label: '进',
            labelEn: 'In',
          },
          {
            value: '出',
            label: '出',
            labelEn: 'Out',
          },
        ],
        linkTypeSelect: [
          {
            //联系方式
            value: 'PHONE CALL',
            label: 'PHONE CALL',
          },
          {
            value: 'FXA',
            label: 'FXA',
          },
          {
            value: 'EMAIL',
            label: 'EMAIL',
          },
          {
            value: 'MAIL',
            label: 'MAIL',
          },
          {
            value: 'ONLINE CHAT',
            label: 'ONLINE CHAT',
          },
          {
            value: 'FACE TO FACE',
            label: 'FACE TO FACE',
          },
          {
            value: 'OTHERS',
            label: 'OTHERS',
          },
        ],
        followGoalSelect: [
          //跟进目的
          {
            value: 'Caling visit',
            label: 'Caling visit',
          },
          {
            value: 'Quote following',
            label: 'Quote following',
          },
          {
            value: 'Shipping',
            label: 'Shipping',
          },
          {
            value: 'Accounting & Payment',
            label: 'Accounting & Payment',
          },
          {
            value: 'Order following',
            label: 'Order following',
          },

          {
            value: 'Make Friendly Contacts',
            label: 'Make Friendly Contacts',
          },
        ],
        customerSourceOptions: [
          //客户来源
          {
            value: 'ESP',
            label: 'ESP',
          },
          {
            value: 'SAGE',
            label: 'SAGE',
          },
        ],
        linkManSelect: [], //下拉
        infoId: '', //客户id
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    methods: {
      //新增
      showAddEdit(infoId) {
        this.form.infoId = infoId
        this.getLinkmanList()
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //联系人
      async getLinkmanList() {
        let response = await linkmanList({ infoId: this.form.infoId })
        if (response.code === '000000') {
          this.linkManSelect = response.data
        }
      },

      //联系人选择
      linkManChange(val) {
        let linkman = this.linkManSelect.filter((item) => {
          if (item.linkmanId === val) {
            return item.linkman
          }
        })
        this.form.linkman = linkman[0].linkman
      },

      //客户新增
      async save() {
        let form = this.utils.deepCopy(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // if (this.title === '新增跟进记录') {
            if (this.title === 'cusManage.AddFollowUpRecord') {
              this.loading = true
              let response = await customerFollerRecord(form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  this.$t('reqmsg.$4'),
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    height: 40vh;
    overflow: hidden;
    overflow-y: auto;
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
