<template>
  <el-dialog
    title="新增规格值"
    :visible.sync="newVisible"
    width="760px"
    @close="closeNew"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-form
      ref="newForm"
      :model="newForm"
      :rules="newFormRules"
      label-width="130px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="中文规格值名称" prop="nameCn">
            <el-input
              v-model.trim="newForm.nameCn"
              placeholder="请输入内容"
              v-no-special-chars="invalidChars"
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="英文规格值名称" prop="nameEn">
            <el-input
              v-model="newForm.nameEn"
              maxlength="50"
              placeholder="请输入内容"
              v-no-special-chars="invalidChars"
              @input="() => tagValueblur(newForm)"
              v-filterSpace
              v-trim
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary"  @click="saveNew">
          确 定
        </el-button>
        <el-button @click="closeNew">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'specValueNewModel',
    props: {
      invalidChars: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        newForm: {
          nameCn: '',
          nameEn: '',
        },
        newFormRules: {
          nameCn: [{ required: true, trigger: 'blur', message: '请输入' }],
          nameEn: [{ required: true, trigger: 'blur', message: '请输入' }],
        },
        newVisible:false,
       
      }
    },

    methods: {
      //规格值首字母大写
      tagValueblur(row) {
        let val = row.nameEn
        if (!val) return
        let reg = new RegExp('^[\u4E00-\u9FA5]*$')
        if (reg.test(val)) {
          // return (this.tagValue = '')
          row.nameEn = ''
          return 
        }
        if (!reg.test(val)) {
          row.nameEn = val
            ?.replace(/[\u4E00-\u9FA5]/g, '')
            ?.replace(/[\u3000-\u303F\uFF00-\uFFEF\u2018-\u201D\u2026]/g, '')
            ?.replace(/\b\w+\b/g, (word) => {
              return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
          console.log(row.nameEn)
        }
      },
      // 保存规格值
      saveNew() {
        this.$refs['newForm'].validate((valid) => {
          if (valid) {
          
            this.$emit('new-spec-value', {
              ...this.newForm,
            },() => {
              this.closeNew()
            })
          }
        })
      },

      closeNew() {
        this.newVisible = false
        this.newForm.nameCn = ''
        this.newForm.nameEn = ''
        this.$refs['newForm'].resetFields()
      },
    },
  }
</script>
