<!--出库单详情-->
<template>
  <div class="table-container">
    <el-row v-if="$route.path !== '/audit-process/audit-manage/index'">
      <el-page-header @back="goBack" v-if="!$route.query.noReturn">
        <template slot="content">
          <el-row type="flex" align="middle">
            <p class="title">
              <span class="header mr15">
                出库单号：
                {{ detail.warehouseOutNumber }}
              </span>
            </p>
          </el-row>
        </template>
      </el-page-header>
    </el-row>

    <template>
      <el-tabs v-model="active">
        <el-tab-pane label="出库详情" name="1">
          <h3 class="mb20">基本信息</h3>
          <BaseDetail :detail="detail"></BaseDetail>

          <div class="detail-table mt20">
            <h3 class="mt30 mb20">出库产品</h3>
            <BaseProDetail
              v-if="showPro"
              :detailId="detailId"
              :detail="detail"
              class="mt20"
            ></BaseProDetail>
          </div>
          <!-- 出库库位弹窗 -->
          <el-dialog
            :visible.sync="showOutLocDialog"
            title="出库库位"
            width="480px"
            append-to-body
            @close="onClose"
            modal-append-to-body
          >
            <div class="loc-content">
              <el-row v-for="(loc, index) in locs" :key="index" class="mb20">
                <el-col :span="12">
                  <span class="mr15">库位号:</span>
                  <span>{{ loc.locationNumber }}</span>
                </el-col>
                <el-col :span="12">
                  <span class="mr15">出库数量:</span>
                  <span>
                    <el-input
                      v-model="loc.num"
                      disabled
                      size="mini"
                      style="width: 100px"
                    ></el-input>
                  </span>
                </el-col>
              </el-row>
            </div>
          </el-dialog>

          <div class="detail-files mt20">
            <h3 class="mt30 mb20">附件</h3>
            <div v-if="fileList.length">
              <div class="flie-list" v-for="(item, i) in fileList" :key="i">
                <i class="el-icon-document"></i>
                <a class="page-link ml5">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top-start"
                  >
                    <span class="ell">
                      <router-link
                        :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                        target="_blank"
                      >
                        {{ item.name }}
                      </router-link>
                    </span>
                  </el-tooltip>
                </a>
                <span class="size ml10">
                  {{ utils.calculateFileSize(item.size) }}
                </span>
                <span class="ml10">{{ item.time }}</span>
              </div>
            </div>
            <div v-else>-</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关联调整单" name="2">
          <div class="costTable">
            <el-table
              :data="tableData"
              border
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
            >
              <el-table-column
                label="调整单号"
                prop="costAdjustNumber"
                width="150"
              ></el-table-column>
              <el-table-column
                label="产品编码"
                prop="sku"
                width="120"
              ></el-table-column>
              <el-table-column
                label="产品名称"
                prop="productCn"
              ></el-table-column>
              <el-table-column
                label="英文规格"
                prop="specification"
                show-overflow-tooltip
                width="180"
              >
                <template slot-scope="{ row }">
                  {{ $hasChineseChar(row.specification) }}
                </template>
              </el-table-column>
              <el-table-column
                label="中文规格"
                prop="specificationCn"
                show-overflow-tooltip
                width="180"
              >
                <template slot-scope="{ row }">
                  {{ row.specificationCn || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="数量"
                prop="warehouseInNum"
                width="120"
              ></el-table-column>
              <el-table-column label="调整金额" prop="adjustPrice" width="120">
                <template slot-scope="scope">
                  {{
                    scope.row.adjustPrice && scope.row.adjustPrice.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="调整前单价"
                prop="priceExcludingTax"
                width="120"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.priceExcludingTax &&
                    scope.row.priceExcludingTax.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="调整前总成本"
                prop="totalPriceExcluding"
                width="120"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.totalPriceExcluding &&
                    scope.row.totalPriceExcluding.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="调整后单价"
                prop="priceExcludingTaxed"
                width="120"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.priceExcludingTaxed &&
                    scope.row.priceExcludingTaxed.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="调整后总成本"
                prop="totalPriceExcluded"
                width="120"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.totalPriceExcluded &&
                    scope.row.totalPriceExcluded.toFixed(3)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建人"
                prop="creatorName"
                width="120"
              ></el-table-column>
              <el-table-column
                label="创建时间"
                prop="createTime"
              ></el-table-column>
            </el-table>
          </div>

          <el-pagination
            :current-page="page.pageNo"
            :page-size="page.pageLe"
            :total="total"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            @current-change="pageChange"
            @size-change="sizeChange"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
  import { InboundSheet, OutboundSheetInteractor } from '@/core'
  import BaseStar from '@/components/base-star/base-star'
  import Loc from './components/loc'
  import globalBackButtonMixin from '@/utils/global-back-button'
  import BaseDetail from './components/base-detail'
  import BaseProDetail from './components/base-product-detail'

  let self
  export default {
    name: 'outbound-sheet-detail',
    props: {
      detailId: {
        type: String,
        default: '',
      },
    },
    data() {
      self = this
      return {
        active: '1',
        showOutLocDialog: false,
        warehouses: [],
        detail: {},
        locs: [],
        fileList: [],
        showPro: false, //产品课表控制
        tableData: [],
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
      }
    },

    components: {
      Loc,
      BaseStar,
      BaseDetail,
      BaseProDetail,
    },
    computed: {
      warehouseInId() {
        return this.$route.query.warehouseInId
      },
      //销售出库
      typeIsXS() {
        return this.detail.type === 3
      },
      //退货出库
      typeIsTh() {
        return this.detail.type === 4
      },
      //其他出库
      typeIsQt() {
        return this.detail.type === 5
      },
      typeIsConfirm() {
        return this.detail.createWay !== 0
      },
      thTitle() {
        return '采购数量'
      },
    },
    mixins: [globalBackButtonMixin],
    created() {
      this.getDetail()
      this.getWarehouseList()
    },
    methods: {
      async getDetail() {
        const warehouseOutId =
            this.detailId || this.$route.query.warehouseOutId,
          method = this.detailId ? 'getDetailNoAuth' : 'getDetail'
        let res = await OutboundSheetInteractor[method](warehouseOutId)
        this.showPro = true
        if (res.code == '000000') {
          this.detail = res.data

          let files = []
          if (res.data.fileUrls) {
            files = files.concat(JSON.parse(res.data.fileUrls))
          }
          if (res.data.originFileUrls) {
            files = files.concat(JSON.parse(res.data.originFileUrls))
          }
          this.fileList = files

          // 获取关联调整单
          this.getCostAdjust(this.detail.warehouseOutNumber)
          // 将warehouseId传递给父组件
          this.$emit('warehouseId', res.data?.warehouseId)
        }
      },

      // 获取关联调整单
      getCostAdjust(warehouseNumber) {
        const params = {
          warehouseNumber: warehouseNumber,
          ...this.page,
        }
        InboundSheet.costAdjustWarehouseIn(params).then((res) => {
          this.tableData = res?.data?.data || []
          this.total = res?.data?.total || 0
        })
      },

      pageChange(val) {
        this.page.pageNo = val
        this.getCostAdjust(this.detail.warehouseOutNumber)
      },

      sizeChange(val) {
        this.page.pageNo = 1
        this.page.pageLe = val
        this.getCostAdjust(this.detail.warehouseOutNumber)
      },

      async getWarehouseList() {
        let res = await OutboundSheetInteractor.findWarehouseList(0)
        this.warehouses = res.data || []
      },
      goBack() {
        this.$router.push('/stock-center/outbound-sheet/outbound-sheet')
      },
      getRemark(remark) {
        if (!remark) {
          return '--'
        } else {
          return remark
        }
        // if (this.typeIsQt || this.typeIsTh) {
        //   if (!remark) {
        //     return '--'
        //   } else {
        //     return remark
        //   }
        // } else {
        //   if (remark) {
        //     return remark
        //   }
        // }
      },
      //销售出库 5,6,7,9
      // 退货出库 6,8
      // 其他出库 5.6.8
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }
          var filterIndexs = [5, 6]

          //其他出库
          if (this.typeIsQt) {
            filterIndexs = [5, 6, 8]
          }
          //销售出库
          if (this.detail.createWay !== 0) {
            filterIndexs = [5, 6, 7, 9]
          }
          //退货出库
          if (this.typeIsTh) {
            filterIndexs = [5, 6, 8]
          }
          if (!filterIndexs.includes(index)) {
            sums[index] = ''
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                //退货出库-出库成本小数点问题
                if (index === 8) {
                  return this.toFixed3(prev + curr)
                } else {
                  return prev + curr
                }
              } else {
                return prev
              }
            }, 0)

            if (index == 9) {
              sums[index] = this.toFixed3(sums[index])

              if (this.detail.flag == 0 && !this.detailId) {
                sums[index] = '--'
              }
            }
            if (index == 5) {
              if (!sums[index]) {
                sums[index] = '--'
              }
            }
          }
        })

        return sums
      },
      onClose() {
        this.locParams.show = false
      },
      addLoc(row) {
        this.locs = row.warehouseOutProductLocationVOList || []
        if (this.locs.length === 0) {
          return this.$message.warning('没有出库库位数据')
        }
        this.showOutLocDialog = true
      },
    },

    filters: {
      warehouseName(val) {
        const warehouse = self.warehouses.find(
          (item) => item.warehouseId === val
        )
        return warehouse ? warehouse.name : '--'
      },
      typeFilter(val) {
        const types = [
          //入库方式
          {
            code: 3,
            name: '销售出库',
          },
          {
            code: 4,
            name: '退货出库',
          },
          {
            code: 5,
            name: '其他出库',
          },
        ]
        const targetType = types.find((type) => type.code === val)
        return targetType ? targetType.name : '--'
      },
    },
  }
</script>

<style scoped lang="scss">
  .table-container {
    padding: 0 20px 20px !important;
    & .title {
      display: flex;
      align-items: center;
    }
    & .detail-fields {
      .el-row {
        & .label {
          color: #282c34;
          width: 100px;
          text-align: right;
          display: inline-block;
          margin-right: 15px;
        }
        & .content {
          color: #000016;
        }
      }
    }
  }
  .detail-label {
    font-size: 14px;
    color: #606266;
  }
  .detail-content {
    font-size: 16px !important;
    margin-top: 10px;
    font-weight: normal;
  }
  .header {
    font-size: 18px;
    color: #606266;
    //font-family: 'PingFang Bold';
  }
  .fs14 {
    font-size: 14px;
    font-family: 'PingFang Bold';
    color: #282c34;
  }
  .mr15 {
    margin-right: 15px;
  }

  .pointer {
    cursor: pointer;
  }
  .loc-content {
    max-height: 300px;
    overflow: auto;
  }
  .costTable {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }
</style>
