<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="组织名称" prop="name">
              <el-input
                v-model.trim="form.name"
                placeholder="请输入最少2个字符"
                minlength="2"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属地区" prop="area">
              <el-input v-model.trim="form.area" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {
    departmentInsertPO,
    departmentUpdatePO,
  } from '@/api/organization-manage'
  export default {
    name: 'AddBranchModel',
    data() {
      return {
        loading: false,
        title: '添加组织',
        form: {
          name: '',
          area: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入组织名称' },
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                if (value.length < 2) {
                  callback(new Error('组织名称至少两个字符'))
                } else {
                  callback()
                }
              },
            },
          ],
          area: [
            { required: true, trigger: 'blur', message: '请输入所属地区' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      //新增、编辑
      showAddEdit(data, str) {
        if (str === 'edit') {
          this.title = '编辑组织'
          this.form.parentDeptId = data.parentDeptId
          this.form.deptId = data.deptId
          this.form.name = data.name
          this.form.area = data.area
        } else {
          this.title = '添加组织'
          this.form.parentDeptId = data.deptId
          this.form.area = data.area
          this.form.name = ''
        }
        this.dialogFormVisible = true
      },

      //关闭
      close() {
        this.title = '添加组织'
        this.$refs['form'].resetFields()
        //this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //保存处理
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = this.utils.deepCopy(this.form)
            this.loading = true
            if (this.title === '添加组织') {
              let response = await departmentInsertPO(form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '新增组织成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            } else {
              let response = await departmentUpdatePO(form)
              this.loading = false
              if (response.code === '000000') {
                this.$baseMessage(
                  '更新组织成功',
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .text-hidden {
    width: 150px;
  }
</style>
