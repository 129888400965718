<template>
  <el-row>
    <div class="product-info">
      <ProductInfo is-audit-detail :infoParames="productInfo"/>
      <!-- 2.2.0新增详情，hadPlatFormData为true并且detailInfo有值时展示 -->
      <PlatformProductDetails
        v-if="productInfo.hadPlatFormData && detailInfo"
        type="2"
        :detailInfo="detailInfo"
        markOptions
      />
    </div>
  </el-row>
</template>

<script>
 import ProductInfo from '@/views/product/ProductView/components/ProductInfo'
 import PlatformProductDetails from '@/views/product/platformProduct/components/PlatformProductDetails'

  import { mapGetters } from 'vuex'
  import {
    ProductListInteractor,
    auditInteractor,
    PlatformProductListInteractor,
  } from '@/core'

  export default {
    name: 'ProductDetail',
    components: {
      ProductInfo,
      PlatformProductDetails,

    },
    props: {
      detailId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        productInfo: {},
        detailInfo: {}, // 传递给PlatformProductDetails组件的数据
      }
    },

    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),


    },
    watch: {
      settings() {
        this.tableKey++
      },
      detailId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.getProductDetail(value)
        },
      },
    },

    methods: {

      //产品详情
      async getProductDetail(value) {
        let response = await ProductListInteractor.getDetailForShowApi({
          productId: value,
        })
        if (response.code === '000000') {
          this.productInfo = response.data
          if (response.data.hadPlatFormData) {
            this.getPlatformProductDetailsInfo(response.data.productId) // 获取getPlatformProductDetails组件所需详情
          }

        }
      },


      // 获取PlatformProductDetails组件所需详情
      async getPlatformProductDetailsInfo(erpProductId) {
        try {
          const { code, data } =
            await PlatformProductListInteractor.platformProductInfoApi({
              erpProductId,
            })
          if (code === '000000') {
            this.detailInfo = data
          }
        } catch (err) {
          console.log(err)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
.product-info {
  margin-bottom: 50px;
  padding:10px 25px  25px;
}
</style>
