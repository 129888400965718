<template>
  <el-dialog
    title="付款单详情"
    :visible.sync="dialogReceiptDetailVisible"
    width="1305px"
    @close="close"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="7">
          <p>付款单号</p>
          <span>{{ form.number }}</span>
          <span>
            <el-tag
              type="warning"
              plain
              v-if="form.state == 1"
              size="small"
              class="ml10"
            >
              待处理
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 3" size="small">
              已驳回
            </el-tag>
            <el-tag type="success" plain v-if="form.state == 2" size="small">
              已完成
            </el-tag>
          </span>
        </el-col>
        <el-col :span="5">
          <p>付款类型</p>
          <span v-if="form.payType == 1">采购付款</span>
          <span v-else-if="form.payType == 2">销售退款</span>
          <span v-else-if="form.payType == 3">网拍</span>
          <span v-else-if="form.payType == 4">信用额度还款</span>
        </el-col>
        <el-col :span="5">
          <p>采购开发</p>
          <span>{{ form.buyerName }}</span>
        </el-col>
        <el-col :span="7">
          <p>付款总金额</p>
          <span class="green">
            {{ moneySign }}{{ form.amountApplication }} &nbsp; &nbsp;
          </span>
          <span style="font-size: 12px; color: #666666">
            汇率：{{ form.exchangeRate }} &nbsp;&nbsp;RMB：{{
              toFixed3(form.amountApplication * form.exchangeRate)
            }}
          </span>
        </el-col>
      </el-row>
      <!-- 底部表格 -->
      <el-row class="mt30">
        <!-- 销售退款 -->
        <el-table
          v-show="form.payType === 2"
          border
          :data="topTableData"
          :default-expand-all="true"
          style="width: 100%"
          class="bottom-table"
        >
          <el-table-column type="expand" width="1">
            <template slot-scope="scope">
              <el-table border :data="scope.row.financeOrderVOList">
                <el-table-column
                  align="center"
                  type="index"
                  width="55"
                  label="#"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.$index > 9">0{{ scope.$index + 1 }}</span>
                    <span v-else>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="140"
                  prop="orderCode"
                  align="center"
                  label="销售订单号"
                >
                  <template slot-scope="scope">
                    <span class="blue-text">
                      <router-link
                        target="_blank"
                        :to="`/order/orderList/orderDetail?orderCode=${
                          scope.row.orderCode
                        }&noReturn=${true}`"
                      >
                        {{ scope.row.orderCode }}
                      </router-link>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="businessName"
                  align="center"
                  label="客户代表"
                ></el-table-column>
                <el-table-column
                  prop="area"
                  align="center"
                  label="地区"
                ></el-table-column>
                <el-table-column
                  width="120"
                  prop="customerName"
                  align="center"
                  label="客户姓名"
                ></el-table-column>
                <el-table-column
                  width="200"
                  prop="customerName"
                  align="center"
                  label="公司名称"
                ></el-table-column>
                <el-table-column
                  width="100"
                  prop="orderPrice"
                  align="center"
                  label="信用额度"
                >
                  <template slot-scope="scope">
                    {{ scope.row.orderPrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="shoukuanPrice"
                  align="center"
                  label="剩余额度"
                >
                  <template slot-scope="scope">
                    {{ scope.row.shoukuanPrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="totlePrice"
                  align="center"
                  label="订单总金额"
                >
                  <template slot-scope="scope" v-if="scope.row.totlePrice">
                    <span v-if="scope.row.currency === '人民币'">￥</span>
                    <span v-if="scope.row.currency === '美元'">$</span>
                    {{ scope.row.totlePrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="amountReceived"
                  align="center"
                  label="已收金额"
                >
                  <template slot-scope="scope" v-if="scope.row.amountReceived">
                    <span v-if="scope.row.currency === '人民币'">￥</span>
                    <span v-if="scope.row.currency === '美元'">$</span>
                    {{ scope.row.amountReceived || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="outstandingAmount"
                  align="center"
                  label="未收金额"
                >
                  <template
                    slot-scope="scope"
                    v-if="scope.row.outstandingAmount"
                  >
                    <span v-if="scope.row.currency === '人民币'">￥</span>
                    <span v-if="scope.row.currency === '美元'">$</span>
                    {{ scope.row.outstandingAmount || '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column align="center" type="index" width="55" label="#" />
          <el-table-column
            width="140"
            prop="shoukuanStyle"
            align="center"
            label="采购订单号"
          >
            <template slot-scope="scope">
              <span class="blue-text">
                <router-link
                  target="_blank"
                  :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                    scope.row.purchaseOrderId
                  }&noReturn=${true}`"
                >
                  {{ scope.row.purchaseOrderNumber }}
                </router-link>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="supplierName"
            align="center"
            width="200"
            label="供应商名称"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column
            prop="accountName"
            align="center"
            label="收款名称"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="bankName"
            align="center"
            label="开户行"
          ></el-table-column> -->
          <el-table-column
            prop="bankAccount"
            align="center"
            show-overflow-tooltip
            label="账户信息"
          ></el-table-column>
          <!-- <el-table-column
            width="200"
            prop="dutyAccount"
            align="center"
            label="税号"
          ></el-table-column> -->
          <el-table-column
            width="104"
            prop="amountApplication"
            align="center"
            label="付款金额"
          >
            <template slot-scope="scope">
              <span class="f_w_6 green" v-if="scope.row.amountApplication">
                {{ moneySign }}{{ scope.row.amountApplication }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 采购付款 -->
        <el-table
          v-show="form.payType === 1"
          border
          :data="topTableData"
          :default-expand-all="true"
          style="width: 100%"
          class="bottom-table"
        >
          <el-table-column type="expand" width="1">
            <template slot-scope="scope">
              <el-table border :data="scope.row.financeOrderVOList">
                <el-table-column
                  align="center"
                  type="index"
                  width="55"
                  label="#"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.$index > 9">0{{ scope.$index + 1 }}</span>
                    <span v-else>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="140"
                  prop="orderCode"
                  align="center"
                  label="销售订单号"
                >
                  <template slot-scope="scope">
                    <span class="blue-text">
                      <router-link
                        target="_blank"
                        :to="`/order/orderList/orderDetail?orderCode=${
                          scope.row.orderCode
                        }&noReturn=${true}`"
                      >
                        {{ scope.row.orderCode }}
                      </router-link>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="businessName"
                  align="center"
                  label="客户代表"
                ></el-table-column>
                <el-table-column
                  prop="area"
                  align="center"
                  label="地区"
                ></el-table-column>
                <el-table-column
                  width="120"
                  prop="customerName"
                  align="center"
                  label="客户姓名"
                ></el-table-column>
                <el-table-column
                  width="200"
                  prop="customerName"
                  align="center"
                  label="公司名称"
                ></el-table-column>
                <el-table-column
                  width="100"
                  prop="orderPrice"
                  align="center"
                  label="信用额度"
                >
                  <template slot-scope="scope">
                    {{ scope.row.orderPrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="shoukuanPrice"
                  align="center"
                  label="剩余额度"
                >
                  <template slot-scope="scope">
                    {{ scope.row.shoukuanPrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="totlePrice"
                  align="center"
                  label="订单总金额"
                >
                  <template slot-scope="scope" v-if="scope.row.totlePrice">
                    <span v-if="scope.row.currency === '人民币'">￥</span>
                    <span v-if="scope.row.currency === '美元'">$</span>
                    {{ scope.row.totlePrice || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="amountReceived"
                  align="center"
                  label="已收金额"
                >
                  <template slot-scope="scope" v-if="scope.row.amountReceived">
                    <span v-if="scope.row.currency === '人民币'">￥</span>
                    <span v-if="scope.row.currency === '美元'">$</span>
                    {{ scope.row.amountReceived || '--' }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="outstandingAmount"
                  align="center"
                  label="未收金额"
                >
                  <template
                    slot-scope="scope"
                    v-if="scope.row.outstandingAmount"
                  >
                    <span v-if="scope.row.currency === '人民币'">￥</span>
                    <span v-if="scope.row.currency === '美元'">$</span>
                    {{ scope.row.outstandingAmount || '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column align="center" type="index" width="55" label="#" />
          <el-table-column
            width="140"
            prop="shoukuanStyle"
            align="center"
            label="采购订单号"
          >
            <template slot-scope="scope">
              <span class="blue-text">
                <router-link
                  target="_blank"
                  :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
                    scope.row.purchaseOrderId
                  }&noReturn=${true}`"
                >
                  {{ scope.row.purchaseOrderNumber }}
                </router-link>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="supplierName"
            align="center"
            width="200"
            label="供应商名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="accountName"
            align="center"
            label="收款名称"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="bankName"
            align="center"
            label="开户行"
          ></el-table-column>
          <el-table-column
            prop="bankAccount"
            align="center"
            show-overflow-tooltip
            label="银行账号"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="dutyAccount"
            align="center"
            label="税号"
          ></el-table-column>
          <el-table-column
            width="104"
            prop="amountApplication"
            align="center"
            label="付款金额"
          >
            <template slot-scope="scope">
              <span class="f_w_6 green" v-if="scope.row.amountApplication">
                {{ moneySign }}{{ scope.row.amountApplication }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row></el-row>
      <el-row class="mt20 remark">
        <el-col :span="8">
          <p>备注</p>
          <tooltip-over
            class="c_pointer"
            v-if="form.remark"
            :content="form.remark || '--'"
            refName="tooltipOver5"
          ></tooltip-over>
          <span style="color: #606266" v-else>无</span>
        </el-col>
        <el-col :span="8">
          <p>创建人</p>
          <span>{{ form.creator }}</span>
        </el-col>
        <el-col :span="8">
          <p>创建时间</p>
          <span>{{ form.createTime }}</span>
        </el-col>
      </el-row>
      <el-row class="mt10 remark">
        <el-col :span="24">
          <p>附件</p>
          <div v-if="fileList.length">
            <div class="flie-list" v-for="(item, i) in fileList" :key="i">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="ell">
                    <router-link
                      :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <span style="color: #606266" v-else>无</span>
        </el-col>
      </el-row>
      <el-divider />
      <el-row class="mt20 remark">
        <el-col :span="6">
          <p>审核单号</p>
          <span>
            {{ form.auditNumber }}
          </span>
          <p class="mt10">审核通过时间：{{ utils.cutDate(form.createTime) }}</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="mt30 text-center">
            <el-button @click="close">关 闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import {
  getDetailForShow
} from '@/api/finance/finance-payment'
import tooltipOver from '@/components/base-tooltip'
export default {
  name: 'ReceiptDetailModel',
  components: { tooltipOver },
  data() {
    return {
      dialogReceiptDetailVisible: false, //付款单详情弹窗
      amendRow: '',
      tagStatus: 1,
      form: {},
      money: 0,
      topTableData: [],
      moneySign: '', //付款单货币符号
      //moneySign2: '', //销售单货币符号
      fileList: [],
    }
  },
  methods: {
    async showReceiptDetail() {
      this.dialogReceiptDetailVisible = true
      let response = await getDetailForShow({ businessId: this.amendRow })
      this.form = response.data
      this.topTableData = response.data.payOrderChildShowVOList
      this.moneySign = this.form.currency === '美元' ? '$' : '￥'
      this.fileList = response.data.fileAnnex
        ? JSON.parse(response.data.fileAnnex)
        : []
    },

    close() {
      this.dialogReceiptDetailVisible = false
      this.money = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px 0px;
  .detail-header {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
  .remark {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }
}
::v-deep {
  .el-table__expanded-cell {
    padding: 0 0 0 0;
  }
  .el-table__expand-column .el-icon {
    visibility: hidden;
  }
  // .bottom-table
  //   > .el-table__header-wrapper
  //   > .el-table__header
  //   > .has-gutter
  //   > tr
  //   > th:nth-child(5) {
  //   background-color: #fff;
  //   border: none;
  // }
}
</style>
