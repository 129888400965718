<template>
  <el-dialog
    :title="$t('productDetail.BatchSetting')"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="el-dialog-div">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        :label-position="settings === 'zh' ? 'left' : 'top'"
      >
        <el-row :gutter="15">
          <el-col :span="12" v-for="(item, index) in specSelect" :key="index">
            <el-form-item
              :prop="'specsValue' + (index + 1)"
              :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsSel'),
                  trigger: 'change',
                },
              ]"
            >
              <template slot="label">
                {{ `${item.nameEn}${specShowSpilterator}${item.nameCn}` }}
              </template>
              <!-- 请选择(多选) 编辑规格-->
              <el-select
                multiple
                :placeholder="$t('placeholder.M2006')"
                clearable
                collapse-tags
                v-model="form['specsValue' + (index + 1)]"
                value-key="key"
              >
                <el-option
                  v-for="(it, i) of item.value"
                  :key="i"
                  :label="`${it.nameEn}${specShowSpilterator}${it.nameCn}`"
                  :value="it"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 包装方式/英文 -->
            <el-form-item
              :label="$t('productDetail.PackingMethodEnglish')"
              prop="packingMethodEn"
            >
              <!-- 请选择 -->
              <el-select
                v-model="form.packingMethodEn"
                :placeholder="$t('placeholder.plsSel')"
              >
                <el-option
                  v-for="(item, index) of proPackingData"
                  :key="index"
                  :label="item.nameEn"
                  :value="item.nameEn"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row></el-row>
        <el-row
          style="border-top: 1px solid #ededed"
          class="mt20 pt20"
          :gutter="20"
        >
          <el-row class="mb20">
            <!-- 产品情况 -->
            <h4>{{ $t('productDetail.ProductSituation') }}</h4>
          </el-row>
          <el-col :span="8">
            <!-- 产品重量 -->
            <el-form-item
              :label="$t('productDetail.ProductWeight')"
              prop="productWeightKgs"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.productWeightKgs"
                :placeholder="$t('placeholder.plsInput')"
                type="number"
                :controls="false"
                @focus="handleFocus('productWeightKgs')"
                @blur="handleBlur('productWeightKgs')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
              />

              <p class="calcu-p">
                LBS:
                <!-- <span>{{ productWeightKgsLbs }}</span> -->
                {{ (form.productWeightKgs * 2.2046).toFixed(1) }}
              </p>
            </el-form-item>
          </el-col>
          <!-- 印刷区域尺寸-修改 -->
          <el-col :span="16">
            <!-- 印刷区域尺寸 -->
            <el-form-item
              :label="$t('productDetail.PrintingAreaSize')"
              prop="printSize"
            >
              <el-row :gutter="10" type="flex" justify="space-between">
                <el-col :span="11">
                  <!-- 请输入>0的数值 -->
                  <el-form-item prop="printLength">
                    <!-- 长度 -->
                    <el-input
                      v-model="form.printLength"
                      type="number"
                      :placeholder="$t('placeholder.M2008')"
                      @focus="handleFocus('printLength')"
                      @blur="handleBlur('printLength')"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    >
                      <template slot="append">
                        <el-select v-model="form.printUnit" style="width: 70px">
                          <el-option label="IN" value="IN"></el-option>
                          <el-option label="FT" value="FT"></el-option>
                          <el-option label="CM" value="CM"></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </el-form-item>
                  <p class="t_a_r calcu-p">
                    <span>
                      {{ getProSizeUnit(form, 'Length', form.printUnit, 2) }}
                    </span>
                    {{ getProSizeUnitText(form.printUnit) }}
                  </p>
                </el-col>
                <el-col :span="1">x</el-col>
                <el-col :span="11">
                  <!-- 请输入>0的数值 -->
                  <el-form-item prop="printWidth">
                    <!-- 宽度 -->
                    <el-input
                      v-model="form.printWidth"
                      type="number"
                      :placeholder="$t('placeholder.M2009')"
                      @focus="handleFocus('printWidth')"
                      @blur="handleBlur('printWidth')"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    >
                      <template slot="append">
                        <el-select v-model="form.printUnit" style="width: 70px">
                          <el-option label="IN" value="IN"></el-option>
                          <el-option label="FT" value="FT"></el-option>
                          <el-option label="CM" value="CM"></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </el-form-item>
                  <p class="t_a_r calcu-p">
                    <span>
                      {{ getProSizeUnit(form, 'Width', form.printUnit, 2) }}
                    </span>
                    {{ getProSizeUnitText(form.printUnit) }}
                  </p>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- 产品尺寸 -->
          <el-form-item :label="$t('productDetail.ProductSize')">
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="7">
                <!-- 请输入>0的数值 -->
                <el-form-item prop="productLengthCm">
                  <!-- 长度 -->
                  <el-input
                    v-model="form.productLengthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2008')"
                    @focus="handleFocus('productLengthCm')"
                    @blur="handleBlur('productLengthCm')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  >
                    <template slot="append">
                      <el-select v-model="form.sizeUnit" style="width: 70px">
                        <el-option label="IN" value="IN"></el-option>
                        <el-option label="FT" value="FT"></el-option>
                        <el-option label="CM" value="CM"></el-option>
                      </el-select>
                    </template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>
                    {{ getProSizeUnit(form, 'Length', form.sizeUnit, 1) }}
                  </span>
                  {{ getProSizeUnitText(form.sizeUnit) }}
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 请输入>0的数值 -->
                <el-form-item prop="productWidthCm">
                  <!-- 宽度 -->
                  <el-input
                    v-model="form.productWidthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2009')"
                    @focus="handleFocus('productWidthCm')"
                    @blur="handleBlur('productWidthCm')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  >
                    <template slot="append">
                      <el-select v-model="form.sizeUnit" style="width: 70px">
                        <el-option label="IN" value="IN"></el-option>
                        <el-option label="FT" value="FT"></el-option>
                        <el-option label="CM" value="CM"></el-option>
                      </el-select>
                    </template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>
                    {{ getProSizeUnit(form, 'Width', form.sizeUnit, 1) }}
                  </span>
                  {{ getProSizeUnitText(form.sizeUnit) }}
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <el-form-item prop="productHeightCm">
                  <!-- 高度 -->
                  <el-input
                    v-model="form.productHeightCm"
                    type="number"
                    :placeholder="$t('placeholder.M2010')"
                    @focus="handleFocus('productHeightCm')"
                    @blur="handleBlur('productHeightCm')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  >
                    <template slot="append">
                      <el-select v-model="form.sizeUnit" style="width: 70px">
                        <el-option label="IN" value="IN"></el-option>
                        <el-option label="FT" value="FT"></el-option>
                        <el-option label="CM" value="CM"></el-option>
                      </el-select>
                    </template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>
                    {{ getProSizeUnit(form, 'Height', form.sizeUnit, 1) }}
                  </span>
                  {{ getProSizeUnitText(form.sizeUnit) }}
                </p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <el-row
          style="border-top: 1px solid #ededed"
          class="mt20 pt20"
          :gutter="20"
        >
          <el-row class="mb20">
            <!-- 装箱情况 -->
            <h4>{{ $t('productDetail.PackingSituation') }}</h4>
          </el-row>
          <el-col :span="12">
            <!-- 装箱数量 -->
            <el-form-item
              :label="$t('productDetail.PackingQuantity')"
              prop="numberPerBox"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.numberPerBox"
                type="number"
                :placeholder="$t('placeholder.plsInput')"
                @focus="handleFocus('numberPerBox')"
                @blur="checkSize"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 毛重 -->
            <el-form-item
              :label="$t('productDetail.GrossWeight')"
              prop="boxWeightKgs"
            >
              <!-- 请输入 -->
              <el-input
                v-model="form.boxWeightKgs"
                type="number"
                :placeholder="$t('placeholder.plsInput')"
                @focus="handleFocus('boxWeightKgs')"
                @blur="checkSize"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
              />
              <p class="calcu-p">
                LBS:
                <!-- <span>{{ boxWeightKgsLbs }}</span> -->
                {{ Math.round(form.boxWeightKgs * 2.2046) || 0 }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <template slot="label">
              <!-- 装箱尺寸 -->
              <i style="color: #ff4d4f">*</i>
              {{ $t('productDetail.PackingSize') }}
            </template>
            <el-row :gutter="10" type="flex" justify="space-between">
              <el-col :span="7">
                <!-- 请输入长度  请输入>0的数值 -->
                <el-form-item prop="boxLengthCm">
                  <!-- 长度 -->
                  <el-input
                    v-model="form.boxLengthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2008')"
                    @focus="handleFocus('boxLengthCm')"
                    @blur="handleBlur('boxLengthCm')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  >
                    <template slot="append">CM</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ getINSize(form.boxLengthCm) }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 请输入宽度 请输入>0的数值-->
                <el-form-item prop="boxWidthCm">
                  <!-- 宽度 -->
                  <el-input
                    v-model="form.boxWidthCm"
                    type="number"
                    :placeholder="$t('placeholder.M2009')"
                    @focus="handleFocus('boxWidthCm')"
                    @blur="handleBlur('boxWidthCm')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  >
                    <template slot="append">CM</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ getINSize(form.boxWidthCm) }}</span>
                  IN
                </p>
              </el-col>
              <el-col :span="1">x</el-col>
              <el-col :span="7">
                <!-- 请输入高度 请输入>0的数值-->
                <el-form-item prop="boxHeightCm">
                  <el-input
                    v-model="form.boxHeightCm"
                    type="number"
                    :placeholder="$t('placeholder.M2010')"
                    @focus="handleFocus('boxHeightCm')"
                    @blur="handleBlur('boxHeightCm')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  >
                    <template slot="append">CM</template>
                  </el-input>
                </el-form-item>
                <p class="t_a_r calcu-p">
                  <span>{{ getINSize(form.boxHeightCm) }}</span>
                  IN
                </p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="end">
          <!-- 体积 -->
          <p>
            {{ $t('productDetail.VolumeM3') }}：{{ boxM3 }}
            <span></span>
          </p>
          <p class="ml30">
            <!-- 体积 -->
            {{ $t('productDetail.VolumeFT3') }}：{{ boxFt3 }}
            <span></span>
          </p>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('productDetail.Cancel') }}</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import mixins from './util/mixins'
  export default {
    name: 'ProductSpecListModel',
    mixins: [mixins],
    data() {
      return {
        form: {
          packingMethodEn: '',
          productWeightKgs: '', // 产品重量
          numberPerBox: '', // 装箱数量
          boxWeightKgs: '', // 毛重

          // 印刷区域尺寸
          printLength: '',
          printWidth: '',
          printUnit: 'IN',
          // 产品尺寸
          productLengthCm: '',
          productWidthCm: '',
          productHeightCm: '',
          sizeUnit: 'IN',
          // 装箱尺寸
          boxLengthCm: '',
          boxWidthCm: '',
          boxHeightCm: '',


        },
        dialogFormVisible: false,
        specShowSpilterator:'-', // 规格显示分隔符
      }
    },
    props: {
      proPackingData: {
        type: Array,
        default: () => [],
      },
      specSelect: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      // 体积
      boxFt3() {
        let sb =
          (this.form.boxLengthCm *
            this.form.boxWidthCm *
            this.form.boxHeightCm || 0) *
          0.000001 *
          35.31467
        return parseFloat(sb.toFixed(6))
      },
      boxM3() {
        let sb =
          (this.form.boxLengthCm *
            this.form.boxWidthCm *
            this.form.boxHeightCm || 0) * 0.000001
        return parseFloat(sb.toFixed(6))
      },
      rules() {
        return {
          packingMethodEn: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          productWeightKgs: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          numberPerBox: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: this.$t('rules.M2014'),
              trigger: 'blur',
            },
          ],
          boxWeightKgs: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxLengthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxWidthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxHeightCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        }
      },
    },
    watch: {},
    created() {},
    methods: {
      getINSize(val) {
        return (val * 0.3937 || 0).toFixed(2)
      },
      // 产品换算单位
      getProSizeUnitText(val) {
        if (val === 'FT' || val === 'CM') {
          return 'IN'
        } else {
          return 'CM'
        }
      },

      //展示弹框
      productSpecListModel() {
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      // 根据填写的数据获取到对应单位的值
      setSizeIN() {
        return {
          ...this.form,
          boxHeightIn: this.getINSize(this.form.boxHeightCm),
          boxLengthIn: this.getINSize(this.form.boxLengthCm),
          boxWidthIn: this.getINSize(this.form.boxWidthCm),

          printLengthCovert: this.getProSizeUnit(
            this.form,
            'Length',
            this.form.printUnit,
            2
          ),
          printWidthCovert: this.getProSizeUnit(
            this.form,
            'Width',
            this.form.printUnit,
            2
          ),

          productHeightIn: this.getProSizeUnit(
            this.form,
            'Height',
            this.form.sizeUnit,
            1
          ),
          productLengthIn: this.getProSizeUnit(
            this.form,
            'Length',
            this.form.sizeUnit,
            1
          ),
          productWidthIn: this.getProSizeUnit(
            this.form,
            'Width',
            this.form.sizeUnit,
            1
          ),
        }
      },

      // 毛重需大于产品重量*装箱数量
      checkSize() {
        if (
          !this.form.boxWeightKgs ||
          !this.form.productWeightKgs ||
          !this.form.numberPerBox
        )
          return

        let s_1 =
          Number(this.form.boxWeightKgs) <
          Number(this.form.productWeightKgs) * Number(this.form.numberPerBox)
        if (s_1) {
          this.$set(this.form, 'boxWeightKgs', '')
          return this.$baseMessage(
            // '毛重值需大于产品重量*装箱数量',
            this.$t('rules.M2033'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      },
      // 关闭
      close() {
        this.dialogFormVisible = false
        this.$refs['form'].resetFields()
      },
      handleFocus(name) {
        if (this.form[name] == 0) {
          this.$set(this.form, name, '')
        }
      },
      handleBlur(name) {},
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$emit('specRow', this.setSizeIN())
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .calcu-p {
    font-size: 12px;
    color: #999;
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  .el-dialog-div {
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  ::v-deep {
    .el-dialog-div {
      .el-form {
        width: 95% !important;
        margin: 0 auto !important;
      }
    }
  }
</style>
