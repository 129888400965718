<template>
  <el-dialog
    :title="$t('productDetail.BatchSetting')"
    :visible.sync="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="850px"
  >
    <div class="mb20">
      {{
        lang === 'en'
          ? 'Batch set the following information for all products:'
          : '批量设置所有产品的以下信息：'
      }}
    </div>
    <el-form
      :model="batchSetData"
      :label-width="lang === 'en' ? '170px' : '110px'"
    >
      <div class="row">
        <!-- 计划装运日期 -->
        <el-form-item :label="$t('orderList.plannedShipmentDate')">
          <el-date-picker
            v-model="batchSetData.planShippingDate"
            type="date"
            :placeholder="$t('placeholder.selDate')"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <!-- 运输方式 -->
        <el-form-item :label="$t('orderDetails.ShippingMethod')">
          <el-select
            v-model="batchSetData.shippingMethodId"
            :placeholder="$t('placeholder.plsSel')"
            clearable
            @change="handleShippingMethodChange"
          >
            <el-option
              v-for="item in shippingMethodList"
              :key="item.id"
              :label="lang === 'en' ? item.transportWayEn : item.transportWayCn"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <div class="deliveryTime">
          <!-- 客户交期 -->
          <el-form-item :label="$t('orderList.customerDeliveryTime')">
            <el-date-picker
              v-model="batchSetData.customerDeliveryDate"
              type="date"
              :placeholder="$t('placeholder.selDate')"
              value-format="yyyy-MM-dd"
              @change="handleCustomerDeliveryDateChange"
            />
          </el-form-item>
          <el-checkbox
            v-model="batchSetData.firmFlag"
            :true-label="1"
            :false-label="0"
            :style="{ marginLeft: lang === 'en' ? '170px' : '110px' }"
            :disabled="!batchSetData.customerDeliveryDate"
          >
            Firm {{ lang === 'en' ? 'Date' : '交期' }}
          </el-checkbox>
        </div>
        <!-- 订单支持 -->
        <el-form-item :label="$t('AddOrder.OrderAssistant')">
          <UserSelect v-if="visible" @user-choose="handleFromBusinessChange" />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">
        {{ lang === 'en' ? 'Cancel' : '取消' }}
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        {{ lang === 'en' ? 'Confirm' : '确定' }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import UserSelect from '@/components/user-select'
  import { mapGetters } from 'vuex'
  export default {
    name: 'BatchSettingDialog',
    components: { UserSelect },
    model: { prop: 'visible' },
    props: {
      visible: { type: Boolean, default: false }, // 弹框显示隐藏
      shippingMethodList: { type: Array, default: () => [] }, // 运输方式下拉
    },
    data() {
      return {
        batchSetData: {
          planShippingDate: '', // 计划装运日期
          shippingMethodId: '', // 运输方式ID
          shippingMethod: '', // 运输方式中文名称
          shippingMethodType: '', // 运输方式类型
          customerDeliveryDate: '', // 客户交期
          firmFlag: 0, // 是否Firm交期 1-是 0-否
          fromBusinessId: '', // 订单支持ID
          fromBusinessName: '', // 订单支持中文名称
          fromBusinessNameEn: '', // 订单支持英文名称
        },
      }
    },
    computed: {
      ...mapGetters({ lang: 'settings/language' }),
    },
    methods: {
      handleSubmit() {
        this.$emit('batchSetting', { ...this.batchSetData })
        this.handleClose()
      },
      handleClose() {
        this.batchSetData = this.$options.data().batchSetData
        this.$emit('input', false)
      },
      // 运输方式change
      handleShippingMethodChange(id) {
        const {
          transportWayCn: shippingMethod,
          transportType: shippingMethodType,
        } = this.shippingMethodList.find((n) => n.id === id) || {}
        Object.assign(this.batchSetData, { shippingMethod, shippingMethodType })
      },
      // 客户交期change
      handleCustomerDeliveryDateChange(val) {
        if (!val) {
          this.batchSetData.firmFlag = 0 // 如果清空了客户交期，则Firm交期取消勾选
        }
      },
      // 订单支持change
      handleFromBusinessChange(user = {}) {
        const {
          userId: fromBusinessId,
          userName: fromBusinessName,
          englishName: fromBusinessNameEn,
        } = user
        Object.assign(this.batchSetData, {
          fromBusinessId,
          fromBusinessName,
          fromBusinessNameEn,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .row {
    display: flex;
    .deliveryTime {
      display: flex;
      flex-direction: column;
    }
  }
</style>
