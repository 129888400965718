<template>
  <el-dialog
    title="离职"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="20">
          <el-form-item label="离职时间" prop="leaveTime">
            <el-date-picker
              v-model="form.leaveTime"
              type="date"
              placeholder="年/月/日"
              style="width: 80%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </el-dialog>
</template>

<script>
  import { userBulkDepartures } from '@/api/organization-user'
  import UserChoose from '@/components/userChoose'
  export default {
    name: 'LeaveOfficeModel',
    components: {
      UserChoose,
    },
    data() {
      return {
        loading: false,
        form: {
          leaveTime: '',
          belongerId: '',
          usreIds: [],
          userNames: [],
        },
        belongerName: '',
        rules: {
          leaveTime: [
            { required: true, trigger: 'blur', message: '请选择离职时间' },
          ],
          // belongerId: [
          //   { required: true, trigger: 'blur', message: '请选择归属权转让人' },
          // ],
        },
        dialogFormVisible: false,
      }
    },
    methods: {
      //新增
      showAddEdit(useridArr, userNames) {
        this.form.usreIds = useridArr
        this.form.userNames = userNames
        this.dialogFormVisible = true
      },

      //离职点击
      selectSupplierClick() {
        this.$refs['UserChoose'].showAddEdit(
          'belongerUser',
          this.form.belongerId
        )
      },
      //人员组织架构返回
      chooseUser(checkedUser) {
        this.form.belongerId = checkedUser[0].id
        this.belongerName = checkedUser[0].name
      },

      //关闭
      close() {
        this.belongerName = ''
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },

      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let form = this.utils.deepCopy(this.form)
            this.loading = true
            let response = await userBulkDepartures(form)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '操作成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
</style>
