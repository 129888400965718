<template>
  <el-drawer
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    custom-class="duty-drawer"
    :close-on-click-modal="false"
    size="90%"
  >
    <div slot="title">
      <span class="header-main-title">编辑值班</span>
      <span class="header-sub-title">以下均为北京时间</span>
    </div>
    <div class="duty-wrapper">
      <div class="duty-form-wrapper">
        <el-form :model="formData" ref="formRef" :rules="rules">
          <el-form-item label-width="100px">
            <template slot="label">
              <i style="color: #ff4d4f">*</i>
              值班时段
            </template>
            <div
              v-for="(item, index) in formData.timeList"
              :key="item.timeData[0]"
              class="time-item"
            >
              <el-time-picker
                is-range
                :value="item.timeData"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="HH:mm"
                format="HH:mm"
                readonly
              />
              <i class="el-icon-delete" @click="handleDeleteTimeData(index)" />
            </div>
            <el-time-picker
              is-range
              v-model="selectTime"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="HH:mm"
            />
            <el-button @click="handleAdd" class="ml20">新增时段</el-button>
          </el-form-item>
          <div class="table-title">客服专员</div>
          <el-form-item label-width="100px">
            <template slot="label">
              <i style="color: #ff4d4f">*</i>
              值班人员
            </template>
            <el-button @click="handleBatchSet(0)">批量设置</el-button>
            <el-button @click="handleClearAll(0)" class="ml20">
              清空人员
            </el-button>
          </el-form-item>
          <el-table :data="formData.timeList" border>
            <el-table-column
              type="index"
              label="序号"
              width="50"
              align="center"
            />
            <el-table-column label="时段" align="center">
              <template slot-scope="scope">
                {{ scope.row.timeData[0] }}-{{ scope.row.timeData[1] }}
                <div style="color: #ff4d4f">
                  <i class="el-icon-warning" />
                  {{
                    subtractOneHour(
                      scope.row.timeData,
                      scope.row.assignTimeConfig
                    )
                  }}后此时段不再接收分配邮件
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              v-for="item in weekDayList"
              :key="item.value"
            >
              <template #header>
                <div class="weekDayHeader">
                  <i>*</i>
                  {{ item.label }}
                  <i
                    class="el-icon-error"
                    @click="handleClearWeekDay(item.value)"
                  />
                </div>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="'timeList.' + scope.$index + '.userInfos' + item.value"
                  :rules="rules.user"
                >
                  <el-select
                    v-model="scope.row['userInfos' + item.value]"
                    multiple
                    collapse-tags
                    clearable
                    placeholder="请选择"
                    filterable
                    value-key="userId"
                  >
                    <el-option
                      v-for="user in userList"
                      :key="user.userId"
                      :value="user"
                      :label="
                        user.userName +
                        (user.englishName ? ` (${user.englishName})` : '')
                      "
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="截止分配时间（h）" align="center">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'timeList.' + scope.$index + '.assignTimeConfig'"
                  :rules="rules.assignTimeConfig"
                >
                  <el-input
                    v-model="scope.row.assignTimeConfig"
                    v-input.float
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>

          <div class="table-title">"重点客户"专属客服</div>
          <el-form-item label-width="100px">
            <template slot="label">
              <i style="color: #ff4d4f">*</i>
              值班人员
            </template>
            <el-button @click="handleBatchSet(1)">批量设置</el-button>
            <el-button @click="handleClearAll(1)" class="ml20">
              清空人员
            </el-button>
          </el-form-item>
          <el-table :data="formData.keyTimeList" border>
            <el-table-column
              type="index"
              label="序号"
              width="50"
              align="center"
            />
            <el-table-column label="时段" align="center">
              <template slot-scope="scope">
                {{ scope.row.timeData[0] }}-{{ scope.row.timeData[1] }}
                <div style="color: #ff4d4f">
                  <i class="el-icon-warning" />
                  {{
                    subtractOneHour(
                      scope.row.timeData,
                      scope.row.assignTimeConfig
                    )
                  }}后此时段不再接收分配邮件
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              v-for="item in weekDayList"
              :key="item.value"
            >
              <template #header>
                <div class="weekDayHeader">
                  <i>*</i>
                  {{ item.label }}
                  <i
                    class="el-icon-error"
                    @click="handleClearWeekDay(item.value, 1)"
                  />
                </div>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'keyTimeList.' + scope.$index + '.userInfos' + item.value
                  "
                  :rules="rules.user"
                >
                  <el-select
                    v-model="scope.row['userInfos' + item.value]"
                    multiple
                    collapse-tags
                    clearable
                    placeholder="请选择"
                    value-key="userId"
                    filterable
                  >
                    <el-option
                      v-for="user in userList"
                      :key="user.userId"
                      :value="user"
                      :label="
                        user.userName +
                        (user.englishName ? ` (${user.englishName})` : '')
                      "
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="截止分配时间（h）" align="center">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'keyTimeList.' + scope.$index + '.assignTimeConfig'"
                  :rules="rules.assignTimeConfig"
                >
                  <el-input
                    v-model="scope.row.assignTimeConfig"
                    v-input.float
                    clearable
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <div>
          <ErpLoadingButton type="primary" @click="handleSubmit">
            提 交
          </ErpLoadingButton>
          <el-button @click="handleClose">取 消</el-button>
        </div>
      </div>
    </div>
    <!-- 批量设置值班 -->
    <BatchSetDialog
      v-model="batchSetVisible"
      :timeOptions="timeOptions"
      :userList="userList"
      @setUsers="setUsers"
    />
  </el-drawer>
</template>
<script>
  import BatchSetDialog from './BatchSetDialog'
  import { getParams, subtractOneHour } from '../../utils'
  import { deepCopy, dateTimeFormat } from 'kits'
  import { MailSettingInteractor } from '@/core'
  const checkNumber = (rule, value, callback) => {
    if (value && value <= 0) return callback(new Error('必须大于0'))
    if (value && value > 8) return callback(new Error('最大值为8'))
    callback()
  }
  export default {
    name: 'EditDialog',
    components: {
      BatchSetDialog,
    },
    props: {
      value: { type: Boolean, default: false },
      tableData: { type: Array, default: () => [] },
      keyTableData: { type: Array, default: () => [] },
    },
    data() {
      return {
        selectTime: [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59),
        ],
        formData: {
          timeList: [],
          keyTimeList: [], // 重点客户专属客服值班表数据
        },
        rules: {
          user: [{ required: true, message: '值班人员必选', trigger: 'blur' }],
          assignTimeConfig: [{ validator: checkNumber, trigger: 'change' }],
        },
        weekDayList: [
          { label: '星期一', value: 1 },
          { label: '星期二', value: 2 },
          { label: '星期三', value: 3 },
          { label: '星期四', value: 4 },
          { label: '星期五', value: 5 },
          { label: '星期六', value: 6 },
          { label: '星期日', value: 7 },
        ],
        tableIndex: -1, // 表格索引
        weekDay: 0, // 星期几 1-7对应星期一到星期日
        batchSetVisible: false, // 批量设置弹窗
        isKey: 0, // 0-普通专员 1-重点客户专属客服
        userList: [],
      }
    },
    computed: {
      dialogVisible: {
        get() {
          if (this.value && this.tableData.length) {
            this.formData.timeList = deepCopy(this.tableData)
            this.formData.keyTimeList = deepCopy(this.keyTableData)
          }
          return this.value
        },
        set(flag) {
          this.$emit('input', flag)
        },
      },

      timeOptions() {
        if (this.formData.timeList.length) {
          return this.formData.timeList.map((item) => item.timeData)
        } else if (this.formData.keyTimeList.length) {
          return this.formData.keyTimeList.map((item) => item.timeData)
        }
        return []
      },
    },

    methods: {
      // 获取人员下拉
      getUserList() {
        MailSettingInteractor.getAuthedBusinessApi().then((res) => {
          if (res?.code === '000000') {
            this.userList = res?.data || []
          }
        })
      },
      subtractOneHour,
      handleAdd() {
        let [startTime, endTime] = this.selectTime
        startTime = dateTimeFormat('hh:mm', startTime)
        endTime = dateTimeFormat('hh:mm', endTime)
        const timeList = this.formData.timeList
        if (timeList.length >= 5) {
          return this.$message.warning('最多添加5个时段')
        }
        if (!startTime || !endTime) {
          return this.$message.warning('请选择时间段')
        }
        const startTimeDate = new Date(`2023-01-01T${startTime}:00`)
        const endTimeDate = new Date(`2023-01-01T${endTime}:00`)
        if (startTimeDate >= endTimeDate) {
          return this.$message.warning('结束时间必须大于开始时间')
        }
        const lastTimeData = timeList[timeList.length - 1]?.timeData
        if (!lastTimeData) {
          return this.add(startTime, endTime)
        }
        const currentStartTimeDate = new Date(`2023-01-01T${startTime}:00`)
        const lastStartTimeDate = new Date(`2023-01-01T${lastTimeData[0]}:00`)
        if (currentStartTimeDate <= lastStartTimeDate) {
          return this.$message.warning('当前开始时间需大于上一时段的开始时间')
        }
        this.add(startTime, endTime)
      },
      add(startTime, endTime) {
        const newItem = {
          timeData: [startTime, endTime],
          userNameList1: [],
          userNameList2: [],
          userNameList3: [],
          userNameList4: [],
          userNameList5: [],
          userNameList6: [],
          userNameList7: [],
          assignTimeConfig: '',
        }

        this.formData.keyTimeList.push({
          ...newItem,
        })

        this.formData.timeList.push({
          ...newItem,
        })

        this.selectTime = [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59),
        ]
      },
      // 批量设置
      handleBatchSet(isKey = 0) {
        this.isKey = isKey
        const timeList = isKey
          ? this.formData.keyTimeList
          : this.formData.timeList

        this.batchSetVisible = true
      },

      // 删除时段
      handleDeleteTimeData(index) {
        this.$confirm(`是否确认删除?`, '提示', { type: 'warning' })
          .then(() => {
            this.formData.keyTimeList.splice(index, 1)

            this.formData.timeList.splice(index, 1)
          })
          .catch(() => {})
      },
      // 清空当前星期的人员
      handleClearWeekDay(weekDay, isKey = 0) {
        const timeList = isKey
          ? this.formData.keyTimeList
          : this.formData.timeList
        timeList.forEach((item) => {
          item[`userInfos${weekDay}`] = []
        })
      },
      // 清空所有人员
      handleClearAll(isKey = 0) {
        this.$confirm(`是否确认清空所有人员?`, '提示', { type: 'warning' })
          .then(() => {
            const weekDays = this.weekDayList.map((item) => item.value)
            const timeList = isKey
              ? this.formData.keyTimeList
              : this.formData.timeList
            timeList.forEach((item) => {
              weekDays.forEach((day) => {
                item[`userInfos${day}`] = ''
              })
            })
          })
          .catch(() => {})
      },
      // 批量设置值班人员
      setUsers(data) {
        const { timeData, weekDayList, selectUsers } = data
        const timeList = this.isKey
          ? this.formData.keyTimeList
          : this.formData.timeList
        timeList.forEach((item) => {
          if (item.timeData.join() === timeData.join()) {
            weekDayList.forEach((day) => {
              // item[`userInfos${day}`] = selectUsers
              this.$set(item, `userInfos${day}`, selectUsers)
            })
          }
        })
      },
      handleSubmit(e, done) {
        const { timeList, keyTimeList } = this.formData
        if (timeList.length && keyTimeList.length) {
          this.$refs.formRef.validate(async (valid) => {
            if (!valid) return done()

            // 客服
            const arr = [{}]
            arr[0].isKey = 0
            arr[0].scheduleConfigVOList = getParams(timeList)
            // 重点
            if (keyTimeList?.length) {
              const keyInfo = {
                isKey: 1,
              }
              keyInfo.scheduleConfigVOList = getParams(keyTimeList)
              arr.push(keyInfo)
            }
            const res = await MailSettingInteractor.mailUserScheduleSaveApi(arr)
            done()
            if (res?.code === '000000') {
              this.$message.success('编辑成功')
              this.$emit('refresh')
              this.handleClose()
            }
          })
        } else {
          this.$message.warning('请至少添加一个时段')
          done()
        }
      },
      handleClose() {
        this.$refs.formRef.resetFields()
        this.formData.timeList = []
        this.formData.keyTimeList = []
        this.selectTime = [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59),
        ]
        this.dialogVisible = false
      },
    },

    watch: {
      dialogVisible(val) {
        if (val) {
          this.getUserList()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .header-main-title {
    color: #555;
    font-size: 18px;
  }
  .header-sub-title {
    color: #666;
    font-size: 14px;
    margin-left: 20px;
  }

  ::v-deep {
    .is-active {
      background-color: #fff !important;
    }
    .select-user {
      .el-tag {
        & + .el-tag {
          margin-left: 2px;
        }
        .el-select__tags-text {
          max-width: 28px;
        }
      }
      .el-tag__close {
        display: none;
      }
    }
  }
  .time-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    > i {
      margin-left: 20px;
      cursor: pointer;
      color: #ff4d4f;
    }
  }
  .weekDayHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    > i {
      color: #ff4d4f;
      &:last-of-type {
        cursor: pointer;
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }
  .table-title {
    margin: 10px 0;
    height: 35px;
    line-height: 35px;
    padding-left: 15px;
    background-color: rgba(242, 242, 242, 1);
    color: #333333;
  }
  .duty-form-wrapper {
    height: 800px;
    max-height: 90vh;
    overflow-y: auto;
  }
  ::v-deep {
    .duty-drawer .el-drawer__body {
      padding: 10px;
    }
    .duty-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: hidden;
      .duty-form-wrapper {
        flex: 1;
        overflow-y: auto;
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
      height: 42px;
      padding-top: 10px;
      div {
        text-align: center;
      }
    }
  }
</style>
