var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-product"},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"产品库","name":"1"}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"prop":"codeOrName"}},[_c('el-input',{attrs:{"placeholder":"请输入产品名称/编码","maxlength":"50","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},model:{value:(_vm.ruleForm.codeOrName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "codeOrName", $$v)},expression:"ruleForm.codeOrName"}})],1)],1),_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"prop":"categoryId"}},[_c('el-cascader',{ref:"cascaderAddr",staticClass:"w100",attrs:{"options":_vm.categoryList,"props":_vm.optionProps,"clearable":"","show-all-levels":false},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})],1)],1),_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"text-left"},[_c('el-button',{staticClass:"pub-h-36",on:{"click":_vm.search}},[_vm._v("搜索")])],1)])],1)],1),_c('div',{staticClass:"container"},[_c('el-table',{ref:"multipleTable",attrs:{"border":"","data":_vm.tableData,"height":"450","row-key":function (row) {
              return row.skuId
            }},on:{"selection-change":_vm.selectionChange}},[_c('el-table-column',{attrs:{"type":"selection","reserve-selection":true,"width":"40"}}),_c('el-table-column',{attrs:{"align":"center","label":"产品编码","prop":"skuId","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"page-link"},[_c('router-link',{attrs:{"to":("/product/ProductView?proId=" + (scope.row.productId) + "&noReturn=" + (true)),"target":"_blank"}},[_vm._v(" "+_vm._s(scope.row.skuId)+" ")])],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"200","align":"center","label":"产品名称","prop":"nameCn","show-overflow-tooltip":""}}),(_vm.columnsHidden)?_c('el-table-column',{attrs:{"width":"180","align":"center","label":"产品类别","prop":"categoryName"}}):_vm._e(),(_vm.columnsHidden)?_c('el-table-column',{attrs:{"width":"180","align":"center","label":"产品类型","prop":"standardCustomized"}}):_vm._e(),_c('el-table-column',{attrs:{"align":"center","label":"英文规格","show-overflow-tooltip":"","prop":"label","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.label || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"中文规格","show-overflow-tooltip":"","prop":"labelCn","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.labelCn || '--')+" ")]}}])}),(_vm.columnsHidden)?_c('el-table-column',{attrs:{"width":"180","align":"center","label":"可用库存","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.row.stockWarehouseNumVOList &&
                  scope.row.stockWarehouseNumVOList.length > 1
                )?_c('span',[(scope.row.stockWarehouseNumVOList.length > 1)?_c('el-popover',{attrs:{"placement":"right","width":"200","trigger":"hover","content":_vm.formatStock(scope.row.stockWarehouseNumVOList)}},[_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.formatStock( scope.row.stockWarehouseNumVOList.slice(0, 1) ))+" "),_c('span',{staticClass:"more"},[_vm._v(" 等"+_vm._s(scope.row.stockWarehouseNumVOList.length)+"个 ")])])]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(_vm.formatStock(scope.row.stockWarehouseNumVOList))+" ")])]}}],null,false,562074687)}):_vm._e()],1)],1),_c('el-pagination',{attrs:{"current-page":_vm.page.pageNo,"page-size":_vm.page.pageLe,"total":_vm.total},on:{"current-change":_vm.currentChange}})],1),_c('el-tab-pane',{attrs:{"label":"辅料库","name":"2"}},[_c('el-row',{attrs:{"gutter":15}},[_c('el-col',{attrs:{"span":12}},[_c('el-input',{attrs:{"placeholder":"搜索产品名称/产品编码","maxlength":"50","clearable":""},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1),_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"text-left"},[_c('el-button',{on:{"click":_vm.search2}},[_vm._v("搜索")])],1)])],1),_c('el-table',{ref:"multipleTable2",staticClass:"mt10",attrs:{"border":"","data":_vm.tableData2,"row-key":function (row) {
            return row.accessoriesNumber
          }},on:{"selection-change":_vm.selectionChange2}},[_c('el-table-column',{attrs:{"type":"selection","reserve-selection":true,"width":"40"}}),_c('el-table-column',{attrs:{"align":"center","label":"产品编码","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"page-link"},[_vm._v(" "+_vm._s(scope.row.accessoriesNumber)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"产品图片","prop":"productImg","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"img-wrap"},[_c('el-badge',{directives:[{name:"show",rawName:"v-show",value:(
                  scope.row.productImg &&
                  scope.row.productImg.split(',').length > 1
                ),expression:"\n                  scope.row.productImg &&\n                  scope.row.productImg.split(',').length > 1\n                "}],attrs:{"value":scope.row.productImg
                    ? scope.row.productImg.split(',').length
                    : ''}}),(scope.row.productImg)?_c('el-image',{staticClass:"c_pointer",attrs:{"src":scope.row.productImg.split(',')[0]},on:{"click":function($event){_vm.handlePreview(scope.row.productImg.split(','))}}}):_c('span',[_vm._v("--")])],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"产品名称","prop":"nameCn","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","label":"库存","prop":"nameCn","width":"300","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.warehouseStockList &&
                scope.row.warehouseStockList.length > 1
              )?_c('span',[(scope.row.warehouseStockList.length > 1)?_c('el-popover',{attrs:{"placement":"right","width":"200","trigger":"hover","content":_vm.formatStock(scope.row.warehouseStockList)}},[_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.formatStock(scope.row.warehouseStockList.slice(0, 1)))+" "),_c('span',{staticClass:"more"},[_vm._v(" 等"+_vm._s(scope.row.warehouseStockList.length)+"个 ")])])]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(_vm.formatStock(scope.row.warehouseStockList))+" ")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.page2.pageNo,"page-size":_vm.page2.pageLe,"total":_vm.total2,"page-sizes":[10, 20, 30, 40, 50, 100],"layout":"total, sizes, prev, pager, next, jumper"},on:{"current-change":_vm.pageChange2,"size-change":_vm.sizeChange2}})],1)],1),_c('div',{staticClass:"text-center mt20"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("确认")]),_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1),(_vm.showViewer)?_c('el-image-viewer',{attrs:{"on-close":_vm.closeViewer,"url-list":_vm.imgUrl}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }