<template>
  <div>
    <el-table class="expand-table" :data="tableData" border center v-loading="tableData === null">
      <!-- 产品信息 -->
      <el-table-column :label="$t('orderList.ProductInformation')" width="300">
        <template slot-scope="scope">
          <dl class="product-dl">
            <dt class="split-wrap">
              <img class="main-img" :src="scope.row.imgMain +URL_COMPRESS"
                @click="handlePreview(scope.row.imgMain.split(','))" />
              <div class="mark">
                <p>
                  {{ getLangProductType(scope.row.standardCustomized) }}
                </p>
                <p v-if="scope.row.sampleFlag" style="margin-top: 4px" class="el-tag-other">
                  {{ $t('AddOrder.PrenatalSample') }}
                </p>
              </div>
              <el-popover v-show="scope.row.orderProductListVOS" placement="bottom" width="" trigger="hover">
                <el-table border :data="scope.row.orderProductListVOS" width="800px"
                  :header-cell-style="{ textAlign: 'center' }">
                  <!-- 产品信息 -->
                  <el-table-column :label="$t('orderList.ProductInformation')" width="300">
                    <template slot-scope="scope">
                      <div class="flex">
                        <img v-show="scope.row.imgMain" class="child-img" :src="scope.row.imgMain.split(',')[0]" alt=""
                          @click="handlePreview(scope.row.imgMain.split(','))" />
                        <div class="ml10">
                          <p>{{ scope.row.sku }}</p>
                          <p>
                            {{
                            lang === 'en'
                            ? scope.row.productEn
                            : scope.row.productCn
                            }}
                          </p>
                          <p>{{$t('addInquiry.EnglishSpecification')}}：{{ scope.row.specification }}</p>
                          <p>{{$t('addInquiry.CNSpecification')}}：{{ scope.row.specificationCn }}</p>
                          <p>1</p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 订单数量 -->
                  <el-table-column :label="$t('orderList.OrderQuantity')" prop="amount" align="center"
                    width="150"></el-table-column>
                  <!-- 已出运数量 -->
                  <el-table-column :label="$t('orderList.ShippedQuantity')" align="center" width="150">
                    <template slot-scope="scope">
                      {{ scope.row.delyveryAmount || '--' }}
                    </template>
                  </el-table-column>
                  <!-- 印刷方式 -->
                  <el-table-column :label="$t('orderList.PrintingMethod')" align="center" prop="printingWay"
                    width="150">
                    <template slot-scope="scope">
                      {{
                      scope.row.printingWayEn || scope.row.printingWay || '--'
                      }}
                    </template>
                  </el-table-column>
                  <!-- 印刷效果图 -->
                  <el-table-column :label="$t('orderList.PrintingRenderings')" align="center" width="160">
                    <template slot-scope="scope">
                      <div class="design-pic" v-if="
                          scope.row.rendingsUrl &&
                          scope.row.printingWay.toUpperCase() != 'BLANK'
                        ">
                        <img class="child-img" :src="scope.row.rendingsUrl.split(',')[0]" alt="" @click="
                            handlePreview(scope.row.rendingsUrl.split(',')[0])
                          " />
                      </div>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
                  <!-- 产品进度 -->
                  <el-table-column :label="$t('orderList.ProductProgress')" width="300" align="center">
                    <template slot-scope="scope">
                      <div class="process">
                        <div class="process-item" v-for="(process, idx) in scope.row
                            .orderProductCouseListVOS" :key="idx">
                          <span>
                            {{ getLangProductProgress(process.majorState) }}
                            {{ process.productCourseNum }}
                          </span>
                          <span class="status-mark is-plain el-button--primary" v-if="process.substate">
                            {{ getLangProductProgress(process.substate) }}
                          </span>
                        </div>
                        <span v-if="
                            !scope.row.orderProductCouseListVOS ||
                            scope.row.orderProductCouseListVOS.length === 0
                          ">
                          --
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <em class="main-split" slot="reference">
                  {{ lang === 'en' ? 'Main' : '主' }}
                </em>
              </el-popover>
            </dt>
            <dd>
              <div>{{ scope.row.sku }}</div>
              <div>
                {{ lang === 'en' ? scope.row.productEn : scope.row.productCn }}
              </div>
              <div>{{$t('addInquiry.EnglishSpecification')}}：{{ scope.row.specification }}</div>
              <div>{{$t('addInquiry.CNSpecification')}}：{{ scope.row.specificationCn }}</div>
              <div>
                {{
                lang === 'en'
                ? scope.row.productBelongerEn || '--'
                : scope.row.productBelonger || '--'
                }}
              </div>
            </dd>
          </dl>
        </template>
      </el-table-column>
      <!-- 订单数量 -->
      <el-table-column :label="$t('orderList.OrderQuantity')" prop="amount" align="center"></el-table-column>
      <!-- 已出运数量 -->
      <el-table-column :label="$t('orderList.ShippedQuantity')" align="center">
        <template slot-scope="scope">
          {{ scope.row.delyveryAmount || '--' }}
        </template>
      </el-table-column>
      <!-- 印刷方式 -->
      <el-table-column :label="$t('orderList.PrintingMethod')" align="center" prop="printingWay">
        <template slot-scope="scope">
          {{ scope.row.printingWayEn || scope.row.printingWay || '--' }}
        </template>
      </el-table-column>
      <!-- 印刷效果图 -->
      <el-table-column align="center" :label="$t('orderList.PrintingRenderings')" width="160">
        <template slot-scope="scope">
          <div class="design-pic" v-if="scope.row.rendingsUrl">
            <el-badge v-show="
                scope.row.rendingsUrl &&
                scope.row.rendingsUrl.split(',').length > 1
              " :value="
                scope.row.rendingsUrl
                  ? scope.row.rendingsUrl.split(',').length
                  : ''
              "></el-badge>
            <img class="pic" :src="scope.row.rendingsUrl.split(',')[0] + URL_COMPRESS" alt=""
              @click="handlePreview(scope.row.rendingsUrl.split(','))" />
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 产品进度 -->
      <el-table-column :label="$t('orderList.ProductProgress')" width="300" align="center">
        <template slot-scope="scope">
          <div class="process">
            <div class="process-item" v-for="(process, idx) in scope.row.orderProductCouseListVOS" :key="idx">
              <span>
                {{ getLangProductProgress(process.majorState) }}
                {{ process.productCourseNum }}
              </span>
              <span class="status-mark is-plain el-button--primary" v-if="process.substate">
                {{ getLangProductProgress(process.substate) }}
              </span>
            </div>
            <span v-if="
                !scope.row.orderProductCouseListVOS ||
                scope.row.orderProductCouseListVOS.length === 0
              ">
              --
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
  </div>
</template>

<script>
  import { URL_COMPRESS } from '@/config'
  export default {
    props: ['tableData', 'lang', 'loading'],
    data() {
      return {
        showViewer: false, //图片预览参数
        imgUrl: [], //图片预览url
        URL_COMPRESS: URL_COMPRESS, // 图片压缩
      }
    },
    created() {
      console.log(this.tableData)
    },
    methods: {
      // 产品类型中英文翻译
      getLangProductType(state) {
        let text = ''
        switch (state) {
          case '标准品':
            text = this.$t('orderDetails.StandardProduct')
            break
          case '订制品':
            text = this.$t('orderDetails.CustomizedProduct')
            break
          case '辅料':
            text = this.$t('orderDetails.Excipients')
            break
        }
        return text
      },

      // 产品进度中英文翻译
      getLangProductProgress(state) {
        let text = ''
        switch (state) {
          case '待安排':
            text = this.$t('orderDetails.PendingArrangement')
            break
          case '待采购处理':
            text = this.$t('orderList.PendingPurchase')
            break
          case '采购中':
            text = this.$t('orderList.InProcurement')
            break
          case '验货中':
            text = this.$t('orderList.UnderInspection')
            break
          case '已采购':
            text = this.$t('orderList.Purchased')
            break
          case '已入库':
            text = this.$t('orderList.BeenStorage')
            break
          case '已出库':
            text = this.$t('orderList.OutOfWarehouse')
            break
          case '出库中':
            text = this.$t('orderList.InDelivery')
            break
          case '已完成':
            text = this.$t('orderList.Completed')
            break
          case '已出运':
            text = this.$t('orderList.Shipped')
            break
          case '未出库':
            text = this.$t('orderList.Notoutofstock')
            break
        }
        return text
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .expand-table {
    width: 1300px;
    margin-left: 55px;

    .product-dl {
      display: flex;
      align-items: center;
      padding: 5px 0;
      width: 100%;

      .split-wrap {
        position: relative;

        em {
          position: absolute;
          right: 0;
          bottom: 0;
          width: auto;
          text-align: center;
          cursor: pointer;
        }
      }

      dt {
        width: 70px;
        height: 70px;
        border-radius: 4px;
        box-shadow: 0 0 5px #ccc;
        overflow: hidden;
        margin-right: 10px;
        position: relative;
      }

      .main-img {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
      }

      .mark {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        line-height: 12px;

        p {
          padding: 0 3px;
          color: #1890ff;
          background: #e8f4ff;
          border-radius: 4px;

          &.el-tag-other {
            background-color: #fff7e8;
            border-color: #ffefd1;
            color: #ffae18;
          }
        }
      }

      dd {
        min-width: 100px;
        flex: 1;
        line-height: 18px;
      }
    }

    .design-pic {
      position: relative;

      .el-badge {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 2;
      }
    }

    .pic {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      box-shadow: 0 0 5px #ccc;
      background-color: #ccc;
    }

    .process {
      text-align: left;

      .process-item {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;

        &+.process-item {
          border-top: 1px dashed #000;
        }
      }
    }

    .status-mark {
      display: inline-block;
      text-align: center;
      border-radius: 2px;
      height: 20px;
      line-height: 18px;
      font-size: 12px;
      margin-left: 10px;
      border-width: 1px;
      border-style: solid;
    }
  }

  ::v-deep {
    .expand-table tr {
      height: auto;

      th.el-table__cell {
        height: 38px;
        padding: 4px 0;
      }
    }

    .expand-table .el-table-cell {
      padding: 4px 0;
    }

    .child-img {
      width: 70px;
      height: 70px;
      border-radius: 2px;
    }
  }
</style>
