<template>
  <div class="table-container outbound">
    <el-row>
      <el-page-header @back="goBack" content="创建出库单"></el-page-header>
    </el-row>
    <h3 class="mb20">基本信息</h3>
    <div class="form-wrapper">
      <!-- 其他出库表单 -->
      <el-form ref="form" label-width="90px" key="typeIsQt" :model="form" :rules="rules">
        <!-- 出库方式 -->
        <el-row>
          <el-col :span="6">
            <el-form-item label="出库方式" prop="type">
              <el-select disabled v-model="currentType" value-key="type" style="width: 100%">
                <el-option v-for="item in typeNames" :key="item.type" :value="item" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 出库仓库 -->
            <el-form-item label="出库仓库" prop="warehouseId">
              <el-select v-model="form.warehouseId" value-key="type" :popper-append-to-body="false" style="width: 100%">
                <el-option :title="item.name" v-for="item in warehouses" :key="item.warehouseId"
                  :value="item.warehouseId" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人" prop="creator">
              <el-input disabled v-model="form.creator"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区" prop="creatorArea">
              <el-input disabled v-model="form.creatorArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <!--  操作人 -->
            <el-form-item prop="operationName">
              <span slot="label">
                <span style="visibility: hidden">号</span>
                操作人
              </span>
              <el-input disabled v-model="form.operationName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sapn="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input type="textarea" :rows="2" show-word-limit v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 入库产品 -->
    <h3 class="mt30 mb20">出库产品</h3>
    <p class="m20">
      <el-button @click="showPro" type="default" style="border-color: #2882ff; color: #2882ff">
        选择产品
      </el-button>
    </p>

    <!-- 其他出库表格 -->
    <!-- show-summary
      sum-text="总计"
      :summary-method="getSummaries" -->
    <el-table border :data="tableData" class="mt20" :span-method="objectSpanMethod">
      <el-table-column align="center" prop="skuId" label="产品编码">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" v-if="scope.row.skuId.indexOf('FL') === -1"
            @click="skuIdClick(scope.row.skuId)">
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>{{ scope.row.skuId }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="productName" label="产品名称"></el-table-column>
      <el-table-column align="center" prop="productSpec" label="英文规格" width="180" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpec || '--' }}
        </template>
      </el-table-column>
       <el-table-column align="center" prop="productSpec" label="中文规格" width="180" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.productSpecCn ||  '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型"></el-table-column>
      <el-table-column align="center" prop="productUnit" label="单位">
        <template slot-scope="scope">
          {{ scope.row.productUnit ? scope.row.productUnit : '--' }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="warehouseOutNum" width="150px" label="实际出库数量">
        <template slot="header">
          <span style="color: red; vertical-align: middle">*</span>
          <span>实际出库数量</span>
        </template>
        <template slot-scope="scope">
          <el-input-number :class="{ error: showError && !scope.row.warehouseOutNum }"
            v-model="scope.row.warehouseOutNum" @change="onChangewarehouseOutNum(scope.row, $event)" :precision="0"
            :min="0" @blur="checkEmpty(scope.row, 'warehouseOutNum')"></el-input-number>
          <p v-if="
              scope.row.warehouseOutNumEmptyCheck && !scope.row.warehouseOutNum
            " class="error-info">
            为正数,不能输入0或空
          </p>
        </template>
      </el-table-column>
      <!-- V1.6.1入库批次 -->
      <el-table-column align="center" prop="lotNo" label="入库批次" width="200px">
        <template slot-scope="scope">
          <div class="lot-wrapper">
            <el-input disabled v-model="scope.row.lotNo"></el-input>
            <i v-if="isChoose" class="el-icon-circle-plus-outline" @click="lotSel(scope.row)"></i>
          </div>
        </template>
      </el-table-column>
      <!-- V1.6.1采购单号 -->
      <el-table-column align="center" prop="purchaseNumber" label="采购单号" :formatter="formatter"></el-table-column>
      <!-- V1.6.1采购开发 -->
      <el-table-column align="center" prop="buyer" label="采购开发" :formatter="formatter"></el-table-column>
      <el-table-column align="center" label="可用库存">
        <template slot="header" slot-scope="scope">
          <span>可用库存</span>
          <el-tooltip class="item" effect="dark" placement="top" content="当前入库单批次剩余可用库存数，去除锁定库存数，去除待出库数量">
            <i class="iconfont el-icon-question c_pointer"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <!-- {{ usable == '1' ? scope.row.usableNum : scope.row.unusableNum }} -->
          {{ scope.row.usableNum }}
          <p class="el-form-item__error" v-if="scope.row.warehouseOutNum > scope.row.stockNum">
            库存不足
          </p>
        </template>
      </el-table-column>

      <el-table-column align="center" width="160" prop="lotNumber">
        <template slot="header" slot-scope="scope">
          <span class="red">*</span>
          <span>批次出库数量</span>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="remark" label="备注" width="250px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top-start">
            <el-input v-model="scope.row.remark" placeholder="请添加备注"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请添加备注" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <span style="color: red">*</span>
          <span>出库库位</span>
        </template>
        <template slot-scope="scope">
          <div v-if="ruleType !== '1'">
            {{ ruleType === '3' ? '从少到多自动扣减' : '从多到少自动扣减' }}
          </div>
          <div v-else>
            <SelectedLocs :locs="scope.row.warehouseOutProductLocationDTOList" @add-loc="addLoc"
              :tableIndex="scope.$index" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <p class="operation">
            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
              <el-button type="danger" plain icon="el-icon-delete-solid" circle size="mini"
                @click="deletePro(scope.$index)"></el-button>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
    </el-table>
    <h3 class="mt30 mb20">附件</h3>
    <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"></ErpUpload>
    <p class="divider"></p>
    <div style="text-align: center">
      <el-button type="primary" @click="onSubmit" :loading="loading">
        确认
      </el-button>
      <el-button @click="$router.back()" class="ml15">取消</el-button>
    </div>
    <!-- 产品列表 -->
    <ProductDialg :params="params" :selectedPro="selectedPro" :warehouseId="form.warehouseId" @close="onClose"
      @outnumchange="onOutNumChange" @select-pro="selectPro"></ProductDialg>
    <!-- 库位列表 -->
    <Loc ref="loc" :params="locParams" :storeType="currentType.type" :warehouseOutNum="currentRow.warehouseOutNum"
      :warehouseId="form.warehouseId" @set-loc="onSetLoc" @close="onClose('loc')"></Loc>

    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick" />
    <!-- 批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList"></LotBatchSel>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProductDialg from './components/product.vue'
  import Loc from './components/loc.vue'
  import { tableMixin } from '../helper/mixin'

  import AuditChoose from '@/components/AuditChoose'
  import SelectedLocs from '../inbound-sheet/components/selected-locs.vue'
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'
  import { OutboundSheetInteractor, ProductListInteractor } from '@/core'

  const baseProduct = {
    skuId: '',
    productName: '',
    productSpec: '',
    productType: '',
    productUnit: '',
    contractNum: '',
    giftNum: '',
    totalPrice: '0.000',
    price: '',
    warehouseOutNum: '',
    remark: '',
    warehouseOutProductLocationDTOList: [], //出库库位信息
    stockNum: 0, //库存数量
    productImage: '',
  }
  let self
  export default {
    name: 'new-outbound-sheet',

    data() {
      self = this
      return {
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        ruleType: 0, //0手动设置 1从多到少 2从少到多
        form: {
          area: '',
          date: '',
          businessName: '',
          businessId: '',
          supplierName: '',
          type: 5, //出库方式code
          typeName: '', // 出库方式名称
          warehouseId: '', //出库仓库
          operationId: '',
          operationName: '',
          remark: '',
          price: '0.00',
          warehouseOutId: '',
          orderNumber: '',
          customerName: '',
          warehouseOutNumber: '',
        },
        selectedPro: [], //已选择产品
        rules: {
          type: [
            {
              required: true,
              message: '请选择出库方式',
            },
          ],
          warehouseId: [
            {
              required: true,
              message: '请选择出库仓库',
              trigger: 'change',
            },
          ],

          remark: [
            {
              validator: (rule, value, callback) => {
                if (value && value.length > 500) {
                  return callback(new Error('备注长度不能超过500'))
                }
                callback()
              },
            },
          ],
        },
        isLoading: false,
        operation: {}, //登录人
        typeNames: [
          {
            type: 5,
            typeName: '其他出库',
          },
        ], //出库方式
        currentType: {}, //选中出库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
          warehouseName: '',
        },
        locParams: {
          show: false,
          locs: [],
        },
        tableData: [],
        currentRow: {},
        files: '',
        showError: false,
        usable: '0',
        isChoose: false, // 是否可以选择入库批次
        loading: false,
      }
    },

    components: {
      ProductDialg,
      Loc,
      AuditChoose,
      SelectedLocs,
      LotBatchSel,
    },

    watch: {
      'form.warehouseId': function (newval, oldval) {
        if (this.isEdit) {
          if (this.isResetWhenWhChanged) {
            this.resetFormAndTableDataWhenWHChanged()
          }
        } else {
          this.resetFormAndTableDataWhenWHChanged()
        }
        // 获取仓库名
        let whName = this.warehouses.find((w) => w.warehouseId === newval).name
        // 获取规则值

        let targetRule = this.settingRules.find(
          (r) => r.warehouseName.indexOf(whName) !== -1
        )
        if (targetRule) {
          // '3' 从少到多
          // '2' 从多到少
          // '1' 手动设置
          this.ruleType = targetRule.value
        } else {
          this.ruleType = '1'
        }

        if (this.isResetWhenWhChanged === false) {
          this.isResetWhenWhChanged = true
        }
        // 获取仓库
        let wh = this.warehouses.find((w) => w.warehouseId === newval)
        if (wh) {
          this.flag = wh.flag
        }
        // 批次规则
        this.getStockCostConfig(newval, '')
      },
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),

      tenantId() {
        return this.userInfo.tenantId
      },
      typeIsCg() {
        //销售出库
        return this.form.type === 3
      },
      //退货出库
      typeIsTh() {
        return this.form.type === 4
      },
      //其他出库
      typeIsQt() {
        return this.form.type === 5
      },
      isEdit() {
        return !!this.$route.query.warehouseOutId
      },
    },
    mixins: [tableMixin],
    async created() {
      this.initData()
      // 仓库数据
      let res_1 = await OutboundSheetInteractor.findWarehouseList(1)
      this.warehouses = res_1.data || []

      let res_2 = await OutboundSheetInteractor.warehouseSetListPage(4)
      if (res_2 && res_2.code === '000000') {
        this.settingRules = res_2.data || []
      }
    },
    methods: {
      // 获取推荐批次
      async getLotRecommend(params) {
        const { skuId } = params
        let res = await OutboundSheetInteractor.lotRecommend(params)
        if (res && res.code === '000000') {
          this.tableData.forEach((item) => {
            if (item.skuId == skuId) {
              item.stockLot = res.data.map((item) => {
                item.lotNumber = item.num
                return item
              })
            }
          })
          this.tableData = this.formatTableData(this.tableData, 'stockLot')
          this.resetCombineParams()
          this.getSpanArr(this.tableData)
        }
      },

      // 出库数量变化
      onChangewarehouseOutNum(row, e) {
        const { stockNum, warehouseOutNum, lockNum, skuId, usable } = row

        const { warehouseId } = this.form
        let flag
        // if (stockNum - lockNum < warehouseOutNum) {
        if (stockNum < warehouseOutNum) {
          this.$message.warning('库存不足')
          row.warehouseOutNum = 0
          event.target.value = ''
          this.onChangePriceExcludingTax(row)
          flag = false
        } else {
          flag = true
          this.onChangePriceExcludingTax(row)
        }
        if (warehouseId && row.warehouseOutNum && skuId) {
          if (this.warehouseOutType == 1) {
            const params = {
              num: warehouseOutNum,
              skuId,
              tenantId: this.userInfo.tenantId,
              warehouseId,
            }
            if (usable === 0) {
              params.usable = usable
            }
            this.getLotRecommend(params)
            // 清空库位信息
            row.warehouseOutProductLocationDTOList = []
          }
        }
        return flag
      },
      // 获取仓库配置
      async getStockCostConfig(id) {
        let res = await OutboundSheetInteractor.getStockCostConfigureVO(id)
        if (res && res.code === '000000') {
          this.isChoose = res.data?.warehouseInBatch === 1 ? true : false
          this.warehouseOutType = res.data?.warehouseOutType
        }
      },
      formatter(row, column, cellValue, index) {
        return cellValue ? cellValue : '-'
      },
      // 设置选中批次
      setLocList(list) {

        this.tableData.forEach((item) => {
          if (item.skuId === this.currentLotRow.skuId) {
            item.stockLot = list
            item.warehouseOutProductLocationDTOList = []
          }
        })
        // this.currentLotRow.stockLot = list
        // const key = `${this.currentLotRow.skuId}${this.currentLotRow.skuId}`
        this.tableData = this.formatTableData(this.tableData, 'stockLot')
        this.resetCombineParams()
        this.getSpanArr(this.tableData)
      },
      // 选择批次
      lotSel(row) {
        if (!row.warehouseOutNum || row.warehouseOutNum === 0) {
          return this.$message.warning('请输入实际出库数量')
        }
        // let flag = this.onChangewarehouseOutNum(row)
        // if (!flag) return
        this.currentLotRow = row //当前待设置的批次行
        if (!row.stockLot) {
          this.$set(row, 'stockLot', [])
        }
        console.log(row.stockLot)
        // 添加orderProductUniqueId
        this.$refs.lotBatchSel.show({
          list: JSON.parse(JSON.stringify(row.stockLot)), //拷贝
          sku: row.skuId,
          orderProductUniqueId: row.orderProductUniqueId,
          productName: row.productName,
          warehouseId: this.form.warehouseId,
          applyNum: row.warehouseOutNum,
          useType: row.usable === 1 ? 1 : 2,
        })
      },
      //产品编码click
      skuIdClick(skuId) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      getWhLocType() {
        let requestParams = {
          warehouseId: this.form.warehouseId,
        }
        return 1
      },
      goBack() {
        this.$router.push('/stock-center/outbound-sheet/outbound-sheet')
      },
      checkEmpty(row, field) {
        if (
          row[field] === 0 ||
          row[field] === '' ||
          typeof row[field] === 'undefined'
        ) {
          // this.showEmptyError = true
          if (!row[field + 'emptyCheck']) {
            this.$set(row, [field + 'EmptyCheck'], true)
          }
        }
        if (this.typeIsTh && row.warehouseOutNum > row.contractNum) {
          this.$set(row, 'warehouseOutNum', row.contractNum)
          return this.$baseMessage(
            '实际出库数量不能大于采购数量',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
      },
      // 提交时产品校验
      $_checkProduct() {
        // this.showError = true
        let tableData = this.tableData
        tableData.forEach((item) => {
          if (!item['warehouseOutNumEmptyCheck']) {
            this.$set(item, 'warehouseOutNumEmptyCheck', true)
          }
          // 出库单价校验去掉
          // if (!item['priceEmptyCheck']) {
          //   this.$set(item, 'priceEmptyCheck', true)
          // }
        })
        if (this.typeIsQt) {
          return !!this.tableData.find((item) => !item.warehouseOutNum)
        }
        return !!this.tableData.find((item) => !item.warehouseOutNum)
      },
      // 校验库位选择
      $_checkEmptyLoc() {
        let checResult = true
        let tableData = this.tableData
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]
          let { warehouseOutProductLocationDTOList } = pro
          if (warehouseOutProductLocationDTOList.length === 0) {
            //step01 校验是否选择出库库位
            checResult = false
            this.$message.warning(`请选择产品 ${pro.productName}的出库库位`)
            return checResult
          }
        }
        return checResult
      },
      // 实际出库数量，备用品数量校验
      $_checkCount() {
        let checResult = true
        let tableData = this.tableData
        for (let i = 0, l = tableData.length; i < l; i++) {
          let pro = tableData[i]

          let {
            warehouseOutProductLocationDTOList,
            warehouseOutNum,
            giftCount,
            giftNum,
            remark,
            productName,
          } = pro

          if (warehouseOutProductLocationDTOList.length === 0) {
            //step01 校验是否选择出库库位
            checResult = false
            this.$message.warning(`请选择产品 ${pro.productName}的出库库位`)
            break
          } else {
            //step02 校验实际出库数和库位选择总数是否相同 废弃
            // let totalLocNum = warehouseOutProductLocationDTOList
            //   .map((i) => i.num)
            //   .reduce((prev, next) => prev + next)
            // if (warehouseOutNum !== totalLocNum) {
            //   this.$message.warning(
            //     `产品 ${pro.productName}的实际出库数量${warehouseOutNum}和库位已选出库数量${totalLocNum}不相等`
            //   )
            //   checResult = false
            //   break
            // }

            // step02 v1.6.1 校验 产品实际出库数量是否和批次出库数量相同
            // 获取产品sku
            const skuList = [...new Set(tableData.map((i) => i.skuId))]
            for (let i = 0; i < skuList.length; i++) {
              const { warehouseOutNum, productName } = tableData.find(
                (item) => item.skuId === skuList[i]
              )

              const lotNumberTotal = tableData
                .filter((j) => j.skuId == skuList[i])
                .map((k) => k.lotNumber)
                .reduce((prev, next) => {
                  prev += next
                  return prev
                }, 0)

              if (warehouseOutNum !== lotNumberTotal) {
                this.$message.warning(
                  `产品${productName}的实际出库数量不等于批次出库数量`
                )
                checResult = false
                break
              }
            }
            // if ((checResult = false)) return checResult
            // 校验批次出库数量和库位数量
            const lotLocTotalNum = tableData[
              i
            ].warehouseOutProductLocationDTOList.reduce((prev, next) => {
              prev += next.num
              return prev
            }, 0)
            if (tableData[i].lotNumber !== lotLocTotalNum) {
              this.$message.warning(
                `产品${productName}的出库库位数量不等于批次出库数量`
              )
              checResult = false
              break
            }
            //step03 备用品数量校验
            if (giftNum > giftCount) {
              this.$message.warning(
                `产品 ${pro.productName}的备用品数量校验${giftNum}不能大于备用品实际数量${giftCount}`
              )
              checResult = false
              break
            }
            // step04备注长度校验
            if (remark && remark.length > 500) {
              checResult = false
              break
            }
          }
          return checResult
        }

        return checResult
      },
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },
      showPro() {
        if (!this.form.warehouseId) {
          return this.$message.warning('请选择出库仓库')
        }
        this.selectedPro = this.tableData
        this.params.warehouseName = this.warehouses.find(
          (wh) => wh.warehouseId === this.form.warehouseId
        ).name
        this.params.show = true
      },

      toFixed2(val) {
        if (!val && val !== 0) {
          return '0.00'
        } else {
          return (val * 1).toFixed(3)
        }
      },
      onChangePriceExcludingTax(row) {
        row.totalPrice = (row.price * row.warehouseOutNum).toFixed(3)
      },
      //查询采购单号
      async searchPurchaseOrder() {
        const { orderNumber, warehouseId } = this.form
        if (!orderNumber) {
          return this.$message.warning('请输入采购单号!')
        }
        if (!warehouseId) {
          return this.$message.warning('请选择仓库!')
        }

        let res = await OutboundSheetInteractor.getDetailForShowByNumber(
          orderNumber,
          warehouseId
        )
        if (res && res.code === '000000') {
          this.form.supplierId = res.data.supplierId
          this.form.supplierName = res.data.supplierName
          let warehouseInProductVOList = res.data.warehouseInProductVOList
          this.execPurchaseOrderData(warehouseInProductVOList || [])
        } else {
          this.form.orderNumber = ''
          this.form.supplierName = ''
          this.tableData = []
        }
      },
      async getPrice(product) {
        if (!product || product.length === 0) {
          return
        }
        const skuIds = product.map((p) => p.skuId).join(',')
        let res = await OutboundSheetInteractor.listStockPriceBySkuIds(
          this.form.warehouseId,
          skuIds
        )
        if (res && res.code === '000000') {
          res.data.forEach((item, index) => {
            product[index].price = item
          })
        }
      },
      async searchOrder() {
        const { orderNumber, warehouseId } = this.form
        if (!orderNumber) {
          return this.$message.warning('请输入订单编号!')
        }
        if (!warehouseId) {
          return this.$message.warning('请选择仓库!')
        }
        let res = await OutboundSheetInteractor.getDetailByOrderCode(
          orderNumber,
          warehouseId
        )
        if (res && res.code === '000000') {
          let d = res.data
          if (d[0]) {
            // 后端返回数据结构改变，相关字段放在产品同级
            this.form.businessId = d[0].businessId
            this.form.businessName = d[0].businessName
            this.form.customerName = d[0].customerName
          }
          let orderProductVOS = res.data.filter(
            (i) => i.standardCustomized === '标准品'
          )
          // 解析生成tableData
          this.execOrderData(orderProductVOS || [])
          // 获取单价
        } else {
          this.form.orderNumber = ''
        }
      },

      execOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.sku
          _basePro.productName = item.productName
          _basePro.productSpec = item.specification
          _basePro.productType = item.productCategory
          _basePro.contractNum = item.amount
          _basePro.productImage = item.rendingsUrl
          _basePro.productCode = item.productCode
          _basePro.salesOrderId = _basePro.orderId
          _basePro.salesOrderNumber = this.form.orderNumber
          tableData.push(_basePro)
        })
        this.tableData = tableData
      },

      execPurchaseOrderData(productList) {
        const tableData = []
        productList.forEach((item) => {
          let _basePro = { ...baseProduct, ...item }
          _basePro.skuId = item.skuId
          //_basePro.price = item.priceExcludingTax
          _basePro.productUnit = item.productUnit
          _basePro.productName = item.productName
          _basePro.productSpec = item.productSpec
          _basePro.productType = item.productType
          _basePro.contractNum = item.warehouseInNum
          //_basePro.productSpec = item.specsValue1
          _basePro.priceExcludingTax = item.priceExcludingTax
          _basePro.priceIncludingTax = item.priceIncludingTax
          //_basePro.productImage = item.imgMain
          //_basePro.productCode = item.productCode
          tableData.push(_basePro)
        })
        this.tableData = tableData
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          let summayIndexs

          if (this.typeIsCg || this.typeIsTh) {
            summayIndexs = [5, 6]
          } else {
            summayIndexs = [5, 6]
          }

          if (!summayIndexs.includes(index)) {
            sums[index] = ''
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            // if (index === 5 && sums[index] === 0) {
            //   sums[index] = '--'
            // }
            if (this.typeIsQt) {
              if (index === 9) {
                sums[index] = this.toFixed3(sums[index])
              }
            }
          }
        })

        return sums
      },
      deletePro(index) {
        const delRow = this.tableData[index]
        const { lotNo, skuId } = delRow
        this.tableData.splice(index, 1)
        this.tableData = this.tableData.map((item) => {
          if (item.skuId === skuId) {
            item.stockLot = item.stockLot.filter((item) => item.lotNo !== lotNo)
          }
          return item
        })
        this.formatTableData(this.tableData, 'stockLot')
      },
      onSetLoc(locs, totolCounts /*giftCount*/) {
        locs = locs.map((loc) => {
          let obj = {}
          obj.locationNumber = loc.locationNumber
          obj.num = loc.outNum
          obj.skuId = this.currentRow.skuId
          obj.warehouseId = this.form.warehouseId
          obj.warehouseOutLotId = this.currentRow.stockLotId
          obj.warehouseOutProductLocationId = loc.warehouseOutProductLocationId
          return obj
        })

        this.currentRow.warehouseOutProductLocationDTOList = locs

        // this.currentRow.warehouseOutNum = totolCounts
        // this.currentRow.giftNum = giftCount
        // this.onChangewarehouseOutNum(this.currentRow)
      },
      onOutNumChange(count, index) {
        const obj = {
          ...this.tableData[index],
          warehouseOutNum: count,
        }
        this.tableData.splice(index, 1, obj)
        this.onChangePriceExcludingTax(this.currentRow)
      },
      async addLoc(index) {
        let row = this.tableData[index]
        this.currentRow = row
        let { warehouseOutProductLocationDTOList } = row
        const { skuId, warehouseOutNum, usable, lotNo, stockLotId, lotNumber } =
          row,
          { warehouseId } = this.form
        if (!warehouseId) {
          return this.$message.warning('请选择仓库')
        }

        if (!warehouseOutNum && warehouseOutNum === 0) {
          return this.$message.warning('请输入实际出库数量')
        }
        if (!lotNo) {
          return this.$message.warning('请选择入库批次')
        }

        let res = await OutboundSheetInteractor.listWarehouseLocationUsableNum({
          flag: this.flag,
          skuId,
          usable,
          warehouseId,
          lotNo,
          stockLotId,
        })
        // 设置回显
        if (warehouseOutProductLocationDTOList.length > 0) {
          // warehouseOutProductLocationDTOList
          res.data = res.data.map((item) => {
            let loc = warehouseOutProductLocationDTOList.find(
              (loc) => loc.locationNumber === item.locationNumber
            )
            if (loc) {
              item = { ...item, outNum: loc.num }
            }
            return item
          })
        }
        this.locParams.locs = res.data
        this.locParams.index = index
        this.locParams.lotNumber = lotNumber //入库批次数量
        this.locParams.show = true
      },
      onClose(isLoc) {
        if (isLoc) {
          return (this.locParams.show = false)
        }
        this.params.show = false
      },
      // 选择产品
      selectPro(val) {
        // 可用和不可用判断
        if (this.tableData.length > 0) {
          let usable = this.tableData[0].usable
          if (val.some((pro) => pro.usable !== usable)) {
            return this.$message.warning(
              '可用库存和不可用库存的产品不能同时选择'
            )
          }
        }

        val.forEach((item) => {
          if (!this.tableData.find((pro) => pro.skuId === item.skuId)) {
            this.tableData.push({
              ...baseProduct,
              ...item,
              stockNum: item[item.usable == 0 ? 'unusableNum' : 'usableNum'],
              price: 0,
              usableNum: '-', // 初始化
            })
          }
        })
        this.resetCombineParams()
        this.formatTableData(this.tableData, 'stockLot')
        this.getSpanArr(this.tableData)
        this.usable = this.tableData[0].usable
      },
      $_toggleLoading() {
        this.isLoading = !this.isLoading
      },

      //审核模板返回数据-auditData
      async saveAuditClick(auditData) {
        this.loading = true
        auditData.orderId = this.$route.query.warehouseOutId //单据id
        const { type, typeName } = this.currentType
        let tableData = JSON.parse(JSON.stringify(this.tableData))
        const requestData = {
          fileUrls: this.files,
          operationId: this.form.operationId,
          operationName: this.form.operationName,
          orderNumber: this.form.orderNumber,
          remark: this.form.remark,
          supplierName: this.form.supplierName,
          type,
          typeName,
          warehouseId: this.form.warehouseId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseOutId: this.form.warehouseOutId,
          warehouseOutProductDTOList: tableData.map((item) => {
            item.warehouseOutNum = item.lotNumber
            item.num = item.lotNumber
            item.warehouseOutLotVOList = item.stockLot
            return item
          }),
          createWay: 0, //
          auditObjectDTO: auditData,
          area: this.userInfo.area,
        }
        if (this.isEdit) {
          //处理编辑任务参数
          requestData.warehouseOutProductDTOList &&
            requestData.warehouseOutProductDTOList.forEach((item) => {
              item.warehouseOutProductLocationDTOList.forEach((j) => {
                j.warehouseInProductId = item.warehouseInProductId
                j.warehouseId = requestData.warehouseId
                j.skuId = item.skuId
              })
            })
          requestData.warehouseOutNumber = this.form.warehouseOutNumber
        }
        const requestMethod = this.isEdit
          ? 'updateWarehouseIn'
          : 'insertWarehouseOut'
        let res = await OutboundSheetInteractor[requestMethod](requestData)
        if (res && res.code === '000000') {
          this.$message.success(
            this.isEdit ? '更新出库单成功' : '新增出库单成功'
          )
          this.$router.back()
        }
        this.loading = false
        this.$_toggleLoading()
      },

      //确认
      async onSubmit() {
        let flag = false
        this.$refs.form.validate((validate) => {
          if (validate) {
            flag = true
          }
        })

        if (!flag) {
          return
        }

        if (!this.tableData || this.tableData.length === 0) {
          return this.$message.warning('请选择产品')
        }

        if (this.$_checkProduct()) {
          return
        }
        if (this.ruleType == '1') {
          if (!this.$_checkEmptyLoc()) {
            return
          }
          if (!this.$_checkCount()) {
            return
          }
        }

        let params = {
          auditType: 5, //出库5
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if (!response.data.length) {
            return this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else {
            this.$refs['AuditChoose'].showAddEdit(params)
          }
        })
      },
      initData() {
        this.currentType = this.typeNames[0]
        this.form.operationName = this.userInfo.userName
        this.form.operationId = this.form.operationId
        this.form.creatorArea = this.userInfo.area
        this.form.creator = this.userInfo.userName
      },
      resetFormAndTableDataWhenTypeChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key !== 'warehouseOutId' &&
            key !== 'warehouseOutNumber' &&
            key != 'creatorArea' &&
            key != 'creator'
          ) {
            this.form[key] = ''
          }
        })
        this.tableData = []
      },
      // 切换出库时重置
      resetFormAndTableDataWhenWHChanged() {
        Reflect.ownKeys(this.form).forEach((key) => {
          if (
            key !== 'type' &&
            key !== 'operationName' &&
            key !== 'warehouseId' &&
            key != 'creatorArea' &&
            key != 'creator'
          ) {
            this.form.orderNumber = ''
          }
        })
        this.tableData = []
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
    },
  }
</script>

<style scoped lang="scss">
  .lot-wrapper {
    display: flex;
    align-items: center;

    .el-input {
      flex: 1;
    }

    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 20px;
      color: rgb(40, 130, 255);
    }
  }

  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mb15 {
    margin-bottom: 15px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .table-container {
    padding: 0 15px 20px !important;
  }

  .operation {
    display: flex;
    justify-content: space-evenly;

    i {
      font-size: 20px;
    }
  }

  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }

  .pointer {
    cursor: pointer;
  }

  ::v-deep .el-input-number--small {
    width: 100px !important;
  }

  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }

  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }

  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }
</style>

<style>
  /* .outbound .el-select-dropdown__item {
  width: 200px;
} */
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
