<!--
  功能：收款通知组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年11月16日 20:00:00
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <IndexCard
    title="收款通知"
    :tabs="tabs"
    @tab-click="handleTabClick"
    :loading="loading"
    :singleNum="singleNum"
    :teamNum="teamNum"
  >
    <div v-if="list.length > 0"
      ref="scrollContainer"
      class="infinite-scroll"
      @scroll="onScroll"
      >
      <template v-for="(item, index) in list">
        <IndexCardItem :key="index" class="collection-notice-item">
          <div
            class="content"
            @click="clientFollowClick(item)"
            :style="itemStyleObj"
          >
            <div class="mails-item--top">
              订单
              <span class="mail-code" :style="boldStyle.bold">
                {{ item.orderCode }}
              </span>
              已到达
              <span class="mail-code" :style="boldStyle.bold">
                {{ item.pay ? item.pay : '--' }}
              </span>
              收款节点
            </div>
            <div class="mails-item--bottom">
              <p>
                <span class="label">客户代表:</span>
                <span class="content">{{ item.businessName }}</span>
              </p>
              <p>
                <span class="label">订单支持:</span>
                <span class="content">
                  {{ item.fromBusinessName ? item.fromBusinessName : '--' }}
                </span>
              </p>
              <p>
                <span>{{ item.createTime }}</span>
              </p>
            </div>
          </div>
        </IndexCardItem>
      </template>
    </div>
    <IndexCardEmpty v-else></IndexCardEmpty>
  </IndexCard>
</template>

<script>
  import mixins from '../mixins'
  import { HomeInteractor } from '@/core'

  export default {
    name: 'CollectionNotice',
    mixins: [mixins],
    props: {},
    // 组件状态值
    data() {
      return {
        list: [],
        tabs: true
      }
    },
    computed: {},

    methods: {
      onScroll(event) {
        const scrollContainer = event.target
        const scrollTop = scrollContainer.scrollTop
        const scrollHeight = scrollContainer.scrollHeight
        const clientHeight = scrollContainer.clientHeight
        if (scrollTop + clientHeight >= scrollHeight) {
          if (this.active === 'ME') {
            if (Math.ceil(this.singleNum / this.pageLe) > this.pageNo) {
              this.pageNo++
              this.getList()
            }
          } else {
            if (Math.ceil(this.teamNum / this.pageLe) > this.pageNo) {
              this.pageNo++
              this.getList()
            }
          }
        }
      },
      getList() {
        this.loading = true
        const requestData = {
          authFlag: this.active === 'ME' ? 0 : 1,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          tenantId: this.userInfo.tenantId,
          userId: this.userInfo.userId,
        }
        HomeInteractor.listHomeOrder(requestData).then((res) => {
          if (res && res.code === '000000') {
            const { pageVO = {}, singleNum, teamNum } = res.data || {}
            this.singleNum = singleNum || 0
            this.teamNum = teamNum || 0
            this.list = pageVO?.data?.length ? [...this.list, ...pageVO.data] : [...this.list]
          } else {
            this.list = []
          }
          this.loading = false
        })
      },

      clientFollowClick(item) {
        let canClick = this.beforeListItemClick()
        if (canClick) {
          let url = `${window.location.origin}/#/write?from=homepage&email=${item.email}`
          this.utils.openWindow(url)
        }
      },
    },

    created() {
      this.getList()
    },
  }
</script>

<style scoped lang="scss">
.infinite-scroll {
    height: 100%;
    overflow-y: auto;
  }
  .collection-notice-item {
    font-size: 14px;
    .mails-item--top {
      margin-bottom: 6px;
      color: #000;
      .mail-code {
        font-family: Semibold;
      }
      .sender {
        font-family: Regular;
      }
      .topic {
        font-family: Semibold;
      }
    }
    .mails-item--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      p {
        color: #5e5e5e;
        font-family: Regular;
        .label {
          margin-right: 5px;
        }
      }
    }
  }
</style>
