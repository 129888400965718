<template>
  <el-form :model="tableData" ref="print_form" class="print-form-table">
    <el-table
      :key="tableKey"
      :data="tableData.productPrintingFullValuationDTOList"
      style="width: 100%; border-top: none"
      :span-method="objectSpanMethod"
      ref="table"
      border
    >
      <el-table-column
        align="center"
        :label="$t('productDetail.PrintingPosition')"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.printingPositionNum }}-location</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('productDetail.PricingMethod')"
        width="120"
      >
        <template slot-scope="scope">
          <span>
            {{
              lang === 'zh'
                ? scope.row.valuationMethod
                : scope.row.valuationMethodEn
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="132">
        <template slot="header">
          <div>
            <p class="required-symbol">
              {{ $t('productDetail.ProductivityPerUnitTime') }}
            </p>
            <div class="select-box">
              <el-select
                v-model.sync="tableData.capacityUnit"
                @change="timeCapacityFn"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="lang === 'zh' ? item.nameCn : item.nameEn"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-tooltip
                v-if="
                  tableData.capacityUnit != 3 && tableData.capacityUnit != 4
                "
                class="item"
                effect="light"
                :content="$t('productDetail.capacityUnitTip')"
                placement="bottom"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="
              'productPrintingFullValuationDTOList.' +
              scope.$index +
              '.capacity'
            "
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="scope.row.capacity"
              :placeholder="$t('placeholder.plsInput')"
              @change="rowChangeFn($event, scope.row, scope.$index, 1)"
              oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" width="120">
        <template slot="header">
          <div>
            <p class="required-symbol">
              {{ $t('productDetail.FinishedProductCostPrice') }}
            </p>
            <p>（{{ $t('productDetail.CNUnit') }}）</p>
          </div>
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="
              scope.$index === 0
                ? 'productPrintingFullValuationDTOList.' +
                  scope.$index +
                  '.price'
                : ''
            "
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="scope.row.price"
              maxlength="20"
              :disabled="scope.$index !== 0"
              :placeholder="
                scope.$index === 0 ? $t('placeholder.plsInput') : ''
              "
              @change="rowChangeFn($event, scope.row, scope.$index, 2)"
              @input="handleInput($event, scope.row)"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" width="120">
        <template slot="header">
          <div>
            <p class="required-symbol">
              {{ $t('productDetail.EditionFeeOther') }}
            </p>
          </div>
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="
              'productPrintingFullValuationDTOList.' +
              scope.$index +
              '.publishFee'
            "
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="scope.row.publishFee"
              :placeholder="$t('placeholder.plsInput')"
              oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('productDetail.QuantityLadder')"
      >
        <el-table-column
          v-for="(item, index) in numberStepListClomun"
          :key="index + 1000"
          :label="item + ''"
          align="center"
        >
          <el-table-column align="center" width="120">
            <template slot="header">
              <div>
                <p class="required-symbol">
                  {{ $t('productDetail.ProfitRate') }}
                </p>
              </div>
            </template>
            <template slot-scope="scope">
              <el-form-item
                class="profit-rate"
                :prop="
                  'productPrintingFullValuationDTOList.' +
                  scope.$index +
                  '.profitRate' +
                  index
                "
                label-width="0"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  type="number"
                  v-model="scope.row['profitRate' + index]"
                  v-prevent-mousewheel
                  @change="
                    rowChangeFn($event, scope.row, scope.$index, 3, index)
                  "
                  oninput="if(value > 99.99999){value=99.99999}if(value < -99.99999){value=-99.99999}if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+6)}"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" :prop="'price' + index" width="100">
            <template slot="header">
              <div>
                <p>{{ $t('productDetail.FinishedProduct') }}</p>
                <p>{{ $t('productDetail.Price') }}($)</p>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content">
                  <p>= {{ $t('productDetail.FinishedProductTips') }}</p>
                  <p v-if="scope.row['profitRate' + index]">
                    =（{{ Number(scope.row.price) }} ÷ {{ exchange }} +
                    {{ scope.row['transportHeadPrice' + index] || 0 }}）÷（1 -
                    {{
                      Number(scope.row['profitRate' + index]) > 0
                        ? scope.row['profitRate' + index] + '%'
                        : '(' + scope.row['profitRate' + index] + '%)'
                    }}
                    ）
                  </p>
                  <p v-else>
                    =（{{ Number(scope.row.price) }} ÷ {{ exchange }} +
                    {{ scope.row['transportHeadPrice' + index] || 0 }}）÷（1 -
                    0%）
                  </p>
                  <p
                    v-if="
                      scope.row['price' + index] &&
                      scope.row['price' + index] != 'NaN' &&
                      sortResult[index] &&
                      sortResult[index] != 'NaN' &&
                      scope.row['price' + index] != sortResult[index]
                    "
                    style="color: #ff4d4f"
                  >
                    Unit Price for Additional Imprint ${{
                      scope.row['price' + index]
                    }}
                    - ${{ sortResult[index] }} = ${{
                      (scope.row['price' + index] - sortResult[index]).toFixed(
                        toFixedNumber
                      )
                    }}
                  </p>
                </div>
                <span>
                  {{
                    scope.row['price' + index] &&
                    scope.row['price' + index] != 'NaN'
                      ? scope.row['price' + index]
                      : ''
                  }}
                  <i
                    v-if="
                      scope.row['price' + index] &&
                      scope.row['price' + index] != 'NaN'
                    "
                    class="el-icon-warning-outline"
                    style="color: #909399"
                  ></i>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import Decimal from 'decimal.js'
  export default {
    data() {
      return {
        tableKey: 0,
        rules: {
          capacity: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        options: [
          {
            value: 0,
            nameCn: '个/秒钟',
            nameEn: 'Unit/Second',
          },
          {
            value: 1,
            nameCn: '个/分钟',
            nameEn: 'Unit/Minute',
          },
          {
            value: 2,
            nameCn: '个/小时',
            nameEn: 'Unit/Hour',
          },
          {
            value: 3,
            nameCn: '个/天(8小时)',
            nameEn: 'Unit/Day(8-hour)',
          },
          {
            value: 4,
            nameCn: '个/天(12小时)',
            nameEn: 'Unit/Day(12-hour)',
          },
        ],
        rowspanVal: 1,
        sortResult: [],
      }
    },
    props: {
      pricingTableData: {
        type: Object,
        required: true,
      },
      numberStepList: {
        type: Array,
        required: true,
      },
      transportDay: {
        type: String | Number,
        default: 0,
      },
      exchange: {
        type: Number,
        default: 0,
      },
      toFixedNumber: {
        type: Number,
        default: 2,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      tableData: {
        get() {
          return this.pricingTableData
        },
        set(val) {
          this.$emit('undate:pricingTableData', val)
        },
      },
      numberStepListClomun() {
        this.tableKey++
        return this.numberStepList.map((item) => item.num)
      },
    },
    created() {
      this.rowspanVal = this.tableData?.valuationMethodIds?.length
    },
    watch: {
      // 小数位改变
      toFixedNumber(val) {
        let sum = 0
        this.tableData.productPrintingFullValuationDTOList.map(
          (item, index) => {
            if (index === 0) {
              item.price = new Decimal(item.price)
                .toDecimalPlaces(val)
                .toNumber()
                .toFixed(val)
              sum = new Decimal(item.price)
                .mul(new Decimal(item.capacity))
                .toNumber()
            } else {
              item.price = new Decimal(sum)
                .div(new Decimal(item.capacity || 0))
                .toDecimalPlaces(val)
                .toNumber()
                .toFixed(val)
            }
          }
        )
        this.tableData.productPrintingFullValuationDTOList.map((row, index) => {
          this.numberStepList.forEach((k, idx) => {
            row['price' + idx] = this.calculationFinishedProduct(
              row.price,
              row['transportHeadPrice' + idx],
              row['profitRate' + idx]
            )
          })
          return row
        })
      },
      // 计价方式改变重新合并单元格
      'tableData.valuationMethodIds'(val) {
        this.$nextTick(() => {
          this.rowspanVal = val.length
          this.tableKey++
        })
      },
      'tableData.transportDay'(val) {
        this.tableData.productPrintingFullValuationDTOList.map(
          (item, index) => {
            this.numberStepList.forEach((value, index) => {
              item['deliverDay' + index] =
                Number(item['printingDay' + index]) + Number(val)
            })
            return item
          }
        )
        this.tableKey++
      },
      'tableData.productPrintingFullValuationDTOList': {
        handler(val) {
          let arr = []
          val.forEach((item) => {
            let subArr = []
            this.numberStepList.forEach((sub, subIndex) => {
              subArr.push(item['price' + subIndex])
            })
            arr.push(subArr)
          })
          const arrSort = arr.reduce((acc, curr) => {
            curr.forEach((value, index) => {
              if (!Array.isArray(acc[index])) {
                acc[index] = []
              }
              acc[index].push(value)
            })
            return acc
          }, [])
          this.sortResult = arrSort.map((subArr) => {
            let min = undefined
            for (const value of subArr) {
              if (
                (typeof value === 'number' && value !== 0) ||
                (typeof value === 'string' && value.trim() !== '')
              ) {
                if (min === undefined || value * 1 < min) {
                  min = value * 1
                }
              }
            }
            return min // 返回最小的非零且非空元素
          })
        },
        deep: true, // 启用深度监听
      },
    },
    methods: {
      //成品价格计算（成本价 ÷ 汇率 + 运费单价）÷ (1 - 利润率)
      calculationFinishedProduct(price, transportHeadPrice, profitRate) {
        price = Number(price)
        transportHeadPrice = Number(transportHeadPrice)
        profitRate = Number(profitRate)
        let sum1 =
          !price || !this.exchange
            ? transportHeadPrice
            : new Decimal(price)
                .div(new Decimal(this.exchange))
                .add(new Decimal(transportHeadPrice))
        let sum2 = profitRate
          ? new Decimal(1).sub(new Decimal(profitRate).div(new Decimal(100)))
          : 1
        // Math.pow(10, this.toFixedNumber): 这部分是将 10 的幂运算，其中 this.toFixedNumber 是您希望保留的小数位数。例如，如果 this.toFixedNumber 是 2，那么这部分就是计算 10 的 2 次方，即 100。
        // sum * Math.pow(10, this.toFixedNumber): 这一步将您计算出的 sum 值乘以上面计算的 10 的幂，这样可以将小数点向右移动 this.toFixedNumber 位，将小数部分转换为整数部分，以便于使用 Math.round() 进行四舍五入。
        // Math.round(sum * Math.pow(10, this.toFixedNumber)): 这是 Math.round() 方法，它将上一步得到的结果进行四舍五入。
        // / Math.pow(10, this.toFixedNumber): 最后，这一步将得到的结果除以 10 的 this.toFixedNumber 次方，将小数点移到正确的位置，恢复到原来的小数位数。
        let sum = new Decimal(sum1).div(new Decimal(sum2)).toNumber()
        sum =
          Math.round(
            new Decimal(sum).mul(new Decimal(Math.pow(10, this.toFixedNumber)))
          ) / Math.pow(10, this.toFixedNumber)
        return sum
      },
      handleInput(value, row) {
        const num = Number(this.toFixedNumber)
        const sanitizedValue = value.replace(/[^0-9.]/g, '')
        const regex = new RegExp(`^-?\\d*\\.?\\d{0,${num}}`)
        const match = sanitizedValue.match(regex)
        let newValue = match ? match[0] : ''
        if (newValue.indexOf('.') !== -1) {
          const parts = newValue.split('.')
          newValue = parts[0] + '.' + parts[1].slice(0, num)
        }

        row.price = newValue
      },

      //表格值改变去计算
      rowChangeFn(val, row, rowIndex, type, profitRateIndex) {
        const obj = {
          1: () => {
            this.numberStepList.forEach((value, index) => {
              row['printingDay' + index] = this.generationTimeFn(
                this.tableData.capacityUnit,
                val,
                value.num
              )
              row['printingFrontDay' + index] = this.generationTimeFn(
                this.tableData.capacityUnit,
                val,
                value.num
              )
              row['deliverDay' + index] =
                Number(row['printingDay' + index]) + this.tableData.transportDay
            })
            if (rowIndex === 0) {
              if (!row.price) return
              const cardinality = val
                ? new Decimal(val).mul(new Decimal(row.price))
                : ''
              this.tableData.productPrintingFullValuationDTOList.map(
                (item, index) => {
                  if (index !== 0) {
                    if (!item.capacity) {
                      item.price = 0
                    } else {
                      item.price = cardinality
                        ? new Decimal(cardinality)
                            .div(new Decimal(item.capacity))
                            .toDecimalPlaces(this.toFixedNumber)
                            .toNumber()
                            .toFixed(this.toFixedNumber)
                        : ''
                    }
                  }
                  this.numberStepList.forEach((value, index) => {
                    if (
                      (!item['profitRate' + index] && item['profitRate' + index] != 0) ||
                      Number(item['profitRate' + index]) == 100
                    ) {
                      item['price' + index] = 0
                    } else {
                      item['price' + index] = this.calculationFinishedProduct(
                        item.price,
                        item['transportHeadPrice' + index],
                        item['profitRate' + index]
                      )
                    }
                  })
                  return item
                }
              )
              return
            }
            // 计算成品成本价
            const cardinality =
              this.tableData.productPrintingFullValuationDTOList?.[0]
                ?.capacity &&
              this.tableData.productPrintingFullValuationDTOList?.[0]?.price
                ? new Decimal(
                    this.tableData.productPrintingFullValuationDTOList?.[0]?.capacity
                  ).mul(
                    new Decimal(
                      this.tableData.productPrintingFullValuationDTOList?.[0]?.price
                    )
                  )
                : ''
            row.price = cardinality
              ? new Decimal(cardinality)
                  .div(new Decimal(val))
                  .toDecimalPlaces(this.toFixedNumber)
                  .toNumber()
                  .toFixed(this.toFixedNumber)
              : ''
            this.numberStepList.forEach((value, index) => {
              if (
                (!row['profitRate' + index] && row['profitRate' + index] != 0) ||
                Number(row['profitRate' + index]) == 100
              ) {
                row['price' + index] = 0
              } else {
                row['price' + index] = this.calculationFinishedProduct(
                  row.price,
                  row['transportHeadPrice' + index],
                  row['profitRate' + index]
                )
              }
            })
          },
          2: () => {
            if (!row.capacity) return
            const cardinality = new Decimal(row.capacity || 0).mul(
              new Decimal(val || 0)
            )
            this.tableData.productPrintingFullValuationDTOList.map(
              (item, index) => {
                if (index !== 0) {
                  item.price = item.capacity
                    ? new Decimal(cardinality)
                        .div(new Decimal(item.capacity))
                        .toDecimalPlaces(this.toFixedNumber)
                        .toNumber()
                        .toFixed(this.toFixedNumber)
                    : ''
                }
                this.numberStepList.forEach((value, index) => {
                  if (
                    (!item['profitRate' + index] && item['profitRate' + index] != 0) ||
                    Number(item['profitRate' + index]) == 100
                  ) {
                    item['price' + index] = 0
                  } else {
                    item['price' + index] = this.calculationFinishedProduct(
                      item.price,
                      item['transportHeadPrice' + index],
                      item['profitRate' + index]
                    )
                  }
                })
                return item
              }
            )
          },
          3: () => {
            if ((!val && val != 0 )|| Number(val) == 100) {
              row['price' + profitRateIndex] = 0
            } else {
              row['price' + profitRateIndex] = this.calculationFinishedProduct(
                row.price,
                row['transportHeadPrice' + profitRateIndex],
                val
              )
            }
          },
        }
        obj[type]()
      },
      // 秒、分、小时向上取值转换天8小时
      generationTimeFn(type, num, totalNum) {
        const obj = {
          0: () => {
            const dayNUm = Number(num) * 60 * 60 * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          1: () => {
            const dayNUm = num * 60 * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          2: () => {
            const dayNUm = num * 8
            return dayNUm ? Math.ceil(Number(totalNum) / dayNUm) : ''
          },
          3: () => {
            return num ? Math.ceil(Number(totalNum) / Number(num)) : ''
          },
          4: () => {
            return num ? Math.ceil(Number(totalNum) / Number(num)) : ''
          },
        }
        if (obj[type]) {
          return obj[type]()
        } else {
          return 0
        }
      },
      // 单位时间产能改变时
      timeCapacityFn(val) {
        this.tableData.productPrintingFullValuationDTOList.map(
          (item, index) => {
            this.numberStepList.forEach((value, index) => {
              item['printingDay' + index] = this.generationTimeFn(
                this.tableData.capacityUnit,
                item.capacity,
                value.num
              )
              item['printingFrontDay' + index] = this.generationTimeFn(
                this.tableData.capacityUnit,
                item.capacity,
                value.num
              )
              item['deliverDay' + index] =
                Number(item['printingDay' + index]) +
                Number(this.tableData.transportDay)
            })
            return item
          }
        )
        this.tableKey++
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex % this.rowspanVal === 0) {
            return {
              rowspan: this.rowspanVal,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .required-symbol {
    &::before {
      content: '*';
      color: #ff4d4f;
    }
  }

  .profit-rate {
    &::after {
      content: '%';
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    .el-input__inner {
      padding: 0 18px 0 15px;
    }
  }

  .print-form-table {
    ::v-deep.el-table {
      .el-table__header {
        border-top: 1px solid #ebeef5;
      }
    }
  }
  .select-box {
    display: flex;
    align-items: center;
    ::v-deep.el-tooltip {
      margin-left: 2px;
    }
  }
</style>
