<template>
  <el-dialog
    title="备货单详情"
    :visible.sync="purchaseVisible"
    width="1200px"
    append-to-body
  >
    <div class="custom-dialog">
      <el-row :gutter="20">
        <el-col :span="12">
          <h4>阐述备货原因</h4>
          <p>{{ detail.stockUpReason }}</p>
        </el-col>
        <el-col :span="12">
          <h4>销售运营计划</h4>
          <p>{{ detail.salesPlan }}</p>
        </el-col>
      </el-row>

      <h4>产品列表</h4>
      <div class="c-table-wrap">
        <table class="custom-table" style="table-layout:fixed;">
          <thead>
            <tr>
              <th class="index" width="50px">序号</th>
              <th width="180">产品编号</th>
              <th class="more" width="180">产品名称</th>
              <th width="180">英文规格</th>
              <th width="200">中文规格</th>
              <th width="150">库存可用量</th>
              <th  width="150">需求数量</th>
              <th  width="180">采购单价(元)</th>
              <th  width="180">采购预算总成本</th>
              <th  width="180">采购开发</th>
              <th  width="180">订单支持</th>
              <th  width="150">月询盘数量</th>
              <th  width="150">月订单数量</th>
              <th  width="150">月订单金额($)</th>
            </tr>
          </thead>
          <tbody v-if="productList.length">
            <tr v-for="(item, i) in productList" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.sku }}</td>
              <td>{{ item.nameCn }}</td>
              <td>
                <el-row v-if="item.specsValue1 || item.specsValue2">
                  <span v-if="item.specsValue1" style="word-break: break-word;">{{ item.specsValue1 }}</span>
                  <span v-if="item.specsValue2"  style="word-break: break-word;">，{{ item.specsValue2 }}</span>
                </el-row>
                <span v-else>--</span>
              </td>
              <td>
                <el-row v-if="item.specsValueCn1 || item.specsValueCn2">
                  <span v-if="item.specsValueCn1">{{ item.specsValueCn1 }}</span>
                  <span v-if="item.specsValueCn2">，{{ item.specsValueCn2 }}</span>
                </el-row>
                <span v-else>--</span>
              </td>
              <td>
                {{ formatStock(item.stockWarehouseNumVOList) }}
              </td>
              <td>{{ item.numDemand }}</td>
              <td>{{ item.costPrice }}</td>
              <td>
                {{ utils.moneyFormat(item.costPrice * item.numDemand) }}
              </td>
              <td>{{ item.buyerName }}</td>
              <td>{{ item.fromBusinessName }}</td>
              <td>{{ item.enquiryMonthNum }}</td>
              <td>{{ item.orderMonthNum }}</td>
              <td>{{ item.orderMonthAmount }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="99">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-right mt10 mb10">
        总数量：
        <span class="gray f_s_16 mr20">{{ amount }}</span>
        总成本：
        <span class="gray f_s_16">{{ utils.moneyFormat(costAmount) }}</span>
      </div>

      <div class="detail-style">
        <div class="line">
          <div class="item">
            <em>期望交期</em>
            <span>{{ detail.expectedDeliveryDate || '--' }}</span>
          </div>
          <div class="item">
            <em>收货地址</em>
            <span>{{ detail.receivingAddress || '--' }}</span>
          </div>
          <div class="item">
            <em>备注</em>
            <span>{{ detail.remark || '--' }}</span>
          </div>
          <div class="item">
            <em>创建人</em>
            <span>{{ detail.creator || '--' }}</span>
          </div>
          <div class="item">
            <em>创建时间</em>
            <span>{{ detail.createTime || '--' }}</span>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="line">
          <div class="item">
            <em>审核单号</em>
            <p>{{ detail.auditNumber || '--' }}</p>
            <p class="small">
              审核通过时间: {{ utils.cutDate(detail.auditDate) || '--' }}
            </p>
          </div>
          <div class="item">
            <em>采购订单编号</em>
            <p
              v-if="
                detail.purchaseOrderNumber &&
                detail.purchaseOrderNumber.includes(',')
              "
            >
              {{
                Array.from(new Set(detail.purchaseOrderNumber.split(','))).join(
                  ','
                ) || '--'
              }}
            </p>
            <p v-else>{{ detail.purchaseOrderNumber }}</p>
            <p class="small">
              设置人：{{ detail.updateBuyerName }}&nbsp;&nbsp;设置时间:
              {{ utils.cutDate(detail.updateBuyerTime) }}
            </p>
          </div>
        </div>
      </div>

      <div class="text-center mt20">
        <el-button @click="purchaseVisible = false">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
export default {
  data() {
    return {
      amount: 0,
      costAmount: 0,
      productList: [],
      detail: {},
      purchaseVisible: false,
    }
  },
  mounted() {},
  methods: {
    // 初始化加载
    async initLoading(id) {
      const res = await purchaseDemandInterator.purchasingDGetDetailForShow(id)

      if (res && res.code == '000000') {
        this.detail = res.data ? res.data : {}
        this.productList = res.data.purchasingDemandProductShowVOList || []
        if (this.productList.length) {
          let arr = [],
            costArr = []
          this.productList.forEach((item) => {
            arr.push(Number(item.numDemand))
            costArr.push(Number(item.numDemand * item.costPrice))
          })
          this.amount = arr.reduce((p, v) => p + v)
          this.costAmount = costArr.reduce((p, v) => p + v)
        }
        this.purchaseVisible = true
      }
    },

    // 备货单详情采购开发回显
    buyerNameFormat(data) {
      if (data) {
        const arr = []
        data.forEach((item) => (item.buyerName ? arr.push(item.buyerName) : ''))
        return Array.from(new Set(arr)).toString()
      } else {
        return '--'
      }
    },

    // 格式化库存数据
    formatStock(stockData) {
      stockData = stockData || []
      let isArray = (stockData.constructor = Array)
      let __stockInfo = []

      if (isArray && stockData.length > 0) {
        for (let i = 0; stockData.length > i; i++) {
          __stockInfo.push(`${stockData[i].warehouseName}:${stockData[i].num}`)
        }
      } else {
        __stockInfo.push(`--`)
      }
      return __stockInfo.toString()
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .detail-style .line .item {
    min-width: 180px;
    em {
      padding: 10px 0 5px;
    }
  }
  .detail-style .line:last-child .item {
    min-width: 360px;
    p {
      padding: 0;
      font-size: 16px;
      color: #000;
    }
    p.small {
      font-size: 12px;
      color: #666;
    }
  }
  h4 {
    margin: 0 0 10px;
  }
  p {
    font-size: 16px;
    padding: 0 0 10px;
    line-height: 24px;
    color: #000;
  }
}
</style>
