<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2024年11月19日 10:50:11
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="pro-container">
    <el-button type="primary" @click="handleEdit">编辑值班</el-button>
    <div class="table-section">
      <div class="table-title">客服专员</div>
      <DutyTable :data="normalTableData" />
    </div>
    <div class="table-section">
      <div class="table-title">"重点客户"专属客服</div>
      <DutyTable :data="keyTableData" />
    </div>
    <!-- 编辑值班弹框 -->
    <EditDialog
      v-model="editDialogVisible"
      :tableData="currentEditData"
      :keyTableData="keyCurrentEditData"
      @refresh="getList"
    />
  </div>
</template>

<script>
  import EditDialog from './components/EditDialog'
  import DutyTable from './components/DutyTable'
  import { getTableData, subtractOneHour } from './utils'
  import { MailSettingInteractor } from '@/core'
  import { deepCopy } from 'kits'
  export default {
    name: 'DutyConfig',
    components: { EditDialog, DutyTable },
    data() {
      return {
        normalTableData: [],
        keyTableData: [],
        editDialogVisible: false,
        currentEditData: [],
        keyCurrentEditData: [],
      }
    },
    methods: {
      subtractOneHour,
      /**
       * 处理数据
       * @param {*} data
       * @param {*} isKey 是否是"重点客户"专属客服 1是 0否
       * @returns
       */
      processScheduleData(data, isKey) {
        if (!data) return null
        const processedData = deepCopy(data)
        processedData.isKey = isKey ? 1 : 0
        processedData.scheduleConfigVOList =
          processedData.scheduleConfigVOList?.map((item) => ({
            ...item,
            scheduleOrderVOList:
              item.scheduleOrderVOList?.map((scheduleItem) => ({
                ...scheduleItem,
                userInfos: [],
              })) || [],
          })) || []
        return processedData
      },

      /**
       * 获取值班数据
       */
      async getList() {
        try {
          const res = await MailSettingInteractor.mailUserScheduleQueryApi()
          if (res?.code !== '000000' || !Array.isArray(res?.data)) return

          // 根据isKey字段分类数据
          let normalData = res.data.find((item) => !item.isKey)
          let keyData = res.data.find((item) => item.isKey)

          // 处理数据缺失情况
          if (!normalData && keyData) {
            normalData = this.processScheduleData(keyData, 0)
          }
          if (!keyData && normalData) {
            keyData = this.processScheduleData(normalData, 1)
          }

          // 处理表格数据
          this.normalTableData = getTableData(
            normalData?.scheduleConfigVOList ?? []
          )
          this.keyTableData = getTableData(keyData?.scheduleConfigVOList ?? [])
        } catch (error) {
          console.error('获取值班数据失败:', error)
        }
      },

      /**
       * 编辑值班
       */
      handleEdit() {
        this.currentEditData = [...this.normalTableData]
        this.keyCurrentEditData = [...this.keyTableData]
        this.editDialogVisible = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pro-container {
    padding: 20px;

    .table-section {
      background: #fff;

      border-radius: 4px;

      .table-title {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin: 15px 0;
        position: relative;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .mt40 {
      margin-top: 40px;
    }
  }
</style>
