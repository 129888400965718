<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <el-col :span="20" class="page-header-left">
        <el-row type="flex" class="align_center flex_row_y flex_wrap top-search">
          <div v-show="!selectRows.length">
            <el-form ref="form" :inline="true" :model="queryForm" label-width="49px" @submit.native.prevent>
              <el-form-item>
                <el-input v-model="queryForm.number" placeholder="请输入销售单号/采购单号" clearable
                  @keyup.enter.native="initLoading(true)" />
              </el-form-item>
              <el-form-item>
                <el-select style="width: 180px" v-model="queryForm.supplierId" placeholder="请选择供应商" filterable
                  @change="initLoading(true)" clearable :filter-method="filterSupplier">
                  <el-option v-for="item in supplierList" :key="item.supplierId" :label="item.supplierName"
                    :value="item.supplierId"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-button class="mr10" v-show="!selectRows.length" @click="initLoading(true)">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            搜索
          </el-button>
          <el-row v-show="selectRows.length" type="flex" class="align_center">
            <p class="mr10">
              已选择
              <strong class="f_s_16">{{ selectRows.length }}</strong>
              个
            </p>
          </el-row>
          <el-button @click="highSearch">高级筛选</el-button>
          <template v-if="!isPad">
            <el-button @click="orderPay(1)" v-show="isOrderPay" v-allowed="['PURCHASE:PAY_REFUND_APPLY']">
              付款/退款申请
            </el-button>
            <el-button @click="orderRegister(1)" v-show="isOfferRegister" v-allowed="['PURCHASE:INVOICE_REGISTER']">
              发票登记
            </el-button>
            <el-button @click="orderCancel()" v-show="isOrderCancel" v-allowed="['PURCHASE:INVALID']">
              作废
            </el-button>
            <el-button @click="costApply()" v-show="isCost" v-allowed="['PURCHASE:FEEAPPLICATION']">
              费用申请
            </el-button>
          </template>
        </el-row>
        <p class="mt15" v-if="isPad">
          <el-button @click="orderPay(1)" v-show="isOrderPay" v-allowed="['PURCHASE:PAY_REFUND_APPLY']">
            付款/退款申请
          </el-button>
          <el-button @click="orderRegister(1)" v-show="isOfferRegister" v-allowed="['PURCHASE:INVOICE_REGISTER']">
            发票登记
          </el-button>
          <el-button @click="orderCancel()" v-show="isOrderCancel" v-allowed="['PURCHASE:INVALID']">
            作废
          </el-button>
          <el-button @click="costApply()" v-show="isCost" v-allowed="['PURCHASE:FEEAPPLICATION']">
            费用申请
          </el-button>
        </p>
      </el-col>
      <el-col :span="4" :class="{ self: isPad }">
        <el-row type="flex" justify="end">
          <!-- 自定义展示列 -->
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
                :disabled="item.checkable === false" :label="item.label">
                {{ item.label }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <el-button type="primary" class="ml10" size="mini" plain @click="operateSave()">
                保存
              </el-button>
            </div>
            <template #reference>
              <el-tooltip effect="dark" content="列表可拖动排序" placement="top-start">
                <el-button plain type="primary">自定义显示</el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </el-row>
      </el-col>
    </el-row>

    <el-radio-group class="mt15 mb15" v-model="queryForm.type" @change="initLoading(true)">
      <el-radio v-for="(item, i) in orderStatusList" :key="i" :label="item.value">
        {{ item.label }}
      </el-radio>
    </el-radio-group>
    <ErpTable :page-number.sync="page.pageNo" :page-size.sync="page.pageLe" :table-data="tableData" :total="total"
      :extraHeight="30" class="table-fixed" @query="getList" :key="customTableKey" @selection-change="setSelectRows"
      :default-sort="defaultSort" @sort-change="sortChange">
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="item.label"
        :show-overflow-tooltip="item.prop === 'supplierName' ? false : true" :width="item.width" min-width="120"
        :resizable="false" align="center" :prop="item.prop" :sortable="item.sortable ? 'custom' : false"
        :class-name="item.prop">
        <template slot="header" slot-scope="scope">
          <p v-if="item.prop === 'warehouseInDate'">
            <span>最后入库日期</span>
            <el-tooltip placement="top" effect="light">
              <div slot="content">产品全部入库，取最后的入库日期</div>
              <i class="el-icon-warning-outline c_pointer ml10"></i>
            </el-tooltip>
          </p>
          <span v-else>{{ item.label }}</span>
        </template>
        <template #default="{ row }">
          <div class="text-left dispose" v-if="item.prop === 'number'">
            <span class="page-link">
              <router-link :to="`../purchase/purchaseOrder/purchaseOrderDetail?id=${
                  row.purchaseOrderId
                }&updateFlag=${row.updateFlag ? row.updateFlag : 0}`">
                {{ row.number }}
              </router-link>
            </span>
            <el-tooltip placement="top" v-if="
                row.abnormalWarehousingStatus == 2 ||
                row.abnormalWarehousingStatus == 3
              ">
              <div slot="content" v-if="row.abnormalWarehousingStatus == 2">
                当前采购订单有待处理退款
                <span class="ml5 page-link" @click="disposeRefund(row.purchaseOrderId)">
                  立即处理
                </span>
              </div>
              <div slot="content" v-if="row.abnormalWarehousingStatus == 3">
                当前采购订单有待处理退款 审核中
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <!-- 采购需求单号 -->
          <div v-else-if="item.prop === 'purchasingDemandProductNumbers'">
            <div v-if="row.purchasingDemandProductNumbers" style="display: flex; align-items: center">
              <div style="white-space: pre-wrap; width: 160px">
                <p style="display: inline-block" class="page-link text-left" v-for="(num, i) in row.purchasingDemandProductNumbers.split(
                    ','
                  )" :key="i">
                  <span @click="
                      goPurchasingDetail(num, row.salesOrderIds.split(',')[i])
                    ">
                    {{ num }}
                  </span>
                </p>
              </div>

              <erp-mouseover-tip v-if="row.updateFlag || row.renderingStatus">
                <template name="content">
                  <div class="tip_content_bottom">
                    <span style="
                        white-space: pre-line;
                        display: inline-block;
                        min-width: 180px;
                      " v-if="row.renderingStatus && !row.updateFlag">
                      {{ row.renderingRemarks }}
                    </span>
                    <div v-if="row.updateFlag">
                      <h3>销售订单异常</h3>
                      <div>
                        <span style="margin-right: 5px; white-space: pre-wrap" v-html="row.updateRemark"></span>
                      </div>
                    </div>

                    <div style="margin-top: 10px; text-align: right" v-if="row.renderingStatus && !row.updateFlag">
                      <el-button style="display: inline-block" size="mini" @click="changeStatus(row)">
                        知道了
                      </el-button>
                    </div>
                  </div>
                </template>
              </erp-mouseover-tip>
            </div>
            <div v-else>-</div>
          </div>
          <!-- 效果图 -->
          <div v-else-if="item.prop === 'image'">
            <PreviewImage :Images="row.printingImgVOList"></PreviewImage>
          </div>
          <div v-else-if="item.prop === 'supplierName'">
            <el-tooltip placement="bottom" trigger="hover" effect="light">
              <div slot="content">
                <p>
                  账户信息：{{ row.bankName || '--' }} /
                  {{ row.bankAccount || '--' }} /
                  {{ row.accountName || '--' }} / {{ row.dutyAccount || '--' }}
                </p>
              </div>
              <el-row type="flex" align="middle" justify="center" class="supplierName-row">
                <el-tooltip class="item" effect="dark" :content="row.supplierName" placement="top">
                  <p class="supplierName-p">
                    <span>{{ row.supplierName || '--' }}</span>
                  </p>
                </el-tooltip>
                <el-row class="test-row" v-if="row.isAgreement == 1">
                  <el-tag type="danger" size="mini" class="ml5">协议</el-tag>
                </el-row>
              </el-row>
            </el-tooltip>
          </div>
          <div v-else-if="item.prop === 'amountTotal'">
            <template v-if="isViewCost">
              <el-popover placement="bottom" width="" trigger="click">
                <!--
                  需要进行单元格合并，合并项：交易记录、预计交易金额、预计交易时间

                  :span-method="
                    (...rest) => mergeRowByColumn(...rest, transferData, mergeList)
                  "
                 -->
                <el-table
                  border
                  :data="transferData"
                  :header-cell-style="{ textAlign: 'center' }"
                  max-height="500"
                >
                  <el-table-column width="180" label="交易记录" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.tradingRecordStr || '--' }}
                      <!-- <span v-if="scope.row.type == 0">
                        {{
                        '账期-' +
                        (scope.row.percent * 100).toFixed(0) +
                        '%' || 0 + '%'
                        }}
                        {{ scope.row.paymentNodeName }}
                      </span>
                      <span v-else>
                        {{ scope.row.paymentNodeName || '--' }}
                      </span> -->
                    </template>
                  </el-table-column>
                  <el-table-column width="120" label="预计交易金额" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.amountPayableStr || '--' }}
                      <!-- {{ scope.row.currency == '人民币' ? '￥' : '$'
                      }}{{ utils.numberFormat(scope.row.amountPayable, 2) }} -->
                    </template>
                  </el-table-column>
                  <el-table-column width="120" property="estimatedTransactionDate" label="预计交易时间" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.estimatedTransactionDateStr || '--' }}
                      <!-- {{
                      utils.cutDate(scope.row.estimatedTransactionDate) ||
                      '--'
                      }} -->
                    </template>
                  </el-table-column>
                  <el-table-column width="120" label="实际交易金额" align="center">
                    <template slot-scope="scope">
                      <span :style="{
                          color: scope.row.entryType == 1 ? 'red' : '',
                        }">
                        <span v-if="scope.row.entryType == 1">-</span>
                        {{ scope.row.currency == '人民币' ? '￥' : '$'
                        }}{{ scope.row.amountActual }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column width="120" property="confirmTime" label="实际交易时间" align="center">
                    <template slot-scope="scope">
                      {{ utils.cutDate(scope.row.confirmTime) || '--' }}
                    </template>
                  </el-table-column>
                  <el-table-column width="120" property="stateNote" label="交易状态" align="center">
                    <template slot-scope="scope">
                      <span v-if="scope.row.state == 1">处理中</span>
                      <span v-else-if="scope.row.state == 2">待确认</span>
                      <span v-else-if="scope.row.state == 3">已驳回</span>
                      <span v-else-if="scope.row.state == 4">已完成</span>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
                </el-table>
                <span class="page-link" slot="reference" @click="getTransferData(row.purchaseOrderId)">
                  {{ row.currency == '人民币' ? '￥' : '$'
                  }}{{ utils.numberFormat(row.amountTotal, 2) }}
                </span>
              </el-popover>
            </template>
            <NoViewCost v-else></NoViewCost>
          </div>
          <div v-else-if="item.prop === 'amountPaid'">
            <template v-if="isViewCost">
              {{ row.currency == '人民币' ? '￥' : '$'
              }}{{ utils.numberFormat(row.amountPaid, 2) }}
            </template>
            <NoViewCost v-else />
          </div>
          <!-- 未付金额（总金额-已付金额+历史金额） -->
          <div v-else-if="item.prop === 'unAmountPaid'">
            <template v-if="isViewCost">
              {{ row.currency == '人民币' ? '￥' : '$'
              }}{{
              utils.numberFormat(
              row.amountTotal - row.amountPaid + row.amountRefunded,
              2
              )
              }}
            </template>
            <NoViewCost v-else />
          </div>

          <div v-else-if="item.prop === 'amountInvoiced'">
            <template v-if="isViewCost">
              {{ row.currency == '人民币' ? '￥' : '$'
              }}{{ utils.numberFormat(row.amountInvoiced, 2) }}
            </template>
            <NoViewCost v-else />
          </div>
          <div v-else-if="item.prop === 'unAmountInvoiced'">
            <template v-if="isViewCost">
              {{ row.currency == '人民币' ? '￥' : '$'
              }}{{ utils.numberFormat(row.unAmountInvoiced, 2) }}
            </template>
            <NoViewCost v-else />
          </div>
          <div v-else-if="item.prop === 'state'">
            <el-tag v-if="row.state == 1" type="info">录入中</el-tag>
            <el-row v-else-if="row.state == 2">
              <el-tooltip placement="top" effect="light">
                <div slot="content">
                  <p>驳回原因：{{ row.auditReason || '--' }}</p>
                  <p>驳回时间：{{ row.auditTime || '--' }}</p>
                </div>
                <el-tag type="danger" class="c_pointer">已驳回</el-tag>
              </el-tooltip>
            </el-row>
            <el-row v-else-if="row.state == 3">
              <el-tooltip placement="top" effect="light">
                <div slot="content">
                  <p>撤销原因：{{ row.auditReason || '--' }}</p>
                  <p>撤销人：{{ row.auditName || '--' }}</p>
                  <p>撤销时间：{{ row.auditTime || '--' }}</p>
                </div>
                <el-tag type="info" class="c_pointer">已撤销</el-tag>
              </el-tooltip>
            </el-row>
            <el-tag v-else-if="row.state == 4" class="c_pointer" @click="statusTypeClick(row)">
              审核中
            </el-tag>
            <el-tag v-else-if="row.state == 5">采购中</el-tag>
            <el-row v-else-if="row.state == 6">
              <el-tooltip placement="top" effect="light">
                <div slot="content" class="tip-content" v-for="(item, index) of row.inspectionOrderShowVOList"
                  :key="index">
                  <p>验货员：{{ item.inspector || '--' }}</p>
                  <p>
                    验货单号：
                    <span @click="cargoDetail(item.inspectionOrderId, 'operate')" class="blue-text">
                      {{ item.number || '--' }}
                    </span>
                  </p>
                  <p>到货时间：{{ item.inspectionDate || '--' }}</p>
                </div>
                <el-tag type="primary" class="c_pointer">验货中</el-tag>
              </el-tooltip>
            </el-row>
            <el-row v-else-if="row.state == 7">
              <el-tooltip placement="top" effect="light">
                <div slot="content" class="tip-content">
                  <p>完成时间：{{ row.completeDate || '--' }}</p>
                </div>
                <el-tag type="success" class="c_pointer">已完成</el-tag>
              </el-tooltip>
            </el-row>
            <el-row v-else-if="row.state == 8">
              <el-tooltip placement="top" effect="light">
                <div slot="content" class="tip-content">
                  <p>作废原因：{{ row.deleteReason || '--' }}</p>
                  <p>操作人：{{ row.deleteName || '--' }}</p>
                  <p>操作时间：{{ row.deleteTime || '--' }}</p>
                </div>
                <el-tag type="info" class="c_pointer">已作废</el-tag>
              </el-tooltip>
            </el-row>
            <el-tag v-else-if="row.state == 9" type="danger">
              销售订单异常
            </el-tag>
            <el-tag v-else-if="row.state == 10" type="warning">
              供应商延期
            </el-tag>
            <el-tag v-else-if="row.state == 11" type="success">已入库</el-tag>
            <el-tag v-else-if="row.state == 12" type="danger">入库异常</el-tag>
          </div>
          <div v-else-if="item.prop === 'nodeNameCn'">
            <span class="colorblue" @click="showTrackingDetail(row)">
              {{ row.nodeNameCn }}
            </span>
          </div>
          <!--  -->
          <!-- 其他 -->
          <span v-else>{{ row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120" fixed="right" :resizable="false">
        <template slot-scope="scope">
          <el-tooltip content="打印" placement="top">
            <el-button v-show="isState(scope.row.state, [4, 5, 6, 7, 11, 12])" @click="
                printContract(
                  scope.row.purchaseOrderId,
                  scope.row.modelName,
                  scope.row.salesOrderIds.split(',')[0]
                )
              " type="primary" plain size="mini" icon="iconfont icon-dayin" circle></el-button>
          </el-tooltip>

          <el-dropdown>
            <el-button type="primary" plain icon="el-icon-more" circle style="padding: 8px"></el-button>
            <el-dropdown-menu slot="dropdown" placement="bottom">
              <el-dropdown-item v-allowed="['PURCHASE:PAY_REFUND_APPLY']">
                <p v-show="![1, 2, 3, 4].includes(scope.row.state)" @click="orderPay(2, scope.row)">
                  付款/退款申请
                </p>
              </el-dropdown-item>

              <el-dropdown-item v-allowed="['PURCHASE:INVOICE_REGISTER']">
                <p v-show="
                    isState(scope.row.state, [5, 6, 7, 11, 12]) &&
                    scope.row.unAmountInvoiced > 0 &&
                    scope.row.operatePurchaseOrderInvoice === '1' &&
                    scope.row.financeSetupDuty
                  " @click="orderRegister(2, scope.row.purchaseOrderId)">
                  发票登记
                </p>
              </el-dropdown-item>

              <el-dropdown-item>
                <p v-show="scope.row.state == 4" @click="revokeClick(scope.row)">
                  撤销
                </p>
              </el-dropdown-item>

              <el-dropdown-item>
                <p v-show="
                    scope.row.state != 4 &&
                    scope.row.state != 8 &&
                    scope.row.state != 12 &&
                    !scope.row.updateFlag == 1
                  " @click="
                    edit(
                      scope.row.purchaseOrderId,
                      scope.row.salesOrderIds,
                      scope.row
                    )
                  ">
                  编辑
                </p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </ErpTable>

    <!-- 撤销弹窗 -->
    <el-dialog title="撤销原因" :visible="cancelVisible" append-to-body width="500px" @close="onCancel"
      modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input type="textarea" :rows="3" placeholder="请输入撤销原因" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <el-button type="primary" class="ml30" @click="cancelSure">
          确 认
        </el-button>
        <el-button @click="onCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 作废 -->
    <el-dialog title="作废" :visible.sync="operatVisible" :close-on-click-modal="false" width="500px">
      <div class="offer-dialog">
        <div v-show="voidTable.length > 0">
          <p>Tip：存在以下单据，订单不允许作废</p>
          <el-table class="mt20" :data="voidTable" border :max-height="500">
            <el-table-column type="index" width="50" label="#" align="center"></el-table-column>
            <el-table-column align="center" label="单据号" prop="orderNumber"></el-table-column>
            <el-table-column align="center" label="类型" prop="codeDesc"></el-table-column>
          </el-table>
        </div>
        <div class="container" v-show="voidTable.length == 0">
          <el-form :model="operatForm" :rules="operatRules" ref="operatForm" label-width="60px">
            <el-form-item label="原因" prop="delReason">
              <el-input v-model="operatForm.delReason"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="text-center mt30">
          <el-button v-if="voidTable.length == 0" type="primary" @click="operatSave">
            确认
          </el-button>
          <el-button @click="operatCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 高级筛选Drawer -->
    <el-drawer title="高级筛选" :visible.sync="drawer" :before-close="highSearchClose">
      <!-- 采购订单 -->
      <orderHighSearch @cancelClick="highSearchClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 审核进度Drawer -->
    <el-drawer title="审核进度" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>

    <!-- 查看验货详情 -->
    <cargo-manage-detail ref="cargoManageDetail"></cargo-manage-detail>

    <!-- 批量付款申请 -->
    <order-payment ref="orderPayment" :title="title"></order-payment> 

    <!-- 发票登记 -->
    <order-register ref="orderRegister" @orderReg="getList"></order-register>

    <!-- 费用申请 -->
    <costApply ref="costApply" :isViewCost="isViewCost"></costApply>

    <!-- 备货单详情 -->
    <stockUp-detail ref="stockUp"></stockUp-detail>

    <!-- 处理退款 -->
    <dispose-refund ref="disposeRefund"></dispose-refund>
    <!-- 跟单进度 -->
    <TrackingNodes ref="tracking-nodes" @node-save-success="nodeSaveSuccess"></TrackingNodes>
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ErpDraggable from 'vuedraggable'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import OrderPayment from './components/orderPayment.vue'
  import orderRegister from './components/orderRegister.vue'
  import orderHighSearch from './components/orderHighSearch.vue'
  import cargoManageDetail from './../cargoManage/components/cargoManageDetail.vue'
  import costApply from '@/components/costApply'
  import stockUpDetail from './../purchaseDemand/components/stockUpDetail'
  import disposeRefund from './components/disposeRefund.vue'
  import CustomCache from '@/utils/custom-cache'
  import viewCost from './utils'
  import NoViewCost from './components/no-view-cost'
  import TrackingNodes from '@/components/tracking-nodes' // 和七月份跟单模块优化重复（代码没合），这里不做处理
  import customTableMixin from '@/utils/custom-table-mixin'
  import {
    PurchaseOrderInteractor,
    InboundSheet,
    OrderListInteractor,
  } from '@/core'
  import PreviewImage from '@/components/preview-image/index'
  import { getKeyIds } from '@/utils/pagelist-util'
  import { mergeRowByColumn } from 'kits'

  export default {
    name: 'PurchaseOrder',
    mixins: [customTableMixin],

    components: {
      Audit,
      ErpDraggable,
      cargoManageDetail,
      OrderPayment,
      orderRegister,
      orderHighSearch,
      costApply,
      stockUpDetail,
      disposeRefund,
      NoViewCost,
      TrackingNodes,
      PreviewImage,
    },
    inject: ['isPad'],
    data() {
      return {
        mergeList: [
          { column: 0, prop: 'tradingRecordStr' },
          { column: 1, prop: 'amountPayableStr' },
          { column: 2, prop: 'estimatedTransactionDateStr' },
        ],
        isViewCost: viewCost(), //查看成本
        queryForm: {
          type: 0,
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
        },
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        tableData: [],
        transferData: [],
        drawer: false, //高级筛选抽屉
        userCustomizeColumnId: '', //自义定保存id
        orderStatusList: [
          {
            label: '全部',
            value: 0,
          },
          {
            label: '待处理',
            value: 1,
          },
          {
            label: '审核中',
            value: 2,
          },
          {
            label: '采购中',
            value: 3,
          },
          {
            label: '验货中',
            value: 4,
          },
          {
            label: '已入库',
            value: 7,
          },
          {
            label: '已完成',
            value: 5,
          },
        ],
        auditObjectId: '', //审核id
        statusDrawer: false, //审核进度,
        auditType: 3,
        cancelVisible: false,
        cancelReason: '', //撤销原因
        selectRows: [], //table勾选
        supplierListAll: [], //全部供应商
        supplierList: [],
        isOrderPay: true,
        operatVisible: false,
        drawer: false, //高级筛选抽屉
        isOfferRegister: true,
        isOrderCancel: true,
        isCost: true,
        operatForm: {
          delReason: '',
        },
        operatRules: {
          delReason: [
            { required: true, message: '请输入原因', trigger: 'blur' },
            {
              max: 50,
              message: '长度不能超过50个字符',
              trigger: 'blur',
            },
          ],
        },
        columns: [
          {
            width: 130,
            label: '采购订单编号',
            prop: 'number',
            isShow: true,
            checkable: false,
            isSort: false,
          },
          {
            width: 180,
            label: '采购需求单号',
            prop: 'purchasingDemandProductNumbers',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '效果图',
            prop: 'image',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '买方',
            prop: 'purchaser',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '供应商名称',
            prop: 'supplierName',
            isShow: true,
            checkable: true,
            width: 180,
            isSort: false,
          },
          {
            label: '产品编号',
            prop: 'sku',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '产品名称',
            prop: 'nameCn',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '采购总量',
            prop: 'numTotal',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '总金额',
            prop: 'amountTotal',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '已付金额',
            prop: 'amountPaid',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '未付金额',
            prop: 'unAmountPaid',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '已开票金额',
            prop: 'amountInvoiced',
            isShow: true,
            isSort: false,
          },
          {
            label: '未开票金额',
            prop: 'unAmountInvoiced',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '采购状态',
            prop: 'state',
            isShow: true,
            checkable: false,
            isSort: false,
          },
          {
            label: '交货日期',
            prop: 'actualDeliveryDate',
            isShow: true,
            checkable: true,
            isSort: false,
            sortable: true,
            width: 200,
          },
          {
            label: '最后入库日期',
            prop: 'warehouseInDate',
            isShow: true,
            checkable: true,
            width: 180,
          },
          {
            label: '跟单进度',
            prop: 'nodeNameCn',
            isShow: true,
            checkable: true,
            isSort: false,
          },
          {
            label: '审核通过日期',
            prop: 'auditTime',
            isShow: false,
            checkable: true,
            isSort: false,
            sortable: true,
            width: 220,
          },
          {
            label: '采购开发',
            prop: 'buyerName',
            isShow: true,
            checkable: false,
            isSort: false,
          },
          {
            label: '创建人',
            prop: 'creator',
            isShow: false,
            checkable: true,
            isSort: false,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: false,
            checkable: true,
            isSort: false,
            sortable: true,
            width: 150,
          },
        ],
        title: '批量付款申请',
        TrackingNodesVisible: false, // 跟单节点是否可见
        voidTable: [], // 不可作废表格
        defaultSort: {}, // 默认排序
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        this.columns.forEach((item) => {
          if (item.isSort) {
            this.defaultSort = { prop: item.prop, order: item.sortType }
          }
        })
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    created() {
      this.getDict()
      // this.getColumsData()
    },
    activated() {
      this.getColumsData()
    },

    methods: {
      mergeRowByColumn,
      nodeSaveSuccess() {
        this.getList()
      },
      // 节点查看
      showTrackingDetail({ purchaseOrderId }) {
        OrderListInteractor.getTrackingNos(purchaseOrderId).then((res) => {
          if (res?.code === '000000') {
            if (!res.data || res.data.length === 0) {
              this.$message.warning('没有数据')
            } else {
              this.$refs['tracking-nodes'].initData(res.data)
            }
          }
        })
      },
      // 标题修改
      _toggleTitle(isBatch) {
        this.title = isBatch ? '批量付款/退款申请' : '付款/退款申请'
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(32, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'actualDeliveryDate') {
                    this.queryForm.sortName = 'actual_delivery_date'
                  } else if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'create_time'
                  } else if (item.prop === 'auditTime') {
                    this.queryForm.sortName = 'audit_time'
                  }
                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })

              this.initLoading()
            }
          })
          .catch(() => {
            this.initLoading()
          })
      },

      // 保存自定义显示列
      operateSave(showMessage) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 32,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            if (showMessage !== false) {
              return this.$baseMessage(
                '操作成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          }
        })
      },

      // 排序
      sortChange(column) {
        if (column.prop === 'actualDeliveryDate') {
          this.queryForm.sortName = 'actual_delivery_date'
        } else if (column.prop === 'createTime') {
          this.queryForm.sortName = 'create_time'
        } else if (column.prop === 'auditTime') {
          this.queryForm.sortName = 'audit_time'
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.initLoading(true)
      },

      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave(false)
      },

      getDict() {
        // 获取供应商字典项
        OrderListInteractor.supplierListForSelect().then((res) => {
          this.supplierListAll = res?.data ?? []
          // 为了防止大量数据渲染导致页面卡顿，默认只显示前10条
          this.supplierList = this.supplierListAll.filter((item, index) => {
            return index < 10
          })
        })
      },
      filterSupplier(keyword) {
        if (keyword.trim()) {
          this.supplierList = this.supplierListAll
            .filter((item, index) => {
              return item.supplierName.indexOf(keyword) > -1
            })
            .filter((item, index) => {
              return index < 10
            })
        } else {
          this.supplierList = this.supplierListAll.filter((item, index) => {
            return index < 10
          })
        }
      },

      // 初始化加载
      initLoading(pageReset) {
        if (pageReset) {
          this.page.pageNo = 1
          this.page.pageLe = 10
        }

        this.getList()
      },

      // 获取列表
      getList() {
        const params = {
          ...this.queryForm,
          ...this.page,
          keyIds: getKeyIds(this),
        }

        OrderListInteractor.purchaseListPage(params).then((res) => {
          if (res?.code === '000000') {
            const data = res?.data

            this.tableData = data?.data ? data.data : []
            this.tableData = this.tableData.map((item) => {
              return {
                ...item,
                updateRemark: item.updateRemark
                  ? item.updateRemark.replace(/\,/g, '')
                  : '',
                renderingRemarks: item.renderingRemarks
                  ? item.renderingRemarks.replace(/\,/g, '')
                  : '',
              }
            })
            this.total = data.total ? data.total : 0
          }

        })
        this.selectRows = []
      },

      // 获取交易记录
      getTransferData(id) {
        OrderListInteractor.listPaymentMethod({ businessId: id }).then(
          (res) => {
            if (res.data?.length) {
              this.transferData = res.data.filter(row => {
                const { type, percent, paymentNodeName, currency, amountPayable, estimatedTransactionDate } = row
                let tradingRecordStr = '' // 交易记录
                const estimatedTransactionDateStr = this.utils.cutDate(estimatedTransactionDate) // 预计交易时间
                if (type == 0) {
                  tradingRecordStr = '账期-' + (percent * 100).toFixed(0) + '%' || 0 + '%'
                } else {
                  tradingRecordStr = paymentNodeName
                }
                row.tradingRecordStr = tradingRecordStr // 交易记录
                const symbol = currency == '人民币' ? '￥' : '$'
                const amount = this.utils.numberFormat(amountPayable, 2)
                row.amountPayableStr = `${symbol}${amount}` // 预计交易金额
                row.estimatedTransactionDateStr = estimatedTransactionDateStr || '--' // 预计交易时间
                // 过滤掉hidden为1的数据
                return row.hidden !== 1
              })
            }
          }
        )
      },

      //审核进度查看
      statusTypeClick(row) {
        this.auditObjectId = row.purchaseOrderId
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 表格勾选事件
      setSelectRows(rows) {
        this.selectRows = rows
        if (this.selectRows.length) {
          let financeSetupId = this.selectRows[0].financeSetupId

          // 币种不同 发票登记不显示
          const currencys = [... new Set(this.selectRows.map((it) => it.currency))]
          const isSameCurrency = currencys.length === 1 // 是否是相同的币种
          // 发票登记操作按钮显示
          this.isOfferRegister = isSameCurrency && this.selectRows.every(
            (item) =>
              item.unAmountInvoiced > 0 &&
              (item.state == 5 ||
                item.state == 6 ||
                item.state == 7 ||
                item.state == 11 ||
                item.state == 12) &&
              item.financeSetupId == financeSetupId &&
              item.operatePurchaseOrderInvoice === '1' &&
              item.financeSetupDuty
          )
          // 作废操作按钮显示
          this.isOrderCancel = this.selectRows.every(
            // 已撤销 采购中 已驳回  可作废
            (item) => item.state == 3 || item.state == 5 || item.state == 2
          )
          this.isOrderPay =
            !this.selectRows.find(
              (item) =>
                item.state == 1 ||
                item.state == 2 ||
                item.state == 3 ||
                item.state == 4 ||
                item.state == 8
            ) &&
            [...new Set(this.selectRows.map((i) => i.supplierName))].length < 2
          // 费用申请操作按钮显示
          const currency = this.selectRows[0].currency

          this.isCost = this.selectRows.every(
            (item) =>
              item.currency == currency &&
              (item.state == 5 ||
                item.state == 6 ||
                item.state == 7 ||
                item.state == 11 ||
                item.state == 12)
          )
        } else {
          this.isOfferRegister = true
          this.isOrderPay = true
          this.isOrderCancel = true
          this.isCost = true
        }
      },

      // 付款申请
      orderPay(type, row) {
        let self = this
        let arr = []
        let refundIds = []
        if (type == 1) {
          if (!self.selectRows.length) {
            self.$message.warning('请至少选择一条数据！')
            return false
          } else {
            self.selectRows.forEach((item) => {
              arr.push(item.purchaseOrderId)
              if (item.abnormalWarehousingStatus == 2) {
                refundIds.push(item.number)
              }
            })
          }
        } else {
          arr.push(row.purchaseOrderId)
          if (row.abnormalWarehousingStatus == 2) {
            refundIds.push(row.number)
          }
        }
        this._toggleTitle(type == 1 ? true : false)

        // 判断是否存在审核模板
        let auditParams = {
          auditType: 7,
          status: 0,
          tenantId: self.userInfo.tenantId,
        }
        self.pubApi.getAuditTemplates(auditParams).then((response) => {
          // 如果response是null或者data为空，则给出提示
          if (!response?.data?.length) {
            self.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
            return false
          } else {
            self.$refs.orderPayment.refundIds = refundIds.join(',')
            self.$refs.orderPayment.initLoading(arr.join(','))
          }
        })
      },

      // 发票登记
      orderRegister(type, id) {
        let self = this
        let arr = []
        if (type == 1) {
          if (!self.selectRows.length) {
            self.$message.warning('请至少选择一条数据！')
            return false
          } else {
            self.selectRows.forEach((item) => arr.push(item.purchaseOrderId))
          }
        } else {
          arr.push(id)
        }
        self.$refs.orderRegister.getOrderListInvoiceDetail(arr.join(','))
      },

      // 费用申请
      costApply() {
        if (this.selectRows.length < 1) {
          this.$message.warning('请至少选择一条数据！')
          if ([...new Set(this.selectRows.map((i) => i.currency))].length > 1) {
            return this.$message.warning('币种不同')
          }
        } else {
          this.$refs.costApply.source = 'list'
          this.$refs.costApply.initLoading(2, this.selectRows)
        }
      },

      // 验货详情
      cargoDetail(id) {
        this.$refs.cargoManageDetail.initLoading(id, 'show')
      },

      // 编辑
      edit(id, salesOrderIds, row) {
        const showSwitch = row?.salesOrderNumber?.includes('PD') ? '1' : '0'
        if (typeof salesOrderIds === 'string') {
          salesOrderIds = salesOrderIds.split(',').filter((i) => i)
          if (salesOrderIds.length === 0) {
            salesOrderIds = null
          }
        }
        const bool =
          this.userInfo.area === '合肥' && !salesOrderIds ? true : false
        this.$router.push({
          path: '../purchase/purchaseDemand/createPurchaseOrder',
          query: {
            purchaseOrderId: id,
            isStock: bool,
            showSwitch: showSwitch, // 1 是备货需求 0是采购需求
          },
        })
      },

      // 打印合同
      printContract(id, type, salesOrderIds) {
        let bool = false
        if (['4', '5', '6'].includes(type)) {
          bool = !salesOrderIds && this.userInfo.area === '合肥' ? true : false
          const obj = {
            4: '1',
            5: '2',
            6: '3',
          }
          type = obj[type]
        }
        const path = bool
          ? '/print/purchaseContract-BH'
          : '/print/purchaseContract'
        // const bool =
        //   !salesOrderIds && this.userInfo.area === '合肥' ? true : false
        // const path = bool
        //   ? '/print/purchaseContract-BH'
        //   : '/print/purchaseContract'
        const routeData = this.$router.resolve({
          path,
          query: {
            id: id,
            type,
          },
        })
        window.open(routeData.href, '_blank')
      },

      //撤销
      revokeClick(row) {
        InboundSheet.getAuditObject({
          orderId: row.purchaseOrderId,
          auditType: 3,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            this.auditObjectId = res.data.auditObjectId
            this.cancelVisible = true
          }
        })
      },

      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },

      //确定撤销
      cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        const {
          auditObjectId,
          auditStatus = 4,
          auditType = 3,
          cancelReason,
        } = this
        const { tenantId, userId, userName } = this.userInfo
        InboundSheet.cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res?.code === '000000') {
            this.$message.success('撤销成功')
            this.cancelVisible = false
            this.getList()
          }
        })
      },

      // 跳转详情
      goPurchasingDetail(num, id) {
        if (id) {
          let routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: num, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        } else {
          this.$refs.stockUp.initLoading(num.split('--')[0])
        }
      },

      // 备货单详情采购开发回显
      buyerNameFormat(data) {
        if (data) {
          const arr = []
          data.forEach((item) =>
            item.buyerName ? arr.push(item.buyerName) : ''
          )
          return Array.from(new Set(arr)).toString()
        } else {
          return '--'
        }
      },

      // 格式化库存数据
      formatStock(stockData) {
        stockData = stockData || []
        let isArray = (stockData.constructor = Array)
        let __stockInfo = []

        if (isArray && stockData.length > 0) {
          for (let i = 0; stockData.length > i; i++) {
            __stockInfo.push(
              `${stockData[i].warehouseName}:${stockData[i].num}`
            )
          }
        } else {
          __stockInfo.push(`--`)
        }
        return __stockInfo.toString()
      },

      // 作废
      async orderCancel() {
        // this.operatVisible = true
        if (!this.selectRows.length) {
          this.$message.warning('请至少选择一条数据！')
          return false
        } else {
          let ids = this.selectRows.map((it) => it.purchaseOrderId)
          let res = await PurchaseOrderInteractor.getPurchaseDeleteFront({
            ids: ids.toString(),
          })
          this.voidTable = res.data
          this.operatVisible = true
        }
      },

      // 作废保存
      operatSave(type) {
        let self = this
        self.$refs['operatForm'].validate((valid) => {
          if (valid) {
            self
              .$confirm(`确定作废, 操作之后无法修改?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
              .then(() => {
                let ids = []
                self.selectRows.forEach((item) => {
                  ids.push(item.purchaseOrderId)
                })

                const params = {
                  ids: ids.join(','),
                  delReason: self.operatForm.delReason,
                }
                OrderListInteractor.purchaseLogicDeleteByIds(params).then(
                  (res) => {
                    if (res.code === '000000') {
                      self.$message({ type: 'success', message: '保存成功!' })
                      self.initLoading()
                      self.operatVisible = false
                      self.$refs['operatForm'].resetFields()
                    }
                  }
                )
              })
              .catch(() => { })
          }
        })
      },

      // 作废取消
      operatCancel() {
        this.$refs['operatForm'].resetFields()
        this.operatVisible = false
      },

      // 处理退款
      disposeRefund(id) {
        this.$refs.disposeRefund.initLoading(id)
      },

      // 高级筛选
      highSearch() {
        this.queryFormType = this.queryForm.type
        this.queryFormSortName = this.queryForm.sortName
        this.queryFormSortType = this.queryForm.sortType
        this.drawer = true
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },

      // 高级筛选触发
      searchClick(val) {
        this.queryForm = val
        this.$set(this.queryForm, 'type', this.queryFormType)
        this.$set(this.queryForm, 'sortName', this.queryFormSortName)
        this.$set(this.queryForm, 'sortType', this.queryFormSortType)
        this.initLoading(true)
        this.highSearchClose()
      },
      // 条件满足打印
      isState(val, arr) {
        let state = arr.some((item) => {
          return item == val
        })
        return state
      },
      // 点击知道了，修改状态
      changeStatus(val) {
        let data = {
          purchaseOrderId: val.purchaseOrderId,
        }
        OrderListInteractor.updateRenderingStatusUsingPOST(data).then((res) => {
          if (res && res.code.toString() === '000000') {
            this.$message.success('操作完成！')
            this.initLoading()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .page-header {
    position: relative;

    .self {
      position: absolute;
      top: 0;
      right: 0;
    }

    /deep/ .el-button,
    /deep/ .el-form {
      margin-bottom: 10px;
    }
  }

  .mt15 {
    margin-top: 15px;
  }

  ::v-deep {
    .el-table__row .el-button {
      margin: 0 5px;
    }

    .purchasingDemandProductNumbers .cell {
      width: 160px !important;
    }
  }

  .dispose {
    display: flex;

    i {
      font-size: 16px;
      color: #d21e06;
      cursor: pointer;
      line-height: 22px;
      margin-left: 2px;
    }
  }

  .tip-content {
    line-height: 18px;

    .el-tag--small {
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      padding: 0;
      background: none;
      border: none;
    }
  }

  .tip-content+.tip-content {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px dashed #999;
  }

  .tip_content_bottom {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5px 5px;
    min-width: 80px;
    line-height: 22px;
  }

  .colorblue {
    color: #1890ff;
    cursor: pointer;
  }

  .supplierName-row {
    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }
</style>
<style lang="scss">
  .custom-table-checkbox {
    overflow-y: scroll !important;
  }
</style>