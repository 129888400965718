<template>
  <el-dialog
    title="拆分采购"
    width="900px"
    class="custom-dialog"
    :visible.sync="modelVisible"
    :before-close="close"
  >
    <div v-if="isSplit">
      <p class="mb5">产品BOM单：</p>
      <el-form class="container" :model="form" ref="ruleForm">
        <el-table
          class="input-table"
          :data="form.bomProductDTOList"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="产品编码" width="120">
            <template slot-scope="scope">
              {{
                scope.row.skuId || scope.row.sku || scope.row.accessoriesNumber
              }}
            </template>
          </el-table-column>
          <el-table-column label="产品图片" width="120">
            <template slot-scope="scope">
              <div class="img-wrap">
                <el-badge
                  v-show="
                    scope.row.imgMain && scope.row.imgMain.split(',').length > 1
                  "
                  :value="
                    scope.row.imgMain ? scope.row.imgMain.split(',').length : ''
                  "
                ></el-badge>
                <el-image
                  v-if="scope.row.imgMain"
                  :src="scope.row.imgMain.split(',')[0]"
                  class="c_pointer"
                  @click="handlePreview(scope.row.imgMain.split(','))"
                ></el-image>
                <span v-else>--</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="产品名称">
            <template slot-scope="scope">
              <div class="ell">{{ scope.row.nameCn }}</div>
            </template>
          </el-table-column>
          <el-table-column label="产品类型" width="120">
            <template slot-scope="scope">
              {{
                scope.row.sonProductType === '1'
                  ? '辅料'
                  : scope.row.standardCustomized
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="组合数量"
            width="120"
            prop="numCombination"
            label-class-name="required-th"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'bomProductDTOList.' + scope.$index + '.numCombination'"
                :rules="[
                  {
                    required: true,
                    message: '组合数量必填',
                    trigger: 'change',
                  },
                  {
                    pattern: /^[1-9]\d*$/,
                    message: '请输入>0的整数',
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  maxlength="20"
                  :disabled="true"
                  v-model="form.bomProductDTOList[scope.$index].numCombination"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="印刷方式"
            width="150"
            label-class-name="required-th"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'bomProductDTOList.' + scope.$index + '.printingWay'"
                :rules="[
                  {
                    required: true,
                    message: '请选择印刷方式',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-model="form.bomProductDTOList[scope.$index].printingWay"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in printingWayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="flex tips mt10">
        <div>Tips:</div>
        <div class="ml5">
          <p>确定后，产品会根据BOM单自动拆分采购需求。</p>
          <p>
            若BOM单不满足采购需求，请点击
            <el-link type="primary" @click="createBom(form.bomProductDTOList)">
              修改
            </el-link>
          </p>
        </div>
      </div>

      <div class="text-center mt20">
        <el-button type="primary" @click="save" :loading="loading">
          {{ loading ? '加载中' : '确认' }}
        </el-button>
        <el-button @click="close()">取消</el-button>
      </div>
    </div>
    <div v-else>
      <div class="empty">
        <p>
          无BOM单，
          <el-link type="primary" @click="createBom(null)">去创建>></el-link>
        </p>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
import { mapGetters } from 'vuex'
import { getLabels } from '@/utils/specs-utils'
export default {
  data() {
    return {
      parentForm: {},
      form: {
        bomProductDTOList: [], // bom单列表
      },
      printingWayList: [],
      isSplit: null, // 是否拆分
      modelVisible: false,
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    // 初始化加载
    async initLoading(row, bomList) {
      this.parentForm = row || this.$parent.parentForm

      this.printingWayList = this.$parent.printingWayList
      if (bomList?.length) {
        this.isSplit = true
        // 清空印刷方式，取主产品的印刷方式
        this.form.bomProductDTOList = [...bomList].map((item) => {
          return {
            ...item,
            printingWay: item.printingWay,
            imgMain: item.imgMain || item.productImg,
            sku: item.sku || item.skuId || item.accessoriesNumber,
          }
        })
      } else {
        const res = await purchaseDemandInterator.listByParentSku(
          this.parentForm.sku,
          this.userInfo.tenantId
        )

        if (res && res.code == '000000') {
          this.isSplit = res.data?.length > 0 ? true : false
          this.form.bomProductDTOList =
            res?.data?.map((item) => {
              return {
                ...item,
                imgMain: item.imgMain || item.productImg,
                printingWay: '',
              }
            }) || []
        }
      }

      this.modelVisible = true
    },

    // 保存
    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 保存接口
          this.loading = true
          console.log(this.form.bomProductDTOList)
          const params = this.form.bomProductDTOList.map((item) => {
            if(item.sku.indexOf('FL') !== '-1') {
              const labels = getLabels(item)
              console.log(item)
              item.specsValue1 = item.label
              item.specsValueCn1 = item.labelCn
            }
            return {
              ...item,
              creator: this.userInfo.userName,
              creatorEn: this.userInfo.englishName,
              productId: item.productId || item.productAccessoriesId,
              imgMain: item.printingImg || item.imgMain,
              sku: item.skuId || item.sku || item.accessoriesNumber,
              parentSku: this.parentForm.sku,
              parentUrl: this.parentForm.printingImg || this.parentForm.imgMain,
              parentName: this.parentForm.nameCn,
              purchasingDemandProductId:
                this.parentForm.purchasingDemandProductId,
              parentSpecsValue:
                this.parentForm.specsValue1 +
                (this.parentForm.specsValue2
                  ? '/' + this.parentForm.specsValue2
                  : ''),
              // 重复产品Id
              orderProductUniqueId: this.parentForm.orderProductUniqueId,
            }
          })
          const res = await purchaseDemandInterator.bomProductSplit(params)

          if (res && res.code == '000000') {
            this.$message.success('保存成功')
            this.$parent.initLoading()
            this.close()
            this.loading = false
          } else {
            this.loading = false
          }
        }
      })
    },

    // 创建-修改bom
    createBom(list) {
      this.modelVisible = false
      this.$emit('bomDrawerOpen', list)
    },

    // 关闭弹窗重置表单
    close() {
      if (this.isSplit) {
        this.$refs.ruleForm.resetFields()
        this.form = this.$options.data().form
      }
      this.modelVisible = false
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .container {
    min-height: 300px;
    .el-table {
      max-height: 360px;
      overflow-y: auto;
    }
    .el-form-item {
      margin-bottom: 18px;
    }
    p {
      text-align: left;
      line-height: 24px;
    }
  }
  .tips {
    color: #999;
  }
  .empty {
    text-align: center;
    p {
      margin: 80px 0;
    }
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;
    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }
    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
}
</style>
