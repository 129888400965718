<template>
  <el-dialog
    title="验货"
    width="1200px"
    class="custom-dialog"
    v-if="cargoManageVisible"
    :visible.sync="cargoManageVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <el-form :model="form" ref="ruleForm">
      <div class="custom-detail">
        <el-row>
          <el-col>
            <em>验货单号：</em>
            <span>{{ detail.number || '--' }}</span>
          </el-col>
          <el-col class="w_90">
            <em>供应商名称：</em>
            <span>{{ detail.supplierName || '--' }}</span>
          </el-col>
          <el-col style="display: flex">
            <em style="width: 100px; word-break: keep-all">期望验货日期：</em>
            <span>{{ detail.inspectionDate || '--' }}</span>
          </el-col>
          <el-col>
            <em>验货阶段：</em>
            <span>{{ detail.inspectionStage | inspectionStageFormat }}</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <em>工厂交期：</em>
            <span>{{ detail.expectDeliveryDate || '--' }}</span>
          </el-col>
          <el-col class="w_90">
            <em>采购开发：</em>
            <span>{{ detail.buyerName || '--' }}</span>
          </el-col>
          <el-col>
            <em style="width: 35px">QC:</em>
            <span>{{ detail.inspector || '--' }}</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col class="w_130" style="width: 50%">
            <em>物流公司及运单号：</em>
            <span>{{ detail.logisticsCompanyNo || '--' }}</span>
          </el-col>
          <el-col style="width: 50%">
            <em>验货地址：</em>
            <span>{{ detail.inspectionAddress || '--' }}</span>
          </el-col>
        </el-row>
      </div>

      <div class="wrap" v-if="isShow">
        <div
          class="container mb15"
          v-for="(item, i) in form.inspectionProductEditDTOList"
          :key="i"
        >
          <div class="top pointer" @click="isShowElement(item.isShow, i)">
            <div class="left">
              <div class="left-label">产品：</div>
              <div class="left-content">
                <span v-show="item.sku">{{ item.sku }}</span>
                <span v-show="item.nameCn">
                  {{ item.sku ? '/' : '' }}{{ item.nameCn }}
                </span>
                <span>/英文规格:</span>
                <template
                  v-if="
                    $hasChineseChar(
                      item.specsValue1 || '' + item.specsValue2 || ''
                    ) == '--'
                  "
                >
                  <span>--</span>
                </template>
                <template v-else>
                  <span v-show="item.specsValue1">{{ item.specsValue1 }}</span>
                  <span v-show="item.specsValue2">
                    ;{{ item.specsValue2 }}/
                  </span>
                </template>

                <span v-if="item.specsValueCn1 || item.specsValueCn2">
                  /中文规格:
                </span>
                <span v-show="item.specsValueCn1">
                  {{ item.specsValueCn1 }}
                </span>
                <span v-show="item.specsValueCn2">
                  ;{{ item.specsValueCn2 }}/
                </span>
              </div>
            </div>
            <div class="center">
              <span class="ml20">
                客户代表：{{ item.businessName || '--' }}
              </span>
              <span class="ml20">
                产品运营：{{ item.productOperation || '--' }}
              </span>
              <span class="ml20">验货数量：</span>
              <span class="green f_s_16">{{ item.numInspection || '--' }}</span>
              <span class="ml20">
                {{ item.provideSamples == 1 ? '已提供样品' : '未提供样品' }}
              </span>
              <span class="ml20">
                检验标准：{{ item.actualInspectionStandard || '--' }}
              </span>
            </div>

            <div class="right">
              <span
                v-if="
                  item.actualResults == '合格' || item.actualResults == '不合格'
                "
                class="green"
              >
                已验货
              </span>
              <span v-else-if="detail.state == 5" class="red">已撤销</span>
              <span v-else class="red">待验货</span>
            </div>
          </div>
          <div v-show="item.isShow">
            <div class="intro">
              <div>
                <p>效果图：</p>
                <ErpImage
                  :imgs="item.printingImg"
                  :styleObj="{ height: '50px', width: '50px' }"
                  @handlePreview="handlePreview('ErpImage')"
                  @closeViewer="closeViewer"
                  ref="ErpImage"
                ></ErpImage>
              </div>
              <div>
                <p>验货要求：{{ item.inspectionRequirements || '--' }}</p>
                <p>装箱信息：{{ item.packingInformation || '--' }}</p>
              </div>
            </div>

            <div class="form">
              <div class="custom-detail">
                <el-row>
                  <el-col class="w_90">
                    <em>验&nbsp;货&nbsp;结&nbsp;果：</em>
                    <span v-if="item.actualResults == '合格'" class="green">
                      {{ item.actualResults }}
                    </span>
                    <span v-else :class="item.actualResults ? 'red' : ''">
                      {{ item.actualResults || '--' }}
                    </span>
                  </el-col>
                  <el-col class="w_90">
                    <em>抽&nbsp;检&nbsp;数&nbsp;量：</em>
                    <span>{{ item.samplingQuantity }}</span>
                  </el-col>
                  <el-col class="w_105">
                    <em>严重问题数量：</em>
                    <span>{{ item.seriousQuantity }}</span>
                  </el-col>
                  <el-col class="w_105">
                    <em>一般问题数量：</em>
                    <span>{{ item.generalQuantity }}</span>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col class="w_90">
                    <em>严重不良率：</em>
                    <span class="green">
                      {{ item.seriousQuantityRate }}
                    </span>
                  </el-col>
                  <el-col class="w_90">
                    <em>一般不良率：</em>
                    <span class="green">
                      {{ item.generalQuantityRate }}
                    </span>
                  </el-col>
                  <el-col class="w_105">
                    <em>整体不良率：</em>
                    <span class="green">
                      {{ item.overallQuantityRate }}
                    </span>
                  </el-col>
                </el-row>
              </div>

              <div v-if="item.boxGaugeUrl" class="images">
                <p>箱规图（唛头，开箱图）</p>

                <viewer
                  :images="getImages(item, 'boxGaugeUrl')"
                  :options="options"
                  class="erp-viewer"
                  :style="{
                    zindex: 99999,
                  }"
                >
                  <img
                    v-for="src in getImages(item, 'boxGaugeUrl')"
                    :src="src.url"
                    :key="src.title"
                  />
                </viewer>
              </div>

              <div v-if="item.productPackagingUrl" class="images">
                <p>产品包装图</p>

                <viewer
                  :images="getImages(item, 'productPackagingUrl')"
                  :options="options"
                >
                  <img
                    v-for="src in getImages(item, 'productPackagingUrl')"
                    :src="src.url"
                    :key="src.title"
                  />
                </viewer>
              </div>

              <div v-if="item.productUrl" class="images">
                <p>产品图（Logo，正面，侧面，背面）</p>

                <viewer
                  :images="getImages(item, 'productUrl')"
                  :options="options"
                >
                  <img
                    v-for="src in getImages(item, 'productUrl')"
                    :src="src.url"
                    :key="src.title"
                  />
                </viewer>
              </div>

              <el-row :gutter="20">
                <el-col :span="12">
                  <h4>验货结论说明</h4>
                  <p>{{ item.actualResultDescription || '--' }}</p>
                </el-col>
                <el-col :span="12">
                  <div v-if="item.fileAnnex">
                    <h4>附件</h4>
                    <div
                      class="flie-list"
                      v-for="(file, i) in JSON.parse(item.fileAnnex)"
                      :key="i"
                    >
                      <i class="el-icon-document"></i>
                      <a class="page-link ml5">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="file.name"
                          placement="top-start"
                        >
                          <span class="ell">
                            <router-link
                              :to="`/preview-file/index?name=${file.name}&url=${file.url}`"
                              target="_blank"
                            >
                              {{ file.name }}
                            </router-link>
                          </span>
                        </el-tooltip>
                      </a>
                      <span class="size ml10">
                        {{ utils.calculateFileSize(file.size) }}
                      </span>
                      <span class="ml10">{{ file.time }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row
                :gutter="20"
                class="mb20"
                v-if="item.marks || item.actualMark"
              >
                <el-col :span="12">
                  <div>
                    <h4 style="display: inline-block; margin-right: 10px">
                      唛头
                    </h4>
                    <el-button
                      size="mini"
                      @click="downloadMark(item.marks || item.actualMark)"
                    >
                      下载唛头
                    </el-button>
                  </div>
                  <div style="white-space: pre-wrap">
                    {{ item.marks || item.actualMark }}
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <div v-show="detail.remark">
        <p>备注</p>
        {{ detail.remark }}
      </div>
      <div v-show="fileList.length">
        <p>凭证/附件</p>
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
    </el-form>

    <div class="text-center mt20">
      <el-button @click="close()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { inspectionGetDetailForEdit } from '@/api/order'
  import { downloadMarksCommon } from '@/utils/utils.js'
  import { purchaseExamineGoods } from '@/core/interactors/purchase/examineGoods'
  import { URL_COMPRESS } from '@/config'
  export default {
    data() {
      return {
        form: {
          buyerId: '',
          inspectionOrderId: '', // 业务Id
          purchaseOrderId: '', // 采购单Id
          purchaseOrderNumber: '', // 采购单单号
          number: '', // 验货单号
          supplierName: '', // 供应商名称
          inspectionDate: '', // 验货日期
          expectDeliveryDate: '', // 期望交期
          inspectionAddress: '', // 验货地址

          inspector: '', // 验货员
          inspectorId: '', // 验货员ID
          inspectionProductEditDTOList: [],
          fileAnnex: '', // 附件
          remark: '', // 备注
        },
        detail: {},
        fileList: [],
        cargoManageVisible: false,
        isShow: true,
        isLoading: false,
        options: {},
      }
    },
    filters: {
      inspectionStageFormat(n) {
        if (n) {
          switch (n) {
            case 1:
              return '初期验货'
            case 2:
              return '中期验货'
            case 3:
              return '尾期验货'
          }
        } else {
          return '--'
        }
      },
    },
    methods: {
      // 获取图片
      getImages(item, flag) {
        if (item[flag]) {
          return item[flag].split(',').map((item) => {
            const obj = {}
            if (
              ['boxGaugeUrl', 'productPackagingUrl', 'productUrl'].includes(
                flag
              )
            ) {
              obj.url = `${item}${URL_COMPRESS}`
            } else {
              obj.url = item
            }

            return obj
          })
        }
        return []
      },
      // 初始化数据
      initLoading(id) {
        purchaseExamineGoods
          .inspectionGetDetailForEditApi({ businessId: id })
          .then((res) => {
            if (res?.code !== '000000' || !res.data) {
              return
            }
            this.detail = res.data ? res.data : {}
            this.form.buyerId = res.data.buyerId
            this.form.purchaseOrderNumber = res.data.purchaseOrderNumber
            this.form.inspectionOrderId = res.data.inspectionOrderId
            this.form.purchaseOrderId = res.data.purchaseOrderId
            this.form.number = res.data.number
            this.form.supplierName = res.data.supplierName
            this.form.inspectionDate = res.data.inspectionDate
            this.form.expectDeliveryDate = res.data.expectDeliveryDate
            this.form.inspectionAddress = res.data.inspectionAddress
            this.form.inspector = res.data.inspector
            this.form.inspectorId = res.data.inspectorId
            this.form.remark = res.data.remark
            this.form.inspectionProductEditDTOList = []
            this.fileList = res.data.fileAnnex
              ? JSON.parse(res.data.fileAnnex)
              : ''
            if (
              res.data.inspectionProductEditVOList &&
              res.data.inspectionProductEditVOList.length
            ) {
              res.data.inspectionProductEditVOList.forEach((item) => {
                let obj = {}
                obj = Object.assign({}, { ...item }, { isShow: true })
                this.form.inspectionProductEditDTOList.push(obj)
              })
            }
            this.cargoManageVisible = true
          })
      },

      // 图片跳转新标签页
      imageLink(name, img) {
        window.open(
          `#/preview-file/index?name=${name + '.jpg'}&url=${img}`,
          '_blank'
        )
      },

      isShowElement(boo, i) {
        this.isShow = false
        this.$set(this.form.inspectionProductEditDTOList[i], 'isShow', !boo)
        this.isShow = true
      },

      // 关闭弹窗重置表单
      close() {
        this.$refs.ruleForm.resetFields()
        this.cargoManageVisible = false
      },
      // 查看预览图片
      handlePreview(refName) {
        this.$refs[refName].handlePreview()
      },
      closeViewer() {
        this.$refs.ErpImage.closeViewer()
      },
      // 下载唛头
      downloadMark(value) {
        downloadMarksCommon(value)
        // exportPurchaseMarksWord({
        //   marks: JSON.stringify(value.split('\n')),
        // }).then((res) => {
        //   var linkElement = document.createElement('a')
        //   var blob = new Blob([res], { type: 'application/vnd.ms-word' })
        //   var url = window.URL.createObjectURL(blob)
        //   linkElement.setAttribute('href', url)
        //   const downloadDate = Utils.timeFormat(new Date())
        //   let fileName = `唛头-${downloadDate}`
        //   linkElement.setAttribute('download', `${fileName}.doc`)
        //   document.body.appendChild(linkElement)
        //   linkElement.click()
        //   URL.revokeObjectURL(linkElement.href) // 释放URL 对象
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-dialog {
    .wrap {
      overflow-y: auto;
      max-height: 360px;

      .container {
        border: 1px solid #ebeef5;
        border-radius: 4px;

        .top {
          padding: 0 20px;
          /* line-height: 39px; */
          border-bottom: 1px solid #ebeef5;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: baseline;

          .left {
            flex-shrink: 1;
            /* 当空间不足时，.left可以缩小 */
            word-wrap: break-word;
            /* 文本超长时自动换行 */
            line-height: 20px;
            padding: 5px 0;
            display: flex;

            .left-content {
              flex-shrink: 1;
              /* 当空间不足时，.left可以缩小 */
              word-wrap: break-word;
            }

            .left-label {
              flex-shrink: 0;
            }
          }

          .center,
          .right {
            flex-grow: 1;
            /* 当空间有多余时，.right可以放大 */
            flex-shrink: 0;
            /* 当空间不足时，.right不缩小 */
            /* 可以设置min-width等样式来确保最小宽度 */
          }

          .right {
            margin-left: 10px;
            text-align: right;
          }
        }

        .result {
          text-align: right;

          em {
            font-style: normal;
          }
        }

        .intro {
          padding: 0 20px;
          display: flex;
          justify-content: space-between;

          // max-height: 120px;
          // overflow-y: auto;
          > div {
            width: 50%;

            img {
              width: 100%;
              padding: 0 120px 20px 0;
            }
          }
        }

        .custom-table {
          display: table;
        }

        .form {
          padding: 0 15px;
        }

        .images {
          img {
            display: inline-block;
            width: 90px;
            height: 90px;
            cursor: pointer;
            border-radius: 4px;
            margin-right: 10px;
          }
        }
      }
    }

    p {
      margin: 10px 0;
    }
  }

  ::v-deep {
    .el-upload {
      margin-bottom: 10px;
      width: 90px;
      height: 90px;

      i {
        display: block;
        line-height: 90px;
      }
    }

    .el-upload-list__item {
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
  }

  .viewer-container {
    z-index: 99999 !important;
  }
</style>
