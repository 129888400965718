<template>
  <PrintWrapper>
    <table
      style="border-collapse: collapse; margin: 0 auto; font-size: 12px"
      width="700px;"
      align="center"
      id="printTest1"
    >
      <tbody>
        <tr>
          <td colspan="9" align="center">
            <div class="header">
              <h1>采购合同</h1>
              <h4>（仅供备货使用）</h4>
              <p class="dingD">
                <span class="fw">合同编号：</span>
                <span>{{ info.number ? info.number : '' }}</span>
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="zhubox">
              <!-- 主体内容 -->
              <div class="zthead mt">
                <!-- 采购信息 -->
                <div class="ftName1">
                  <div class="qzPsty fw">
                    <label>甲方(买方)：</label>
                    <span
                      class="address"
                      style="
                        border-bottom: 1px black solid;
                        width: 238px;
                        font-weight: bold;
                      "
                    >
                      {{ info.purchaser ? info.purchaser : '' }}
                    </span>
                  </div>
                  <section v-show="type != 1">
                    <div class="fw fsz">
                      <label>地址：</label>
                      <span
                        id="address"
                        class="address"
                        style="border-bottom: 1px black solid; width: 280px"
                        contenteditable="true"
                      >
                        {{ purchaserAddress }}
                      </span>
                    </div>
                    <div class="fw fsz">
                      <label>联系人：</label>
                      <input
                        class="spanSty"
                        style="border-bottom: 1px solid #000000"
                        type="text"
                        :value="info.creator"
                      />
                      <label>电话：</label>
                      <input
                        id="tel"
                        class="spanSty"
                        style="border-bottom: 1px solid #000000"
                        type="text"
                        :value="info.creatorPhone || info.supplierLinkmanPhone"
                      />
                    </div>
                  </section>
                </div>
                <div class="ftName">
                  <div class="qzPsty fw fsz">
                    <label style="font-size: 14px">乙方(卖方)：</label>
                    <input
                      class="spanSty"
                      style="
                        border-bottom: 1px solid #000000;
                        width: 248px;
                        font-weight: bold;
                      "
                      type="text"
                      :value="info.supplierName ? info.supplierName : ''"
                    />
                  </div>
                  <section v-show="type != 1">
                    <div class="fw fsz">
                      <label>地址：</label>
                      <span
                        class="address"
                        style="border-bottom: 1px black solid; width: 280px"
                        contenteditable="true"
                      >
                        {{ info.supplierAddress ? info.supplierAddress : '' }}
                      </span>
                    </div>
                    <div class="fw fsz">
                      <label>联系人：</label>
                      <input
                        class="spanSty"
                        style="border-bottom: 1px solid #000000; width: 100px"
                        type="text"
                        v-model.trim="info.supplierLinkmanName"
                      />
                      <label>电话：</label>
                      <input
                        style="
                          border-bottom: 1px solid #000000;
                          width: 132px !important;
                        "
                        type="text"
                        v-model.trim="info.supplierLinkmanPhone"
                      />
                    </div>
                  </section>
                </div>
                <!-- 日期 -->
                <div class="contDate clearfix mt">
                  <div class="contTime">
                    <p>
                      双方在真实、自愿的基础上，根据《中华人民共和国民法典》的规定，
                      达成如下协议，以资共同遵守：
                    </p>
                  </div>
                </div>
                <!-- 地址 -->
                <div class="clearfix mt">
                  <div class="contTime">
                    <h4>一、货物名称、规格型号、包装要求等。</h4>
                  </div>
                </div>

                <div v-show="info.purchaseProductShowVOList" class="mt">
                  <div>
                    <table
                      class="item-table"
                      v-for="(item, i) in info.purchaseProductShowVOList"
                      :key="i"
                      id="tableExcel"
                      width="700px"
                      border="1"
                      cellspacing="0"
                      cellpadding="5px"
                      border-collaspe="collapse"
                    >
                      <tbody>
                        <tr>
                          <td colspan="3">图片</td>
                          <td>品名</td>
                          <td>数量</td>
                          <td>单位</td>
                          <td>单价</td>
                          <td>金额</td>
                        </tr>
                        <tr>
                          <td rowspan="99" colspan="3" style="padding: 5px">
                            <!-- printingImg可能存在多张，需要单独处理 -->

                            <div
                              v-if="
                                item.printingImg || item.image || item.imgMain
                              "
                            >
                              <img
                                style="width: 90%; height: auto"
                                :src="item"
                                v-for="(item, i) in (
                                  item.printingImg ||
                                  item.image ||
                                  item.imgMain
                                ).split(',')"
                                :key="i"
                              />
                            </div>
                          </td>
                          <td style="text-align: center">
                            {{ item.declareCn || item.nameCn || item.nameEn }}
                          </td>
                          <td style="text-align: center">
                            {{ calcResult(item.sku, 'numActual') }}
                          </td>
                          <td style="text-align: center">
                            {{ item.declareUnit || item.productUnit }}
                          </td>
                          <td style="text-align: center">
                            <template v-if="isViewCost">
                              {{ getShowPrice(item) }}
                            </template>
                            <NoViewCost v-else></NoViewCost>
                          </td>
                          <td style="text-align: center">
                            <template v-if="isViewCost">
                              {{ getSingeProTotal(item) }}
                            </template>
                            <NoViewCost v-else></NoViewCost>
                          </td>
                        </tr>

                        <tr v-show="false">
                          <td style="text-align: center">版费</td>
                          <td colspan="3"></td>
                          <td style="text-align: center"></td>
                        </tr>

                        <tr>
                          <td>包装要求</td>
                          <td colspan="4" style="text-align: left" class="pl5">
                            {{ item.packingMethodEn }}
                          </td>
                        </tr>
                        <tr
                          :style="{
                            borderBottom:
                              info.purchaseProductShowVOList.length !== 1 &&
                              (i === info.purchaseProductShowVOList.length - 1
                                ? '1px solid gray'
                                : 'none'),
                          }"
                        >
                          <td style="width: 10px !important">
                            参
                            <br />
                            数
                            <br />
                            标
                            <br />
                            准
                          </td>
                          <td colspan="4" style="text-align: left" class="pl5">
                            <div
                              style="text-align: left; word-break: break-all"
                              v-html="
                                item.productSize
                                  ? item.productSize.replace(/\n/g, '<br>')
                                  : '--'
                              "
                            ></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      width="700px"
                      cellspacing="0"
                      cellpadding="5px"
                      class="aaa-table"
                      v-if="info.discountAmount"
                    >
                      <tbody>
                        <tr>
                          <td
                            width="550px"
                            style="word-break: break-word"
                            class="item1"
                          >
                            <template v-if="isViewCost">
                              {{ info.discountReason }}
                            </template>
                            <NoViewCost v-else></NoViewCost>
                          </td>
                          <td width="150px" v-if="info.discountAmount">
                            <template v-if="isViewCost">
                              {{ info.discountAmount * -1 }}
                            </template>
                            <NoViewCost v-else></NoViewCost>
                          </td>
                          <td width="150px" v-else>
                            <span style="visibility: hidden">111</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  style="
                    position: relative;
                    min-height: 30px;
                    margin-top: 10px;
                    border: 1px solid #cccccc;
                    padding: 5px;
                  "
                >
                  <span
                    style="display: inline-block; position: absolute; top: 5px"
                  >
                    特别约定:
                  </span>
                  <div v-if="info.marks">
                    <span
                      style="
                        display: inline-block;
                        width: 660px;
                        margin-left: 80px;
                        white-space: pre-wrap;
                      "
                    >
                      {{ info.marks }}
                    </span>
                  </div>
                </div>

                <!-- 要求 -->
                <div class="fsz mt">
                  <h4 style="font-size: 14px">二、交货约定</h4>

                  <p>
                    1、乙方交货时间：乙方应在甲方指定时间将货物交付第一承运人并附上约定的、记录货物相关事项的资料。
                  </p>

                  <p>
                    2、运输方式：
                    <input
                      class="spanSty"
                      style="border-bottom: 1px solid #000000"
                      type="text"
                      v-model="info.transportWay"
                    />
                    、 运费承担方：
                    <input
                      class="spanSty"
                      style="border-bottom: 1px solid #000000"
                      type="text"
                      v-model="info.freightPayer"
                    />
                    。
                  </p>
                  <p>
                    <strong>
                      3、此批货为甲方特别定制货，未经甲方书面同意，乙方无权使用、将货品投放市场、交付给任何第三方销售。
                    </strong>
                  </p>
                  <h4 class="fs14">三、货物质量：</h4>
                  <p>
                    1、货物的质量标准，按照按双方约定标准执行，如没有特别约定的，则依次按国家标准、行业标准、通用标准执行。
                  </p>
                  <p>2、包装要求如下：</p>
                  <p>2.1、重量限制：单箱实重最好控制在 15 ~ 22kg 之间，如单票重量低于此范围，则按照最小体积装箱。</p>
                  <p>2.2、尺寸限制：最大边长不超过 120cm，第二边不超过 75cm，包裹长 + （宽 + 高）* 2 不超过 265cm。</p>
                  <p>2.3、建议使用大小合适的纸箱，避免空箱过多，如纸箱过大，需进行切割以适配货物尺寸。</p>
                  <p>2.4、纸箱材质要求：必须使用外贸五层加硬瓦楞纸箱。建议材质规格：200g/160g/90g/160g/200g（分别对应面纸、瓦楞、芯纸、瓦楞、里纸）。</p>
                  <p>3、注意事项：</p>
                  <p>3.1、确保纸箱的强度和尺寸符合要求，以保证货物在运输过程中的安全。</p>
                  <p>3.2、合理装箱，避免超重或尺寸超标，以免影响运输效率或产生额外费用。</p>
                  <p>3.3、切割纸箱时，注意保持纸箱的结构完整性，避免影响其承重能力。</p>
                  <h4 style="font-size: 14px">四、合同价格与支付方式</h4>
                  <p>
                    1、合同金额：本合同总价为{{ info.currency }}（小写）：
                    <input
                      style="
                        text-align: left;
                        border-bottom: 1px solid #000000;
                        width: 120px;
                      "
                      class="spanSty"
                      type="text"
                      :value="
                        isViewCost
                          ? (info.amountTotal * 1).toFixed(2)
                          : '******'
                      "
                    />
                    （大写：
                    <input
                      id="daxie"
                      class="spanSty"
                      style="border-bottom: 1px solid #000000; width: 240px"
                      type="text"
                      :value="
                        isViewCost
                          ? number_str(
                              (info.amountTotal * 1).toFixed(2),
                              info.currency
                            )
                          : '******'
                      "
                    />
                    ），
                  </p>
                  <p>
                    合同总价包括印刷费：
                    {{ info.currency == '美元' ? '$' : '￥' }}
                    {{ isViewCost ? printFees : '******' }}，其他费用：
                    {{ info.currency == '美元' ? '$' : '￥' }}
                    {{ isViewCost ? otherFees : '******' }}
                    <span v-show="otherExplain">
                      <input
                        style="border: 0; width: 360px"
                        type="text"
                        :value="'（' + otherExplain + '）'"
                      />
                    </span>
                  </p>
                  <p>
                    <strong>
                      2、款项支付方式：
                      <input
                        class="spanSty"
                        style="border-bottom: 1px solid #000000; width: 100%"
                        type="text"
                        :value="
                          payWay(
                            info.purchasePaymentMethodShowVOList ||
                              info.purchasePaymentMethodEditDTOList ||
                              []
                          )
                        "
                      />
                      ，尾款自首次提货并入甲方仓库三个月付清。
                    </strong>
                  </p>
                  <p>
                    3、乙方收款信息：收款人
                    <input
                      class="spanSty"
                      style="border-bottom: 1px solid #000000; width: 533px"
                      type="text"
                      :value="info.accountName ? info.accountName : ''"
                    />
                    ，
                  </p>
                  <p>
                    收款渠道及收款帐号：
                    <input
                      class="spanSty"
                      style="border-bottom: 1px solid #000000; width: 300px"
                      type="text"
                      :value="info.bankName ? info.bankName : ''"
                    />
                    /
                    <input
                      class="spanSty"
                      style="border-bottom: 1px solid #000000; width: 240px"
                      type="text"
                      :value="info.bankAccount ? info.bankAccount : ''"
                    />
                    。
                  </p>
                  <p>
                    4、本合同中合同款是否已含税（含税
                    <input
                      class="spanSty"
                      style="border-bottom: 1px solid #000000; width: 30px"
                      type="text"
                      :value="[1, '1'].includes(info.invoiceType) ? '是' : '否'"
                    />
                    ，如含税则填“是”，不含税则填“否”。
                    <span v-if="info.invoiceType == 1 || info.invoiceType == 2">
                      请将合同编号填写在发票备注栏。
                    </span>
                    ）
                    <span v-if="info.invoiceType == 1 || info.invoiceType == 2">
                      如本合同无特说明，乙方应在发货后
                      <input
                        class="spanSty"
                        style="border-bottom: 1px solid #000000; width: 30px"
                        type="text"
                        value="七"
                      />
                      日内开具全额
                      <span
                        v-if="info.invoiceType == 1"
                        style="
                          display: inline-block;

                          border-bottom: 1px solid rgb(0, 0, 0);
                        "
                      >
                        &nbsp;增值税专用发票 &nbsp;
                      </span>
                      <span
                        v-else-if="info.invoiceType == 2"
                        style="
                          display: inline-block;

                          border-bottom: 1px solid rgb(0, 0, 0);
                        "
                      >
                        &nbsp; 增值税普通发票 &nbsp;
                      </span>
                      邮寄给甲方，甲方的收件地址为：
                      <span
                        id="invAddr"
                        class="address"
                        style="border-bottom: 1px black solid; width: auto"
                      >
                        {{ reAddress }}
                      </span>
                      ，收件人：
                      <input
                        id="invName"
                        class="spanSty"
                        style="border-bottom: 1px solid #000000"
                        type="text"
                        :value="reMan"
                      />
                      ，联系方式：
                      <input
                        id="invTel"
                        class="spanSty"
                        style="border-bottom: 1px solid #000000"
                        type="text"
                        :value="rePhone"
                      />
                      。（如有则据实填写，如无则填“/”)
                    </span>
                  </p>
                  <div v-if="type == 1">
                    <h4 style="font-size: 14px">五、合同争议的解决方式：</h4>
                    <p>
                      1.本合同在履行过程中发生的争议，由双方当事人协商解决；协商或调解不成的依法向甲方所在地人民法院起诉。
                    </p>
                    <p>
                      2.如有未尽事宜，须经双方共同协商，签订补充协议，补充协议与本协议具有同等效力。本协议壹式叁份，甲方执贰份，乙方执壹份。本合同经由甲方最后确认并盖章后方可生效
                    </p>
                  </div>
                  <div v-else>
                    <h4 style="font-size: 14px">五、违约责任：</h4>
                    <p>
                      1、乙方逾期交货的，每延期一天，应支付延期交货部分货款总值1
                      %的违约金，未交货或未按时足额交货超过三
                      十日的，甲方有权不经通知而单方解除本合同，本合同所述违约金不足以弥补甲方损失的，甲方还有权要求乙方赔偿其他经济损失。
                    </p>
                    <p>
                      2、乙方所交付货物的品种、规格、质量、包装等因素中有一项不合格率高于1%（含）但低于10%（不含）的，甲方有权要求乙方在合理期限内予以更换、维修、重新包装等，并承担修理、调换、重新包装而实际支付费用。乙方逾期未在合理期限按本条约定处理的，甲方有权自行或委托第三方维修或从第三方购买，因此发生的费用由乙方全额承担。
                    </p>
                    <p>
                      3、乙方所交付货物的品种、规格、质量、包装等因素中有一项不合格率高于10%（含）但低于30%（不含）的，甲方除有权按本合同第五条第二款约定处理外，还有权将乙方已交付货物全部退回或没收并视乙方全部未交货。未交货规则按本合同第五条第一款处理。
                    </p>
                    <p>
                      4、乙方所交付货物的品种、规格、质量、包装等因素中有一项不合格率高于30%（含）的，甲方除有权按本合同第五条第二款约定、第五条第三款约定处理外，还有权不经通知而单方解除合同，并要求乙方按本合同第五条第一款承担违约责任，本合同所述违约金不足以弥补甲方损失的，甲方还有权要求乙方赔偿其他经济损失。
                    </p>
                    <p>
                      5、乙方应按本合同第三条约定向甲方提供三包服务。乙方逾期未在合理期限按本条约定提供三包服务的，甲方有权自行或委托第三方维修，因此发生的费用由乙方全额承担。
                    </p>
                    <p>
                      6、乙方逾期未按约定时间提供发票的，依本合同第五条第一款违约责任处理。
                    </p>
                  </div>
                  <div v-show="type == 3">
                    <h4 style="font-size: 14px">六、特别约定：</h4>
                    <p style="text-decoration: underline">
                      1.本合同所载双方地址、联系人、电话等系双方确认并保证能够收到或送达到的地址,双方一致同意，此地址可以做为产生争议时的法律文书送达地址，如因一方原因致使法律文书无法送达，或出现查无此人，或出现无此地址或出现其他等原因，皆视为法律文书已经送达，双方本条款皆予以理解并表示认同。
                    </p>
                    <p style="text-decoration: underline">
                      2.因一方违约，他方因请求该项债权而支出的一切诉讼费用（包括但不限于诉讼费、律师费、差旅费、交通费、保全费等）均由违约一方承担，双方本条款皆予以理解并表示认同。
                    </p>
                  </div>
                  <div>
                    <h4 v-show="type == 2" style="font-size: 14px">
                      六、不可抗力
                    </h4>
                    <h4 v-show="type == 3" style="font-size: 14px">
                      七、不可抗力
                    </h4>
                    <p v-show="type != 1">
                      1.甲乙任何一方因自然灾害、战争、疾病或其他甲乙任何一方均不能预见、不能避免且不能克服的事由（以下简
                      称“不可抗力”），
                      延迟履行或不能完全履行本合同规定的责任和义务的，遭遇不可抗力事由的一方可免除承担相应的违约责任。
                    </p>
                  </div>
                  <div v-if="type == 2">
                    <h4 style="font-size: 14px">七、合同争议的解决方式</h4>
                    <p>
                      1.本合同在履行过程中发生的争议，由双方当事人协商解决；协商或调解不成的依法向甲方所在地人民法院起诉。
                    </p>
                    <p>
                      2.本合同由双方签字、盖章后生效。如有未尽事宜，须经双方共同协商，签订补充协议，补充协议与本协议具有同等效力。本协议一式三份，甲方执二份，乙方执一份。
                    </p>
                  </div>
                  <div v-show="type == 3">
                    <h4 style="font-size: 14px">八、合同争议的解决方式</h4>
                    <p>
                      1.本合同在履行过程中发生的争议，由双方当事人协商解决；协商或调解不成的依法向甲方所在地人民法院起诉。
                    </p>
                    <p>
                      2.本合同由双方签字、盖章后生效。如有未尽事宜，须经双方共同协商，签订补充协议，补充协议与本协议具有同等效力。本协议一式三份，甲方执二份，乙方执一份。
                    </p>
                  </div>
                </div>
                <!-- name -->
                <div class="clearfix mt">
                  <div class="ftName1">
                    <div class="qzPsty fw">
                      <label>甲方（签字、签章）：</label>
                    </div>
                    <div class="fw">
                      <label>代理人：</label>
                      <p style="margin-top: 7px">
                        <input
                          class="spanSty"
                          style="border-bottom: 1px solid #000000; width: 50px"
                          type="text"
                          value=""
                        />
                        年
                        <input
                          class="spanSty"
                          style="border-bottom: 1px solid #000000; width: 30px"
                          type="text"
                          value=""
                        />
                        月
                        <input
                          class="spanSty"
                          style="border-bottom: 1px solid #000000; width: 30px"
                          type="text"
                          value=""
                        />
                        日
                      </p>
                    </div>
                  </div>
                  <div class="ftName">
                    <div class="qzPsty fw">
                      <label>乙方（签字、签章）：</label>
                    </div>
                    <div class="fw">
                      <label>代理人：</label>
                      <p style="margin-top: 7px">
                        <input
                          class="spanSty"
                          style="border-bottom: 1px solid #000000; width: 50px"
                          type="text"
                          value=""
                        />
                        年
                        <input
                          class="spanSty"
                          style="border-bottom: 1px solid #000000; width: 30px"
                          type="text"
                          value=""
                        />
                        月
                        <input
                          class="spanSty"
                          style="border-bottom: 1px solid #000000; width: 30px"
                          type="text"
                          value=""
                        />
                        日
                      </p>
                    </div>
                  </div>
                  <div>
                    <p style="font-size: 15px; font-weight: bold">
                      <br />
                      注：
                    </p>
                    <p
                      style="
                        text-align: center;
                        font-size: 15px;
                        font-weight: bold;
                      "
                    >
                      乙方收到本电子合同后应在一个工作日内签字、盖章并回传给甲方做最终确认、签字、盖章。
                    </p>
                  </div>

                  <div>
                    <p style="margin-top: 10px">
                      以上为合同正文全部内容，以下无正文。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <el-button
      v-print="printObj"
      style="position: absolute; visibility: hidden; left: -300000px"
      id="printbutton"
    >
      打印
    </el-button> -->
  </PrintWrapper>
</template>

<script>
  import { OrderInteractor } from '@/core'

  import PrintWrapper from './printWrapper'
  import viewCost from '@/views/purchase/purchaseOrder/utils'
  import NoViewCost from '@/views/purchase/purchaseOrder/components/no-view-cost'
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        isViewCost: viewCost(),
        id: '',
        info: {},
        tax: false,
        type: 3, // 1简易模板，2正式模板，3详细模板
        purchaserAddress: '',
        purchaserMan: '',
        reAddress: '',
        reMan: '',
        rePhone: '',
        defaultList: [], // 默认缓存的产品列表
        amountTotal: 0, // 合同总金额
        otherFees: 0, // 其他费用
        printFees: 0, // 印刷费
        otherExplain: '', // 版费说明
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    components: {
      PrintWrapper,
      NoViewCost,
    },
    created() {
      this.id = this.$route.query.id
      if (this.$route.query.type) this.type = this.$route.query.type
      if (this.id) {
        this.getDetail(this.id)
      } else {
        this.info = JSON.parse(sessionStorage.getItem('purchaseOrder'))
        this.type = this.info.modelName
        // 数量为0的产品不显示
        this.info.purchaseProductShowVOList =
          this.info.purchaseProductEditDTOList
            .filter((item) => Number(item.numPurchase) || Number(item.numRelax))
            .map((item) => {
              return {
                ...item,
                amountIncludingTax: item.numDemand * item.priceIncludingTax,
              }
            })
        this.defaultList = this.info.purchaseProductShowVOList
        this.getPurchaseInfo()
        this.getMaxDate()
        this.isTax()
        this.mergeProduct()
        this.otherFeesCalc()
      }
    },
    methods: {
      // 获取单价1.8.3purchase
      getShowPrice(item) {
        let totalMoney = this.getSingeProTotal(item)
        if (!totalMoney || totalMoney === '0' || totalMoney === 0) {
          return ''
        } else {
          return (totalMoney / this.calcResult(item.sku, 'numActual')).toFixed(
            3
          )
        }
      },
      getDetail(id) {
        OrderInteractor.lookPurchaseStockUp({ businessId: id }).then((res) => {
          if (res && res.code == '000000') {
            this.info = res.data
            this.info.purchaseProductShowVOList =
              res.data.purchaseProductShowVOList.filter(
                (i) => i.numActual !== 0
              )
            // 数量为0的产品不显示
            this.defaultList = this.info.purchaseProductShowVOList
            this.getPurchaseInfo()
            this.isTax()
            this.mergeProduct()
            this.otherFeesCalc()
          }
        })
      },

      // 合并相同sku的产品，针对拆分的产品需要合并
      mergeProduct() {
        let arr = []
        let product = []
        this.info.purchaseProductShowVOList.forEach((item) => {
          arr.push(item.sku)
        })
        arr = Array.from(new Set(arr))

        this.info.purchaseProductShowVOList.forEach((item, i) => {
          if (arr.includes(item.sku) && arr.length) {
            product.push(item)
            arr.forEach((sku, j) => {
              if (sku == item.sku) {
                arr.splice(j, 1)
              }
            })
          }
        })

        this.info.purchaseProductShowVOList = product
        // 合同总金额求和
        this.info.purchaseProductShowVOList.forEach((item) => {
          this.amountTotal += this.getSingeProTotal(item)
        })
      },
      // 计算单个产品金额
      getSingeProTotal(item) {
        let money =
          item.priceIncludingTax * this.calcResult(item.sku, 'numActual') +
          this.calcResult(item.sku, 'setUpCharge') +
          this.calcResult(item.sku, 'otherCost') -
          this.calcResult(item.sku, 'supplierDiscount')
        if (money && money !== 0) {
          return money.toFixed(2)
        }
      },
      // 累加结果
      calcResult(sku, field) {
        let amount = 0
        this.defaultList.forEach((item) => {
          if (item.sku == sku) {
            if (field == 'numActual') {
              if (item[field]) {
                amount +=
                  item[field] +
                  (Number(item['numAbnormal'])
                    ? Number(item['numRelax'])
                    : Number(item['numAbnormal']) || 0)
              } else {
                amount += Number(item['numPurchase']) + Number(item['numRelax'])
              }
            } else {
              amount += Number(item[field])
            }
          }
        })
        return amount
      },

      // 获取甲方信息
      getPurchaseInfo() {
        OrderInteractor.contractData({ tenantId: this.userInfo.tenantId }).then(
          (res) => {
            if (res && res.code === '000000') {
              res.data.forEach((item) => {
                if (item.name == this.info.purchaser) {
                  this.purchaserAddress = item.address
                  this.purchaserMan = item.man
                  this.reAddress = item.reAddress
                  this.reMan = item.reMan
                  this.rePhone = item.rePhone
                }
              })
            }
          }
        )
      },

      // 获取是否含税
      isTax() {
        if (this.info.purchaseProductShowVOList) {
          this.tax = this.info.purchaseProductShowVOList.some((item) => {
            let { taxRate } = item
            // fix bigMath of taxRate
            taxRate =
              typeof taxRate === 'object' && taxRate != null
                ? +taxRate.value
                : taxRate
            return taxRate > 0
          })
        } else {
          this.tax = false
        }
      },

      // 计算其他费用
      otherFeesCalc() {
        const _list = this.defaultList

        let [otherFees, printFees, explainArr] = [0, 0, []]
        for (let i = 0; i < _list.length; i++) {
          otherFees += Number(_list[i].otherCost || 0)
          printFees += Number(_list[i].setUpCharge || 0)
          explainArr.push(_list[i].costDescription)
        }
        explainArr = Array.from(new Set(explainArr))
        this.otherFees = otherFees > 0 ? otherFees.toFixed(2) : 0
        this.printFees = printFees > 0 ? printFees.toFixed(2) : 0
        this.otherExplain = explainArr.join(';')
      },

      // 预览日期取最大值
      getMaxDate() {
        let arr = []
        let date = ''
        if (this.info.purchaseProductEditDTOList) {
          this.info.purchaseProductEditDTOList.forEach((item) => {
            if (item.actualDeliveryDate) {
              arr.push(item.actualDeliveryDate.replace(/-/g, ''))
            }
          })
          if (arr.length > 0) {
            date = arr.reduce((p, v) => (p > v ? p : v))
            date =
              date.substring(0, 4) +
              '-' +
              date.substring(4, 6) +
              '-' +
              date.substring(6, 8)
            this.info.actualDeliveryDate = date
          }
        }
      },

      // 支付方式格式化
      payWay(array) {
        if (array) {
          let arr = []
          let text = ''
          let amountTotal = this.info.amountTotal

          let currency = this.info.currency == '美元' ? '$' : '￥'
          array.forEach((item) => {
            if (item.type == 0) {
              arr.push(
                (item.percent * 100).toFixed(0) + '%' + item.paymentNodeName
              )
            }
          })
          if (amountTotal) {
            let [node1, node2, node3, node4] = [0, 0, 0, 0]
            let [nodeName1, nodeName2, nodeName3, nodeName4] = ['', '', '', '']
            array.forEach((item) => {
              if (item.paymentNodeName.includes('定金')) {
                if (item.amountPayable) {
                  node1 = this.isViewCost
                    ? item.amountPayable.toFixed(2)
                    : '******'
                } else {
                  // node1 = (item.percent * amountTotal).toFixed(2)

                  node1 = this.isViewCost
                    ? (item.percent * amountTotal).toFixed(2)
                    : '******'
                }
                nodeName1 = item.paymentNodeName
              }
              if (item.paymentNodeName.includes('发货前')) {
                if (item.amountPayable) {
                  // node2 = item.amountPayable.toFixed(2)

                  node2 = this.isViewCost
                    ? item.amountPayable.toFixed(2)
                    : '******'
                } else {
                  // node2 = (item.percent * amountTotal).toFixed(2)

                  node2 = this.isViewCost
                    ? (item.percent * amountTotal).toFixed(2)
                    : '******'
                }
                nodeName2 = item.paymentNodeName
              }
              if (item.paymentNodeName.includes('发货后')) {
                if (item.amountPayable) {
                  // node3 = item.amountPayable.toFixed(2)

                  node3 = this.isViewCost
                    ? item.amountPayable.toFixed(2)
                    : '******'
                } else {
                  // node3 = (item.percent * amountTotal).toFixed(2)

                  node3 = this.isViewCost
                    ? (item.percent * amountTotal).toFixed(2)
                    : '******'
                }
                nodeName3 = item.paymentNodeName
              }
              if (item.paymentNodeName.includes('收货后')) {
                if (item.amountPayable) {
                  // node4 = item.amountPayable.toFixed(2)
                  node4 = this.isViewCost
                    ? item.amountPayable.toFixed(2)
                    : '******'
                } else {
                  // node4 = (item.percent * amountTotal).toFixed(2)

                  node4 = this.isViewCost
                    ? (item.percent * amountTotal).toFixed(2)
                    : '******'
                }
                nodeName4 = item.paymentNodeName
              }
            })
            text =
              '(' +
              (node1 ? nodeName1 + currency + node1 + ' ' : '') +
              (node2 ? nodeName2 + currency + node2 + ' ' : '') +
              (node3 ? nodeName3 + currency + node3 + ' ' : '') +
              (node4 ? nodeName4 + currency + node4 : '') +
              ')'
          }
          return arr.join(',') + text
        } else {
          return ''
        }
      },

      number_chinese(str) {
        var num = parseFloat(str)
        var strOutput = '',
          strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分'
        num += '00'
        var intPos = num.indexOf('.')
        if (intPos >= 0) {
          num = num.substring(0, intPos) + num.substr(intPos + 1, 2)
        }
        strUnit = strUnit.substr(strUnit.length - num.length)
        for (var i = 0; i < num.length; i++) {
          strOutput +=
            '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) +
            strUnit.substr(i, 1)
        }
        return strOutput
          .replace(/零角零分$/, '整')
          .replace(/零[仟佰拾]/g, '零')
          .replace(/零{2,}/g, '零')
          .replace(/零([亿|万])/g, '$1')
          .replace(/零+元/, '元')
          .replace(/亿零{0,3}万/, '亿')
          .replace(/^元/, '零元')
      },

      number_str(money, type) {
        //汉字的数字
        const cnNums = new Array(
          '零',
          '壹',
          '贰',
          '叁',
          '肆',
          '伍',
          '陆',
          '柒',
          '捌',
          '玖'
        )
        //基本单位
        const cnIntRadice = new Array('', '拾', '佰', '仟')
        //对应整数部分扩展单位
        const cnIntUnits = new Array('', '万', '亿', '兆')
        //对应小数部分单位
        let cnDecUnits = new Array('角', '分')
        //整数金额时后面跟的字符
        let cnInteger = '整'
        //整型完以后的单位
        let cnIntLast = '元'
        if (type == '美元') {
          cnDecUnits = new Array('', '美分')
          cnIntLast = '美元'
        }
        //最大处理的数字
        let maxNum = 999999999999999.99
        //金额整数部分
        let integerNum
        //金额小数部分
        let decimalNum
        //输出的中文金额字符串
        let chineseStr = ''
        //分离金额后用的数组，预定义
        let parts
        if (money == '') {
          return ''
        }
        money = parseFloat(money)
        if (money >= maxNum) {
          //超出最大处理数字
          return ''
        }
        if (money == 0) {
          chineseStr = cnNums[0] + cnIntLast + cnInteger
          return chineseStr
        }
        //转换为字符串
        money = money.toString()
        if (money.indexOf('.') == -1) {
          integerNum = money
          decimalNum = ''
        } else {
          parts = money.split('.')
          integerNum = parts[0]
          decimalNum = parts[1].substr(0, 2)
        }
        //获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
          let zeroCount = 0
          let IntLen = integerNum.length
          for (var i = 0; i < IntLen; i++) {
            let n = integerNum.substr(i, 1)
            let p = IntLen - i - 1
            let q = p / 4
            let m = p % 4
            if (n == '0') {
              zeroCount++
            } else {
              if (zeroCount > 0) {
                chineseStr += cnNums[0]
              }
              //归零
              zeroCount = 0
              chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
            }
            if (m == 0 && zeroCount < 4) {
              chineseStr += cnIntUnits[q]
            }
          }
          chineseStr += cnIntLast
        }
        //小数部分
        if (decimalNum != '') {
          let decLen = decimalNum.length
          for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1)
            if (n != '0') {
              if (type == '美元') {
                if (decLen >= 2) {
                  cnDecUnits = new Array('拾', '美分', '毫', '厘')
                  chineseStr += cnNums[Number(n)] + cnDecUnits[i]
                } else {
                  cnDecUnits = new Array('', '拾美分', '毫', '厘')
                  chineseStr += cnNums[Number(n)] + cnDecUnits[i]
                }
              } else {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i]
              }
            }
          }
        }
        if (chineseStr == '') {
          chineseStr += cnNums[0] + cnIntLast + cnInteger
        } else if (decimalNum == '') {
          chineseStr += cnInteger
        }
        return chineseStr
      },
    },
  }
</script>

<style lang="scss" scoped>
  .address {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    min-height: 20px;
    outline: 0;
    border: none;
    font-size: 13px;
    font-family: LiSong Pro Light;
    font-weight: normal;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-user-modify: read-write-plaintext-only;
  }
  [contentEditable='true']:empty:not(:focus):before {
    content: attr(data-text);
  }

  input {
    outline: none;
    background: none;
  }
  select {
    /*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
    border-top: 0px #000;
    border-left: 0px #000;
    border-right: 0px #000;
    border-bottom: 1px solid #000000;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 14px;
    font-weight: bold;
    width: 205px;
    outline: none;
    background: none;
  }
  /*清除ie的默认选择框样式清除，隐藏下拉箭头*/
  select::-ms-expand {
    display: none;
  }

  body {
    font-family: LiSong Pro Light;
  }
  * {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  /* 清除浮动 */
  .clearfix:before,
  .clearfix:after {
    content: '';
    display: block;
    clear: both;
  }

  .clearfix {
    zoom: 1;
  }

  .zhubox {
    width: 700px;
    margin: 0 auto;
  }

  .header {
    text-align: center;
    font-size: 14px;
    position: relative;
    margin-bottom: 10px;
  }

  .dingD {
    text-align: right;
    margin-top: 10px;
  }
  .dingD2 {
    float: right;
    display: inline-block;
  }

  .zthead {
    font-size: 14px;
  }

  /* 供货方 */
  .Supplier,
  .Purchaser {
    width: 100%;
    float: left;
  }
  p,
  h4 {
    min-height: 18px;
  }
  .Supplier > p {
    line-height: 25px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .Supplier > p > span {
    display: inline-block;
    min-width: 200px;
    height: 30px;
    border-bottom: 1px solid #000000;
    line-height: 25px;
    padding-right: 5px;
    vertical-align: middle;
  }
  .Supplier > p > input {
    display: inline-block;
    min-width: 200px;
    height: 30px;
    border: 0;
    border-bottom: 1px solid #000000;
    line-height: 25px;
    padding-right: 5px;
    vertical-align: middle;
  }

  .Purchaser {
    float: right;
  }

  .Purchaser p {
    line-height: 25px;
  }

  /* 日期 */
  .contDate {
    width: 100%;
    line-height: 23px;
    text-indent: 20px;
  }

  .contTime {
    float: left;
  }

  .pchTime {
    width: 300px;
    float: right;
  }

  .pchTime1 {
    width: 210px;
    float: right;
  }

  /* 表格 */
  #tableExcel {
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
  }

  #tableExcel tr td {
    width: 120px;
    height: 20px;
  }

  /* 合计 */
  .Total {
    width: 300px;
    height: 40px;
    float: left;
  }

  .Deposit {
    width: 300px;
    height: 40px;
    float: right;
  }

  /* 要求 */
  .Require {
    height: 50px;
    border: 1px solid #000000;
  }

  .reqTxt1 {
    display: inline-block;
    width: 70px;
    height: 50px;
    border-right: 1px solid #000000;
    text-align: center;
    line-height: 50px;
    vertical-align: top;
  }

  .reqTxt {
    display: inline-block;
    width: 600px;
  }

  .reqTxt2 {
    display: inline-block;
    width: 73px;
    vertical-align: top;
  }

  /* 唛头图片 */
  .zhum,
  .cem {
    width: 320px;
    float: left;
  }

  .cem {
    float: right;
  }

  .zhumCont,
  .cemCont {
    width: 100%;
    height: 70px;
    border: 1px solid #000000;
  }

  .ftName {
    float: right;
    width: 350px;
  }

  .ftName1 {
    width: 350px;
    float: left;
  }

  .ftName input {
    height: 20px;
    border: 0;
  }

  .ftName1 input {
    height: 20px;
    border: 0;
  }
  /* 图片 */
  .proImgb {
    margin-top: 20px;
    display: inline-block;
  }
  .proImgb > h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  .proImgb > ul > li {
    width: 49%;
    min-height: 50%;
    float: left;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  .proImgb > ul > li:nth-child(odd) {
    margin-right: 10px;
  }
  .proImgb > ul > li > img {
    width: 100%;
  }
  .proImgb > ul > li > h3 {
    text-align: center;
    line-height: 37px;
  }

  /* 公共类 */
  .fsz {
    font-size: 12px;
  }
  .fsz > p {
    line-height: 20px;
  }
  .fsz1 {
    font-size: 13px;
  }

  .mt {
    margin-top: 10px;
  }

  .fw {
    font-weight: bold;
  }
  .spanSty {
    display: inline-block;
    min-width: 10px;
    height: 26px;
    line-height: 26px;
    border: none;
    vertical-align: middle;
    text-align: center;
  }
  .qzPsty {
    margin-top: 20px;
  }
  .plhSty {
    line-height: 25px;
  }
  .ft_iptW {
    width: 75%;
  }
  table.aaa-table {
    td {
      border: 1px solid gray;
      border-top: none;
      padding: 5px;
      min-height: 35px;
      &.item1 {
        border-right: none;
      }
    }
  }

  table.item-table {
    td {
      // border: 1px solid gray;
    }
  }
  .fs14 {
    font-size: 14px;
  }
</style>
