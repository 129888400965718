<template>
  <div class="custom-container pl30 pr30" style="margin-top: -12px">
    <div>
      <h4>基本信息</h4>

      <p
        style="font-size: 14px; margin-bottom: 10px"
        v-if="orderInfo.updateRemark"
      >
        修改原因:{{ orderInfo.updateRemark }}
      </p>
      <div class="c-table-wrap">
        <table class="custom-table">
          <thead>
            <tr>
              <th>销售单号</th>
              <th>报价单号</th>
              <th>签约日期</th>
              <th>出口抬头</th>
              <th>订单来源</th>
              <th>客户代表</th>
              <th>创建人</th>
              <th>创建时间</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ orderInfo.orderCode || '--' }}</td>
              <td>
                <span
                  @click="
                    viewOffer(orderInfo.enquiryId, orderInfo.priceSheetCode)
                  "
                  v-if="orderInfo.priceSheetCode"
                  class="page-link"
                >
                  {{ orderInfo.priceSheetCode }}
                </span>
                <span v-else>--</span>
              </td>
              <td>{{ orderInfo.contractDate || '--' }}</td>
              <td>{{ orderInfo.exportsRise || '--' }}</td>
              <td>{{ orderInfo.orderSource || '--' }}</td>
              <td>{{ orderInfo.businessName || '--' }}</td>
              <td>{{ orderInfo.creatorName || '--' }}</td>
              <td>{{ orderInfo.creatorTime || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="c-table-wrap">
        <table class="custom-table mt10">
          <thead>
            <tr>
              <th>客户姓名</th>
              <th>客户邮箱</th>
              <th>公司名称</th>
              <th>公司详细地址</th>
              <th>联系人</th>
              <th>联系电话</th>
              <th>客户订单号</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ orderInfo.customerName || '--' }}</td>
              <td>{{ orderInfo.customerEmail || '--' }}</td>
              <td>{{ orderInfo.companyName || '--' }}</td>
              <td>
                <span class="disable-color">
                  {{ computedCompanyDetailedAddress || '--' }}
                </span>
              </td>
              <td>{{ orderInfo.customerName || '--' }}</td>
              <td>{{ orderInfo.customerPhone || '--' }}</td>
              <td>{{ orderInfo.customerOrderCode || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        style="margin-top: 15px; color: red; font-weight: bold"
        v-if="orderInfo.customerPiFlag == 1 && belonger"
      >
        提示：此PI审核通过后，客户默认分管给当前客户代表。
        当前客户负责人归属于：{{ belonger }}
      </p>
      <el-table
        border
        class="mt30"
        ref="multipleTable"
        :data="collectCargoList"
      >
        <el-table-column
          type="index"
          width="50"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="address"
          width="300"
          align="center"
          label="收货地址"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="收货公司"
          prop="companyName"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="收货人"
          prop="consigneeName"
          align="center"
        ></el-table-column>
        <el-table-column
          label="联系电话"
          prop="consigneePhone"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip content="查看绑定产品" placement="top">
              <el-button
                plain
                type="primary"
                @click="viewProduct(scope.row.addressId)"
                size="mini"
                icon="el-icon-connection"
                circle
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        border
        class="mt30"
        style="width: 50%"
        ref="multipleTable"
        :data="collectCargoList"
      >
        <el-table-column label="装运港" prop="productType" align="center">
          <template slot-scope="scope">
            {{ scope.row.portOfLoading }}
          </template>
        </el-table-column>
        <el-table-column label="目的港" prop="productType" align="center">
          <template slot-scope="scope">
            {{ scope.row.destination }}
          </template>
        </el-table-column>
      </el-table>

      <h4>收款情况</h4>
      <div class="c-table-wrap">
        <table class="custom-table">
          <thead>
            <tr>
              <th>订单总金额</th>
              <th>应收金额</th>
              <th>已收金额</th>
              <th>抵扣金额</th>
              <th>抵扣人民币</th>
              <th>抵扣说明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="gray f_s_16">
                <span class="bold">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{
                  (orderInfo[orderInfo.currency === '美元' ? 'totlePrice' :'rmbTotlePrice'] * 1 +
                  orderInfo.discountAmountTotle * 1
                  ) | | orderMoneyFormat}}
                </span>
              </td>
              <td>
                <span v-if="orderInfo.receivablePrice" class="green f_s_16">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{ orderInfo.receivablePrice | orderMoneyFormat }}
                </span>
                <span v-else>0.00</span>
              </td>
              <td
                class="f_s_16"
                :class="
                  orderInfo.receivedPrice >= orderInfo.receivablePrice
                    ? 'green'
                    : 'red'
                "
              >
                <span v-if="orderInfo.receivedPrice">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{ orderInfo.receivedPrice | orderMoneyFormat }}
                </span>
                <span v-else>0.00</span>
              </td>
              <td>
                <span v-if="orderInfo.usMoney">
                  ${{ orderInfo.usMoney | orderMoneyFormat }}
                </span>
                <span v-else>0.00</span>
              </td>
              <td>
                <span v-if="orderInfo.rmbMoney">
                  ￥{{ orderInfo.rmbMoney | orderMoneyFormat }}
                </span>
                <span v-else>0.00</span>
              </td>
              <td>{{ orderInfo.discountRemark || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="c-table-wrap">
        <table class="custom-table mt10">
          <thead>
            <tr>
              <th>收款环节</th>
              <th>收款比例</th>
              <th>应收款金额</th>
              <th>预计收款时间</th>
              <th>实收款金额</th>
              <th>实际收款时间</th>
              <th>收款单号</th>
              <th>收款状态</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in orderInfo.salesGatheringVOS" :key="i">
              <td>{{ item.paymentLink || '--' }}</td>
              <td>{{ item.paymentPropor + '%' || '--' }}</td>
              <td>
                <span v-if="item.receivablePrice">
                  {{ orderInfo.currency == '美元' ? '$' : '￥' }}
                  {{ item.receivablePrice | orderMoneyFormat }}
                </span>
                <span v-else>0.00</span>
              </td>
              <td>{{ item.receivablePriceDate || '--' }}</td>
              <td>{{ item.actualReceivablePrice | orderMoneyFormat }}</td>
              <td>{{ item.actualReceivablePriceDate || '--' }}</td>
              <td>{{ item.voucherNo || '--' }}</td>
              <td>
                <span v-if="item.voucherStatus == 1">待处理</span>
                <span v-else-if="item.voucherStatus == 2">待认领</span>
                <span v-else-if="item.voucherStatus == 3">已驳回</span>
                <span v-else-if="item.voucherStatus == 4">已完成</span>
                <span v-else-if="item.voucherStatus == 5">已作废</span>
                <span v-else>--</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>产品列表</h4>
      <div>
        <el-table border :data="orderInfo.orderProductVOS">
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>

          <el-table-column label="产品图" align="center" width="140px">
            <template slot-scope="scope">
              <div class="tag-wrap">
                <el-tag size="mini">{{ scope.row.standardCustomized }}</el-tag>
                <el-image
                  v-if="scope.row.image || scope.row.imgMain"
                  :src="getImg(scope.row.image || scope.row.imgMain)"
                  class="c_pointer"
                  @click="handlePreview(scope.row.image || scope.row.imgMain)"
                ></el-image>
                <span v-else>--</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="产品信息"
            class-name="more"
            align="center"
            min-width="200"
          >
            <template slot-scope="scope">
              <p class="text-left">产品编码：{{ scope.row.sku || '--' }}</p>
              <p class="text-left">
                产品名称：{{ scope.row.productEn || '--' }}
              </p>
              <p class="text-left">
                英文规格：{{
                  scope.row.specification === 'undefined'
                    ? '--'
                    : scope.row.specification || '--'
                }}
              </p>
              <p class="text-left">
                中文规格：{{
                  scope.row.specificationCn === 'undefined'
                    ? '--'
                    : scope.row.specificationCn || '--'
                }}
              </p>
              <p class="text-left">
                产品运营：{{ scope.row.productBelonger || '--' }}
              </p>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('orderDetails.customerDeliveryTime')"
            min-width="260"
            align="center"
          >
            <template slot-scope="scope">
              <p class="text-left">
                {{ $t('orderDetails.TransportationType') }}：{{
                  (scope.row.productExtend &&
                    scope.row.productExtend.shippingMethod) ||
                  '--'
                }}
              </p>
              <p class="text-left">
                {{ $t('orderDetails.PlanShipmentDate') }}：{{
                  (scope.row.productExtend &&
                    scope.row.productExtend.planShippingDate) ||
                  '--'
                }}
              </p>
              <p style="display: flex; align-items: center">
                {{ $t('orderDetails.customerDeliveryTime') }}：{{
                  (scope.row.productExtend &&
                    scope.row.productExtend.customerDeliveryDate) ||
                  '--'
                }}
                <el-tag
                  size="mini"
                  type="warning"
                  effect="dark"
                  style="margin-left: 6px"
                  v-if="
                    scope.row.productExtend &&
                    scope.row.productExtend.firmFlag == 1
                  "
                >
                  Firm 交期
                </el-tag>
              </p>
            </template>
          </el-table-column>
          <el-table-column label="订单数量" align="center" width="120">
            <template slot-scope="scope">{{ scope.row.amount || 0 }}</template>
          </el-table-column>
          <el-table-column label="售价" align="center">
            <template slot-scope="scope">
              {{ scope.row.netPrice || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="印刷效果图" align="center" width="200">
            <template slot-scope="scope">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.rendingsUrl"
              >
                <el-image
                  :src="getFirstImgUrl(scope.row.rendingsUrl)"
                  class="c_pointer"
                  @click="handlePreview(scope.row.rendingsUrl.split(','))"
                  style="width: 50px; height: 50px"
                ></el-image>
                <span
                  class="fileAnnex-span"
                  v-if="scope.row.rendingsUrl.split(',').length > 1"
                >
                  {{ scope.row.rendingsUrl.split(',').length }}
                </span>
              </el-row>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="印刷方式" align="center" width="120">
            <template slot-scope="scope">
              {{ scope.row.printingWayEn || scope.row.printingWay || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="Setup Charge($)" align="center" width="140">
            <template slot-scope="scope">
              {{ scope.row.setUpCharge || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="Shipping Fee" align="center" width="130">
            <template slot-scope="scope">
              {{ scope.row.shippingFee || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="库存" class-name="more" align="center">
            <template slot-scope="scope">
              {{ scope.row.warehouseNumber || '--' }}
            </template>
          </el-table-column>

          <el-table-column label="计价方式" align="center">
            <template slot-scope="scope">
              {{
                (scope.row.productExtend &&
                  scope.row.productExtend.valuationMethodValue) ||
                '--'
              }}
            </template>
          </el-table-column>

          <el-table-column label="印刷位置" align="center">
            <template slot-scope="scope">
              {{
                (scope.row.productExtend &&
                  scope.row.productExtend.printingPositionValue) ||
                '--'
              }}
            </template>
          </el-table-column>

          <el-table-column
            label="产品描述"
            class-name="more"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <ErpWrapText :text="scope.row.productDescription" />
            </template>
          </el-table-column>

          <el-table-column
            label="印刷描述"
            class-name="more"
            align="center"
            width="160"
          >
            <template slot-scope="scope">
              <ErpWrapText :text="scope.row.printingEffect" />
            </template>
          </el-table-column>

          <el-table-column label="印刷文件" align="center" width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.printedDocumentsUrl">
                <div
                  class="flie-list"
                  v-for="(file, i) in JSON.parse(scope.row.printedDocumentsUrl)"
                  :key="i"
                  style="width: 300px"
                >
                  <i class="el-icon-document"></i>
                  <a class="page-link ml5">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="file.name"
                      placement="top-start"
                    >
                      <span class="ell" style="max-width: 120px">
                        <router-link
                          :to="`/preview-file/index?name=${file.name}&url=${file.url}`"
                          target="_blank"
                        >
                          {{ file.name }}
                        </router-link>
                      </span>
                    </el-tooltip>
                  </a>
                  <span class="size ml10" style="white-space: nowrap">
                    {{ utils.calculateFileSize(file.size) }}
                  </span>
                  <span class="ml10" style="white-space: nowrap">
                    {{ file.time }}
                  </span>
                </div>
              </div>
              <div v-else>未上传</div>
            </template>
          </el-table-column>

          <el-table-column label="其他费用($)" align="center" width="150">
            <template slot-scope="scope">
              {{ scope.row.otherCharge || '--' }}
            </template>
          </el-table-column>

          <el-table-column label="费用说明" align="center">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.chargeFees" placement="top">
                <div class="limit-text">{{ scope.row.chargeFees || '--' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="备注" align="center">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.remark" placement="top">
                <div class="limit-text">{{ scope.row.remark || '--' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="客户折扣" align="center">
            <template slot-scope="scope">
              {{ scope.row.discountAmount }}
            </template>
          </el-table-column>
          <el-table-column label="客户折扣说明" align="center" width="180">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.discountReason" placement="top">
                <div class="limit-text">
                  {{ scope.row.discountReason || '--' }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="订单支持" align="center">
            <template slot-scope="scope">
              {{ scope.row.fromBusinessName || '--' }}
            </template>
          </el-table-column>

          <el-table-column label="退货数量" align="center">
            <template slot-scope="scope">
              {{ scope.row.refundProductAmount || 0 }}
            </template>
          </el-table-column>

          <el-table-column label="总计" align="center" width="120">
            <template slot-scope="scope">
              ￥{{ scope.row.rmbTotle | orderMoneyFormat }}
              <br />
              ${{ scope.row.usTotle | orderMoneyFormat }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="total-money">
        总数量
        <span class="mr20">{{ orderInfo.totleAmount || 0 }}</span>
        总折扣
        <span class="mr20">
          {{ orderInfo.discountAmountTotle > 0 ? '-' : '' }}
          {{ orderInfo.discountAmountTotle | orderMoneyFormat }}
        </span>
        总金额
        <span class="red">${{ orderInfo.totlePrice | orderMoneyFormat }}</span>
      </div>
      <h4>客户备注</h4>
      <div class="text-pre-wrap">{{ orderInfo.customerRemark }}</div>
      <h4>内部备注</h4>
      <div class="text-pre-wrap">{{ orderInfo.remark }}</div>
      <h4>附件</h4>
      <div v-show="fileList.length">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
    </div>

    <!-- 绑定产品 -->
    <el-dialog
      title="绑定产品"
      :visible.sync="productListVisible"
      width="800px"
      append-to-body
    >
      <table class="custom-table l-table">
        <thead>
          <tr>
            <th width="500">Item</th>
            <th>订单数量</th>
            <th>印刷方式</th>
            <th>售价</th>
            <th>发货数量</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in productList" :key="i">
            <td>
              <span v-show="item.productCode">{{ item.productCode }} /</span>
              <span v-show="item.productEn">{{ item.productEn }} /</span>
              {{ item.specification }}
            </td>
            <td>{{ item.amount || '--' }}</td>
            <td>{{ item.printingWayEn || item.printingWay || '--' }}</td>
            <td>{{ item.netPrice || '--' }}</td>
            <td>{{ item.quantTotle || '--' }}</td>
          </tr>
        </tbody>
      </table>
      <div class="text-center mt20">
        <el-button @click="productListVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 安排订单 -->
    <el-dialog title="安排订单" :visible.sync="planOrderVisible" width="1100px">
      <el-form :model="form" ref="ruleForm">
        <div class="c-table-wrap">
          <table class="custom-table">
            <thead>
              <tr>
                <th width="300">Item</th>
                <th width="100">规格</th>
                <th width="100">Qty</th>
                <th width="200">
                  <span>
                    <i>*</i>
                    期望交期
                  </span>
                </th>
                <th width="200">
                  <span>
                    <i>*</i>
                    采购开发
                  </span>
                </th>
                <th>订单备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in form.planOrderList" :key="i">
                <td>
                  <span v-show="item.productCode">
                    {{ item.productCode }} /
                  </span>
                  <span v-show="item.productEn">{{ item.productEn }} /</span>
                </td>
                <td>{{ item.specification || '--' }}</td>
                <td>{{ item.amount || '--' }}</td>
                <td>
                  <el-form-item
                    :prop="'planOrderList.' + i + '.expectedDeliveryDate'"
                    :rules="[
                      {
                        required: true,
                        message: '期望交期必填',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-date-picker
                      v-model="item.expectedDeliveryDate"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    :prop="'planOrderList.' + i + '.salesName'"
                    :rules="[
                      {
                        required: true,
                        message: '采购开发必填',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      v-model="item.salesName"
                      @focus="businessChange(i)"
                      placeholder="请选择"
                    ></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    :prop="'planOrderList.' + i + '.remark'"
                    :rules="[
                      {
                        max: 500,
                        message: '不能超过500字符',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="item.remark"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-right mt5 f_s_12">
            采购订单初次审核通过后，会自动发送“安排生产”邮件给客户
          </p>
        </div>
      </el-form>
      <div class="text-center mt20">
        <el-button @click="planOrderCancel()">取消</el-button>
        <el-button type="primary" @click="planOrderSave()">确认</el-button>
      </div>
    </el-dialog>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 报价单预览 -->
    <el-dialog :visible.sync="offerSheetVisible" width="1210px" append-to-body>
      <div class="offer-container">
        <offerSheet
          v-if="offerSheetVisible"
          :offerSheetInfo="offerSheetInfo"
        ></offerSheet>
      </div>
      <div class="text-center mt20">
        <el-button @click="offerSheetVisible = false">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </div>
</template>

<script>
  import offerSheet from '@/views/order/inquiryManage/inquiryPrint.vue'
  import UserChoose from '@/components/userChoose'
  import CompanyDetailListInteractor from '@/core/interactors/new-customer-manage/detail'
  import { OrderListInteractor, OrderInteractor } from '@/core'
  import { URL_COMPRESS } from '@/config'

  export default {
    components: {
      UserChoose,
      offerSheet,
    },
    props: {
      detailId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        active: '1',
        orderInfo: {},
        shipInfo: {},
        form: {
          planOrderList: [],
        },
        offerSheetInfo: {
          enquiryIds: '',
          priceSheetCode: '',
        },
        planOrderIndex: '',
        businessArray: [],
        productList: [],
        waitTable: [],
        fileList: [],
        productListVisible: false,
        planOrderVisible: false,
        offerSheetVisible: false,
        imgUrl: [], //图片预览url
        showViewer: false, //图片预览参数
        collectCargoList: [],
        belonger: '',
        computedCompanyDetailedAddress: '',
        countryOptions: [],
      }
    },
    watch: {
      detailId: {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.getDetail(value)
        },
      },
    },

    methods: {
      getImg(url) {
        if (url) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },
      getFirstImgUrl(urls) {
        if (urls) {
          urls = urls.replace(URL_COMPRESS, '')
        }
        let url = urls ? urls.split(',')[0] : ''
        if (url) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        let res = await OrderListInteractor.listCountryApi(data)
        if (res.code === '000000') this.countryOptions = res.data
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.orderInfo.companyCountry
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.orderInfo.companyAddress
          return
        }
        this.getStateFn(countryObj.id).then((data) => {
          let ProvinceObj = data.filter(
            (item) => item.code === this.orderInfo.companyProvince
          )?.[0]
          let companyCountry = countryObj.name
          let companyProvince = ProvinceObj?.name
          let addressArr = [
            companyCountry,
            companyProvince || this.orderInfo.companyProvince,
            this.orderInfo.companyCity,
            this.orderInfo.companyAddress,
          ]
          this.computedCompanyDetailedAddress = addressArr
            .filter((item) => item)
            .join('/')
        })
      },
      // 获取详情
      async getDetail(code) {
        await this.countryList()
        let self = this
        // 订单信息
        OrderInteractor.orderNewGetDetailByOrderCodeApi({
          orderCode: code,
        }).then((res) => {
          if (res?.code === '000000') {
            self.orderInfo = res.data ? res.data : {}
            self.fileList = res.data?.accessoryUrl
              ? JSON.parse(res.data.accessoryUrl)
              : []
            self.getDetailedAddress()
            CompanyDetailListInteractor.getCustomerInfo(
              self.orderInfo.infoId
            ).then((res2) => {
              if (res2 && res2.code === '000000') {
                this.belonger = res2.data.belongerName
              }
            })
          }
        })
        // 出库/出运信息
        OrderInteractor.orderShipInfoApi({ orderCode: code }).then((res) => {
          self.shipInfo = res.data ? res.data : {}
          // 收货信息集合
          if (self.shipInfo.shipVOS && self.shipInfo.shipVOS.length) {
            self.collectCargoList = self.shipInfo.shipVOS.map((item) => {
              return {
                ...item,
                shippingMethod: self.shipInfo.shippingMethod,
                deliveryDate: self.shipInfo.deliveryDate,
                shippingDate: self.shipInfo.shippingDate,
                checked: false,
                destination: self.shipInfo.destination,
                portOfLoading: self.shipInfo.portOfLoading,
              }
            })
          }
          // 已安排出运信息
          if (self.shipInfo?.alreadShipProductVOS?.length) {
            self.shipInfo.alreadShipProductVOS.forEach((item) => {
              if (item.productCourseVOList?.length) {
                item.productCourseVOList.forEach((item2, index2) => {
                  if (item2.remark && item2.statusName === '1') {
                    item.remarkArr = []
                    item2.remark.split(',').forEach((item2, index3) => {
                      let obj = {
                        label: item2.split(':')[0],
                        value: item2.split(':')[1],
                      }
                      item.remarkArr.push(obj)
                    })
                    item.businessCourseId = item2.businessCourseId
                  }
                })
              }
            })
          }
          self.waitTable = res?.data?.shipProductVOS?.length
            ? res.data.shipProductVOS
            : []
        })
      },

      // 查看报价单
      viewOffer(id, code) {
        this.offerSheetInfo.enquiryIds = id
        this.offerSheetInfo.priceSheetCode = code
        this.offerSheetVisible = true
      },

      // 查看绑定产品
      viewProduct(id) {
        OrderInteractor.addressProductApi({ addressId: id }).then((res) => {
          this.productList = res.data
          this.productListVisible = true
        })
      },

      // 安排订单
      planOrderSave() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            OrderInteractor.orderAlignOrderListApi({
              alignOrderDTOS: this.form.planOrderList,
            }).then((res) => {
              if (res && res.code == '000000') {
                this.$message.success('保存成功！')
                this.getDetail(this.$route.query.orderCode)
                this.active = '2'
                this.planOrderVisible = false
              }
            })
          }
        })
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 选择业务员
      businessChange(i) {
        this.planOrderIndex = i
        this.$refs['UserChoose'].showAddEdit('1', this.businessArray)
      },

      // 获取业务员
      chooseUser(userIdArr) {
        this.form.planOrderList[this.planOrderIndex].salesId = userIdArr[0].id
        this.form.planOrderList[this.planOrderIndex].salesName =
          userIdArr[0].name
      },

      // 安排订单取消
      planOrderCancel() {
        this.$refs['ruleForm'].resetFields()
        this.planOrderVisible = false
      },

      // 多选事件
      handleSelectionChange(val) {
        this.form.planOrderList = val.map((item, i) => {
          return {
            ...item,
            expectedDeliveryDate: '',
            salesId: '',
            salesName: '',
          }
        })
      },

      // 安排产品
      planProduct(type, row) {
        if (type == 1 && !this.form.planOrderList.length) {
          this.$message.warning('至少选择一条！')
          return false
        }
        if (type == 2) {
          this.form.planOrderList = []
          this.form.planOrderList.push({
            ...row,
            expectedDeliveryDate: '',
            salesId: '',
            salesName: '',
          })
        }
        this.planOrderVisible = true
      },
      // 获取印刷效果图
      getRendingImg(urls) {
        let _html = '--'
        if (urls) {
          _html = `<img src="${urls.split(',')[0]}" width="100" />`
        }
        return _html
      },

      // 切换选项卡
      handleClick(index) {},

      // 返回上一页
      goBack() {
        this.$router.push('/order/orderList')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tag-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px; // 添加顶部边距，为tag腾出空间

    .el-tag {
      position: absolute;
      top: -16px; // 调整tag的位置，避免被截断
      right: 0;
      z-index: 2;
      max-width: 100%; // 确保tag不会超出容器宽度
      overflow: hidden; // 处理超长文本
      text-overflow: ellipsis; // 超长文本显示省略号
      white-space: nowrap; // 防止文本换行
    }

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 2px;
    }
  }

  .total-money {
    line-height: 40px;
    text-align: right;

    span {
      font-family: 'PingFang Bold';
      font-size: 18px;
      margin-left: 5px;
    }
  }

  .table-container {
    display: flex;
    justify-content: space-between;

    .l-table {
      width: 69%;
      height: min-content;
    }

    .r-table {
      width: 30%;
      height: min-content;
    }
  }

  .public-table {
    border: 1px solid #ebeef5 !important;
  }

  .line-style {
    display: flex;
    justify-content: flex-start;
    height: 32px;
    line-height: 32px;
    margin-top: 20px;
  }

  .tl {
    text-align: left;
  }

  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff !important;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }
</style>
