<template>
  <div class="table-container">
    <el-row :gutter="20" class="mb20">
      <!-- 货币设置 -->
      <!-- v-allowed="['Currency:Setting']" -->
      <el-col :span="8">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">货币设置</p>
          </el-row>
          <el-table :data="currencyList" border :max-height="tableMaxHeight">
            <el-table-column
              label="国家货币"
              prop="financeName"
              align="center"
            />
            <el-table-column
              prop="code"
              align="center"
              label="代码"
            ></el-table-column>
            <el-table-column
              prop="exchangeRate"
              align="center"
              label="汇率"
            ></el-table-column>
            <el-table-column
              prop="effTime"
              align="center"
              label="生效时间"
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    v-show="scope.row.financeSetupId != '1'"
                    @click="editCurrency(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="历史记录" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-document"
                    plain
                    circle
                    size="mini"
                    v-show="scope.row.financeSetupId != '1'"
                    @click="currencyRecord(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">发票设置</p>
            <el-button
              circle
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="editInvoice()"
            ></el-button>
          </el-row>
          <el-table :data="invoiceList" border :max-height="tableMaxHeight">
            <el-table-column
              label="公司名称"
              prop="financeName"
              align="center"
            />
            <el-table-column align="center" label="是否默认">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  icon="el-icon-star-off"
                  circle
                  plain
                  style="
                    background: #ffba00;
                    border-color: #ffba00;
                    color: #ffffff;
                  "
                  v-if="scope.row.defaultSet == 1"
                  :disabled="scope.row.defaultSet == 1"
                  size="mini"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="150"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope" v-if="scope.row.defaultSet !== 1">
                <el-tooltip content="设为默认" placement="top">
                  <el-button
                    type="warning"
                    icon="el-icon-star-off"
                    circle
                    plain
                    size="mini"
                    :disabled="scope.row.defaultSet == 1"
                    @click="setDefaultInvoice(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="editInvoice(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removeInvoice(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">支付方式</p>
            <el-button
              circle
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="editPayment()"
            ></el-button>
          </el-row>
          <el-table :data="paymentList" border :max-height="tableMaxHeight">
            <el-table-column
              label="支付方式"
              prop="financeName"
              align="center"
            />
            <el-table-column prop="charge" align="center" label="手续费">
              <template slot-scope="scope">
                {{ scope.row.charge == 1 ? '计算' : '不计算' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="editPayment(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removePayment(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">科目</p>
            <el-button
              circle
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="editSubject()"
            ></el-button>
          </el-row>
          <el-table :data="subjectList" border :max-height="tableMaxHeight">
            <el-table-column
              label="科目名称"
              prop="financeName"
              align="center"
            />
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="editSubject(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removeSubject(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">费用杂项</p>
            <el-button
              circle
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="editCost()"
            ></el-button>
          </el-row>
          <el-table :data="costList" border :max-height="tableMaxHeight">
            <el-table-column
              label="费用名称"
              prop="financeName"
              align="center"
            />
            <el-table-column label="分摊方式" align="center">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.expenseMethod == 1
                      ? '数量比例'
                      : scope.row.expenseMethod == 2
                      ? '金额比例'
                      : scope.row.expenseMethod == 3
                      ? '手动分摊'
                      : '--'
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="editCost(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removeCost(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>

      <!-- 账户设置 -->
      <el-col :span="12">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">账户设置</p>
            <el-button
              circle
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="() => editReceiver('')"
            ></el-button>
          </el-row>
          <el-table
            :data="accountSettingList"
            border
            :max-height="tableMaxHeight"
          >
            <el-table-column label="账户" prop="financeName" align="center">
              <template slot-scope="scope">
                {{ getAccount(scope.row) }} <el-tag type="danger" v-if="scope.row.cbsFlag === 1 && scope.row.shroffType != 3" size="mini">CBS</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="类型" align="center" width="120">
              <template slot-scope="scope">
                {{ getShroffTypeName(scope.row.shroffType) }}
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100" align="center">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.shorffStatus === 1 ? 'success' : 'danger'"
                >
                  {{ scope.row.shorffStatus === 1 ? '启用' : '禁用' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="编辑"
                  placement="top-start"
                  v-if="scope.row.shorffStatus == 1"
                >
                  <el-button type="primary" plain circle size="mini" @click="editReceiver(scope.row)">
                    <i class="el-icon-edit"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启用"
                  v-if="scope.row.shorffStatus === 0"
                  placement="top-start"
                >
                  <el-button
                    type="primary"
                    plain
                    circle
                    size="mini"
                    @click="toggleStatus(scope.row,1)"
                  >
                    <i class="iconfont icon-qiyong"></i>
                  </el-button>
                </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="禁用"
                    placement="top-start"
                    v-if="scope.row.shorffStatus == 1"
                  >
                    <el-button
                      type="danger"
                      plain
                      circle
                      size="mini"
                      @click="toggleStatus(scope.row,0)"
                    >
                      <i class="iconfont icon-lajiyoujian"></i>
                    </el-button>
                  </el-tooltip>
                </el-tooltip>

                <el-tooltip
                  content="删除"
                  placement="top"
                  v-if="scope.row.shorffStatus === 0"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removeReceiver(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="8">
        <el-row class="public-col">
          <el-row type="flex" justify="space-between" align="middle">
            <p class="module-name">出口抬头默认收款账户</p>
            <el-button
              circle
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="editRise()"
            ></el-button>
          </el-row>
          <el-table :data="riseList" border :max-height="tableMaxHeight">
            <el-table-column
              label="出口抬头"
              prop="exportsRise"
              align="center"
            />
            <el-table-column label="收款账户"
              prop="shroffAccount"
              align="center"
            />
            <el-table-column
              align="center"
              label="操作"
              width="100"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="mini"
                    @click="editRise(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    circle
                    size="mini"
                    @click="removeRise(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <CurrencySetting ref="CurrencySetting" @success="getCurrencyList" />
    <InvoiceSetting ref="InvoiceSetting" @success="getInvoiceList" />
    <PaymentSetting ref="PaymentSetting" @success="getPaymentList" />
    <SubjectSetting ref="SubjectSetting" @success="getSubjectList" />
    <CostSetting ref="CostSetting" @success="getCostList" />
    <ReceiverSetting ref="ReceiverSetting" @success="getAccountSettingList" />
    <CurrencyRecord ref="CurrencyRecord" />
    <RiseSetting ref="RiseSetting" @success="getRiseList" />
  </div>
</template>

<script>
  import CurrencySetting from './components/currency-setting-model'
  import InvoiceSetting from './components/invoice-setting-model'
  import PaymentSetting from './components/payment-setting-model'
  import SubjectSetting from './components/subject-setting-model'
  import CostSetting from './components/cost-setting-model'
  import ReceiverSetting from './components/receiver-setting-model'
  import CurrencyRecord from './components/currency-record-model'
  import RiseSetting from './components/rise-setting-model'
  import {
    FinanceCurrencyList,
    FinanceInvoiceList,
    FinanceSubjectList,
    FinancePaymentList,
    FinanceCostList,
    FinanceInvoiceSetDefault,
    FinanceInvoiceDelete,
    FinanceSubjectDelete,
    FinancePaymentDelete,
    FinanceCostDelete,
    listShroffAccountPage,
    updateShroffAccountStatus,
    deleteById,
    shroffAccountExportsListPage,
    shroffAccountExportsDelete,
  } from '@/api/finance/finance-setting'
  export default {
    name: 'FinanceSetting',
    components: {
      CurrencySetting,
      InvoiceSetting,
      PaymentSetting,
      SubjectSetting,
      CostSetting,
      ReceiverSetting,
      CurrencyRecord,
      RiseSetting,
    },
    data() {
      return {
        currencyList: [],
        invoiceList: [],
        paymentList: [],
        subjectList: [],
        costList: [],
        receiverList: [],
        accountSettingList: [],
        riseList: [], // 出口抬头默认收款账户
      }
    },
    computed: {
      tableMaxHeight() {
        return (window.innerHeight - 45 - 20 - 20 - 20) / 2 - 30 - 20 - 20
      },

    },
    created() {
      this.getCurrencyList()
      this.getInvoiceList()
      this.getPaymentList()
      this.getSubjectList()
      this.getCostList()
      // this.getReceiverList()
      this.getAccountSettingList()
      this.getRiseList()
    },
    methods: {
      // 获取货币列表
      getCurrencyList() {
        FinanceCurrencyList().then((res) => {
          if (res.code === '000000') {
            this.currencyList = res.data || []
          }
        })
      },
      //新增/编辑货币
      editCurrency(row) {
        this.$refs.CurrencySetting.showDialog(row)
      },
      //历史记录
      currencyRecord(row) {
        this.$refs.CurrencyRecord.showDialog(row.financeSetupId)
      },
      // 获取发票列表
      getInvoiceList() {
        FinanceInvoiceList().then((res) => {
          if (res.code === '000000') {
            this.invoiceList = res.data || []
          }
        })
      },
      //新增/编辑发票
      editInvoice(row) {
        this.$refs.InvoiceSetting.showDialog(row)
      },
      //删除发票
      removeInvoice(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          FinanceInvoiceDelete({
            financeSetupId: row.financeSetupId,
          })
            .then((res) => {
              if (res.code === '000000') {
                this.$message.success('删除成功！')
                this.getInvoiceList()
              }
            })
            .catch((err) => {})
        })
      },
      // 获取支付方式列表
      getPaymentList() {
        FinancePaymentList().then((res) => {
          if (res.code === '000000') {
            this.paymentList = res.data || []
          }
        })
      },
      //新增/编辑支付方式
      editPayment(row) {
        this.$refs.PaymentSetting.showDialog(row)
      },
      // 设置默认发票
      setDefaultInvoice(row) {
        FinanceInvoiceSetDefault({ financeSetupId: row.financeSetupId })
          .then((res) => {
            if (res.code === '000000') {
              this.$message.success('设置成功！')
              this.getInvoiceList()
            }
          })
          .catch((err) => {})
      },
      //删除支付方式
      removePayment(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          FinancePaymentDelete({
            financeSetupId: row.financeSetupId,
          })
            .then((res) => {
              if (res.code === '000000') {
                this.$message.success('删除成功！')
                this.getPaymentList()
              }
            })
            .catch((err) => {})
        })
      },
      // 获取科目列表
      getSubjectList() {
        FinanceSubjectList().then((res) => {
          if (res.code === '000000') {
            this.subjectList = res.data || []
          }
        })
      },
      //新增/编辑科目
      editSubject(row) {
        this.$refs.SubjectSetting.showDialog(row)
      },
      //删除科目
      removeSubject(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          FinanceSubjectDelete({
            financeSetupId: row.financeSetupId,
          })
            .then((res) => {
              if (res.code === '000000') {
                this.$message.success('删除成功！')
                this.getSubjectList()
              }
            })
            .catch((err) => {})
        })
      },
      // 获取费用列表
      getCostList() {
        FinanceCostList().then((res) => {
          if (res.code === '000000') {
            this.costList = res.data || []
          }
        })
      },
      //新增/编辑费用
      editCost(row) {
        this.$refs.CostSetting.showDialog(row)
      },
      //删除费用
      removeCost(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          FinanceCostDelete({
            financeSetupId: row.financeSetupId,
          })
            .then((res) => {
              if (res.code === '000000') {
                this.$message.success('删除成功！')
                this.getCostList()
              }
            })
            .catch((err) => {})
        })
      },
      // 获取收款列表
      // getReceiverList() {
      //   FinanceReceiverList().then((res) => {
      //     if (res.code === '000000') {
      //       this.receiverList = res.data || []
      //     }
      //   })
      // },
      //新增/编辑收款单信息
      editReceiver(row) {
        this.$refs.ReceiverSetting.showDialog(row)
      },
      //删除收款单信息
      removeReceiver(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          deleteById(
           row.shroffAccountId,
          )
            .then((res) => {
              if (res.code === '000000') {
                this.$message.success('删除成功！')
                this.getAccountSettingList()
              }
            })
            .catch((err) => {})
        })
      },

      // 账户设置列表启用禁用
      toggleStatus({ shroffAccountId },shorffStatus) {
        const msg1 = shorffStatus == 1 ? '启用' :'禁用'
        this.$confirm(`是否确认${shorffStatus == 1 ? '启用' :'禁用'}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          updateShroffAccountStatus({
            shroffAccountId,
            shorffStatus
          }).then((res) => {
            if (res.code == '000000') {
              this.$message.success(`${shorffStatus == 1 ? '启用' :'禁用'}成功`)
              this.getAccountSettingList()
            }
          })
        })
      },


      // 获取账户设置列表
      getAccountSettingList() {
        listShroffAccountPage().then((res) => {
          if (res && res.code === '000000') {
            this.accountSettingList = res.data.data || []
          }
        })
      },

      // 账户设置列表账户字段
      getAccount({ payee, bankDeposit, bankAccount }) {
        return `${payee || '--'}/${bankDeposit || '--'}/${bankAccount || '--'}`
      },

      // 账户设置列表类型
      getShroffTypeName(shroffType) {
        const type2text = {
          2: '付款,收款',
          3: '收款',
          4: '付款',
        }
        return type2text[shroffType] || '--'
      },
      // 出口抬头默认收款账户-获取列表
      async getRiseList() {
        try {
          const { code, data } = await shroffAccountExportsListPage()
          if (code === '000000' && Array.isArray(data.data)) {
            this.riseList = data.data
          }
        } catch (e) {
          console.log(e)
        }
      },
      // 出口抬头默认收款账户-新增编辑
      editRise(row) {
        this.$refs.RiseSetting.showDialog(row)
      },
      // 出口抬头默认收款账户-删除
      removeRise(row) {
        this.$confirm('是否确认删除？', '提示', {
          type: 'warning',
        }).then(() => {
          shroffAccountExportsDelete(row.id)
            .then((res) => {
              if (res.code === '000000') {
                this.$message.success('删除成功！')
                this.getRiseList()
              }
            })
            .catch((err) => {})
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .table-container {
    display: flex;
    flex-direction: column;
    > .el-row {
      flex: 1;
      > .el-col {
        height: 100%;
      }
    }
  }
  .public-col {
    border: 1px solid #ededed;
    padding: 10px;
    height: 100%;
    .el-row {
      margin-bottom: 20px;
      height: 30px;
    }
  }
</style>
