export const invalidChars = [
  '~',
  '`',
  '·',
  '!',
  '！',
  '@',
  '#',
  '$',
  '￥',
  '%',
  '^',
  '…',
  '&',
  '+',
  '-',
  '*',
  '/',
  ':',
  '：',
  ';',
  '；',
  '"',
  '“',
  '”',
  "'",
  '‘',
  '’',
  '\\',
  '|',
  '<',
  '>',
  '《',
  '》',
  ',',
  '，',
  '.',
  '。',
  '?',
  '？',
  '、',
  '_',
  '—',
  '=',
  ' ',
  '[',
  ']',
  '【',
  '】',
  '{',
  '}',
]
