<template>
  <el-dialog
    title="提交询价"
    width="1200px"
    class="custom-dialog"
    :visible.sync="inquiryDemandVisible"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="container">
      <div class="c-table-wrap">
        <table class="custom-table">
          <thead>
            <tr>
              <th width="300">产品名称</th>
              <th>产品图片</th>
              <th>需求数量</th>
              <th width="300">产品类别</th>
              <th width="300">产品描述</th>

              <th width="300">印刷描述</th>
              <th>印刷参考图</th>
              <th width="300">备注说明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ detail.productEn }}</td>
              <td>
                <div class="img-wrap">
                  <el-badge
                    v-show="
                      detail.productImg &&
                      detail.productImg.split(',').length > 1
                    "
                    :value="
                      detail.productImg
                        ? detail.productImg.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="detail.productImg"
                    :src="detail.productImg.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(detail.productImg.split(','))"
                  ></el-image>
                  <div v-else>--</div>
                </div>
              </td>
              <td>{{ detail.amount }}</td>
              <td>{{ detail.category || '--' }}</td>
              <td>{{ detail.productDescribe }}</td>

              <td>{{ detail.printingDescribe || '--' }}</td>

              <td>
                <div class="img-wrap">
                  <el-badge
                    v-show="
                      detail.printingImg &&
                      detail.printingImg.split(',').length > 1
                    "
                    :value="
                      detail.printingImg
                        ? detail.printingImg.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="detail.printingImg"
                    :src="detail.printingImg.split(',')[0]"
                    class="c_pointer"
                    @click="handlePreview(detail.printingImg.split(','))"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </td>
              <td>{{ detail.remarks || '--' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <el-form ref="ruleForm" :model="form">
        <el-table
          class="mt20"
          :data="form.inquiryOrderSupplierVOList"
          border
          :header-cell-style="{ textAlign: 'center' }"
          :header-cell-class-name="star"
          width="100%"
        >
          <el-table-column
            type="index"
            label="#"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column label="供应商名称" width="200">
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'inquiryOrderSupplierVOList.' + scope.$index + '.supplierName'
                "
                :rules="[
                  {
                    required: true,
                    message: '供应商必填',
                    trigger: 'blur',
                  },
                  {
                    max: 100,
                    message: '字符长度不能超过100',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].supplierName
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="产品图片" width="120">
            <template slot-scope="scope">
              <el-form-item
                :prop="'inquiryOrderSupplierVOList.' + scope.$index + '.image'"
                :rules="[
                  {
                    required: true,
                    message: '产品图片必填',
                    trigger: 'blur',
                  },
                ]"
              >
                <div
                  class="wrapper-box"
                  @click="choseRendingImages(scope.$index, scope.row.image)"
                >
                  <el-badge
                    v-show="
                      scope.row.image && scope.row.image.split(',').length > 1
                    "
                    :value="
                      scope.row.image ? scope.row.image.split(',').length : ''
                    "
                  ></el-badge>
                  <div class="wrapper-image" v-show="scope.row.image">
                    <img :src="getFirstImgUrl(scope.row.image)" alt />
                  </div>
                  <div class="wrapper-add" v-show="!scope.row.image">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <!--印刷logo位置/尺寸  -->
          <el-table-column label="印刷logo位置/尺寸" width="150">
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'inquiryOrderSupplierVOList.' +
                  scope.$index +
                  '.actualLogoSize'
                "
                :rules="[
                  {
                    required: true,
                    message: '印刷logo位置/尺寸必填',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  maxlength="500"
                  type="textarea"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].actualLogoSize
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 产品颜色 -->

          <el-table-column label="产品颜色" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].productColor
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <!-- 起订量 -->
          <el-table-column label="起订量" width="150">
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'inquiryOrderSupplierVOList.' + scope.$index + '.quantity'
                "
                :rules="[
                  {
                    required: true,
                    message: '起订量必填',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  :min="0"
                  :precision="0"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].quantity
                  "
                  :controls="false"
                  placeholder="请输入"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>

          <!-- 起订价(元) -->
          <el-table-column label="起订价(元)" width="150">
            <template slot-scope="scope">
              <el-form-item
                :prop="'inquiryOrderSupplierVOList.' + scope.$index + '.price'"
                :rules="[
                  {
                    required: true,
                    message: '起订价(元)必填',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  :controls="false"
                  :min="0"
                  :precision="3"
                  v-model="form.inquiryOrderSupplierVOList[scope.$index].price"
                  placeholder="请输入"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>

          <!--  交期(工作日) -->

          <el-table-column label="交期(工作日)" width="180">
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'inquiryOrderSupplierVOList.' + scope.$index + '.completeDays'
                "
                :rules="[
                  {
                    required: true,
                    message: '交期(工作日)必填',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input-number
                  style="width: 150px"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].completeDays
                  "
                  :controls="false"
                  :min="0"
                  :precision="0"
                  placeholder="请输入"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>

          <!-- 印刷方式-->
          <el-table-column label="印刷方式" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].printingWay
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 打样费用(元) -->
          <el-table-column label="打样费用(元)" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input-number
                  :controls="false"
                  :min="0"
                  :precision="3"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].proofingFee
                  "
                  placeholder="请输入"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 打样时间(工作日) -->
          <el-table-column label="打样时间(工作日)" width="180">
            <template slot-scope="scope">
              <el-form-item>
                <el-input-number
                  style="width: 150px"
                  :controls="false"
                  :min="0"
                  :precision="0"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].proofingDays
                  "
                  placeholder="请输入"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 箱规(外箱尺寸，装箱数，毛重) -->
          <el-table-column label="箱规(外箱尺寸，装箱数，毛重)" width="220">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="500"
                  type="textarea"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].boxSpecs
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!--  单个产品克重(克)-->
          <el-table-column label="单个产品克重(克)" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input-number
                  :controls="false"
                  :min="0"
                  :precision="3"
                  type="textarea"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].productWeightg
                  "
                  placeholder="请输入"
                  clearable
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 产品材质 -->
          <el-table-column label="产品材质" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].material
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 产品尺寸 -->
          <el-table-column label="产品尺寸" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].productSize
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 联系人 -->
          <el-table-column label="联系人" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].contacts
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 联系方式-->
          <el-table-column label="联系方式" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index]
                      .contactInformation
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 产品链接-->
          <el-table-column label="产品链接" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="100"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index].linkHref
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 采购员意见/评价 -->
          <el-table-column label="采购员意见/评价" width="150">
            <template slot-scope="scope">
              <el-form-item>
                <el-input
                  maxlength="500"
                  type="textarea"
                  v-model="
                    form.inquiryOrderSupplierVOList[scope.$index]
                      .purchasingComments
                  "
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
              <el-button
                :class="scope.row.recommendFlag == 1 ? 'icon-y' : ''"
                @click="recommend(scope.$index)"
                size="mini"
                icon="iconfont icon-good"
                circle
              ></el-button>
              <el-button
                class="icon-r"
                @click="del(scope.$index)"
                size="mini"
                icon="el-icon-delete"
                circle
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="add">
        <span @click="add" class="c_pointer">新增一行</span>
      </div>
    </div>

    <div class="text-center mt20">
      <el-button @click="save()" type="primary" :loading="loading">
        确定
      </el-button>
      <el-button @click="close()">取消</el-button>
    </div>

    <!-- 选择图片 -->
    <choose-images
      :visible.sync="chooseImagesDialogVisible"
      :default-file-list="currentDefaultRendingList"
      @confirm="setRendingImages"
    ></choose-images>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
  </el-dialog>
</template>

<script>
import ChooseImages from '../components/chooseImages'
import { InquiryOrderInteractor } from '@/core/interactors/purchase/InquiryOrder'
export default {
  components: {
    ChooseImages,
  },
  data() {
    return {
      inquiryOrderId: '',
      form: {
        inquiryOrderSupplierVOList: [
          {
            supplierName: '', // 供应商名称
            supplierId: '', // 供应商id
            image: '', // 图片
            actualLogoSize: '', //logo
            productColor: '', //产品颜色
            quantity: undefined, //起订量
            price: undefined, //起订价
            completeDays: undefined, //交期工作日
            printingWay: '', //印刷方式 1.5.4
            proofingFee: undefined, //打样费 1.5.4
            proofingDays: undefined, //打样时间(工作日) 1.5.4
            boxSpecs: '', //箱规
            productWeightg: undefined, //产品重量g 1.5.4
            material: '', //材质 1.5.4
            productSize: '', //尺寸
            contacts: '', //	联系人 1.5.4
            contactInformation: '', //联系方式
            linkHref: '', // 链接
            purchasingComments: '', //采购意见 1.5.4
            recommendFlag: '', // 是否推荐
          },
        ],
      },
      detail: {},
      inquiryDemandVisible: false,
      showViewer: false, //图片预览参数
      imgUrl: '', //图片预览url
      chooseImagesDialogVisible: false, // 选择图片弹窗
      currentRendingIndex: -1, // 当前选择图片的产品，对应的 index
      currentDefaultRendingList: [], // 当前选中的图片
      loading: false,
    }
  },
  methods: {
    // 初始化数据
    initLoading(id) {
      InquiryOrderInteractor.inquiryGetDetailForEditApi({ businessId: id }).then((res) => {
        this.inquiryOrderId = id
        if (res && res.code == '000000') {
          this.detail = res.data
          this.inquiryDemandVisible = true
        }
      })
    },

    // 新增一行
    add() {
      if (this.form.inquiryOrderSupplierVOList.length > 19) {
        this.$message.warning('供应商最多新增20条！')
      } else {
        this.form.inquiryOrderSupplierVOList.push({
          supplierName: '', // 供应商名称
          supplierId: '', // 供应商id
          offerPrice: '', // 报价
          deliveryCycle: '', // 交货周期
          packingMethod: '', // 包装方式
          boxSpecs: '', // 箱规
          image: '', // 图片
          linkHref: '', // 链接
          remarks: '', // 备注说明
          recommendFlag: '', // 是否推荐
        })
      }
    },

    // 删除一行
    del(i) {
      this.$confirm(`是否确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.form.inquiryOrderSupplierVOList.splice(i, 1)
          this.$message.success('删除成功！')
        })
        .catch(() => {})
    },

    // 确定
    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (!this.form.inquiryOrderSupplierVOList.length) {
            this.$message.warning('至少添加一条供应商！')
            return false
          }

          this.loading = true
          const params = {
            ...this.detail,
            inquiryOrderId: this.inquiryOrderId,
            inquiryOrderSupplierDTOList: [
              ...this.form.inquiryOrderSupplierVOList,
            ],
          }
          const res = await InquiryOrderInteractor.inquiryHandleApi(params)
          
          if (res && res.code == '000000') {
            this.$message.success('保存成功！')

            if (typeof this.$parent.getList === 'function') {
              this.$parent.getList()
            } else {
              this.$emit('get-list')
            }
            this.inquiryDemandVisible = false
            this.form = this.$options.data().form
          }
          this.loading = false
        }
      })
    },

    // 推荐供应商
    recommend(i) {
      let list = this.form.inquiryOrderSupplierVOList
      if (list[i].recommendFlag == 1) {
        list[i].recommendFlag = 0
        this.$message.info('已取消！')
      } else {
        list[i].recommendFlag = 1
        this.$message.success('已推荐！')
      }
    },

    // table添加必填样式
    star({ row, rowIndex, column, columnIndex }) {
      if ([1, 2, 3, 5, 6, 7].includes(columnIndex)) {
        return 'star'
      }
    },

    // 获取第一张图片
    getFirstImgUrl(urls) {
      return urls ? urls.split(',')[0] : ''
    },

    // 显示 选择图片 弹窗
    choseRendingImages(index, urls) {
      this.chooseImagesDialogVisible = true
      this.currentRendingIndex = index
      this.currentDefaultRendingList = []
      if (urls) {
        let _urls = urls.split(',')
        for (let i = 0; i < _urls.length; i++) {
          this.currentDefaultRendingList.push({
            url: _urls[i],
            name: '',
          })
        }
      }
    },

    // 大图预览
    handlePreview(item) {
      if (Array.isArray(item)) {
        this.imgUrl = item
      } else {
        this.imgUrl = [item]
      }
      this.showViewer = true
    },

    // 取消预览
    closeViewer() {
      this.showViewer = false
    },

    // 设置对应产品的 图片
    setRendingImages(url) {
      this.form.inquiryOrderSupplierVOList[this.currentRendingIndex].image = url
      this.$refs.ruleForm.validate(() => {})
    },

    // 关闭弹窗重置表单
    close() {
      this.inquiryDemandVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  .container {
    .add {
      margin: 15px 0;
      text-align: center;
      color: #409eff;
    }
    .img-wrap {
      position: relative;
      .el-image {
        width: 56px;
        height: 56px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
        border-radius: 2px;
      }
      .el-badge {
        position: absolute;
        top: -4px;
        right: 12px;
        z-index: 2;
      }
    }
    .wrapper-box {
      text-align: center;
      position: relative;
      .wrapper-add {
        display: inline-block;
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 4px;
        cursor: pointer;
        i {
          font-size: 18px;
        }
      }
      .wrapper-image {
        img {
          width: 64px;
          height: 64px;
          margin-top: 8px;
          border-radius: 2px;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .el-badge {
        position: absolute;
        top: 4px;
        right: 8px;
      }
    }
  }
}
::v-deep {
  .el-table td {
    padding: 0;
  }
  .el-form-item {
    margin: 0;
    padding: 14px 0;
  }
  .el-table table th.star div:before {
    content: '*';
    color: red;
  }
  .el-table__body-wrapper {
    height: 360px;
    overflow: scroll;
  }
}
</style>
