<template>
  <div :class="['custom-container', isAudit ? 'audit-wrapper' : '']">
    <el-row v-if="!isAudit">
      <el-page-header
        title="返回"
        @back="goBack()"
        content="库存调拨详情"
      ></el-page-header>
    </el-row>

    <h4 style="margin: 0 0 10px">基本信息</h4>
    <div class="custom-detail">
      <el-row>
        <el-col>
          <em>出库方式：</em>
          <span>
            {{ detail.warehouseOutType == 0 ? '调拨出库' : '同仓调拨' }}
          </span>
        </el-col>
        <el-col>
          <em>调出仓库：</em>
          <span>{{ detail.warehouseOutName || '--' }}</span>
        </el-col>
        <el-col>
          <em>调入仓库：</em>
          <span>{{ detail.warehouseInName || '--' }}</span>
        </el-col>
        <el-col>
          <em>调拨单号：</em>
          <span>
            {{ detail.transferOrderNumber || '--' }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <em style="width: 100px">调拨入库单号：</em>
          <span
            style="width: calc(100% - 100px)"
            class="page-link-noline"
            v-if="detail.transferWarehouseInNumber"
            @click="goDetail(detail)"
          >
            {{ detail.transferWarehouseInNumber }}
          </span>
          <span v-else style="width: calc(100% - 100px)">--</span>
        </el-col>
        <el-col>
          <em>交货方式：</em>
          <span>{{ detail.deliveryMode | getMode }}</span>
        </el-col>
        <el-col>
          <em style="width: 80px">物流单号：</em>
          <el-tooltip
            class="item"
            effect="dark"
            :content="detail.shipNo"
            placement="top-start"
            :disabled="!detail.shipNo"
          >
            <span class="text-hidden" style="width: calc(100% - 80px)">
              {{ detail.shipNo || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <el-col>
          <em style="width: 110px">预计到达时间：</em>
          <span style="width: calc(100% - 110px)">
            {{ detail.arriveTime || '--' }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <em>所属地区：</em>
          <span>{{ detail.area || '--' }}</span>
        </el-col>
        <el-col>
          <em>创建人：</em>
          <span>{{ detail.creator || '--' }}</span>
        </el-col>
        <el-col>
          <em>创建时间：</em>
          <span>{{ detail.createTime || '--' }}</span>
        </el-col>
        <el-col>
          <em>操作人：</em>
          <span>{{ detail.updator || '--' }}</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <em>额外费用：</em>
          <span>
            {{ (detail.cost && detail.cost.toFixed(3)) || '--' }}
          </span>
        </el-col>
        <el-col>
          <em>分摊方式：</em>
          <span>{{ detail.expenseMethod | expenseMethodType }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="width: 100%">
          <em>备注：</em>
          <span>{{ detail.transferOrderRemark || '--' }}</span>
        </el-col>
      </el-row>
    </div>

    <h4>产品信息</h4>
    <el-table
      :data="detail.transferOrderProductVOS"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      class="mt20 dyn-table"
      :span-method="objectSpanMethod"
    >
      <el-table-column prop="skuId" label="产品编码" width="160">
        <template slot-scope="scope">
          <el-link
            type="primary"
            @click="skuIdClick(scope.row)"
            v-if="scope.row.skuId.indexOf('FL') === -1"
          >
            {{ scope.row.skuId }}
          </el-link>
          <span v-else>
            {{ scope.row.skuId }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="productSpec"
        label="英文规格/中文规格"
        width="200"
        show-overflow-tooltip=""
      >
        <template slot-scope="scope">
          <div>
            <span class="ell" style="width: 160px; display: inline-block">
              {{ $hasChineseChar(scope.row.productSpec) }}/{{
                scope.row.productSpecCn || '--'
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="productType"
        label="产品类型"
        width="150"
      ></el-table-column>

      <el-table-column prop="productUnit" label="单位"></el-table-column>
      <el-table-column prop="stockNum" label="库存"></el-table-column>
      <el-table-column
        prop="lotNo"
        label="批次号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="批次单价"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="num"
        label="调拨出库数量"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="transferOrderLotRemark"
        label="备注"
        show-overflow-tooltip
        width="200px"
      >
        <template slot-scope="scope">
          <p
            v-if="scope.row.transferOrderLotRemark"
            class="text-hidden"
            style="width: 100%"
          >
            {{ scope.row.transferOrderLotRemark }}
          </p>
          <p v-else>--</p>
        </template>
      </el-table-column>
      <el-table-column prop="lotNo" label="分摊费用">
        <template slot-scope="scope">
          {{
            (scope.row.adjustTotalPrice &&
              scope.row.adjustTotalPrice.toFixed(3)) ||
            '--'
          }}
        </template>
      </el-table-column>
      <el-table-column
        width="300"
        prop="lotNo"
        label="调出库位  调入库位"
        v-if="detail.warehouseOutType == 1"
      >
        <template slot-scope="scope">
          <SelectedLocs
            :locs="scope.row.transferOrderLocationVOS"
            showInloc
            :tableIndex="scope.$index"
            @add-loc="showLoc"
          />
        </template>
      </el-table-column>
      <el-table-column width="160" prop="lotNo" label="出库库位" v-else>
        <template slot-scope="scope">
          <SelectedLocs
            :locs="scope.row.transferOrderLocationVOS"
            :tableIndex="scope.$index"
            @add-loc="showLoc"
          />
        </template>
      </el-table-column>
    </el-table>

    <h4>附件</h4>
    <ErpUpload
      ref="ErpUpload"
      :uploadParams="uploadParams"
      @initUpload="initUpload"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
    ></ErpUpload>
    <LocDetail
      ref="loc-detail"
      :showInloc="detail.warehouseOutType === '1'"
      :tableData="currentRow.transferOrderLocationVOS"
    ></LocDetail>
  </div>
</template>

<script>
  import { ProductListInteractor, AllotInquireInteractor } from '@/core'
  import SelectedLocs from '@/views/stock-center/inbound-sheet/components/selected-locs.vue'
  import LocDetail from '@/views/stock-center/inbound-sheet/components/base-loc-detail'
  import popoverShipClick from '@/views/stock-center/outbound-sheet/components/popoverShipClick.vue'

  const mergeColumns = [
    '产品编码',
    '产品名称',
    // '规格',
    '英文规格/中文规格',
    '产品类型',
    '单位',
    '库存',
  ]
  export default {
    props: {
      detailId: {
        type: String,
        default: '',
      },
    },
    components: {
      SelectedLocs,
      LocDetail,
      popoverShipClick,
    },
    data() {
      return {
        costAdjustId: '', // 成本调整id
        detail: {}, //详情
        uploadParams: {
          //文件参数
          uploadButton: false,
        },
        spanArr: [], //合并处理参数
        pos: 0, //合并处理参数
        currentRow: {}, //当前行
      }
    },

    computed: {
      // 是否是审核页面
      isAudit() {
        const path = this.$route.path
        return path == '/audit-process/audit-manage/index' || path === '/home'
      },
    },

    created() {
      this.getDetail(this.$route.query.id || this.detailId)
    },
    filters: {
      // 模式转换
      getMode(val) {
        const code2text = {
          2: '国际物流',
          3: '国内物流',
          4: '自送',
        }
        return code2text[val] || '--'
      },

      // 分弹方式转换
      expenseMethodType(val) {
        const code2text = {
          5: '数量分摊',
          6: '金额分摊',
          7: '手动分摊',
        }
        return code2text[val] || '--'
      },
    },
    methods: {
      // 展示库位弹窗
      showLoc(index) {
        let row = this.detail.transferOrderProductVOS[index]
        this.currentRow = row
        this.$refs['loc-detail'].show()
      },

      // 查看入库详情
      goDetail({ transferWarehouseInId: warehouseInId }) {
        let routeData = this.$router.resolve({
          name: 'inbound-sheet-detail',
          query: {
            warehouseInId,
            noReturn: true,
          },
        })

        window.open(routeData.href, '_blank')
      },
      skuIdClick({ skuId }) {
        this.getProductIdByCode(skuId.split('-')[0])
      },

      //根据产品code，查询产品id
      async getProductIdByCode(id) {
        // 辅料不能跳转
        if (id.indexOf('FL') > -1) return
        let response = await ProductListInteractor.productIdByCode(id)
        if (response.code === '000000') {
          let routeData = this.$router.resolve({
            path: '/product/ProductView',
            query: {
              proId: response.data,
              noReturn: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      //合并单元格getSpanArr
      getSpanArr(list) {
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            if (
              list[i].transferOrderProductId + list[i].skuId ===
              list[i - 1].transferOrderProductId + list[i - 1].skuId
            ) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },

      /**
       * 合并单元格objectSpanMethod
       */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (mergeColumns.includes(column.label)) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      // 表格数据展开
      extractTableData(list = []) {
        let tableData = []
        list.forEach((item) => {
          const {
            productCode,
            productId,
            productName,
            productSpec,
            productSpecCn,
            productType,
            productUnit,
            skuId,
            stockNum,
          } = item

          item.transferOrderLotVOS.forEach((subItem) => {
            tableData.push({
              productCode,
              productId,
              productName,
              productSpec,
              productSpecCn,
              productType,
              productUnit,
              skuId,
              stockNum,
              ...subItem,
            })
          })
        })
        console.log(tableData)
        return tableData
      },
      // 获取详情
      async getDetail(id) {
        const res = await AllotInquireInteractor.getTransferOrderDetail(id)
        if (res && res.code === '000000') {
          res.data.transferOrderProductVOS = this.extractTableData(
            res.data.transferOrderProductVOS
          )
          if (res.data.transferOrderUrl) {
            this.initUpload(res.data.transferOrderUrl, false)
          }

          this.detail = res?.data || []
          this.getSpanArr(this.detail.transferOrderProductVOS)
          // 将warehouseInId, warehouseOutId传递给父组件
          const { warehouseInId, warehouseOutId } = res.data || {}
          const ids = [warehouseInId, warehouseOutId].join()
          this.$emit('ids', ids)
        }
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },

      // 显示文件
      initUpload(data, closable) {
        this.$refs.ErpUpload.initUpload(data, closable)
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    padding: 0 20px 20px 20px;
  }

  .audit-wrapper {
    padding: 0 20px 20px 20px !important;
  }

  ::v-deep {
    .el-table__footer-wrapper {
      .has-gutter {
        height: 40px;
        font-family: 'PingFang Bold';
        .required-th::before {
          display: none;
        }
        td {
          text-align: center;
        }
      }
    }
  }
  .mr5 {
    margin-right: 5px;
  }
</style>
