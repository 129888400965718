<template>
  <div
    class="table-container custom-container"
    v-if="$route.meta.showParent"
    :key="$route.path"
  >
    <el-row
      class="page-header align_center"
      type="flex"
      justify="space-between"
    >
      <el-row type="flex" class="align_center">
        <el-button
          type="primary"
          @click="addNew"
          size="small"
          icon="el-icon-plus"
          v-allowed="['NEW-INBOUND']"
        >
          新增
        </el-button>
        <!-- 查询表单 -->
        <el-form
          ref="form"
          class="ml10"
          :inline="true"
          label-width="49px"
          :model="queryForm"
          size="small"
          @submit.native.prevent
        >
          <el-form-item prop="name">
            <el-input
              style="width: 300px"
              v-model="queryForm.name"
              clearable
              placeholder="输入入库单号/采购单号/订单编号/加工单号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="warehouseId">
            <el-select
              v-model="queryForm.warehouseId"
              placeholder="入库仓库"
              style="width: 130px"
              clearable
            >
              <el-option
                v-for="item in warehouses"
                :key="item.warehouseId"
                :value="item.warehouseId"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="type">
            <el-select
              v-model="queryForm.type"
              placeholder="入库方式"
              clearable
              style="width: 130px"
            >
              <el-option
                v-for="item in types"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 入库时间 -->
          <el-form-item prop="date">
            <el-date-picker
              clearable
              v-model="queryForm.date"
              align="right"
              type="daterange"
              style="width: 240px"
              value-format="yyyy-MM-dd"
              start-placeholder="入库开始时间"
              end-placeholder="入库结束时间"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="init">搜索</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-row>
      <el-row>
        <!-- 自定义展示列 -->
        <el-popover
          ref="showOption"
          popper-class="custom-table-checkbox"
          trigger="hover"
        >
          <erp-draggable v-bind="dragOptions" :list="columns">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="item + index"
              v-model="item.isShow"
              :disabled="item.checkable === false"
              :label="item.label"
            >
              {{ item.label }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button
              type="primary"
              class="ml10"
              size="mini"
              plain
              @click="operateSave(true)"
            >
              保存
            </el-button>
          </div>
          <template #reference>
            <el-tooltip
              effect="dark"
              content="列表可拖动排序"
              placement="top-start"
            >
              <el-button plain type="primary" class="pub-h-36">
                自定义显示
              </el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </el-row>
    </el-row>
    <p class="divider"></p>
    <el-radio-group v-model="currentStatus">
      <el-radio :label="1">待入库</el-radio>
      <el-radio :label="0">审核中</el-radio>
      <el-radio :label="2">已入库</el-radio>
      <el-radio :label="5">异常入库</el-radio>
      <el-radio :label="3">审核驳回</el-radio>
      <el-radio :label="4">已撤销</el-radio>
      <el-radio label="">全部</el-radio>
    </el-radio-group>

    <ErpTable
      :tableData="tableData"
      :extraHeight="120"
      :pagination="false"
      :key="customTableKey"
      :default-sort="defaultSort"
      @sort-change="sortChange"
      class="mt15"
    >
      <el-table-column
        align="center"
        min-width="120"
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :width="item.width"
        :resizable="false"
        :prop="item.prop"
        :sortable="item.sortable ? 'custom' : false"
      >
        <template slot-scope="scope">
          <!-- 入库单单号 -->
          <span
            v-if="item.prop === 'warehouseInNumber'"
            class="erp-table-link"
            @click="goDetail(scope.row)"
          >
            {{ scope.row.warehouseInNumber }}
          </span>

          <!-- 关联编号 -->
          <span
            v-else-if="item.prop === 'orderNumber'"
            @click="goRelatedDetail(scope.row)"
          >
            <template v-if="scope.row.orderNumber">
              <span class="erp-table-link">{{ scope.row.orderNumber }}</span>
            </template>
            <template v-else>--</template>
          </span>
          <!-- 申请入库数量 -->
          <span v-else-if="item.prop === 'expectNum'">
            {{ scope.row.type === 2 ? '--' : scope.row.expectNum }}
          </span>

          <!--  不含税入库成本-->
          <el-row v-else-if="item.prop === 'inPrice'">
            <BaseStar v-if="scope.row.flag === 0"></BaseStar>
            <span v-else>
              <span v-if="scope.row.inPrice">
                {{ toFixed3(scope.row.inPrice) }}
              </span>
              <span v-else>-</span>
            </span>
          </el-row>
          <!--  含税入库成本-->
          <el-row v-else-if="item.prop === 'inTaxPrice'">
            <BaseStar v-if="scope.row.flag === 0"></BaseStar>
            <span v-else>
              <span v-if="scope.row.inTaxPrice">
                {{ toFixed3(scope.row.inTaxPrice) }}
              </span>
              <span v-else>0.000</span>
            </span>
          </el-row>
          <!-- 状态 -->
          <el-row v-else-if="item.prop === 'status'">
            <el-tooltip
              class="item"
              effect="light"
              :content="scope.row.auditRemark"
              placement="top-start"
              :disabled="![3, 4].includes(scope.row.status)"
            >
              <el-tag
                :class="{ pointer: [0, 3].includes(scope.row.status) }"
                :type="getTagType(scope.row.status)"
                @click="showReviewProgress(scope.row)"
              >
                {{ scope.row.status | statusFilter }}
              </el-tag>
            </el-tooltip>
          </el-row>
          <el-row v-else-if="item.prop === 'warehouseInDate'">
            <span>
              {{
                scope.row.warehouseInDate
                ? scope.row.warehouseInDate.split(' ')[0]
                : '--'
              }}
            </span>
          </el-row>
          <!-- 效果图 -->
          <el-row v-else-if="item.prop === 'printingImgs'">
            <PreviewImage :id="scope.row.warehouseInId" imageType="1" type="inbound" :Images="scope.row.printingImgs | imagesFormat"></PreviewImage>
          </el-row>
          <!-- 产品图 -->
          <el-row v-else-if="item.prop === 'productImages'">
            <PreviewImage :id="scope.row.warehouseInId" imageType="2" type="inbound" :Images="scope.row.productImages | imagesFormat"></PreviewImage>
          </el-row>
          <span v-else>{{ scope.row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        label="操作"
        align="center"
        :resizable="false"
        width="120px"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="查看详情"
            placement="top-start"
          >
            <el-button
              type="primary"
              plain
              icon="iconfont icon-chakan"
              circle
              size="mini"
              @click="goDetail(scope.row)"
            ></el-button>
          </el-tooltip>

          <el-dropdown style="margin-left: 15px">
            <el-button
              type="primary"
              plain
              icon="el-icon-more"
              circle
              :disabled="[3, 4].includes(scope.row.status)"
            ></el-button>
            <el-dropdown-menu slot="dropdown" placement="bottom">
              <el-dropdown-item v-if="scope.row.status == 1">
                <p @click="confirmSheet(scope.row)">确认入库</p>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="[0].includes(scope.row.status) && scope.row.type === 2"
              >
                <p @click="cancel(scope.row)">撤销审核</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <template v-slot:pagination>
        <Pagination
          v-if="page.total > 0"
          :limit.sync="page.pageLe"
          :page.sync="page.pageNo"
          :total="page.total"
          @pagination="onPagination"
        />
      </template>
    </ErpTable>

    <!-- 撤销弹窗 -->
    <el-dialog
      title="撤销原因"
      :visible="cancelVisible"
      append-to-body
      width="450px"
      @close="onCancel"
      modal-append-to-body
    >
      <span style="color: red; position: absolute; left: 10px">*</span>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入撤销原因"
        maxlength="500"
        show-word-limit
        v-model="cancelReason"
      ></el-input>
      <div class="footer mt30 text-center">
        <el-button type="primary" @click="cancelSure">确认</el-button>
        <el-button @click="onCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 审核进度Drawer -->
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-="statusClose"
    >
      <Audit
        ref="Audit"
        @cancelClick="statusClose"
        :auditObjectId="currentAuditObjectId"
      />
    </el-drawer>
  </div>
  <div v-else class="pro-container" :key="$route.path">
    <router-view />
  </div>
</template>

<script>
  import { InboundSheet, StoreCheck } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import Pagination from '@/components/Pagination/Index'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import BaseStar from '@/components/base-star/base-star'
  import ErpDraggable from 'vuedraggable'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import customTableMixin from '@/utils/custom-table-mixin'
  import PreviewImage from '@/views/stock-center/inbound-sheet/components/preview-image'
  export default {
    name: 'WarehousingOrder',
    mixins: [customTableMixin],
    components: { ErpDraggable, Pagination, Audit, BaseStar, PreviewImage, },
    data() {
      return {
        printDetail: {}, // 打印详情
        auditUserVOS: [],
        progressStatus: '',
        warehouseInId: '',
        cancelVisible: false,
        statusDrawer: false,
        cancelReason: '',
        queryForm: {
          endDate: '',
          name: '', //入库单号、采购单号、供应商
          startDate: '', //startDate
          type: '', //入库方式(0采购入库,1退货入库,2其他入库,3调拨入库，4盘点入库))
          warehouseId: '', //	入库仓库
          date: [],
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
        },
        statusList: [], //状态列表
        currentStatus: '', //当前状态
        currentAuditObjectId: '',
        warehouses: [], //仓库下拉列表
        types: [
          //入库方式
          {
            code: 0,
            name: '采购入库',
          },
          {
            code: 1,
            name: '退货入库',
          },
          {
            code: 2,
            name: '其他入库',
          },
          {
            code: 10,
            name: '调拨入库',
          },
          {
            code: 11,
            name: '盘盈入库',
          },
          {
            code: 14,
            name: '退料入库',
          },
          {
            code: 15,
            name: '加工入库',
          },
        ],
        tableData: [],
        page: {
          pageLe: 10,
          pageNo: 1,
          total: 0,
        },
        columns: [
          {
            label: '入库单号',
            prop: 'warehouseInNumber',
            isShow: true,
            checkable: false,
            width: 160,
          },
          {
            label: '关联编号',
            prop: 'orderNumber',
            isShow: true,
            checkable: false,
            width: 160,
          },
          {
            label: '效果图',
            prop: 'printingImgs',
            isShow: true,
            checkable: true,
            width: 100,
          },
          {
            label: '产品图片',
            prop: 'productImages',
            isShow: true,
            checkable: true,
            width: 100,
          },
          {
            label: '供应商',
            prop: 'supplierName',
            isShow: true,
            checkable: true,
          },
          {
            label: '入库仓库',
            prop: 'warehouseName',
            isShow: true,
            checkable: true,
          },
          {
            label: '入库方式',
            prop: 'typeName',
            isShow: true,
            checkable: true,
          },
          {
            label: '申请入库数量',
            prop: 'expectNum',

            isShow: true,
            checkable: true,
          },
          {
            label: '实际入库数量',
            prop: 'inNum',
            isShow: true,
            checkable: true,
          },
          {
            label: '不含税入库成本',
            prop: 'inPrice',
            isShow: true,
            checkable: true,
          },
          {
            label: '含税入库成本',
            prop: 'inTaxPrice',
            isShow: true,
            checkable: true,
          },
          {
            label: '状态',
            prop: 'status',
            isShow: true,
            checkable: false,
          },
          {
            label: '创建人',
            prop: 'creator',
            isShow: true,
            checkable: true,
          },
          {
            label: '所属区域',
            prop: 'creatorArea',
            isShow: true,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: true,
            checkable: true,
            width: 180,
            sortable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '入库时间',
            prop: 'warehouseInDate',
            isShow: true,
            checkable: true,
            sortable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '操作员',
            prop: 'operationName',
            isShow: true,
            checkable: true,
          },
          {
            label: '备注',
            prop: 'remark',
            isShow: true,
            checkable: true,
          },
        ],
        userCustomizeColumnId: '', //自义定保存id
        defaultSort: {}, // 默认排序
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        this.columns.forEach((item) => {
          if (item.isSort) {
            this.defaultSort = { prop: item.prop, order: item.sortType }
          }
        })
        this.customTableKey = Math.random()
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    watch: {
      currentStatus() {
        this.page.pageNo = 1
        this.getTableData()
      },
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.getColumsData()
        }
      },
    },
    filters: {
      statusFilter(val) {
        let map = {
          0: '审核中',
          1: '待入库',
          2: '已入库',
          3: '审核驳回',
          4: '已撤销',
          5: '异常入库',
        }
        return map[val] ? map[val] : '--'
      },
      auditStatusFilter(val) {
        let map = {
          1: '审核中',
          2: '已通过',
          3: '已驳回',
          4: '已撤销',
        }
        return map[val] ? map[val] : '--'
      },
      imagesFormat(imgs) {
        if(imgs) {
          return imgs.split(',').filter(img => img)
        } else {
          return []
        }
      }
    },
    created() {
      this.getColumsData()
      this.getWarehouse()
    },

    methods: {
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(7, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'warehouseInDate') {
                    this.queryForm.sortName = 'warehouse_in_date'
                  } else if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'create_time'
                  }
                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })
              this.getTableData()
            }
          })
          .catch(() => {
            this.getTableData()
          })
      },

      async getWarehouse() {
        let res = await InboundSheet.findWarehouseList({
          hasCloudWarehouse: 1,
        })
        if (res && res.code === '000000') {
          this.warehouses = res.data
        }
      },

      async getTableData() {
        let res = await InboundSheet.getListPage({
          ...this.page,
          ...this.queryForm,
          currentStatus: this.currentStatus,
        })
        if (res?.code === '000000') {
          this.tableData = res.data.data || []
          this.page.total = res.data.total
        }
      },
      // 排序
      sortChange(column) {
        if (column.order) {
          if (column.prop === 'warehouseInDate') {
            this.queryForm.sortName = 'warehouse_in_date'
          } else if (column.prop === 'createTime') {
            this.queryForm.sortName = 'create_time'
          } else {
            this.queryForm.sortName = ''
          }
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.getTableData()
      },
      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },
      //自定义保存
      operateSave(flag) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 7,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000' && flag) {
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      onPagination({ page, limit }) {
        this.page.pageNo = page
        this.page.pageLe = limit

        this.getTableData()
      },
      // 初始化加载
      init() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getTableData()
      },
      async showReviewProgress({ status, warehouseInId }) {
        this.progressStatus = status
        let map = [
          0, // '审核中',
          3, //'已驳回',
        ]
        if (!map.includes(status)) {
          return
        }

        const res = await InboundSheet.getAuditObject({
          orderId: warehouseInId,
          auditType: 4,
          tenantId: this.userInfo.tenantId,
        })

        if (res && res.code === '000000') {
          // 获取提交审核（撤销所用）auditObjectId
          if (!res.data || !res.data.auditObjectId) {
            this.$message.warning('未获取到审核对象')
          } else {
            this.currentAuditObjectId = res.data.auditObjectId
            this.statusDrawer = true
          }
        }
      },

      goDetail({ warehouseInId }) {
        this.$router.push({
          name: 'inbound-sheet-detail',
          query: {
            warehouseInId,
          },
        })
      },
      goRelatedDetail({ orderNumber, type }) {
        const type2strat = {
          1: 'salesOrderDetail',
          0: 'purchaseOrderDetail',
          3: 'salesOrderDetail',
          10: 'allotInquireDetail',
          11: 'stockCheckDetail',
          15: 'outprocessingDetail',
          14: 'outprocessingDetail',
        }
        return navigateTo(this, {
          orderNumber,
          showType: 'id',
          pageType: PAGE_TYPES[type2strat[type]],
        })
      },
      printSheet({ warehouseInId }) {
        let routeData = this.$router.resolve({
          path: '/stock-center/inbound-sheet/print-inbound-sheet',
          query: {
            warehouseInId,
          },
        })
        window.open(routeData.href, '_blank')
      },
      confirmSheet({ ensureFlag, warehouseInId }) {
        if (ensureFlag === 0) {
          return this.$baseMessage(
            '暂无操作权限，请联系管理员',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.$router.push({
          name: 'confirm-sheet',
          query: {
            warehouseInId,
          },
        })
      },

      addNew() {
        this.$router.push({
          path: '/stock-center/inbound-sheet/new-inbound-sheet',
        })
      },
      editSheet({ warehouseInId }) {
        this.$router.push({
          path: '/stock-center/inbound-sheet/edit-inbound-sheet',
          query: { warehouseInId },
        })
      },
      getTagType(status) {
        if ([0, 1].includes(status)) {
          return 'warning'
        }
        if ([2].includes(status)) {
          return 'success'
        }
        if ([5, 3, 4].includes(status)) {
          return 'danger'
        }
        return ''
      },
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },
      async cancelSure() {
        if (!this.cancelReason) {
          return this.$message.warning('请输入撤销原因')
        }
        const {
          auditObjectId,
          auditStatus = 4,
          auditType = 4,
          cancelReason,
        } = this
        const { tenantId, userId, userName } = this.userInfo
        const res = await InboundSheet.cancel({
          auditObjectId,
          auditStatus,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        })

        if (res.code === '000000') {
          this.$message.success('撤销成功')
          this.cancelVisible = false
          this.getTableData()
        }
      },
      async cancel({ warehouseInId, warehouseId }) {
        try {
          // 打开弹框前先校验仓库是否被锁
          const { code } = await StoreCheck.auditCheckWarehousrLockApi(warehouseId)
          if(code === '000000') {
            const res = await InboundSheet.getAuditObject({
              orderId: warehouseInId,
              auditType: 4,
              tenantId: this.userInfo.tenantId,
            })

            if (res && res.code === '000000') {
              // 获取提交审核（撤销所用）auditObjectId
              this.auditObjectId = res.data.auditObjectId
              this.warehouseInId = warehouseInId
              this.cancelVisible = true
            }
          }
        } catch (e) {
          console.log(e)
        }
      },
      statusClose() {
        this.statusDrawer = false
      },
      handleClose() {
        this.drawer = false
      },
      onReset() {
        this.page.pageNo = 1
        this.$refs.form.resetFields()
        this.queryForm = this.$options.data().queryForm
        this.currentStatus = ''
        this.init()
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }
  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }
  .pointer {
    cursor: pointer;
  }

  .link {
    text-decoration: underline;
    color: #1890ff;
  }
  .progress {
    padding: 15px 20px;
    .progress-wrapper {
      display: flex;

      & .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        & .line {
          flex: 1;
          width: 1px;
          background-color: #c0c4cc;
          margin-top: 5px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        & span {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }
  .error-color {
    color: #f56c6c;
  }
  .success-color {
    color: #67c23a;
  }
  .warning-color {
    color: #e6a23c;
  }
  .war .pointer {
    cursor: pointer;
  }
  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }
  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
