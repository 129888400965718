<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="760px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="130px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="中文规格名称" prop="nameCn">
            <el-input
              v-model="form.nameCn"
              placeholder="请输入内容"
              v-no-special-chars="invalidChars"
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="英文规格名称" prop="nameEn">
            <el-input
              v-model="form.nameEn"
              placeholder="请输入内容"
              v-no-special-chars="invalidChars"
              maxlength="50"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确 定
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { productSettingInteractor, PublicMethods } from '@/core'
  export default {
    name: 'SpecModel',
    props: {
      invalidChars: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        loading: false,
        title: '新增规格',
        form: {
          nameCn: '',
          nameEn: '',
          id: '',
          valueType: 0,
          disable: 0,
        },
        rules: {
          nameCn: [{ required: true, trigger: 'blur', message: '请输入' }],
          nameEn: [{ required: true, trigger: 'blur', message: '请输入' }],
        },
        dialogFormVisible: false,
        // invalidChars
      }
    },
    methods: {
      //展示弹框
      showAddEdit(row) {
        if (!row) {
          this.form.type = 1
          this.title = '新增规格'
        } else {
          this.title = '编辑规格'
          this.form = Object.assign(
            {
              ...this.form,
            },
            row
          )
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.$refs['form'].resetFields()
        this.form = {
          nameCn: '',
          nameEn: '',
          id: '',
          valueType: 0,
        }
        this.loading = false
        this.dialogFormVisible = false
      },

      // 标签删除
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1)
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let response
            this.loading = true
            productSettingInteractor
              .productSpecsSettingSaveApi({
                ...this.form,
              })
              .then((res) => {
                if (res?.code === '000000') {
                  this.$baseMessage(
                    this.form.id ? '编辑成功' : '新增成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                  this.$emit('spec', this.form.type)
                  this.close()
                }
              })
              .then(() => {
                this.loading = false
              })
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-tag {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
</style>
