<template>
  <div class="custom-container">
    <erp-search ref="erpsearch" :searchFormConfig="searchFormConfig" :value="queryForm" @enter-search="onButtonSearch"
      :key="searchKey"></erp-search>
    <el-row type="flex" justify="end">
      <!-- 自定义展示列 -->
      <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
        <erp-draggable v-bind="dragOptions" :list="columns">
          <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
            :disabled="item.checkable === false" :label="item.label">
            {{ item.label }}
          </el-checkbox>
        </erp-draggable>
        <!-- 保存自定义显示列 -->
        <div class="save-btn">
          <el-button type="primary" class="ml10" size="mini" plain @click="operateSave(true)">
            <!-- 保存 -->
            {{ $t('productCenter.Save') }}
          </el-button>
        </div>
        <template #reference>
          <!-- 列表可拖动排序 -->
          <el-tooltip effect="dark" :content="$t('productCenter.ListDraggableToSort')" placement="top-start">
            <el-button plain type="primary" class="pub-h-36">
              <!-- 自定义显示 -->
              {{ $t('productCenter.CustomDisplay') }}
            </el-button>
          </el-tooltip>
        </template>
      </el-popover>
    </el-row>
    <div class="mt10">
      <el-table style="margin-bottom: -5px" :data="tableData" border stripe :default-sort="defaultSort"
        :height="tableHeight" :key="customTableKey" @sort-change="sortChange">
        <el-table-column v-for="(item, index) in finallyColumns" :key="index" :prop="item.prop" :label="item.label"
          :sortable="item.sortable ? 'custom' : false" :resizable="false" :min-width="
            item.prop == 'status' ||
            item.prop == 'creator' ||
            item.prop == 'amount'
              ? 120
              : 200
          " align="center" show-overflow-tooltip>
          <template #default="{ row }">
            <template v-if="item.prop === 'status'">
              <span>{{ row.status | statusFilter }}</span>
            </template>
            <template v-else-if="item.prop === 'orderCode'">
              <span :class="{ prod_code: row.orderCode }" @click="receiptNoClick(row.orderId, 1,row.orderCode)">
                {{ row.orderCode || '--' }}
              </span>
            </template>
            <template v-else-if="item.prop === 'amount'">
              ￥{{ row.amount }}
            </template>
            <template v-else>
              {{ row[item.prop] || '--' }}
            </template>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column align="center" :label="$t('productCenter.Operate')" width="180" fixed="right"
          :resizable="false">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" v-if="!(scope.row.status == 3 || scope.row.status == 4)"
              @click="handleClick(scope.row, scope.$index)">
              查询最新状态
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <Pagination class="mt-10" v-if="total > 0" :limit.sync="page.pageLe" :page.sync="page.pageNo" :total="total"
        @pagination="onPagination" />
    </div>
    <!-- 付款单详情弹窗1 -->
    <PaymentDetailModel ref="PaymentDetailModel" />
    <!-- 付款单详情弹窗2,销售退款 -->
    <PaymentDetailModel2 ref="PaymentDetailModel2" />
    <!-- 费用单详情 -->
    <CostPaymentDetail ref="CostPaymentDetail"></CostPaymentDetail>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Index'
  import PaymentDetailModel from '../payment-manage/components/payment-detail-model'
  import PaymentDetailModel2 from '../payment-manage/components/payment-detail-model2'
  import CostPaymentDetail from '../cost-manage/components/payment-detail-model'

  import { getList, getLatestStatus } from '@/api/finance/finance-payment'
  import ErpDraggable from 'vuedraggable'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      ErpDraggable, //拖动
      Pagination,
      PaymentDetailModel,
      PaymentDetailModel2,
      CostPaymentDetail
    },
    data() {
      return {
        searchKey: Math.random(),
        searchFormConfig: {
          labelPosition: 'right',
          defaultExpand: false, //默认展开
          formItemList: [
            {
              type: 'text',
              name: 'referenceNum',
              prop: 'referenceNum',
              label: '参考业务号',
              labelWidth: '80px',
              placeholder: '请输入',
              maxlength: 30,
            },
            {
              type: 'text',
              name: 'busNum',
              prop: 'busNum',
              label: '业务流水号',
              labelWidth: '80px',
              placeholder: '请输入',
              maxlength: 30,
            },
            {
              type: 'text',
              name: 'orderCode',
              prop: 'orderCode',
              label: '业务单号',
              labelWidth: '70px',
              placeholder: '请输入',
              maxlength: 30,
            },

            {
              type: 'select',
              name: 'status',
              prop: 'status',
              label: '支付状态',
              labelWidth: '70px',
              placeholder: '请选择',
              optList: [
                { label: '未完成', value: 1 },
                { label: '支付成功', value: 2 },
                { label: '支付失败', value: 3 },
                { label: '银行退票', value: 4 },
                { label: '状态可疑', value: 5 },
              ],
            },
            {
              type: 'select',
              name: 'timeWay',
              prop: 'timeWay',
              label: '',
              width: '120px',
              labelWidth: '0',
              placeholder: '请选择',
              optList: [
                { label: '发起付款时间', value: 1 },
                { label: '付款成功时间', value: 2 },
              ],
            },
            {
              type: 'daterange',
              name: 'dateRange',
              prop: 'dateRange',
              label: '',
              labelWidth: '0',
              placeholder: '',
              disabled: true,
            },
            {
              type: 'userselect',
              name: 'creatorId',
              prop: 'creatorId',
              label: '发起人',
              labelWidth: '70px',
              placeholder: '请选择',
              chooseUser: this.chooseCreate,
            },
          ],
          operate: [
            {
              type: 'primary',
              name: '搜索',
              handleClick: this.onButtonSearch,
            },
            {
              type: 'plain',
              name: '重置',
              handleClick: this.onReset,
            },
          ],
        },
        queryForm: {
          referenceNum: '', // 参考业务号
          busNum: '', // CBS业务流水号
          orderCode: '', // 付款单号
          status: '', // 支付状态
          creatorId: '', // 发起人
          timeWay: '', // 时间选择方式
          dateRange: [], // 时间
          createTimeStart: '',
          createTimeEnd: '',
          payTimeStart: '',
          payTimeEnd: '',
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
        },

        defaultSort: {}, // 默认排序
        userCustomizeColumnId: '', //自义定保存id
        columns: [
          {
            label: '参考业务号',
            prop: 'referenceNum',
            isShow: true,
            checkable: false,
            isSort: false,
            sortType: null,
          },
          {
            label: 'CBS业务流水号',
            prop: 'busNum',
            isShow: true,
            checkable: false,
            isSort: false,
            sortType: null,
          },
          {
            label: '业务单号',
            prop: 'orderCode',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '付款金额',
            prop: 'amount',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '支付状态',
            prop: 'status',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '失败原因',
            prop: 'errorMsg',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '发起人',
            prop: 'creator',
            isShow: true,
            checkable: true,
            isSort: false,
            sortType: null,
          },
          {
            label: '付款成功时间',
            prop: 'payTime',
            isShow: true,
            checkable: true,
            sortable: true,
            isSort: false,
            sortType: null,
          },

          {
            label: '发起付款时间',
            prop: 'createTime',
            isShow: true,
            checkable: true,
            sortable: true,
            isSort: false,
            sortType: null,
          },
        ],
        tableHeight: 600,
        tableData: [],
        customTableKey: '',
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        this.columns.forEach((item) => {
          if (item.isSort) {
            this.defaultSort = { prop: item.prop, order: item.sortType }
          }
        })
        this.customTableKey = Math.random()
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    watch: {
      'queryForm.timeWay': {
        handler(newval, oldval) {
          console.log(newval)
          if (newval) {
            this.searchFormConfig.formItemList[5].disabled = false
          } else {
            this.searchFormConfig.formItemList[5].disabled = true
            this.queryForm.dateRange = []
          }
        },
        deep: true,
      },
    },

    // 状态过滤
    filters: {
      statusFilter(n) {
        const dict = {
          1: '未完成',
          2: '支付成功',
          3: '支付失败',
          4: '银行退票',
          5: '状态可疑',
        }
        return dict[n] || '--'
      },
    },

    created() {
      //获取自定义保存
      this.getColumsData()
    },

    activated() {
      this.getColumsData()
    },

    mounted() {
      this.getTableHeight()

      this.$Bus.$on('form-trigger', () => {
        this.getTableHeight()
      })
    },
    methods: {
      getTableHeight() {
        setTimeout(() => {
          let wrapper = document
            .querySelector('.custom-container')
          if (!wrapper) {
            return
          }
          const wrapperH = document
            .querySelector('.custom-container')
            .getBoundingClientRect().height

          const otherH =
            document.querySelector('.search-form')?.getBoundingClientRect()
              .height +
            35 +
            72 +
            20 + // 35是上下间距  72是分页占据空间
            20

          this.tableHeight = wrapperH - otherH
        }, 200)
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(20, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              console.log(res.userCustomizeColumnId)
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.columns = res.columns
              // 处理排序搜索条件
              this.columns.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'payTime') {
                    this.queryForm.sortName = 'pay_time'
                  } else if (item.prop === 'createTime') {
                    this.queryForm.sortName = 'create_time'
                  }
                  this.queryForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.queryForm.sortName = null)
                }
              })
              //CBS支付列表
              this.getTableData()
            }
          })
          .catch(() => {
            this.getTableData()
          })
      },

      // 排序
      sortChange(column) {
        if (column.order) {
          if (column.prop === 'payTime') {
            this.queryForm.sortName = 'pay_time'
          } else if (column.prop === 'createTime') {
            this.queryForm.sortName = 'create_time'
          } else {
            this.queryForm.sortName = ''
          }
        } else {
          this.queryForm.sortName = ''
        }
        this.queryForm.sortType = column.order
        this.saveSort(column)
        this.getTableData()
      },

      // 保存排序
      saveSort(column) {
        this.columns.forEach((item) => {
          if (item.prop === column.prop) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },

      // 保存自定义显示列
      operateSave(flag) {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 20,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res?.code === '000000' && flag) {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            // 操作成功
            return this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 选择发起人
      chooseCreate(val) {
        this.queryForm.creatorId = val.userId
      },

      // 搜索
      onButtonSearch() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getTableData()
      },

      onPagination({ page, limit }) {
        this.page.pageNo = page
        this.page.pageLe = limit

        this.getTableData()
      },

      // 查询
      async getTableData() {
        if (this.queryForm.dateRange && this.queryForm.dateRange.length) {
          this.queryForm[
            this.queryForm.timeWay == 1 ? 'createTimeStart' : 'payTimeStart'
          ] = this.queryForm.dateRange[0]

          this.queryForm[
            this.queryForm.timeWay == 1 ? 'createTimeEnd' : 'payTimeEnd'
          ] = this.queryForm.dateRange[1]

          this.queryForm[
            this.queryForm.timeWay == 1 ? 'payTimeStart' : 'createTimeStart'
          ] = ''

          this.queryForm[
            this.queryForm.timeWay == 1 ? 'payTimeEnd' : 'createTimeEnd'
          ] = ''
        } else {
          this.queryForm.createTimeStart = ''
          this.queryForm.createTimeEnd = ''
          this.queryForm.payTimeStart = ''
          this.queryForm.payTimeEnd == ''
        }
        let res = await getList({
          ...this.queryForm,
          ...this.page,
        })
        setTimeout(() => {
          this.customTableKey = Math.random()
        }, 300)
        if (res.code === '000000') {
          this.tableData = res.data?.data
          this.total = res.data.total
        }
      },

      //查询最新状态
      async handleClick(row, index) {
        let res = await getLatestStatus({ cbsPayId: row.cbsPayId })
        if (res.code === '000000') {
          this.$baseMessage(
            // '查询成功',
            this.$t('reqmsg.$66'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.$nextTick(() => {
            row = res.data
            this.getTableData()
          })
          // this.$set(row, 'status', res.data.status)
        }
      },
      //点击付款单号(费用单号)
      receiptNoClick(payOrderId, payType, orderCode) {
        if (orderCode?.indexOf('FY') > -1) {
          this.$refs.CostPaymentDetail.amendRow = orderCode
          this.$refs['CostPaymentDetail'].showReceiptDetail()
        } else {
          if (payType === 1) {
            this.$refs['PaymentDetailModel'].amendRow = payOrderId
            this.$refs['PaymentDetailModel'].showReceiptDetail()
          } else {
            this.$refs['PaymentDetailModel2'].amendRow = payOrderId
            this.$refs['PaymentDetailModel2'].showReceiptDetail()
          }
        }


      },
      // 重置
      onReset() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.queryForm = {
          referenceNum: '', // 参考业务号
          busNum: '', // CBS业务流水号
          orderCode: '', // 付款单号
          status: '', // 支付状态
          creatorId: '', // 发起人
          timeWay: '', // 时间选择方式
          dateRange: [], // 时间
          createTimeStart: '',
          createTimeEnd: '',
          payTimeStart: '',
          payTimeEnd: '',
          sortName: '', // 升序降序名称
          sortType: '', // 升序降序（ASC,DESC）
        }
        this.$refs.erpsearch.resetUserSelect()
        this.getTableData()
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {

    // 产品编码
    .prod_code {
      color: #409eff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  //自定义显示
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  ::v-deep {
    .el-table--border th.gutter:last-of-type {
      display: table-cell !important;
    }
  }
</style>
