<template>
  <el-table :data="data" border >
    <el-table-column type="index" label="序号" width="50" align="center" />
    <el-table-column label="时段" align="center">
      <template slot-scope="scope">
        {{ scope.row.timeData[0] }}-{{ scope.row.timeData[1] }}
        <div style="color: #ff4d4f">
          <i class="el-icon-warning" />
          {{
            subtractOneHour(scope.row.timeData, scope.row.assignTimeConfig)
          }}后此时段不再接收分配邮件
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期一" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList1" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期二" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList2" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期三" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList3" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期四" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList4" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期五" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList5" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期六" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList6" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="星期日" align="center">
      <template slot-scope="scope">
        <div v-for="name of scope.row.userNameList7" :key="name">
          {{ name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="assignTimeConfig"
      label="截止分配时间（h）"
      align="center"
    />
  </el-table>
</template>

<script>
import { subtractOneHour } from '../utils'

export default {
  name: 'DutyTable',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    subtractOneHour
  }
}
</script>