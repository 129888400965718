<template>
  <div class="custom-wrap">
    <div class="custom-top">
      <div class="flex">
        <el-row>
          <div
            :class="[
              $route.query.noReturn ? '' : 'fix-msg-operate-no',
              'flex',
              'justify_start',
            ]"
          >
            <el-page-header
              @back="$router.go(-1)"
              v-if="!$route.query.noReturn"
            ></el-page-header>
            <div class="no-return">
              {{ number }}
            </div>
            <el-tag class="ml10" size="mini">
              {{ state | stateNames }}
            </el-tag>
          </div>
        </el-row>
      </div>

      <div>
        <el-button
          plain
          @click="downloadShippingLabel"
          v-if="showDownloadShippingLabel"
        >
          下载送货标签
        </el-button>
        <el-button plain @click="printContract">查看合同</el-button>
        <el-button
          plain
          @click="edit"
          v-show="state != 4 && state != 8 && state != 12"
        >
          编辑
        </el-button>
        <el-upload
          action="/"
          class="upload-btn"
          :http-request="uploadSignContract"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :multiple="true"
        >
          <el-button plain v-allowed="['PURCHASE:SELAED_CONTRACT']">
            上传已盖章合同
          </el-button>
        </el-upload>
        <!-- 已作废的订单，不显示此按钮 -->
        <el-button
          v-if="+state !== 8"
          class="ml10"
          plain
          v-allowed="['PURCHASE:SHIPPING_CERTIFICATE']"
          @click="handleUploadShippingCertificate"
        >
          上传发货凭证
        </el-button>
      </div>
    </div>

    <el-divider></el-divider>

    <div>
      <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane label="订单信息" name="1">
          <h4 class="mt5">基本信息</h4>

          <div class="custom-detail">
            <el-row>
              <el-col class="w_90">
                <em>供应商名称：</em>
                <span>
                  {{ detail.supplierName || '--' }}
                  <el-tag
                    v-if="detail.isAgreement === 1"
                    type="danger"
                    size="mini"
                    style="width: auto; margin-left: 10px; padding: 0 5px"
                  >
                    协议
                  </el-tag>
                </span>
              </el-col>
              <el-col>
                <em>买&nbsp;&nbsp;方：</em>
                <span>{{ detail.purchaser || '--' }}</span>
              </el-col>

              <el-col>
                <em>联&nbsp;系&nbsp;人&nbsp;：</em>
                <span>{{ detail.supplierLinkmanName || '--' }}</span>
              </el-col>
              <el-col>
                <em>联系电话：</em>
                <span>{{ detail.supplierLinkmanPhone || '--' }}</span>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="w_90">
                <em>采&nbsp;购&nbsp;开&nbsp;发：</em>
                <span>{{ detail.buyerName || '--' }}</span>
              </el-col>
              <el-col class="w_105">
                <em style="width: 35px">QC:</em>
                <span
                  class="page-link"
                  @click="getUser"
                  v-if="hasQCEditRight && state != 7"
                >
                  {{ detail.merchandiserName || '未填写' }}
                </span>
                <span v-else>
                  {{ detail.merchandiserName || '--' }}
                </span>
              </el-col>
              <el-col>
                <em>交货地址：</em>
                <span>{{ detail.receivingAddress || '--' }}</span>
              </el-col>
              <el-col>
                <em>运输方式：</em>
                <span>{{ detail.transportWay || '--' }}</span>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="w_90">
                <em>费用承担方：</em>
                <span>{{ detail.freightPayer || '--' }}</span>
              </el-col>
              <el-col class="w_105">
                <em style="width: 60px">创建人：</em>
                <span style="width: calc(100% - 60px)">
                  {{ detail.creator || '--' }}
                </span>
              </el-col>
              <el-col>
                <em>创建时间：</em>
                <span>{{ detail.createTime || '--' }}</span>
              </el-col>
              <el-col class="w_105">
                <em>协议结束日期：</em>
                <span>{{ detail.agreementEndDate || '--' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="w_90">
                <em>发票类型:</em>
                <span>{{ detail.invoiceType | invoiceTypeFilter }}</span>
              </el-col>
              <el-col class="w_105" v-if="showSwitch === '0'">
                <em>选择入库仓库:</em>
                <span v-if="detail.warehouseFlag">
                  是（验货完成后，需要申请入库）
                </span>
                <span v-else="detail.warehouseFlag">
                  否（验货完成后，直接入库虚拟仓）
                </span>
              </el-col>
              <!-- 如果是卖家供应商，并且采购类型为备货单，则显示备货到供应商仓库 -->
              <!-- purchaseDemandFlag：采购类型（1：按需；2：备货单） -->
              <el-col
                v-if="isSeller && Number(detail.purchaseDemandFlag) === 2"
                class="w_130"
              >
                <em>备货到供应商仓库:</em>
                <el-switch
                  v-model="detail.warehouseInFlag"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
                ></el-switch>
              </el-col>
            </el-row>
          </div>

          <h4>产品列表</h4>
          <el-table
            :data="productListTable"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            border
            :key="productKey"
          >
            <el-table-column
              align="center"
              width="220"
              prop="nameCn"
              label="产品信息"
            >
              <template slot-scope="scope">
                <div class="info-wrapper">
                  <p>
                    <span style="margin-right: 5px; margin-left: -5px">
                      产品编码:{{ scope.row.sku }}
                    </span>

                    <el-popover
                      v-show="scope.row.mainFlag == 0"
                      placement="right"
                      width=""
                      trigger="hover"
                    >
                      <div class="c-table-wrap">
                        <table class="custom-table text-center">
                          <thead>
                            <tr>
                              <th>产品图</th>
                              <th>产品编码</th>
                              <th class="more">中文品名/英文品名</th>
                              <th>英文规格</th>
                              <th>中文规格</th>
                              <th>数量</th>
                            </tr>
                          </thead>
                          <tbody>
                            <td>
                              <div class="img-wrap">
                                <el-badge
                                  v-show="
                                    scope.row.parentUrl &&
                                    scope.row.parentUrl.split(',').length > 1
                                  "
                                  :value="
                                    scope.row.parentUrl
                                      ? scope.row.parentUrl.split(',').length
                                      : ''
                                  "
                                ></el-badge>
                                <el-image
                                  style="height: 50px; width: 50px"
                                  v-if="scope.row.parentUrl"
                                  :src="scope.row.parentUrl.split(',')[0]"
                                  class="c_pointer"
                                  @click="
                                    handlePreview(
                                      scope.row.parentUrl.split(',')
                                    )
                                  "
                                ></el-image>
                                <span v-else>--</span>
                              </div>
                            </td>
                            <td>
                              <span
                                v-if="!scope.row.productCode.includes('FL')"
                                @click="
                                  productView(
                                    scope.row.productId,
                                    scope.row.productCode
                                  )
                                "
                                class="page-link"
                              >
                                {{ scope.row.parentSku }}
                              </span>
                              <span v-else>{{ scope.row.parentSku }}</span>
                            </td>
                            <td>{{ scope.row.parentName }}</td>
                            <td>{{ scope.row.parentSpecsValue }}</td>
                            <td>{{ scope.row.parentSpecsValueCn }}</td>
                            <td>
                              {{
                                scope.row.parentNumDemand /
                                scope.row.numCombination
                              }}
                            </td>
                          </tbody>
                        </table>
                      </div>

                      <em
                        class="child-split"
                        slot="reference"
                        v-show="scope.row.mainFlag == 0"
                      >
                        子
                      </em>
                    </el-popover>
                  </p>
                  <p>产品名称:{{ scope.row.nameCn }}</p>
                  <p>
                    <span>英文规格:</span>

                    <span v-if="scope.row.specsValue1 || scope.row.specsValue2">
                      {{ getSpecsValue(scope.row) }}
                    </span>

                    <span v-else>--</span>
                  </p>
                  <p>
                    <span>中文规格:</span>

                    <span
                      v-if="scope.row.specsValueCn1 || scope.row.specsValueCn2"
                    >
                      {{ getSpecsValueCn(scope.row) }}
                    </span>

                    <span v-else>--</span>
                  </p>
                  <p>
                    <span>客户代表:</span>
                    <span>
                      {{ scope.row.businessName || '--' }}
                    </span>
                  </p>
                  <p>
                    <span>产品运营:</span>
                    <span>
                      {{ scope.row.productOperateName || '--' }}
                    </span>
                  </p>
                  <p>
                    <span>订单支持:</span>

                    <span>
                      {{ scope.row.fromBusinessName || '--' }}
                    </span>
                  </p>
                  <!-- <p>期望交期:{{ scope.row.expectedDeliveryDate || '--' }}</p> -->
                </div>
              </template>
            </el-table-column>
            <!-- 计划装运日期/客户交期 -->
            <el-table-column
              v-if="tabsType == 1"
              align="center"
              width="260"
              label="计划装运日期/客户交期"
            >
              <template slot-scope="{ row }">
                <div style="text-align: left">
                  <span>计划装运日期</span>
                  <el-tooltip placement="top">
                    <div slot="content">
                      订单预计交付给国际物流或国际快递的
                      <br />
                      日期，并以装运地的时区时间为准。
                    </div>
                    <i class="el-icon-question" />
                  </el-tooltip>
                  <span>：</span>
                  <span>
                    {{
                      row.productExtend && row.productExtend.planShippingDate
                        ? row.productExtend.planShippingDate
                        : '--'
                    }}
                  </span>
                </div>
                <div style="text-align: left">
                  <span>客户交期</span>
                  <el-tooltip placement="top">
                    <div slot="content">
                      1、订单交付到客户指定收货地点的约定日期，并以交货
                      <br />
                      地的时区时间为准，需要全力确保与客户约定的交期。
                      <br />
                      2、「Firm 交期」指的是明确的客户交期，需要严格地
                      <br />
                      满足此交期，否则会面临更加严重的违约责任。
                    </div>
                    <i class="el-icon-question" />
                  </el-tooltip>
                  <span>：</span>
                  <span>
                    {{
                      row.productExtend &&
                      row.productExtend.customerDeliveryDate
                        ? row.productExtend.customerDeliveryDate
                        : '--'
                    }}
                  </span>
                  <el-tag
                    v-if="row.productExtend && row.productExtend.firmFlag == 1"
                    size="mini"
                    type="warning"
                    effect="dark"
                    style="margin-left: 5px"
                  >
                    Firm 交期
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="priceIncludingTax"
              width="120"
              label="含税单价"
            >
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ utils.moneyFormat(scope.row.priceIncludingTax) }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="taxRate"
              label="税率"
            >
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ BigMath.multiply(scope.row.taxRate, 100) }}%
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="priceExcludingTax"
              width="120"
              label="未税单价"
            >
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ utils.moneyFormat(scope.row.priceExcludingTax) }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              prop="salesOrderNumber"
              label="采购需求编号"
              fixed="right"
            >
              <template slot-scope="scope">
                <div class="page-link">
                  <router-link
                    :to="`/order/orderList/orderDetail?orderCode=${
                      scope.row.salesOrderNumber
                    }&noReturn=${true}`"
                    target="_blank"
                  >
                    {{ scope.row.salesOrderNumber }}
                  </router-link>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              width="120"
              prop="numDemand"
              label="数量"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="numPurchase"
              label="本次采购数量"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="numRelax"
              label="溢装数"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="printingImg"
              label="效果图"
            >
              <template slot-scope="scope">
                <div
                  class="img-wrap"
                  v-if="scope.row.printingWay.toUpperCase() != 'BLANK'"
                >
                  <el-badge
                    v-show="
                      scope.row.printingImg &&
                      scope.row.printingImg.split(',').length > 1
                    "
                    :value="
                      scope.row.printingImg
                        ? scope.row.printingImg.split(',').length
                        : ''
                    "
                  ></el-badge>
                  <el-image
                    v-if="scope.row.printingImg"
                    :src="
                      scope.row.printingImg
                        .split(',')[0]
                        .concat('?x-oss-process=image/resize,p_10')
                    "
                    class="c_pointer"
                    @click="handlePreview(scope.row.printingImg.split(','))"
                  ></el-image>
                  <span v-else>--</span>
                </div>
                <div v-else>--</div>
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" label="订单备注">
              <template slot-scope="scope">
                {{ scope.row.remarkOrder || '--' }}
              </template>
            </el-table-column>

            <el-table-column align="center" width="180" label="产品描述">
              <template slot-scope="scope">
                <div
                  style="text-align: left; word-break: break-all"
                  v-html="
                    scope.row.productSize
                      ? scope.row.productSize.replace(/\n/g, '<br>')
                      : '--'
                  "
                ></div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="isDrawback"
              label="是否退税"
            >
              <template slot-scope="scope">
                {{ scope.row.isDrawback == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="tabsType == 1"
              align="center"
              width="150"
              label="印刷方式"
              style="text-align: left"
            >
              <template slot-scope="{ row }">
                <div style="text-align: left">
                  <WewooTitleTip
                    style="flex: 1"
                    :content="row.printingWay || '--'"
                  />
                </div>
                <div style="text-align: left; display: flex">
                  计价方式：
                  <WewooTitleTip
                    style="flex: 1"
                    :content="
                      row.productExtend &&
                      row.productExtend.valuationMethodValue
                        ? row.productExtend.valuationMethodValue
                        : '--'
                    "
                  />
                </div>
                <div style="text-align: left; display: flex">
                  印刷位置：
                  <WewooTitleTip
                    style="flex: 1"
                    :content="
                      row.productExtend &&
                      row.productExtend.printingPositionValue
                        ? row.productExtend.printingPositionValue
                        : '--'
                    "
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              width="120"
              label="印刷方式"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{ row.printingWay || '--' }}
              </template>
            </el-table-column>
            <!-- 供应商为卖家 并且 采购类型为按需时，显示颜色颜色和印刷尺寸 -->
            <template
              v-if="isSeller && Number(detail.purchaseDemandFlag) === 1"
            >
              <el-table-column
                align="center"
                width="120"
                label="印刷颜色"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.printColor || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="120"
                label="印刷尺寸"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.sellerPrintSize || '--' }}
                </template>
              </el-table-column>
            </template>
            <!-- 供应商为卖家 显示 包装要求、产前样确认 -->
            <template v-if="isSeller">
              <el-table-column
                align="center"
                width="120"
                label="包装要求"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.packaging || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="120"
                label="产前样确认"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.sampleFlag"
                    :active-value="1"
                    :inactive-value="0"
                    disabled
                  ></el-switch>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              align="center"
              width="180"
              prop="productPrintingDescribe"
              label="印刷描述"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  style="text-align: left; word-break: break-all"
                  v-html="
                    scope.row.printingWay.toUpperCase() != 'BLANK' &&
                    scope.row.productPrintingDescribe
                      ? scope.row.productPrintingDescribe.replace(/\n/g, '<br>')
                      : '--'
                  "
                ></div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              label="包装方式"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.packingMethodEn || '--' }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              width="120"
              prop="numberPerBox"
              label="装箱数量"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="boxVolumeMActual"
              label="外箱体积"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="boxWeightKgs"
              label="外箱重量"
            ></el-table-column>

            <el-table-column
              align="center"
              width="400"
              prop="logoInfo"
              label="印刷文件"
            >
              <template slot-scope="scope">
                <el-row>
                  <div
                    v-if="
                      scope.row.fileList &&
                      scope.row.fileList.length &&
                      scope.row.printingWay.toUpperCase() != 'BLANK'
                    "
                  >
                    <div
                      class="flie-list"
                      v-for="(item, i) in scope.row.fileList"
                      :key="i"
                    >
                      <i class="el-icon-document"></i>
                      <a class="page-link ml5">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.name"
                          placement="top-start"
                        >
                          <span class="ell">
                            <router-link
                              :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                              target="_blank"
                            >
                              {{ item.name }}
                            </router-link>
                          </span>
                        </el-tooltip>
                      </a>
                      <span class="size ml10">
                        {{ utils.calculateFileSize(item.size) }}
                      </span>
                      <span class="ml10">{{ item.time }}</span>
                    </div>
                  </div>
                  <div v-else>--</div>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="supplierDiscount"
              label="供应商折扣金额"
            >
              <template slot-scope="scope">
                {{
                  (scope.row.supplierDiscount &&
                    scope.row.supplierDiscount.toFixed(3)) ||
                  '--'
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="supplierDiscountRemaks"
              label="供应商折扣备注"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.supplierDiscountRemaks || '--' }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="120" label="印刷费">
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ toFixed3(scope.row.setUpCharge) }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
            <el-table-column align="center" width="120" label="其他费用">
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ toFixed3(scope.row.otherCost) }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" label="费用说明">
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ scope.row.costDescription || '--' }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              width="180"
              label="备注"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.remark || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="150"
              prop="actualDeliveryDate"
              label="工厂交期"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="amountExcludingTax"
              width="150"
              label="总计-未税"
              fixed="right"
            >
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ isRmb ? '￥' : '$' }}
                  {{ utils.numberFormat(scope.row.amountExcludingTax, 2) }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="amountIncludingTax"
              width="150"
              label="总计-含税"
              fixed="right"
            >
              <template slot-scope="scope">
                <template v-if="isViewCost">
                  {{ isRmb ? '￥' : '$' }}
                  {{ utils.numberFormat(scope.row.amountIncludingTax, 2) }}
                </template>
                <NoViewCost v-else></NoViewCost>
              </template>
            </el-table-column>
          </el-table>
          <div class="account-num text-right mt10">
            总数量:
            <span>{{ detail.numTotal }}</span>
            未税金额:
            <span class="red">
              <template v-if="isViewCost">
                {{ isRmb ? '￥' : '$' }}
                {{ utils.numberFormat(detail.amountExcludingTax, 2) }}
              </template>
              <NoViewCost v-else></NoViewCost>
            </span>
            <template>抵扣金额:&nbsp;</template>
            <span class="red relative" style="padding-right: 15px">
              <template v-if="isViewCost">
                -{{ isRmb ? '￥' : '$' }}
                {{ utils.moneyFormat(detail.discountAmount, 2) }}
              </template>
              <NoViewCost v-else></NoViewCost>
              <el-tooltip
                class="item"
                effect="dark"
                :content="detail.discountReason"
                placement="top-start"
                :disabled="!detail.discountReason"
              >
                <i
                  class="el-icon-warning"
                  style="color: black"
                  v-if="isViewCost"
                ></i>
              </el-tooltip>
            </span>
            含税金额:
            <span class="red">
              <template v-if="isViewCost">
                {{ isRmb ? '￥' : '$' }}
                {{ utils.numberFormat(detail.amountIncludingTax, 2) }}
              </template>
              <NoViewCost v-else></NoViewCost>
            </span>
          </div>
          <!-- 如果是卖家供应商，并且采购类型是按需，则显示收货地址分配数量 -->
          <pscdsTable
            v-if="isSeller && Number(detail.purchaseDemandFlag) === 1"
            class="mt20"
            :form="{
              purchaseProductEditDTOList: detail.purchaseProductShowVOList,
            }"
            isDetail
          />
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="flex-btn mt10 mb15">
                <h4>箱唛</h4>
                <el-button @click="printMarks(detail.marks)">
                  预览箱唛
                </el-button>
                <el-button
                  @click="downloadMarks(detail.marks, detail.purchaseOrderId)"
                >
                  下载箱唛
                </el-button>
              </div>
              <p style="white-space: pre-wrap">{{ detail.marks }}</p>
            </el-col>
            <el-col :span="12">
              <h4>备注</h4>
              <p class="text-pre-wrap">{{ detail.remark }}</p>
            </el-col>
          </el-row>
          <h4 v-if="signContractList && signContractList.length">已盖章合同</h4>
          <div
            class="file_list"
            v-if="signContractList && signContractList.length"
          >
            <div
              v-for="(item, i) in signContractList"
              :key="i"
              class="file_line"
            >
              <i class="icon_file el-icon-link"></i>
              <span class="file_name c_pointer">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <router-link
                    class="d_block upload-file-color"
                    :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                    target="_blank"
                  >
                    {{ item.name }}
                  </router-link>
                </el-tooltip>
              </span>
              <span class="size">{{ utils.calculateFileSize(item.size) }}</span>
              <span class="time">{{ item.time }}</span>
              <i class="icon_del el-icon-close" @click="delFile(i)"></i>
            </div>
          </div>
          <h4>发货凭证</h4>
          <el-row>
            <el-col :span="6">
              <em>发货日期：</em>
              <span>{{ shippingCertificateData.shipmentDate || '--' }}</span>
            </el-col>
            <el-col :span="12" class="erp-upload-wrapper">
              <em>发货凭证：</em>
              <ErpUpload
                v-if="shippingCertificateData.orderFiles.length"
                ref="erpUploadRef"
                :uploadParams="{ uploadButton: false }"
              />
              <template v-else>--</template>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="采购产品信息" name="2">
          <div>
            <div class="flex-btn mb15">
              <h4>待收货</h4>
              <el-button
                @click="examiseCargo(1)"
                v-show="state == 5 || state == 6"
              >
                批量验货
              </el-button>
            </div>
            <el-table
              :data="cargoCollectList"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
              @selection-change="collectSetSelectRows"
              border
            >
              <el-table-column align="center" type="selection" width="55" />
              <el-table-column
                prop="salesOrderNumber"
                label="销售订单编号"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">{{ scope.row.salesOrderNumber }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="sku"
                label="产品编码"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">{{ scope.row.sku }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="nameCn"
                label="产品名称"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">{{ scope.row.nameCn }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="specsValue1"
                label="英文规格"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">
                    <span v-if="scope.row.specsValue1">
                      {{ getSpecsValue(scope.row) }}
                    </span>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="specsValue1"
                label="中文规格"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">
                    <span v-if="scope.row.specsValueCn1">
                      {{ getSpecsValueCn(scope.row) }}
                    </span>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="产品类型">
                <template slot-scope="scope">
                  {{ scope.row.standardCustomized || '--' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="printingWay"
                label="是否需要印刷"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.printingWay">
                    {{
                      scope.row.printingWay.toUpperCase() === 'BLANK'
                        ? '否'
                        : '是'
                    }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="numActual"
                label="采购数量"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="updateTime"
                label="更新时间"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="examiseCargo(2, scope.row)"
                    v-show="state == 5 || state == 6"
                  >
                    验货通知
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div v-show="cargoEnterList.length">
            <div class="flex-btn mt20 mb15">
              <h4>待入库</h4>
              <el-button @click="putStorage(1)" v-show="putStorageVisible">
                批量入库
              </el-button>
            </div>
            <el-table
              :data="cargoEnterList"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
              @selection-change="enterSetSelectRows"
              border
            >
              <el-table-column align="center" type="selection" width="55" />
              <el-table-column
                prop="salesOrderNumber"
                label="销售订单编号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="sku"
                label="产品编码"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="nameCn"
                label="产品名称"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="specsValue1"
                label="英文规格"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">
                    <span v-if="scope.row.specsValue1">
                      {{ getSpecsValue(scope.row) }}
                    </span>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="specsValue1"
                label="中文规格"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">
                    <span v-if="scope.row.specsValueCn1">
                      {{ getSpecsValueCn(scope.row) }}
                    </span>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="standardCustomized" label="产品类型">
                <template slot-scope="scope">
                  {{ scope.row.standardCustomized }}
                </template>
              </el-table-column>
              <el-table-column
                prop="printingWay"
                label="是否需要印刷"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.printingWay">
                    {{
                      scope.row.printingWay.toUpperCase() === 'BLANK'
                        ? '否'
                        : '是'
                    }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <!--  -->
              <el-table-column
                prop="numActual"
                label="采购数量"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  <span>
                    {{ row.state == 28 ? row.numInspection : row.numActual }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="numQualified"
                label="待入库数量"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="actualDeliveryDate"
                label="期望交期"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="inspector"
                label="QC"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column prop="state" label="状态">
                <template slot-scope="scope">
                  <el-link v-if="scope.row.state == 0" type="primary">
                    审核中
                  </el-link>
                  <el-link v-else-if="scope.row.state == 11" type="primary">
                    待收货
                  </el-link>
                  <el-link v-else-if="scope.row.state == 21" type="info">
                    无需验货
                  </el-link>
                  <el-link v-else-if="scope.row.state == 22" type="primary">
                    验货中
                  </el-link>
                  <el-link v-else-if="scope.row.state == 23" type="success">
                    验货通过
                  </el-link>
                  <el-link v-else-if="scope.row.state == 24" type="danger">
                    不合格
                  </el-link>
                  <el-link v-else-if="scope.row.state == 25" type="primary">
                    入库申请中
                  </el-link>
                  <el-link v-else-if="scope.row.state == 26" type="danger">
                    入库申请驳回
                  </el-link>
                  <el-link v-else-if="scope.row.state == 27" type="danger">
                    入库异常
                  </el-link>
                  <el-link v-else-if="scope.row.state == 28" type="danger">
                    更换供应商
                  </el-link>
                  <el-link v-else-if="scope.row.state == 31" type="success">
                    已完成
                  </el-link>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    v-if="
                      scope.row.numQualified > 0 ||
                      scope.row.state == 23 ||
                      scope.row.state == 26
                    "
                    @click="putStorage(2, scope.row)"
                  >
                    入库申请
                  </el-button>
                  <el-button
                    v-else-if="scope.row.state == 24"
                    @click="
                      dispose(
                        scope.row.inspectionId,
                        scope.row.purchaseProductId,
                        scope.row
                      )
                    "
                  >
                    处理
                  </el-button>
                  <el-button v-else-if="scope.row.state == 27" @click="anomaly">
                    处理
                  </el-button>
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <h4>已完成</h4>
          <el-table
            :data="cargoFinishList"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            border
          >
            <el-table-column
              prop="salesOrderNumber"
              label="销售订单编号"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="sku"
              label="产品编码"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="nameCn"
              label="产品名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="specsValue1"
              label="英文规格"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="ell">
                  <div v-if="scope.row.specsValue1">
                    {{ getSpecsValue(scope.row) }}
                  </div>
                  <div v-else>--</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="specsValue1"
              label="中文规格"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="ell">
                  <div v-if="scope.row.specsValueCn1">
                    {{ getSpecsValueCn(scope.row) }}
                  </div>
                  <div v-else>--</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="standardCustomized" label="产品类型">
              <template slot-scope="scope">
                {{ scope.row.standardCustomized }}
              </template>
            </el-table-column>
            <el-table-column
              prop="printingWay"
              label="是否需要印刷"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.printingWay">
                  {{
                    scope.row.printingWay.toUpperCase() === 'BLANK'
                      ? '否'
                      : '是'
                  }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="numActual"
              label="采购数量"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="actualDeliveryDate"
              label="期望交期"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="inspector"
              label="QC"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="numWarehouseIn"
              label="入库数量"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="warehouseInDate"
              label="入库时间"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  v-show="scope.row.abnormalWarehousingStatus == 2"
                  @click="disposeRefund(scope.row.purchaseOrderId)"
                >
                  处理
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="付款/发票" name="3">
          <div class="flex-btn mb15">
            <h4>交易情况</h4>
            <el-button
              v-allowed="['PURCHASE:PAY_REFUND_APPLY']"
              @click="payment(0, false)"
              v-show="state == 5 || state == 6 || state == 11 || state == 7"
            >
              付款/退款申请
            </el-button>
          </div>

          <div class="custom-detail">
            <el-row>
              <el-col class="w_90">
                <em>订单总金额：</em>
                <span class="gray">
                  <template v-if="isViewCost">
                    {{ isRmb ? '￥' : '$'
                    }}{{
                      utils.numberFormat(
                        detail.amountTotal + detail.discountAmount,
                        2
                      )
                    }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </span>
              </el-col>
              <el-col class="w_90">
                <em>抵扣金额：</em>
                <span class="gray relative">
                  <template v-if="isViewCost">
                    {{ isRmb ? '￥' : '$'
                    }}{{ utils.moneyFormat(detail.discountAmount) || 0 }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="detail.discountReason"
                    placement="top-start"
                    :disabled="!detail.discountReason"
                  >
                    <i class="el-icon-warning" v-if="isViewCost"></i>
                  </el-tooltip>
                </span>
              </el-col>

              <el-col class="w_90">
                <em>应付金额：</em>
                <span class="gray">
                  <template v-if="isViewCost">
                    {{ isRmb ? '￥' : '$'
                    }}{{ utils.numberFormat(detail.amountTotal, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </span>
              </el-col>
              <el-col>
                <em>已付金额：</em>
                <span class="black">
                  <template v-if="isViewCost">
                    {{ isRmb ? '￥' : '$'
                    }}{{ utils.numberFormat(detail.amountPaid, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </span>
              </el-col>
              <el-col>
                <em>退款金额：</em>
                <span class="red">
                  <template v-if="isViewCost">
                    {{ isRmb ? '￥' : '$'
                    }}{{ utils.numberFormat(detail.amountRefunded, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </span>
              </el-col>
              <el-col>
                <em>交易金额：</em>
                <span class="green">
                  <template v-if="isViewCost">
                    {{ isRmb ? '￥' : '$' }}
                    {{ utils.numberFormat(detail.amountPaid, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </span>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="w_90">
                <em>开&nbsp;&nbsp;&nbsp;户&nbsp;&nbsp;&nbsp;行：</em>
                <span>{{ detail.bankName || '--' }}</span>
              </el-col>
              <el-col>
                <em>账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户：</em>
                <span>{{ detail.bankAccount || '--' }}</span>
              </el-col>
              <el-col>
                <em>户&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</em>
                <span>{{ detail.accountName || '--' }}</span>
              </el-col>
              <el-col>
                <em>税&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：</em>
                <span>{{ detail.dutyAccount || '--' }}</span>
              </el-col>
            </el-row>
          </div>

          <div class="c-table-wrap">
            <!-- 新的表格，支持合并单元格 -->
            <el-table
              :data="paymentList"
              :span-method="
                (...rest) => mergeRowByColumn(...rest, paymentList, mergeList)
              "
              border
            >
              <el-table-column label="交易记录" align="center" min-width="100">
                <template slot-scope="{ row: item }">
                  {{ item.tradingRecordStr.split(separator)[0] || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="类型" align="center">
                <template slot-scope="{ row: item }">
                  {{ item.typeStr.split(separator)[0] || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="预计交易金额" align="center">
                <template slot-scope="{ row: item }">
                  {{ item.amountPayableStr.split(separator)[0] || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="预计交易时间" align="center">
                <template slot-scope="{ row: item }">
                  {{
                    item.estimatedTransactionDateStr.split(separator)[0] || '--'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="实际交易金额" align="center">
                <template slot-scope="{ row: item }">
                  <div :style="{ color: item.entryType == '1' ? 'red' : '' }">
                    <template v-if="isViewCost">
                      <span v-if="item.entryType == '1'">-</span>
                      <span>
                        {{ item.currency == '人民币' ? '￥' : '$' }}

                        {{ utils.numberFormat(item.amountActual, 2) }}
                      </span>
                    </template>
                    <NoViewCost v-else></NoViewCost>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="实际交易时间" align="center">
                <template slot-scope="{ row: item }">
                  {{ utils.cutDate(item.confirmTime) || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="交易单号" align="center" min-width="100">
                <template slot-scope="{ row: item }">
                  <span
                    v-if="item.paymentOrderNumber.includes('FK')"
                    class="page-link"
                    @click="viewFK(item)"
                  >
                    {{ item.paymentOrderNumber }}
                  </span>
                  <span
                    v-else-if="item.paymentOrderNumber.includes('FY')"
                    class="page-link"
                    @click="viewFY(item)"
                  >
                    {{ item.paymentOrderNumber }}
                  </span>
                  <span v-else>
                    {{ item.paymentOrderNumber || '--' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="交易状态" align="center">
                <template slot-scope="{ row: item }">
                  <div v-if="item.type == 0" class="tool-tip">
                    <span v-if="item.state == 1 || item.state == 2">
                      付款中
                    </span>
                    <span v-else-if="item.state == 3">
                      <el-tooltip
                        class="item"
                        :content="item.rejectReason"
                        placement="top"
                        effect="light"
                      >
                        <div slot="content">
                          <p>驳回人：{{ item.updatorName || '--' }}</p>
                          <p class="mt5">
                            驳回原因：{{ item.rejectReason || '--' }}
                          </p>
                          <p class="mt5">
                            驳回时间：{{ item.updateTime || '--' }}
                          </p>
                        </div>
                        <span>付款驳回</span>
                      </el-tooltip>
                    </span>
                    <span v-else-if="item.state == 4">付款完成</span>
                    <span v-else-if="item.state == 5">银行处理中</span>
                    <span v-else>--</span>
                    <erp-mouseover-tip v-if="item.bankReturnFlag == 1">
                      <template name="content">
                        <div>银行退票，请联系财务处理</div>
                      </template>
                    </erp-mouseover-tip>
                  </div>
                  <div v-else-if="item.type == 2" class="tool-tip">
                    <span v-if="item.state == 1 || item.state == 2">
                      收款中
                    </span>
                    <span v-else-if="item.state == 3">
                      <el-tooltip
                        class="item"
                        :content="item.rejectReason"
                        placement="top"
                        effect="light"
                      >
                        <span>收款驳回</span>
                      </el-tooltip>
                    </span>
                    <span v-else-if="item.state == 4">收款完成</span>
                    <span v-else-if="item.state == 5">银行处理中</span>
                    <span v-else>-</span>
                    <erp-mouseover-tip v-if="item.bankReturnFlag == 1">
                      <template name="content">
                        <div>银行退票，请联系财务处理</div>
                      </template>
                    </erp-mouseover-tip>
                  </div>
                  <div v-else-if="item.type == 4" class="tool-tip">
                    <span v-if="item.state == 1 || item.state == 2">
                      付款中
                    </span>
                    <span v-else-if="item.state == 3">
                      <el-tooltip
                        class="item"
                        :content="item.rejectReason"
                        placement="top"
                        effect="light"
                      >
                        <div slot="content">
                          <p>驳回人：{{ item.updatorName || '--' }}</p>
                          <p class="mt5">
                            驳回原因：{{ item.rejectReason || '--' }}
                          </p>

                          <p class="mt5">
                            驳回时间：{{ item.updateTime || '--' }}
                          </p>
                        </div>
                        <span>付款驳回</span>
                      </el-tooltip>
                    </span>
                    <span v-else-if="item.state == 4">付款完成</span>
                    <span v-else-if="item.state == 5">银行处理中</span>
                    <span v-else>--</span>
                    <erp-mouseover-tip v-if="item.bankReturnFlag == 1">
                      <template name="content">
                        <div>银行退票，请联系财务处理</div>
                      </template>
                    </erp-mouseover-tip>
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <el-table-column label="审核单号" align="center">
                <template slot-scope="{ row: item }">
                  {{ item.auditNumber || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="审核状态" align="center">
                <template slot-scope="{ row: item }">
                  <span
                    class="span-audit process"
                    v-if="
                      item.applicationPayOrderState == 0 ||
                      item.applicationPayOrderState == 1
                    "
                    @click="
                      statusTypeClick(
                        item.applicationPayOrderId,
                        item.paymentOrderId,
                        item.paymentOrderNumber,
                        item.type
                      )
                    "
                  >
                    审核中
                  </span>
                  <span
                    class="span-audit pass"
                    v-else-if="item.applicationPayOrderState == 2"
                    @click="
                      statusTypeClick(
                        item.applicationPayOrderId,
                        item.paymentOrderId,
                        item.paymentOrderNumber,
                        item.type
                      )
                    "
                  >
                    审核通过
                  </span>
                  <span
                    class="span-audit reject"
                    v-else-if="item.applicationPayOrderState == 3"
                    @click="
                      statusTypeClick(
                        item.applicationPayOrderId,
                        item.paymentOrderId,
                        item.paymentOrderNumber,
                        item.type
                      )
                    "
                  >
                    审核驳回
                  </span>

                  <el-tooltip
                    placement="top"
                    effect="light"
                    v-else-if="item.applicationPayOrderState == 4"
                  >
                    <div slot="content">
                      <span>
                        {{ '撤销人：' + (item.updatorName || '--') }}
                      </span>
                      <br />
                      <span>
                        {{ '撤销原因：' + (item.rejectReason || '--') }}
                      </span>
                      <br />
                      <span>
                        {{ '撤销时间：' + (item.updateTime || '--') }}
                      </span>
                    </div>
                    <span type="danger" plain size="small">已撤销</span>
                  </el-tooltip>
                  <span v-else-if="item.applicationPayOrderState == 5">
                    重新提交
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="申请人" align="center">
                <template slot-scope="{ row: item }">
                  {{ item.applyName || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="申请时间" align="center">
                <template slot-scope="{ row: item }">
                  {{ utils.cutDate(item.applyTime) || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="{ row: item }">
                  <div
                    class="page-link"
                    v-show="item.type == 4 && item.state == 4 && isViewCost"
                    @click="costView(item)"
                  >
                    查看费用分摊
                  </div>
                  <div class="page-link" v-show="item.receiptFile">
                    <router-link
                      :to="`/preview-file/index?name=${
                        item.paymentOrderNumber + '.pdf'
                      }&url=${item.receiptFile}`"
                      target="_blank"
                    >
                      电子回单
                    </router-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div v-show="detail.financeSetupDuty">
            <div class="flex-btn mt20 mb15">
              <h4>发票情况</h4>
              <el-button
                v-allowed="['PURCHASE:INVOICE_REGISTER']"
                @click="offerRegister()"
                v-show="
                  (state == 5 || state == 6 || state == 7 || state == 11) &&
                  detail.operatePurchaseOrderInvoice === 1 &&
                  detail.amountTotal - detail.amountInvoiced > 0
                "
              >
                发票登记
              </el-button>
            </div>
            <div class="c-table-wrap">
              <table class="custom-table">
                <thead>
                  <tr>
                    <th width="200">应开票金额</th>
                    <th width="200">已开票金额</th>
                    <th width="200">未开票金额</th>
                    <th width="300">公司名称</th>
                    <th width="300">税号</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="gray">
                      <template v-if="isViewCost">
                        {{ isRmb ? '￥' : '$'
                        }}{{ utils.numberFormat(invoiceAmount, 2) }}
                      </template>
                      <NoViewCost v-else></NoViewCost>
                    </td>
                    <td class="green">
                      <template v-if="isViewCost">
                        {{ isRmb ? '￥' : '$'
                        }}{{ utils.numberFormat(detail.amountInvoiced, 2) }}
                      </template>
                      <NoViewCost v-else></NoViewCost>
                    </td>
                    <td class="red">
                      <template v-if="isViewCost">
                        {{ isRmb ? '￥' : '$'
                        }}{{
                          utils.numberFormat(
                            invoiceAmount - detail.amountInvoiced,
                            2
                          )
                        }}
                      </template>
                      <NoViewCost v-else></NoViewCost>
                    </td>
                    <td>{{ detail.financeSetupName }}</td>
                    <td>{{ detail.financeSetupDuty }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="c-table-wrap">
              <table class="custom-table mt20">
                <thead>
                  <tr>
                    <th width="200">开票时间</th>
                    <th width="200">发票号码</th>
                    <th width="200">发票类型</th>
                    <th width="200">开票金额</th>
                    <th width="200">发票凭证</th>
                    <th width="200">创建人</th>
                    <th width="200">创建时间</th>
                    <th width="200">状态</th>
                    <th width="100">操作</th>
                  </tr>
                </thead>
                <tbody v-if="invoiceList.length">
                  <tr v-for="(item, i) in invoiceList" :key="i">
                    <td>{{ item.invoiceDate }}</td>
                    <td>{{ item.invoiceNumber }}</td>
                    <td>{{ item.invoiceType }}</td>
                    <td>
                      <template v-if="isViewCost">
                        {{ isRmb ? '￥' : '$'
                        }}{{ utils.numberFormat(item.amountCurrent, 2) }}
                      </template>
                      <NoViewCost v-else></NoViewCost>
                    </td>
                    <!-- 发票凭证 -->
                    <td>
                      <template
                        v-if="
                          (item.imgList && item.imgList.length) ||
                          (item.pdfList && item.pdfList.length)
                        "
                      >
                        <el-row type="flex" align="middle" justify="center">
                          <!-- 回显图片 -->
                          <template v-if="item.imgList.length">
                            <el-image
                              :src="item.imgList[0].url"
                              class="c_pointer"
                              @click="
                                handlePreview(
                                  item.imgList.map((img) => img.url)
                                )
                              "
                              style="width: 50px; height: 50px"
                            ></el-image>
                            <span
                              class="fileAnnex-span"
                              v-if="item.imgList.length > 1"
                            >
                              {{ item.imgList.length }}
                            </span>
                          </template>
                        </el-row>
                        <!-- 回显pdf -->
                        <div v-if="item.pdfList.length">
                          <div
                            class="flie-list"
                            v-for="pdf in item.pdfList"
                            :key="pdf.url"
                            style="width: 300px"
                          >
                            <i class="el-icon-document"></i>
                            <a class="page-link ml5">
                              <el-tooltip
                                class="item"
                                effect="dark"
                                :content="pdf.name"
                                placement="top-start"
                              >
                                <span class="ell" style="max-width: 120px">
                                  <router-link
                                    :to="`/preview-file/index?name=${pdf.name}&url=${pdf.url}`"
                                    target="_blank"
                                  >
                                    {{ pdf.name }}
                                  </router-link>
                                </span>
                              </el-tooltip>
                            </a>
                            <span class="size ml10" style="white-space: nowrap">
                              {{ utils.calculateFileSize(pdf.size) }}
                            </span>
                            <span class="ml10" style="white-space: nowrap">
                              {{ pdf.time }}
                            </span>
                          </div>
                        </div>
                      </template>
                      <span v-else>/</span>
                    </td>
                    <td>{{ detail.creator }}</td>
                    <td>{{ item.createTime || '--' }}</td>
                    <td>{{ item.state == 0 ? '已生成' : '已作废' }}</td>
                    <td>
                      <el-tooltip content="作废" placement="top">
                        <el-button
                          type="primary"
                          plain
                          circle
                          size="mini"
                          v-show="item.state == 0"
                          @click="invoiceDeleteClick(item, i)"
                          v-allowed="['PURCHASE:INVALID']"
                        >
                          <i class="iconfont icon-void f_s_12"></i>
                        </el-button>
                      </el-tooltip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="empty">
                    <td colspan="99">暂无数据</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="mt20" v-if="productListTable.length">
            <h4>产品收入成本</h4>
            <el-table border :data="productListTable">
              <el-table-column
                label="产品编码"
                align="center"
                prop="sku"
              ></el-table-column>
              <el-table-column
                label="产品名称"
                align="center"
                prop="nameCn"
                width="240"
              ></el-table-column>
              <el-table-column
                label="英文规格"
                align="center"
                prop="specification"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">
                    <span v-if="scope.row.specsValue1">
                      {{ getSpecsValue(scope.row) }}
                    </span>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="中文规格"
                align="center"
                prop="specification"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="ell">
                    <div v-if="scope.row.specsValueCn1">
                      {{ getSpecsValueCn(scope.row) }}
                    </div>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="产品类型"
                align="center"
                prop="standardCustomized"
              ></el-table-column>
              <el-table-column label="是否需要印刷" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.printingWay.toUpperCase() === 'BLANK'
                      ? '否'
                      : '是'
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单数量"
                align="center"
                prop="numActual"
              ></el-table-column>

              <el-table-column
                align="center"
                prop="amountIncludingTax"
                label="订单金额"
              >
                <template slot="header">
                  订单金额 ({{ isRmb ? '￥' : '$' }})
                </template>
                <template slot-scope="scope">
                  <template v-if="isViewCost">
                    {{ utils.numberFormat(scope.row.amountIncludingTax, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </template>
              </el-table-column>
              <el-table-column
                label="实际入库数量"
                align="center"
                prop="numWarehouseIn"
              ></el-table-column>
              <el-table-column align="center" prop="amountIncludingTax">
                <template slot="header">产品金额(￥)</template>
                <template slot-scope="scope">
                  <template v-if="isViewCost">
                    {{ utils.numberFormat(scope.row.receiptCostEx, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="expense" width="160px">
                <template slot="header">费用分摊金额 （￥）</template>
                <template slot-scope="scope">
                  <template v-if="isViewCost">
                    {{ utils.numberFormat(scope.row.expenseEx, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="actualPrice">
                <template slot="header">实际成本(￥)</template>
                <template slot-scope="scope">
                  <template v-if="isViewCost">
                    {{ utils.numberFormat(scope.row.actualPriceEx, 2) }}
                  </template>
                  <NoViewCost v-else></NoViewCost>
                </template>
              </el-table-column>
            </el-table>

            <h4 class="text-right mt10">
              <span class="mr30">汇率：{{ detail.exchangeRate }}</span>

              <span>实际总成本</span>
              <template v-if="isViewCost">
                ￥
                {{ utils.amountTotal(productListTable, 'actualPriceEx', 2) }}
              </template>
              <NoViewCost v-else></NoViewCost>
            </h4>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="4" v-if="lang != 'en'">
          <OperateLogs
            ref="operateLogs"
            v-if="active == '4'"
            :showModule="false"
            :bizId="detail.purchaseOrderId"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 批量验货 -->
    <collect-cargo
      ref="collectCargo"
      @handle-reshipment="handleReshipment"
    ></collect-cargo>

    <!-- 批量入货 -->
    <enter-cargo :storageList="storageList" ref="enterCargo"></enter-cargo>

    <!-- 处理 -->
    <dispose
      ref="dispose"
      @inspection-notice="inspectionNotice"
      @re-get-getail="reGetDetail"
    ></dispose>

    <!-- 申请付款 -->
    <OrderPayment ref="OrderPayment" title="付款/退款申请"></OrderPayment>

    <!-- 发票登记 -->
    <order-register ref="orderRegister" @orderReg="orderReg"></order-register>

    <!-- 入库异常 -->
    <enter-cargo-error ref="enterCargoError"></enter-cargo-error>

    <!-- 处理退款 -->
    <dispose-refund ref="disposeRefund"></dispose-refund>

    <!-- 查看费用分摊 -->
    <cost-apportion ref="costApportion"></cost-apportion>

    <!-- 审核进度Drawer -->
    <el-drawer
      title="审核进度"
      :visible.sync="statusDrawer"
      :before-="statusClose"
    >
      <Audit
        ref="Audit"
        :auditObjectId="auditObjectId"
        @cancelClick="statusClose"
      />
    </el-drawer>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />

    <!-- 付款单详情 -->
    <fk-model ref="fkModel"></fk-model>

    <!-- 费用单详情 -->
    <fy-model ref="fyModel"></fy-model>

    <!-- 上传发货凭证弹框 -->
    <ShippingCertificateDialog
      v-model="shippingCertificateVisible"
      :shippingCertificateData="shippingCertificateData"
      @refresh="handleRefresh"
    />
  </div>
</template>

<script>
  import {
    OrderListInteractor,
    InboundSheet,
    OrderSettingInteractor,
  } from '@/core'
  import WewooTitleTip from 'wewoo-title-tip'
  import collectCargo from '../purchaseOrder/components/collectCargo.vue'
  import enterCargo from '../purchaseOrder/components/enterCargo.vue'
  import dispose from '../purchaseOrder/components/dispose.vue'
  import applyPayment from '../purchaseOrder/components/applyPayment.vue'
  import OrderRegister from '../purchaseOrder/components/orderRegister.vue'
  import enterCargoError from '../purchaseOrder/components/enterCargoError.vue'
  import disposeRefund from './components/disposeRefund.vue'
  import costApportion from './components/costApportion.vue'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import { mapGetters } from 'vuex'
  import { downloadMarksCommon } from '@/utils/utils.js'
  import { hasRight } from '@/utils/permissionBtn'
  import OrderPayment from './components/orderPayment.vue'
  import viewCost, { NO_VIEW_COST_SHOW } from './utils'
  import NoViewCost from './components/no-view-cost'
  import OperateLogs from '@/components/operate-logs'
  import fkModel from '@/views/finance-center/payment-manage/components/payment-detail-model.vue'
  import fyModel from '@/views/finance-center/cost-manage/components/payment-detail-model.vue'
  import pscdsTable from '../purchaseDemand/components/pscdsTable'
  import ShippingCertificateDialog from './components/ShippingCertificateDialog.vue'
  import { getSpecsValue, getSpecsValueCn } from '@/utils/specs-utils'
  import { download, format, encode, mergeRowByColumn, isJson } from 'kits'
  export default {
    components: {
      WewooTitleTip,
      collectCargo,
      enterCargo,
      dispose,
      applyPayment,
      OrderRegister,
      enterCargoError,
      disposeRefund,
      costApportion,
      Audit,
      OrderPayment,
      NoViewCost,
      OperateLogs,
      fkModel,
      fyModel,
      pscdsTable,
      ShippingCertificateDialog,
    },
    data() {
      return {
        // 需要合并的列：交易记录、类型、预计交易金额、预计交易时间
        mergeList: [
          { column: 0, prop: 'tradingRecordStr' },
          { column: 1, prop: 'typeStr' },
          { column: 2, prop: 'amountPayableStr' },
          { column: 3, prop: 'estimatedTransactionDateStr' },
        ],
        separator: '$~****~$', // 分隔符需要复杂点，不能用****，因为isViewCost为false时，金额是******
        isViewCost: viewCost(), //查看成本
        active: '1',
        detail: {},
        state: '',
        number: '',
        /**
         * 订单信息
         */
        productListTable: [], // 产品列表
        productKey: 0,
        /**
         * 采购产品信息
         */
        cargoCollectList: [], // 采购收货列表
        cargoEnterList: [], // 采购入库列表
        cargoFinishList: [], // 采购完成列表
        collectSelectRows: [], // 收货集合
        enterSelectRows: [], // 入库集合
        /**
         * 付款/发票
         */
        paymentList: [], // 付款环节列表
        invoiceList: [], // 发票列表
        invoiceAmount: 0, // 应开票金额（有税率产品的总计-含税总计）
        /**
         * 详情展示字段
         */
        transactionMoney: 0, // 交易金额
        paidMoney: 0, // 已付金额
        paymentVisible: false,
        putStorageVisible: true,
        isRmb: true, // 当前币种
        refundMoney: '', // 退款金额总和
        /**
         * 审核进度
         */
        statusDrawer: false, //审核进度,
        auditObjectId: '', //审核id
        auditType: 7, //付款的审核type
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        fileList1: [],
        refundFlag: '', // 付款申请标识
        signContractList: [], // 已盖章合同
        storageList: [], // 入库申请入库仓库列表
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentDefaultRendingList: [], // 当前选中的图片
        hasQCEditRight: false,
        isStock: false,
        showSwitch: 0,
        bizId: '',
        shippingCertificateVisible: false, // 上传发货凭证弹框是否打开
        shippingCertificateData: {
          businessId: '',
          orderFiles: [],
          shipmentDate: '',
        }, // 发货凭证详情
        tabsType: '', // 1-采购需求 2-备货需求
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      showDownloadShippingLabel() {
        const regex = /^C\d+$/
        if (this.number && regex.test(this.number)) {
          const arr = [1, 4, 5, 6, 7, 11]
          if (arr.includes(this.state)) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      // 当采购订单中选择的供应商有关联的卖家时
      isSeller() {
        const { sellerSupplierFlag, purchaseProductShowVOList } = this.detail
        const flag = purchaseProductShowVOList?.every((item) =>
          [null, undefined, ''].includes(item.parentSku)
        )
        return +sellerSupplierFlag === 1 && flag
      },
    },
    filters: {
      // 交货方式转义
      stateNames(n) {
        const dicts = {
          1: '录入中',
          2: '已驳回',
          3: '已撤销',
          4: '审核中',
          5: '采购中',
          6: '验货中',
          7: '已完成',
          8: '已作废',
          9: '销售订单异常',
          10: '供应商延期',
          11: '已入库',
          12: '入库异常',
        }

        return dicts[n]
      },

      // 发票类型转换
      invoiceTypeFilter(val) {
        const num2text = {
          1: '增值税专用发票',
          2: '增值税普通发票',
          3: '不开票',
        }
        return num2text[val] || '--'
      },
    },
    created() {
      let id = this.$route.query.id
      this.getDetail(id)
      this.getPurchaseOrderExtend(id) // 获取发货凭证详情
      this.bizId = id
      this.hasQCEditRight = hasRight('QC:EDIT')
    },
    methods: {
      getSpecsValue,
      getSpecsValueCn,
      mergeRowByColumn,
      // 下载送货标签
      async downloadShippingLabel() {
        let res = await OrderListInteractor.exportDeliverLabelWordApi({
          number: this.number,
        })
        const data = format(new Date())
        const fileName = `${this.number}送货标签-${data}`
        download('word', fileName, res)
      },
      reGetDetail() {
        this.getDetail(this.detail.purchaseOrderId)
      },
      inspectionNotice(pro) {
        this.examiseCargo(2, pro, true)
      },

      getUser() {
        const { merchandiserId, merchandiserName } = this.detail

        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId: merchandiserId
              ? [
                  {
                    id: merchandiserId,
                    name: merchandiserName,
                  },
                ]
              : '',
            canSelectZeroUser: false,
            singleSelect: true,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, englishName } = users[0]
              OrderListInteractor.updateQC({
                merchandiserId: id,
                merchandiserName: name,
                purchaseOrderId: this.detail.purchaseOrderId,
              }).then((res) => {
                this.detail.merchandiserId = id
                this.detail.merchandiserName = name
              })
            }
          })
      },
      // 获取订单详情
      getDetail(id) {
        OrderListInteractor.purchaseGetDetailForShow({ businessId: id }).then(
          (res) => {
            const data = res?.data
            if (data) {
              // 判断是否备货
              const isStock =
                data.purchaseProductShowVOList[0].salesOrderNumber.includes(
                  'PD'
                )
                  ? true
                  : false
              this.showSwitch =
                data.purchaseProductShowVOList[0].salesOrderNumber.includes(
                  'PD'
                )
                  ? '1'
                  : '0'
              // 赋值全部数据
              this.detail = data
              console.log('data', JSON.parse(JSON.stringify(data)))
              if (data.purchaseProductShowVOList?.length) {
                this.tabsType = data.purchaseProductShowVOList[0].type // 1-采购需求 2-备货需求
              }
              // 判断当前币种
              // this.isRmb =
              //   data.isReviewed == 0 || data.currency == '人民币' ? true : false
              this.isRmb = data.currency == '人民币' ? true : false
              // 赋值当前状态
              this.state = data.state
              // 赋值采购单号
              this.number = data.number
              // 赋值附件
              this.fileList1 = res.data.contractToBeApproved
                ? JSON.parse(res.data.contractToBeApproved)
                : []

              // 已盖章合同
              this.signContractList = res.data.sealedContract
                ? JSON.parse(res.data.sealedContract)
                : []
              // 判断是否为备货合同
              this.isStock =
                this.userInfo.area === '合肥' && isStock ? true : false
              // 赋值产品列表
              this.productListTable = data.purchaseProductShowVOList
              this.productListTable.forEach((item) => {
                item.fileList = item.logoInfo?.trim()
                  ? JSON.parse(item.logoInfo)
                  : []
                if (item.abnormalWarehousingStatus == 2) {
                  this.refundFlag = this.detail.number
                }
              })
              this.productKey += 1
              // 赋值应开票金额
              this.invoiceAmount = data.invoiced

              // 赋值采购收货列表
              this.cargoCollectList = data.purchaseProductShowVOList.filter(
                (item) => {
                  if (item.state == 11) {
                    return {
                      ...item,
                    }
                  }
                }
              )
              // 赋值采购入库列表
              this.cargoEnterList = data.purchaseProductShowVOList.filter(
                (item) => {
                  if (item.state != 0 && item.state != 11 && item.state != 31) {
                    return {
                      ...item,
                    }
                  }
                }
              )
              // 赋值采购完成列表
              this.cargoFinishList = data.purchaseProductShowVOList.filter(
                (item) => {
                  if (item.state == 31) {
                    return {
                      ...item,
                    }
                  }
                }
              )
              // 赋值付款环节列表
              // this.paymentList = data.purchasePaymentMethodShowVOList
              this.paymentList = this.getPaymentList(
                data.purchasePaymentMethodShowVOList || []
              )
              // 赋值发票列表
              this.invoiceList = data.purchaseInvoiceShowVOList.map((item) => {
                const { fileAnnex } = item
                if (item.invoiceType === '增值税发票') {
                  item.invoiceType = '专用发票'
                }
                if (isJson(fileAnnex)) {
                  const fileList = JSON.parse(fileAnnex)
                  // 文件类型只有图片和pdf
                  item.imgList = fileList.filter((n) => !n.url.includes('pdf'))
                  item.pdfList = fileList.filter((n) => n.url.includes('pdf'))
                } else {
                  item.imgList = item.fileAnnex
                    .split(',')
                    .map((url) => ({ url }))
                  item.pdfList = []
                }
                return item
              })

              // 计算付款/发票(交易金额，已付金额)
              if (this.paymentList.length) {
                this.calculateMoney(this.paymentList)
              }
              // 已付金额>=交易金额
              this.paymentVisible = this.paidMoney >= this.transactionMoney
              // 获取入库申请出库仓库 PS:按需采购的入库申请下拉框加虚拟仓,备货的入库下拉框不加虚拟仓
              this.storageList = this.getStorageList(isStock)
            }
          }
        )
      },
      // 获取交易记录表格数据
      getPaymentList(list = []) {
        const { separator, isViewCost } = this
        const newList = list.filter((row, index) => {
          const {
            type,
            entryType,
            paymentNode,
            percent,
            paymentNodeName,
            currency,
            amountPayable,
            estimatedTransactionDate,
          } = row
          let tradingRecordStr = '' // 交易记录
          let typeStr = '' // 类型
          let amountPayableStr = '' // 预计交易金额
          if (type == 0) {
            if (entryType == 1) {
              tradingRecordStr = '采购退款'
            } else {
              tradingRecordStr = `账期-${parseInt(percent * 100) || 0}%${
                paymentNodeName || ''
              }`
            }
          } else {
            tradingRecordStr = paymentNodeName
          }
          if (type == 0) {
            if (entryType == 1) {
              typeStr = '退款'
            } else {
              typeStr = '付款'
            }
          } else if (type == 2) {
            typeStr = '退款'
          } else if (type == 4) {
            typeStr = '费用'
          }
          const symbol = currency == '人民币' ? '￥' : '$'
          if (isViewCost) {
            const amount = this.utils.numberFormat(amountPayable, 2)
            amountPayableStr = `${symbol}${amount}`
          } else {
            amountPayableStr = NO_VIEW_COST_SHOW
          }
          // 生成需要合并的列表的数据：只有类型为付款时，才需要合并
          if (typeStr === '付款') {
            row.tradingRecordStr = tradingRecordStr // 交易记录
            row.typeStr = typeStr // 类型
            row.amountPayableStr = amountPayableStr // 预计交易金额
            row.estimatedTransactionDateStr = estimatedTransactionDate || '--' // 预计交易时间
          } else {
            // 除“付款”外，拼接下标，在模板中读取数据时需要将下标过滤掉
            row.tradingRecordStr = `${tradingRecordStr}${separator}${index}` // 交易记录
            row.typeStr = `${typeStr}${separator}${index}` // 类型
            row.amountPayableStr = `${amountPayableStr}${separator}${index}` // 预计交易金额
            row.estimatedTransactionDateStr = `${
              estimatedTransactionDate || '--'
            }${separator}${index}` // 预计交易时间
          }
          if (Number(paymentNode) > 4) {
            row.amountPayableStr = `${symbol} 0.00`
          }

          // 过滤掉hidden为1的数据
          return row.hidden !== 1
        })
        return newList
      },

      // 获取入库申请出库仓库
      getStorageList(bool) {
        OrderListInteractor.storageFindWarehouseList2({
          hasAuth: 0,
          hasCloudWarehouse: bool ? 0 : 1,
        }).then((res) => {
          this.storageList = res?.data || []
        })
      },

      //唛头格式转换
      getMark(item) {
        if (!item) {
          return []
        } else {
          return item.split('\n')
        }
      },
      calculateMoney(list) {
        let arr = []
        let refundArr = []
        list.forEach((item) => {
          if (item.type == 0) {
            arr.push(Number(item.amountActual))
          }
          if (item.type == 2) {
            refundArr.push(Number(item.amountActual))
          }
        })
        // 计算已付金额
        this.paidMoney = parseFloat(arr.reduce((p, v) => p + v)).toFixed(3)
        // 交易金额 = 订单总金额 - 已退金额
        this.transactionMoney = this.detail.amountTotal - 0
        // 计算退款金额总和
        this.refundMoney =
          refundArr.length > 0
            ? parseFloat(refundArr.reduce((p, v) => p + v)).toFixed(3)
            : ''
      },

      // 切换选项卡
      handleClick(index) {},

      //审核进度查看
      statusTypeClick(
        applicationPayOrderId,
        paymentOrderId,
        paymentOrderNumber,
        type
      ) {
        let auditType = null
        let orderId = null

        if (type === 0) {
          //付款
          auditType = 7
          orderId = applicationPayOrderId || paymentOrderId
        } else if (type === 4) {
          //费用
          auditType = 8
          orderId = paymentOrderId
        }

        InboundSheet.getAuditObject({
          orderId: orderId,
          auditType: auditType,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000' && res.data) {
            this.auditObjectId = res.data.auditObjectId
            this.statusDrawer = true
          }
        })
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },

      // 待收货列表选中事件
      collectSetSelectRows(rows) {
        this.collectSelectRows = rows
      },

      // 待入库列表选中事件
      enterSetSelectRows(rows) {
        this.enterSelectRows = rows
        // 判断采购批量入库按钮是否显示
        if (this.enterSelectRows.length) {
          this.putStorageVisible = this.enterSelectRows.every(
            (item) =>
              item.numQualified > 0 || item.state == 23 || item.state == 26
          )
        } else {
          this.putStorageVisible = true
        }
      },

      // 获取批量验货中的最大日期
      getMaxDate(data) {
        let arr = []
        data.forEach((item) => arr.push(item.actualDeliveryDate))
        return arr.sort(function (a, b) {
          return (
            new Date(b.replace(/-/g, '/')).getTime() -
            new Date(a.replace(/-/g, '/')).getTime()
          )
        })[0]
      },

      // 处理产品
      _handleProduct(pros, flag) {
        return pros.map((item) => {
          return {
            ...item,
            provideSamples: '',
            provideSamples: '0', // 是否提供样品默认否
            numInspection: flag ? item.numInspection : item.numActual,
            packingInformation: flag
              ? item.packingInformation
              : '装箱数量：' +
                item.numberPerBox +
                '; 装箱尺寸：' +
                item.boxLengthCm +
                '"x' +
                item.boxWidthCm +
                '"x' +
                item.boxHeightCm +
                '"' +
                '；装箱重量：' +
                item.boxWeightKgs, // 装箱信息带出
          }
        })
      },

      // 验货 or 批量验货
      async examiseCargo(type, row, isReshipment) {
        const collectCargo = this.$refs.collectCargo

        collectCargo.form.inspectionProductEditDTOList = []
        if (type == 1) {
          if (!this.collectSelectRows.length) {
            this.$message.warning('至少选择一条待收货数据！')
            return false
          } else {
            // productSize的值用来回显验货通知弹框中的验货要求字段
            collectCargo.form.inspectionProductEditDTOList =
              this.collectSelectRows.map((n) => ({
                ...n,
                inspectionRequirements: n.productSize,
              }))
          }
        } else {
          row.inspectionRequirements = row.productSize
          collectCargo.form.inspectionProductEditDTOList.push(row)
        }
        // 验货地址-供应商地址
        collectCargo.addressList[0].address = this.detail.supplierAddress
        // QC
        const { merchandiserName, merchandiserId } = this.detail

        // QC是否可编辑
        collectCargo.hasQCEditRight = this.hasQCEditRight
        // 判断是否备货
        collectCargo.isStock =
          this.detail.purchaseProductShowVOList[0].salesOrderNumber.includes(
            'PD'
          )
            ? true
            : false
        if (isReshipment) {
          // 不合格处理数据回显
          const { inspectionId, sku, orderProductUniqueId } = row
          let _arr = []
          var { data } = await OrderListInteractor.inspectionGetDetailForEdit({
            businessId: inspectionId,
            skus: sku,
            orderProductUniqueId: orderProductUniqueId,
          })
          // 存在orderProductUniqueId需要过滤数据
          if (orderProductUniqueId) {
            _arr = data.inspectionProductEditVOList.filter(
              (item) => item.orderProductUniqueId === orderProductUniqueId
            )
          } else {
            _arr = data.inspectionProductEditVOList
          }
          collectCargo.form.inspectionProductEditDTOList = _arr
        }

        // 处理数据
        collectCargo.form = {
          ...collectCargo.form,
          ...row,
          ...this.detail,
          ...data,
          purchaseOrderNumber: this.detail.number,
          isReshipment,
          inspectionProductEditDTOList: this._handleProduct(
            collectCargo.form.inspectionProductEditDTOList,
            isReshipment
          ),
          inspectionHandleStatus: 4,
        }
        if (type == 2) {
          collectCargo.form.expectDeliveryDate = row.actualDeliveryDate
        } else {
          collectCargo.form.expectDeliveryDate = this.getMaxDate(
            this.collectSelectRows
          )
        }
        if (isReshipment) {
          collectCargo.form.inspectorId = data.inspectorId
          collectCargo.form.inspector = data.inspector
        } else {
          if (this.detail.merchandiserName) {
            collectCargo.form.inspectorId = merchandiserId
            collectCargo.form.inspector = merchandiserName
          } else {
            collectCargo.form.inspectorId = ''
            collectCargo.form.inspector = ''
          }
        }

        collectCargo.collectCargoVisable = true
      },

      // 入库 or 批量入库
      putStorage(type, row) {
        const enterCargo = this.$refs.enterCargo
        enterCargo.form.applicationWarehouseInProductEditDTOList = []
        if (type == 1) {
          if (!this.enterSelectRows.length) {
            this.$message.warning('至少选择一条数据！')
            return false
          } else {
            const isEnter = this.enterSelectRows.every(
              (item) =>
                item.state == 21 || (item.state == 23) | (item.state == 26)
            )
            if (isEnter) {
              enterCargo.form.applicationWarehouseInProductEditDTOList =
                this.enterSelectRows
            } else {
              this.$message.warning('所选数据存在不可操作的状态！')
              return false
            }
          }
        } else {
          enterCargo.form.applicationWarehouseInProductEditDTOList.push(row)
        }
        enterCargo.form.applicationWarehouseInProductEditDTOList =
          enterCargo.form.applicationWarehouseInProductEditDTOList.map(
            (item) => {
              return {
                ...item,
                numWarehouseIn: item.numQualified,
                giftNum: '',
              }
            }
          )
        enterCargo.isCheck = false
        enterCargo.form.buyerId = this.detail?.buyerId
        enterCargo.form.buyerName = this.detail?.buyerName
        enterCargo.form.supplierName = this.detail?.supplierName
        enterCargo.form.orderNumber = this.detail?.number
        enterCargo.form.purchaseOrderId = this.detail?.purchaseOrderId
        enterCargo.enterCargoVisable = true
      },

      // 处理
      dispose(inspectionId, purchaseProductId, row) {
        this.$refs.dispose.form.inspectionId = inspectionId
        this.$refs.dispose.form.purchaseProductId = purchaseProductId
        this.$refs.dispose.form.purchaseOrderId = this.detail.purchaseOrderId
        this.$refs.dispose.product = [row]
        this.$refs.dispose.disposeVisable = true
      },

      handleReshipment(data) {
        this.$refs.dispose.orderDisposalUnqualified(data)
      },

      // 申请付款
      payment(entryType, disabled) {
        let arr = []
        let refundIds = []
        let row = this.detail
        arr.push(row.purchaseOrderId)
        if (row.abnormalWarehousingStatus == 2) {
          refundIds.push(row.number)
        }

        // 判断是否存在审核模板
        let auditParams = {
          auditType: 7,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        this.pubApi.getAuditTemplates(auditParams).then((response) => {
          if (!response.data.length) {
            this.$baseMessage(
              '请联系管理员配置审核模板',
              'warning',
              false,
              'erp-hey-message-warning'
            )
            return false
          } else {
            this.$refs.OrderPayment.refundIds = refundIds.join(',')
            this.$refs.OrderPayment.initLoading(arr.join(','), {
              entryType,
              disabled,
            })
          }
        })
      },

      // 发票登记
      offerRegister() {
        this.$refs.orderRegister.getOrderListInvoiceDetail(
          this.detail.purchaseOrderId
        )
      },

      //发票作废
      invoiceDeleteClick(item, i) {
        this.$confirm('确定将该发票作废吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          OrderListInteractor.invoiceDelete({
            purchaseInvoiceId: item.purchaseInvoiceId,
          }).then((res) => {
            if (res.data === 1) {
              this.$baseMessage(
                '作废成功',
                'success',
                false,
                'erp-hey-message-success'
              )
              // 作废清除对应的发票凭证
              this.invoiceList[i].fileAnnex = ''
            } else {
              this.$baseMessage(
                '作废失败',
                'error',
                false,
                'erp-hey-message-success'
              )
            }
            this.active = '3'
            this.getDetail(this.detail.purchaseOrderId)
          })
        })
      },

      // 发票登记成功
      orderReg() {
        this.active = '3'
        this.getDetail(this.detail.purchaseOrderId)
      },

      // 预览箱唛 地址中存在#会导致query参数被截取
      async printMarks(data) {
        // 查询配置 1 采购订单唛头配置
        let markConfig = await OrderSettingInteractor.findByUserApi(1)
        let { productImgDisplayed, qrCodeDisplayed, rendImgDisplayed } =
          markConfig
        // 获取图片
        if (productImgDisplayed) {
        }
        if (data) {
          // settingtype = 1 采购订单 preview = 1 采购订单预览
          window.open(
            window.location.origin +
              `#/print/orderShipMark?settingtype=1&content=${encode(
                data
              )}&businessId=${this.$route.query.id}&preview=1`,
            '_blank'
          )
        } else {
          this.$message.warning('箱唛内容为空不支持打印！')
        }
      },

      // 下载箱唛
      downloadMarks(marks, purchaseOrderId) {
        downloadMarksCommon(marks, purchaseOrderId)
      },
      //上传预判
      beforeUpload(file) {
        let suffix = file.name.substr(file.name.lastIndexOf('.'))
        const isFile = [
          '.jpg',
          '.png',
          '.pdf',
          '.cdr',
          '.ai',
          '.wmf',
          '.eps',
        ].includes(suffix)
        const isLt10M = file.size / 1024 / 1024 < 20
        if (!isFile) {
          this.$message.error('上传文件格式不正确!')
        }
        if (!isLt10M) {
          this.$message.error('上传文件大小不能超过 20MB!')
        }
        return isFile && isLt10M
      },
      // 上传签章合同
      uploadSignContract(data) {
        this.utils
          .ossUpload({ file: data.file, type: 'contractFile', limitSize: 20 })
          .then(({ res }) => {
            let url = res.requestUrls[0]
            let list = [
              {
                name: data.file.name,
                size: data.file.size,
                url: url,
                time: this.utils.timeFormat(new Date()),
              },
            ]
            if (this.signContractList.concat(list).length > 20) {
              this.$message.warning('最多上传20张')
              return false
            }
            this.signContractList = this.signContractList.concat(list)
            this.detail?.purchaseOrderId &&
              OrderListInteractor.addSealedContract &&
              OrderListInteractor.addSealedContract({
                businessId: this.detail.purchaseOrderId,
                sealedContract: JSON.stringify(this.signContractList),
              }).then((r) => {
                if (r?.code === '000000') {
                  this.$message.success('上传成功')
                }
              })
          })
      },

      // 查看合同
      printContract() {
        let type = '1'
        let path = ''
        if (['4', '5', '6'].includes(this.detail.modelName)) {
          path = this.isStock
            ? '/print/purchaseContract-BH'
            : '/print/purchaseContract'
          const obj = {
            4: '1',
            5: '2',
            6: '3',
          }
          type = obj[this.detail.modelName]
        } else {
          path = '/print/purchaseContract'
          type = this.detail.modelName
        }
        let routeData = this.$router.resolve({
          // path: this.isStock
          //   ? '/print/purchaseContract-BH'
          //   : '/print/purchaseContract',
          path,
          query: {
            id: this.$route.query.id,
            type,
          },
        })
        window.open(routeData.href, '_blank')
      },

      // 编辑
      edit() {
        // this.$router.push(
        //   `../../purchase/purchaseDemand/createPurchaseOrder?purchaseOrderId=${this.$route.query.id}&isStock=${this.isStock}&showSwitch=${this.showSwitch}`
        // )
        this.$router.push({
          path: '../../purchase/purchaseDemand/createPurchaseOrder',
          query: {
            purchaseOrderId: this.$route.query.id,
            isStock: this.isStock,
            showSwitch: this.showSwitch,
          },
        })
      },

      // 异常处理
      anomaly() {
        let arr = []
        let wArr = []
        this.cargoEnterList.forEach((item) => {
          if (item.state == 27) {
            arr.push(item)
            wArr.push(item.warehouseInNumber)
          }
        })
        this.$refs.enterCargoError.number = '< ' + this.number + ' >'
        this.$refs.enterCargoError.warehouseInNumber =
          '< ' + wArr.join(',') + ' >'
        // 赋值采购订单和入库单
        this.$refs.enterCargoError.initLoading(arr)
      },

      // 查看费用分摊情况
      costView(row) {
        const data = {
          transactionCode: row.paymentOrderNumber,
          typeMemo: row.paymentNodeName,
          amount: row.amountActual,
          currency: row.currency,
          totalMoney: this.detail.amountIncludingTax,
          amountActual: row.amountActual * row.exchangeRate,
          tableData: this.productListTable,
          entryType: row.entryType,
          purchaseProductShowVOS: row.purchaseProductShowVOS,
        }
        this.$refs.costApportion.initLoading(data)
      },

      // 处理退款
      disposeRefund(id) {
        this.$refs.disposeRefund.isDetail = true
        this.$refs.disposeRefund.initLoading(id)
      },

      // 跳转产品详情
      productView(productId, productCode) {
        let routeData = this.$router.resolve({
          path: '../../../../product/ProductView',
          query: {
            proId: productId,
            productCode: productCode.split('-')[0],
          },
        })

        window.open(routeData.href, '_blank')
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 删除已盖章合同
      delFile(i) {
        if (this.signContractList.length === 1) {
          this.$message.warning('至少保留一张')
          return false
        }
        this.$confirm(`是否确认删除?`, '提示', { type: 'warning' }).then(() => {
          this.signContractList.splice(i, 1)
          OrderListInteractor.addSealedContract({
            businessId: this.detail.purchaseOrderId,
            sealedContract: JSON.stringify(this.signContractList),
          }).then((r) => {
            if (r?.code === '000000') {
              this.$message.success('删除成功')
            }
          })
        })
      },

      // 查看付款单
      viewFK(item) {
        this.$refs.fkModel.amendRow = item.paymentOrderId
        this.$refs.fkModel.showReceiptDetail()
      },

      // 查看费用单
      viewFY(item) {
        this.$refs.fyModel.amendRow = item.paymentOrderNumber
        this.$refs.fyModel.showReceiptDetail()
      },
      // 上传发货凭证
      handleUploadShippingCertificate() {
        this.shippingCertificateVisible = true
      },
      // 获取发货凭证详情
      async getPurchaseOrderExtend(id) {
        const res = await OrderListInteractor.purchaseOrderExtendDetailApi(id)
        if (res?.code === '000000') {
          this.shippingCertificateData = res.data
          if (res.data.orderFiles?.length) {
            this.$nextTick(() => {
              this.$refs?.erpUploadRef?.initUpload &&
                this.$refs.erpUploadRef.initUpload(
                  JSON.stringify(res.data.orderFiles),
                  false
                )
            })
          }
        }
      },
      // 上传发货凭证成功-刷新页面
      handleRefresh() {
        this.getPurchaseOrderExtend(this.bizId)
        this.getDetail(this.bizId)
      },
    },
  }
</script>

<style lang="scss" scoped>
  // 解决付款/发票tab下，表格抖动
  .el-table {
    width: 99.9% !important;
  }
  .span-audit {
    text-decoration: underline;
    cursor: pointer;
  }

  .span-audit.pass {
    color: #008000;
  }

  .span-audit.process {
    color: #1890ff;
  }

  .span-audit.reject {
    color: #f56c6c;
  }

  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }

  .no-return {
    font-size: 18px;
    font-family: PingFang Bold;
    color: #303133;
  }

  .upload-btn {
    display: inline-block;
    margin-left: 10px;
  }

  .file_list {
    .file_line {
      width: 360px;
      height: 32px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      line-height: 32px;
      font-size: 12px;
      color: #606266;
      position: relative;
      display: flex;
      justify-content: flex-start;
      margin-top: 5px;

      span {
        margin-right: 15px;
      }

      .icon_file {
        font-size: 16px;
        color: #fa6400;
        position: absolute;
        left: 5px;
        top: 8px;
      }

      .file_name {
        padding-left: 25px;
        width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .size {
        width: 70px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon_del {
        font-size: 16px;
        position: absolute;
        right: 5px;
        top: 8px;
        cursor: pointer;
      }
    }
  }

  .split-style {
    position: relative;
    padding-right: 15px;

    em {
      position: absolute;
      top: 2px;
      right: 0;
      z-index: 999;
      cursor: pointer;
    }
  }

  .el-icon-warning {
    position: absolute;
  }

  .relative {
    position: relative;
    display: inline-block;
  }

  .info-wrapper {
    text-align: left;
    width: 200px;

    p {
      width: 100%;
      padding: 2px 5px;
      white-space: break-spaces;
    }
  }

  ::v-deep {
    .tool-tip {
      .el-icon-warning {
        position: unset !important;
      }
    }
    // 发货凭证
    .erp-upload-wrapper {
      display: flex;
      .upload_file {
        margin-top: -6px;
        .file_list {
          margin-top: 0;
          .file_line:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  // 采购订单详情页，产品列表-印刷文件样式优化
  // 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
  .flie-list {
    display: flex;

    .page-link {
      flex: 1;
      overflow: hidden;
      text-align: left;

      span.ell {
        max-width: none; // 去掉max-width: 300px;
        width: 100%;

        > a {
          width: 100%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>
