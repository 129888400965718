<template>
  <div class="other-setting-wrapper">
    <div class="top">
      <!-- 邮件同步数量设置 -->
      <mailReceivingSetting
        :mailDateList="mailDateList"
        :mailNumberList="mailNumberList"
        :baseForm="form"
        @changeMailSetting="changeMailSetting"
      ></mailReceivingSetting>
    </div>
    <el-divider></el-divider>
    <!-- 安排生产邮件默认抄送设置 -->
    <div class="plan-product">
      <planProduct
        @changePlanProductMsg="changePlanProductMsg"
        :baseForm="form"
        :accountList="accountList"
      ></planProduct>
    </div>
    <el-divider></el-divider>
    <!-- 安排发货邮件默认抄送设置 -->
    <div class="plan-ship">
      <planShip
        @changePlanShipMsg="changePlanShipMsg"
        :baseForm="form"
        :accountList="accountList"
      ></planShip>
    </div>
    <el-divider></el-divider>
    <!-- 开启分派模式 -->
    <div>
      <el-checkbox v-model="dispatchEnabled" class="dispatch-mode">
        {{ $t('mailConfig.DispatchMode') }}
      </el-checkbox>
    </div>
    <div class="configuration-list-box">
      <configurationList
        :mailListObj="mailListObj"
        ref="configurationList"
      ></configurationList>
    </div>
    <div class="bottom">
      <el-button type="primary" :loading="loading" @click="save">
        {{ $t('inquiryList.Save') }}
      </el-button>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { mailSettingSave, getMailAllAccountList } from '@/api/mail/setting.js'
  import planProduct from './componetns/planProduct.vue'
  import planShip from './componetns/planShip.vue'
  import mailReceivingSetting from './componetns/mailReceivingSetting.vue'
  import configurationList from './componetns/configurationList'
  import settingData from './util/setData'
  import { getSettingMsg } from './util/utils'

  export default {
    name: 'other-setting',
    components: {
      planProduct,
      planShip,
      mailReceivingSetting,
      configurationList,
    },
    data() {
      return {
        loading: false,
        form: {
          mailDate: 0,
          mailNumber: 0,
          optional: 0,
        },
        type: '',
        options: [],
        mailDateList: settingData.mailDateList,
        mailNumberList: settingData.mailNumberList,
        accountList: [],
        productForm: {},
        shipForm: {},
        setForm: {},
        dispatchEnabled: false, //分派模式
        mailListObj: {
          tableData: [],
        }, //邮箱配置列表
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    created() {
      this.getDetail()
      this.getAccountList()
    },
    methods: {
      async getDetail() {
        let obj = await getSettingMsg(this.userInfo.tenantId)
        if (!obj) {
          this.form.mailDate = 0
          this.form.mailNumber = 0
          this.form.optional = 0
          this.dispatchEnabled = false
          this.mailListObj.tableData = []
        } else {
          this.form = { ...obj }
          this.dispatchEnabled = obj.dispatchEnabled ? true : false
          this.mailListObj.tableData =
            obj?.mailAccountConfigVOS?.reduce((previousValue, currentValue) => {
              let disabled = currentValue.email ? true : false
              let email = currentValue.emailAccount
              let defaultEmail = currentValue.email
              let effect = []
              if (currentValue.forwarded) effect.push('forwarded')
              if (currentValue.replied) effect.push('replied')
              if (currentValue.sended) effect.push('sended')
              previousValue.push({
                disabled,
                email,
                defaultEmail,
                effect,
              })
              return previousValue
            }, []) ?? []
        }
      },
      getAccountList() {
        getMailAllAccountList({ pageSize: 999 }).then((res) => {
          if (res?.code === '000000') {
            this.accountList = res.data?.records
          }
        })
      },
      // 保存按钮点击
      save() {
        let dt = {
          ...this.setForm,
          ...this.productForm,
          ...this.shipForm,
        }
        delete dt.emailAccount
        if (
          (!this.form.mailDate && this.form.mailDate != 0) ||
          (!this.form.mailNumber && this.form.mailNumber != 0)
        ) {
          // 请选择
          this.$message.warning(this.$t('placeholder.plsSel'))
          return
        }
        this.$refs.configurationList.submitForm().then((obj) => {
          if (!obj.bol) return
          let data = obj.data.reduce((previousValue, currentValue) => {
            let emailAccount = currentValue.email
            let email = currentValue.defaultEmail
            let forwarded = currentValue.effect.includes('forwarded') ? 1 : 0
            let replied = currentValue.effect.includes('replied') ? 1 : 0
            let sended = currentValue.effect.includes('sended') ? 1 : 0
            previousValue.push({
              email,
              emailAccount,
              forwarded,
              replied,
              sended,
            })
            return previousValue
          }, [])
          this.loading = true
          mailSettingSave({
            ...dt,
            dispatchEnabled: this.dispatchEnabled ? 1 : 0,
            mailAccountConfigDTOS: data,
            tenantId: this.userInfo.tenantId,
          }).then((res) => {
            this.loading = false
            if (res && res.code === '000000') {
              // 保存成功
              this.$message.success(this.$t('reqmsg.$9'))
              this.resetFrom()
              this.getDetail()
            } else {
              this.$message.error(res.desc)
            }
          })
        })
      },
      changePlanProductMsg(val) {
        this.productForm = { ...val }
      },
      changePlanShipMsg(val) {
        this.shipForm = { ...val }
      },
      changeMailSetting(val) {
        this.setForm = { ...val }
      },
      resetFrom() {
        this.productForm = {}
        this.shipForm = {}
        this.setForm = {}
      },
    },
  }
</script>
<style lang="scss" scoped>
  .other-setting-wrapper {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 52px;
    .top {
      flex: 1;
    }
    .bottom {
      width: 100%;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      background: #fff;
      padding: 10px;
    }
    .configuration-list-box {
      margin-top: 20px;
    }
    ::v-deep .dispatch-mode {
      .el-checkbox__label {
        font-size: 15px;
        color: #000;
      }
    }
  }
</style>
