<template>
  <!-- 绑定邮箱 -->
  <el-dialog :title="$t('mailConfig.BindEmail')" :visible.sync="dialogFormVisible" width="450px" @close="close"
    v-if="dialogFormVisible" :close-on-click-modal="false">
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="24">
          <el-col :span="24">
            <!-- 邮件类型 -->
            <el-form-item :label="$t('mailConfig.MailType')" prop="mailServer" :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]">
              <el-select v-model="form.mailServer" :disabled="isEdit">
                <el-option v-for="(item, index) in mailServerList" :key="index"
                  :label="settings === 'en' ? item.nameEn : item.name" :value="item.name">
                  {{ settings === 'en' ? item.nameEn : item.name }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 邮箱归属人 -->
            <el-form-item :label="$t('mailConfig.EmailOwner')" prop="businessName" :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]">
              <el-input v-if="settings === 'en'" :placeholder="$t('placeholder.plsSel')" v-model="form.businessNameEn"
                @focus="businessChange"></el-input>
              <el-input v-else :placeholder="$t('placeholder.plsSel')" v-model="form.businessName"
                @focus="businessChange"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 出口抬头 -->
            <el-form-item :label="$t('mailConfig.ExportsRise')" prop="exportHead" :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]">
              <el-select v-model="form.exportHead" value-key="amountShort" :disabled="isEdit && isGmail">
                <el-option v-for="item in exportsRiseList" :key="item.amountId" :label="item.amountShort"
                  :value="item.amountShort"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" v-if="!isGmail || isEdit">
            <!-- 邮箱地址 -->
            <el-form-item :label="$t('mailConfig.EmailAddress')" prop="emailAccount" :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsInput'),
                },
              ]">
              <el-input :disabled="form.mailAccountId !== undefined" v-model.trim="form.emailAccount"
                :placeholder="`${$t('placeholder.M2022')}：yourname@email.com`" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="!isGmail">
            <!-- 密码 -->
            <el-form-item :label="$t('mailConfig.Password')" prop="authCode" :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsInput'),
                },
              ]">
              <el-input v-model.trim="form.authCode" :placeholder="$t('placeholder.plsInput')" type="password" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="isGmail && !isEdit" class="text-center">
            <img :src="chromeLogo" class="pointer" @click="bindGmail" />
            <div>{{ $t('mailConfig.chrome') }}</div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template v-slot:footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save" v-if="!isGmail || isEdit">
          <!-- 确 认 -->
          {{ $t('mailConfig.Confirm') }}
        </el-button>
        <el-button @click="close">
          <!-- 取 消 -->
          {{ $t('mailConfig.Cancel') }}
        </el-button>
      </el-row>
    </template>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" :single-select="true" />
  </el-dialog>
</template>

<script>
  import { orderGetDict } from '@/api/order'
  import { setMailAccountList, listServerName } from '@/api/mail/setting.js'
  import UserChoose from '@/components/userChoose2'
  import { mapGetters } from 'vuex'
  import { MailSettingInteractor } from '@/core'
  import chromeLogo from '@/assets/chrome.png'
  export default {
    name: 'ClientManageAddModel',
    components: {
      UserChoose,
    },
    data() {
      return {
        chromeLogo,
        loading: false,
        mailServerList: [],
        form: {
          emailAccount: '',
          authCode: '',
          businessName: '',
          businessNameEn: '',
          businessId: '',
          mailServer: '',
          exportHead: '',
        },
        exportsRiseList: [], //出口抬头
        rules: {},
        dialogFormVisible: false,
        value: '',
        businessArrId: [],
        businessArrName: [],
        businessArrNameEn: [],
        isEdit: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
      isGmail() {
        return this.form.mailServer === 'Gmail'
      },
    },
    created() {
      listServerName().then((res) => {
        if (res?.code === '000000') {
          this.mailServerList =
            res.data?.map((item) => {
              return {
                name: item.name,
                nameEn: item.nameEn,
                value: item.name,
              }
            }) || []
        }
      })
      this.getDict()
    },
    methods: {
      // 获取出口抬头，询盘来源字典项
      getDict() {
        orderGetDict({ amountType: 1 }).then((res) => {
          res.data.forEach((item) => {
            if (item.amountStatus == 1) {
              this.exportsRiseList.push({ ...item })
            }
          })
        })
      },
      // 获取客户代表
      chooseUser(userIdArr) {
        let that = this
        that.businessArrId = []
        that.businessArrName = []
        that.businessArrNameEn = []
        that.form.businessId = ''
        that.form.businessName = ''
        that.form.businessNameEn = ''
        userIdArr.forEach((item) => {
          that.businessArrId.push(item.id)
          that.businessArrName.push(item.name)
          that.businessArrNameEn.push(item.englishName)
        })
        that.form.businessId = that.businessArrId.join(',')
        that.form.businessName = that.businessArrName.join(',')
        that.form.businessNameEn = that.businessArrNameEn.join(',')
      },

      // 选择客户代表
      businessChange() {
        this.$refs['UserChoose'].showAddEdit('1', this.businessArrId, true)
      },
      //新增、编辑
      showAddEdit(row) {
        if (row) {
          this.businessArrId = row.businessId ? row.businessId.split(',') : []
          this.businessArrName = row.businessName
            ? row.businessName.split(',')
            : []
          this.businessArrNameEn = row.businessNameEn
            ? row.businessNameEn.split(',')
            : []
          this.form = Object.assign({}, row)
          this.isEdit = true
        } else {
          this.isEdit = false
          this.initUser()
        }

        this.dialogFormVisible = true
      },

      initUser() {
        const { englishName, userName, userId } = this.userInfo
        this.form = {
          ...this.form,
          businessName: userName,
          businessNameEn: englishName,
          businessId: userId,
        }
        this.businessArrId = [userId]
      },
      bindGmailShow(form) {
        this.form = form
        this.dialogFormVisible = true
        MailSettingInteractor.Api({
          ...this.form,
          code: this.$route.query.code,
        }).then((res) => {
          if (res?.code === '000000') {
            this.$message.success(this.$t('reqmsg.$9'))
            this.$emit('fetch-data')
            this.removeItem()
            this.close()
          }
        })
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.businessArrId = []
        this.businessArrName = []
        this.businessArrNameEn = []

        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let data = { ...this.form, tenantId: this.userInfo.tenantId }
            // data.businessId = this.userInfo.userId
            if (data.mailAccountId) {
              data.reBindMail = 1
            }
            this.loading = true
            setMailAccountList(data).then((res) => {
              this.loading = false
              if (res?.code === '000000') {
                // 设置成功
                this.$message.success(this.$t('reqmsg.$9'))
                this.$emit('fetch-data')
                this.close()
              }
            })
          }
        })
      },
      bindGmail() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            MailSettingInteractor.gmailAuthorizationUrlApi(
              this.form.exportHead
            ).then((res) => {
              if (res?.code === '000000') {
                this.saveForm()
                window.open(res?.data, '_self')
                this.close()
              }
            })
          }
        })
      },

      //  存储表单数据
      saveForm() {
        localStorage.setItem('BIND_GMAIL_FORM', JSON.stringify(this.form))
      },
      removeItem() {
        localStorage.removeItem('BIND_GMAIL_FORM')
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    // height: 40vh;
    overflow: hidden;
    overflow-y: auto;

    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }

  .pointer {
    height: 40px;
    width: 40px;
  }
</style>
