import { mapGetters } from 'vuex'
import { SPECIAL_NODES } from '@/api/order'
const trackNodesMixin = {
  computed: {
    ...mapGetters({
      lang: 'settings/language',
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    _msgOfDate(item, baseKey) {
      const isEn = this.lang === 'en',
        { nodeNameEn, nodeNameCn } = item
      this.$message.warning(
        `${isEn ? 'Node  ' : '节点  '}${
          isEn ? nodeNameEn : nodeNameCn
        }  ${this.$t(`orderList.${baseKey}`)}${
          isEn ? '  is necessary' : '不能为空'
        }`
      )
    },

    _getDeferredDays(item) {
      let { realDate, dueDate } = item
      realDate = new Date(realDate).setHours(0)
      realDate = new Date(realDate).setMinutes(0)
      realDate = new Date(realDate).setSeconds(0)
      dueDate = new Date(dueDate).setHours(0)
      dueDate = new Date(dueDate).setMinutes(0)
      dueDate = new Date(dueDate).setSeconds(0)
      let df = (_(realDate) - _(dueDate)) / 1000 / 60 / 60 / 24
      if (df <= 0) df = ''
      return df
    },

    _dateCompare(item) {
      const { realDate, dueDate } = item
      if (realDate && dueDate) {
        if (_(realDate) < _(dueDate)) {
          return true
        }
      }
      return false
    },

    onDateChange(val, item, calc) {
      if (!val || this._dateCompare(item)) {
        // if (item.status != 3 && item.status != 2) {
        //不是延期状态
        //清空延期时间并且清除勾选
        item.deferredDays = undefined
        item.deferred = 0
        // }
      }
      const { realDate, dueDate } = item
      // if (calc !== false) {
      // const { realDate, dueDate } = item
      if (!realDate || !dueDate) return
      // if (this.deferred == 1) {

      item.deferredDays = this._getDeferredDays(item)
      // }
      // }
    },

    checkRealDate(list) {
      // 校验前过滤三个特殊节点
      list = list || this.detail.orderTrackingNodeVOList
      list = list
        .slice()
        .reverse()
        .filter((i) => !SPECIAL_NODES.includes(i.nodeType))

      for (let i = 0; i < list.length; i++) {
        if (i === 0 && !list[i].dueDate) {
          return this._msgOfDate(list[i], 'EstimatedDate')
        }

        const { status } = list[i]
        // 已完成状态实际日期不能为空
        if ((status == 2 || status == 3) && !list[i].realDate) {
          this._msgOfDate(list[i], 'ActualDate')
          return false
        }

        // 已延期状态实际日期不能为空
        if ((status == 2 || status == 3) && !list[i].dueDate) {
          this._msgOfDate(list[i], 'EstimatedDate')
          return false
        }

        const { realDate, dueDate } = list[i],
          curRD = realDate,
          curDD = dueDate

        // 预计日期 填写后、实际日期可以不填写
        // 反过来  实际日期填写、预计日期一定要填写
        if (curRD && !curDD) {
          this._msgOfDate(list[i], 'EstimatedDate')
        }

        if (i > 0) {
          // 根据下一个节点，判断上一个节点的实际日期,预计日期能否为空
          const { realDate: prevRD, dueDate: prevDD } = list[i - 1]
          if (curRD && !prevDD) {
            // 验预计日期
            this._msgOfDate(list[i - 1], 'EstimatedDate')
            return false
          }
          if (curRD && !prevRD) {
            // 实际日期校验
            this._msgOfDate(list[i - 1], 'ActualDate')
            return false
          }
        }
      }
      return true
    },
  },
}

export default trackNodesMixin
