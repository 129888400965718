// 数据转换：以时间段为维度转换为以星期为维度----提交用
export function getParams(timeList) {
  const params = []
  const weekDayList = [1, 2, 3, 4, 5, 6, 7]
  weekDayList.forEach((weekDay) => {
    const scheduleOrderVOList = []
    timeList.forEach((item, index) => {
      const userInfos = item[`userInfos${weekDay}`]

      console.log(userInfos, 'userInfos')
      scheduleOrderVOList.push({
        scheduleOrder: index + 1,
        startTime: item.timeData[0],
        endTime: item.timeData[1],
        assignTimeConfig: item.assignTimeConfig,
        userInfos,
      })
    })
    params.push({ weekDay, scheduleOrderVOList })
  })
  return params
}

// 数据转换：以星期为维度转换为以时间段为维度---回显用
export function getTableData(data) {
  const tableData = []
  // 遍历每一天的数据
  data.forEach(({ weekDay, scheduleOrderVOList }) => {
    scheduleOrderVOList.forEach(
      ({ startTime, endTime, assignTimeConfig, userInfos }) => {
        // 提取用户信息
        const userNames = userInfos.map((user) => user.userName)
        const userIds = userInfos.map((user) => user.userId)
        // 查找是否已经存在的时间段
        const existingTimeEntry = tableData.find(
          ({ timeData }) => timeData[0] === startTime && timeData[1] === endTime
        )
        if (existingTimeEntry) {
          // 如果已经存在，更新相应的用户名单和用户 ID 列表
          existingTimeEntry[`userNameList${weekDay}`].push(...userNames)
          existingTimeEntry[`userIdList${weekDay}`].push(...userIds)
          existingTimeEntry[`userInfos${weekDay}`].push(...userInfos)

        } else {
          // 如果不存在，创建新的时间段记录
          const timeLine = {
            timeData: [startTime, endTime],
            assignTimeConfig: assignTimeConfig,
          }
          // 动态创建 userNameList 和 userIdList
          for (let i = 1; i <= 7; i++) {
            timeLine[`userNameList${i}`] = i === weekDay ? [...userNames] : []
            timeLine[`userIdList${i}`] = i === weekDay ? [...userIds] : []
            timeLine[`userInfos${i}`] = i === weekDay ? [...userInfos] : []
          }
          tableData.push(timeLine)
        }
      }
    )
  })
  console.log('tableData', tableData)
  return tableData
}

// 传入时段和分配时间
export function subtractOneHour(timeData, assignTimeConfig = 0) {
  const [startTime, endTime] = timeData
  const time = subtractHours(endTime, assignTimeConfig)
  const startTimeDate = new Date(`2023-01-01T${startTime}:00`)
  const timeDate = new Date(`2023-01-01T${time}:00`)
  // 结束时间 - 分配时间 > 开始时间，则返回结束时间 - 分配时间；否则返回开始时间
  return timeDate > startTimeDate ? time : startTime
}

// 传入结束时间和小时数，得到减去小时数后的时间
function subtractHours(endTime, mHours) {
  // 将时间字符串转换为小时和分钟
  let [hours, minutes] = endTime.split(':').map(Number)
  // 计算要减去的小时和分钟
  const totalMinutesToSubtract = Math.round(mHours * 60)
  const hoursToSubtractInt = Math.floor(totalMinutesToSubtract / 60)
  const minutesToSubtractInt = totalMinutesToSubtract % 60
  // 从原时间中减去相应的小时和分钟
  hours -= hoursToSubtractInt
  minutes -= minutesToSubtractInt
  // 处理分钟小于0的情况
  if (minutes < 0) {
    minutes += 60
    hours -= 1
  }
  // 处理小时小于0的情况
  if (hours < 0) {
    hours += 24 // 如果小时小于0，调整为24小时制
  }
  // 格式化时间为两位数
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}`
}
