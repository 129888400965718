<template>
  <div class="write-page">
    <!-- 移除全部 -->
    <erp-remove-all style="position: absolute; left: -2000px; top: -2000px" />
    <div class="tool-bar">
      <div class="left-tools">
        <!-- 保存 ns nosave缩写 有值时不显示发送等按钮，只显示保存按钮-->
        <el-button type="primary" v-if="$route.query.ns"
          :disabled="!isEnclosureListHandled || !contentAttachmentsHandled" @click="localSave">
          保存
        </el-button>
        <!-- 发送 -->
        <el-button type=" primary" size="small" @click="sendAction" v-if="!$route.query.ns"
          :disabled="!isEnclosureListHandled || !contentAttachmentsHandled">
          {{ $t('myMail.Send') }}
        </el-button>
        <!-- 定时发送 -->
         <!-- 20241023 定时发送功能暂时关闭 https://devops.aliyun.com/projex/req/EVTX-1718# 《隐藏邮件【定时发送】功能》 -->
        <!-- <el-button type="primary" size="small" plain :disabled="!isEnclosureListHandled || !contentAttachmentsHandled"
          @click="setSendTime" v-if="!$route.query.ns" :loading="timingloading">
          {{ $t('myMail.TimingSend') }}
        </el-button> -->
        <!-- 存草稿 -->
        <el-button type="primary" size="small" plain @click="saveDraft(null, false, true)" v-if="!$route.query.ns"
          :disabled="!isEnclosureListHandled || !contentAttachmentsHandled" :loading="draftLoading">
          {{ $t('myMail.SaveDraft') }}
        </el-button>

        <div class="btn-upload" v-if="!$route.query.ns" @click="handleUpload">
          <i class="iconfont icon-biezhen"></i>
          <!-- 添加附件 -->
          <span>{{ $t('myMail.AddAttachment') }}</span>
        </div>

        <el-dropdown @command="changeSign" placement="bottom-start" v-if="!$route.query.ns">
          <span class="btn-sign">
            <i class="iconfont icon-sign"></i>
            <span class="sign-name">{{ signName }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item v-for="item in signList" :key="item.mailSignatureId" :command="item">
              <div class="sign-item">
                {{ item.title }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="null" v-if="signList.length">
              {{ lang === 'en' ? 'nonuse' : '不使用' }}
            </el-dropdown-item>
            <!-- 暂无签名 -->
            <div class="sign-item empty" v-if="signList.length == 0">
              {{ $t('myMail.NoSignature') }}
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="changeTemplate" placement="bottom-start" v-if="!$route.query.ns">
          <span class="btn-sign">
            <i class="iconfont icon-fankui"></i>
            <span class="sign-name">{{ templateName }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item v-for="item in templateList" :key="item.mailTemplateId" :command="item">
              <div class="sign-item">
                {{ item.templateName }}
              </div>
            </el-dropdown-item>

            <div class="sign-item empty" v-if="templateList.length == 0">
              <!-- 暂无模板 -->
              {{ $t('myMail.NoTemplate') }}
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 选择效果图 -->
        <el-button type="text" class="fs-14" @click="showRendingsDialog">{{$t('myMail.SelectRenderings')}}</el-button>
      </div>
      <div class="right-tools">
        <el-button v-if="ccShowFlag" type="text" @click="changeCc">
          <!-- 取消抄送 : 抄送 -->
          {{ ccVisible ? $t('myMail.CancelCc') : $t('myMail.Cc') }}
        </el-button>
        <span v-if="ccShowFlag">|</span>
        <el-button v-if="ccShowFlag" type="text" @click="changeBcc">
          <!-- 取消密送 ：密送  -->
          {{ bccVisible ? $t('myMail.CancelBcc') : $t('myMail.Bcc') }}
        </el-button>
        <span v-if="ccShowFlag">|</span>
        <el-button type="text" @click="handleForward">
          <!-- 取消群发单显 : 群发单显 -->
          {{
          sendForm.singleDisplay === 1
          ? $t('myMail.CancelBulkSingleDisplay')
          : $t('myMail.BulkSingleDisplay')
          }}
        </el-button>
        <div class="select-box">
          <!-- 发件人 -->
          <span class="label">{{ $t('myMail.Sender') }}</span>

          <el-select v-model="sendForm.emailAccount" @change="changeCurrent" :disabled="
              !!$route.query.ns ||
              $route.query.type == 1 ||
              $route.query.type == 2
            ">
            <el-option v-for="item in accountList" :label="item.emailAccount" :value="item.emailAccount"
              :key="item.emailAccount"></el-option>
          </el-select>
        </div>
        <ErpFullScreen />
      </div>
    </div>
    <div class="mail-base-item">
      <!-- 收件人 -->
      <div class="dt">{{ $t('myMail.Recipient') }}</div>
      <div class="dd">
        <MailUser ref="sendRef" :list="sendForm.receiveList" :email-account="sendForm.emailAccount"
          @inhere="inputStatus = 1" />
      </div>
      <el-popover placement="bottom" width="300" trigger="click" :tabindex="1" @show="showpopover(1)">
        <div class="contact-popover">
          <div class="search-area">
            <!-- 请输入联系人 -->
            <el-input v-model="contactName" :placeholder="$t('rules.M2022')" size="small" clearable class="search-input"
              @clear="searchContact" @keydown.native.enter="searchContact"></el-input>
            <el-button class="btn-search" type="primary" plain @click="searchContact">
              <!-- 搜索 -->
              {{ $t('myMail.Search') }}
            </el-button>
          </div>
          <!-- <div class="contacts-groups">
            <LatestContacts ref="LatestContacts" :checkedData="sendForm.receiveList" />
          </div> -->
        </div>
        <!-- <el-button type="primary" size="mini" circle icon="el-icon-plus" slot="reference"></el-button> -->
      </el-popover>
    </div>
    <div class="mail-base-item" v-if="ccVisible && ccShowFlag">
      <div class="dt">
        <!-- 抄送 -->
        {{ $t('myMail.Cc') }}
      </div>
      <div class="dd">
        <MailUser ref="ccRef" :list="sendForm.ccEmailList" :email-account="sendForm.emailAccount"
          @inhere="inputStatus = 2" />
      </div>
      <el-popover placement="bottom" width="300" trigger="click" :tabindex="2" @show="showpopover(2)">
        <div class="contact-popover">
          <div class="search-area">
            <!-- 请输入联系人 -->
            <el-input v-model="contactName" :placeholder="$t('rules.M2022')" size="small" clearable class="search-input"
              @clear="searchContact" @keydown.native.enter="searchContact"></el-input>
            <el-button class="btn-search" type="primary" plain @click="searchContact">
              <!-- 搜索 -->
              {{ $t('myMail.Search') }}
            </el-button>
          </div>
          <!-- <div class="contacts-groups">
            <LatestContacts ref="LatestContacts" :checkedData="sendForm.ccEmailList" />
          </div> -->
        </div>
        <!-- <el-button type="primary" size="mini" circle icon="el-icon-plus" slot="reference"></el-button> -->
      </el-popover>
    </div>
    <div class="mail-base-item" v-if="bccVisible && ccShowFlag">
      <div class="dt">
        <!-- 密送 -->
        {{ $t('myMail.Bcc') }}
      </div>
      <div class="dd">
        <MailUser ref="bccRef" :list="sendForm.bccEmailList" :email-account="sendForm.emailAccount"
          @inhere="inputStatus = 3" />
      </div>
      <el-popover placement="bottom" width="300" trigger="click" :tabindex="3" @show="showpopover(3)">
        <div class="contact-popover">
          <div class="search-area">
            <!-- 请输入联系人 -->
            <el-input v-model="contactName" :placeholder="$t('rules.M2022')" size="small" clearable class="search-input"
              @clear="searchContact" @keydown.native.enter="searchContact"></el-input>
            <el-button class="btn-search" type="primary" plain @click="searchContact">
              <!-- 搜索 -->
              {{ $t('myMail.Search') }}
            </el-button>
          </div>
          <!-- <div class="contacts-groups">
            <LatestContacts ref="LatestContacts" :checkedData="sendForm.bccEmailList" />
          </div> -->
        </div>
        <!-- <el-button type="primary" size="mini" circle icon="el-icon-plus" slot="reference"></el-button> -->
      </el-popover>
    </div>
    <div class="mail-base-item">
      <div class="dt">
        <!-- 主题 -->
        {{ $t('myMail.Topic') }}
      </div>
      <div class="dd">
        <el-input v-model="sendForm.title"></el-input>
      </div>
    </div>
    <div class="attach-files">
      <MailFiles ref="mailFiles" :show-title="false" :show-close="true"></MailFiles>
    </div>
    <div class="ueditor-write">
      <Ueditor id="ueditorMail" ref="ueditor" @fileIn="uploadFiles" :self-init="false" @ueditor-focus="onEditorFocus" />
    </div>

    <SendTimeModel ref="SendTimeModel" @confirm="sendTimingAction" />
    <!-- 附件上传弹窗 -->
    <el-dialog :title="$t('myMail.Pleaseuploadattachments')" :visible.sync="uploadVisible" width="450px"
      :close-on-click-modal="false">
      <div class="dialog-box">
        <p>
          <span class="mr10">{{ $t('myMail.Pleasedottedline') }}</span>
        </p>
        <el-upload ref="elUpload" class="upload-demo mt10" drag action="" multiple :file-list="fileList"
          :on-remove="onRemove" :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t('myMail.plsD') }}
            <em>{{ $t('myMail.ctu') }}</em>
            <input ref="AddFile" type="file" @change="changeFile" multiple id="mail-upload-input" @click.stop />
          </div>
        </el-upload>
        <div class="mt10">
          <p>{{ $t('myMail.notice') }}</p>
          <p>1、{{ $t('myMail.isLow20') }}</p>
          <p>2、{{ $t('myMail.total25') }}</p>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div>
          <el-button @click.stop="uploadVisible = false">
            {{ $t('myMail.cancel') }}
          </el-button>
          <el-button type="primary" @click.stop="uploadFiles">
            {{ $t('myMail.confirmUpload') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 选择效果图弹窗 -->
    <ChooseRendings ref="chooseRendings" :uploaded-sizes="uploadedSizes" @upload-rendings="uploadRendings">
    </ChooseRendings>
  </div>
</template>

<script>
  import {
    mailSignList,
    getMailOtherConfig,
    mailTemplateList,
  } from '@/api/mail/mail-setting'
  import { uploadFile } from '@/api/mail/mail.js'
  import Ueditor from '@/components/Ueditor/Ueditor.vue'
  import { orderEnquiryDetail } from '@/api/order'
  import MailUser from '@/views/mail/my-mail/components/MailUser.vue'
  import LatestContacts from '@/views/mail/my-mail/components/LatestContacts.vue'
  import SendTimeModel from '@/views/mail/my-mail/components/SendTimeModel.vue'
  import MailFiles from '@/components/MailFiles'
  import ChooseRendings from '@/views/mail/my-mail/components/ChooseRendings'
  import { MailInteractor, systemInteractor } from '@/core'
  import {
    getEnclosureList,
    getUnprocessedContentAttachments,
    processFile,
    replaceSrcWithLoadingSrc,
  } from './utils/file'
  import { getUnhandledContentFiles } from './utils/tool'
  import { bindDropEvent, getRemainingHeight } from 'kits'

  import {
    isShipment,
    isSign,
    getSendGoodMailContent,
    getSignMailInfo,
    localSaveShipMailContent,
  } from '@/views/order/shipmentManage/helper/send-mail'
  import { listContact } from '@/api/mail/contacts.js'
  import ErpFullScreen from '@/erp/components/ErpFullScreen'
  import { BusOn, BusEmit } from '@/utils/eventBus'
  import { mapState,mapGetters } from 'vuex'
  import { getBoxFullName } from './utils/mail-data'
  import cheerio from 'cheerio'
  import {
    sendMail,
    addDraft,
    getMailDetailById,
    updateDraft,
  } from '@/api/mail/mail.js'
import { re } from 'mathjs'

  function hasChineseCharacters(htmlString, isContent) {
    const regex = /[\u4e00-\u9fa5]/;
    if(!isContent) {
      return regex.test(htmlString);
    }



     // Parse the HTML string into a DOM element
     const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const root = doc.body || doc.documentElement;






    const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);
    let textNode;
    while ((textNode = walker.nextNode())) {

      if (regex.test(textNode.textContent)) {
          return true;
      }

    }

    return false;
  }

  function removeElementsAndKeywords(htmlString) {



    // Parse the HTML string into a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const root = doc.body || doc.documentElement;

    const quoteContents = root.querySelectorAll('.erp-mail-quote')

    quoteContents.forEach(quote => quote.parentNode.removeChild(quote));

    const sections = root.querySelectorAll('section');
    sections.forEach(section => section.parentNode.removeChild(section));

    // const elementsWithAppendClass = root.querySelectorAll('[class*="append"]');
    // elementsWithAppendClass.forEach(element => element.parentNode.removeChild(element));

    // const serializer = new XMLSerializer();

    const keywords = ['attach', 'attached', 'attachment'];
    const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);
    let textNode;
    while ((textNode = walker.nextNode())) {
        for (const keyword of keywords) {
            if (textNode.textContent?.toLowerCase()?.includes(keyword)) {
                return true;
            }
        }
    }

    return false;
}

  const maxFileTotalSize = 25

  export default {
    name: 'WriteMail',
    components: {
      Ueditor,
      MailUser,
      LatestContacts,
      SendTimeModel,
      ErpFullScreen,
      MailFiles,
      ChooseRendings
    },
    mixins: [BusOn],
    data() {
      const editHeight = () => {
        const H = document.body.clientHeight
        return H - 428
      }
      return {
        ccShowFlag: true, // 是否群发单显
        ccVisible: false, //是否抄送
        bccVisible: false, //是否密送
        allVisible: true,
        inputStatus: 1, //当前是在输入那个收件人
        sendStatus: false, //发送状态
        successConfig: {
          // 提示config
          type: 'immediate',
          isDraft: false,
        },
        signName: '',
        signList: [], //签名列表
        templateName: '',
        templateList: [], // 模板列表
        sendForm: {
          singleDisplay: 0, // 1群发单显 0取消
          bccEmailList: [], // 密送人
          ccEmailList: [], // 抄送人
          content: '', // 邮件内容
          emailAccount: '', // 发件人
          enclosureList: [],
          receiveList: [], // 收件人
          title: '', // 主题
        },
        draftMailId: '',
        fileSize: 20,
        contactName: '', // 搜索联系人名称
        currentRow: 1, //1:收件人，2：抄送，3：密送
        autoSaveInterval: 0, //定时保存
        delaySendTime: 0, //延时发送时间
        timer: null, //定时器
        signMemory: null, //签名记忆
        boxFullName: '', //保存草稿箱名称
        lang: 'zh',
        mailSended: false, //邮件已经点击发送,不允许再次点击
        uploadVisible: false,
        fileList: [], //文件列表
        fileType: [
          'com',
          'msi',
          'nsh',
          'iso',
          'lib',
          'cab',
          'vbs',
          'wsc',
          'isp',
          'js',
          'msp',
          'wsf',
          'pif',
          'sys',
          'ex_',
          'wsh',
          'mst',
          'ps1',
          'exe',
          'bat',
          'hta',
          'appx',
          'jar',
          'apk',
          'cpl',
          'ade',
          'scr',
          'vxd',
          'dll',
          'sct',
          'msix',
          'lnk',
          'chm',
          'appxbundle',
          'adp',
          'shb',
          'vb',
          'ins',
          'mde',
          'ex',
          'vbe',
          'cmd',
          'msc',
          'jse',
          'msixbundle',
          'dmg',
        ],
        contactList: [], //最近联系人
        privilege: {}, // 权限
        type: '0', // 邮件类型 默认写邮件
        draftLoading: false, // 保存草稿loading
        timingloading: false, // 定时发送loading
        unhandledContentFileCounts: 0, //正文中未处理的附件
      }
    },
    computed: {
      ...mapState({
        currentAccount: (state) => state.mail.currentAccount,
        currentAccountServer: (state) => state.mail.currentAccountServer,
        accountList: (state) => state.mail.accountList,
      }),
      ...mapGetters({
        language: 'settings/language',
      }),
      userInfo() {
        let user = this.$store.state.user.userInfo
        if (user && user.userId) {
          return user
        } else {
          let user2 = localStorage.getItem('setUserInfo')
            ? JSON.parse(localStorage.getItem('setUserInfo'))
            : {}
          return user2
        }
      },
      // 当前选中邮箱
      currentMailServe() {
        let emailAccount = this.sendForm.emailAccount || this.currentAccount
        let obj = this.accountList.find((item) => {
          return item.emailAccount == emailAccount
        })
        return obj?.mailServer
      },
      isEnclosureListHandled() {
        if (
          !this.sendForm?.enclosureList ||
          this.sendForm.enclosureList.length === 0
        ) {
          return true
        }
        if (
          Array.isArray(this.sendForm?.enclosureList) &&
          this.sendForm?.enclosureList.some((file) => !file.enclosureUrl)
        ) {
          return false
        }
        return true
      },

      contentAttachmentsHandled() {
        return this.unhandledContentFileCounts == 0
      },
      // 已经上传的附件总大小
      uploadedSizes() {
        let totalSize = this.sendForm.enclosureList.reduce((prev, next) => {
          prev += next.enclosureSize
          return prev
        }, 0)
        return totalSize
      }

    },
    watch: {
      uploadVisible(val) {
        let self = this
        if (!val) {
          this.fileList = []
        } else {
          if (!this.bindDragEvent) {
            this.bindDragEvent = true
            // 等元素显示出来后在绑定事件
            setTimeout(() => {
              bindDropEvent(
                '#mail-upload-input',
                { isDrag: true },
                self.changeFile.bind(this)
              )
            }, 1000)
          }
        }
      },
      autoSaveInterval(val) {
        if (val) {
          setTimeout(() => {
            this.timer = setInterval(() => {
              if (!this.$route.query.type == 0 && !this.privilege?.allowReply) {
                //写邮件并且没有写邮件权限
                return
              }
              if (this.type == 1 && !this.privilege?.allowReply) {
                // 回复
                return
              }
              if (this.type == 2 && !this.privilege?.allowForward) {
                // 转发
                return
              }
              this.saveDraft(null, true)
            }, val)
          }, 2000)
        }
      },
      ccShowFlag() {
        this.changeUEditorHeight()
      },
      ccVisible() {
        this.changeUEditorHeight()
      },
      bccVisible() {
        this.changeUEditorHeight()
      },
      'sendForm.enclosureList': {
        deep: true,
        handler() {
          this.changeUEditorHeight()
        },
      },
      'sendForm.emailAccount': {
        immediate: true,
        handler: function (val) {
          if (!val) return
          // 切换发件人，清空模板
          this.getMailTemplateList()
          this.getPrivilege(
            this.$route.query.mailId ? [this.$route.query.mailId] : [],
            val
          )


        },
      },
    },
    mounted() {
      this.signName = this.$t('placeholder.M2021')
      this.templateName = this.$t('placeholder.M2020')
      this.signMemory = localStorage.getItem('signMemory')
        ? JSON.parse(localStorage.getItem('signMemory'))
        : {}
      this.getMailConfig()
      this.accountInit().finally(() => {
        this.pageInit()
      })

      // 重新设置编辑器高度
      let remainHeight = getRemainingHeight(
        ['.tool-bar', 'mail-base-item', 'attach-files'],
        '',
        130
      )
      this.$refs.ueditor.initUE(remainHeight)
    },

    methods: {
      uploadRendings(files) {
        this.sendForm.enclosureList = [...this.sendForm.enclosureList, ...files]
        this.$refs.mailFiles.showFiles(this.sendForm.enclosureList)
      },
      // 选择效果图弹窗
      showRendingsDialog() {
        this.$refs.chooseRendings.showAddEdit()
      },
      // 监听编辑器失焦事件
      onEditorFocus() {
        let refNames = [
          'sendRef', //发件
          'ccRef', //抄送
          'bccRef', // 密送
        ]
        refNames.forEach((refName) => {
          let refComp = this.$refs[refName]
          // 关闭人员下拉
          refComp?.hide()
        })
      },
      // 获取最近联系人
      getListContact() {
        listContact({
          emailAccount: this.sendForm.emailAccount,
          type: 0,
        }).then((res) => {
          this.contactList = res.data
        })
      },
      // 动态改变UEditor高度
      changeUEditorHeight() {
        setTimeout(() => {
          //等dom变化后重新计算
          let remainHeight = getRemainingHeight(
            ['.tool-bar', 'mail-base-item', 'attach-files'],
            '',
            130
          )

          if(this.$refs?.ueditor) {
            this.$refs.ueditor.setUEditorHeight(remainHeight < 300 ? 300 : remainHeight)
          }
        }, 400)
      },
      // 操作提示信息
      showOperationMessage(isAutoSave) {
        if (isAutoSave) {
          this.$message.success(
            this.lang === 'en'
              ? 'Your email has been automatically saved'
              : '您的邮件已自动保存'
          )
          return
        }
        if (this.successConfig.type == 'immediate') {
          this.$message.success(
            this.lang === 'en' ? 'Your email has been sent' : '您的邮件已发送'
          )
        } else if (this.successConfig.type == 'timing') {
          if (this.successConfig.isDraft) {
            this.$message.success(
              this.lang === 'en'
                ? 'The mail has been saved to the draft box'
                : '邮件已保存至草稿箱'
            )
          } else {
            this.$message.success(
              this.lang === 'en'
                ? 'Your scheduled mail is saved successfully'
                : '您的定时邮件保存成功'
            )
          }
        }
        setTimeout(() => {
          try {
            window.MAIL_SAFE_CLOSE = true
            window.close()
            window.location.replace('about:blank')
          } catch (err) {
            // console.log(err)
          }
        }, 1000)
      },
      // 本地保存出运发货邮件
      localSave() {
        localSaveShipMailContent.call(this)
      },



      // 邮箱设置
      getMailConfig() {
        getMailOtherConfig({
          businessId: this.userInfo.userId,
        }).then((res) => {
          if (res.code === '000000') {
            if (res.data && res.data.length) {
              let config = res.data[0]
              if (config.autoSaveIntervalFlag) {
                this.autoSaveInterval =
                  (config.autoSaveInterval || 0) * 60 * 1000
              }
              if (config.delayedSendFlag) {
                this.delaySendTime = config.delayedSend || 0
              }
            }
          }
        })
      },
      // 获取签名列表
      getMailSignList() {
        return new Promise((resolve, reject) => {
          if (!this.userInfo.userId || !this.sendForm.emailAccount) {
            reject()
            return
          }
          mailSignList({
            businessId: this.userInfo.userId,
            emailAccount: this.sendForm.emailAccount,
          })
            .then((res) => {
              if (res.code == '000000') {
                this.signList = res.data || []
                resolve()
              }
            })
            .catch((err) => {
              reject()
            })
        })
      },
      //切换签名
      changeSign(item) {
        let mailContent = this.$refs.ueditor?.getUEContent()
        if (item) {
          this.signName = item.title
          let $ = cheerio.load(`<div class="wrapper">${mailContent}</div>`) //使用cheerio作为选择器替换html内容
          if (mailContent.indexOf('<section>') > -1) {
            // 如果原来有签名，直接替换签名内容
            // $('section').html(`<hr/>${item.content}`)
            $('section').html(`${item.content}`)
            let content = $('.wrapper').html()
            this.$refs.ueditor.setReadyContent(content)
          } else {
            // 如果原来没有签名，判断是否有其他签名内容
            let append = $('.append').length
              ? `<div class="append">${$('.append').html()}</div>`
              : ''
            if ($('.append').length) {
              $('.append').replaceWith(
                // `<section><hr/>  ${item.content}</section>${append}`
                `<section>${item.content}</section>${append}`
              )
            } else {
              if ($('.wrapper>p').length) {
                $('.wrapper').append(
                  // `<section><hr/>  ${item.content}</section>`
                  `<section> ${item.content}</section>`
                )
              } else {
                $('.wrapper').append(
                  // `<p><br/><br/><br/></p><section><hr/>  ${item.content}</section>`
                  `<p><br/><br/><br/></p><section>${item.content}</section>`
                )
              }
            }
            let content = $('.wrapper').html()
            this.$refs.ueditor.setReadyContent(content)
          }
        } else {
          // 如果没有选择内容，清空签名
          this.signName =
            this.lang === 'en' ? 'Select the signature' : '选择签名'
          if (mailContent.indexOf('<section>') > -1) {
            let index = mailContent.indexOf('<section>')
            let section = mailContent.substring(
              index,
              mailContent.indexOf('</section>') + 10
            )
            let content = mailContent.replace(section, '')
            this.$refs.ueditor.setReadyContent(content)
          }
        }
        this.signMemory[this.currentAccount] = this.signName //记录该邮箱使用的签名title
        let Memory = JSON.stringify(this.signMemory)
        localStorage.setItem('signMemory', Memory)
      },
      // 设置默认签名
      setDefaultSign() {
        if (this.signList.length) {
          this.signName =
            this.signMemory[this.currentAccount] || this.lang === 'en'
              ? 'Select the signature'
              : '选择签名'
          let sign = this.signList.find((item) => {
            return item.title == this.signName
          })
          if (sign) {
            this.changeSign(sign)
          } else {
            this.changeSign(this.signList[0])
          }
        } else {
          this.changeSign(null)
        }
      },
      // 获取模板列表
      getMailTemplateList() {
        return new Promise((resolve, reject) => {
          if (!this.userInfo.userId || !this.sendForm.emailAccount) {
            reject()
            return
          }
          mailTemplateList({
            businessId: this.userInfo.userId,
            emailAccount: this.sendForm.emailAccount,
          })
            .then((res) => {
              if (res.code == '000000') {
                this.templateList = res.data || []
                if (this.templateList.length == 0) {
                  this.templateName =
                    this.lang === 'en' ? 'Select the template' : '选择模板'
                }
                resolve()
              }
            })
            .catch((err) => {
              reject()
            })
        })
      },

      changeTemplate(item) {
        try {
          if(item.templateContent) {
            this.$refs?.ueditor?.editor.focus()
            this.$refs?.ueditor?.editor.execCommand('inserthtml',`${ item.templateContent}`);
          }

        } catch (err) {
          // console.log(err)
        }

      },
      // 打开人员下拉
      showpopover(index) {
        this.contactName = ''
        this.inputStatus = index
        this.searchContact()
        if (index == 1) {
          this.sendForm.receiveList = [...this.sendForm.receiveList]
        } else if (index == 2) {
          this.sendForm.ccEmailList = [...this.sendForm.ccEmailList]
        } else if (index == 3) {
          this.sendForm.bccEmailList = [...this.sendForm.bccEmailList]
        }
      },
      //添加联系人
      addContacts(list) {
        if (this.inputStatus == 1) {
          this.sendForm.receiveList = list
        } else if (this.inputStatus == 2) {
          this.sendForm.ccEmailList = list
        } else {
          this.sendForm.bccEmailList = list
        }
        this.$forceUpdate()
      },
      inSertAfter() {
        this.$refs.ueditor.inSertAfter()
      },
      // 搜索联系人
      searchContact() {
        BusEmit('LatestContacts', 'searchContact', this.contactName)
      },
      //调取上传
      handleUpload() {
        // this.$refs.AddFile.click()
        this.uploadVisible = true
      },
      // 文件移除
      onRemove(file, fileList) {
        this.fileList = fileList
      },
      // 上传附件
      changeFile(e, isDrag) {
        e.stopPropagation()
        e.preventDefault()
        let file = isDrag ? e.dataTransfer.files : e.target.files
        let files = Array.from(file)
        // 待上传附件
        let toUploadFiles = files.concat(this.fileList || [])
        let toUploadSizes = toUploadFiles.reduce((prev, next) => {
          prev += next.size
          return prev
        }, 0)

        let uploadedSizes = this.getUploadedSizes()
        if (toUploadSizes + uploadedSizes > 1024 * 1024 * maxFileTotalSize) {
          e.target.value = ''
          const msg =
            this.lang === 'en'
              ? 'The total size of attachments cannot exceed 25MB'
              : '附件总大小不能超过25MB'
          return this.$message.warning(msg)
        }
        this.fileList = this.fileList.concat(files)
        e.target.value = ''
      },
      // 校验附件总大小
      getUploadedSizes() {
        // 获取当前附件总大小
        let totalSize = this.sendForm.enclosureList.reduce((prev, next) => {
          prev += next.enclosureSize
          return prev
        }, 0)
        return totalSize
      },
      // 上传附件
      uploadFiles() {
        let file = this.fileList
        if (!file || file?.length === 0) {
          return this.$message.warning(
            this.lang === 'en' ? 'Please select File' : '请选择上传文件'
          )
        }
        let nomarFiles = [],
          maxAttachLen = 20,
          fileType = this.fileType
        if (maxAttachLen < file.length) {
          // 附件数量超出
          this.$message.error(`${this.$t('reqmsg.mail01')}${maxAttachLen}`)
          return
        }
        for (let v of file) {
          let suffix = v.name.substr(v.name.lastIndexOf('.') + 1)
          if (fileType.includes(suffix)) {
            // 文件不支持上传
            this.$message.error(`${suffix}${this.$t('reqmsg.mail03')}`)
            continue
          }
          if (v.size / 1024 / 1024 >= this.fileSize) {
            // 大小超过  无法上传
            this.$message.error(
              `${v.name}${this.$t('reqmsg.mail04')}${this.fileSize}M，${this.$t(
                'reqmsg.mail05'
              )}`
            )
            return
          }
          nomarFiles.push({
            file: v,
            flagSaveName: 1,
            source: 1,
            uploadType: 1,
          })
        }
        let that = this
        nomarFiles.forEach((v, index) => {
          uploadFile(v).then((res) => {
            if (res?.code == '000000') {
              this.sendForm.enclosureList.push({
                enclosureName: res.data.fileName,
                fileId: res.data.fileId,
                enclosureSize: res.data.size,
                enclosureUrl: res.data.path,
                suffix: res.data.suffix,
                uploadType: 1,
                mailEnclosureId: res.data.fileId,
                source: 1,
              })
              if (index === nomarFiles.length - 1) {
                this.uploadVisible = false
              }
              // 附件不超过maxFileTotalSize
              let totalSize = this.sendForm.enclosureList.reduce(
                (total, item) => {
                  return (total += item.enclosureSize)
                },
                0
              )
              if (totalSize > 1024 * 1024 * maxFileTotalSize) {
                const msg =
                  this.lang === 'en'
                    ? 'The total size of attachments cannot exceed 25MB'
                    : '附件总大小不能超过25MB'
                // this.$message.warning(`附件总大小不能超过${maxFileTotalSize}MB`)
              }
            }
          })
        })
      },
      // 删除上传附件
      deleteFile(index) {
        this.sendForm.enclosureList.splice(index, 1)
      },

      // 发送操作
      sendAction() {
        // 附件不超过25MB
        let totalSize = this.sendForm.enclosureList.reduce((total, item) => {
          return (total += item.enclosureSize)
        }, 0)
        if (totalSize > 1024 * 1024 * 25) {
          const msg =
            this.lang === 'en'
              ? 'The total size of attachments cannot exceed 25MB'
              : '附件总大小不能超过25MB'
          this.$message.warning(msg)
          return
        }
        let content = this.$refs.ueditor.getUEContent()

        // 清空签名的标签名
        content = content.replace(/<section>/g, '')
        content = content.replace(/<\/section>/g, '')
        let data = { ...this.sendForm }
        let { type, mailId, from } = this.$route.query
        data['content'] = content
        data['businessId'] = this.userInfo.userId
        data['tenantId'] = this.userInfo.tenantId
        data['type'] = type //添加类型
        data.mailServer = this.currentMailServe //邮箱类型
        data.boxFullName = getBoxFullName(this.currentMailServe)
        if (from === 'draft' && mailId != undefined) {
          data.mailId = mailId
        }
        if ((type == 1 || type == 2) && mailId) {
          // 转发和回复添加mailId
          data.prevMailId = mailId
        }
        if (!this.sendForm.receiveList.length) {
          const msg =
            this.lang === 'en' ? 'Please select the recipient' : '请选择收件人'
          return this.$message({
            type: 'warning',
            message: msg,
          })
        }

        if (!this.sendForm.title) {
          return this.$message.warning(this.$t('myMail.noTitle'))
        }

        if (!content) {
          return this.$message.warning(this.$t('myMail.noContent'))
        }

        if (this.sendForm.title && this.sendForm.title.length > 500) {
          const msg =
            this.lang === 'en'
              ? 'Mail subject lines are limited to 500 characters'
              : '邮件主题限制500个字符'
          return this.$message({
            type: 'warning',
            message: msg,
          })
        }
        let msg = ''
        let hasTitleChinse = hasChineseCharacters(this.sendForm.title)
        let hasChinese = hasChineseCharacters(content, true)
        let names = (this.sendForm.enclosureList || []).map((item) => item.enclosureName).join('')
        let hasChineseNameFile = hasChineseCharacters(names)
        let checkFileResult = false
        if(removeElementsAndKeywords(content) && !this.sendForm?.enclosureList?.length) {
          checkFileResult = true
        }

        if(hasTitleChinse) {
          msg = this.$t('myMail.titleContainChinese')
        }else if(hasChinese) {
          msg = this.$t('myMail.containChinese')
        } else if(hasChineseNameFile) {
          msg = this.$t('myMail.fileChinese')
        } else if(checkFileResult) {
          msg = this.$t('myMail.forgerFile')
        }
        if(msg) {
          this.$confirm(msg, this.$t('reqmsg.M2005'), {
            type: 'warning',
            confirmButtonText:this.lang === 'en' ? 'Continue' : '继续',
            cancelButtonText:this.lang === 'en' ? 'Cancel' : '取消',
          }).then(() => {
            if (!this.mailSended) {
              this.mailSended = true
            } else {
              return
            }
            sendMail(data)
              .then((res) => {
                if (res?.code == '000000') {
                  this.successConfig.type = 'immediate'
                  this.sendStatus = true
                  this.sendStatus = true
                  this.draftMailId = ''
                  this.replyTag()
                  this.$emit('updateLatest')
                  BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
                  this.sendResponse = res.data || {}
                  this.showOperationMessage()
                } else {
                  this.mailSended = false
                }
              })
              .then((res) => {
                if (res === undefined) {
                  this.mailSended = false
                }
              })
          })
        } else {
          if (!this.mailSended) {
            this.mailSended = true
          } else {
            return
          }
          sendMail(data)
            .then((res) => {
              if (res && res.code == '000000') {
                this.successConfig.type = 'immediate'
                this.sendStatus = true
                this.sendResponse = res.data || {}
                this.draftMailId = ''
                this.replyTag()
                this.$emit('updateLatest')
                BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
                if (this.$route.query.from === 'sign') {
                  const signBC = new BroadcastChannel('SIGN_MAIL')
                  signBC.postMessage('sended')
                }
                this.showOperationMessage()
              } else {
                this.mailSended = false
              }
            })
            .then((res) => {
              if (res === undefined) {
                this.mailSended = false
              }
            })
        }
      },

      // 回复邮件给之前的邮件打标识
      replyTag() {
        if (
          this.$route.query.from === 'reply' ||
          this.$route.query.from === 'business'
        ) {
          if (
            this.$route.query.mailId &&
            this.$route.query.mailId !== undefined
          ) {
            this.$store
              .dispatch('mail/setMailTag', {
                mailId: this.$route.query.mailId,
                reply: 1,
                businessId: this.userInfo.userId,
              })
              .then((res) => { })
              .catch((err) => { })
          }
        } else if (this.$route.query.from === 'transform') {
          this.$store
            .dispatch('mail/setMailTag', {
              mailId: this.$route.query.mailId,
              isForward: 1,
              businessId: this.userInfo.userId,
            })
            .then((res) => { })
            .catch((err) => { })
        }
      },
      //设置定时发生时间
      setSendTime() {
        if (this.sendForm.receiveList.length == 0) {
          const msg =
            this.lang === 'en' ? 'Please select the recipient' : '请选择收件人'
          this.$message.warning(msg)
          return
        }
        let content = this.$refs.ueditor.getUEContent()
        let data = { ...this.sendForm }


        if (!this.sendForm.title) {
          return this.$message.warning(this.$t('myMail.noTitle'))
        }

        if (!content) {
          return this.$message.warning(this.$t('myMail.noContent'))
        }
        let msg = ''
        let hasTitleChinse = hasChineseCharacters(this.sendForm.title)
        let hasChinese = hasChineseCharacters(content, true)
        let names = (this.sendForm.enclosureList || []).map((item) => item.enclosureName).join('')
        let hasChineseNameFile = hasChineseCharacters(names)
        let checkFileResult = false
        if(removeElementsAndKeywords(content) && !this.sendForm?.enclosureList?.length) {
          checkFileResult = true
        }

        if(hasTitleChinse) {
          msg = this.$t('myMail.titleContainChinese')
        }else if(hasChinese) {
          msg = this.$t('myMail.containChinese')
        } else if(hasChineseNameFile) {
          msg = this.$t('myMail.fileChinese')
        } else if(checkFileResult) {
          msg = this.$t('myMail.forgerFile')
        }
        if (msg) {
          this.$confirm(msg, this.$t('reqmsg.M2005'), {
            type: 'warning',
            confirmButtonText:this.lang === 'en' ? 'Continue' : '继续',
            cancelButtonText:this.lang === 'en' ? 'Cancel' : '取消',
          })
            .then(() => {
              this.$refs.SendTimeModel.showDialog()
            })
            .catch(() => { })

        } else {
          this.$refs.SendTimeModel.showDialog()
        }
      },
      // 定时发送
      sendTimingAction(time) {
        this.timingloading = true
        this.saveDraft(time)
      },

      // 保存至草稿
      saveDraft(time = null, isAutoSave = false, loading) {
        if (loading === true) {
          this.draftLoading = true
        }

        if (
          this.sendForm.title &&
          this.sendForm.title.length > 500 &&
          !isAutoSave
        ) {
          const msg =
            this.lang === 'en'
              ? 'Mail subject lines are limited to 500 characters'
              : '邮件主题限制500个字符'
          return this.$message({
            type: 'warning',
            message: msg,
          })
        }
        // 附件不超过25MB
        let totalSize = this.sendForm.enclosureList.reduce((total, item) => {
          return (total += item.enclosureSize)
        }, 0)
        if (totalSize > 1024 * 1024 * 25 && !isAutoSave) {
          const msg =
            this.lang === 'en'
              ? 'The total size of attachments cannot exceed 25MB'
              : '附件总大小不能超过25MB'
          this.$message.warning(msg)
          return
        }
        this.sendForm.content = this.$refs.ueditor.getUEContent()

        let data = { ...this.sendForm }
        // 添加isAutoSave
        data.isAutoSave = isAutoSave
        let { type, mailId, from } = this.$route.query
        data['businessId'] = this.userInfo.userId
        data['tenantId'] = this.userInfo.tenantId
        data['type'] = type
        if (from !== 'draft' && mailId) {
          data.prevMailId = mailId
        }

        data.mailServer = this.currentMailServe //邮箱类型
        if (time) {
          data['timingSendTime'] = time
          data['timingSwitch'] = 1
          data['timingType'] = 0
        }
        data.boxFullName = getBoxFullName(this.currentMailServe)
        if (
          this.draftMailId ||
          (this.$route.query.from == 'draft' &&
            this.$route.query.mailId != undefined)
        ) {
          data.mailId = this.draftMailId || this.$route.query.mailId
          updateDraft(data)
            .then((res) => {
              if (res?.code === '000000') {
                if (!isAutoSave) {
                  this.successConfig.type = 'timing'
                  if (time) {
                    this.successConfig.isDraft = false
                  } else {
                    this.successConfig.isDraft = true
                  }
                  this.sendStatus = true
                  this.mailSended = true
                }
                BusEmit('MailList', 'updatePage') // 触发更新邮件分类数量
                this.showOperationMessage(isAutoSave)
              }
            })
            .then(() => {
              this.draftLoading = false
              this.timingloading = false
            })
        } else {
          addDraft(data)
            .then((res) => {
              if (res?.code === '000000') {
                this.draftMailId = res.data
                if (!isAutoSave) {
                  this.successConfig.type = 'timing'
                  if (time) {
                    this.successConfig.isDraft = false
                  } else {
                    this.successConfig.isDraft = true
                  }
                  this.sendStatus = true
                  this.mailSended = true
                }

                BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
                BusEmit('MailList', 'updatePage') // 触发更新邮件分类数量
                this.showOperationMessage(isAutoSave)
              }
            })
            .then(() => {
              this.draftLoading = false
              this.timingloading = false
            })
        }
      },
      // 切换当前发件人
      changeCurrent(val) {
        this.sendForm.emailAccount = val
        this.$store.commit('mail/setCurrentAccount', val)
        this.getCCMail(this.sendForm.emailAccount)
        this.getMailSignList().then(() => {
          if (this.signList.length) {
            setTimeout(() => {
              this.setDefaultSign()
            }, 666)
          } else {
            this.signName =
              this.lang === 'en' ? 'Select the signature' : '选择签名'
            this.changeSign(null)
          }
        })
      },

      // 切换抄送人
      changeCc() {
        if (this.ccVisible) {
          this.sendForm.ccEmailList = []
          this.inputStatus = 2
        }
        this.ccVisible = !this.ccVisible
      },
      // 切换密送人
      changeBcc() {
        if (this.bccVisible) {
          this.sendForm.bccEmailList = []
          this.inputStatus = 3
        }
        this.bccVisible = !this.bccVisible
      },
      // 群发单显功能
      handleForward() {
        if (this.sendForm.singleDisplay) {
          this.ccShowFlag = true
          this.sendForm.singleDisplay = 0
        } else {
          this.ccShowFlag = false
          this.sendForm.ccEmailList = []
          this.sendForm.singleDisplay = 1
        }
      },

      // 转化收件人，抄送人
      getMailStr(str) {
        if (str) {
          let reStr = str
            .split(',')
            .map((ele) => {
              return `${ele.split('@')[0]}&lt;${ele}&gt;`
            })
            .join(';')
          return reStr
        } else {
          return '--'
        }
      },
      // 再写一封
      handleRewrite() {
        this.draftMailId = ''
        this.sendForm = {
          bccEmailList: [], // 密送人
          ccEmailList: [], // 抄送人
          content: '', // 邮件内容
          emailAccount: this.currentAccount, // 发件人
          enclosureList: [],
          receiveList: [], // 收件人
          title: '', // 主题
          businessJson: null,
          singleDisplay: 0,
          boxFullName: '',
        }
        // 再写一封邮件
        this.$refs.mailFiles?.showFiles(this.sendForm.enclosureList)
        this.ccShowFlag = true
        this.sendStatus = false
        this.ccVisible = false
        this.bccVisible = false
        this.templateName =
          this.lang === 'en' ? 'Select the template' : '选择模板'
        this.$refs.ueditor.setReadyContent('')

        setTimeout(() => {
          this.setDefaultSign()
        }, 666)
      },
      handleBackToSend() {
        this.$router.push({
          path: '/mail/my-mail/email-list',
          query: {
            mailName: this.sendResponse?.boxName,
            serve: this.sendResponse?.mailServer,
            boxFullName: this.sendResponse?.boxFullName,
            account: this.sendResponse?.emailAccount,
            check: 1, // 查看已发邮件
            mailFullName: this.sendResponse?.boxFullName,
          },
        })
      },

      handleBackToReceive() {
        // 处理跳转至收件箱列表
        this.$router.push({ path: '/mail/my-mail/receive' })
      },

      handleGiveUp() {
        // 处理跳转至收件箱列表
        this.$router.push({ path: '/mail/my-mail/receive' })
      },
      //邮箱和账号没有数据
      accountInit() {
        return new Promise((resolve, reject) => {
          if (isShipment.call(this)) {
            resolve()
          } else if (isSign.call(this)) {
            resolve()
          } else {
            this.$store
              .dispatch('mail/getMailAccountList')
              .then((data) => {
                let accountList = this.accountList
                if (accountList.length == 0) {
                  // 您没有绑定邮箱，请先绑定
                  // this.$message.warning(this.$t('reqmsg.$58'))
                  return
                }

                if (!this.currentAccount) {
                  this.$store.commit(
                    'mail/setCurrentAccount',
                    accountList && accountList.length
                      ? accountList[0].emailAccount
                      : ''
                  )
                  this.$store.commit(
                    'mail/setcurrentAccountServer',
                    accountList && accountList.length
                      ? accountList[0].mailServer
                      : ''
                  )
                }
                // 回复转发不用赋值，取详情返回的
                if (
                  this.$route.query.type !== '1' ||
                  this.$route.query.type !== '2'
                ) {
                  this.sendForm.emailAccount = this.currentAccount
                }
                if (this.$route.query.type === '0') {
                  this.getCCMail(this.currentAccount)
                }
                resolve()
              })
              .catch((err) => {
                reject(err)
              })
          }
        })
      },
      // 获取邮件信息
      mailInit() {
        const self = this
        getMailDetailById(this.$route.query.mailId).then((res) => {
          if (res?.code !== '000000') {
            return
          }
          if(res?.data?.extendInfo?.content) {
            res.data.extendInfo.content = `<div class="erp-mail-quote">${res.data.extendInfo.content}</div>`
          }
          this.type = res?.data?.baseInfo?.type
          let detail = res?.data || {}
          // 在进行其他方式处理之前先处理content中的正文资源
          let _unhandledContentFiles = []
          let onlyBase64 = !detail?.unprocessedAttachments ||detail?.unprocessedAttachments?.length === 0

          if (detail.extendInfo?.content) {
            _unhandledContentFiles = getUnhandledContentFiles(
              detail.extendInfo.content,
              onlyBase64  // 是否只处理base64图片
            )

          }
          if (detail?.extendInfo?.content) {
            detail.extendInfo.content = replaceSrcWithLoadingSrc(
              detail.extendInfo.content,
              (detail.unprocessedAttachments || []).concat(
                _unhandledContentFiles
              ),
              onlyBase64

            )
          }

          // 非草稿箱邮件要删除section,防止定时发送的bug出现
          if (this.$route.query.from !== 'draft') {
            let contentStr = detail.baseInfo.content
            // 处理垃圾箱中的内容
            if (contentStr) {
              let onlyBase64 = !detail?.unprocessedAttachments ||detail?.unprocessedAttachments?.length === 0
              contentStr = replaceSrcWithLoadingSrc(
                contentStr,
                (detail?.unprocessedAttachments || []).concat(
                  _unhandledContentFiles
                ),
                onlyBase64
              )
            }

            // 清除包裹其他内容的标签
            let $ = cheerio.load(`<div class="wrapper">${contentStr}</div>`) //使用cheerio作为选择器替换html内容
            if ($('.wrapper').children('.append').length) {
              let inner = $('.append').html()
              $('.wrapper .append').replaceWith(inner)
            }
            // 清空签名的标签名
            contentStr = $('.wrapper').html()
            contentStr = contentStr.replace(/<section>/g, '')
            contentStr = contentStr.replace(/<\/section>/g, '')
            detail.baseInfo.content = contentStr
          }
          //抄送人邮箱
          let ccStr = detail.extendInfo.ccEamils
            ? // 抄送人
            `<p style='font-size:12px;color:#666;'><strong>Cc：</strong>${this.getMailStr(
              detail.extendInfo.ccEamils
            )}</p>`
            : ''

          let str = `<div class='append'><br/><hr/><p style='font-size:12px;color:#666;'><strong>From: </strong>${detail.baseInfo.sendEmail
            }</p><p style='font-size:12px;color:#666;'><strong>Sent: </strong>${this.utils.formatTimeToEnglishDate(
              detail.baseInfo.sendTime
            )}</p><p style='font-size:12px;color:#666;'><strong>To: </strong>${this.getMailStr(
              detail.extendInfo.receiveEmails
            )}</p>${ccStr}<p style='font-size:12px;color:#666;'><strong>Subject: </strong>${detail.baseInfo.title || ''
            }</p><hr/></div>`
          let bccEmailList = []
          let ccEmailList = []
          let receiveList = []
          let enclosureList = []
          let content = ''
          let title = ''
          let businessJson = ''
          if (this.$route.query.from === 'draft') {
            this.draftMailId = this.$route.query.mailId
            bccEmailList = detail.extendInfo.bccEmails
              ? detail.extendInfo.bccEmails.split(',')
              : []
            ccEmailList = detail.extendInfo.ccEamils
              ? detail.extendInfo.ccEamils.split(',')
              : []
            receiveList = detail.extendInfo.receiveEmails
              ? detail.extendInfo.receiveEmails.split(',')
              : []
            title = detail.baseInfo.title
            enclosureList = detail.enclosureList || []
            businessJson = detail.baseInfo.businessJson
            content = detail.extendInfo.content || ''
            if (detail.extendInfo.ccEamils) {
              this.ccVisible = true
            }
            if (detail.extendInfo.bccEmails) {
              this.bccVisible = true
            }
          } else if (this.$route.query.from === 'reply') {
            let hasFile = this.$route.query.file === 'true' ? true : false // 带附件回复
            let isReplyAll =
              this.$route.query.isReplyAll === 'true' ? true : false // 回复全部
            // 抄送人为原始邮件抄送人
            ccEmailList = isReplyAll ? [...new Set(detail?.baseInfo?.ccEamils?.split(',') || [])].filter(ccEmail => ccEmail !== detail.baseInfo.emailAccount && ccEmail) :[]
            if(ccEmailList.length > 0) {
              this.ccShowFlag = true
              this.ccVisible = true
            }
            //收件人 原始邮件收件人 + 发件人 + 过滤掉本次发件人
            let receiveEmails = detail?.baseInfo?.receiveEmails?.split(',') || []
            // 原发件人放在第一个
            receiveEmails.unshift(detail?.baseInfo?.sendEmail)
            receiveEmails = receiveEmails.filter(email => email !==  detail.baseInfo.emailAccount && email)
            receiveEmails = [...new Set(receiveEmails)]


            bccEmailList = [] // 密送人
            receiveList = isReplyAll
              ? receiveEmails
              : [detail.baseInfo.sendEmail] // 收件人
            title = detail.baseInfo?.title?.startsWith('Re:')
              ? `${detail.baseInfo.title || ''}`
              : `Re:${detail.baseInfo.title || ''}` // 主题
            enclosureList = hasFile ? detail.enclosureList || [] : []
            businessJson = detail.baseInfo.businessJson
            content = str + (detail.extendInfo.content || '')
          } else if (this.$route.query.from === 'transform') {
            bccEmailList = [] // 密送人
            ccEmailList = [] // 抄送人
            receiveList =
              this.$route.query.receiveMail !== undefined
                ? [this.$route.query.receiveMail]
                : [] // 收件人
            title = `Fw:${detail.baseInfo.title || ''}` // 主题
            enclosureList = detail.enclosureList ? detail.enclosureList : []
            businessJson = detail.baseInfo.businessJson
            content = str + (detail.extendInfo.content || '')
          } else if (this.$route.query.from === 'business') {
            let file = JSON.parse(this.$route.query.file)
            let isArray = file instanceof Array
            bccEmailList = [] // 密送人
            ccEmailList = [] // 抄送人
            receiveList = [this.$route.query.email] // 收件人
            title = detail.baseInfo?.title?.startsWith('Re:')
              ? `${detail.baseInfo.title || ''}`
              : `Re:${detail.baseInfo.title || ''}` // 主题
            enclosureList = isArray ? file : [file]
            businessJson = detail.baseInfo.businessJson
            content = str + (detail.extendInfo.content || '')
          }
          this.sendForm = {
            bccEmailList, // 密送人
            ccEmailList, // 抄送人
            emailAccount: detail.baseInfo.emailAccount, // 发件人
            enclosureList,
            receiveList, // 收件人
            title, // 主题// 主题
            businessJson,
            singleDisplay: 0,
          }

          // 获取抄送人邮箱
          this.getCCMail(this.sendForm.emailAccount, false)

          this.getMailSignList().then(() => {
            if (
              this.signList.length > 0 &&
              this.$route.query.from !== 'draft'
            ) {
              this.signName =
                this.signMemory[this.currentAccount] || this.lang === 'en'
                  ? 'Select the signature'
                  : '选择签名'
              let sign = this.signList.find((item) => {
                return item.title == this.signName
              })
              if (!sign) {
                sign = this.signList[0]
              }
              // sign  签名内容
              this.signName = sign.title
              // this.sendForm.content = `<p><br/><br/><br/></p><section><hr/>${sign.content}</section><div class="append">${content}</div>`
              this.sendForm.content = `<p><br/><br/><br/></p><section>${sign.content}</section><div class="append">${content}</div>`
            } else if (
              this.signList.length > 0 &&
              this.$route.query.from == 'draft'
            ) {
              this.sendForm.content = content
              // 如果草稿箱，要回显当前签名
              this.signList.forEach((item) => {
                if (content.indexOf('<hr/>' + item.content) > -1) {
                  this.signName = item.title
                  // this.sendForm.content = content.replace(
                  //   '<hr/>' + item.content,
                  //   `<section><hr/>${item.content}</section>`
                  // )
                  this.sendForm.content = content.replace(
                    item.content,
                    `<section>${item.content}</section>`
                  )
                }
              })
            } else {
              this.sendForm.content = `<div class="append">${content}</div>`
            }
            // console.log(self.sendForm.content)
            self.sendForm.content = self.sendForm.content.replace('<html>', '')
            self.sendForm.content = self.sendForm.content.replace('</html>', '')
            let bodyTagStartIndex = self.sendForm.content.indexOf('<body')
            let bodyTagEndIndex
            for (
              let i = bodyTagStartIndex;
              i < self.sendForm.content.length;
              i++
            ) {
              if (self.sendForm.content[i] === '>') {
                bodyTagEndIndex = i
                break
              }
            }
            let start = self.sendForm.content.substring(0, bodyTagStartIndex)
            let end = self.sendForm.content.substring(bodyTagEndIndex + 1)
            self.sendForm.content = start + end


            setTimeout(async () => {

              self.$refs.ueditor.setReadyContent(self.sendForm.content)

              // 判断正文中是否有未处理的附件

              this.handleContentAttachments(
                (detail.unprocessedAttachments || []).concat(
                  _unhandledContentFiles
                )
              )
              // 处理附件
              this.sendForm.enclosureList = getEnclosureList(
                this.sendForm.enclosureList || [],
                detail.unprocessedAttachments
              )
              this.$refs.mailFiles?.showFiles(this.sendForm.enclosureList)
            }, 666)
          })
        })
      },

      // 处理正文中未处理的附件
      async handleContentAttachments(unprocessedContentAttachments) {
        let WritePage = this
        // 获取正文中未处理的附件
        unprocessedContentAttachments = getUnprocessedContentAttachments(
          unprocessedContentAttachments
        )
        this.unhandledContentFileCounts = unprocessedContentAttachments.length
        // 6s后清空状态
        setTimeout(() => {
          if (this.unhandledContentFileCounts > 0) {
            this.unhandledContentFileCounts = 0
          }
        }, 6000)
        // 定义当前组件未处理正文附件

        if (unprocessedContentAttachments.length > 0) {
          // loading提示正文图片资源处理中
          let loading = window.AXIOS_LOADING.service({
                    fullscreen: true,
                    background: 'rgba(0, 0, 0, 0.4)',
                    text: '正文图片资源处理中',
                  })
          for (let i = 0; i < unprocessedContentAttachments.length; i++) {
            let attach = unprocessedContentAttachments[i]
            processFile(attach, this, 1, true, this.$refs.ueditor, WritePage, i == unprocessedContentAttachments.length - 1 ? loading : null)
          }
        }
      },
      // 页面初始化
      async pageInit() {
        // 附件处理
        if (!this.$route.query.mailId) {
          this.$refs.mailFiles.showFiles(this.sendForm.enclosureList)
        }

        // jiangyongqiang添加 2021/12/6 bugfix 从收款通知跳转到写邮件页面需要显示收件人

        if (this.$route.query.from === 'homepage') {
          // 首页直接进入，需要先获取账号列表和当前账号，再获取签名列表，默认选择第一个
          this.getMailSignList().then(() => {
            this.setDefaultSign()
          })
          this.sendForm.receiveList = this.$route.query.email
            ? [this.$route.query.email]
            : []
        }
        // 来源 book 通讯录 draft 草稿箱 transform 转发的  reply 回复的  offer 报价业务 business 其他业务
        else if (this.$route.query.mailId) {
          // 草稿箱，转发，回复，business部分
          this.mailInit()
        } else if (this.$route.query.from === 'offer') {
          let file = JSON.parse(this.$route.query.file)
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            content: '', // 邮件内容
            emailAccount: this.currentAccount, // 发件人
            enclosureList: [file],
            receiveList: [this.$route.query.email], // 收件人
            title: '', // 主题
            businessJson: null,
            singleDisplay: 0,
          }

          this.getMailSignList().then(() => {
            this.setDefaultSign()
          })
        } else if (
          this.$route.query.from === 'business' &&
          !this.$route.query.mailId
        ) {
          let file = ''
          let isArray = null
          if (this.$route.query.file) {
            file = JSON.parse(this.$route.query.file)
            isArray = file instanceof Array
            this.sendForm = {
              bccEmailList: [], // 密送人
              ccEmailList: [], // 抄送人
              content: '', // 邮件内容
              emailAccount: this.currentAccount, // 发件人
              enclosureList: isArray ? file : [file],
              title: '', // 主题
              businessJson: null,
              singleDisplay: 0,
            }
          }
          this.sendForm.receiveList = [this.$route.query.email] //收件人
          let str = ''
          // let ccStr = `<p>Hello , </p><p>Thank you for your interest in our products.  We will be pleased to provide you the information you need.  Please review the following information to the best of your knowledge and we will reply with current pricing, complete with production time breakdown as soon as possible.</p>`
          if (
            this.$route.query.enquiryId &&
            this.$route.query.enquiryId !== undefined
          ) {
            let res = await orderEnquiryDetail({
              businessId: this.$route.query.enquiryId,
            })
            if (res.code == '000000') {
              let products = res.data.products
              if (products.length) {
                //循环产品
                products.forEach((item, index) => {
                  str += `<p style="margin-top:5px;"></p><p>Item #：${item.productEn}</p>
                              <p>Quantity：${item.amount}</p>
                              <p>Color：${item.specification}</p>
                              <p>Printing：${item.printingWay}</p>
                              <p>Deadline：</p>
                              <p>Shipping to zip- code：</p>`
                })
              }
            }
          }
          let content = ''
          this.getMailSignList().then(() => {
            if (this.signList.length) {
              this.signName =
                this.signMemory[this.currentAccount] || this.lang === 'en'
                  ? 'Select the signature'
                  : '选择签名'
              let sign = this.signList.find((item) => {
                return item.title == this.signName
              })
              if (!sign) {
                sign = this.signList[0]
              }
              this.signName = sign.title
              // content = `<p><br/><br/><br/></p><section><hr/>  ${sign.content}</section><div class="append">${str}</div>`
              content = `<p><br/><br/><br/></p><section> ${sign.content}</section><div class="append">${str}</div>`
            } else {
              content = `<div class="append">${str}</div>`
            }
            if (content) {
              setTimeout(() => {
                this.$refs.ueditor?.setReadyContent(content)
              }, 666)
            }
          })
        } else if (isShipment.call(this)) {
          getSendGoodMailContent.call(this)
        } else if (isSign.call(this)) {
          getSignMailInfo.call(this)
        } else if (this.$route.query.from === 'book') {
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            content: '', // 邮件内容
            emailAccount: this.currentAccount, // 发件人
            enclosureList: [],
            receiveList: this.$route.query.receive.split(','), // 收件人
            title: '', // 主题
            businessJson: null,
            singleDisplay: 0,
          }
          this.getMailSignList().then(() => {
            if (this.signList.length) {
              setTimeout(() => {
                this.setDefaultSign()
              }, 666)
            }
          })
          // 首页简易发票
        } else if (this.$route.query.from === 'simpleInvoice') {
          // let detail = this.$route.query
          let file = JSON.parse(this.$route.query.file)
          let isArray = file instanceof Array
          this.sendForm = {
            bccEmailList: [], // 密送人
            ccEmailList: [], // 抄送人
            emailAccount: this.currentAccount, // 发件人
            enclosureList: isArray ? file : [file],
            receiveList: [file.receiveMail], // 收件人
            title: file.title, // 主题
            businessJson: null,
            singleDisplay: 0,
            content: file.content, // 邮件内容
          }
          if (this.sendForm.content) {
            setTimeout(() => {
              this.$refs.ueditor.setReadyContent(this.sendForm.content)
            }, 666)
          }
        } else if (this.$route.query.from === 'newcustomer') {
          this.sendForm.receiveList = this.$route.query.email?.split(',')
        } else {
          //写邮件
          this.getMailSignList().then(() => {
            if (this.signList.length) {
              setTimeout(() => {
                this.setDefaultSign()
              }, 666)
            }
            this.setFont()
          })
        }

        this.$refs.mailFiles?.showFiles(this.sendForm.enclosureList)
      },
      // 设置默认字体
      setFont() {
        if (this.$refs.ueditor) {
          this.$refs.ueditor.setFocusText('Arial')
          this.$refs.ueditor.setFocusText('forecolor', '#000000')
          this.$refs.ueditor.setFocusText('fontsize', '24px')
        }
      },

      // 写邮件，转发邮件，回复邮件获取抄送人
      getCCMail(emailAccount, clear) {
        let type = this.$route.query.type
        if (clear !== false) {
          this.sendForm.ccEmailList = []
        }

        if (['0', '1', '2'].includes(type)) {
          MailInteractor.getMailAccountConfigVOApi({
            emailAccount,
            tenantId: this.userInfo.tenantId,
          }).then((res) => {
            if (res?.code === '000000') {
              let type2field = {
                0: 'sended',
                1: 'replied',
                2: 'forwarded',
              }
              let field = type2field[type]
              if (res.data[field] === 1) {
                try {
                  let ccEmailList = Array.from(new Set([...this.sendForm.ccEmailList,res.data.email]))
                  this.$set(this.sendForm, 'ccEmailList', ccEmailList)
                  this.ccVisible = true
                }catch(err) {
                  console.log(err)
                }

              }
            }
          })
        }
      },

      // 查询权限
      async getPrivilege(mailIdList, emailAccount) {
        let res = await systemInteractor.myMailPrivilegeApi({
          emailAccount: emailAccount,
          mailIdList,
        })
        let privilege = {}
        if (res?.code === '000000') {
          privilege = res.data || {}
        } else {
          privilege = {}
        }
        this.privilege = privilege
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-name {
    width: 5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sign-item {
    max-width: 7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.empty {
      color: #999;
      text-align: center;
      padding: 0 10px;
    }
  }

  .write-page {
    height: 100%;
    min-width: 980px;
    overflow-x: auto;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .tool-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid #ebeef5;

      .left-tools {
        display: flex;
        align-items: center;

        .btn-upload,
        .btn-sign {
          white-space: nowrap;
          margin-left: 10px;
          cursor: pointer;
          font-size: 14px;
          color: #666;
          display: flex;
          align-items: center;

          .iconfont {
            margin-right: 3px;
          }
        }
      }

      .right-tools {
        display: flex;
        align-items: center;

        .el-button {
          padding: 0 6px;
          font-size: 14px;
        }

        .label {
          font-size: 14px;
          color: #999;
          margin-right: 6px;
          margin-left: 10px;
        }

        .select-box {
          margin-right: 10px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
      }
    }

    .mail-base-item {
      padding: 10px 20px 0;
      display: flex;
      border-bottom: 1px solid #ebeef5;
      position: relative;

      .dt {
        width: 70px;
        margin-bottom: 10px;
        line-height: 30px;
      }

      .dd {
        flex: 1;
      }

      .el-button {
        height: 30px;
      }

      /deep/ .el-input__inner {
        border: none !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }

    .attach-files {
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;

      .file-item {
        height: 30px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        padding: 0 12px;
        margin-bottom: 10px;
        margin-right: 20px;
        line-height: 30px;
        display: flex;

        .icon {
          line-height: 30px;
        }

        .preview {
          margin-right: 10px;
          color: #1890ff;
        }

        .size {
          margin-right: 10px;
          color: #999;
        }

        &>.file-name {
          max-width: 160px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &>.icon {
          font-size: 12px;
          color: #fa6400;
          margin-right: 10px;
          line-height: 30px;
        }

        &>.close {
          cursor: pointer;
          line-height: 30px;
          color: #ccc;

          &:hover {
            color: #1890ff;
          }
        }
      }
    }

    .ueditor-write {
      flex: 1;
      overflow-y: auto;
      padding: 10px 20px;

      &>div {
        height: 100%;
      }

      #edui1 {
        height: 100%;
      }
    }
  }

  #ueditorMail {
    height: 100%;
  }

  .mail-success {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    box-shadow: 0 3px 10px #ccc;
    background: #fff;
    padding-top: 30vh;
    text-align: center;

    .success-icon {
      i {
        font-size: 60px;
        color: #2eb797;
      }
    }

    .mail-success-tip1 {
      font-size: 18px;
      color: #303030;
      margin: 24px auto 12px;
    }

    .mail-success-tip2 {
      font-size: 12px;
      color: #777777;
      margin-bottom: 32px;
    }
  }

  .contact-popover {
    .search-area {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .search-input {
        flex: 1;
        margin-right: 10px;
      }
    }

    .contacts-groups {
      height: 60vh;
      overflow-y: auto;
    }
  }

  ::v-deep {
    .el-upload-dragger {
      position: relative;

      #mail-upload-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 180px;
        width: 360px;
        z-index: 20;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .el-button.fs-14 {
    font-size: 14px !important;
    margin-left: 10px;
  }
</style>
