<template>
  <!-- 绑定邮箱 -->
  <el-dialog
    :title="$t('mailConfig.addSubAccount')"
    :visible.sync="dialogFormVisible"
    width="506px"
    @close="close"
    :close-on-click-modal="false"
    custom-class="bind-mail"
  >
    <div class="line mt-10"></div>
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        label-width="100px"
      >
        <el-form-item
          label-position="left"
          :label="$t('mailConfig.myemail') + ':'"
          prop="emailAccount"
          :rules="[
            {
              required: true,
              trigger: 'change',
              message: $t('placeholder.plsSel'),
            },
          ]"
        >
          <el-select v-model="form.emailAccount">
            <el-option
              v-for="(item, index) in mailServerList"
              :key="item.mailAccountId"
              :label="item.emailAccount"
              :value="item.emailAccount"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('mailConfig.authorizedPerson') + ':'"
          label-position="left"
          :label-width="settings === 'en' ? '150px' : '100px'"
          required
        >
          <!-- 添加按钮 -->
          <div class="mb-10 add-btn-wrappper">
            <el-button size="small" @click="openUserChoose">
              {{ $t('mailConfig.add') }}
            </el-button>
          </div>
        </el-form-item>

        <!-- 被授权人列表 -->
        <div class="table-wrapper">
          <el-table
            :data="selectedUsers"
            border
            style="width: 100%"
            class="mb-20"
            max-height="215px"
          >
            <el-table-column
              prop="name"
              :label="$t('mailConfig.uName')"
              align="center"
              min-width="100"
            >
              <template slot-scope="{ row }">
                {{ settings === 'en' ? row.englishName : row.name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="isOnline"
              :label="$t('mailConfig.isCustomerService')"
              align="center"
              min-width="120"
            >
              <template slot-scope="{ row }">
                <el-switch
                  v-model="row.roleType"
                  :inactive-value="0"
                  :active-value="1"
                  :inactive-text="$t('mailConfig.close')"
                  :active-text="$t('mailConfig.open')"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('mailConfig.operation')"
              align="center"
              :width="settings === 'en' ? '120' : '80'"
            >
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  @click="removeUser(row)"
                  class="delete-btn"
                >
                  {{ $t('mailConfig.delete') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </div>
    <div class="line mb-10"></div>
    <template v-slot:footer>
      <el-row class="text-right">
        <el-button @click="close">
          {{ $t('mailConfig.Cancel') }}
        </el-button>
        <el-button type="primary" :loading="loading" @click="save">
          {{ $t('mailConfig.Confirm') }}
        </el-button>
      </el-row>
    </template>

    <!-- 选择用户组件 -->
    <UserChoose
      ref="userChoose"
      @choose-user="chooseUser"
      :multiple="true"
    ></UserChoose>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { deepCopy } from 'kits'
  import UserChoose from '@/components/userChoose'
  import { MailSettingInteractor } from '@/core'

  export default {
    name: 'AccountAuthorizationForm',

    data() {
      return {
        loading: false,
        mailServerList: [],
        selectedUsers: [],
        form: {
          emailAccount: '',
        },
        dialogFormVisible: false,
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        settings: 'settings/language',
      }),
      rules() {},
    },

    components: {
      UserChoose,
    },

    methods: {
      //新增、编辑
      showAddEdit() {
        this.getMailServerList()
      },

      // 获取邮箱账号下拉列表
      getMailServerList() {
        MailSettingInteractor.getMailAccountListApi().then((res) => {
          if (res?.code === '000000') {
            this.mailServerList = (res.data || []).filter(
              (item) => item.mailServer === 'Gmail'
            )
            if (this.mailServerList.length === 1) {
              this.form.emailAccount = this.mailServerList[0].emailAccount
            }
            this.dialogFormVisible = true
          }
        })
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.selectedUsers = []
        this.dialogFormVisible = false
        if (this.$refs.userChoose) {
          this.$refs.userChoose.close()
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.selectedUsers.length === 0) {
              this.$message.warning(
                this.$t('mailConfig.pleaseAddAuthorizedPerson')
              )
              return
            }
            const requestData = this.selectedUsers.map((user) => ({
              emailAccount: this.form.emailAccount,
              authUserId: user.id,
              authUserNameCn: user.name,
              authUserNameEn: user.englishName,
              roleType: user.roleType,
            }))
            MailSettingInteractor.batchAuthEmailApi(requestData).then((res) => {
              if (res?.code === '000000') {
                this.$message.success(this.$t('reqmsg.$9'))
                this.$emit('fetch-data')
                this.close()
              }
            })
          }
        })
      },
      removeUser(user) {
        this.selectedUsers = this.selectedUsers.filter((u) => u.id !== user.id)
      },

      chooseUser(users) {
        console.log(this.selectedUsers)
        const existUsers = users.filter((user) => {
          return this.selectedUsers.some((u) => u.id === user.id)
        })

        const newUsers = users
          .filter((user) => {
            return !existUsers.some((u) => u.id === user.id)
          })
          ?.map((user) => ({
            ...user,
            roleType: 1, // 默认客服
          }))
        debugger
        this.selectedUsers = [...this.selectedUsers, ...newUsers]
        // this.selectedUsers = users.map((user) => ({
        //   ...user,
        //   roleType: 1, // 默认客服
        // }))
      },
      openUserChoose() {
        const roleId = deepCopy(this.selectedUsers)
        if (roleId?.length > 0) {
          this.$refs.userChoose.showAddEdit('', roleId)
        } else {
          this.$refs.userChoose.showAddEdit()
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px;
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
    .selected-users {
      margin-top: 10px;
      .user-tag {
        margin-right: 8px;
        margin-bottom: 8px;
        .status-dot {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-left: 5px;
          &.online {
            background-color: #67c23a;
          }
          &.offline {
            background-color: #909399;
          }
        }
      }
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
  ::v-deep {
    .el-switch__label.is-active {
      background-color: transparent !important;
    }
  }
  ::v-deep {
    .el-dialog.bind-mail .el-dialog__body {
      padding: 0px !important;
    }
  }
  .add-btn-wrappper {
    display: flex;
    justify-content: flex-end;
  }
  .table-wrapper {
    height: 220px;
  }
  .el-dialog.bind-mail .el-dialog__body {
    padding: 0px !important;
  }
  .line {
    height: 1px;
    background-color: #ebeef5;
    margin: 20px 0;
    width: 100%;
    &.mt-10 {
      margin-top: 10px;
    }
    &.mb-10 {
      margin-bottom: 10px;
    }
  }
</style>
