<template>
  <div class="table-container outbound">
    <el-row v-if="$route.query.noReturn !== 'true'">
      <el-page-header @back="goBack" content="出库申请"></el-page-header>
    </el-row>
    <h3 class="mt30 mb20">基本信息</h3>
    <div class="form-wrapper">
      <el-form ref="form" label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="出库方式" prop="type">
              <el-select v-model="currentType" value-key="type" disabled style="width: 215px">
                <el-option v-for="item in typeNames" :key="item.type" :value="item" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出库仓库 -->
            <el-form-item label="出库仓库" prop="warehouseId" x>
              <el-select style="width: 215px" v-model="form.warehouseId" value-key="type"
                :popper-append-to-body="false">
                <el-option :title="item.name" v-for="item in warehouses" :key="item.warehouseId"
                  :value="item.warehouseId" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售单号" prop="orderNumber">
              <el-input v-model="form.salesOrderCode" placeholder="请输入" disabled style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 加工单号 -->
            <el-form-item label="加工单号" prop="orderNumber">
              <el-input style="width: 215px" v-model="form.processOrderCode" placeholder="请输入" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item prop="creator">
              <span slot="label">创建人</span>
              <el-input disabled v-model="form.creator" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属地区">
              <el-input disabled v-model="form.creatorArea" style="width: 215px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark">
              <span slot="label">备注</span>
              <el-input style="width: 90%" type="textarea" :autosize="{ minRows: 2 }" v-model="form.remark"
                maxlength="500"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 出库产品 -->
    <h3 class="mt30 mb20">出库产品</h3>

    <el-table border :data="tableData" class="mt20" :span-method="objectSpanMethod">
      <el-table-column align="center" prop="skuId" label="产品编码" fixed="left" width="120">
        <template slot-scope="scope">
          <el-link type="priamry" :underline="false" @click="skuIdClick(scope.row)">
            {{ scope.row.skuId }}
          </el-link>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="parentName" label="产品名称">
        <template slot-scope="scope">
          <span>
            {{ scope.row.productCn || '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center"  label="英文规格" show-overflow-tooltip width="150">
        <template slot-scope="scope">
          {{ scope.row.productSpecs || '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center"  label="中文规格"  show-overflow-tooltip width="150">
        <template slot-scope="scope">
          {{ scope.row.productSpecsCn || '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productType" label="产品类型"></el-table-column>

      <el-table-column align="center" prop="productType" label="单位">
        <template slot-scope="scope">
          {{
          scope.row.childProduct && scope.row.childProduct.length > 0
          ? scope.row.parentStandardCustomized
          : scope.row.productUnit || '-'
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expectOutNum" label="实际出库数量" width="180px">
        <template slot-scope="scope">
          <el-input-number :class="{ error: showError && !scope.row.warehouseOutNum }"
            v-model="scope.row.warehouseOutNum" @change="onChangewarehouseOutNum(scope.row, $event)" :precision="0"
            :min="0" @blur="checkEmpty(scope.row, 'warehouseOutNum')"></el-input-number>
          <p v-if="
              scope.row.warehouseOutNumEmptyCheck && !scope.row.warehouseOutNum
            " class="error-info">
            为正数,不能输入0或空
          </p>
        </template>
      </el-table-column>
      <!-- V1.6.1入库批次 -->
      <el-table-column align="center" prop="lotNo" label="入库批次" width="240">
        <template slot-scope="scope">
          <div class="lot-wrapper">
            <el-input disabled v-model="scope.row.lotNo"></el-input>
            <i v-if="isChoose" class="el-icon-circle-plus-outline" @click="lotSel(scope.row)"></i>
          </div>
        </template>
      </el-table-column>
      <!-- V1.6.1采购单号 -->
      <el-table-column align="center" prop="purchaseNumber" label="采购单号">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseNumber">
            {{ scope.row.purchaseNumber }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <!-- V1.6.1采购开发 -->
      <el-table-column align="center" prop="buyer" label="采购开发">
        <template slot-scope="scope">
          <span v-if="scope.row.buyer">
            {{ scope.row.buyer }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="160" prop="usableNum" label="可用库存"></el-table-column>
      <el-table-column align="center" width="160" prop="lotNumber" label="批次出库数量"></el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200px">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.remark && scope.row.remark.length > 20" class="item" effect="light"
            :content="scope.row.remark" placement="top-start">
            <el-input v-model="scope.row.remark" placeholder="请输入"></el-input>
          </el-tooltip>
          <el-input v-model="scope.row.remark" placeholder="请输入" v-else></el-input>
          <p v-if="scope.row.remark && scope.row.remark.length > 500" class="error-info">
            备注长度不能超过500
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="操作" width="200px" fixed="right">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
            <el-button type="danger" plain icon="el-icon-delete-solid" circle size="mini"
              @click="deletePro(scope.$index)"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <h3 class="mt30 mb20">相关附件</h3>
    <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload"
      style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"></ErpUpload>
    <p class="divider"></p>
    <div style="text-align: center; position: sticky; bottom: 0">
      <el-button @click="backCancel" class="ml15">取消</el-button>

      <el-button type="primary" @click="onSubmit">确认</el-button>
    </div>
    <!-- 批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList"></LotBatchSel>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import globalBackButtonMixin from '@/utils/global-back-button'
  import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'
  import { OutboundSheetInteractor } from '@/core'
  import LotBatchSel from '@/components/lot-batch-sel/index.vue'
  import { tableMixin } from '@/views/stock-center/helper/mixin'
  import { mixin as productMixin } from '@/views/purchase/outsourceProcess/help/mixin'

  const mergeColumns = [
    '产品编码',
    '产品名称',
    '规格',
    '产品类型',
    '产品类别',
    '申请出库数量/单位',
    '单位', //其他入库
    '库存', //其他入库
    '实际出库数量',
  ]
  let self
  export default {
    name: 'new-inbound-sheet',
    components: {
      LotBatchSel,
    },
    mixins: [tableMixin, productMixin],
    data() {
      self = this
      return {
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        showReject: false,
        reason: '',
        form: {
          creatorArea: '',
          creator: '',
          warehouseName: '',
          warehouseId: '', //出库仓库
          remark: '',
          createWay: 0,
          warehouseName: '',
          salesOrderCode: '',
          processOrderCode: '',
          orderNumber: '',
          supplierId: '', // 供应商id
        },

        rules: {
          warehouseId: [
            {
              required: true,
              message: '请选择出库仓库',
            },
          ],
        },
        isLoading: false,
        operation: {}, //登录人
        typeNames: [
          {
            type: 13,
            typeName: '领料出库',
          },
        ], //出库方式
        currentType: {
          type: 13,
          typeName: '领料出库',
        }, //选中出库方式
        warehouses: [], //仓库列表
        params: {
          show: false,
          warehouseName: '',
        },

        tableData: [],
        currentRow: {},
        files: '',
        isChoose: false, // 是否可以选择入库批次
        showError: false,
        warehouseOutType: '',
      }
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),

      tenantId() {
        return this.userInfo.tenantId
      },

      fileNames() {
        return this.files.map((f) => f.name).join(',')
      },
      fileUrls() {
        return this.files.map((f) => f.url).join(',')
      },
    },

    watch: {
      'form.warehouseId': async function (newval, oldval) {
        // 重置tableData
        if (newval) {
          await this.getStockCostConfig(newval)
        }
        if (newval) {
          if (!this.trigger) {
            this.trigger = true
            return
          }
          let tableData = this.initTableData.map((item, index) => {
            item.warehouseOutNum = this.tableData[index].warehouseOutNum
            return item
          })
          this.tableData = tableData

          for (let i = 0; i < this.tableData.length; i++) {
            const { skuId, warehouseOutNum, orderProductUniqueId } =
              this.tableData[i]
            if (!warehouseOutNum) continue
            if (this.warehouseOutType == 1) {
              const params = {
                num: warehouseOutNum,
                skuId,
                tenantId: this.userInfo.tenantId,
                warehouseId: newval,
                orderProductUniqueId,
                usable: 1,
                salesOrderNumber: this.form.salesOrderCode,
                processOut: true,
              }
              let res = await OutboundSheetInteractor.lotRecommend(params)
              if (res && res.code === '000000') {
                this.tableData.forEach((item) => {
                  if (
                    item.skuId == skuId &&
                    item.orderProductUniqueId === orderProductUniqueId
                  ) {
                    item.outLots = res.data.map((item) => {
                      item.lotNumber = item.num
                      return item
                    })
                  }
                })
              }
            }
          }
          this.tableData = this.formatTableData(this.tableData, 'outLots')
          this.resetCombineParams()
          this.getSpanArr(this.tableData)
        }
      },
    },
    async created() {
      const { processProductIds } = this.$route.query
      let res = await OutboundSheetInteractor.pickingProductQuery({
        processProductIds,
      })
      if (res && res.code === '000000') {
        this.initData(res.data)
      }
    },
    methods: {
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (mergeColumns.includes(column.label)) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      // 重置合并相关参数
      resetCombineParams() {
        this.spanArr = []
        this.pos = 0
      },
      // 设置选中批次
      setLocList(list) {
        this.tableData.forEach((item) => {
          if (item.orderProductUniqueId === this.currentLotRow.orderProductUniqueId) {
            item.outLots = list
          }
        })
        this.tableData = this.formatTableData(this.tableData, 'outLots')
        this.resetCombineParams()
        this.getSpanArr(this.tableData)
      },
      // 选择批次
      lotSel(row) {
        if (!row.warehouseOutNum || row.warehouseOutNum === 0) {
          return this.$message.warning('请输入实际出库数量')
        }
        this.currentLotRow = row //当前待设置的批次行
        if (!row.outLots) {
          this.$set(row, 'outLots', [])
        }
        console.log(row.outLots)

        // 添加orderProductUniqueId

        this.$refs.lotBatchSel.show({
          list: JSON.parse(JSON.stringify(row.outLots)), //拷贝
          sku: row.skuId,
          orderProductUniqueId: row.orderProductUniqueId,
          productName: row.productName,
          warehouseId: this.form.warehouseId,
          applyNum: row.warehouseOutNum,
          useType: row.usable === 1 ? 1 : 2,
          salesOrderNumber: '',
          orderCode: this.form.salesOrderCode,
          processOut: true,
        })
      },
      // 获取仓库配置
      async getStockCostConfig(id) {
        let res = await OutboundSheetInteractor.getStockCostConfigureVO(id)
        if (res && res.code === '000000') {
          this.isChoose = res.data?.warehouseInBatch === 1 ? true : false
          this.warehouseOutType = res.data?.warehouseOutType
        }
      },
      backCancel() {
        this.$router.push({
          path: '/purchase/outsourceProcess/outsource-process',
        })
      },

      goBack() {
        this.$router.push('/purchase/outsourceProcess/outsource-process')
      },
      checkEmpty(row, field) {
        if (
          row[field] === 0 ||
          row[field] === '' ||
          typeof row[field] === 'undefined'
        ) {
          if (!row[field + 'emptyCheck']) {
            this.$set(row, [field + 'EmptyCheck'], true)
          }
        }
      },
      // 提交时产品校验

      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },
      // 获取推荐批次
      async getLotRecommend(params) {
        const { skuId, orderProductUniqueId, salesOrderNumber } = params
        let res = await OutboundSheetInteractor.lotRecommend(params)
        if (res && res.code === '000000') {
          this.tableData.forEach((item) => {
            if (
              item.skuId == skuId &&
              item.orderProductUniqueId === orderProductUniqueId
            ) {
              item.outLots = res.data.map((item) => {
                item.lotNumber = item.num
                return item
              })
            }
          })
          this.tableData = this.formatTableData(this.tableData, 'outLots')
          this.resetCombineParams()
          this.getSpanArr(this.tableData)
        }
      },

      onChangewarehouseOutNum(row, e) {
        const {
          stockNum,
          warehouseOutNum,
          lockNum,
          skuId,
          orderProductUniqueId,
        } = row
        const { warehouseId } = this.form
        let flag
        if (warehouseId && row.warehouseOutNum && skuId) {
          if (this.warehouseOutType == 1) {
            const params = {
              num: warehouseOutNum,
              skuId,
              tenantId: this.userInfo.tenantId,
              warehouseId,
              orderProductUniqueId,
              usable: 1,
              salesOrderNumber: this.form.salesOrderCode,
              processOut: true,
            }

            this.getLotRecommend(params)
          }
        }
        return flag
      },

      toFixed2(val) {
        if (!val && val !== 0) {
          return '0.00'
        } else {
          return (val * 1).toFixed(3)
        }
      },

      deletePro(index) {
        if (this.tableData.length === 1) {
          return this.$message.warning('至少保留一条数据')
        }
        this.tableData.splice(index, 1)
      },

      async onSubmit() {
        let flag = false
        let arr = []
        let self = this
        if (!this.tableData || this.tableData.length === 0) {
          return this.$message.warning('请选择产品')
        }

        const skuList = [
          ...new Set(
            this.tableData.map((i) => i.skuId + i.orderProductUniqueId)
          ),
        ]
        for (let i = 0; i < skuList.length; i++) {
          const { warehouseOutNum, productCn } = this.tableData.find(
            (item) => item.skuId + item.orderProductUniqueId === skuList[i]
          )
          const lotNumberTotal = this.tableData
            .filter((j) => j.skuId + j.orderProductUniqueId == skuList[i])
            .map((k) => k.lotNumber)
            .reduce((prev, next) => {
              prev += next
              return prev
            }, 0)

          if (warehouseOutNum !== lotNumberTotal) {
            return this.$message.warning(
              `产品${productCn}的实际出库数量不等于批次出库数量`
            )
          }
        }
        const { type, typeName } = this.currentType
        this.isLoading = true
        let warehouseOutProductDTOList = JSON.parse(
          JSON.stringify(this.tableData)
        )
        warehouseOutProductDTOList = warehouseOutProductDTOList.map((item) => {
          item.warehouseOutNum = item.lotNumber
          return item
        })
        const requestData = {
          originFileUrls: this.form.originFileUrls,
          fileUrls: this.files,
          operationId: this.form.operationId,
          operationName: this.form.operationName,
          remark: this.form.remark,
          orderNumber: this.form.orderNumber,
          businessId: this.form.businessId,
          businessName: this.form.businessName,
          type,
          typeName,
          warehouseId: this.form.warehouseId,
          warehouseName: this.warehouses.find(
            (item) => this.form.warehouseId === item.warehouseId
          ).name,
          warehouseOutProductDTOList,
          createWay: 1,
          area: this.userInfo.area,
        }

        let res = await OutboundSheetInteractor.processWarehouseOut(requestData)
        if (res && res.code === '000000') {
          this.$message.success('出库申请成功')
          this.$router.push({
            path: '/purchase/outsourceProcess/outsource-process',
          })
        }
      },
      // 初始化基础信息
      _initBaseInfo(data) {
        const { userName, area, userId } = this.userInfo
        this.form.operationName = userName
        this.form.operationId = userId
        this.form.creatorArea = area
        this.form.creator = userName
        this.form.businessId = data.businessId
        this.form.businessName = data.businessName
        this.form.processOrderCode = data.processOrderCode
        this.form.salesOrderCode = data.salesOrderCode
        this.form.orderNumber = data.processOrderCode
        this.form.supplierId = data.supplierId
        this.tableData = (data.products || []).map((item) => {
          item.outLots = []
          item.warehouseOutNum = undefined
          item.usable = 1
          return item
        })
        this.initTableData = JSON.parse(JSON.stringify(this.tableData))
        this.setBusinessUser(this.tableData)
        this.getSpanArr(this.tableData)
      },

      // 设置businessUser
      setBusinessUser(list) {
        const pro = list && list[0]
        if (pro) {
          this.form.businessUserId = pro.businessUserId
          this.form.businessUserName = pro.businessUserName
          this.form.businessUserNameEn = pro.businessUserNameEn
        }
      },

      // 初始化数据
      async initData(data) {
        this._initBaseInfo(data)
        this._initDefaultWarehouseId('out')
        this._getWarehouseList()
      },

      initUpload(data, showClose) {
        this.$refs.ErpUpload.initUpload(data, showClose)
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mb15 {
    margin-bottom: 15px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .table-container {
    padding: 20px 15px !important;
  }

  .operation {
    display: flex;
    justify-content: space-evenly;

    i {
      font-size: 20px;
    }
  }

  .error-info {
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    position: relative;
    padding-top: 5px;
  }

  .pointer {
    cursor: pointer;
  }

  ::v-deep .el-input-number--small {
    width: 100px !important;
  }

  ::v-deep .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }

  ::v-deep .el-input-number--small .el-input-number__increase,
  ::v-deep .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }

  ::v-deep .el-input-number--small.error .el-input__inner {
    border-color: #ff4d4f;
  }

  .lot-wrapper {
    display: flex;
    align-items: center;

    .el-input {
      flex: 1;
    }

    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 20px;
      color: rgb(40, 130, 255);
    }
  }
</style>

<style>
  .outbound .el-select-dropdown__item {
    width: 200px;
  }

  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
